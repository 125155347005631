<template>
  <article class="post-card post-link" :data-read="refinedItem.myViewCnt > 0 ? 'active' : ''" @click="goNote(refinedItem);">

<!--    <a href="javascript:" ></a>-->
<!--    <router-link :to="getPath(refinedItem)" class="post-link" @click="moveBoard"></router-link>-->
    <div class="post-image">
      <v-lazy-image v-if="refinedItem.thumb" :src="refinedItem.thumb" />
    </div>
    <div class="post-deem"></div>
    <div class="post-content">
      <div class="post-content-date">
        <h6>{{ refinedItem.yearMonth }}</h6>
        <h1>{{ refinedItem.day }}</h1>
      </div>
      <section class="post-content-desc">
        <div class="post-title">
          <h3>{{ refinedItem.title }}</h3>
          <div class="post-info">
            <div class="info-item">
              <i class="icon-like"></i>
              <span class="text">{{ refinedItem.likeCnt }}</span>
            </div>
            <div class="info-item">
              <i class="icon-share"></i>
              <span class="text">{{ refinedItem.sharedCnt }}</span>
            </div>
            <div class="info-item">
              <i class="icon-view"></i>
              <span class="text">{{ refinedItem.inqcnt }}</span>
            </div>
          </div>
        </div>
        <div class="post-desc">
          <p>{{refinedItem.cutCn}}</p>
        </div>
        <div class="post-profile">
          <div class="author">
            <span class="name" style="margin-right: 5px">{{ refinedItem.lrnerNm }}</span>
<!--            <span class="office">{{ isGroup ? refinedItem.refTyNm : '' }}</span> &lt;!&ndash; refinedItem.deptNm &ndash;&gt;-->
          </div>
        </div>
      </section>
      <div class="post-content-type">
        <div v-if="refinedItem.refCttTyNm === 'VOD'" class="badge-vod"><span class="text">영상</span></div>
        <div v-else-if="refinedItem.refCttTyNm === 'PDF'" class="badge-pdf"><span class="text">PDF</span></div>
        <div v-else class="badge-note"><span class="text">노트</span></div>
      </div>
      <div class="post-content-read" :class="{'is-active': refinedItem.myViewCnt > 0}">
        <i class="icon-read"></i>읽음
      </div>
    </div>
  </article>
<!--  <SSLNotePopupModal v-if="noteModal && !isMobile" v-model="noteModal" :soc-lrn-post-sn="socLrnPostSn" :dist-crse-cd="distCrseCd"/>-->

<!--  <SSLEnroll-->
<!--      v-if="enroll.toggle"-->
<!--      v-model:toggle="enroll.toggle"-->
<!--      :dist-crse-cd="enroll.key"-->
<!--      :socLrnPostSn="enroll.socLrnPostSn"-->
<!--  />-->
</template>
<script>

import {computed, reactive, ref} from "vue";
import {timestampToDatePart} from "@/assets/js/util"; // dateToDateFormat, timestampToDateFormat,
import {useRouter} from "vue-router"; //useRoute
import VLazyImage from "v-lazy-image";
import {getTargetDt, getCrseSnToDistCrseCd} from '@/assets/js/modules/ssl/ssl-common'; //, isMyLrnTrgtGrpMstSn
// import SSLNotePopupModal from "@/components/main/SSLNotePopupModal.vue";
import navigationUtils from "@/assets/js/navigationUtils";
// import SSLEnroll from "@/components/ssl/common/SSLEnroll.vue";

export default {
  name: 'SSLBoardCard',
  components:{
    // SSLEnroll,
    // SSLNotePopupModal,
    VLazyImage
  },
  props: {
    item: {
      required: true,
      type: Object
    },
    isGroup: Boolean
  },
  emits: ['moveBoard'],
  setup(props, {emit}) {
    // const route = useRoute();
    const router = useRouter();
    const isMobile = navigationUtils.any();
    const noteModal = ref(false);

    const enroll = reactive({
      key: null,
      toggle: false,
      socLrnPostSn:0
    });

    const refinedItem = computed(() => {

      const datePart = timestampToDatePart(getTargetDt(props.item));
      return {
        ...props.item,
        yearMonth: `${datePart.year}.${datePart.mm}`,
        day: datePart.dd,
        cutCn : (props.item.cn !=null && props.item.cn.length > 300 ? props.item.cn.substring(0,300) + '...':props.item.cn)
      }
    });

    // const getPath = (item) => {
    //   const distCrseCd = computed(()=>getDistCrseCd(item.distCrseSn.toString()));
    //
    //   if(item.stt === '01'){
    //     return {name: 'SSLWrite', params: {distCrseCd:distCrseCd.value, lrnTrgtGrpMstSn:item.lrnTrgtGrpMstSn, socLrnPostSn: item.socLrnPostSn}};
    //   }else{
    //     if(route.name === 'SSLMyBoard'){
    //       if(timestampToDateFormat((item.pblcnDt ? item.pblcnDt : item.regDt), 'yyyy-MM-dd') === dateToDateFormat(new Date(), 'yyyy-MM-dd')){
    //         return {name: 'SSLWrite', params: {distCrseCd:distCrseCd.value, lrnTrgtGrpMstSn:item.lrnTrgtGrpMstSn, socLrnPostSn: item.socLrnPostSn}};
    //       }else{
    //         return {name: 'SSLView', params: {distCrseCd:distCrseCd.value, lrnTrgtGrpMstSn:item.lrnTrgtGrpMstSn, socLrnPostSn: item.socLrnPostSn}};
    //       }
    //     }else{
    //       return {name: 'SSLView', params: {distCrseCd:distCrseCd.value, lrnTrgtGrpMstSn:item.lrnTrgtGrpMstSn, socLrnPostSn: item.socLrnPostSn}};
    //     }
    //   }
    // }

    const moveBoard = () => {
      emit('moveBoard', props.item.socLrnPostSn);
    }

    // const socLrnPostSn = ref(0);
    // const distCrseCd = ref('');

    const goNote = (item) => {
      // console.log('crseMstSn : ' + item.crseMstSn);
      let distCrseCd = getCrseSnToDistCrseCd(item.crseMstSn);
      // console.log('distCrseCd : ' + distCrseCd);

      // if (isMyLrnTrgtGrpMstSn(item.lrnTrgtGrpMstSn)|| distCrseCd == 'trivia') { //신청한 과정인경우
        emit('moveBoard', props.item.socLrnPostSn);

        router.push({
          name: 'SSLView',
          params: {distCrseCd: distCrseCd, lrnTrgtGrpMstSn: item.lrnTrgtGrpMstSn, socLrnPostSn: item.socLrnPostSn}
        })
      // }else{
      //   enroll.key = distCrseCd.value;
      //   enroll.toggle = true;
      //   enroll.socLrnPostSn = item.socLrnPostSn;
      // }
    }

    return {
      // category,
      refinedItem,
      // getPath,
      moveBoard,
      goNote,
      noteModal,
      isMobile,
      // socLrnPostSn,
      // distCrseCd,
      enroll
    }


  }
}
</script>
