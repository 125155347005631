import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {computed, ref} from "vue";
import {ACT_GET_CHECK_PWD_GROUP_LRN_BOARD_MNG, MUT_SET_GROUP_DIST_PWDTOKEN} from "@/store/modules/board/board";
import {getItems, lengthCheck} from "@/assets/js/util";
import navigationUtils from "@/assets/js/navigationUtils";

export const groupCardSetup = (props) =>{

    const store = useStore();
    const router = useRouter();

    const alertShow = ref(false);
    const pwd = ref('');
    const pwdFail = ref(true);

    const isEnd = computed(() => props.items.filter(x =>  x.lrnTrgtCnt === x.peoplCnt)[0]);
    const boardSn = ref(0);
    const distCrseSn = computed(()=>props.distCrseSn)

    const setDefaultImg = (e) => {
        e.target.src = require('@/assets/images/profile/sample.png');
    }

    const clickGroupDetail = (board) => {
        boardSn.value = board;
        alertShow.value = true;
    };

    const okConfirm = () => {
        store.dispatch(`board/${ACT_GET_CHECK_PWD_GROUP_LRN_BOARD_MNG}`, {
            boardSn: boardSn.value,
            pwd: pwd.value
        }).then(res => {
            if(lengthCheck(res)) {
                const items = getItems(res);
                store.commit(`board/${MUT_SET_GROUP_DIST_PWDTOKEN}`, items[0].pwdToken);
                router.push({name: 'GroupLearningDetailView', params: {distCrseSn: items[0].distCrseSn}});
            } else {
                pwdFail.value = false;
            }
        }).catch(e => {
            console.error(e);
        })
    }

    const goGroupLrn = (distCrseSn) => {
        store.commit(`board/${MUT_SET_GROUP_DIST_PWDTOKEN}`, null);
        router.push({name: 'GroupLearningDetailView', params: {distCrseSn: distCrseSn}});
    }

    const closeConfirm = () => {
        pwd.value = '';
        pwdFail.value = true;
        alertShow.value = false;
    }

    return {
        alertShow,
        pwd,
        pwdFail,
        isEnd,
        setDefaultImg,
        // getProfileImg,
        clickGroupDetail,
        okConfirm,
        closeConfirm,
        goGroupLrn,
        distCrseSn,

        isMobile: navigationUtils.any()
    }
}