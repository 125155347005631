<template>
  <main class="kb-main" id="kb-hrdcloud">
    <div class="main-header">
      <div class="header-top min-component">
        <div class="header-column">
          <a href="javascript:" class="page-nav" @click="goBack">
            <i class="icon-kb-nav-arrow"></i>
            <span class="text">이전</span>
          </a>
        </div>
        <div class="header-column">
          <button class="kb-btn-mode" @click="toggleConcentrationMode">
            <span class="text">{{ concentrationModeText }}</span>
            <i class="icon-mode"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="main-content min-component" :class="{'viewscript' : viewScriptFlag}">
      <div class="hrdcloud-view-container">
        <div class="player hrdcloud-player">
          <div class="player-container">
            <div class="player-inner" :class="{'viewscript': viewScriptFlag}">
              <template v-if="!isLoading && hrd && uuid">
                <HrdCloudPlayer
                    v-if="hrd.lrnObjTyCd === lrnObjTyCds.MP3 || hrd.lrnObjTyCd === lrnObjTyCds.MP4"
                    v-model:uuid="uuid"
                    v-model:is-series-active="isSeriesActive"
                    :coldcall="coldcall"
                    :crse="hrd"
                    :progress="user"
                    :enable-progress="true"
                    :popups="popups"
                    :wrapper-dom1="wrapperDom1"
                    :wrapper-dom2="wrapperDom2"
                    :wrapper-dom3="wrapperDom3"
                    :view-script-flag="viewScriptFlag"
                    :funcs="detailFuncs"
                />
              </template>
            </div>
            <div class="player-details" :class="{'viewscript' : viewScriptFlag}">
              <HrdPlayerDetails v-if="hrd" :hrd="hrd" :funcs="detailFuncs" />
            </div>
          </div>
        </div>
        <HrdCloudComment  :class="{'viewscript' : viewScriptFlag}" v-if="hrd && hrd.objSn" :effect-nav="effectNav" :objSn="hrd.objSn" />
      </div>
    </div>

    <HrdCloudStopAlert
        v-model="popups.miss.show"
        title="학습확인(Cold Call) 미응답 안내"
        type="coldcall"
        :func="popups.miss"
        v-model:wrapper-dom="wrapperDom1"
    >
      <template v-slot:content>
        <p class="text text-sm"><span style="color:red;">학습확인(Cold Call) 미응답</span>으로<br>
          <span style="color:red;">학습완료 및 마일리지가 인정되지 않습니다.</span></p>
      </template>
    </HrdCloudStopAlert>
    <HrdCloudStopAlert
        v-model="popups.resume.show"
        title="이어보시겠습니까?"
        type="resume"
        :func="popups.resume"
        v-model:wrapper-dom="wrapperDom2"
    >
      <template v-slot:content>
        <p class="text">이어보기 위치에 따라 <span class="text-red">학습확인(Cold Call) 미호출</span>로 인해<br>
          <span class="text-red">학습완료가 미인정</span>될 수 있습니다.</p>
        <p class="text-sm text-muted">※ 정상적인 학습완료를 위해서는 '다시학습' 을 권장드립니다.</p>
      </template>
    </HrdCloudStopAlert>
    <HrdCloudLrnComplete v-model="popups.complete" v-model:wrapper-dom="wrapperDom3" v-model:hrdObj="hrd"/>

    <HrdCloudHistory v-if="hrd && historyModal" v-model="historyModal" :title="hrd.objNm" :duration="hrd.cttSz" :items="histories" :func="showLatestHistory" :ctt-ty-cd-dcd="hrd.cttTyCdDcd" />
    <HrdCloudHistoryDtlModal v-if="hrd && latestHistoryModal" v-model="latestHistoryModal" v-model:lrn-obj-dtl-dist-sn="lrnObjDtlDistSn" :year="new Date().getFullYear()" :month="new Date().getMonth()+1" />
    <HrdCloudShareModal v-if="hrd && shareModal" v-model="shareModal" :data="hrd" />
    <HrdCloudOpinionModal v-if="opinionModal" v-model="opinionModal" :cttMstSn="hrd.objSn" :title="hrd.objNm" />
    <HrdCloudCaptionModiModal v-if="captionModal" v-model="captionModal" :cttMstObj="hrd" :caption="caption" />

    <HrdCloudSttModal v-if="hrd && sttModal" v-model="sttModal" :title="hrd.objNm" :item="sttText" />
  </main>
</template>

<script>
import {goBack} from '@/assets/js/modules/common/common';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
import {getItem, initProgressBar, lengthCheck, setParams, timestampToDateFormat} from '@/assets/js/util';
import HrdCloudComment from '@/components/hrdcloud/HrdCloudComment';
import HrdCloudPlayer from '@/components/hrdcloud/HrdCloudPlayer';
import {
  darkMode,
  detailCategoryDisplay,
  disableMyAddChanged,
  generateUUID,
  // getTop1CategoryByLevel,
  getUserProgress,
  handleActions,
  lightMode,
  // makeTags,
  pageView,
  toggleConcentrationMode,
} from '@/assets/js/modules/hrd/hrd-common';
import HrdCloudHistory from '@/components/hrdcloud/HrdCloudHistory';
import HrdCloudShareModal from '@/components/hrdcloud/HrdCloudShareModal';
import HrdCloudOpinionModal from '@/components/hrdcloud/HrdCloudOpinionModal';
import HrdCloudHistoryDtlModal from '@/components/history/HrdCloudHistoryDtlModal';
import HrdCloudSttModal from '@/components/hrdcloud/HrdCloudSttModal';
import HrdPlayerDetails from '@/components/hrdcloud/pages/HrdPlayerDetails';
import HrdCloudStopAlert from '@/components/hrdcloud/HrdCloudStopAlert.vue';
import HrdCloudLrnComplete from '@/components/hrdcloud/HrdCloudLrnComplete.vue';
import {lrnObjTyCds} from '@/assets/js/modules/learn/learn-common';
import HrdCloudCaptionModiModal from "@/components/hrdcloud/HrdCloudCaptionModiModal.vue";
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'HrdVod',
  components:{
    HrdCloudLrnComplete,
    HrdCloudStopAlert,
    HrdPlayerDetails,
    HrdCloudHistoryDtlModal,
    HrdCloudOpinionModal,
    HrdCloudShareModal,
    HrdCloudHistory,
    HrdCloudPlayer,
    HrdCloudComment,
    HrdCloudSttModal,
    HrdCloudCaptionModiModal,
  },
  props: {
    objDetail: Object,
    effectNav: {
      required: false,
      type: Number,
      default: 0
    },
  },
  setup(props) {
    const {showMessage} = useAlert();
    const store = useStore(), route = useRoute();
    const isMobile = computed(() => store.state.auth.isMobile);
    const session = computed(() => store.state.auth.session);
    const hrd = computed(() => props.objDetail);
    const isLoading = ref(true);
    const lrnObjDtlDistSn = computed(() => route.params.lrnObjDtlDistSn);
    const category = ref({});
    const concentrationModeText = computed(() => store.state.hrdcloud.mode === 'dark' ? '집중모드 켜짐' : '집중모드 꺼짐');
    const isMenuActive = ref(false);
    const coldcall = ref(false);
    const user = reactive({ progRsltSn: 0, maxPlaySec: 0, lastPlaySec: 0, cmptnYn: 'N', mdfcnDt: null, regDt: null });
    const latestHistoryModal = ref(false), historyModal = ref(false), shareModal = ref(false), opinionModal = ref(false), sttModal = ref(false), captionModal = ref(false);
    const histories = ref([]);
    const sttText = ref({});
    const wrapperDom1 = ref(null);
    const wrapperDom2 = ref(null);
    const wrapperDom3 = ref(null);
    const uuid = ref(null);
    const isSeriesActive = ref(false);

    const viewScriptFlag = ref(false);
    const caption = ref(null);

    const popups = reactive({
      miss: {
        show: false,
        toggle: (bool) => {
          popups.miss.show = bool;
        },
        response: {
          effect: 0,
          type: ''
        },
        respond: (type) => {
          popups.miss.response.type = type;
          popups.miss.show = false;
          popups.miss.response.effect++;
        },
        initRespond : () => {
          popups.miss.response.type = '';
        }
      },
      resume: {
        show: false,
        toggle: (bool) => {
          popups.resume.show = bool;
        },
        response: {
          effect: 0,
          type: '',
          continueEffect: 0,
        },
        respond: (type) => {
          popups.resume.response.type = type;
          popups.resume.show = false;
          popups.resume.response.effect++;
          if (type === 'continue') {
            popups.resume.response.continueEffect++;
          }
        },
        initRespond : () => {
          popups.resume.response.type = '';
        }
      },
      complete: {
        show: false,
        feedback: false,
        toggle: (bool) => {
          popups.complete.show = bool;
          if(!bool) popups.complete.feedback = bool;
        },
        callback: (videoInstance) => {
          videoInstance.value.exitFullscreen();
        }
      },
      getUUID: async () => {
        const id = await generateUUID();
        if (id && id.uuid) uuid.value = id.uuid;
      }
    });

    const objectParam = computed(() => {
      return {
        distCrseSn: hrd.value.distCrseSn,
        distChapSn: hrd.value.distChapSn,
        lrnObjDtlDistSn: hrd.value.lrnObjDtlDistSn,
        objSn: hrd.value.objSn,
        // division: 'vod'
      }
    });

    // 콘텐츠 정보
    const getHrdCloudDetail = async () => {

      pageView(hrd);
      await popups.getUUID();
      const userProgressRes = await getUserProgress(hrd);
      if(lengthCheck(userProgressRes)) setParams(user, getItem(userProgressRes));
      isLoading.value = false;

      // store.dispatch(`prep/${ACT_GET_HRDCLOUD_DETAIL}`, {lrnObjDtlDistSn: lrnObjDtlDistSn.value}).then(async (res) => {
      //   if (res && res.items.length > 0) {
      //     hrd.value = res.items.map(x => {
      //       return {
      //         ...getTop1CategoryByLevel(x.cloudKeyLst),
      //         ...x,
      //         tag: makeTags(x.tag)
      //       };
      //     })[0];
      //     pageView(hrd);
      //     await popups.getUUID();
      //     const userProgressRes = await getUserProgress(hrd);
      //     if(lengthCheck(userProgressRes)) setParams(user, getItem(userProgressRes));
      //   }
      //   isLoading.value = false;
      // }).catch(() => {
      //   isLoading.value = false;
      // });
    };

    const togglePlayerMenu = () => {
      isMenuActive.value = !isMenuActive.value;
    };

    const detailFuncs = {
      openHistory: (item) => {
        handleActions(item).actHistory(historyModal, histories);
      },
      openShare: (item) => {
        handleActions(item).actShare(shareModal);
      },
      openOpinion: (item) => {
        handleActions(item).actOpinion(opinionModal);
      },
      openStt: (item) => {
        handleActions(item).actStt(sttModal,sttText);
      },
      toggleScript: (message) => {
        if(message){
          showMessage(message)
          console.log(message);
        }
        viewScriptFlag.value = !viewScriptFlag.value;
      },
      openCaptionModi:(idx, subtitle, player) =>{
        player.pause();
        caption.value = subtitle;
        captionModal.value = true;
      }
    };

    const showLatestHistory = () => {
      latestHistoryModal.value = true;
    };

    getHrdCloudDetail();

    watch(() => lrnObjDtlDistSn.value, () => {
      initProgressBar();
      if (lrnObjDtlDistSn.value > 0) {
        getHrdCloudDetail();
      }
    });


    const leaderAdd = computed(() => store.state.hrdcloud.leaderAdd);

    // 담기 후 업데이트를 위해 한번 더 호출
    watch(() => leaderAdd.value.show, () => {
      if (hrd.value && !leaderAdd.value.show && leaderAdd.value.updateMyAdd) {
        const lrnObjDtlDistSn = leaderAdd.value.lrnObjDtlDistSn;
        if (hrd.value.lrnObjDtlDistSn === lrnObjDtlDistSn) {
          hrd.value.put = leaderAdd.value.isPut;
          disableMyAddChanged();
        }
      }
    });

    onMounted(() => {
      darkMode();
    });

    onBeforeUnmount(() => {
      lightMode();
      coldcall.value = false;
    });

    return {
      isMobile, session, uuid, lrnObjDtlDistSn,
      hrd, category, coldcall, user, histories,sttText,
      isLoading,
      goBack,
      concentrationModeText,
      isMenuActive,
      toggleConcentrationMode,
      togglePlayerMenu,
      timestampToDateFormat,
      handleActions, detailFuncs, detailCategoryDisplay,
      historyModal, shareModal, opinionModal, captionModal, objectParam,sttModal,
      latestHistoryModal ,showLatestHistory,
      popups, lrnObjTyCds,
      wrapperDom1, wrapperDom2, wrapperDom3,
      isSeriesActive,
      viewScriptFlag, caption
    };
  }
};
</script>
