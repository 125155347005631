<template>
  <section class="myssl-section section-mybadge">
    <header class="section-header">
      <h3 class="title">내 배지 <span class="text-muted">{{ learnerBadges.length }}</span></h3> <!-- /{{activeBadges.length}} -->
    </header>
    <div class="mybadge-wrapper">

      <div class="sbadge-wrapper my-sbadge-wrapper">
        <!-- badge -->
        <SSLBadgeTitle v-for="(badge, idx) in activeBadges"
                  :key="idx"
                  :badge-ty-cd-dcd="badge.badgeTyCdDcd"
                  :is-active="badge.isActive"
                  :is-new="badge.isNew"
                  :hidden-yn="badge.hiddenYn"
                  :badge-data="badge"
                  :show-title="true"
                  :show-tooltip="true"
        />
      </div>
    </div>
  </section>
</template>

<script>

import SSLBadgeTitle from '@/components/ssl/badge/SSLBadgeTitle';
import {sslLearnerBadgeSetup} from '@/assets/js/modules/ssl/setup/ssl-learn-setup';

export default {
  name: 'SSLLearnerBadge',
  components: {
    SSLBadgeTitle
  },
  setup: sslLearnerBadgeSetup
}
</script>
