<template>
  <HrdCloudMobileCloudMap v-if="showHrdCloudMap" v-model="showHrdCloudMap"/>
  <HrdCloudMobileCategory v-if="showHrdCloudCate" v-model="showHrdCloudCate"/>
  <HrdCloudHistoryMobileKbTube v-if="showKbTubeModal" v-model="showKbTubeModal" :init-upload-modal-active="true"/>
  <HrdCloudHistoryMobileAttendance v-if="attendanceModal" v-model="attendanceModal" />

  <main class="kb-main" id="kb-hrdcloud-v2" v-if="!showKbTubeModal && !attendanceModal">
    <HrdCloudMobileHeaderV2
        :title="renderTitle"
        :keyword="search.keyword"
        :show-search="true"
    >
      <template v-slot:left >
        <CloudMainMenu
        />
      </template>
      <template v-slot:title>
        <HrdCloudMobileSubMenuV2 v-model="showSubMenu" v-model:showHrdCloudMap="showHrdCloudMap" v-model:showKbTubeModal="showKbTubeModal" v-model:tabMenu="tabMenu"/>
      </template>
    </HrdCloudMobileHeaderV2>
    <template v-if="tabMenu === 0">
      <template v-if="showInterestInfoModal">
        <div class="container" style="padding-top: 20px;">
          <div class="title">KB스타런에서 무엇을 학습하고 싶은가요?</div>
          <div class="subtitle">관심 주제를 선택하여 KB스타런 경험을 맞춤 설정 하세요!</div>
          <div class="topics">
            <div v-for="(item,idx) in interestInfoItems" :key="idx" class="topic"
                 :class="{'selected': item.useYn === 'Y'}" @click="topicSelected(item)">
              {{ item.interestInfoNm }}
              <!--            <input type="checkbox" class="kb-form-check-input-v2" name="chk_01" id="chk_01_1" :value="item">-->
            </div>
          </div>
        </div>
        <div class="page-buttons"><button class="kb-btn kb-btn-primary" style="margin-top: -50px;" @click="showInterestInfoModal = !showInterestInfoModal"><span class="text">설정 완료</span></button></div>
      </template>
      <template v-else>
        <form class="kb-form-search-forms">
          <div class="kb-form-search-v2">
            <div class="kb-form-search-field"><input type="text" v-model.trim="search.keyword"
                                                     class="kb-form-search-input-v2"
                                                     @keydown.enter.prevent="searchKeyword(search.keyword)"
                                                     placeholder="어떤 콘텐츠를 찾으시나요?">
              <button type="button" class="kb-form-search-btn" @click="searchKeyword(search.keyword)"><i class="icon-search">검색</i>
              </button>
              <button v-if="search.keyword.length > 0" type="button" class="kb-form-search-delete" @click="resetSearch"><i class="icon-delete">삭제</i></button>
            </div>
            <div style="display: flex; overflow-x: auto; padding-top: 10px;">
              <div style="display: flex; overflow-x: auto;">
                <div @click="clickInterestSearch(-1)" style="margin-right: 5px;">
                  <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn"
                     :class="{'text-is-active': activeIdx === -1}" href="javascript:"><span
                      class="text">관심</span>
                  </a>
                </div>
                <div v-for="(data,idx) in interestInfoItems.filter(x => x.useYn === 'Y')" :key="idx"
                     @click="clickInterestSearch(idx)"
                     style="margin-right: 5px;">
                  <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn"
                     :class="{'text-is-active': idx === activeIdx}" href="javascript:"><span
                      class="text">{{ data.interestInfoNm }}</span>
                  </a>
                </div>
              </div>
              <div type="button" style="width: 35px; height: 30px; text-align: right; margin-left: auto;" @click="showInterestInfoModal = !showInterestInfoModal"><i
                  class="icon-setting"></i>
              </div>
            </div>
          </div>
        </form>
        <HrdCloudMobileMyV2 v-if="!isSearch" :session="session" :toggleModal="toggleModal" :attendanceCnt="attendanceCnt" v-model="attendanceModal" />
        <HrdCloudMobileMainRoadmapList v-show="!isSearch">
        </HrdCloudMobileMainRoadmapList>

      <div v-if="hrdShorts.length > 0" class="main-content main-component" style="padding-left: 15px; margin-bottom: -30px;">
        <article class="content-section">
          <header class="section-header"><h4 class="title">숏클</h4></header>
          <HrdCloudMobileSwiper :items="hrdShorts" :session="session" :show-put="true" :is-shorts="true" :addShortsItems="addShortsItems" :swipeLoading="swipeLoading"/>
        </article>
      </div>

      <div v-if="!isSearch" class="main-header main-header-visual" style="z-index: 10;">
        <!-- ㅡmain-header-nav -->
        <div class="header-snb">
          <nav class="snb">
            <ul class="snb-list">
              <li class="snb-item">
                <button :class="{'is-active': selectedMenu === 0 && !isSearch}" @click="selectedMenuFunc(0)">신규</button>
              </li>
              <li class="snb-item">
                <button :class="{'is-active': selectedMenu === 1 && !isSearch}" @click="selectedMenuFunc(1)">추천</button>
              </li>
              <li class="snb-item">
                <button :class="{'is-active': selectedMenu === 2 && !isSearch}" @click="selectedMenuFunc(2)">직무인기</button>
              </li>
              <li class="snb-item">
                <button :class="{'is-active': selectedMenu === 3 && !isSearch}" @click="selectedMenuFunc(3)">지식인기</button>
              </li>
            </ul>
          </nav>
        </div>
      </div>


      <article v-if="isKbBank && !showInterestInfoModal && hrdCloudItems.length > 0 ">
        <HrdCloudMobileSearchResultV2
            :keyword="tmpKeyword"
            :session="session"
            :hrds="hrdCloudItems"
        />
        <article v-if="hrdLoading">
          <LoadingDiv v-model="hrdLoading"/>
        </article>

      </article>
      <article v-else>
        <LoadingDiv v-model="hrdLoading"/>
      </article>

      </template>

    </template>
    <template v-else>
      <template v-if="showInterestInfoModal">
        <div class="container" style="padding-top: 20px;">
          <div class="title">KB스타런에서 무엇을 학습하고 싶은가요?</div>
          <div class="subtitle">관심 주제를 선택하여 KB스타런 경험을 맞춤 설정 하세요!</div>
          <div class="topics">
            <div v-for="(item,idx) in interestInfoItems" :key="idx" class="topic"
                 :class="{'selected': item.useYn === 'Y'}" @click="topicSelected(item)">
              {{ item.interestInfoNm }}
              <!--            <input type="checkbox" class="kb-form-check-input-v2" name="chk_01" id="chk_01_1" :value="item">-->
            </div>
          </div>
        </div>
        <div class="page-buttons"><button class="kb-btn kb-btn-primary" style="margin-top: -50px;" @click="showInterestInfoModal = !showInterestInfoModal"><span class="text">설정 완료</span></button></div>
      </template>
      <template v-else>
      <form class="kb-form-search-forms">
        <div class="kb-form-search-v2">
          <div class="kb-form-search-field"><input type="text" v-model.trim="search.keyword"
                                                   class="kb-form-search-input-v2"
                                                   @keydown.enter.prevent="searchKeyword(search.keyword)"
                                                   placeholder="어떤 콘텐츠를 찾으시나요?">
            <button type="button" class="kb-form-search-btn" @click="searchKeyword(search.keyword)"><i class="icon-search">검색</i>
            </button>
            <button v-if="search.keyword.length > 0" type="button" class="kb-form-search-delete" @click="resetSearch"><i class="icon-delete">삭제</i></button>
          </div>
          <div style="display: flex; overflow-x: auto; padding-top: 10px;">
            <div style="display: flex; overflow-x: auto;">
              <div @click="clickInterestSearch(-1)" style="margin-right: 5px;">
                <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn"
                   :class="{'text-is-active': activeIdx === -1}" href="javascript:"><span
                    class="text">관심</span>
                </a>
              </div>
              <div v-for="(data,idx) in interestInfoItems.filter(x => x.useYn === 'Y')" :key="idx"
                   @click="clickInterestSearch(idx)"
                   style="margin-right: 5px;">
                <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn"
                   :class="{'text-is-active': idx === activeIdx}" href="javascript:"><span
                    class="text">{{ data.interestInfoNm }}</span>
                </a>
              </div>
            </div>
            <div type="button" style="width: 35px; height: 30px; text-align: right; margin-left: auto;" @click="showInterestInfoModal = !showInterestInfoModal"><i
                class="icon-setting"></i>
            </div>
          </div>
        </div>
      </form>
      <HrdCloudMobileCateSnbV2 v-model="search" v-model:showHrdCloudCate="showHrdCloudCate" />

      <HrdCloudList
          :is-loading="isLoading"
          :session="session"
          :result="hrds"
          :show-put="true"
          :paging="paging"
          :paging-func="pagingFunc"
      >
        <template v-slot:top>
          <div class="list-top">
            <div class="top-column">
              <SortButtonSelect3
                  v-model="search.sortTyCdDcd"
                  v-model:toggle="toggles.sortTyCdDcd"
                  sequence-key="cd"
                  name-key="cdNm"
                  :options="sortTyCdDcd"
                  :is-num="false"
                  @selected="pagingFunc"
              />
            </div>
            <div class="top-column">
              <div class="list-breadcrumb">
                <HrdCloudBreadcrumb v-if="search.category1" v-model:search="search" />
              </div>
            </div>
          </div>
        </template>
      </HrdCloudList>
      <div class="main-content main-component" style="padding-left: 15px;">
        <article v-if="hrdShorts.length > 0 && !isLoading" class="content-section">
          <header class="section-header"><h4 class="title">숏클</h4></header>
          <HrdCloudMobileSwiper :items="hrdShorts" :session="session" :show-put="true" :is-shorts="true" :addShortsItems="addShortsItems" :swipeLoading="swipeLoading"/>
        </article>
      </div>
      </template>
    </template>



    <MobileBanerPopupModal
        v-if="popups.length > 0 && !expirationPopup && !lochref.includes('?')"
        :items="popups"
    />
    <HrdCloudMobileSubscribeNotice v-if="subscribeNotiPopupModal" v-model="subscribeNotiPopupModal"/>
    <HrdCloudEventStatusPopup v-if="eventStatusPopup" v-model="eventStatusPopup"/>


  </main>
</template>

<script>
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {
  getCateNm,
  getHrdCloudList,
  handleActions,
  // mainCustomList,
  setMainItems,
  sortTyCdDcds,
} from '@/assets/js/modules/hrd/hrd-common';
import {
  ACT_GET_HRDCLOUD_LATEST_LIST,
  ACT_GET_HRDCLOUD_LIST,
  ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST,
  ACT_GET_HRDCLOUD_RECOMMEND_LIST
  // ACT_GET_HRDCLOUD_RECENT_LIST,
  // ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST,
  // ACT_GET_HRDCLOUD_RECOMMEND_LIST,
  // ACT_GET_HRDCLOUD_RECENT_LIST,
  // ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST,
  // ACT_GET_HRDCLOUD_RECOMMEND_LIST,
} from '@/store/modules/prep/prep';
import {getDcdOptions, getListFunc, getValueByQuery, pushQuery, setFilterByQuery} from '@/assets/js/ui.init';
import {onBeforeRouteLeave, useRoute} from 'vue-router';
import {
  dateToDateFormat,
  getCheckItems, getItem,
  getItems, getPaging, isSuccess,
  lengthCheck,
  stringCheck,
  timestampToDateFormat
} from '@/assets/js/util';
import {ACT_GET_HRDCLOUD_CATE_SEARCH_LIST} from '@/store/modules/hrdcloud/hrdcloud';
// import HrdCloudMobileMainList from '@/components/hrdcloud/mobile/HrdCloudMobileMainList';
// import HrdCloudBanner from '@/components/hrdcloud/HrdCloudBanner';
// import HrdCloudMobileMy from '@/components/hrdcloud/mobile/HrdCloudMobileMy';
import HrdCloudMobileCloudMap from '@/views/pages/prep/mobile/HrdCloudMobileCloudMap';
// import HrdCloudMobileRoute from '@/components/hrdcloud/mobile/HrdCloudMobileRoute';
import HrdCloudMobileCategory from '@/components/hrdcloud/mobile/HrdCloudMobileCategory';
import {banerDivCdDcds, banerExpsrLocCdDcds, getBanerList} from '@/assets/js/modules/baner/common-baner';
import MobileBanerPopupModal from '@/components/main/mobile/MobileBanerPopupModal';
import HrdCloudHistoryMobileKbTube from '@/components/history/mobile/HrdCloudHistoryMobileKbTube';
import HrdCloudMobileSubscribeNotice from "@/components/hrdcloud/mobile/HrdCloudMobileSubscribeNotice.vue";
// import HrdCloudNotice from "@/components/hrdcloud/HrdCloudNotice";
import HrdCloudEventStatusPopup from "@/components/hrdcloud/HrdCloudEventStatusPopup";
import {
  getTodayDate
} from "@/assets/js/util";
// import {Swiper, SwiperSlide} from "swiper/vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
// import HrdCloudVisitor from "@/components/hrdcloud/HrdCloudVisitor.vue";
// import HrdCloudMobileSwiper from "@/components/hrdcloud/mobile/HrdCloudMobileSwiper.vue";
import {
  ACT_GET_SOC_BOARD_LIST,
  ACT_GET_SOC_CNT,
  ACT_GET_SOC_EXCELLENT_BOARD_LIST,
  ACT_GET_SOC_SUBSCRIBE_BOARD_LIST
} from "@/store/modules/ssl/ssl-index";
import {getDistCrseCd} from "@/assets/js/modules/ssl/ssl-common";
import {
  ACT_GET_USER_INTEREST_INFO_LIST, ACT_GET_USER_INTEREST_RECMD_INFO_LIST,
  ACT_INSERT_USER_INTEREST_INFO,
  ACT_UPDATE_USER_INTEREST_INFO
} from "@/store/modules/interest/interest";
import {useAlert} from "@/assets/js/modules/common/alert";
// import HrdCloudRoadmapCardV2 from "@/components/hrdcloud/HrdCloudRoadmapCardV2.vue";
import {moveSSL} from '@/assets/js/modules/common/common';
import {ACT_GET_BOARD_CATE_LIST, ACT_GET_BOARD_MST} from "@/store/modules/board/board-mst";
import {getPostList} from "@/assets/js/modules/board/board-common";
import router from "@/router";
import {sslRoutes, currentYear, sslCodes} from "@/assets/js/modules/ssl/ssl-common";
import {ACT_GET_HRDCLOUD_HISTORY_ATTENDANCE_LIST} from "@/store/modules/history/history";
import HrdCloudHistoryMobileAttendance from "@/components/history/mobile/HrdCloudHistoryMobileAttendance.vue";
// import {getGroupSum} from "@/assets/js/modules/mypage/talent-common";
import HrdCloudMobileSearchResultV2 from '@/components/hrdcloud/mobile/HrdCloudMobileSearchResultV2';
import HrdCloudMobileHeaderV2 from "@/components/hrdcloud/mobile/HrdCloudMobileHeaderV2.vue";
import HrdCloudMobileMyV2 from "@/components/hrdcloud/mobile/HrdCloudMobileMyV2.vue";
import HrdCloudMobileMainRoadmapList from "@/components/hrdcloud/mobile/HrdCloudMobileMainRoadmapList.vue";
import CloudMainMenu from "@/components/hrdcloud/mobile/HrdCloudMobileMainCategory.vue";
import HrdCloudList from "@/components/hrdcloud/HrdCloudList.vue";
import HrdCloudMobileCateSnbV2 from "@/components/hrdcloud/mobile/HrdCloudMobileCateSnbV2.vue";
import SortButtonSelect3 from "@/components/common/SortButtonSelect3.vue";
import HrdCloudBreadcrumb from "@/components/hrdcloud/HrdCloudBreadcrumb.vue";
import HrdCloudMobileSubMenuV2 from "@/components/hrdcloud/mobile/HrdCloudMobileSubMenuV2.vue";
import HrdCloudMobileSwiper from "@/components/hrdcloud/mobile/HrdCloudMobileSwiper.vue";

export default {
  name: 'HrdCloudMobileMainV2',
  components: {
    HrdCloudMobileSwiper,
    HrdCloudMobileSubMenuV2,
    HrdCloudBreadcrumb, SortButtonSelect3, HrdCloudMobileCateSnbV2, HrdCloudList,
    CloudMainMenu,
    HrdCloudMobileMainRoadmapList,
    HrdCloudMobileMyV2,
    HrdCloudMobileHeaderV2,
    HrdCloudHistoryMobileAttendance,
    // HrdCloudMobileSwiper,
    HrdCloudMobileSearchResultV2,
    LoadingDiv,
    HrdCloudMobileSubscribeNotice,
    // eslint-disable-next-line vue/no-unused-components
    HrdCloudMobileCategory,
    // HrdCloudMobileRoute,
    // eslint-disable-next-line vue/no-unused-components
    HrdCloudMobileCloudMap,
    // HrdCloudMobileMy,
    // HrdCloudBanner,
    // HrdCloudMobileMainList,
    MobileBanerPopupModal,
    HrdCloudHistoryMobileKbTube,
    // HrdCloudNotice,
    HrdCloudEventStatusPopup,
  },
  setup() {
    const store = useStore(), route = useRoute();
    const session = computed(() => store.state.auth.session);
    const sortTyCdDcd = computed(() => getDcdOptions('2073'));
    const isSearching = computed(() => (route.query && route.query.keyword && route.query.keyword.length > 0));
    const isLoading = ref(true);
    const hrdLoading = ref(true);
    const sslLoading = ref(true);
    const hottipLoading = ref(true);
    const swipeLoading = ref(false);
    const hotTipMyPreviewList = ref([]);
    const tmpHotTipItems = ref([]);

    const showRoute = ref(true),
        showSubMenu = ref(false),
        showHrdCloudMap = ref(false),
        showHrdCloudCate = ref(false),
        showKbTubeModal = ref(false),
        subscriptionModal = computed(() => store.state.hrdcloud.subscribed.show),
        addModal = computed(() => store.state.hrdcloud.added.show);
    const lochref = ref(location.href);

    const activeIdx = ref(-2);

    const isKbBank = computed(() => session.value.susdyCdDcd && (session.value.susdyCdDcd === '2109001'));

    const interestInfoItems = ref([]);
    const interestRecmdInfoItems = ref([]);
    const attendanceModal = ref(false);

    const interestInfoNms = ref("");

    const showInterestInfoModal = ref(false);

    const isSearch = ref(false);

    const {showConfirm, showMessage} = useAlert();

    const sslInfo = sslRoutes;

    const sslCurrentYear = currentYear;
    const sslTarget = sslCodes[sslCurrentYear];

    let boardMst = null;
    let boardCateList = [{cateNm: '전체', boardCateSn: ''}];

    const attendanceCnt = ref(0);

    const isSearchEnd = ref(false);
    const selectedMenu = ref(0);

    const mainList = ref([
      { name: 'recommend', title: '지금 나에게 필요한 콘텐츠', params: {category1:1}, isLoading: true, items:[], url: `prep/${ACT_GET_HRDCLOUD_RECOMMEND_LIST}` },
      {
        name: 'latest',
        title: '따끈따끈 신규 콘텐츠',
        params: {category1: 2},
        isLoading: true,
        items: [],
        url: `prep/${ACT_GET_HRDCLOUD_LATEST_LIST}`
      },
      { name: 'latestPopularDuty', title: '최근 직무 분야 인기 콘텐츠', params: {category1:1}, isLoading: true, items:[], url: `prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}` },
      { name: 'latestPopularKnowledge', title: '최근 지식 분야 인기 콘텐츠', params: {category1:2}, isLoading: true, items:[], url: `prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}` },
      // { name: 'recent', title: '나의 최근 학습 콘텐츠', params: {}, isLoading: true, items:[], url:  `prep/${ACT_GET_HRDCLOUD_RECENT_LIST}` }
    ]);

    console.log('sslRoute = ', sslInfo);
    console.log("sslCurrentYear = ", sslCurrentYear);
    // console.log('sslRoute = ', sslInfo.bestNote.lrnTrgtGrpMstSns);
    console.log("sslCodes = ", sslCodes[sslCurrentYear]);

    const lrnTrgtGrpMstSns = ref([]);

    Object.keys(sslTarget).forEach(k => {
      if(k !== 'trivia' && k !== 'book') {
        // if( k === 'book'){
        //   arr.push(getBookGroupSum(target, targetSSLCodes[k].refGroup.map(x => x.lrnTrgtGrpMstSn)));
        // }else{
        // arr.push(getGroupSum(target, sslTarget[k].group.map(x => x.lrnTrgtGrpMstSn)));
        // console.log("sslTarget = ", sslTarget['book'].group[0].lrnTrgtGrpMstSn);
        // console.log("sslTarget[k].group.map(x => x.lrnTrgtGrpMstSn)", sslTarget[k].group.map(x => x.lrnTrgtGrpMstSn));
        lrnTrgtGrpMstSns.value.push(sslTarget[k].group[0].lrnTrgtGrpMstSn);
      }
      // }
    });
    const getMainList = async (idx) => {
      hrdLoading.value = true;
      if (mainList.value[idx].items.length != 0) {
        hrdCloudItems.value = mainList.value[idx].items;
        hrdLoading.value = false;
        return ;
      }
      hrdCloudItems.value = [];
      if (idx === 0) {
        let latest = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_LATEST_LIST}`, {...paging});
        setMainItems(mainList, latest, idx);
      } else if (idx === 1) {
        let recommend = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECOMMEND_LIST}`, {...paging});
        setMainItems(mainList, recommend, idx);
      } else if (idx === 2) {
        let latestPopularDuty = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}`, {category1: mainList.value[idx].params.category1, ...paging});
        setMainItems(mainList, latestPopularDuty, idx);
      } else if (idx === 3) {
        let latestPopularKnowledge = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}`, {category1: mainList.value[idx].params.category1, ...paging});
        setMainItems(mainList, latestPopularKnowledge, idx);
      }
      hrdCloudItems.value = mainList.value[idx].items;
      hrdLoading.value = false;
      // let recent = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECENT_LIST}`, {pageNo: 1, pageSize: 4});
      // setMainItems(mainList, recent, 4);
    };
    // const lrnTrgtGrpMstSns = ref([]);

    const addMainList = async (idx) => {
      console.log("idx = ", idx);
      console.log("idx = ", paging);
      let res;
      if (idx === 0) {
        res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_LATEST_LIST}`, {...paging});
      } else if (idx === 1) {
        res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECOMMEND_LIST}`, {...paging});
      } else if (idx === 2) {
        res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}`, {category1: mainList.value[idx].params.category1, ...paging});
      } else {
        res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}`, {category1: mainList.value[idx].params.category1, ...paging});
      }
      if (getItems(res).length === 0) {
        isSearchEnd.value = true;
        paging.pageNo--;
      }
      hrdCloudItems.value = hrdCloudItems.value.concat(getItems(res));
      hrdLoading.value = false;
      console.log("res = ", getItems(res));
    };

    const selectedMenuFunc = async  (idx) => {
      activeIdx.value = -2;
      if (selectedMenu.value === idx && !isSearch.value) {
        return ;
      }
      selectedMenu.value = idx;
      paging.pageNo = 1;
      getMainList(idx);
      isSearchEnd.value = false;
      isSearch.value = false;
    }
    const debounce = ref(0);
    const timeout = ref(1500);

    const handleFilterQuery = () => {
      console.log("rout!!");
      if (route.name === 'HrdCloud' || route.name === 'HrdCloudRoute') {
        if (route.query) {
          if (route.query.keyword != null && route.query.keyword.length > 0) {
            isLoading.value = true;
            setFilterByQuery(search, {keyword: route.query.keyword});
            paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
            debounce.value = setTimeout(() => {
              if (debounce.value) clearTimeout(debounce.value);
              getList();
            },timeout.value);
          } else {
            isLoading.value = true;
            if (!route.query.sortTyCdDcd) {
              route.query.sortTyCdDcd = sortTyCdDcds.SORT_TY_CD_LATEST;
            }
            setFilterByQuery(search, route.query);
            if (route.query.category1 == 1) {
              tabMenu.value = 1;
            } else if (route.query.category1 == 2) {
              tabMenu.value = 2;
            } else if (route.query.category1 == 3) {
              tabMenu.value = 3;
            } else if (route.query.category1 == 4) {
              tabMenu.value = 4;
            } else if (route.query.category1 == 5) {
              tabMenu.value = 5;
            } else {
              tabMenu.value = 0;
            }
            if (tabMenu.value === 0) {
              debounce.value = setTimeout(() => {
                if (debounce.value) clearTimeout(debounce.value);
                getMainList(0);
              },timeout.value);
            } else {
              paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
              debounce.value = setTimeout(() => {
                if (debounce.value) clearTimeout(debounce.value);
                getList();
              },timeout.value);
            }
          }
        }
      }
    };


    // const latestItems = ref([]);
    // const popularDutyItems = ref([]);
    // const recommendedItems = ref([]);
    const hrdCloudItems = ref([]);

    // const getHrdCloudList = async () => {
    //   const res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_LATEST_LIST}`, {...paging});
    //   hrdCloudLatestItems.value = hrdCloudLatestItems.value.concat(getItems(res));
    //   hrdCloudItems.value = hrdCloudItems.value.concat(getItems(res));
    //   hrdLoading.value = false;
    // };

    const getInterestInfoList = async () => {
      console.log("!!!!!!!!!")
      await store.dispatch(`interest/${ACT_GET_USER_INTEREST_INFO_LIST}`, {
        lrnerId: session.value.lrnerId,
      }).then(res => {
        if (lengthCheck(res)) {
          interestInfoItems.value = getItems(res);
          interestInfoNms.value = "";
          for (const target of interestInfoItems.value) {
            if (target.useYn == 'Y') {
              interestInfoNms.value = interestInfoNms.value + "," + target.interestInfoNm;
            }
          }
          if (interestInfoNms.value == "") {
            interestInfoNms.value = "미설정";
          } else {
            interestInfoNms.value = interestInfoNms.value.substring(1);
          }
        }
        // console.log('승격예비::',gradeItems.value)
      }).catch(err => {
        console.error(err)
      })
    }

    const getInterestRecmdInfoList = async () => {
      console.log("!!!!!!!!!")
      await store.dispatch(`interest/${ACT_GET_USER_INTEREST_RECMD_INFO_LIST}`, {
        lrnerId: session.value.lrnerId,
      }).then(res => {
        if (lengthCheck(res)) {
          console.log("res = ", res);
          interestRecmdInfoItems.value = getItems(res);
        }
        // console.log('승격예비::',gradeItems.value)
      }).catch(err => {
        console.error(err)
      })
    }

    const getHotTipList = async (boardId) => {
      hottipLoading.value = true;
      await store.dispatch(`boardmst/${ACT_GET_BOARD_MST}`, boardId).then(async (res) => {
        boardMst = getItem(res);
        console.log("boardMst = ", boardMst);
        await store.dispatch(`boardmst/${ACT_GET_BOARD_CATE_LIST}`, boardMst.boardMstSn).then((res) => {
          boardCateList = boardCateList.concat(getItems(res));
          console.log("boardCateList = ", boardCateList);
        }).finally(async () => {
          await getPostList({
            boardMstSn: boardMst.boardMstSn,
            topFixYn: 'N',
            pageNo: 1,
            pageSize: 5
          }, tmpHotTipItems, null, boardCateList);
          hotTipMyPreviewList.value = tmpHotTipItems.value;
        })
      });

      hottipLoading.value = false;

    }

    const moveToBoardDtl = (item) => {
      router.push({name: 'BoardView', params: {'boardId': item.boardId, 'boardSecuKey': item.boardSecuKey}});
    }

    const topicSelected = async (item) => {
      if (item.useYn != "Y") {
        showConfirm({
          title: '관심 주제 설정 [' + item.interestInfoNm + ']',
          text: '나의 관심 주제로 설정하시겠습니까?',
          callback: async () => {
            await store.dispatch(`interest/${ACT_INSERT_USER_INTEREST_INFO}`, {
              interestInfoCdDcd: item.interestInfoCdDcd,
              interestInfoNm: item.interestInfoNm,
            }).then(res => {
              if (isSuccess(res)) {
                showMessage("설정이 완료됐습니다.");
                getInterestInfoList();
              }
              // console.log('승격예비::',gradeItems.value)
            }).catch(err => {
              console.error(err)
            })
          }
        });
      } else {
        showConfirm({
          title: '관심 주제 해제 [' + item.interestInfoNm + ']',
          text: '나의 관심 주제를 해제하시겠습니까?',
          callback: async () => {
            await store.dispatch(`interest/${ACT_UPDATE_USER_INTEREST_INFO}`, {
              interestInfoCdDcd: item.interestInfoCdDcd,
            }).then(res => {
              if (isSuccess(res)) {
                showMessage("설정이 완료됐습니다.");
                getInterestInfoList();
              }
              // console.log('승격예비::',gradeItems.value)
            }).catch(err => {
              console.error(err)
            })
          }
        });
      }
    }


    getInterestInfoList();
    getInterestRecmdInfoList();


    const roadmap = ref([
      {type: 'time', title: '관심', keys: ['bookLrnSec'], showDetail: true, data: 0},
      {type: 'day', title: '기업여신', keys: ['bookLrnDayCnt'], showDetail: false, data: 1},
      {type: 'memo', title: '퇴직연금', keys: ['bookMemoCnt'], showDetail: true, data: 2},
      {type: 'complete', title: '수신/카드', keys: ['bookCompleteCnt'], showDetail: false, data: 3},
    ]);

    const sslList = ref([
      {name: 'excellent', title: '인기', isLoading: true, items: []},
      {name: 'subscribe', title: '구독', isLoading: true, items: []},
    ]);

    const sslitems = ref([]);

    store.dispatch(`ssl/${ACT_GET_SOC_CNT}`).then(res => {
      if (res && res.cnt === 0) {
        sslList.value[1].title = '';
      }
    });

    const modalActive = computed(() => {
      if (subscriptionModal.value || addModal.value) return true;
      else return false;
    });

    const view = reactive({
      name: 'main',
      title: 'HRD 클라우드'
    });

    const sslCardFilter = ref('excellent');

    const excellentItems = ref([]);
    const subscribeItems = ref([]);

    const hrds = ref([]),
        hrdShorts = ref([]),
        categories = ref([]),
        tabMenu = ref(0),
        shortsPaging = reactive({
          pageNo: 1,
          pageSize: 10,
          totalCount: 0
        }),
        paging = reactive({
          pageNo: 1,
          pageSize: 10,
          totalCount: 0
        }),
        categoriesPaging = reactive({
          pageNo: 1,
          pageSize: 9999,
          totalCount: 0
        }),
        search = reactive({
          keyword: '',
          sortTyCdDcd: '',
          category1: '',
          category2: '',
          category3: '',
          category4: '',
          category5: ''
        }),
        toggles = reactive({
          sortTyCdDcd: false
        });

    const getList = async (params) => {
      hrdShorts.value = [];
      let contentsList = await getHrdCloudList({...search, ...paging, ...params});
      hrds.value = getCheckItems(contentsList);
      let shortsList = await getHrdCloudList({...search, ...paging, ...params, picRate: '9.16'});
      // hrds.value = getCheckItems(contentsList);
      hrdShorts.value = getCheckItems(shortsList);
      // console.log("shortsList = ", shortsList);

      paging.totalCount = contentsList && contentsList.paging ? contentsList.paging.totalCount : 0;
      let categoryList = await store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_CATE_SEARCH_LIST}`, {keyword: search.keyword, ...paging});
      categories.value = getCheckItems(categoryList);
      categoriesPaging.totalCount = categoryList && categoryList.paging ? categoryList.paging.totalCount : 0;
      isLoading.value = false;
    };

    const addShortsItems = async () => {
      swipeLoading.value = true;
      shortsPaging.pageNo++;
      let shortsList = await getHrdCloudList({...search, ...shortsPaging, picRate: '9.16'});
      // hrds.value = getCheckItems(contentsList);
      hrdShorts.value = hrdShorts.value.concat(getCheckItems(shortsList));
      swipeLoading.value = false;
    };

    const getShortsList = async () => {
      let shortsContentsList = await getHrdCloudList({picRate: '9.16', ...paging});
      hrdShorts.value = getCheckItems(shortsContentsList);
      console.log("hrdShorts.value ", hrdShorts.value);
    }

    getShortsList();

    const clickInterestSearch = (idx) => {
      console.log("res = ", idx);
      if (idx === -1 && interestInfoNms.value === "미설정") {
        showConfirm('관심주제를 설정 후 사용 가능합니다.<br> 설정 페이지로 이동하시겠습니까?', () => {
          showInterestInfoModal.value = true;
        });
        return;
      }

      if (activeIdx.value === idx) {
        resetSearch();
        return ;
      }

      activeIdx.value = idx;
      search.keyword = '';
      if (idx === -1) {
        searchKeyword(interestInfoNms.value.replaceAll(/[/,]/g, '|'));
        // search.keyword = interestInfoNms.value.replaceAll(/[/,]/g, '|');
      } else {
        searchKeyword(interestInfoItems.value.filter(x => x.useYn === 'Y')[idx].interestInfoNm);
        // search.keyword = interestRecmdInfoItems.value[idx].interestInfoNm;
      }
      // console.log("search.keyword = ", search.keyword);
      // searchKeyword();
    }

    const attendanceList = ref([]);

    getListFunc(`history/${ACT_GET_HRDCLOUD_HISTORY_ATTENDANCE_LIST}`, {year: new Date().getFullYear()}, attendanceList, null, () => {
      // attendanceList.value = attendanceList.value.map(x => ({...x, datePart: timestampToDatePart(x.regDt)}));
      // isLoading.value = false;
      let month = new Date().getMonth() + 1;
      attendanceCnt.value = attendanceList.value.filter(x => x.cmptnMonth === month).length;

    });

    const tmpKeyword = ref("");

    const searchKeyword = async (keyword) => {
      if (keyword.length === 0) {
        showMessage("검색어를 입력 바랍니다.");
      } else {
        tabMenu.value = 0;
        isSearch.value = true;
        if (search.keyword.length > 0) {
          activeIdx.value = -2;
        }
        hrdLoading.value = true;
        sslLoading.value = true;
        hottipLoading.value = true;
        tmpKeyword.value = keyword;
        paging.pageNo = 1;
        shortsPaging.pageNo = 1;
        hrdCloudItems.value = [];
        sslitems.value = [];
        hrdShorts.value = [];
        isSearchEnd.value = false;
        store.dispatch(`prep/${ACT_GET_HRDCLOUD_LIST}`,
            {keyword: keyword, pageSize: paging.pageSize}).then(res => {
          if (lengthCheck(res)) {
            hrdCloudItems.value = getItems(res);
          } else {
            hrdCloudItems.value = [];
          }
          hrdLoading.value = false;
        });

        store.dispatch(`prep/${ACT_GET_HRDCLOUD_LIST}`,
            {keyword: keyword, pageSize: paging.pageSize, picRate: '9.16'}).then(res => {
          if (lengthCheck(res)) {
            hrdShorts.value = getItems(res);
          } else {
            hrdShorts.value = [];
          }
        });

        store.dispatch(`ssl/${ACT_GET_SOC_BOARD_LIST}`,
            {lrnTrgtGrpMstSns: lrnTrgtGrpMstSns.value.join(','), pageSize: paging.pageSize, keyword: keyword}).then(res => {
          if (lengthCheck(res)) {
            sslitems.value = getItems(res);
          } else {
            sslitems.value = [];
          }
          sslLoading.value = false;
        });

        await getPostList({
          word: keyword,
          boardMstSn: boardMst.boardMstSn,
          topFixYn: 'N',
          pageSize: 5
        }, hotTipMyPreviewList, null, boardCateList);
        hottipLoading.value = false;
      }
    }

    const toggleModal = {
      openSubscribe: () => {
        subscriptionModal.value = true;
      }
    };


    let nowDate = new Date();
    const expirationPopup = computed(() => parseInt(localStorage.getItem('kb-popup-hrdcloud')) > nowDate);

    const getsslCardList = async (sslCardFilter) => {
      console.log("sslCardFilter = ", sslCardFilter);
      sslLoading.value = true;
      if (sslCardFilter.value === 'excellent') {
        if (!stringCheck(excellentItems.value)) {
          await store.dispatch(`ssl/${ACT_GET_SOC_EXCELLENT_BOARD_LIST}`).then(res => {
            if (lengthCheck(res)) {
              excellentItems.value = getItems(res).map(x => ({
                ...x,
                date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
                day: timestampToDateFormat(x.pblcnDt, 'dd'),
                distCrseCd: getDistCrseCd(x.distCrseSn)
              }));
            }
          });
        }
        sslitems.value = excellentItems.value;
      } else if (sslCardFilter.value === 'subscribe') {
        if (!stringCheck(subscribeItems.value)) {
          await store.dispatch(`ssl/${ACT_GET_SOC_SUBSCRIBE_BOARD_LIST}`).then(res => {
            if (lengthCheck(res)) {
              console.log("res.value");
              console.log(getItems(res));
              subscribeItems.value = getItems(res).map(x => ({
                ...x,
                date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
                day: timestampToDateFormat(x.pblcnDt, 'dd'),
                distCrseCd: getDistCrseCd(x.distCrseSn)
              }));
              console.log("subscribe.value");
              console.log(sslitems.value);
            }
          });
        }
        sslitems.value = subscribeItems.value;
      } else {
        await store.dispatch(`ssl/${ACT_GET_SOC_BOARD_LIST}`, {
          year: '2024',
          pageSize: 10,
        }).then(res => {
          if (lengthCheck(res)) sslitems.value.push(...getItems(res));
          const resPaging = getPaging(res);
          paging.totalCount = resPaging.totalCount;
          paging.hasMore = resPaging.hasMore;

          isLoading.value = false;
        }).catch(() => {
          isLoading.value = false;
        });
      }
      sslLoading.value = false;
    };

    watch(() => sslCardFilter.value, () => {
      getsslCardList(sslCardFilter);
    });

    watch(() => route.query, () => {
      handleFilterQuery();
    });



    onBeforeRouteLeave(() => {
      console.log("!!");
    });

    onBeforeUnmount(() => {
      console.log("!!");
    });

    onMounted(()=>{
      console.log("!!");
    });




    getsslCardList(sslCardFilter);
    getHotTipList('hottip-my');
    getMainList(0);

    // const handleFilterQuery = () => {
    //   if (route.name === 'HrdCloud' || route.name === 'HrdCloudRoute') {
    //     if (route.query) {
    //       if (route.query.keyword != null && route.query.keyword.length > 0) {
    //         isLoading.value = true;
    //         setFilterByQuery(search, {keyword: route.query.keyword});
    //         paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
    //         debounce.value = setTimeout(() => {
    //           if (debounce.value) clearTimeout(debounce.value);
    //           getList();
    //         }, timeout.value);
    //       } else {
    //         isLoading.value = true;
    //         if (!route.query.sortTyCdDcd) {
    //           route.query.sortTyCdDcd = sortTyCdDcds.SORT_TY_CD_LATEST;
    //         }
    //         setFilterByQuery(search, route.query);
    //         if (route.query.category1 == 1) {
    //           tabMenu.value = 1;
    //         } else if (route.query.category1 == 2) {
    //           tabMenu.value = 2;
    //         } else if (route.query.category1 == 3) {
    //           tabMenu.value = 3;
    //         } else if (route.query.category1 == 4) {
    //           tabMenu.value = 4;
    //         } else if (route.query.category1 == 5) {
    //           tabMenu.value = 5;
    //         } else {
    //           tabMenu.value = 0;
    //         }
    //         if (tabMenu.value === 0) {
    //           debounce.value = setTimeout(() => {
    //             if (debounce.value) clearTimeout(debounce.value);
    //             getMainList();
    //           }, timeout.value);
    //         } else {
    //           paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
    //           debounce.value = setTimeout(() => {
    //             if (debounce.value) clearTimeout(debounce.value);
    //             getList();
    //           }, timeout.value);
    //         }
    //       }
    //     }
    //   }
    // };

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      if (pageNo) {
        pushQuery({...search, pageNo});
      } else {
        pushQuery({...search});
      }
    };

    const handleMenuClick = (tabMenu) => {
      hrds.value = [];
      // initParams(search);
      search.sortTyCdDcd = sortTyCdDcds.SORT_TY_CD_LATEST;
      if (tabMenu === 1) {
        search.category1 = 1;
      } else if (tabMenu === 2) {
        search.category1 = 2;
      } else if (tabMenu === 3) {
        search.category1 = 3;
      } else if (tabMenu === 4) {
        search.category1 = 4;
      } else if (tabMenu === 5) {
        search.category1 = 5;
      }
      pagingFunc(1);
    };

    const searchFunc = async () => {
      await getList();
    };

    const renderTitle = computed(() => {
      if (tabMenu.value > 0) return getCateNm(search.category1);
      else return view.title;
    });

    const prev = ref(0);

    // const scrollFunction = () => {
    //   showSubMenu.value=false;
    //   showHrdCloudCate.value=false;
    //   const curr = window.pageYOffset;
    //   if (prev.value >= curr) showRoute.value=true
    //   else showRoute.value=false;
    //   prev.value = curr;
    // };

    const subscribeNotiPopupModal = ref(true);
    const eventStatusPopup = ref(true);

    const checkEventFlag = () => {
      console.log("checkEventFlag", dateToDateFormat(new Date(), 'yyyyMMdd'), dateToDateFormat(new Date(), 'yyyyMMdd') <= '20231130')
      return dateToDateFormat(new Date(), 'yyyyMMdd') <= '20231130';
    }

    eventStatusPopup.value = checkEventFlag();

    watch(() => tabMenu.value, () => {
      subscribeNotiPopupModal.value = tabMenu.value === 0;
      eventStatusPopup.value = tabMenu.value === 0 ? checkEventFlag() : false;
    });

    onMounted(() => {
      prev.value = window.pageYOffset;
      handleFilterQuery();
    });

    onBeforeUnmount(() => {
      // document.removeEventListener('scroll', scrollFunction);
    });
    // localStorage.removeItem('kb-popup-main');



    const addSearchItems = async (keyword) => {
      console.log("addSearchItems keyword = ", keyword);
      console.log("paging = ", paging);
      tmpKeyword.value = keyword;
      store.dispatch(`prep/${ACT_GET_HRDCLOUD_LIST}`,
          {keyword: keyword, pageSize: paging.pageSize, pageNo: paging.pageNo}).then(res => {
        if (lengthCheck(res)) {
          hrdCloudItems.value = hrdCloudItems.value.concat(getItems(res));
        } else {
          isSearchEnd.value = true;
        }
        hrdLoading.value = false;
      });

      // store.dispatch(`ssl/${ACT_GET_SOC_BOARD_LIST}`,
      //     {lrnTrgtGrpMstSns: lrnTrgtGrpMstSns.value.join(','), pageSize: paging.pageSize, keyword: keyword}).then(res => {
      //   if (lengthCheck(res)) {
      //     sslitems.value = getItems(res);
      //   } else {
      //     sslitems.value = [];
      //   }
      //   sslLoading.value = false;
      // });
    }


    onscroll = (event) => {
      // console.log("scroll!!", event.target.getElementById('kb-hrdcloud-v2').scrollHeight);
      // console.log("scroll!!", window.scrollY);
      // console.log("scroll!!", window.innerHeight);

      if (event.target.getElementById('kb-hrdcloud-v2') && window.scrollY + window.innerHeight >= (event.target.getElementById('kb-hrdcloud-v2').scrollHeight * 0.9)
          && !hrdLoading.value && !isSearchEnd.value && tabMenu.value === 0) {
        paging.pageNo++;
        hrdLoading.value = true;

        if (isSearch.value) {
          if (activeIdx.value === -1) {
            addSearchItems(interestInfoNms.value.replaceAll(/[/,]/g, '|'));
          } else if (activeIdx.value === -2){
            addSearchItems(search.keyword);
          } else {
            addSearchItems(interestInfoItems.value.filter(x => x.useYn === 'Y')[activeIdx.value].interestInfoNm);
          }
          return;
        } else  {
          addMainList(selectedMenu.value);
        }
      }
    }

    const handleScroll = (event) => {
      const scrollContainer = event.target;
      console.log("scrollContainer", scrollContainer);
    };

    const resetSearch = () => {
      activeIdx.value = -2;
      search.keyword = '';
      sslitems.value = excellentItems.value;
      hotTipMyPreviewList.value = tmpHotTipItems.value;
      isSearch.value = false;
      paging.pageNo = 1;
      shortsPaging.pageNo = 1;
      isSearchEnd.value = false;
      hrdCloudItems.value = mainList.value[selectedMenu.value].items;
    }


    const popups = ref([]);
    // 팝업
    getBanerList({
      banerDivCdDcd: banerDivCdDcds.popup,
      banerExpsrLocCdDcd: banerExpsrLocCdDcds.hrdCloud,
      pageNo: 1,
      pageSize: 10
    }, popups);

    return {
      handleScroll,
      isSearching,
      isLoading,
      view,
      session,
      search,
      mainList,
      tabMenu,
      toggles,
      hrds,
      paging,
      toggleModal,
      categories,
      lochref,
      modalActive,
      subscriptionModal,
      addModal,
      showSubMenu,
      showHrdCloudMap,
      showRoute,
      showHrdCloudCate,
      categoriesPaging,
      pagingFunc,
      handleActions,
      searchFunc,
      handleMenuClick,
      sortTyCdDcd,
      getCateNm,
      renderTitle,
      popups,
      expirationPopup,
      showKbTubeModal,
      subscribeNotiPopupModal,
      eventStatusPopup,
      roadmap,
      activeIdx,
      getTodayDate,
      isKbBank,
      sslList,
      sslLoading,
      sslitems,
      interestInfoItems,
      getInterestRecmdInfoList,
      interestInfoNms,
      showInterestInfoModal,
      topicSelected,
      sslCardFilter,
      hrdCloudItems,
      hrdLoading,
      moveSSL,
      searchKeyword,
      clickInterestSearch,
      hottipLoading,
      hotTipMyPreviewList,
      moveToBoardDtl,
      isSearch,
      attendanceCnt,
      attendanceModal,
      tmpKeyword,
      selectedMenu,
      selectedMenuFunc,
      resetSearch,
      hrdShorts,
      addShortsItems,
      swipeLoading
    }
  }
};
</script>
