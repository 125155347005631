<template>
  <main class="kb-main" id="kb-hrdcloud" @click="closeToggles(toggles)">
    <HrdCloudSearchResult v-if="isSearching"
                          :keyword="search.keyword"
                          :isLoading="isLoading"
                          :session="session"
                          :hrds="hrds"
                          :categories="categories"
                          :categories-paging="categoriesPaging"
                          :paging="paging"
                          :paging-func="pagingFunc">
      <template  v-slot:bottom v-if="justShortsItems.items.length> 0">
        <article class="content-section">
          <header class="section-header"><h3 class="title">{{ justShortsItems.title }}</h3>
            <div class="header-sub">
              <router-link :to="{name: 'HrdCloud', query:{sortTyCdDcd: '2073001',category1:'-99'}}" class="more-link">전체보기</router-link>
            </div>
          </header>
          <template v-if="justShortsItems.isLoading">
            <LoadingDiv v-model="justShortsItems.isLoading" />
          </template>
          <template v-else-if="justShortsItems.items && justShortsItems.items.length > 0">
            <HrdCloudSwiper :items="justShortsItems.items" :show-put="true" :session="session" :is-shorts="justShortsItems.name=='shorts'" />
          </template>

        </article>
      </template>
    </HrdCloudSearchResult>
    <template v-else>
      <lxp-main-header :show-breadcrumb="true" :show-title="true" :show-visitor-cnt="true">
        <template v-slot:inner>
          <HrdCloudVisitor />
        </template>
        <template v-slot:action>
          <div class="header-search page-component">
            <form @submit.prevent class="kb-form-search-forms">
              <div class="kb-form-search">
                <div class="kb-form-search-field">
                  <input type="text" class="kb-form-search-input" v-model.trim="search.keyword" placeholder="HRD 클라우드 검색" @keyup.prevent.stop.enter="pagingFunc()">
                  <button type="button" class="kb-form-search-btn" @click="pagingFunc()" ><i class="icon-search">검색</i></button>
                </div>
              </div>
            </form>
            <button type="button" class="kb-btn kb-btn-light-green kb-btn-sm rounded-lg" @click="showLearningRoadmapMainModal = !showLearningRoadmapMainModal">
              <i class="icon-map"></i>
              <span class="text">학습 로드맵</span>
            </button>
            <button v-if="false" type="button" class="kb-btn kb-btn-cream kb-btn-sm rounded-lg" @click="cloudMapModal=!cloudMapModal">
              <i class="icon-map"></i>
              <span class="text">클라우드 Map</span>
            </button>
            <button type="button" class="kb-btn kb-btn-lightblue kb-btn-sm rounded-lg" @click.stop="$router.push({ name:'HistoryHrdCloud' })">
              <i class="icon-player"></i>
              <span class="text">My 클라우드</span>
            </button>
            <button type="button" class="kb-btn kb-btn-pink kb-btn-sm rounded-lg" @click="uploadModal.toggle()">
              <i class="icon-upload"></i>
              <span class="text">Hot Tube 등재</span>
            </button>
          </div>
          <HrdCloudRoute :is-loading="isLoading"
                         v-model:lvl1Sn="tabMenu"
                         v-model:toggle="toggles.lvl1Toggle"
                         :func="handleMenuClick" />
        </template>
      </lxp-main-header>
      <div class="main-content main-component">
        <template v-if="tabMenu == 0">
          <div class="intro-section">
            <HrdCloudBanner />
            <HrdCloudMy :session="session" />
          </div>

          <HrdCloudNotice />

          <!-- 학습로드맵 -->
          <article v-if="learningRoadmapList" class="content-section section-divider">
            <header class="section-header">
              <h3 class="title">학습 로드맵</h3>
              <div class="header-sub" @click.stop="showLearningRoadmapMainModal = !showLearningRoadmapMainModal"><span class="more-link kb-mouse-cursor">전체보기</span></div>
            </header>
            <div class="section-body">
              <swiper v-if="learningRoadmapList.length > 0"
                      class="hrdcloud-swiper"
                      :modules="modules"
                      slides-per-view="auto"
                      :speed="750"
                      :navigation="{
                      prevEl: '.swiper-btn-prev',
                      nextEl: '.swiper-btn-next'
                  }"
              >
                <swiper-slide v-for="(roadmap,idx) in learningRoadmapList" :key="idx" class="roadmap-card-slide">
                  <HrdCloudRoadmapCardV2 :data="roadmap.data" v-model="showLearningRoadmapMainModal" />
                </swiper-slide>
                <button class="swiper-btn-prev">
                  <i class="icon-arrow">prev</i>
                </button>
                <button class="swiper-btn-next">
                  <i class="icon-arrow">next</i>
                </button>
              </swiper>
              <!--              <div v-if="learningRoadmapList.length > 0" class="d-flex justify-content-evenly">-->
              <!--                <template v-for="(roadmap,idx) in learningRoadmapList" :key="idx">-->
              <!--                  <HrdCloudRoadmapCard :data="roadmap.data" />-->
              <!--                </template>-->
              <!--              </div>-->
              <LoadingDiv v-else />
            </div>
          </article>

          <!-- content-section:지금 나에게 필요한 콘텐츠 -->
          <template v-for="(item, idx) in mainList" :key="idx">
            <article class="content-section" :class="{'section-divider' : idx > -1}">
              <header class="section-header"><h3 class="title">{{ item.title }}</h3>
                <div class="header-sub">
                  <router-link :to="item.name==='shorts' ? {name: 'HrdCloud', query:{sortTyCdDcd: '2073001',category1: '-99'}} : {path: `hrdcloud/${item.name}`}" class="more-link">전체보기</router-link>
                </div>
              </header>
              <template v-if="item.items && item.items.length > 0">
                <HrdCloudSwiper :items="item.items" :show-put="true" :session="session" :is-shorts="item.name=='shorts'" />
              </template>
              <template v-else-if="(item.items && item.items.length === 0) && !item.isLoading">
                <div class="search-container">
                  <div class="result-empty">
                    <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
                    <p v-if="item.name === 'recommend'" class="text">해당 직무 추천 콘텐츠가 없습니다.</p>
                    <p v-else-if="item.name === 'latest' || item.name === 'shorts'" class="text">신규 콘텐츠가 없습니다.</p>
                    <p v-else-if="item.name === 'recent'" class="text">최근 학습 콘텐츠가 없습니다.</p>
                    <p v-else-if="item.name === 'latestPopularDuty' || item.name === 'latestPopularKnowledge'" class="text">최근 콘텐츠가 없습니다.</p>
                  </div>
                </div>
              </template>
              <template v-else-if="item.isLoading">
                <LoadingDiv v-model="item.isLoading" />
              </template>
            </article>
          </template>
        </template>
        <template v-else-if="tabMenu !== 0">
          <!--          <div v-if="search.category3 && search.category3 == 1755" class="page-buttons" style="margin-top: 1px;">-->
          <!--            <a v-if="search.category4 && search.category4 == 1756" class="kb-btn kb-btn-cream kb-btn-lg" @click="goProgrammers('pcce')"><span class="text">PCCE 체험하기</span></a>-->
          <!--            <a v-if="search.category4 && search.category4 == 1759" class="kb-btn kb-btn-cream kb-btn-lg" @click="goProgrammers('pccp')"><span class="text">PCCP 체험하기</span></a>-->
          <!--          </div>-->
          <HrdCloudList :is-loading="isLoading"
                        :session="session"
                        :result="hrds"
                        :show-put="true"
                        :paging="paging"
                        :paging-func="pagingFunc"
                        :is-shorts="isShorts">
            <template v-slot:top>
              <div class="list-top">
                <div class="top-column">
                  <div class="list-sort">
                    <div class="sort">
                      <SortButtonSelect
                          v-if="hrds && hrds.length > 0 && !isLoading"
                          v-model="search.sortTyCdDcd"
                          v-model:toggle="toggles.sortTyCdDcd"
                          :options="sortTypeDcd"
                          sequence-key="cd"
                          name-key="cdNm"
                          :width="138"
                          @selected="pagingFunc"
                      />
                    </div>
                    <div class="list-tab">
                      <HrdCloudRouteForShorts v-if="isShorts" />
                    </div>
                  </div>


                </div>
                <div class="top-column">
                  <div class="list-breadcrumb">
                    <HrdCloudBreadcrumb v-if="search.category1" v-model:search="search" />
                  </div>
                </div>
              </div>
            </template>

            <!--shorts가 아닌 카테고리의 경우 해당 영역의 shorts 콘텐츠 노출 -->
            <template  v-slot:bottom v-if="!isShorts && justShortsItems.items.length> 0">
              <article class="content-section">
                <header class="section-header"><h3 class="title">{{ justShortsItems.title }}</h3>
                  <div class="header-sub">
                    <router-link :to="{name: 'HrdCloud', query:{sortTyCdDcd: '2073001',category1: '-99'}}" class="more-link">전체보기</router-link>
                  </div>
                </header>
                <template v-if="justShortsItems.isLoading">
                  <LoadingDiv v-model="justShortsItems.isLoading" />
                </template>
                <template v-else-if="justShortsItems.items && justShortsItems.items.length > 0">
                  <HrdCloudSwiper :items="justShortsItems.items" :show-put="true" :session="session" :is-shorts="justShortsItems.name=='shorts'" />
                </template>

              </article>
            </template>
          </HrdCloudList>
        </template>
        <HrdCloudMap2 v-model="cloudMapModal"/>
        <HrdCloudRoadmapMainModal v-model="showLearningRoadmapMainModal" :last-job-info="lastJobInfo" :dty-info="aiResult[0]" />
      </div>
    </template>
    <BanerPopupModal
        v-if="popups.length > 0 && !expirationPopup && !lochref.includes('?')"
        :items="popups"
    />
    <KbTubeUpload v-if="uploadModal.isActive" v-model="uploadModal" />
    <Attendance v-if="attendanceModal" v-model="attendanceModal" />
    <HrdCloudSubscribePopup v-if="subscribeNotiPopupModal" v-model="subscribeNotiPopupModal"/>
    <HrdCloudEventStatusPopup v-if="eventStatusPopup" v-model="eventStatusPopup"/>
  </main>
</template>

<script>
import {useStore} from 'vuex';
import {computed, onMounted, reactive, ref, watch} from 'vue';
import LxpMainHeader from '@/components/common/LxpMainHeader';
import HrdCloudRoute from '@/components/hrdcloud/HrdCloudRoute';
import HrdCloudMy from '@/components/hrdcloud/HrdCloudMy';
import HrdCloudBanner from '@/components/hrdcloud/HrdCloudBanner';
import HrdCloudSwiper from "@/components/hrdcloud/HrdCloudSwiper";
import {
  closeToggles,
  getDcdOptions, getListFunc,
  getValueByQuery,
  pushQuery,
  setFilterByQuery
} from '@/assets/js/ui.init';
import {
  dateToDateFormat,
  getCheckItems,
  initParams,
  initProgressBar,
  lengthCheck,
  stringCheck,
  winLearnOpen
} from '@/assets/js/util';
import {
  getHrdCloudList,
  mainCustomList,
  setMainItems,
  sortTyCdDcds
} from '@/assets/js/modules/hrd/hrd-common';
import {useRoute} from 'vue-router';
import {
  ACT_GET_HRDCLOUD_LATEST_LIST,
  ACT_GET_HRDCLOUD_RECENT_LIST,
  ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST,
  ACT_GET_HRDCLOUD_RECOMMEND_LIST,
} from '@/store/modules/prep/prep';
import HrdCloudList from '@/components/hrdcloud/HrdCloudList';
import SortButtonSelect from '@/components/common/SortButtonSelect';
import HrdCloudSearchResult from '@/components/hrdcloud/HrdCloudSearchResult';
import HrdCloudVisitor from '@/components/hrdcloud/HrdCloudVisitor';
import LoadingDiv from '@/components/common/LoadingDiv';
import HrdCloudBreadcrumb from '@/components/hrdcloud/HrdCloudBreadcrumb';
import {
  ACT_GET_HRDCLOUD_CATE_SEARCH_LIST,
} from '@/store/modules/hrdcloud/hrdcloud';
import HrdCloudMap2 from '@/components/hrdcloud/HrdCloudMap2';
import {banerDivCdDcds, banerExpsrLocCdDcds, getBanerList,} from '@/assets/js/modules/baner/common-baner';
import BanerPopupModal from '@/components/main/BanerPopupModal';
import KbTubeUpload from '@/components/history/hrdcloud/KbTubeUpload';
import HrdCloudSubscribePopup from "@/components/hrdcloud/HrdCloudSubscribePopup.vue";
import HrdCloudNotice from "@/components/hrdcloud/HrdCloudNotice";
import HrdCloudEventStatusPopup from "@/components/hrdcloud/HrdCloudEventStatusPopup";
import HrdCloudRoadmapCardV2 from '@/components/hrdcloud/HrdCloudRoadmapCardV2';
import HrdCloudRoadmapMainModal from '@/components/hrdcloud/HrdCloudRoadmapMainModal';
import {ACT_GET_SSO_PROGRAMMERS_URL} from '@/store/modules/mypage/mypage';
import {ACT_GET_USER_RECOMMEND_LSIT, ACT_GET_USER_WORK_PERIOD} from "@/store/modules/my/my";
import {
  getHrdCloudLearningRoadmapSortedList
} from "@/assets/js/modules/hrd/hrd-cloud-roadmap-setup";
import Attendance from "@/components/history/hrdcloud/Attendance";
import localstorageService from "@/assets/js/localstorage.service";
import {HRD_ATT_TOKEN} from "@/assets/js/modules/hrd/hrd-history-common";
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {Navigation} from 'swiper';
import HrdCloudRouteForShorts from "@/components/hrdcloud/HrdCloudRouteForShorts.vue";

export default {
  name: 'HrdCloudMain',
  components: {
    HrdCloudRouteForShorts,
    HrdCloudNotice,
    HrdCloudSubscribePopup,
    HrdCloudEventStatusPopup,
    HrdCloudMap2,
    HrdCloudBreadcrumb,
    LoadingDiv,
    HrdCloudVisitor,
    HrdCloudSearchResult,
    SortButtonSelect,
    HrdCloudList,
    // HrdCloudRow,
    HrdCloudSwiper,
    HrdCloudBanner, HrdCloudMy, HrdCloudRoute, LxpMainHeader,
    BanerPopupModal,
    KbTubeUpload,
    HrdCloudRoadmapCardV2,
    HrdCloudRoadmapMainModal,
    Attendance,
    Swiper,
    SwiperSlide,
  },
  setup() {
    initProgressBar();
    const store = useStore();
    const route = useRoute();
    const lochref = ref(location.href);
    const attendanceModal = ref(false);
    const checkTime = () => {
      const local = localstorageService.getToken(HRD_ATT_TOKEN);
      const now = new Date(), then = new Date(Number(local)) ;
      const diff = Math.round((now.getTime() - then.getTime()) / (60*60*1000));
      if (diff > 24) {
        attendanceModal.value = true;
        localstorageService.saveToken(HRD_ATT_TOKEN, new Date().getTime());
      } else {
        attendanceModal.value = false;
      }
    };


    checkTime();

    const cloudMapModal = ref(false);

    const isLoading = ref(false);
    const isSearching = computed(() => (route.query && route.query.keyword && route.query.keyword.length > 0));
    const session = computed(() => store.state.auth.session);
    const sortTypeDcd = computed(() => getDcdOptions('2073'));

    const isShorts = ref(false);

    // 순서를 변경할때 getMainList의 idx값도 같이 바꿔줘야함
    const mainList = ref(mainCustomList);
    const justShortsItems = ref(mainCustomList.filter(x => x.name==="shorts")[0]);

    const currentTs = ref(new Date().getTime());

    const hrds = ref([]);
    const categories = ref([]);
    const paging = reactive({
      pageNo: 1,
      pageSize: 8,
      totalCount: 0
    });
    const categoriesPaging = reactive({
      pageNo: 1,
      pageSize: 9999,
      totalCount: 0
    });

    // 콘텐츠명, 태그, 카테고리로 검색
    // 검색시 영문 대소문자 구분하지 않음
    const search = reactive({
      keyword: '',
      sortTyCdDcd: '',
      picRate: '16.9',
      category1: 0,
      category2: 0,
      category3: 0,
      category4: 0,
      category5: 0,
      pageNo: 0
    });

    const params = ref({});
    const tabMenu = ref(0);
    const toggles = reactive({
      sortTyCdDcd: false,
      lvl1Toggle: false
    });

    // KB Tube
    const uploadModal = reactive({
      isActive: false,
      toggle: () => {
        uploadModal.isActive = !uploadModal.isActive;
      },
    });

    const goProgrammers = (params) => {

      const session = store.state.auth.session;

      store.dispatch(`mypage/${ACT_GET_SSO_PROGRAMMERS_URL}`, params).then(res => {
        console.log(res);
        if (session.itn) {
          if(confirm('은행내에서 외부과정을 접속하시려면 KB인터넷PC의\n[인터넷PC-연수시스템]바로가기 아이콘을 이용하시기 바랍니다\n\n가상인터넷PC 실행화면으로 이동하시겠습니까?')) location.href="https://kbintpc.kbstar.com";
        }else {
          var item = {
            prvdrCd: "C000020",
            etnLrnUrl: res
          }
          console.log(item)
          winLearnOpen(item);
        }
      });
    };

    // 학습로드맵
    const learningRoadmapList = ref([]);
    const showLearningRoadmapMainModal = ref(false);
    const workData = ref([]);
    const lastJobInfo = ref(null);
    const aiResult = ref([]);
    const getlearningRoadmapData = () => {
      getHrdCloudLearningRoadmapSortedList(learningRoadmapList);

      // 현재 직무 정보
      getListFunc(`my/${ACT_GET_USER_WORK_PERIOD}`, null, workData, null, (res) => {
        if(lengthCheck(res)){
          workData.value.map(x => {
            if(res.lastJobInfo && x.jobCd == res.lastJobInfo.jobCd) {
              lastJobInfo.value = x;
            }
          })
        }
      })
      // AI 추천 직무
      getListFunc(`my/${ACT_GET_USER_RECOMMEND_LSIT}`, null, aiResult);
    }

    const getList = async () => {
      if(search.picRate==='9.16'){
        paging.pageSize = 12
      }else{
        paging.pageSize = 8
      }
      let contentsList = await getHrdCloudList({...search, ...paging, ...params});
      hrds.value = getCheckItems(contentsList);
      paging.totalCount = contentsList && contentsList.paging ? contentsList.paging.totalCount : 0;
      if (stringCheck(search.keyword)) {
        let categoryList = await store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_CATE_SEARCH_LIST}`, {keyword: search.keyword, ...paging});
        categories.value = getCheckItems(categoryList);
        categoriesPaging.totalCount = categoryList && categoryList.paging ? categoryList.paging.totalCount : 0;
      }
        //검색 이 아니고, 메뉴가 숏클라우드가 아닌 직무,지식,hotTube 인 경우 추가로 숏클라우드 콘텐츠 검색
        // justShortsItems.value.isLoading = true
        let shorts = await getHrdCloudList({...search, ...paging, ...params, picRate: '9.16', pageSize:12, pageNo:1, });
        justShortsItems.value.items = getCheckItems(shorts);
        justShortsItems.value.isLoading = false;

      isLoading.value = false;
    };

    // 지금 나에게 필요한 콘텐츠, 따끈따끈 신규 콘텐츠, 나의 최근 학습 콘텐츠, 최근 직무 분야 인기 콘텐츠, 최근 직무외 분야 인기 콘텐츠
    const getMainList = async () => {
      let shorts = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_LATEST_LIST}`, {pageNo: 1, pageSize: 10, picRate: '9.16'});
      setMainItems(mainList, shorts, 0);
      let recommend = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECOMMEND_LIST}`, {pageNo: 1, pageSize: 10});
      setMainItems(mainList, recommend, 1);
      let latest = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_LATEST_LIST}`, {pageNo: 1, pageSize: 10});
      setMainItems(mainList, latest, 2);
      let latestPopularDuty = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}`, {category1: mainList.value[3].params.category1, pageNo: 1, pageSize: 10});
      setMainItems(mainList, latestPopularDuty, 3);
      let latestPopularKnowledge = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}`, {category1: mainList.value[4].params.category1, pageNo: 1, pageSize: 10});
      setMainItems(mainList, latestPopularKnowledge, 4);
      let recent = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECENT_LIST}`, {pageNo: 1, pageSize: 10});
      setMainItems(mainList, recent, 5);
    };

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      if(pageNo){
        pushQuery({...search, pageNo});
      }else{
        pushQuery({...search});
      }
    };

    const handleMenuClick = (tabMenu) => {
      hrds.value = [];
      initParams(search);
      search.sortTyCdDcd = sortTyCdDcds.SORT_TY_CD_LATEST;
      if (tabMenu != 0) {
        search.category1 = tabMenu;
      }

      if(tabMenu == -99){
        isShorts.value = true;
      }else{
        isShorts.value = false;
      }

      pagingFunc(1);
    };

    const debounce = ref(0);
    const timeout = ref(500);

    // console.log(window.location.pathname);
    // console.log(location.href);


    /**
     * 키워드가 있을때는 검색 페이지로 이동하고 키워드 없이 카테고리만 있으면 해당 페이지로 이동
     * */
    const handleFilterQuery = () => {
      if (route.name === 'HrdCloud' || route.name === 'HrdCloudRoute') {
        if (route.query) {
          if (Number(route.query.category1) != 0 && Number(route.query.category2) == 0) timeout.value = 3000;
          else timeout.value = 500;

          if (route.query.keyword != null && route.query.keyword.length > 0) {
            isLoading.value = true;
            justShortsItems.value.isLoading = true;
            setFilterByQuery(search, {keyword: route.query.keyword});
            paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
            debounce.value = setTimeout(() => {
              if (debounce.value) clearTimeout(debounce.value);
              getList();
            },timeout.value);
          } else {
            isLoading.value = true;
            if (!route.query.sortTyCdDcd) {
              route.query.sortTyCdDcd = sortTyCdDcds.SORT_TY_CD_LATEST;
            }
            setFilterByQuery(search, route.query);
            if (route.query.category1 == 1) {
              tabMenu.value = 1;
            } else if (route.query.category1 == 2) {
              tabMenu.value = 2;
            } else if (route.query.category1 == 3) {
              tabMenu.value = 3;
            } else if (route.query.category1 == 4) {
              tabMenu.value = 4;
            } else if (route.query.category1 == 5) {
              tabMenu.value = 5;
            } else if (route.query.category1 == -99) {
              tabMenu.value = -99;
              search.picRate = '9.16';
              isShorts.value = true;
            } else {
              tabMenu.value = 0;
            }
            if (tabMenu.value === 0) {
              debounce.value = setTimeout(() => {
                if (debounce.value) clearTimeout(debounce.value);
                getMainList();
              },timeout.value);
            } else {
              paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
              debounce.value = setTimeout(() => {
                if (debounce.value) clearTimeout(debounce.value);
                getList();
              },timeout.value);
            }
          }
        }
      }
    };

    watch(() => route.query, () => {
      handleFilterQuery();
    });

    onMounted(() => {
      handleFilterQuery();
      getlearningRoadmapData();
    });

    const popups = ref([]);

    //location

    getBanerList({banerDivCdDcd: banerDivCdDcds.popup, banerExpsrLocCdDcd: banerExpsrLocCdDcds.hrdCloud, pageNo: 1, pageSize: 10}, popups);
    let nowDate = new Date();
    nowDate = nowDate.setTime(currentTs.value);

    const expirationPopup = computed(() => parseInt(localStorage.getItem('kb-popup-hrdcloud')) > nowDate);
    const eventStatusPopup = ref(false);

    const subscribeNotiPopupModal = ref(true);

    const checkEventFlag = ()=>{
      console.log("checkEventFlag", dateToDateFormat(new Date(), 'yyyyMMdd'), dateToDateFormat(new Date(), 'yyyyMMdd') <= '20231130')
      return dateToDateFormat(new Date(), 'yyyyMMdd') <= '20231130';
    }

    eventStatusPopup.value = checkEventFlag();


    watch(() => tabMenu.value, () => {
      subscribeNotiPopupModal.value = tabMenu.value === 0;
      eventStatusPopup.value = tabMenu.value === 0 ? checkEventFlag() : false;

    });

    return {
      isShorts, isLoading, session, sortTypeDcd, hrds, paging, cloudMapModal, lochref,
      tabMenu, params, categories, categoriesPaging,
      handleMenuClick,
      mainList,
      search, pagingFunc, isSearching, getList,
      toggles, closeToggles,
      popups,
      expirationPopup,
      uploadModal,
      goProgrammers,
      subscribeNotiPopupModal,
      eventStatusPopup,
      learningRoadmapList,
      showLearningRoadmapMainModal,
      lastJobInfo,
      aiResult,
      attendanceModal,
      modules: [Navigation],
      justShortsItems,
    }
  }
};
</script>
