<template>
  <router-view/>
</template>

<style>
@import "./assets/custom/developer.css";
@import "./assets/custom/temp.custom.css";
@import "./assets/custom/ckeditor.custom.css";
@import "./assets/custom/ssl.custom.css";
@import "./assets/lxp/css/_animate.css";
</style>

<script>
import navigationUtils from '@/assets/js/navigationUtils';
import {commonListenerEnttApp} from '@/assets/js/nativeAppUtils';
if(navigationUtils.any()){
  import("./assets/lxp/mobile/css/lxp.mobile.css");
}else{
  import("./assets/lxp/css/lxp.css");
}

window.commonListenerEnttApp = commonListenerEnttApp; // WebView에서 접근 가능하도록 주입

export default {
  name: 'App',
  setup(){
    const commonListenerApp = {
      onUnmountLayer(layerId){
        console.log(layerId)
        window.commonListenerEnttApp.onUnmountLayer(layerId);
      },
      onSelRefObj(objJsonStr, currTime){
        console.log(objJsonStr, currTime)
        window.commonListenerEnttApp.onSelRefObj(objJsonStr, currTime)
      }
    }

    return {
      commonListenerApp,
    }
  }
}
</script>
