<template>
  <article class="content-section section-divider-m mt-2">
    <div class="learningGroup-container">
      <div class="list-top ps-0">
        <div class="top-column">
          <p class="text text-muted">총 {{ paging.totalCount }}건</p>
        </div>
      </div>
      <!-- symbol-item-wrap -->
      <ul class="symbol-item-wrap symbol-item-margin" data-content="row">
        <div class="text-item-container">
          <!-- text-item -->
          <div v-for="(item, idx) in items" :key="idx" class="text-item">
            <div class="item-row row-contents">
              <div class="item-column">
                <h3 class="item-title">{{ item.objNm }}</h3>
                <div class="item-meta fsize-m">
                  <strong class="text text-primary" :class="{'text-red' : item.mtgAcptYn === 'N'}">{{ item.mtgAcptYn === 'Y' ? '인정' : '미인정'}}</strong>
                  <span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd') }} {{timestampToDateFormat(item.regDt, 'hh:mm')}}-{{timestampToDateFormat(item.mdfcnDt, 'hh:mm')}}</span>
                  <span class="text">참석 {{ item.grpMoimAttndCnt }} 정원 {{ item.grpTrgtDtlCnt }}</span>
                </div>
              </div>
            </div>
            <div class="item-row row-buttons">
              <router-link :to="{name: 'GroupLearningJournal', params: {boardSn: item.boardSn, lrnPostSn: item.lrnPostSn}}" class="kb-btn kb-btn-primary"><strong class="text">모임상세</strong></router-link>
            </div>
          </div>
          <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        </div>

        <!-- symbol-item -->
        <li v-if="items.length === 0" class="symbol-item">
          <div class="item-row row-content">
            <div class="learningGroup-empty">
              <p class="text">완료된 그룹 모임이 없습니다.</p>
            </div>
          </div>
        </li>
        <!-- //symbol-item -->
      </ul>
      <!-- //symbol-item-wrap -->
    </div>
  </article>
</template>

<script>
import {groupMeetingStatusSetup} from "@/assets/js/modules/groupLearing/group-meeting-status-setup";
import CommonPaginationFront from "@/components/CommonPaginationFront.vue";

export default {
  name: "MobileMeetingStatus",
  components: {CommonPaginationFront},
  setup: groupMeetingStatusSetup
}
</script>