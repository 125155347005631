import store from '../../../../store';
import router from '@/router';
import {useAlert} from "@/assets/js/modules/common/alert";
import {
    convertToStorageBytes,
    dateToDateFormat,
    getItem,
    getItems, getNextBusinessDay,
    getPaging, getPreviousBusinessDay,
    isSuccess, isUploadSuccess, lengthCheckFailAlert,
    lengthCheck,
    removeComma,
    setParams, timestampToDateFormat, setParamsByQuery,
} from "@/assets/js/util";
import {goBack, actTyCdDcds} from '@/assets/js/modules/common/common';
import {
    ACT_GET_BOARD_MST,
    ACT_GET_BOARD_OPER_COUNT,
    ACT_GET_BOARD_CATE_LIST,
    ACT_GET_POST_LIST,
    ACT_GET_POST_DTL,
    ACT_GET_POST_COUNT,
    ACT_INSERT_BOARD_EVAL,
    ACT_GET_BOARD_ATCH_FILE_LIST,
    ACT_GET_BOARD_COMMENT_COUNT,
    ACT_DOWNLOAD_BOARD_ATCH_FILE,
    ACT_INSERT_BOARD_ACT_LOG,
    ACT_UPDATE_POST,
    ACT_GET_BOARD_CSTM_INFO_LIST,
    ACT_UPLOAD_BOARD_THUMB_FILE,
    ACT_INSERT_POST, ACT_INSERT_BOARD_ATCH_FILE, ACT_SAVE_BOARD_CSTM_DATA_LIST,
} from "@/store/modules/board/board-mst";
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import {atchFileExts, checkExtension, getTotalSize} from "@/assets/js/file-util";
import ClassicEditor from "@/assets/js/editor/custom-ckeditor";
import {editorConfig} from "@/assets/js/editor/editor-config";
import UploadAdapter from "@/assets/js/editor/upload-adapter";
import {MUT_TOGGLE_CONCENTRATION_MODE} from "@/store/modules/hrdcloud/hrdcloud";
import {useRoute, useRouter} from "vue-router";
import {serverUploadError} from '@/assets/js/modules/error/error-message';
import {useStore} from "vuex";
import {hotTipBoardIds} from "@/assets/js/modules/learn/learn-hot-tip";
import {ACT_GET_SOCS_EDITOR_UPLOAD_OCR_MASKING} from "@/store/modules/board/board";

const {showMessage, showConfirm, showLoading, hideLoading} = useAlert();
export const hashTagSeparator = '#';

// 페이지 권한 확인
export const validTarget = (target, isOper) => {
    if(target == null || target == '' || target == 'all') {
        return true;
    }else if(isOper && target == "oper"){
        return true;
    }else if(!isOper && target == "user"){
        return true;
    }else{
        return false;
    }
}

// 팝업 닫기 or goBack
export const exitBoardPage = () => {
    if(window.name == 'FrontBoardPopup'){
        window.close();
    }else{
        goBack();
    }
}

// 상단메뉴(nohead) 설정 유지
const getNoheadQuery = (returnType) => {
    const urlParam = new URLSearchParams(location.search);
    if(urlParam.has('nohead')){
        return returnType == 'String' ? urlParam.get('nohead') : {'nohead': urlParam.get('nohead')};
    }else{
        return returnType == 'String' ? 0 : null;
    }
}

// 글쓰기 이동
export const goToWrite = (lrnerId, boardMstRef) => {
    if(checkWritingLimit('post', lrnerId, boardMstRef, '0.', goBack)){
        router.push({name: 'BoardEdit', params: {boardId: boardMstRef.value.boardId, boardSecuKey: "0"}, query: getNoheadQuery()});
    }
}

// 글수정 이동
export const goToEdit = (boardSecuKey, boardId) => {
    router.push({ name: 'BoardEdit', params: {boardId, boardSecuKey}, query: getNoheadQuery() });
}

// 글 상세페이지 이동
export const goToView = (boardSecuKey, boardId) => {
    router.push({ name: 'BoardView', params: {boardId, boardSecuKey}, query: getNoheadQuery() });
}

// 답글 쓰기 이동
export const goToWriteAnswer = (lrnerId, boardMstRef, post) => {
    if(checkWritingLimit('post', lrnerId, boardMstRef, post.boardSecuKey, goBack)){
        router.push({
            name: 'BoardEdit',
            params: {
                boardId: boardMstRef.value.boardId,
                boardSecuKey: '0',
            },
            query: {
                gno: (post.postGrpNo > 0) ? post.postGrpNo : post.comPostSn,
                lvl: (post.postLvl + 1),
                aseq: (post.postAnsSeq + 1),
                nohead: getNoheadQuery('String'),
            }
        })
    }
}

// 게시글 조회수 갱신
// const updatePostInqCnt = (boardSecuKey) => {
//     store.dispatch(`boardmst/${ACT_UPDATE_POST_INQ_CNT}`, boardSecuKey);
// }

// 내/외부망 확인
export const validNetwork = (network, callback) => {
    let isInside = store.state.auth.session.itn;
    if(network == 'in' && !isInside){
        showMessage("은행 내부에서만 가능한 기능입니다.");
    }else if(network == 'out' && isInside){
        showMessage("은행 외부에서만 가능한 기능입니다.");
    }else{
        if(callback instanceof Function) callback();
    }
}

// 게시판 정보
export const getBoardMstInfo = (boardId, returnRef, callback) => {
    store.dispatch(`boardmst/${ACT_GET_BOARD_MST}`, boardId).then((res) => {
        if(lengthCheck(res)){
            returnRef.value = getItem(res);
            if(returnRef.value.stt != '00'){
                showMessage("비활성 게시판으로 접근할 수 없습니다.", () => {exitBoardPage();});
            }else{
                if(callback instanceof Function) callback();
            }
        }else{
            returnRef.value = null;
            if(callback instanceof Function) callback();
        }
    })
}

// 게시판 카테고리 정보
export const getBoardCateList = (boardMstSn, returnRef, callback) => {
    store.dispatch(`boardmst/${ACT_GET_BOARD_CATE_LIST}`, boardMstSn).then((res) => {
        if(lengthCheck(res)){
            returnRef.value = getItems(res);
            returnRef.value.unshift({cateNm:'전체', boardCateSn:''});
        }else{
            returnRef.value = [{cateNm:'전체', boardCateSn:''}];
        }
    }).finally(() => {
        if(callback instanceof Function) callback();
    })
}

// 게시판 운영자 확인
export const checkIsOper = (param, returnRef, callback) => {
    store.dispatch(`boardmst/${ACT_GET_BOARD_OPER_COUNT}`, param).then((res) => {
        if(lengthCheck(res)){
            let result = getItem(res);
            if(result > 0) returnRef.value = true;
        }else{
            returnRef.value = false;
        }
        if(callback instanceof Function) callback();
    })
}

// 게시글 리스트
export const getPostList = async (param, postListRef, pagingRef, boardCateList, callback) => {
    let returnParam = {};
    Object.keys(param).forEach(key => {
        if(param[key] != null && param[key] != '' && param[key] != undefined){
            returnParam[key] = param[key];
        }
    })
    await store.dispatch(`boardmst/${ACT_GET_POST_LIST}`, returnParam).then((res) => {
        if(lengthCheck(res)){
            postListRef.value = getItems(res);
            if(pagingRef != null) pagingRef.value = getPaging(res);
            postListRef.value.map((post) => {
                if(post.boardCateSn > 0){
                    boardCateList.forEach((cate) => {
                        if(cate.boardCateSn === post.boardCateSn){
                            post.cateNm = cate.cateNm;
                        }
                    })
                }else{
                    post.cateNm = '-';
                }
            })
        }else{
            postListRef.value = [];
            if(pagingRef != null) pagingRef.value.totalCount = 0;
        }
        if(callback instanceof Function) callback();
    })
}

// 게시글 정보
export const getPostInfo = async (param, returnRef, callback) => {
    if(param.boardSecuKey){
        await store.dispatch(`boardmst/${ACT_GET_POST_DTL}`, param).then((res) => {
            if(lengthCheck(res)){
                returnRef.value = getItem(res);
            }else{
                returnRef.value = null;
                if(res.msg){
                    showMessage({text:res.msg, callback: exitBoardPage});
                }else if(param.mode == 'view'){
                    showMessage({text:'게시글을 열람할 수 없습니다.', callback: goBack});
                }
            }
        })
    }else{
        returnRef.value = null;
        showMessage({text:'잘못된 접근입니다.', callback: goBack});
    }
    if(callback instanceof Function) await callback();
}

// 게시글 삭제
export const deletePost = (comPostSn, callback) => {
    showConfirm('해당 글을 삭제하시겠습니까?', () => {
        store.dispatch(`boardmst/${ACT_UPDATE_POST}`, {
            comPostSn: comPostSn,
            stt: '99',
        }).then((res) => {
            if(lengthCheckFailAlert(res)){
                showMessage('삭제되었습니다.', callback);
            }
        });
    })
}

// 게시글 부가정보
export const getBoardCustomInfos = (boardMstSn, boardSecuKey, returnRef) => {
    store.dispatch(`boardmst/${ACT_GET_BOARD_CSTM_INFO_LIST}`, {boardMstSn, boardSecuKey}).then(res => {
        if(lengthCheck(res)){
            returnRef.value = getItems(res);
        }
    })
}


// 게시글 첨부파일
export const getBoardAtchFiles = (boardSecuKey, returnRef, callback) => {
    store.dispatch(`boardmst/${ACT_GET_BOARD_ATCH_FILE_LIST}`, boardSecuKey).then((res) => {
        if(lengthCheck(res)){
            returnRef.value = getItems(res);
        }else{
            returnRef.value = [];
        }
        if(callback instanceof Function) callback();
    })
}

export const downloadBoardAtchFile = (secuKey, fileName, params) => {
    store.dispatch(`boardmst/${ACT_DOWNLOAD_BOARD_ATCH_FILE}`, {
        secuKey,
        fileName,
        params
    });
}

// 게시글 평가
export const insertBoardEval = (comPostSn, score, successCallback, failCallback) => {
    store.dispatch(`boardmst/${ACT_INSERT_BOARD_EVAL}`, {comPostSn, score}).then((res) => {
        if(lengthCheck(res)){
            if(successCallback instanceof Function) successCallback();
        }else{
            if(failCallback instanceof Function) failCallback();
        }
    })
}

export const actLike = (post) => {
    if(post.isLike == 'Y'){
        showMessage("좋아요는 취소할 수 없습니다.");
    }else{
        showConfirm(`좋아요를 하시겠습니까?<br>(좋아요는 취소할 수 없습니다.)`, () => {
            insertBoardActLog(actTyCdDcds.ACT_TY_CD_LIKE, post.comPostSn, () => {
                post.isLike = 'Y';
                post.likeCnt++;
            });
        });
    }
}

export const actPut = (post) => {
    if(post.isPut == 'Y'){
        showConfirm("해당 게시물의 담기를 취소하시겠습니까?", () => {
            insertBoardActLog(actTyCdDcds.ACT_TY_CD_CANCEL_ADD, post.comPostSn, () => {
                post.isPut = 'N';
            })
        })
    }else{
        insertBoardActLog(actTyCdDcds.ACT_TY_CD_ADD, post.comPostSn, () => {
            post.isPut = 'Y';
        })
    }
}

// 활동 로그
export const insertBoardActLog = (actTyCdDcd, comPostSn, successCallback, failCallback) => {
    store.dispatch(`boardmst/${ACT_INSERT_BOARD_ACT_LOG}`, {
        actTyCdDcd,
        comPostSn
    }).then((res) => {
        if(lengthCheck(res)){
            if(successCallback instanceof Function) successCallback();
        }else{
            if(failCallback instanceof Function) failCallback();
        }
    })

}

export const checkWritingLimit = async (div, rgtrId, boardMstRef, boardSecuKey, msgCallback) => {
    let result = true;

    if(div == 'post' && boardMstRef.value.postMaxCnt > 0){
        // 게시글
        await store.dispatch(`boardmst/${ACT_GET_POST_COUNT}`, {
            boardMstSn: boardMstRef.value.boardMstSn,
            rgtrId: rgtrId,
        }).then((res) => {
            if(lengthCheck(res)){
                let count = getItem(res);
                if(count >= boardMstRef.value.postMaxCnt){
                    showMessage("게시글은 최대 " + boardMstRef.value.postMaxCnt + "개까지 등록할 수 있습니다.", () => { if(msgCallback instanceof Function) msgCallback(); });
                    result = false;
                }
            }
        })
    }else if(div == 'cmnt' && boardMstRef.value.cmntMaxCnt > 0){
        // 댓글
        await store.dispatch(`boardmst/${ACT_GET_BOARD_COMMENT_COUNT}`, {
            boardSecuKey: boardSecuKey,
            rgtrId: rgtrId,
        }).then((res) => {
            if(lengthCheck(res)){
                let count = getItem(res);
                if(count >= boardMstRef.value.cmntMaxCnt){
                    showMessage("댓글은 최대 " + boardMstRef.value.cmntMaxCnt + "개까지 작성할 수 있습니다.", () => { if(msgCallback instanceof Function) msgCallback(); });
                    result = false;
                }
            }
        })
    }

    return result;
}

export const checkScrtMd = (boardMst, post, isWriter, isOper) => {
    if(isOper || boardMst.postScrtYn == 'N'){
        return false;
    }else if(boardMst.postScrtYn == 'Y' && (isWriter || post.postGrpRgtrId == store.state.auth.session.lrnerId)){
        return false;
    }else{
        return true;
    }
}

export const boardEditSetup = () => {
    const route = useRoute();
    const session = computed(() => store.state.auth.session);
    const boardMst = ref(null);
    const boardCateList = ref([]);
    const post = ref(null);
    const isLoading = ref(true);
    const isOper = ref(false);
    const param = reactive({
        comPostSn: 0,
        boardMstSn: 0,
        boardCateSn: 0,
        postGrpNo: 0,
        postLvl: 0,
        postAnsSeq: 0,
        postTitle: '',
        postCn: '',
        postFileAtchYn: 'N',
        userNickNm: '',
        thumbFileUrl: '',
        thumbFileNm: '',
        topFixYn: 'N',
        stt: '00',
        regDt: null,
        mdfcnDt: null,
        hashTag: '',
        removeFiles: [],
        inqCnt: null,
    });
    const cateNm = ref('전체');
    const isCateToggle = ref(false);
    const isReadOnly = ref(false);
    const hashTag = ref(null);
    const uploadThumbFile = ref(null);
    const uploadFiles = ref(null);
    const files = ref({files: [], removed: [], added: []});
    const originFiles = ref([]);
    const pageEditor = ref(null);
    let $_instance = null;
    const isAnswer = computed(() => route.query.aseq > 0 && route.query.gno > 0 && route.query.lvl > 0);
    const customInfos = ref([]);
    const showHottipHeader = computed(() => route.path.indexOf('/hottip') > -1);
    const isHotTipBoard = computed(() => hotTipBoardIds.includes(route.params.boardId)); // 공지사항 제외
    const toggle = reactive({
        mobileSaveBtn: false,
        previewModal: false,
    })

    const showOcrMaskingModal = ref(false);
    const ocrImageUrl = ref('');

    const textInfo = ref([{
        text : null,
        tag : null,
        boundingPoly : null,
        vertices : null
    }]);

    const clickCategory = (item) => {
        cateNm.value = item.cateNm;
        param.boardCateSn = item.boardCateSn;
        isCateToggle.value = false;
    }

    const addHashTag = () => {
        let tagArr = param.hashTag.split(hashTagSeparator);
        if(tagArr.length > 10) {
            showMessage('해시태그는 최대 10개까지 작성 가능합니다.');
        }else{
            if(hashTag.value && hashTag.value.trim().length > 0) tagArr.push(hashTag.value.trim());
            param.hashTag = tagArr.join(hashTagSeparator);
            hashTag.value = null;
        }
    }

    const removeHashTag = (idx) => {
        let tagArr = param.hashTag.split(hashTagSeparator);
        tagArr.splice(idx, 1);
        param.hashTag = tagArr.join(hashTagSeparator);
    }

    const isUploading = ref(false);
    const clickThumb = () => {
        let imgFileExts = ['gif' ,'jpg' ,'jpeg' ,'png' ,'webp' ,'bmp'];
        if(uploadThumbFile.value.files.length > 0){
            if(checkExtension(uploadThumbFile.value.files, imgFileExts)){
                showMessage(`확장자가 ${imgFileExts.join(', ')} 인 파일만 등록 가능합니다.`);
                return;
            }
            isUploading.value = true;
            // 파일 업로드 후 url을 리턴받아 param 정보에 추가
            store.dispatch(`boardmst/${ACT_UPLOAD_BOARD_THUMB_FILE}`, {
                boardMstSn: boardMst.value.boardMstSn,
                file: uploadThumbFile.value.files,
            }).then((res) => {
                if(res.url){
                    param.thumbFileUrl = res.url;
                    param.thumbFileNm = uploadThumbFile.value.files[0].name;
                }else{
                    showMessage("썸네일 파일 업로드 중 오류가 발생했습니다.");
                }
            }).catch((e) => {
                console.log('[ERROR]', e);
                showMessage("썸네일 파일 업로드 중 오류가 발생했습니다.");
            }).finally(() => {isUploading.value = false;});
        }
    }

    const clickFileAtch = () => {
        validNetwork(boardMst.value.atchUpNet, addFile);
    }

    const addFile = () => {
        if (uploadFiles.value && uploadFiles.value.files && uploadFiles.value.files.length > 0) {
            if(checkExtension(uploadFiles.value.files, atchFileExts)){
                showMessage(`확장자가 ${atchFileExts.join(', ')} 인 파일만 등록 가능합니다.`);
                return;
            }
            if((files.value.files.length + uploadFiles.value.files.length) > boardMst.value.atchMaxCnt) {
                showMessage(`첨부파일은 최대 ${boardMst.value.atchMaxCnt}개까지 등록 가능합니다.`);
                uploadFiles.value.value = null;
                uploadFiles.value.files = null;
                return;
            }
            let totalFileSize = (getTotalSize(files.value.files) + getTotalSize(uploadFiles.value.files)) / (1024 * 1024);
            if(boardMst.value.atchMaxSize > 0 && totalFileSize > boardMst.value.atchMaxSize) {
                showMessage(`첨부파일은 최대 ${boardMst.value.atchMaxSize}MB까지 등록 가능합니다.<br>(첨부파일 크기: ${totalFileSize.toFixed(2)}MB)`);
                uploadFiles.value.value = null;
                uploadFiles.value.files = null;
                return;
            }
            Array.from(uploadFiles.value.files).forEach((file, idx) => {
                uploadFiles.value.files[idx].fileNm = file.name;
                uploadFiles.value.files[idx].fileSz = file.size;
                files.value.files.push(file);
                files.value.added.push(file);
            })
        }
    }

    const removeFile = (item) => {
        files.value.removed.push({'comFileAtchSn': item.comFileAtchSn});
        if(files.value.files.indexOf(item) > -1) files.value.files.splice(files.value.files.indexOf(item), 1);
        if(files.value.added.indexOf(item) > -1) files.value.added.splice(files.value.added.indexOf(item), 1);
    }

    const checkValidate = () => {
        if(param.postTitle == ''){
            showMessage('제목을 입력하세요');
            return false;
        }else if(param.postCn == ''){
            showMessage('내용을 입력하세요');
            return false;
        }else if(boardCateList.value.length > 1 && param.boardCateSn == 0){
            showMessage('카테고리를 선택하세요');
            return false;
        }else if(boardMst.value.userScrtYn == 'Y' && param.userNickNm.length == 0){
            showMessage('닉네임을 입력하세요');
            return false;
        }else if(boardMst.value.boardLayoutCd == 'thumbList' && !param.thumbFileUrl){
            showMessage('썸네일 이미지를 등록하세요');
            return false;
        }else if(boardMst.value.atchMinCnt > 0 && files.value.files.length < boardMst.value.atchMinCnt){
            showMessage(`첨부파일을 ${boardMst.value.atchMinCnt}개 이상 등록하세요.`);
            return false;
        }else if(customInfos.value.length > 0){
            return checkValidateCstmImt();
        }else{
            return true;
        }
    }

    const checkValidateCstmImt = () => {
        let result = true;
        for(const target of customInfos.value) {
            // 필수 입력 여부 확인
            if(target.mustYn == 'Y' && (target.value == null || target.value == '')){
                showMessage(target.itemNm + ' 항목을 입력하세요');
                result = false;
                break;
            }
            // 유효 조건 설정 확인
            else if(target.validCondition && target.value){
                // 조건식 예약어 변환
                const now = new Date();
                let today = dateToDateFormat(now, 'yyyy-MM-dd');
                let cstmItmValid = target.validCondition.replace(/\{this\}/g, target.itemType == 'money' ? removeComma(target.value) : target.value);
                // 날짜 제한: 작성일
                const dateRegx = /\{today\}/g;
                if(dateRegx.test(cstmItmValid)) cstmItmValid = cstmItmValid.replace(dateRegx, today);
                // 날짜 제한: 과거
                const dateBeforeRegx = /\{today-\d+b?\}/;
                if(dateBeforeRegx.test(cstmItmValid)){
                    let beforeArr = cstmItmValid.match(dateBeforeRegx);
                    beforeArr.forEach(before => {
                        let minus = before.replace(/[{}]/g, '').replace('today-', '');
                        let targetDate;
                        if(/b/.test(minus)){
                            // 영업일 기준
                            targetDate = getPreviousBusinessDay(now, minus.replace('b',''), 'yyyy-MM-dd');
                        }else{
                            targetDate = dateToDateFormat(new Date(now.getTime() - (1000 * 60 * 60 * 24 * minus)), 'yyyy-MM-dd');
                        }
                        cstmItmValid = cstmItmValid.replace(before, targetDate);
                    });
                }
                // 날짜 제한: 미래
                const dateAfterRegx = /\{today\+\d+b?\}/;
                if(dateAfterRegx.test(cstmItmValid)){
                    let afterArr = cstmItmValid.match(dateAfterRegx);
                    afterArr.forEach(after => {
                        let plus = after.replace(/[{}]/g, '').replace('today+', '');
                        let targetDate;
                        if(/b/.test(plus)){
                            // 영업일 기준
                            targetDate = getNextBusinessDay(now, plus.replace('b',''), 'yyyy-MM-dd');
                        }else{
                            targetDate = dateToDateFormat(new Date(now.getTime() + (1000 * 60 * 60 * 24 * plus)), 'yyyy-MM-dd');
                        }
                        cstmItmValid = cstmItmValid.replace(after, targetDate);
                    });
                }
                // 추가항목 데이터 참조
                const referRegx = /\{#.+\}/;
                if(referRegx.test(cstmItmValid)){
                    let referArr = cstmItmValid.match(referRegx);
                    referArr.forEach(refer => {
                        let searchItemNm = refer.replace(/[{}#]/g, '');
                        let searchItem = customInfos.value.find(data => data.itemNm == searchItemNm);
                        if(searchItem != undefined){
                            if(searchItem.value == null || searchItem.value == ''){
                                showMessage(searchItem.itemNm + ' 항목을 입력하세요');
                                result = false;
                            }else{
                                cstmItmValid = cstmItmValid.replace(refer, searchItem.itemType == 'money' ? removeComma(searchItem.value) : searchItem.value);
                            }
                        }else{
                            showMessage(searchItemNm + ' 항목을 참조할 수 없습니다.');
                            result = false;
                        }
                    })
                    if(!result) break;
                }

                // 유효성 검사
                if(eval(cstmItmValid) == false){
                    console.log('invalid input:', cstmItmValid);
                    if(target.alertErrMsg){
                        showMessage(target.alertErrMsg);
                    }else{
                        showMessage(target.itemNm + ` 항목이 입력 조건에 맞지 않습니다.<br>유효한 데이터 입력 조건을 확인하세요.`);
                    }
                    result = false;
                    break;
                }

            }
        }
        return result;
    }

    // 에디터 세팅
    let tryCount = 0;
    const setPageEditor = () => {
        if(tryCount > 10) return;
        if(pageEditor.value){
            ClassicEditor.create( pageEditor.value, {...editorConfig, placeholder:'내용을 입력하세요'} )
                .then(editor => {
                    /*if(isReadOnly.value) editor.enableReadOnlyMode('pageEditor');
                    else editor.disableReadOnlyMode('pageEditor');*/
                    if (isReadOnly.value) editor.isReadOnly = true;
                    else editor.isReadOnly = false;
                    $_instance = editor;

                    editor.plugins.get('FileRepository').createUploadAdapter = ( loader ) => {
                        showLoading('개인정보 검출중...');
                        // return new UploadAdapter(loader, '/v1/app/socs/editor/upload/ocr');
                        return new UploadAdapter(loader, '/v1/app/common/board/editor/upload/ocr/'+boardMst.value.boardMstSn);
                    };

                    editor.plugins.get('ImageUploadEditing').on('uploadComplete', (evt, {data}) => {
                        if (data.fields && data.fields.length > 0) {
                            ocrImageUrl.value = data.url;
                            textInfo.value = data.fields;
                            showOcrMaskingModal.value = true;
                        }
                        hideLoading();
                    });

                    watch(() => showOcrMaskingModal.value, () => {
                        if (!showOcrMaskingModal.value && textInfo.value.length > 0) {
                            showLoading('마스킹 처리중...');
                            store.dispatch(`board/${ACT_GET_SOCS_EDITOR_UPLOAD_OCR_MASKING}`, {
                                url: ocrImageUrl.value,
                                fields : textInfo.value
                            }).then(res => {
                                $_instance.setData($_instance.getData().replace(ocrImageUrl.value, res));
                                hideLoading();
                            }).catch(() => {
                                hideLoading();
                            });
                        }
                    });

                })
                .catch(error => {
                    console.error(error);
                });
        }else{
            tryCount++;
            setPageEditor();
        }
    };

    const setParamData = () => {
        uploadFiles.value = null;
        // 글 수정인 경우
        if(post.value !== null && post.value.comPostSn !== undefined){
            setParams(param, post.value);
            getBoardAtchFiles(post.value.boardSecuKey, originFiles,() => {files.value.files = originFiles.value});
            if($_instance) $_instance.setData(post.value.postCn);
            if(post.value.boardCateSn > 0){
                boardCateList.value.forEach((cate) => {
                    if(cate.boardCateSn == post.value.boardCateSn){
                        cateNm.value = cate.cateNm;
                    }
                })
            }
        }
        // 글 등록인 경우
        else{
            param.boardMstSn = boardMst.value.boardMstSn;

            // 답글인 경우
            if(isAnswer.value){
                param.postAnsSeq = route.query.aseq;
                param.postGrpNo = route.query.gno;
                param.postLvl = route.query.lvl;
                if($_instance && boardMst.value.ansDefaultCn) $_instance.setData(boardMst.value.ansDefaultCn);
            }else{
                if($_instance && boardMst.value.postDefaultCn) $_instance.setData(boardMst.value.postDefaultCn);
            }
        }

    }

    const getParamData = () => {
        param.postCn = $_instance.getData();
        param.postFileAtchYn = (files.value.files.length > 0) ? 'Y' : 'N';
        param.removeFiles = (files.value.removed.length > 0) ? files.value.removed : [];
    }

    const showPreviewModal = () => {
        getParamData();
        toggle.previewModal = true;
    }

    const clickSaveTemp = async () => {
        await getParamData();
        if(await checkValidate()){
            showConfirm({
                text: '임시저장 하시겠습니까?',
                callback: () => {
                    param.stt = '01';
                    saveBoardInfo();
                }
            })
        }
    }

    const clickSave = async () => {
        await getParamData();
        if(await checkValidate()){
            showConfirm({
                text: '저장하시겠습니까?',
                callback: () => {
                    param.stt = '00';
                    saveBoardInfo();
                }
            })
        }
    }

    const saveBoardInfo = () => {
        isReadOnly.value = true;
        const isNew = (param.comPostSn == 0 || param.comPostSn == null);

        store.dispatch( isNew ? `boardmst/${ACT_INSERT_POST}` : `boardmst/${ACT_UPDATE_POST}`, param).then(async (res) => {
            if(lengthCheckFailAlert(res)){
                param.comPostSn = getItem(res);
                // 부가정보 저장
                if(customInfos.value.length > 0){
                    let cstmRes = await saveCustomInfo(param.comPostSn);
                    if(!isSuccess(cstmRes)){
                        showMessage('일부 데이터 저장에 실패하였습니다.');
                        return;
                    }
                }
                // 첨부파일 저장
                if(files.value.added.length > 0){
                    let fileRes = await insertAtchFile(files.value.added);
                    if (!isUploadSuccess(fileRes)) {
                        // 첨부파일 저장 실패 시 게시글 미등록 처리 (삭제)
                        if(isNew){
                            await store.dispatch(`boardmst/${ACT_UPDATE_POST}`, {comPostSn: param.comPostSn, stt: '99'});
                            param.comPostSn = 0;
                        }
                        showMessage(serverUploadError);
                        return;
                    }
                }
                showMessage('저장되었습니다.', () => { if(param.stt != '01'){exitBoardPage();} });
            }
        }).finally(() => {
            isReadOnly.value = false;
        })
    }

    const insertAtchFile = (targetFiles) => {
        return store.dispatch(`boardmst/${ACT_INSERT_BOARD_ATCH_FILE}`, {
            params: {comPostSn: param.comPostSn},
            files: targetFiles
        });
    }

    const saveCustomInfo = (comPostSn) => {
        return store.dispatch(`boardmst/${ACT_SAVE_BOARD_CSTM_DATA_LIST}`,
            customInfos.value.map(info => {return {
                boardCstmDataSn: info.boardCstmDataSn,
                boardCstmItmSn: info.boardCstmItmSn,
                comPostSn: comPostSn,
                value: info.value,
            }})
        );
    }

    const getPostDtl = () => {
        isLoading.value = true;
        getPostInfo({boardMstSn: boardMst.value.boardMstSn, boardSecuKey: route.params.boardSecuKey, mode: 'write'}, post, async () => {
            let writeLimit =  await checkWritingLimit('post', session.value.lrnerId, boardMst, route.params.boardSecuKey, exitBoardPage);
            if(route.params.boardSecuKey == "0" && !writeLimit){
                // exitBoardPage 실행
            }else{
                setParamData();
                if(!isAnswer.value || boardMst.value.ansCstmItmYn == 'Y'){
                    getBoardCustomInfos(boardMst.value.boardMstSn, route.params.boardSecuKey ? route.params.boardSecuKey : "0.", customInfos);
                }
                isLoading.value = false;
            }
        });
    }

    const checkValidAccess = () => {
        checkIsOper({boardMstSn: boardMst.value.boardMstSn, userSn: session.value.userSn}, isOper, () => {
            if(
                route.query.gno > 0
                && (
                    boardMst.value.ansWrtYn == 'N'
                    || (route.params.comPostSn > 0 && boardMst.value.ansRewrtYn == 'N')
                    || !validTarget(boardMst.value.ansWrtTrgt, isOper.value)
                )
            )
            {
                showMessage('답글 작성 권한이 없습니다.', () => {exitBoardPage()});
            }
            else if(
                boardMst.value.postWrtYn == 'N'
                || (route.params.comPostSn > 0 && boardMst.value.postRewrtYn == 'N' && boardMst.value.ansRewrtYn == 'N')
                || !validTarget(boardMst.value.postWrtTrgt, isOper.value)
            )
            {
                showMessage('게시글 작성 권한이 없습니다.', () => {exitBoardPage()});
            }else{
                getBoardCateList(boardMst.value.boardMstSn, boardCateList, getPostDtl);
            }
        });
    }

    onMounted(() => {
        getBoardMstInfo(route.params.boardId, boardMst, () => {
            checkValidAccess();
        });
        store.commit(`hrdcloud/${MUT_TOGGLE_CONCENTRATION_MODE}`, { 'mode' : 'fill'});
        setPageEditor();
    })

    onBeforeUnmount(() => {
        store.commit(`hrdcloud/${MUT_TOGGLE_CONCENTRATION_MODE}`, { 'mode' : ''});
        if( $_instance ){
            $_instance.destroy();
            $_instance = null;
        }
    })

    return {
        boardMst,
        boardCateList,
        param,
        cateNm,
        isLoading,
        isReadOnly,
        hashTag,
        hashTagSeparator,
        isCateToggle,
        uploadThumbFile,
        uploadFiles,
        files,
        pageEditor,
        customInfos,
        showHottipHeader,
        isHotTipBoard,
        isUploading,
        toggle,
        clickCategory,
        addHashTag,
        removeHashTag,
        clickThumb,
        clickFileAtch,
        addFile,
        removeFile,
        showPreviewModal,
        clickSaveTemp,
        clickSave,
        exitBoardPage,
        goBack,
        convertToStorageBytes,
        deletePost,
        showOcrMaskingModal,
        textInfo,
        ocrImageUrl,
    }
}

export const boardViewSetup = () => {
    const route = useRoute();
    const session = computed(() => store.state.auth.session);
    const boardMst = ref(null);
    const post = ref(null);
    const isLoading = ref(true);
    const isOper = ref(false);
    const isWriter = computed(() => post.value ? session.value.lrnerId == post.value.rgtrId ? true : false : false);
    const showAttachments = ref(false);
    const files = ref([]);
    const customInfos = ref([]);
    const showHottipHeader = computed(() => route.path.indexOf('/hottip') > -1);
    const toggle = reactive({
        shareModal: false,
    })

    const clickDelete = () => {
        deletePost(post.value.comPostSn, () => { goBack() })
    }

    const addAnswer = () => {
        goToWriteAnswer(session.value.lrnerId, boardMst, post.value);
    }

    const downloadAtch = (file) => {
        validNetwork(boardMst.value.atchDownNet, () => {
            // 첨부파일 업로드 네트워크 확인
            if(boardMst.value.atchDownNet == 'each'){
                if(session.value.itn && file.etnUrl){
                    showMessage(`은행 외부망에서 등재된 파일입니다.<br/>외부망에서 다운로드 가능합니다.`);
                }else if(!session.value.itn && file.itnUrl){
                    showMessage(`은행 내부망에서 등재된 파일입니다.<br/>내부망에서 다운로드 가능합니다.`);
                }else{
                    downloadBoardAtchFile(file.secuKey, file.fileNm, {});
                }
            }else{
                downloadBoardAtchFile(file.secuKey, file.fileNm, {});
            }
        });
    }

    const getPostDtl = () => {
        isLoading.value = true;
        //권한체크 추가
        checkIsOper({boardMstSn: boardMst.value.boardMstSn, userSn: session.value.userSn}, isOper);
        getPostInfo({boardMstSn: boardMst.value.boardMstSn, boardSecuKey: route.params.boardSecuKey, mode: 'view'}, post, () => {
            if(post.value != null){
                getBoardCustomInfos(boardMst.value.boardMstSn, route.params.boardSecuKey, customInfos);
                getBoardAtchFiles(route.params.boardSecuKey, files, () => {
                    isLoading.value = false;
                });
            }
        });
    }

    watch(() => route.params.boardSecuKey, getPostDtl);

    onMounted(() => {
        getBoardMstInfo(route.params.boardId, boardMst, getPostDtl);
    });

    return{
        boardMst,
        post,
        files,
        isLoading,
        isWriter,
        isOper,
        showAttachments,
        customInfos,
        hashTagSeparator,
        showHottipHeader,
        toggle,
        actLike,
        actPut,
        goToEdit,
        goToView,
        clickDelete,
        validTarget,
        addAnswer,
        downloadAtch,
        timestampToDateFormat,
        convertToStorageBytes,
        goBack,
    }
}

export const boardMainListSetup = (props, {emit}) => {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const {showMessage} = useAlert();
    const session = computed(() => store.state.auth.session);
    const isMobile = computed(() => store.state.auth.isMobile);
    const boardMst = ref(props.boardMstMap);
    const posts = ref([]);
    const isLoading = ref(true);
    const searchParam = reactive({
        boardMstSn: boardMst.value.boardMstSn,
        pageNo : 1,
        pageSize: boardMst.value.pgSize,
        boardCateSn: '',
        word: '',
        isLike: '',
        postBadgeCdDcd: '',
        postGrpNo: props.postGrpNo,
    });
    const headers = computed(() => boardMst.value.boardItmList.split("$"));
    const paging = ref({pageNo: 1, pageSize: boardMst.value.pgSize, totalCount: 0});
    const isPaging = computed(() => boardMst.value.pgSize > 0);

    const pagingFunc = (num) => {
        if(!isPaging.value){
            return;
        }
        let queryParam = {...route.query};
        queryParam['pageNo'] = num;
        router.push({query: queryParam});
    }

    const clickWrite = () => {
        goToWrite(session.value.lrnerId, boardMst);
    }

    const clickDtl = (item) => {
        if(checkScrtMd(boardMst.value, item, item.rgtrId == session.value.lrnerId, props.isOper)){
            showMessage("비공개 게시글입니다.");
            return;
        }
        goToView(item.boardSecuKey, boardMst.value.boardId);
    }

    const getBoardList = (params) => {
        isLoading.value = true;
        getPostList(getBoardSearchParam(params, boardMst.value), posts, paging, props.boardCateList, () => {
            if(isMobile.value){
                emit('update:listTotalCount', paging.value.totalCount);
            }
            isLoading.value = false
        });
    }

    watch(() => route.query, () => {
        setParamsByQuery(route, searchParam, false);
        getBoardList({...searchParam});
    })

    onMounted(() => {
        setParamsByQuery(route, searchParam, false);
        getBoardList({...searchParam});
    })

    return {
        session,
        boardMst,
        posts,
        isLoading,
        headers,
        paging,
        isPaging,
        getBoardList,
        pagingFunc,
        clickWrite,
        clickDtl,
        validTarget,
        checkScrtMd,
        timestampToDateFormat,
    }
}

export const boardThumbListSetup = (props, {emit}) => {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const session = computed(() => store.state.auth.session);
    const isMobile = computed(() => store.state.auth.isMobile);
    const boardMst = ref(props.boardMstMap);
    const posts = ref([]);
    const isLoading = ref(true);
    const searchParam = reactive({
        boardMstSn: boardMst.value.boardMstSn,
        pageNo : 1,
        pageSize: boardMst.value.pgSize,
        boardCateSn: '',
        word: '',
        postGrpNo: props.postGrpNo,
    });
    const headers = computed(() => boardMst.value.boardItmList.split("$"));
    const paging = ref({pageNo: 1, pageSize: boardMst.value.pgSize, totalCount: 0});
    const isPaging = computed(() => boardMst.value.pgSize > 0);
    const s3Url = process.env.VUE_APP_S3_URL;
    const cloudfrontUrl = process.env.VUE_APP_CLOUDFRONT_URL;

    const getThumbUrl = (url) => {
        if(!url) return;
        return url.replace(s3Url, cloudfrontUrl);
    }

    const pagingFunc = (num) => {
        if(!isPaging.value){
            return;
        }
        let queryParam = {...route.query};
        queryParam['pageNo'] = num;
        router.push({query: queryParam});
    }

    const getBoardList = (params) => {
        isLoading.value = true;
        getPostList(getBoardSearchParam(params, boardMst.value), posts, paging, props.boardCateList, () => {
            if(isMobile.value){
                emit('update:listTotalCount', paging.value.totalCount);
            }
            isLoading.value = false;
        });
    }

    watch(() => route.query, () => {
        setParamsByQuery(route, searchParam, false);
        getBoardList({...searchParam})
    })

    onMounted(() => {
        setParamsByQuery(route, searchParam, false);
        getBoardList({...searchParam})
    })

    return {
        session,
        boardMst,
        posts,
        isLoading,
        headers,
        paging,
        isPaging,
        getBoardList,
        getThumbUrl,
        pagingFunc,
        goToView,
        checkScrtMd,
        timestampToDateFormat,
    }
}

export const BoardGroupListSetup = (props, {emit}) => {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const session = computed(() => store.state.auth.session);
    const isMobile = computed(() => store.state.auth.isMobile);
    const {showMessage} = useAlert();
    const boardMst = ref(props.boardMstMap);
    const posts = ref([]);
    const isLoading = ref(true);
    const searchParam = reactive({
        boardMstSn: boardMst.value.boardMstSn,
        pageNo : 1,
        pageSize: boardMst.value.pgSize,
        boardCateSn: '',
        word: '',
        postGrpNo: props.postGrpNo,
        lrnerId: session.value.lrnerId,
    });
    const headers = computed(() => boardMst.value.boardItmList.split("$"));
    const paging = ref({pageNo: 1, pageSize: boardMst.value.pgSize, totalCount: 0});
    const isPaging = computed(() => boardMst.value.pgSize > 0);

    const pagingFunc = (num) => {
        if(!isPaging.value){
            return;
        }
        let queryParam = {...route.query};
        queryParam['pageNo'] = num;
        router.push({query: queryParam});
    }

    const addAnswer = (idx) => {
        goToWriteAnswer(session.value.lrnerId, boardMst, posts.value[idx]);
    }

    const clickDtl = (item) => {
        if(checkScrtMd(boardMst.value, item, item.rgtrId == session.value.lrnerId, props.isOper)){
            showMessage("비공개 게시글입니다.");
            return;
        }
        goToView(item.boardSecuKey, boardMst.value.boardId);
    }

    const getBoardList = (params) => {
        isLoading.value = true;
        getPostList(getBoardSearchParam(params, boardMst.value), posts, paging, props.boardCateList,() => {
            const groupPost = posts.value.find(x => x.postLvl === 0);
            posts.value = posts.value.filter(x => x.postLvl !== 0);
            if(groupPost) posts.value.unshift(groupPost);
            if(isMobile.value){
                emit('update:listTotalCount', paging.value.totalCount);
            }
            isLoading.value = false
        });
    }

    watch(() => route.query, () => {
        setParamsByQuery(route, searchParam, false);
        getBoardList({...searchParam});
    })

    onMounted(() => {
        // 그룹번호 필수
        if(props.postGrpNo == null || props.postGrpNo == undefined || props.postGrpNo == '0') {
            showMessage('잘못된 접근입니다.', exitBoardPage);
        }else{
            setParamsByQuery(route, searchParam, false);
            getBoardList({...searchParam});
        }
    })

    return {
        session,
        boardMst,
        posts,
        isLoading,
        headers,
        paging,
        isPaging,
        pagingFunc,
        addAnswer,
        getBoardList,
        clickDtl,
        validTarget,
        checkScrtMd,
        timestampToDateFormat,
    }
}

export const boardTimelineSetup = (props, {emit}) => {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const session = computed(() => store.state.auth.session);
    const isMobile = computed(() => store.state.auth.isMobile);
    const boardMst = ref(props.boardMstMap);
    const posts = ref([]);
    const customInfos = ref([]);
    const isLoading = ref(true);
    const searchParam = reactive({
        boardMstSn: boardMst.value.boardMstSn,
        pageNo : 1,
        pageSize: boardMst.value.pgSize,
        boardCateSn: '',
        word: '',
        postGrpNo: props.postGrpNo,
        lrnerId: session.value.lrnerId,
    });
    const headers = computed(() => boardMst.value.boardItmList.split("$"));
    const paging = ref({pageNo: 1, pageSize: boardMst.value.pgSize, totalCount: 0});
    const isPaging = computed(() => boardMst.value.pgSize > 0);
    const files = ref([]);

    const getAtchFiles = (idx) => {
        if(posts.value[idx].toggleAtchFile){
            posts.value[idx].toggleAtchFile = false;
            return;
        }
        getBoardAtchFiles(posts.value[idx].boardSecuKey, files, () => {
            posts.value[idx].toggleAtchFile = true;
        });
    }

    const downloadAtch = (file, idx) => {
        validNetwork(boardMst.value.atchDownNet, () => {
            // 첨부파일 업로드 네트워크 확인
            if(boardMst.value.atchDownNet == 'each'){
                if(session.value.itn && file.etnUrl){
                    showMessage(`은행 외부망에서 등재된 파일입니다.<br/>외부망에서 다운로드 가능합니다.`);
                }else if(!session.value.itn && file.itnUrl){
                    showMessage(`은행 내부망에서 등재된 파일입니다.<br/>내부망에서 다운로드 가능합니다.`);
                }else{
                    downloadBoardAtchFile(file.secuKey, file.fileNm, {});
                    posts.value[idx].toggleAtchFile = false;
                }
            }else{
                downloadBoardAtchFile(file.secuKey, file.fileNm, {});
                posts.value[idx].toggleAtchFile = false;
            }
        });
    }

    const showCmnt = (idx) => {
        posts.value[idx].toggleCmnt = !posts.value[idx].toggleCmnt;
    }

    const addAnswer = (idx) => {
        goToWriteAnswer(session.value.lrnerId, boardMst, posts.value[idx]);
    }

    const pagingFunc = (num) => {
        if(!isPaging.value){
            return;
        }
        let queryParam = {...route.query};
        queryParam['pageNo'] = num;
        router.push({query: queryParam});
    }

    const getBoardList = (params) => {
        isLoading.value = true;
        getPostList(getBoardSearchParam(params, boardMst.value), posts, paging, props.boardCateList,() => {
            if(isMobile.value){
                emit('update:listTotalCount', paging.value.totalCount);
            }
            getBoardCustomInfos(boardMst.value.boardMstSn, 0, customInfos);
            isLoading.value = false;
        });
    }

    watch(() => route.query, () => {
        setParamsByQuery(route, searchParam, false);
        getBoardList({...searchParam});
    })

    onMounted(() => {
        setParamsByQuery(route, searchParam, false);
        getBoardList({...searchParam});
    })

    return {
        session,
        boardMst,
        posts,
        customInfos,
        isLoading,
        headers,
        files,
        paging,
        isPaging,
        pagingFunc,
        addAnswer,
        showCmnt,
        getAtchFiles,
        downloadAtch,
        getBoardList,
        goToEdit,
        actLike,
        actPut,
        deletePost,
        validTarget,
        checkScrtMd,
        timestampToDateFormat,
        convertToStorageBytes,
    }
}

const getBoardSearchParam = (params, boardMst) => {

    if(boardMst.pgSize == 0){
        params['pageNo'] = null;
        params['pageSize'] = null;
    }

    if(boardMst.ansPrntYn !== 'Y'){
        params['postLvl'] = 1;
    }

    return params;
}