<template>
  <div class="loadmap-roundedbox-wrap">
    <div class="roundedbox">
      <div class="roundedbox-top">
        <div style="display: flex;">
          <img :src='data.thumbUrl' alt="">
          <div>
            <div class="top-row">
              <h5 class="roundedbox-title">{{data.sslDivNm}}</h5>
            </div>
            <div class="roundedbox-bottom" v-if="data.selfSocBoardCnt>0">
              <div class="badge" v-if="data.selfSocBoardCnt>=data.targetStandard.finish"><span class="badge-text">완료</span></div>
              <div class="badge badge-primary" v-else><span class="badge-text">진행</span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="roundedbox-bottom" id="mouse-over">
        <button class="roadmap-button" :style="{'background-color': `${data.buttonColor}`}">
          <span class="left">콘텐츠 등록건수</span>
          <span class="right" style="text-align: right;">{{ data.selfSocBoardCnt }}/{{ data.targetStandard.finish }}회</span>
          <span class="center" v-if="!isMobile">학습하기</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: 'SSLMobileHomeStatusCard',
  components: {},
  props: {
    data: Object,
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],
  setup() {
    // const item = computed(() => props.data);

    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);

    return {
      isMobile
    }
  }
}
</script>

<style>
/* 웹 */
.lxp-layout #kb-hrdcloud .roadmap-card {
  position: relative;
  width: 300px;
  flex: 0 0 300px;
  border-radius: 24px;
  overflow: hidden;
}

.lxp-layout #kb-hrdcloud .roadmap-card .card-visual {
  overflow: hidden;
  z-index: 2;
  position: relative;
  height: 500px;
  border-radius: 24px;
}

.lxp-layout #kb-hrdcloud .roadmap-card .card-visual .chart-star {
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 24px;
  background-color: var(--kb-yellow);
}

.lxp-layout #kb-hrdcloud .roadmap-card .card-contents {
  z-index: var(--kb-zindex-dropdown);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 165px;
  padding: 48px 32px;
  background-color: var(--kb-background-brown);
  border-radius: 22px;
}

.lxp-layout #kb-hrdcloud .roadmap-card .content-title {
  font-family: var(--kb-font-KBFGTextB);
  color: #84888b;
  line-height: 19px;
  margin-bottom: 1rem;
}

.lxp-layout #kb-hrdcloud .roadmap-card .content-text {
  font: bold 24px/30px var(--kb-font-KBFGTextB);
}

.lxp-layout #kb-hrdcloud .roadmap-card .content-badge {
  font: bold 0.8rem/1rem var(--kb-font-KBFGTextB);
  margin-left: 1rem;
  padding: 0.5rem;
  border-radius: 1rem;
  color: var(--kb-yellow);
  background-color: var(--kb-black);
}

.lxp-layout #kb-hrdcloud .roadmap-card .card-btn-area {
  position: absolute;
  top: 40px;
  right: 30px;
  z-index: var(--kb-zindex-dropdown);
}

.lxp-layout #kb-hrdcloud .roadmap-card .card-btn-area .card-pagination {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.lxp-layout #kb-hrdcloud .roadmap-card .content-info {
  width: 100%;
}

/* 모바일 */
.lxp-mobile-layout #kb-hrdcloud .roadmap-card {
  position: relative;
  width: 95%;
  margin: auto;
  border-radius: 24px;
  overflow: hidden;
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .card-visual {
  overflow: hidden;
  z-index: 2;
  position: relative;
  height: 415px;
  border-radius: 24px
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .chart-star {
  position: relative;
  width: 100%;
  height: 415px;
  border-radius: 24px;
  background-color: var(--kb-yellow);
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .card-contents {
  z-index: var(--kb-zindex-dropdown);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  padding: 25px 32px;
  background-color: var(--kb-background-brown);
  border-radius: 22px;
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .content-title {
  font-weight: bold;
  line-height: 20px;
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .content-text {
  line-height: 20px;
  font-weight: 300;
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .content-badge {
  font: normal 0.8rem/1rem var(--kb-font-KBFGTextB);
  margin-left: 0.5rem;
  padding: 5px 10px;
  border-radius: 1rem;
  color: var(--kb-yellow);
  background-color: var(--kb-black);
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .card-btn-area {
  position: absolute;
  top: 42px;
  right: 35px;
  z-index: var(--kb-zindex-dropdown);
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .card-btn-area .card-pagination {
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .content-info {
  width: calc(100% - 48px);
}

/* 공통 */
#kb-hrdcloud .roadmap-card .card-header {
  z-index: var(--kb-zindex-dropdown);
  position: absolute;
  left: 0;
  top: 0;
  padding: 48px 32px 0;
}

#kb-hrdcloud .roadmap-card .card-header .title {
  font-size: 20px;
  font-family: var(--kb-font-KBFGDisplayB);
  white-space: nowrap;
  color: var(--kb-white);
  margin-right: 5px;
}

#kb-hrdcloud .roadmap-card .card-visual .card-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--kb-silver);
  opacity: 0.5;
  display: none;
  justify-content: center;
  z-index: var(--kb-zindex-dropdown);
}

#kb-hrdcloud .roadmap-card .card-visual .card-img {
  width: 100%;
  z-index: 2;
}

#kb-hrdcloud .roadmap-card .card-visual .card-shadow .play-btn {
  cursor: pointer;
  background-color: rgba(43, 51, 63, 0.7);
  width: 90px;
  height: 50px;
  margin-top: 50%;
  border: 0.06rem solid white;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#kb-hrdcloud .roadmap-card .card-visual .card-shadow .icon-play {
  width: 24px;
  height: 24px;
}

#kb-hrdcloud .roadmap-card .card-visual .chart-star .star-bg {
  width: 220px;
  height: 220px;
  border-radius: 110px;
}

#kb-hrdcloud .roadmap-card .card-visual .chart-star .star-active {
  position: absolute;
  left: 45px;
  top: 70px;
  width: 220px;
  height: 220px;
  background-size: 220px
}

#kb-hrdcloud .roadmap-card .card-visual:hover {
}

#kb-hrdcloud .roadmap-card .card-visual:hover .card-shadow {
  display: flex;
}

#kb-hrdcloud .roadmap-card .roadmap-recmd {
  position: absolute;
  top: 75px;
  left: 30px;
  z-index: var(--kb-zindex-dropdown);
}

#kb-hrdcloud .roadmap-card .content {
  display: flex;
  flex-direction: column;
}

#kb-hrdcloud .roadmap-card .content-badge.progress-badge {
  color: var(--kb-black);
  background-color: var(--kb-yellow);
}

#kb-hrdcloud .roadmap-card .content-btn img {
  object-fit: none;
}

#kb-hrdcloud .roadmap-card .card-btn-area .card-pagination {
  cursor: pointer;
  margin: 8px 0;
  background-color: rgba(132, 136, 139, 0.5);
  transition-property: height, background-color;
  transition-duration: 0.4s;
  z-index: var(--kb-zindex-dropdown);
}

#kb-hrdcloud .roadmap-card .card-btn-area .card-pagination.card-pagination-active {
  height: 50px;
  background-color: var(--kb-white);
}

#kb-hrdcloud .roadmap-card .content-btn {
  width: 48px;
  height: 48px;
}

.text-is-ing {
  background-color: var(--kb-background-brown);
}
.text-is-end {
  background-color: var(--kb-yellow-negative);
}

</style>
