import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {
  checkSSLAccess,
  currentYear,
  getAllSubscribes,
  getCategoryClass, getCrseSnToDistCrseCd, getDistCrseCd,
  getLrnTrgtGrpNm,
  getRouteLrnTrgtGrp,
  getRouteLrnTrgtGrpMstSn,
  getSubscribes,
  getTargetDistCrseSn,
  getTimeText,
  goHome,
  isMyLrnTrgtGrpMstSn,
  sslRoutes,
} from '@/assets/js/modules/ssl/ssl-common';
import {
  ACT_GET_LEARN_TARGET,
  ACT_GET_MY_SOC_BOARD_LIST,
  ACT_GET_SOC_ACT_BOARD_LIST,
  ACT_GET_SOC_BOOK_LIST,
  ACT_GET_SOC_REF_LIST,
  ACT_GET_SOC_REL_STAT,
  ACT_GET_SOC_REL_WRITE_LIST,
  ACT_INSERT_SOC_ACT,
  ACT_UPDATE_BOOK_MAPNG,
  ACT_GET_MY_SSL_LST,
  MUT_REMOVE_SUBSCRIBE_MY,
  MUT_SET_POSTSN_LIST,
  MUT_SHOW_BADGE, BOARD_MODE_KEY,
} from '@/store/modules/ssl/ssl-index';
import {
  getItem,
  getItems,
  getPaging,
  initParams,
  initProgressBar, isSuccess,
  lengthCheck,
  numberComma,
  setParams,
  timestampToDateFormat,
} from '@/assets/js/util';
import {winOpen} from "@/assets/js/util";
import {computed, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch} from 'vue';
import {actTyCdDcds} from '@/assets/js/modules/common/common';
import {useAlert} from '@/assets/js/modules/common/alert';
import {isSSLPeriod} from '@/assets/js/modules/ssl/ssl-write';
import {Navigation} from 'swiper';
import {getThumbImg} from '@/assets/js/modules/baner/common-baner';
import {MUT_TOGGLE_MY_PORTRAIT_MODAL} from "@/store/modules/my/my";
import {getBadgeList} from "@/assets/js/modules/ssl/ssl-badge";
import {closeToggles} from "@/assets/js/ui.init";
import {saveToken} from "@/assets/js/localstorage.service";


export const sslMySetup = () => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const bookHistoryModal = ref(false);
  const timerModal = ref(false);
  const memoModal = ref(false);

  const selectValue = ref('2001038');
  const currYear = (new Date()).getFullYear().toString();
  const isHome = computed(()=>route.name=='SSLTotalMy'||route.params.view == 'myHome');

  //기본 카드형태로 보이기
  saveToken(BOARD_MODE_KEY, 'card');

  const historyEvents = {
    openTimeHistoryModal: () => {
      selectValue.value = '2001038';
      bookHistoryModal.value = true;
    },
    openMemoHistoryModal: () => {
      selectValue.value = '2001039';
      bookHistoryModal.value = true;
    },
  };

  const modalItem = ref();

  const memoTimerEvents = {
    openTimerModal: (item) => {
      modalItem.value = item;
      timerModal.value = true;
    },
    openMemoModal: (item) => {
      modalItem.value = item;
      memoModal.value = true;
    },
  };


  const distCrseCd = computed(() => route.params.distCrseCd);
  // const distCrseNm = computed(() => sslRoutes[route.params.distCrseCd] ? sslRoutes[route.params.distCrseCd].name : '');
  const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));
  const isMyLrnTrgt = computed(() => isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn.value));

  const myGroup = computed(() => store.state.ssl.group);
  const showMyPortraitModal = computed(() => store.state.my.showMyPortraitModal);

  const viewRoute = computed(() => {
    if (route.params.view) {
      return route.params.view;
    }
    return 'status';
  });

  // 리스트 카드/썸네일옵션 보일지 말지
  const opt = computed(()=>{
    if(viewRoute.value == 'complete'){
      return 'N';
    }else{
      return 'S';
    }
  })

  /* 년도, 과정조회 추가 */
  const filters = reactive({
    year: route.params.year?route.params.year:currYear,
    distCrseSn: 0,
    objType:'',
    sort:'new',
  });

  const selToggles = reactive({
    year: false,
    distCrseSn: false,
    objType:false,
    sort:false
  });

  const distOptions =ref([]);
  const getOptions= async ()=>{ //년도별 my입과 과정
    // console.log('year : ' + filters.year);
    distOptions.value = [];

    await store.dispatch(`ssl/${ACT_GET_MY_SSL_LST}`, filters.year).then(res => {
      if (lengthCheck(res)) {
        distOptions.value = getItems(res);
      }
      if(lrnTrgtGrpMstSn.value > 0 && filters.distCrseSn == 0){
        filters.distCrseSn = parseInt(distOptions.value.filter(x=>x.lrnTrgtGrpMstSn==route.params.lrnTrgtGrpMstSn).map(y=>y.distCrseSn).toString());
      }

    }).catch(e => {
      console.error(e);
    });
  }
  getOptions();

  const yearOptions = (() => {
    const options = [];
    let minYear = 2023;
    if (route.params.view == 'temporary'){ //임시저장인 경우 당해년도만 셋팅
      minYear = parseInt(currYear);
    }

    for(let i = parseInt(currYear); i >= minYear ; i--){
      options.push({value: i.toString(), display: `${i}년`});
    }
    return options;
  })();
  
  const subscribeOptions = ref([
      {key:'subscribe', value:'내가 구독'},
      {key:'subscribed', value:'나를 구독'},
  ]);

  const typeOptions = ref([
    {key:'note', value:'노트'},
    {key:'vod', value:'동영상'},
    {key:'pdf', value:'PDF'},
  ]);

  const sortOptions = ref([
    {key:'new', value:'최신순'},
    {key:'like', value:'인기순'},
  ]);

  const selSubscribe=(view)=>{
    router.push({name: 'SSLTotalMyView', params: {distCrseCd: 'total', lrnTrgtGrpMstSn: 0, year:filters.year, view:view}});
  };

  const distCrseNm = computed(()=>{
    if(filters.distCrseSn == ''){
      return '';
    }else {
      return distOptions.value.filter(x => x.distCrseSn == filters.distCrseSn).map(y => y.crseNm).toString();
    }
  });

  const initPageYear = ()=>{
    router.push({name: 'SSLTotalMyView', params: {distCrseCd: 'total', lrnTrgtGrpMstSn: 0, year:filters.year, view:route.params.view}});
  }
  const initPageSubj=()=>{
    let crseMstSn = distOptions.value.filter(x => x.distCrseSn == filters.distCrseSn).map(y => y.crseMstSn).toString();
    //console.log('crseMstSn : ' +crseMstSn);

    let tKey = 'total';
    let tTgms = 0;
    if(crseMstSn != ''){
      tKey = getCrseSnToDistCrseCd(crseMstSn);
      tTgms = distOptions.value.filter(x => x.distCrseSn == filters.distCrseSn).map(y => y.lrnTrgtGrpMstSn).toString();
    }

    router.push({name: 'SSLTotalMyView', params: {distCrseCd: tKey, lrnTrgtGrpMstSn: tTgms, year:filters.year, view:viewRoute.value}});
  }

  const goQuerySearch=()=>{
    router.push({name: 'SSLTotalMyView', query:{sortCd:filters.sort, type:filters.objType}});
  }

  /* 년도, 과정조회 추가 */

  const toggles = reactive({
    nav: false
  });
  const isReady = ref(false);

  watch(() => lrnTrgtGrpMstSn.value, () => {
    if (route.name === 'SSLMy' || route.name === 'SSLTotalMy') {
      isReady.value = false;
      checkSSLAccess(isMyLrnTrgt, distCrseCd, lrnTrgtGrpMstSn, myGroup, isAccess => {
        if (isAccess) {
          isReady.value = true;
        } else {
          goHome();
        }
      });
    }
  });

  watch(() => filters.year, ()=>{
    if(!['subscribe', 'subscribed'].includes(viewRoute.value)){
      getOptions();
    }
  });

  // 현재 입과된 정보 확인
  checkSSLAccess(isMyLrnTrgt, distCrseCd, lrnTrgtGrpMstSn, myGroup, isAccess => {
    if (isAccess) {
      isReady.value = true;
    } else {
      goHome();
    }
  });

  return {
    toggles,
    distCrseNm,
    viewRoute,
    lrnTrgtGrpMstSn,
    isMyLrnTrgt,
    isReady,
    historyEvents,
    memoTimerEvents,
    bookHistoryModal,
    selectValue,
    modalItem,
    memoModal,
    timerModal,
    showMyPortraitModal,
    route,

    filters, selToggles, yearOptions, getOptions, // subjNm,
    closeToggles, distOptions, initPageYear, initPageSubj, currYear, distCrseCd,
    isHome, subscribeOptions, selSubscribe,

    typeOptions, sortOptions, goQuerySearch,

    opt
  };
};


export const sslMyBoardSetup = (props) => {
  const store = useStore();
  const route = useRoute();

  const items = ref([]);
  const lmtCnt = computed(()=>props.lmtCnt);
  const paging = reactive({pageNo: 1, pageSize: (lmtCnt.value>0?lmtCnt.value:12), totalCount: 0, hasMore: false});
  const isLoading = ref(false);

  const lrnTrgtGrpMstSn = computed(()=>route.params.lrnTrgtGrpMstSn);
  const year= computed(()=>route.params.year?route.params.year:(new Date).getFullYear());
  const sortCd = computed(()=>props.sortCd?props.sortCd:(route.query.sortCd));

  const view = computed(() => route.params.view);
  const objType = computed(()=> route.query.type);

  // console.log("props.sortCd : " + props.sortCd);
  // console.log("route.query.sort : " + route.query.sort);
  // console.log("objType : " + objType.value);
  // console.log("sortCd : " + sortCd.value);
  // console.log("view : " + view.value);
  // console.log("routeName : " + route.name);
  // console.log("lrnTrgtGrpMstSn : " + lrnTrgtGrpMstSn.value);
  const stt = computed(() => {
    if (route.params.view === 'temporary') {
      return '01';
    }else{
      return '00';
    }
  });

  const getMySocsBoardList = (isInit) => { //내가 작성목록
    if (isInit) items.value = [];
    store.dispatch(`ssl/${ACT_GET_MY_SOC_BOARD_LIST}`, {
      lrnTrgtGrpMstSn: (route.name=='SSLTotalMy'?0:lrnTrgtGrpMstSn.value),
      stt: stt.value,
      year: year.value,
      sortCd: sortCd.value,
      cttType:objType.value,
      pageNo: paging.pageNo,
      pageSize: paging.pageSize,
    }).then(res => {
      if (lengthCheck(res)) {
        // items.value.push(...getItems(res));

        items.value.push(...getItems(res).map(x => ({
          ...x,
          date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
          day: timestampToDateFormat(x.pblcnDt, 'dd'),
          distCrseCd: getDistCrseCd(x.distCrseSn)
        })));
      }
      if(lmtCnt.value == 0) {
        paging.hasMore = getPaging(res).hasMore;
      }else{
        paging.totalCount = items.value.length;
      }
      isLoading.value = false;

      const opt  = reactive({
        div:'all',
        title: '',
        sortC: ''
      });

      store.commit(`ssl/${MUT_SET_POSTSN_LIST}`, {paging : getPaging(res), items:items.value.map(x=>x.socLrnPostSn), opt:opt}); // 이전,다음페이지 이동을 위해 추가함.
    }).catch(e => {
      console.error(e);
      isLoading.value = false;
    });
  };

  const getScoActBoardList = (isInit) => { //담은목록
    if (isInit) items.value = [];
    store.dispatch(`ssl/${ACT_GET_SOC_ACT_BOARD_LIST}`, {
      lrnTrgtGrpMstSn: props.lrnTrgtGrpMstSn,
      actTyCdDcd: actTyCdDcds.ACT_TY_CD_ADD,
      year:route.params.year,
      pageNo: paging.pageNo,
      pageSize: paging.pageSize,
    }).then(res => {
      if (lengthCheck(res)) {
        // 더보기 이므로 데이터 를 합친다.
        items.value.push(...getItems(res));
        paging.hasMore = getPaging(res).hasMore;


        const opt  = reactive({
          div:'all',
          title: '',
          sortC: ''
        });

        store.commit(`ssl/${MUT_SET_POSTSN_LIST}`, {paging : getPaging(res), items:items.value.map(x=>x.socLrnPostSn), opt:opt}); // 이전,다음페이지 이동을 위해 추가함.
      }
      isLoading.value = false;
    }).catch(e => {
      console.error(e);
      isLoading.value = false;
    });
  };

  const renderSocsBoardList = (isInit) => {
    paging.hasMore = false;
    isLoading.value = true;
    if (view.value === 'save') {
      getScoActBoardList(isInit);
    }else if(view.value === 'status'){
      items.value = [];
    } else {
      getMySocsBoardList(isInit);
    }
  };

  const moreFunc = () => {
    if (isLoading.value) return;
    isLoading.value = true;
    paging.pageNo++;
    initProgressBar();
    renderSocsBoardList();
  };

  watch(() => [route.params.view, route.params.year, props.lrnTrgtGrpMstSn], () => {
    if (view.value) {
      paging.pageNo = 1;
      renderSocsBoardList(true);
    }
  });

  // 첨들어 왔을때 선택된게 없으면 처리
  // if (route.params.lrnTrgtGrpMstSn > 0) {
    renderSocsBoardList(true);
  // }
  // else {
  //   router.push({name: 'SSLHome'}).then(() => {});
  // }
  // else{
  // 정책 변경
  // router.push({name: 'SSLHome'});
  // }

  return {
    isLoading,
    items,
    paging,
    moreFunc, view
  };

};

// 수정 예정 => 구독자의 글들을 보여질 예정
export const sslMySubscribeSetup = () => { //props
  const store = useStore();
  const route = useRoute();

  const {showConfirm} = useAlert();
  // 구독은 같은 학습그룹일때만 가능한다. ==> 2025년과정부터는 과정상관없이 학습자로만 구독함.
  // 그룹러닝일때는 구독이 없다.
  const isGroup = computed(() => route.params.distCrseCd === 'group');
  const view = computed(() => route.params.view);
  const isSubscribed = computed(() => view.value === 'subscribed');

  // const distCrseSn = computed(() => getDistCrseSns(route.params.distCrseCd));
  const subscribes = computed(() => {
    if (isSubscribed.value) {
      return getSubscribes(
          store.state.ssl.subscribe.target
              // .filter(x => x.lrnTrgtGrpMstSn === props.lrnTrgtGrpMstSn),
          // props.lrnTrgtGrpMstSn
      ).map(x => ({
        ...x,
        trgtLrnerId: x.lrnerId,
        trgtLrnerNm: x.lrnerNm,
        trgtDeptNm: x.deptNm,
      }));
    } else {
      return getSubscribes(
          store.state.ssl.subscribe.my
              // .filter(x => x.lrnTrgtGrpMstSn === props.lrnTrgtGrpMstSn)
              .map(x => ({...x, lrnerId: x.trgtLrnerId})),
          // props.lrnTrgtGrpMstSn
      )
    }
  });

  const deleteSubscribe = (subscribe) => {
    store.dispatch(`ssl/${ACT_INSERT_SOC_ACT}`, {
      trgtLrnerId: subscribe.trgtLrnerId,
      lrnTrgtGrpMstSn: subscribe.lrnTrgtGrpMstSn,
      lrnTrgtGrpNm: subscribe.lrnTrgtGrpNm,
      actTyCdDcd: actTyCdDcds.ACT_TY_CD_CANCEL_SUBSCRIBE,
    }).then(() => {}).catch(() => {});
  };

  const unSubscribe = (subscribe) => {
    showConfirm({
      text: `구독을 취소 하시겠습니까?`,
      callback: () => {
        deleteSubscribe(subscribe);
        store.commit(`ssl/${MUT_REMOVE_SUBSCRIBE_MY}`, {
          actTyCdDcd: actTyCdDcds.ACT_TY_CD_SUBSCRIBE,
          trgtLrnerId: subscribe.trgtLrnerId,
          lrnTrgtGrpMstSn: subscribe.lrnTrgtGrpMstSn,
        });
      },
    })
  };

  getAllSubscribes();

  return {
    isGroup,
    isSubscribed,
    subscribes,
    unSubscribe,
  };

};

export const sslMyWriteSetup = (props) => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const {focusFunc} = toRefs(props);

  const distCrseCd = computed(() => route.params.distCrseCd);
  const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));
  const memoModal = ref(false);
  const timerModal = ref(false);
  const isMobile = computed(() => store.state.auth.isMobile);

  // 글쓰기 버튼 노출은 ssl 기간중에만 가능
  const showWriteButton = computed(()=>isSSLPeriod(null, distCrseCd.value));


  // 글쓰기 가능 조건
  const enableWrite = computed(() => {
    // group 러닝일 경우 리더일 경우만 글을 쓸수 있다.
    if (distCrseCd.value === 'group') {
      return store.state.ssl.group.leaderLrnerId === store.state.auth.session.lrnerId && items.value.length > 0;
    } else if (distCrseCd.value === 'knowledge' || distCrseCd.value === 'hrd' || distCrseCd.value === 'live') {
      return items.value.length > 0
    } else if (distCrseCd.value === 'college') {
      // 입과된 사람들만 글 쓰기 가능
      return isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn.value);
    }
    return true;
  });

  const groupIdx = computed(() => {
    if (sslRoutes[distCrseCd.value] && sslRoutes[distCrseCd.value].group) {
      return sslRoutes[distCrseCd.value].group.map(x => x.lrnTrgtGrpMstSn).indexOf(lrnTrgtGrpMstSn.value);
    }
    return -1;
  });

  const isReady = ref(false);
  const isBook = computed(() => 'book' === route.params.distCrseCd);
  const useRel = computed(() => ['group', 'knowledge', 'hrd', 'book', 'live'].includes(route.params.distCrseCd));
  const useRef = computed(() => ['group'].includes(route.params.distCrseCd));

  const writeClass = computed(() => {
    // if(route.params.distCrseCd === 'self' || route.params.distCrseCd === 'trivia' || route.params.distCrseCd === 'college'){
    if(route.params.distCrseCd === 'selfGa' || route.params.distCrseCd === 'selfGi' ||route.params.distCrseCd === 'selfJa' || route.params.distCrseCd === 'selfIct'|| route.params.distCrseCd === 'selfDvl'|| route.params.distCrseCd === 'trivia' || route.params.distCrseCd === 'college'){
      return 'writing-default'
    }else if(route.params.distCrseCd === 'group'){
      return 'writing-group'
    }else if(route.params.distCrseCd === 'knowledge'){
      return 'writing-jisike';
    }else if(route.params.distCrseCd === 'hrd'){
      return 'writing-cloud'
    }else if(route.params.distCrseCd === 'book'){
      return 'writing-book'
    }else if (route.params.distCrseCd === 'live') {
      return 'writing-learninglive'
    }
    return '';
  });

  const items = ref([]);
  // 참고 리스트
  const written = ref([]);
  const memoItem = ref();
  const timerItem = ref();

  const goToHrd = (item) => {
    router.push({name: 'HrdCloudContentBridge', params: {lrnObjDtlDistSn: item.relSn, mode: 'detail'}});
  }

  const getRelList = () => {
    if(useRel.value){
      if(isBook.value){
        store.dispatch(`ssl/${ACT_GET_SOC_BOOK_LIST}`, {
          lrnTrgtGrpMstSn: lrnTrgtGrpMstSn.value,
          lrnerId: props.lrner ? props.lrner.lrnerId : '',
        }).then(res => {
          if(lengthCheck(res)) items.value = getItems(res).map(x => ({
            ...x,
            inputPage: '',
            percentage: (x.currentPage / x.totalPage) * 100
          }));
          items.value.push({isFree: true, lrnTrgtGrpMstSn: lrnTrgtGrpMstSn.value});
          isReady.value = true;
        });
      } else{
        const distCrseSn = getTargetDistCrseSn(distCrseCd.value);
        if (distCrseSn > 0) {
          store.dispatch(`ssl/${ACT_GET_SOC_REL_WRITE_LIST}`, {division: distCrseCd.value, distCrseSn: distCrseSn}).then(res => {
            if (lengthCheck(res)) {
              items.value = getItems(res).map(x => ({
                ...x,
                relThumb: getThumbImg(x.relThumb),
              }));
              // const resultItems = getItems(res);
              // resultItems.push(...getItems(res))
              // resultItems.push(...getItems(res))
              // resultItems.push(...getItems(res))
              // items.value = resultItems;
            }
            isReady.value = true;
            console.log("items.value ==", items.value);
          });
        } else {
          // const resultItems = []
          // for (let i = 0; i < 4; i++) {
          //   resultItems.push({
          //     relSn: i,
          //     relNm: `지식e학습자료${i}`,
          //   })
          // }
          // items.value = resultItems;
          isReady.value = true;
        }
      }
    }else{
      isReady.value = true;
    }
  }

  const getRefList = () => {
    if (useRef.value) {
      store.dispatch(`ssl/${ACT_GET_SOC_REF_LIST}`, lrnTrgtGrpMstSn.value).then(res => {
        if (lengthCheck(res)) {
          // group 러닝일 경우..., 추후 다른 조건이 생기면 수정
          // written.value = getItems(res).filter(x => x.refPost).map(x => x.refPost.split('^')).filter(x => x.length === 4).map(x => x[3]);
          // console.log(getItems(res).filter(x => x.refPost).map(x => x.refPost.split('^')).filter(x => x.length === 4).map(x => x[3]));
          written.value = getItems(res).filter(x => x.refPost).map(x => x.refPost.split('^')).filter(x => x.length === 4).map(x => x[3]);
        }
      });
    }
  }

  const isWritten = relSn => {
    if (relSn) {
      return written.value.includes(relSn.toString());
    }
  }

  const currentDate = ref(new Date());
  const {showMessage, showConfirm} = useAlert();
  const openContent = (item) => {
    if(item.objBgngDt >= currentDate.value.getTime() || item.objEndDt <= currentDate.value.getTime()) {
      showMessage('해당 학습자료 다운로드 가능기간이 아닙니다.<br/>화면 하단 학습일정을 확인하세요.');
    } else {
      // store.dispatch(`learn/${ACT_GET_KNOWLEDGE_E_LEARN_OBJECT}`, {lrnObjDtlDistSn: item.relSn,fileName: item.relTitle});
      winOpen(`${process.env.VUE_APP_CLOUDFRONT_URL}/${item.relEtnUrl}`);
    }
  }

  watch(() => lrnTrgtGrpMstSn.value, () => {
    items.value = [];
    written.value = [];
    getRelList();
    getRefList();
  });

  watch(() => timerModal.value, () => {
    getRelList();
  });

  getRelList();

  const updatePercentage = (idx) => {
    // 숫자 아닐때
    if (isNaN(items.value[idx].inputPage)) {
      showMessage('숫자를 입력 바랍니다.');
      items.value[idx].inputPage = items.value[idx].currentPage;
      return ;
    }

    if (items.value[idx].inputPage > items.value[idx].totalPage) {
      items.value[idx].inputPage = items.value[idx].totalPage;
    } else if (items.value[idx].inputPage < 0) {
      items.value[idx].inputPage = 0;
    }

    if (items.value[idx].currentPage === items.value[idx].inputPage || items.value[idx].inputPage == '') {
      document.getElementsByName('inputPage')[idx].placeholder = items.value[idx].currentPage;
      return ;
    }

    showConfirm({
      text: '입력한 현재 페이지를 저장하시겠습니까?',
      callback: () => {
        const newPercentage = (items.value[idx].inputPage / items.value[idx].totalPage) * 100;

        items.value[idx].percentage = newPercentage;
        items.value[idx].currentPage = items.value[idx].inputPage;

        store.dispatch(`ssl/${ACT_UPDATE_BOOK_MAPNG}`, items.value[idx]).then(res => {
          if (isSuccess(res)) {
            showMessage('현재 페이지가 저장되었습니다.');
            document.getElementsByName('inputPage')[idx].placeholder = items.value[idx].currentPage;
            items.value[idx].inputPage = '';
            if(res.badges && res.badges.length > 0){
              store.commit(`ssl/${MUT_SHOW_BADGE}`, res.badges);
              getBadgeList();
            }
          } else {
            showMessage('저장을 실패했습니다.<br> 관리자에게 문의바랍니다.');
          }
        })
        return ;
      },closeCallback: () => {
        document.getElementsByName('inputPage')[idx].placeholder = items.value[idx].currentPage;
        items.value[idx].inputPage = '';
      },
    })
  }

  const openMemoModal = (idx) => {
    if (isMobile.value) {
      focusFunc.value.openMemoModal(items.value[idx]);
    } else {
      memoItem.value = items.value[idx];
      memoModal.value = true;
    }
  }

  const openTimerModal = (idx) => {
    if (isMobile.value) {
      focusFunc.value.openTimerModal(items.value[idx]);
    } else {
      timerItem.value = items.value[idx];
      timerModal.value = true;
    }
  }



  return {
    updatePercentage,
    showWriteButton,
    enableWrite,
    isReady,
    items,
    writeClass,
    distCrseCd,
    groupIdx,
    modules: [Navigation],
    timestampToDateFormat,
    currentYear,
    goToHrd,
    getThumbImg,
    getTimeText,
    isWritten,
    openContent,
    memoModal,
    timerModal,
    openMemoModal,
    openTimerModal,
    memoItem,
    timerItem,

  }
}

export const sslMyActivityRelSetup = (props) => {

  const store = useStore();
  const route = useRoute();
  const myGroup = computed(() => store.state.ssl.group);
  const distCrseCd = computed(() => route.params.distCrseCd);
  const distCrseSn = computed(() => props.distCrseSn);
  const params = reactive({
    firstCnt: 0,
    secondCnt: 0,
    thirdCnt: 0
  });

  const getSocRelStat = () => {
    // const distCrseSn = getTargetDistCrseSn(distCrseCd.value);
    if (distCrseSn.value > 0) {
      store.dispatch(`ssl/${ACT_GET_SOC_REL_STAT}`, {division: distCrseCd.value, distCrseSn}).then(res => {
        if (lengthCheck(res)) setParams(params, getItem(res))
      });
    }
  }

  watch(() => distCrseCd.value, () => {
    initParams(params);
    getSocRelStat();
  });

  getSocRelStat();

  return {
    myGroup,
    distCrseCd,
    params,
    numberComma
  }
}


export const sslMyTopSetup = (props) => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const distCrseCd = computed(() => {
    if (props.childDistCrseCd && props.childDistCrseCd.length > 0) {
      return props.childDistCrseCd;
    }
    return route.params.distCrseCd;
  });

  const lrner = reactive({
    lrnerId: '',
    lrnerNm: '',
    chnNm:'',
    deptNm: '',
    deptCd: '',
    lrnTrgtGrpMstSn:0
  });

  const distCrseNm = computed(() => sslRoutes[distCrseCd.value] ? sslRoutes[distCrseCd.value].name : '' );
  const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));

  const isMobile = computed(() => store.state.auth.isMobile);
  const session = computed(() => store.state.auth.session);
  // 그룹러닝 나의 그룹
  const myGroup = computed(() => store.state.ssl.group);

  const categories = computed(() => {
    const output = [];
    if (distCrseNm.value) output.push(distCrseNm.value);
    // 셀프러닝의 경우 학습분야(과정) 을 추가한다.
    if (distCrseCd.value === 'self') {
      const lrnGroup = getRouteLrnTrgtGrp(lrnTrgtGrpMstSn.value);
      if (lrnGroup.lrnTrgtGrpNm) output.push(lrnGroup.lrnTrgtGrpNm);
    } else if (distCrseCd.value === 'group') {
      if (myGroup.value.distCrseSn > 0 && myGroup.value.distCrseLrnFldNm) output.push(myGroup.value.distCrseLrnFldNm);
    }
    return output;
  });

  const categoryClass = computed(() => getCategoryClass(route.params.distCrseCd));

  const mysslTop = ref(null);
  const targetY = ref(0);
  const el = ref(null);


//프로필 이름/부서
  const getLearnTarget = () => {
    // 학습자 정보 가져오기
    store.dispatch(`ssl/${ACT_GET_LEARN_TARGET}`, {lrnerId: session.value.lrnerId, lrnTrgtGrpMstSn: lrnTrgtGrpMstSn.value}).then(res => {
      if(lengthCheck(res)) {
        const item = getItem(res);
        setParams(lrner, item);
      }
    }).catch(() => {});
  }
  getLearnTarget();

  // 프로필 변경 모달
  const showMyPortraitModal = () => {
    store.commit(`my/${MUT_TOGGLE_MY_PORTRAIT_MODAL}`);
  }

  const scrollEvnet = () => {
    if(targetY.value > -1){
      if (window.scrollY > targetY.value) {
        el.value.classList.add('is-scroll');
      } else {
        el.value.classList.remove('is-scroll');
      }
    }
  }

  const goGroup = () => {
    if(myGroup.value.length > 0) {
      router.push({name: 'GroupView', params: {distCrseSn: myGroup.value[0].distCrseSn}});
    } else {
      router.push({name: 'GroupLearning'});
    }
  };
  const goKnowledge = () =>{
    router.push({name: 'KnowledgeELearning'});
  };

  if (isMobile.value) {
    onMounted(() => {
      targetY.value = mysslTop.value.clientHeight;
      el.value = document.querySelector('body .ssl');
      if(el.value){
        document.addEventListener('scroll', scrollEvnet);
        scrollEvnet();
      }
    });

    onBeforeUnmount(() => {
      if(el.value){
        document.removeEventListener('scroll', scrollEvnet);
        el.value.classList.remove('is-scroll');
      }
    });
  }

  watch(() => lrnTrgtGrpMstSn.value, () => {
    getLearnTarget();
  });


  return {
    session,
    myGroup,
    distCrseCd,
    distCrseNm,
    categoryClass,
    categories,
    mysslTop,
    getLrnTrgtGrpNm,
    goGroup,
    goKnowledge,
    lrner,
    showMyPortraitModal,
    getLearnTarget
  }
}
