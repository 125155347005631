import {useStore} from "vuex";
import {computed, ref} from "vue";
import {
    enrollSSL,
    getCrseSnToDistCrseCd,
    getMyLearnTargetList,
    sslRoutes,
    sslStatusStandard
} from "@/assets/js/modules/ssl/ssl-common";
import {ACT_GET_MY_STATUS} from "@/store/modules/ssl/ssl-index";
import {getItems, lengthCheck} from "@/assets/js/util";
import router from "@/router";
import {EffectCoverflow} from "swiper";

export const sslHomeStatusSetup = () => {

    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const learningRoadmapList = ref([]);

    const lrnTrgtGrpMstSns = ref(Object.values(sslRoutes).filter(x=>x.statusViewYn=='Y').map(y=>y.group[0].lrnTrgtGrpMstSn).toString());
    // console.log(lrnTrgtGrpMstSns.value.toString());
    const getMyStatus = () => {

        store.dispatch(`ssl/${ACT_GET_MY_STATUS}`,{strLrnTrgtGrpMstSns:lrnTrgtGrpMstSns.value})
            .then(res => {
                if (lengthCheck(res)) {
                    learningRoadmapList.value = getItems(res).map( x=> {
                        let key = getCrseSnToDistCrseCd(x.crseMstSn);

                        // console.log(key+' : '+x.crseMstSn);

                        let imgUrl = require('@/assets/lxp/images/ssl/home/'+key+'.svg');

                        let buttonColor = '#f4f4b5';
                        if (key == "selfGi") {
                            buttonColor = '#f4f4b5';
                        }else if(key == 'selfJa'){
                            buttonColor = '#FBEED8';
                        }else if(key == 'selfGa'){
                            buttonColor = '#EAFACE';
                        }else if(key == 'selfDvl'){
                            buttonColor = '#feebe5';
                        }else if(key == 'selfIct'){
                            buttonColor = '#daf8fd';
                        }else if(key == 'group'){
                            buttonColor = '#D8D8F5';
                        }
                        return {
                            sslDivCd : key,
                            sslDivNm : x.distCrseNm,
                            targetLrnTrgtGrpMstSn : x.lrnTrgtGrpMstSn,
                            targetStandard: sslStatusStandard[key],
                            selfSocBoardCnt: x.sortNum,
                            thumbUrl: imgUrl,
                            buttonColor: buttonColor
                        }
                    });
                }
            }).catch(e => {
            console.error(e);
            learningRoadmapList.value = [];
        });
    }
    getMyStatus();

    const goMain = (key, lrnTrgtGrpMstSn)  => {
        router.push({name: 'SSLMain', params: {distCrseCd: key, lrnTrgtGrpMstSn}}).then(() => {});
    }

    const myGroup = computed(() => store.state.ssl.group);
    const goLearn=(key, lrnTrgtGrpMstSn)=>{

        if (lrnTrgtGrpMstSn > 0) {
            goMain(key, lrnTrgtGrpMstSn);
        } else {
            // 그룹은 있지만 SSL 그룹러닝에 입과되지 않는 경우 강제 입과를 한다.
            if (key === 'group' && myGroup.value.distCrseSn > 0) {
                enrollSSL(lrnTrgtGrpMstSn, result => {
                    if (result > 0) {
                        getMyLearnTargetList(() => {
                            goMain(key, lrnTrgtGrpMstSn);
                        }).then(() => {});
                    } else {
                        // showMessage('서버처리 중 일시적인 장애가 발생하였습니다.<br>잠시 후 다시 시도 해 주시기 바랍니다.');
                    }
                });
            }else {
                goMain(key, lrnTrgtGrpMstSn);
                // enroll.key = key;
                // enroll.toggle = true;
            }
        }
    }
    return {
        session,
        learningRoadmapList,
        modules: [EffectCoverflow],
        goLearn
    }

}