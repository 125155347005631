<template>
<!--1234-->
<!--  {{category.length}}-->
  <div class="top-bar-row">
    <button class="icon-menu icon-menu-hamburger" :class="{'is-active':isMenuOpen}" @click="toggleMenu"></button>
    <div style="font: normal 11px/16px var(--kb-font-KBFGDisplayB); padding-left: 6px; padding-top: 3px; ">카테고리</div>
  </div>

<!--  <div class="logo" style="text-align: center;">-->
<!--    <button @click="toggleMenu"><i class="icon-menu-hamburger"></i></button>-->
<!--    <div style="font: normal 10px/1.25 var(&#45;&#45;kb-font-KBFGTextB); margin-top: 3px;">카테고리</div>-->
<!--  </div>-->
    <!--Menu-->
    <div class="side-bar" :class="{'active':isMenuOpen}" ref="menu">
      <button v-if="menuHistory.length > 0" @click="goBack"></button>
<!--      <div class="menu-box-1">-->
<!--        <ul  class="list" >-->
<!--          <template v-for="(category,idx) in totalCate" class="snb-item" :key="idx">-->
<!--              <li class="item " v-if="(!checkVice() && category.hrdCloudCateSn !== 5) || checkVice()"-->
<!--               :class="{'bg-gray':category.lvl < currLvl && idx > 0} ">-->
<!--                <a v-if="idx === 0 " :href="'https://lxp.kbstar.com/app/prep/hrdcloud'">홈</a>-->
<!--                <a v-else-if="category.lvl < currLvl"  href="javascript:" class="kb-nav-link" style="color: #A78C3A; font-weight: bold"-->
<!--                   @click="chgLvlCate(category.hrdCloudCateSn, category.hrdCloudCateNm, category.lvl ,category.hrdCloudCateSnLst ,$event);">-->
<!--                  {{ category.hrdCloudCateNm }}-->
<!--                  <span class="arrow-l"></span>-->
<!--                </a>-->
<!--                <a v-else href="javascript:" class="kb-nav-link"-->
<!--                   @click="chgLvlCate(category.hrdCloudCateSn, category.hrdCloudCateNm, category.lvl, category.hrdCloudCateSnLst,$event);">-->
<!--                  {{ category.hrdCloudCateNm }}-->
<!--                  <span :class="hasSubCategories(category.hrdCloudCateSn) ? 'arrow-r' : ''"></span>-->
<!--                </a>              &lt;!&ndash;하위카테고리가 있는경우 화살표 표시&ndash;&gt;-->
<!--              </li>-->
<!--          </template>-->
<!--        </ul>-->
<!--      </div>-->
      <div class="menu-container">
        <div class="top-menu">
          <button
              v-for="menu in topMenus"
              :key="menu.hrdCloudCateSn"
              :class="{ active: selectedTop === menu.hrdCloudCateSn }"
              @click="selectTopMenu(menu.hrdCloudCateSn)"
          >
            {{ menu.hrdCloudCateNm }}
          </button>
        </div>
        <div class="menu-content">
          <div class="menu-column">
            <ul>
              <li
                  v-for="menu in secondLevelMenus"
                  :key="menu.hrdCloudCateSn"
                  :class="{ active: selectedSecond === menu.hrdCloudCateSn }"
                  @click="selectSecondMenu(menu.hrdCloudCateSn)"
              >
                {{ menu.hrdCloudCateNm }}
              </li>
            </ul>
          </div>
          <div class="submenu-column">
            <ul>
              <li @click="getAllContentList()">
                전체보기
              </li>
              <li
                  v-for="menu in thirdLevelMenus"
                  :key="menu.hrdCloudCateSn"
                  :class="{ active: selectedThird === menu.hrdCloudCateSn }"
                  @click="getContentList(menu.hrdCloudCateSnLst)"
              >
                {{ menu.hrdCloudCateNm }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {computed, ref, watch, onMounted, reactive, onUnmounted} from "vue";
import {useStore} from "vuex";
import {getHrdCates, sortTyCdDcds} from "@/assets/js/modules/hrd/hrd-common";
import {pushQuery} from '@/assets/js/ui.init';


export default {
  name: "CloudMainMenu",
  props:{
    model:Object,
    modelValue: Number,
    func: Function,
    toggle: Boolean
  },
  setup() {
    const isMenuOpen = ref(false);
    const menu = ref(null);

    const hrdCloudCateSn = ref(0);

    const selectedTop = ref(1);
    const selectedSecond = ref(null);
    const selectedThird = ref(null);

    const totalCate = ref([
        {
          hrdCloudCateNm: '홈',
          hrdCloudCateSn: 0,
          lvl: 1
        },
    ]);

    // const menuData = computed(() => store.state.hrdcloud.hrdcloudCates);
    //
    // console.log("menuData = ", menuData.value);
    //
    // const topMenus = menuData.value.filter(item => item.lvl === 1);
    // const secondLevelMenus = menuData.value.filter(item => item.upHrdCloudCateSn === this.selectedTop);
    // const thirdLevelMenus = menuData.value.filter(item => item.upHrdCloudCateSn === this.selectedSecond);
    //
    // const topMenus = ref([
    //   { cateSn: 1, lvl: 1, upHrdCloudCateSn: 0, name: "주식" },
    //   { cateSn: 2, lvl: 1, upHrdCloudCateSn: 0, name: "상품" },
    //   { cateSn: 3, lvl: 1, upHrdCloudCateSn: 0, name: "연금" },
    //   { cateSn: 4, lvl: 1, upHrdCloudCateSn: 0, name: "자산·뱅킹" },
    //   { cateSn: 5, lvl: 1, upHrdCloudCateSn: 0, name: "고객센터" },
    // ]);
    // const secondLevelMenus = ref([
    //   { cateSn: 6, lvl: 2, upHrdCloudCateSn: 1, name: "HOME/관심" },
    //   { cateSn: 7, lvl: 2, upHrdCloudCateSn: 1, name: "종목검색" },
    // ]);
    // const thirdLevelMenus = ref([
    //   { cateSn: 8, lvl: 3, upHrdCloudCateSn: 6, name: "HOME" },
    //   { cateSn: 9, lvl: 3, upHrdCloudCateSn: 6, name: "주식 종목검색" }
    // ]);

    const selectTopMenu = async (cateSn) => {
      selectedTop.value = cateSn;
      secondLevelMenus.value = category.value.filter((x) => {
        return x.lvl === 2 && x.stt === '00' && x.upHrdCloudCateSn === selectedTop.value
      });
      selectedSecond.value = secondLevelMenus.value[0].hrdCloudCateSn;

      thirdLevelMenus.value = category.value.filter((x) => {
        return x.lvl === 3 && x.stt === '00' && x.upHrdCloudCateSn === selectedSecond.value
      });
    }

    const selectSecondMenu = async (cateSn) => {
      selectedSecond.value = cateSn;

      thirdLevelMenus.value = category.value.filter((x) => {
        return x.lvl === 3 && x.stt === '00' && x.upHrdCloudCateSn === selectedSecond.value
      });
    }

    const selectThirdMenu = async (cateSn) => {
      selectedThird.value = cateSn;


    }

    //1단계 카테고리가져오기
    const getMainCategories = async () => {
      if (!category.value) getHrdCates();

      if (category.value.length > 0 && totalCate.value.length === 1) {
        category.value.filter(x => {
          if (x.lvl === 1) {
            totalCate.value.push({
              hrdCloudCateNm: x.hrdCloudCateNm,
              hrdCloudCateSn: x.hrdCloudCateSn,
              lvl: x.lvl
            });
          }
        });
      }
    };


    const topMenus = ref([]);
    const secondLevelMenus= ref([]);
    const thirdLevelMenus= ref([]);
    const toggleMenu = (event) => {
      isMenuOpen.value = !isMenuOpen.value;
      event.stopPropagation();
      console.log("isMenuOpen:",isMenuOpen.value);
      if (topMenus.value.length === 0) {
        topMenus.value = category.value.filter((x) => {
          return x.lvl === 1 && x.stt === '00' && (x.hrdCloudCateSn !== 5 || checkVice())
        });
        secondLevelMenus.value = category.value.filter((x) => {
          return x.lvl === 2 && x.stt === '00' && x.upHrdCloudCateSn === selectedTop.value
        });
        selectedSecond.value = secondLevelMenus.value[0].hrdCloudCateSn;
        console.log("secondLevelMenus.value[0].lvl = ", secondLevelMenus.value[0]);
        thirdLevelMenus.value = category.value.filter((x) => {
          return x.lvl === 3 && x.stt === '00' && x.upHrdCloudCateSn === selectedSecond.value
        });

        topMenus.value.forEach(x => { x.hrdCloudCateNm = x.hrdCloudCateNm.replaceAll(/ /g, "\n"); })
      }
    }

    const handleClickOutside = (evnet) => {
      console.log("menu.value"+menu.value);
        if (menu.value && menu.value instanceof HTMLElement){
          if (!menu.value.contains(evnet.target)){
            isMenuOpen.value = false;
          }
        }
    };


    const categories = computed(() => store.state.hrdcloud.hrdcloudCates);
    const category = computed(() => store.state.hrdcloud.hrdcloudCates);
    const category1 = ref(0);

    const store = useStore();
    const session = computed(() => store.state.auth.session);

    //권한체크
    const checkVice = () => {
      if (session.value) {
        if (session.value.vice) {
          return true;
        } else if (session.value.jbpsCd) {
          if (session.value.jbpsCd.indexOf('A') === 0 || session.value.jbpsCd.indexOf('B') === 0) {
            return true;
          }
        }
      }
      return false;
    };

    //현재 카테고리 레벨 값
    const currLvl = ref(1);

    const chgLvlCate = (cateSn,cateNm,lvl,hrdCloudCateSnLst,evnet) => {
      console.log("currLvl.." + currLvl.value);
      console.log("lvl.." + lvl);
      evnet.stopPropagation();
      if (lvl == currLvl.value) {
        getSubCate(cateSn, cateNm,lvl);
        getContentList(hrdCloudCateSnLst);
      } else {
        goBack();
      }
    };


    //메뉴 히스토리 저장
    const menuHistory = ref([]);
    const getSubCate= (cateSn,cateNm,lvl) => {
      //현재 상태 히스토리에 저장
      menuHistory.value.push([...totalCate.value]);

      totalCate.value = [];
      totalCate.value.push({
        hrdCloudCateNm: '홈',
        hrdCloudCateSn: 0,
        lvl: 1});

      totalCate.value.push({
        hrdCloudCateNm: cateNm,
        hrdCloudCateSn: cateSn,
        lvl: lvl});

      //하위카테고리 가져오기
    const filterCategories = category.value.filter(
        (x) => { return x.lvl === (lvl+1) && x.upHrdCloudCateSn === cateSn && x.stt === '00'});
      //.log("categories 값..."+category.value.length);
    for (let i = 0; i < filterCategories.length; i++) {
      totalCate.value.push(filterCategories[i]);
  }
      currLvl.value = lvl+1;
};


    const searchParam = reactive({
      category1: 0,
      category2: 0,
      category3: 0,
      category4: 0,
      category5: 0
    });

    const search = reactive({
      keyword: '',
      sortTypCdDcd: '',
      pageNo: 0,
    });

    const getAllContentList = () => {
      getContentList(secondLevelMenus.value.find(x => x.hrdCloudCateSn === selectedSecond.value).hrdCloudCateSnLst);
    }

    const getContentList = (hrdCloudCateSnLst) => {
      search.sortTyCdDcd = sortTyCdDcds.SORT_TY_CD_LATEST;
      let cateLst = [];
      if (hrdCloudCateSnLst != null && hrdCloudCateSnLst.indexOf(',') > -1) {
        cateLst = hrdCloudCateSnLst.split(',');
        searchParam.category1=0;
        searchParam.category2=0;
        searchParam.category3=0;
        searchParam.category4=0;
        searchParam.category5=0;
      }
      for (let i = 0; i < cateLst.length; i++) {
        if ( i < 5){
          eval(`searchParam.category${i+1} = '${cateLst[i]}'`);
        }
      }
      isMenuOpen.value = !isMenuOpen.value;
      pushQuery({...search, ...searchParam});
      return searchParam;
    }


    const goBack = () => {
      console.log("goBack 함수호출!!");
      if (menuHistory.value.length > 0 ){
        const prevState = menuHistory.value.pop();
        //마지막 상태 복원
        totalCate.value = [...prevState];
      }
      currLvl.value--;
    };

    //하위 카테고리 있는지 확인
    const hasSubCategories =(cateSn) => {
      return categories.value.some((x) => x.upHrdCloudCateSn === cateSn && x.stt === '00');
    }

    watch(() => category1.value, () => {
    });

    watch(() => category.value, () => {
      getMainCategories();
    });

    onMounted(() => {
      getMainCategories();
      console.log("menu.value"+menu.value);
      document.addEventListener('click', handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });


    return {
      isMenuOpen,
      toggleMenu,
      category1,
      categories,
      checkVice,
      session,
      category,
      totalCate,
      hrdCloudCateSn,
      chgLvlCate,
      goBack,
      menuHistory,
      hasSubCategories,
      currLvl,
      getContentList,
      searchParam,
      menu,
      topMenus,
      secondLevelMenus,
      thirdLevelMenus,
      selectedTop,
      selectedSecond,
      selectedThird,
      selectTopMenu,
      selectSecondMenu,
      selectThirdMenu,
      getAllContentList
    };
  },
};
</script>
<style scoped>
.menu-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.top-menu {
  //display: flex;
  padding: 5px;
  background-color: #f8f8f8;
  width: 100%;
  border-bottom: 2px solid #ddd;
  font:normal 14px/18px var(--kb-font-KBFGTextB);
  overflow-x: auto;
  display: inline-flex;
  justify-content:space-between;
}
.top-menu button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  font:normal 14px/18px var(--kb-font-KBFGTextB);
  white-space: pre-line;
}
.top-menu button.active {
  color: var(--kb-primary);
}
.menu-content {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.menu-column {
  width: 40%;
  height: 100%;
  padding: 10px;
  overflow-y: auto;
  border-right: 1px solid #ddd;
}

.submenu-column {
  width: 60%;
  height: 100%;
  padding: 10px;
  overflow-y: auto;
  background-color: white;
}
ul {
  list-style: none;
  padding-bottom: 150px;
}
li {
  cursor: pointer;
  padding: 8px;
  font:normal 14px/18px var(--kb-font-KBFGTextB);
  margin-top: 10px;
}
li.active {
  color: var(--kb-primary);
}
</style>

