<template>
  <main class="kb-main" id="kb-hrdcloud-v2" style="padding: 15px 0px 15px 15px;">
    <div class="main-content main-component">
      <slot name="top" />
      <article v-if="learningRoadmapList" class="content-section">
        <header class="section-header" style="display: flex;">
          <h4 class="title">활동 지표</h4>
<!--          <div class="header-side"><a class="sub-text text-dark kb-mouse-cursor" @click.stop="$router.push({name:'HrdCloudRoadmapMain'})">전체보기</a></div>-->
        </header>
        <div class="section-body">
          <swiper v-if="learningRoadmapList.length > 0"
                  class="hrdcloud-swiper"
                  :modules="modules"
                  slides-per-view="auto"
                  effect="coverflow"
                  :coverflow-effect="{'rotate':0, 'stretch':0, 'depth':100, 'modifier':1, 'slideShadows':false}"
                  :centered-slides="false" >
            <swiper-slide  v-for="(roadmap,idx) in learningRoadmapList" :key="idx" class="roadmap-swiper">
              <SSLMobileHomeStatusCard @click="goLearn(roadmap.sslDivCd, roadmap.targetLrnTrgtGrpMstSn)" :data="roadmap" />
            </swiper-slide>
          </swiper>
          <LoadingDiv v-else />
        </div>
      </article>
    </div>
  </main>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import LoadingDiv from '@/components/common/LoadingDiv';
import SSLMobileHomeStatusCard from '@/components/ssl/home/mobile/SSLMobileHomeStatusCard.vue';
import {sslHomeStatusSetup} from "@/assets/js/modules/ssl/setup/ssl-home-status";

export default {
  name: 'SSLMobileHomeStatusV2',
  components: {
    SSLMobileHomeStatusCard, LoadingDiv, Swiper, SwiperSlide},
  props: {
    mainList: Array
  },
  setup: sslHomeStatusSetup,
};
</script>