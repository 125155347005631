<template>
  <div class="section-wrapper" style="padding-bottom: 72px;">
    <article class="myssl-section section-sbadge">
      <header class="section-header">
        <h3 class="title">배지
          <small class="text-muted">{{myBadges.length}}</small> <!-- /{{activeBadges.length}} -->
          <button class="kb-btn-right"><i class="icon-right"></i></button>
        </h3>
      </header>
      <div class="section-body">
        <div class="sbadge-container" >
          <div class="sbadge-wrapper my-sbadge-wrapper" style="grid-template-columns: repeat(auto-fill, 108px) !important;max-height: unset !important;">
            <!-- badge -->
            <SSLBadgeTitle v-for="(badge, idx) in activeBadges"
                           :key="idx"
                           :badge-ty-cd-dcd="badge.badgeTyCdDcd"
                           :is-active="badge.isActive"
                           :is-new="badge.isNew"
                           :hidden-yn="badge.hiddenYn"
                           :badge-data="badge"
                           :show-title="true"
                           :show-tooltip="false"
                           @click="showBadgeModal(badge)"
            />
          </div>
          <!--          <div class="board-list-actions">-->
          <!--            <button class="kb-btn-more">-->
          <!--              <span class="text">더보기</span>-->
          <!--            </button>-->
          <!--          </div>-->
        </div>
      </div>
    </article>

    <SSLBadgeViewModal
        v-model="badgeModal"
        :selected-badge="selectedBadge"
    />

  </div>
</template>

<script>

import {computed, ref, watch} from 'vue';
import SSLBadgeTitle from '@/components/ssl/badge/SSLBadgeTitle';
import {onBeforeRouteLeave, useRoute} from 'vue-router';
import SSLBadgeViewModal from '@/components/ssl/badge/mobile/SSLBadgeViewModal';
import {getListFunc} from "@/assets/js/ui.init";
import {ACT_GET_COM_BADGE_TY_LIST} from "@/store/modules/badge/badge";
import {ACT_GET_BADGE_LIST} from "@/store/modules/ssl/ssl-index";

export default {
  name: 'SSLMobileMyBadgeFlex',
  components: {SSLBadgeViewModal, SSLBadgeTitle},
  setup() {
    const route = useRoute();
    const currentDate = new Date();
    const currentYear = computed(()=> route.params.year?route.params.year.toString():new Date().getFullYear().toString());
    const currentTimestamp = currentDate.getTime();
    const newRange = 1000 * 60 * 60 * 24 * 7;

    const myBadges = ref([]);
    const sslBadges = ref([]);

    getListFunc(`badge/${ACT_GET_COM_BADGE_TY_LIST}`, {year: currentYear.value, badgeKindCdDcd: "2065001"}, sslBadges, null);
    getListFunc(`ssl/${ACT_GET_BADGE_LIST}`, {year: currentYear.value}, myBadges, null);

    const activeBadges = computed(() => {
      // return sslBadges.value.filter(x => !x.showYears || x.showYears.includes(currentYear)).map(x => {
      return sslBadges.value.filter(x => x.year === currentYear.value).map(x => {
        const targetBadge = {
          badgeTySn: x.badgeTySn,
          badgeTyCdDcd: x.badgeTyCdDcd,
          isActive: false,
          isNew: false,
          badgeName: null,
          badgeDesc: null,
          hiddenYn: x.hiddenYn,
        };
        const found = myBadges.value.filter(y => x.badgeTyCdDcd === y.badgeTyCdDcd);
        if(found.length > 0){
          targetBadge.isActive = true;
          targetBadge.isNew = found.getDt + newRange >= currentTimestamp;
        }

        if (x.badgeTyNm instanceof Object) {
          targetBadge.badgeName = x.badgeTyNm[currentYear.value];
        } else {
          targetBadge.badgeName = x.badgeTyNm;
        }

        if (x.cdDesc instanceof Object) {
          targetBadge.badgeDesc = x.cdDesc[currentYear.value];
        } else {
          targetBadge.badgeDesc = x.cdDesc;
        }

        if(x.hiddenYn === 'Y' && !found) {
          targetBadge.badgeName = x.hiddenBadgeTyNm;
          targetBadge.badgeDesc = x.hiddenBadgeCn;
        }

        return targetBadge;
      })
      .filter(y=>y.isActive) //내 배지만
      .sort((a,b) => {
        if (a.badgeTySn < b.badgeTySn) { return -1; }
        else {return 1;}
      }).sort((a) => {
        if (a.isActive) { return -1; }
        else {return 1;}
      }).sort((a) => {
        if (a.isNew) { return -1; }
        else {return 1;}
      });
      // .sort((a) => {
      //   if (a.isActive) { return -1; }
      //   else {return 1;}
      // })
      // .sort((a) => {
      //   if (a.isNew) { return -1; }
      //   else {return 1;}
      // });
    });
    const badgeModal = ref(false);
    const selectedBadge = ref(null);

    const showBadgeModal = (badge) => {
      selectedBadge.value = badge;
      badgeModal.value = true;
    }

    onBeforeRouteLeave(() => {
      if(badgeModal.value){
        badgeModal.value = false;
        return false;
      }
      return true;
    });

    watch(()=>route.params.year, ()=>{
      // console.log('currentYear : ' + route.params.year);
      getListFunc(`badge/${ACT_GET_COM_BADGE_TY_LIST}`, {year: currentYear.value, badgeKindCdDcd: "2065001"}, sslBadges, null);
      getListFunc(`ssl/${ACT_GET_BADGE_LIST}`, {year: currentYear.value}, myBadges, null);
    });

    return {
      sslBadges,
      myBadges,
      activeBadges,
      badgeModal,
      selectedBadge,
      showBadgeModal
    }


  }
}
</script>
