<template>


  <div class="hrdcloud-list-wrapper ssl-my-home">
    <template v-if="isLoading">
      <LoadingDiv v-model="isLoading" />
    </template>
    <template v-else>
      <!-- board-list -->
      <template v-if="items.length > 0">
        <SSLNoteCard :slides-per-view='"auto"'
                     :sslItems="items"
        />
      </template>
      <template v-else>
        <div class="search-container">
          <div class="result-empty" style="text-align: center; margin-top: 30px; margin-bottom: 30px;"><img
              src="../../../assets/lxp/images/common/img_empty.png" alt="">
            <p class="text">검색된 SSL이 없습니다.</p>
          </div>
        </div>
      </template>
    </template>
  </div>

  <!--    <SSLMyTop/>-->
  <!--    <SSLBoardList-->
  <!--        :is-loading="isLoading"-->
  <!--        :items="items"-->
  <!--        :paging="paging"-->
  <!--        :more-func="moreFunc"-->
  <!--        :opt="opt"-->
  <!--    />-->

</template>

<script>
// import SSLMyTop from '@/components/ssl/my/SSLMyTop';

// import SSLBoardList from '@/components/ssl/SSLBoardList';
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import SSLNoteCard from "@/components/main/SSLNoteCard.vue";
import {sslLearnerBoardSetup} from "@/assets/js/modules/ssl/ssl-learn";

export default {
  name: 'SSLLearnerBoardSwiper',
  components: {
    SSLNoteCard, LoadingDiv,
    // SSLBoardList,
    // SSLMyTop,
  },
  props:{
    sortCd:{
      type:String,
      default:null
    },
    lmtCnt:{
      type:Number,
      default:0
    },
    opt:{
      type:String,
      default:'N'
    }
  },
  setup: sslLearnerBoardSetup
}
</script>
