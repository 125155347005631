import {useAlert} from "@/assets/js/modules/common/alert";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {closeToggles} from "@/assets/js/ui.init";
import {
    dateToDateFormat,
    emptyNumberOnClick, getTodayDate,
    initParams,
    isSuccess,
    nullEmpty,
    numberComma,
    numbersOnly,
    paramCheck, replaceDateToYmd,
    setParams,
    stringCheck, timestampToDateFormat, ymdStrToFormat
} from "@/assets/js/util";
import {
    applyBtnEnable,
    cancelSupport,
    checkUploadBinaries, getbookCostYear, getExamFee, getFileUploadFailResults, getInvProtEdu, getInvProtEduByLcsnCd,
    proofDivCdDcds,
    saveSupport, setCostProof, showComplete,
    uploadCostproof,
    extensions
} from "@/assets/js/modules/support/support-common";
import {
    ACT_DELETE_CANCEL_LRN_SPRT_LCSN,
    ACT_INSERT_LRN_SPRT_LCSN,
    ACT_UPDATE_LEARN_SUPPORT_LICENSE, MUT_CLOSE_SUPPORT, MUT_HIDE_LICENSE_MAIN, MUT_SHOW_MAIN
} from "@/store/modules/support/support";

import {useStore} from "vuex";
// import store from "@/store";

export const supportLicenseSetup = (props, {emit}) => {
    const {showMessage, showLoading, hideLoading} = useAlert(); // showConfirm
    const isLoading = ref(false);
    const isReadOnly = computed(() => props.data && props.data.aplySttCdDcd === '2106006');
    const userAgreement = ref(false), showSearchModal = ref(false);
    const store = useStore();

    const isMobile = computed(() => store.state.auth.isMobile);

    // const extensions = ref(['bmp','gif','jpe','jpeg','jpg','svg', 'png', 'tif']);

    const license = ref({
        lcsnSn: null,
        lcsnCd: null,
        lcsnNm: null,
        grd1Cd: null,
        grd2Cd: null,
        trnAidAmt: 0,           // 연수_보조_금액
        invProtEduGrpAmt: 0,    // 투자_보호_교육_집합_금액
        invProtEduCyberAmt: 0,  // 투자_보호_교육_사이버_금액
        examFeeSprtAmt: 0,      // 응시료_지원_금액
        bookCostSprtAmt: 0,     // 교재_비용_지원_금액
        pblcnInstNm: null,      // 발급처
        aplyVldBgngYmd: null,   // 신청가능 응시 시작일
        aplyVldEenYmd: null     // 신청가능 응시 종료일
    });

    const expAmt = ref(0);
    const invProtEduDivs = ref([]);

    const formModes = reactive({
        isInvProtEdu: false,    // 투자 보호 교육 여부 (집합,사이버)
        isExamFeeSprt: false,   // 응시료 지원 금액
        isBookCostSprt: false,  // 교재 비용 지원 금액
        isTrnAid: false         // 연수 보조 금액
    });

    const param = reactive({
        costLcsnSn: 0,
        lcsnSn: null,
        lcsnCd: null,
        lcsnNm: null,
        grd1Cd: null,
        grd2Cd: null,
        acqsUpdtDiv: '',              // 취득 갱신 구분
        lcsnExamYmd: isMobile.value ? null : '',    // 자격증 응시 일자
        passAnnonYmd: isMobile.value ? null : '',   // 합격 발표 일자
        lcsnRegYmd: isMobile.value ? null : '',     // 자격증 등록 일자
        updtAplyYmd: '',              // 갱신 신청 일자
        updtVldYmd: '',               // 갱신 유효 일자
        certNo: '',                   // 인증 번호
        distCrseSn: 0,                // 배포과정 일련번호
        distCrseNm: '',               // 배포과정 명
        costAplyYn : 'Y',             // 비용신청여부
        invProtEduDiv: '',            // 투자자보호교육 구분
        invProtEduCost: 0,            // 투자 보호 교육 비용
        updtCost: 0,                  // 갱신 비용
        acdmFee: 0,                   // 학원비
        examFee: 0,                   // 응시료
        bookCost: 0,                  // 교재비
        totAmt: 0,                    // 합계 금액
        sprtAmt: 0,                   // 지원 금액
        rtnYn:'N',
        rtnAmt:0,
        addAmtYn:'N',
        addAmt:0,
        aprvrId: '',                  // 승인자 ID
        aprvrNm: '',                  // 승인자 명
        aprvDt: '',                   // 승인자 일시
        regYn: '',                    // 등재 여부
        stt: '',
        aplySttCdDcd: '',
    });

    const costAplies = [{nm:'신청', val:'Y'}, {nm:'신청 안함', val:'N'}];
    const invProtEduDivNm = ref('투자자보호교육 구분을 선택해주세요');

    const toggles = reactive({
        invProtEduDivs: false,
        lcsnExamYmd: false,
        passAnnonYmd: false,
        costAplies: false,
    });

    const isLicenseSearch = ref(false);

    const searchLicense = () => {
        isLicenseSearch.value = true;
        store.commit(`support/${MUT_HIDE_LICENSE_MAIN}`);
    }

    const searchModalFunc = () => {
        showSearchModal.value=true;
    };

    const licenseFiles = ref({ files: [], removed: [], binaries: [] }), // 자격증
        certFiles = ref({ files: [], removed: [], binaries: [] }),    // 투자자보호교육수료증
        receiptFiles = ref({ files: [], removed: [], binaries: [] }), // 영수증 (도서주문 내역 및 영수증)
        acadRegCert = ref({ files: [], removed: [], binaries: [] }),  // 수강증 및 학원비 영수증
        examFeeFiles = ref({ files: [], removed: [], binaries: [] }); // 응시료 영수증

    const usrAgrmntRef = ref(null);

    // 자격증 선택했을때 폼 초기화
    const initForm = () => {
        closeToggles(toggles);
        userAgreement.value = false;
        initParams(param);
        // invProtEduDivNm = '투자자보호교육 구분을 선택해주세요';
        initParams(formModes);
    };

    const initLocalParams = () => {
        initForm();
        initParams(license.value);
        initParams(licenseFiles.value);
        initParams(certFiles.value);
        initParams(receiptFiles.value);
        initParams(acadRegCert.value);
        initParams(examFeeFiles.value);
        userAgreement.value = false;
        if(props.data == null) {
            usrAgrmntRef.value.allUnChk();
        }
        // closeModal();
        isLoading.value = false;
    };

    const closeModal = () => {
        if (props.reList instanceof Function) props.reList();
        initLocalParams();

        if(isMobile.value) {
            if(props.data == null){
                store.commit(`support/${MUT_CLOSE_SUPPORT}`);
            }else {
                store.commit(`support/${MUT_SHOW_MAIN}`);
            }
        }else{
            emit('update:modelValue',false);
        }
    };

    const validate = () => {
        if (paramCheck(license.value.lcsnSn) ) {
            showMessage('자격증을 선택해주세요');
            return false;
        }
        if (paramCheck(license.value.pblcnInstNm)) {
            showMessage('발급처를 입력해주세요');
            return false;
        }

        if (paramCheck(param.lcsnExamYmd)|| param.lcsnExamYmd == undefined) {
            showMessage('자격증 응시 일자를 입력해주세요');
            return false;
        }

        if (license.value.aplyVldBgngYmd != null && license.value.aplyVldBgngYmd != ''){
            if(replaceDateToYmd(param.lcsnExamYmd) < replaceDateToYmd(license.value.aplyVldBgngYmd)){
                showMessage('해당 자격증은 "'+license.value.aplyVldBgngYmd+'이후"<br>응시한 자격증에 대해서 신청이 가능합니다.');
                return false;
            }
        }

        if (license.value.aplyVldEndYmd != null && license.value.aplyVldEndYmd != '' && license.value.aplyVldEndYmd != '9999-12-31'){

            if(replaceDateToYmd(param.lcsnExamYmd) > replaceDateToYmd(license.value.aplyVldEndYmd)){
                showMessage('해당 자격증은 "'+license.value.aplyVldEndYmd+'이전"까지<br>응시한 자격증에 대해서 신청이 가능합니다.');
                return false;
            }
        }

        if (paramCheck(param.passAnnonYmd)|| param.passAnnonYmd == undefined) {
            showMessage('합격 발표 일자를 입력해주세요');
            return false;
        }


        // if (paramCheck(param.lcsnRegYmd)) {
        //     showMessage('자격증 등록 일자를 입력해주세요');
        //     return false;
        // }
        if (formModes.isInvProtEdu) {
            if (invProtEduDivs.value && invProtEduDivs.value.length > 0) {
                if (paramCheck(param.invProtEduDiv)) {
                    showMessage('투자자보호교육 구분을 선택해주세요');
                    return false;
                }
            }
            // if (paramCheck(param.invProtEduCost)) {
            //   showMessage('투자자보호교육 비용을 입력해주세요');
            //   return false;
            // }
            // 수료증
        }
        // if (formModes.isTrnAid) {
        //   if (paramCheck(param.acdmFee)) {
        //     showMessage('학원비를 입력해주세요');
        //     return false;
        //   }
        // }
        // if (formModes.isExamFeeSprt) {
        //   if (paramCheck(param.examFee)) {
        //     showMessage('응시료를 입력해주세요');
        //     return false;
        //   }
        // }
        // if (formModes.isBookCostSprt) {
        //   if (paramCheck(param.bookCost)) {
        //     showMessage('교재비를 입력해주세요');
        //     return false;
        //   }
        // }
        if (!chkPassYmd()){
            showMessage("입행 전 취득 또는 취득일 2년경과한 자격증은<br>단순 등록만 가능하며 비용지원은 불가합니다");
        }
        param.passAnnonYmd = replaceDateToYmd(param.passAnnonYmd);

        if(license.value.grd1Cd != null && expAmt.value > 0 && param.costAplyYn == 'Y') { //등급자격증인 경우 금액 안내함

            let txt;
            if(param.totAmt == null){
                param.totAmt = 0;
            }
            txt = '지원예정금액으로 최종신청금액이 반영됩니다.<br><br>합계금액(실소요비):'+numberComma(param.totAmt)+'원<br>지원예정금액:<span class="text-red">'+numberComma(expAmt.value)+'원</span>';
            showMessage(txt);
            // showConfirm({
            //     text: txt,
            //     callback: () => {
            //         goApply();
            //     },
            // });
        // }else{
        //     goApply();

        }

        return true;
    };

    const apply = () => {

        if(!chkPassYmd() && (param.totAmt > 0 || expAmt.value > 0) && param.costAplyYn == 'Y'){
            param.invProtEduCost = 0;
            param.acdmFee = 0;
            param.examFee = 0;
            param.bookCost = 0;
            param.totAmt = 0;
            expAmt.value = 0;
            showMessage("입행 전 취득 또는 취득일 2년경과한 자격증은<br>단순 등록만 가능하며 비용지원은 불가합니다");
            return false;
        }
        goApply();

    };

    const goApply = async () => {
        nullEmpty(param);
        showLoading();

        let applyRes = null, lcnsUpRes = null, certUpRes = null, receiptUpRes = null, acadRegCertUpRes = null, examFeeUpRes = null;

        param.lcsnExamYmd = replaceDateToYmd(param.lcsnExamYmd);
        param.passAnnonYmd = replaceDateToYmd(param.passAnnonYmd);
        param.lcsnRegYmd = replaceDateToYmd(param.lcsnRegYmd);
        param.updtAplyYmd = replaceDateToYmd(param.updtAplyYmd);
        param.updtVldYmd = replaceDateToYmd(param.updtVldYmd);

        if (param.costLcsnSn > 0) {
            applyRes = await saveSupport(isLoading, `support/${ACT_UPDATE_LEARN_SUPPORT_LICENSE}`, {
                costLcsnSn: param.costLcsnSn,
                params: {...param,
                    removedFiles: licenseFiles.value.removed.concat(certFiles.value.removed).concat(receiptFiles.value.removed).concat(acadRegCert.value.removed).concat(examFeeFiles.value.removed)
                }}, userAgreement, validate);
        } else {
            applyRes = await saveSupport(isLoading, `support/${ACT_INSERT_LRN_SPRT_LCSN}`, param, userAgreement, validate);
            if (applyRes) {
                param.costLcsnSn = applyRes.costLcsnSn;
            }
        }

        try {
            if (param.costLcsnSn > 0) {
                if (checkUploadBinaries(licenseFiles)) {
                    lcnsUpRes = await uploadCostproof({
                        params: {costLcsnSn: param.costLcsnSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_LCSN_COPY},
                        files: licenseFiles.value.files,
                    });
                }
                if (checkUploadBinaries(certFiles)) {
                    certUpRes = await uploadCostproof({
                        params: {costLcsnSn: param.costLcsnSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_LCSN_INV_PROT_EDU_CERT},
                        files: certFiles.value.files,
                    });
                }
                if (checkUploadBinaries(receiptFiles)) {
                    receiptUpRes = await uploadCostproof({
                        params: {costLcsnSn: param.costLcsnSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_LCSN_BOOK_FEE},
                        files: receiptFiles.value.files,
                    });
                }
                if (checkUploadBinaries(acadRegCert)) {
                    acadRegCertUpRes = await uploadCostproof({
                        params: {costLcsnSn: param.costLcsnSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_LCSN_CERT},
                        files: acadRegCert.value.files,
                    });
                }
                if (checkUploadBinaries(examFeeFiles)) {
                    examFeeUpRes = await uploadCostproof({
                        params: {costLcsnSn: param.costLcsnSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_LCSN_EXAM_FEE},
                        files: examFeeFiles.value.files,
                    });
                }

                // let failStr = getFileUploadFailResults(
                //     {title: '자격증', res: lcnsUpRes},
                //     {title: '투자자보호교육수료증', res: certUpRes},
                //     {title: '수강증 및 학원비 영수증', res: receiptUpRes},
                //     {title: '투자자보호교육수료증', res: acadRegCertUpRes},
                //     {title: '응시료 영수증', res: examFeeUpRes}
                // );

                let failed = getFileUploadFailResults(lcnsUpRes, certUpRes, receiptUpRes, acadRegCertUpRes, examFeeUpRes);

                if (isSuccess(applyRes)) {
                    showComplete(isLoading, applyRes, failed,()=> {
                        closeModal();
                    });
                }
            } else {
                if(applyRes.result && applyRes.result.number === 301) {
                    showMessage(applyRes.result.message)
                }
                console.log(applyRes)
            }
        } catch (e) {
            console.error(e);
        }

        hideLoading();
    };

    const selectInvProtEduDivs = (idx) => {
        let selected = invProtEduDivs.value[idx];
        param.invProtEduDiv = selected.invProtEduDiv;
        // invProtEduDivNm = selected.nm;
        param.invProtEduCost = selected.amt;
        toggles.invProtEduDivs = false;

        let examFee = getExamFee(license.value.lcsnCd, selected.year);
        let bookCost = getbookCostYear(license.value.lcsnCd, selected.year);
        // param.acdmFee = license.value.trnAidAmt;
        param.examFee = (stringCheck(examFee) ? examFee[0].amt : license.value.examFeeSprtAmt);
        param.bookCost = (stringCheck(bookCost) ? bookCost[0].amt : license.value.bookCostSprtAmt);
    };

    const changeLicense = () => {
        if (param.costLcsnSn == 0) {
            initForm();
        }

        if(param.costLcsnSn > 0) {
            initParams(param);
            // invProtEduDivNm = '투자자보호교육 구분을 선택해주세요';
            initParams(formModes);

            initParams(license.value);
            initParams(licenseFiles.value);
            initParams(certFiles.value);
            initParams(receiptFiles.value);
            initParams(acadRegCert.value);
            initParams(examFeeFiles.value);
        }

        if (license.value.lcsnCd) {
            setParams(param, license.value);
            invProtEduDivs.value = getInvProtEduByLcsnCd(license.value.lcsnCd);
        }

        /*
        *
        등급외 자격증 입력폼
          - 투자자 보호 교육비 / 응시료 / 교재비 (펀드투자권유자문)
          - 응시료 / 교재비
          - 응시료
        *
        * */
        setFormModes();
        // formModes.isInvProtEdu = license.value.invProtEduGrpAmt > 0 && license.value.invProtEduCyberAmt > 0; // 투자자보호교육비
        //
        // formModes.isExamFeeSprt = license.value.trnAidAmt > 0 || license.value.examFeeSprtAmt > 0 || ['WR7B', 'WR7C', 'WR7D', 'WR9D', 'WR4I'].includes(license.value.lcsnCd); // 응시료
        //
        // formModes.isBookCostSprt = license.value.trnAidAmt > 0 || license.value.bookCostSprtAmt > 0; // 교재비
        //
        // formModes.isTrnAid = license.value.trnAidAmt > 0 && !(['WR6Z', 'WR7A', 'WR7B', 'WR7C', 'WR7D', 'WR9D', 'WR4I'].includes(license.value.lcsnCd)); // 학원비

        if (license.value.lcsnSn > 0) {
            if (!formModes.isInvProtEdu && !formModes.isTrnAid && !formModes.isExamFeeSprt && !formModes.isBookCostSprt) {

                param.costAplyYn = 'N';
                showMessage("해당 자격증은 비용 지원이 되지 않습니다.\n(자격증 취득지원 업무 메뉴얼 참조)");
            }
        }
    };

    const setFormModes = ()=>{
        formModes.isInvProtEdu = license.value.invProtEduGrpAmt > 0 && license.value.invProtEduCyberAmt > 0; // 투자자보호교육비

        formModes.isExamFeeSprt = license.value.trnAidAmt > 0 || license.value.examFeeSprtAmt > 0 || ['WR7B', 'WR7C', 'WR7D', 'WR9D', 'WR4I'].includes(license.value.lcsnCd); // 응시료

        formModes.isBookCostSprt = license.value.trnAidAmt > 0 || license.value.bookCostSprtAmt > 0; // 교재비

        formModes.isTrnAid = license.value.trnAidAmt > 0 && !(['WR6Z', 'WR7A', 'WR7B', 'WR7C', 'WR7D', 'WR9D', 'WR4I'].includes(license.value.lcsnCd)); // 학원비


    };

    const initLicense = () => {
        param.costAplyYn = 'Y';
        if (props.data.license.lcsnCd) {
            setParams(param, license.value);
            invProtEduDivs.value = getInvProtEduByLcsnCd(props.data.license.lcsnCd);
        }

        /*
        *
        등급외 자격증 입력폼
          - 투자자 보호 교육비 / 응시료 / 교재비 (펀드투자권유자문)
          - 응시료 / 교재비
          - 응시료
        *
        * */
        setFormModes();
        // formModes.isInvProtEdu = props.data.license.invProtEduGrpAmt > 0 && props.data.license.invProtEduCyberAmt > 0; // 투자자보호교육비
        //
        // formModes.isExamFeeSprt = props.data.license.trnAidAmt > 0 || props.data.license.examFeeSprtAmt > 0 || ['WR7B', 'WR7C', 'WR7D', 'WR9D', 'WR4I', 'WR73', 'WR77', 'WR72'].includes(props.data.license.lcsnCd); // 응시료
        //
        // formModes.isBookCostSprt = props.data.license.trnAidAmt > 0 || props.data.license.bookCostSprtAmt > 0; // 교재비
        //
        // formModes.isTrnAid = props.data.license.trnAidAmt > 0 && !(['WR6Z', 'WR7A', 'WR7B', 'WR7C', 'WR7D', 'WR9D', 'WR4I', 'WR73', 'WR77', 'WR72',].includes(props.data.license.lcsnCd)); // 학원비

        if(!(formModes.isInvProtEdu||formModes.isExamFeeSprt||formModes.isBookCostSprt||formModes.isTrnAid)){
            param.costAplyYn = 'N';
        }
    }

    const calAmt =()=>{

        if(license.value.grd1Cd != null ) {
            let tmpAmt = (param.totAmt * 0.8);
            if (tmpAmt <= (license.value.trnAidAmt * 0.5) || param.totAmt == 0) {
                expAmt.value = license.value.trnAidAmt * 0.5;
            } else {

                if(tmpAmt <= license.value.trnAidAmt){
                    expAmt.value = tmpAmt;
                }else{
                    expAmt.value = license.value.trnAidAmt;
                }

            }
        }else{
            expAmt.value = 0

        }

    };

    // //입행일
    // const jncmpYmd = ref('');
    //
    // const getJncmpYmd = () => {
    //     store.dispatch(`support/${ACT_GET_JNCMP_YMD}`).then(res=>{
    //         jncmpYmd.value = ymdStrToFormat(res.jncmpYmd, '-');
    //     });
    // };

    //취득일체크
    const chkPassYmd=()=>{
        // console.log('chkPassYmd 체크시작!!!');

        if (['WR7H' , 'WR7G'].includes(license.value.lcsnCd)){ //투자자보호교육은 패스~
            return true;
        }
        // 신청일
        let aplyDt = ref(props.data ? timestampToDateFormat(props.data.aplyDt, 'yyyy-MM-dd') : getTodayDate('yyyy-mm-dd'));

        if (param.passAnnonYmd == null || param.passAnnonYmd == '' || license.value.trnAidAmt == 0){
            return true;

        }else {
            let supportYn = true;

            if (!param.passAnnonYmd.includes("-")){
                param.passAnnonYmd = ymdStrToFormat(param.passAnnonYmd, '-');
            }
            // 입행일 이전취득 비용지원 불가
            // console.log('신청일 : ' + aplyDt.value);
            // console.log('취득일 : ' + param.passAnnonYmd);
            // console.log('입행일 : ' + props.jncmpYmd);

            if (param.passAnnonYmd < props.jncmpYmd) {
                supportYn = false;
                // console.log('입행일 이전취득');
            }

            //취득일 2년이상경과 후 신청 비용지원 불가
            let tmpDt = new Date(param.passAnnonYmd);
            tmpDt.setFullYear(tmpDt.getFullYear() + 2);
            // console.log('취득일 2년경과 : ' + dateToDateFormat(tmpDt, 'yyyy-MM-dd'));
            if (aplyDt.value >= dateToDateFormat(tmpDt, 'yyyy-MM-dd')) {
                supportYn = false;
                // console.log('취득일 2년경과');
            }

            // if (!supportYn) {
            //
            //     showMessage("입행 전 취득 또는 취득일 2년경과한 자격증은<br>단순 등록만 가능하며 비용지원은 불가합니다", initFee);
            //
            //     if (param.totAmt == 0 || expAmt.value == 0){
            //         supportYn = true;
            //     }
            // }
            // if (supportYn) {
            //     console.log('chkPassYmd 체크끝!!');
            // }else{
            //     console.log('chkPassYmd 체크끝------false!!');
            // }
            return supportYn;
        }
    };

    const reSetFormModes=()=>{
        formModes.isInvProtEdu = false;
        formModes.isExamFeeSprt = false;
        formModes.isBookCostSprt = false;
        formModes.isTrnAid = false;
    };

    watch(() => param, () => {

        if (param.passAnnonYmd != null && paramCheck(license.value.lcsnSn) ) {
            showMessage('자격증을 선택해주세요');
            return false;
        }

        if(param.costAplyYn == 'Y') {
            if (!chkPassYmd()) {

                if (formModes.isInvProtEdu || formModes.isExamFeeSprt || formModes.isBookCostSprt || formModes.isTrnAid) {
                    showMessage("입행 전 취득 또는 취득일 2년경과한 자격증은<br>단순 등록만 가능하며 비용지원은 불가합니다");
                    formModes.isInvProtEdu = false;
                    formModes.isExamFeeSprt = false;
                    formModes.isBookCostSprt = false;
                    formModes.isTrnAid = false;
                }

                param.invProtEduCost = 0;
                param.acdmFee = 0;
                param.examFee = 0;
                param.bookCost = 0;
                param.totAmt = 0;
                expAmt.value = 0;
            } else {
                setFormModes();
                param.totAmt = Number(param.invProtEduCost) + Number(param.acdmFee) + Number(param.examFee) + Number(param.bookCost);

                if (param.invProtEduDiv) {
                    invProtEduDivNm.value = getInvProtEdu(param.invProtEduDiv);
                }
                calAmt();
            }
        }

    },{deep:true});

    onMounted(   () => {

        if (props.data != null) {
            initLicense();
            setParams(license.value, props.data.license);
            setParams(param, props.data);
            setCostProof(props.data.proofs, licenseFiles, proofDivCdDcds.PROOF_DIV_CD_LCSN_COPY);
            setCostProof(props.data.proofs, certFiles, proofDivCdDcds.PROOF_DIV_CD_LCSN_INV_PROT_EDU_CERT);
            setCostProof(props.data.proofs, receiptFiles, proofDivCdDcds.PROOF_DIV_CD_LCSN_BOOK_FEE);
            setCostProof(props.data.proofs, acadRegCert, proofDivCdDcds.PROOF_DIV_CD_LCSN_CERT);
            setCostProof(props.data.proofs, examFeeFiles, proofDivCdDcds.PROOF_DIV_CD_LCSN_EXAM_FEE);
            userAgreement.value = true;

            param.lcsnExamYmd = ymdStrToFormat(param.lcsnExamYmd, '-');
            param.passAnnonYmd = ymdStrToFormat(param.passAnnonYmd, '-');
            param.lcsnRegYmd = ymdStrToFormat(param.lcsnRegYmd, '-');
            param.updtAplyYmd = ymdStrToFormat(param.updtAplyYmd, '-');
            param.updtVldYmd = ymdStrToFormat(param.updtVldYmd, '-');

            calAmt();

            // if (!chkPassYmd()){
            //     if(formModes.isInvProtEdu || formModes.isExamFeeSprt || formModes.isBookCostSprt || formModes.isTrnAid){
            //         formModes.isInvProtEdu = false;
            //         formModes.isExamFeeSprt = false;
            //         formModes.isBookCostSprt = false;
            //         formModes.isTrnAid = false;
            //     }
            // }
        }
    });

    const cancel = () => {
        cancelSupport(ACT_DELETE_CANCEL_LRN_SPRT_LCSN, param.costLcsnSn,'자격증 지원', () => {
            closeModal();
        });
    }

    return {
        isReadOnly, param, license, formModes, invProtEduDivs, toggles,
        licenseFiles, certFiles, receiptFiles, acadRegCert, examFeeFiles, invProtEduDivNm,
        selectInvProtEduDivs,
        searchModalFunc,
        apply,
        closeModal, closeToggles,
        userAgreement,
        showSearchModal,
        isLicenseSearch,
        validate, numberComma, numbersOnly, emptyNumberOnClick, applyBtnEnable, cancel,
        changeLicense, searchLicense, extensions, expAmt, usrAgrmntRef, chkPassYmd, reSetFormModes,
        costAplies
    }
}