<template>


  <div class="player-viewport" style="background-color: transparent !important;" :style="isMobile ? {'display':'contents'} : {'display':'flex'}">
    <template v-if="isMobile" ref="backDom">
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="handleGoBack"><i class="icon-history-back"></i></a>
      </div>
    </template>
    <div
        ref="videoContainer"
        class="player-video kb-video-wrapper"
        :class="{'kb-size-100': !isFullscreen, 'kb-size-100-padding-0': !isMobile}"
    >
      <div class="vsc-controller vsc-nosource"></div>
      <video class="video-js vjs-big-play-centered vjs-fade-out"
             ref="videoDom"
             oncontextmenu="return false;"
             playsinline
             :loop="false"
      >
        <!--        <track v-if="crse.substFileNm!==null && crse.substFileNm.length > 0" kind="captions" :src="vttPath" srclang="ko" label="한국어" />-->
      </video>
      <div ref="detailDom" class="video-overlay">
        <slot  name="shortsDetail" />
      </div>
    </div>
    <div ref="actionDom" class="player-details">
      <slot  name="actionsDetail" />
    </div>
  </div>
</template>
<style scoped>

.player-viewport {
  height: 86vh !important;
  width: 55vh !important;
}

.lxp-mobile-layout .player .player-viewport {
  width: 100% !important;
  position: absolute !important;
  padding-top: 0px !important;
}

.lxp-mobile-layout .player .player-video video {width:100%;height:100%;object-fit:contain;}

.video-overlay {
  position: absolute;
  width:95%;
  bottom: 10px;
  left:10px;
  background: rgba(0,0,0, 0.4);
  padding: 10px;
  border-radius: 5px;
  font-size: 1.5em;
}
.vjs-user-active > .video-overlay {
  display: none !important;
}

.player-details {
  width:80px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column-reverse;
}
.lxp-mobile-layout .player .player-details {
  position:absolute;
  right:0;
  bottom:0;
}

.util-back {
  position: absolute; left: 2em; top: 2em; z-index:9999;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  width:32px;
  height:32px;
  border-radius: 50%;
  background-color: rgba(255,255,255,0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.util-back .icon-history-back {
  margin-left: -3px;
}

</style>
<script>
import videojs from 'video.js';
import {useStore} from 'vuex';
import {computed, onBeforeUnmount, onMounted, reactive, ref, toRefs} from 'vue';
import {getType, getVideoUrl} from '@/assets/js/util';
import {callNative, doPlay, eventCdDcds, insertVodLearnProgress} from '@/assets/js/modules/hrd/hrd-common';
import {TOKEN_KEY} from '@/store/modules/auth/auth';
import localstorageService from '@/assets/js/localstorage.service';
import navigationUtils, {isInApp} from '@/assets/js/navigationUtils';
import {getThumbImg} from '@/assets/js/modules/baner/common-baner';
import {goBack, hideHeader, showHeader, hideFooter, showFooter} from '@/assets/js/modules/common/common';
import {onBeforeRouteLeave} from "vue-router";

export default {
  name: 'HrdCloudShortsPlayer',
  components: {},
  props: {
    modelValue: Object, // player instance
    container: Object,
    coldcall: Boolean, // 콜드콜 응답여부
    options: Object, // videojs options
    crse: Object, // learn object (play-info)
    playIndex: String,
    progress: Object,
    uuid: String,
    popups: Object,
    preload: Boolean,
    autoPlay: Boolean,
    videoIndex:{
      type:Number,
      requred:true
    }
  },
  emits:['toggleFull'],
  setup(props,{emit}) {
    const isDev = (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local');
    const store = useStore(),
        isMobile = computed(() => store.state.auth.isMobile),
        session = computed(() => store.state.auth.session),
        isPortableiOS = navigationUtils.isApplePortable(),
        fullscreenBtn = ref(null),
        isAppleTablet = navigationUtils.isAppleTablet();

    hideFooter();

    onBeforeRouteLeave(() => {
      showFooter();
    });

    const videoDom = ref(null),
        detailDom = ref(null),
        actionDom = ref(null),
        backDom = ref(null),
        videoInstance = ref(null),
        activeContinue = ref(true), // 이어보기 최초 한번 노출하기 위한 플래그
        resumePlaySec = ref(0),
        completeActive = ref(true), // false 이후 노출 안되도록 막음
        videoContainer = ref(null),
        completePause = ref(false),
        isFullscreen = ref(false),
        {uuid} = toRefs(props);

    // 이어보기 알럿 응답여부
    const resumeResponded = ref(false);
    const noSleep = ref(true);

    const enableNoSleep = () => {
      if (isMobile.value && isPortableiOS) noSleep.value = true;
    };

    const disableNoSleep = () => {
      if(noSleep.value) noSleep.value = false;
    };

    const objectParam = computed(() => {
      return {
        crseMstSn: props.crse.crseMstSn,
        distCrseSn: props.crse.distCrseSn,
        distCrseNm: props.crse.distCrseNm,
        distChapSn: props.crse.distChapSn,
        distChapNm: props.crse.distChapNm,
        lrnObjDtlDistSn: props.crse.lrnObjDtlDistSn,
        objSn: props.crse.objSn,
        objNm: props.crse.objNm,
        // division: 'vod'
        cttDifi: props.crse.cttDifi
      }
    });

    const metadata = reactive({
      duration: 0,
      lastPlaySec: 0
    });

    const log = reactive({
      max: 0,
      completeYn: 'N',
      logSec: 0,
    });

    const localMessage = reactive({
      show: false,
      center: true,
      title: '',
      text: '학습이 종료되었습니다.',
      callback: null
    });


    // 실제 재생 초
    const getPlaySec = (ct) => {
      return (ct - log.logSec < 0 ? 0 : Math.round((ct - log.logSec )/ videoInstance.value.playbackRate()));
    };

    const exitFullscreen = () => {
      if (isFullscreen.value) {
        if (isMobile.value || isPortableiOS) videoInstance.value.exitFullWindow();
        else videoInstance.value.exitFullscreen();
      }
    }


    const toggleFullScreen = () => {
      // console.log('[toggleFullScreen] isFullscreen : ',isFullscreen.value);
      if (videoInstance.value !== null) {
        if (!isFullscreen.value) {
          videoInstance.value.enterFullWindow();
        } else {
          videoInstance.value.exitFullWindow();
        }
      }
      activeContinue.value = false;
    };

    const renderImg = () => {
      const getImg = getThumbImg(props.crse.thumb);
      return (getImg ? getImg : require('../../assets/lxp/images/@tmp/@tmp_myclass_player_thumbnail_pdf.png'));
    };

    const callNativeFunc = () => {
      const token = computed(() => localstorageService.getToken(TOKEN_KEY));
      callNative(props.crse, props.progress, uuid, token, session);
    };

    const logDebounce = ref(0);

    onMounted(() => {

      console.log("session.value.itn = ", session.value.itn);
      console.log("props.crse = ", props.crse);
      console.log("getVideoUrl(session.value.itn, props.crse)", getVideoUrl(session.value.itn, props.crse));
      console.log("getType(getVideoUrl(session.value.itn, props.crse))", getType(getVideoUrl(session.value.itn, props.crse)));

      const vjsOptions = (isMobile.value || isPortableiOS) ? {
        fluid: true,
        preload: props.preload ? 'true' : 'none',
        autoplay: props.autoPlay ? 'play' : false,
        crossOrigin: 'anonymous',
        language: 'ko',
        width: window.innerWidth,
        height: 100,
        controls: true,
        textTrackSettings: false,
        html5:{
          nativeTextTracks: false
        },
        nativeControlsForTouch:false,
        controlBar: {
          audioTrackButton: false,
          fullscreenToggle: false
        },
        poster: getThumbImg(props.crse.thumb),
        sources: [{
          src: getVideoUrl(session.value.itn, props.crse),
          type: getType(getVideoUrl(session.value.itn, props.crse))
        }]
        ,
        languages:{
          ko:{
            'Play':'재생',
            'subtitles off': '자막끄기',
            'Captions' : '캡션',
            'captions off' : '캡션끄기'
          }
        }
      } : {
        fluid: true,
        preload: props.preload ? 'true' : 'none',
        autoplay: props.autoPlay ? 'play' : false,
        crossOrigin: 'anonymous',
        language: 'ko',
        controls: true,
        textTrackSettings: false,
        html5:{
          nativeTextTracks: false
        },
        controlBar: {
          // subsCapsButton: subtitleBtn(),
          audioTrackButton: false,
        },
        responsive: true,
        poster: getThumbImg(props.crse.thumb),
        sources: [{
          src: getVideoUrl(session.value.itn, props.crse),
          type: getType(getVideoUrl(session.value.itn, props.crse))
        }],
        languages:{
          ko:{
            'Play':'재생',
            'subtitles off': '자막끄기',
            'Captions' : '캡션',
            'captions off' : '캡션끄기'
          }
        }
      };

      videojs(videoDom.value, vjsOptions,function() {
        this.tech_.off('dblclick');

        videoInstance.value = this;

        // 학습종료 닫기 콜백 렌더
        localMessage.callback = () => {
          exitFullscreen();
        };

        let vjs = videoInstance.value;

        videoInstance.value.el()?.appendChild(detailDom.value);

        if (isMobile.value || isPortableiOS) {
          videoInstance.value.el()?.appendChild(actionDom.value);
          // videoInstance.value.el().appendChild(backDom.value);

          videoInstance.value.on('exitFullWindow', () => {
            isFullscreen.value = false;
            if (!isMobile.value) showHeader();
            emit('toggleFull', isFullscreen.value);
          });

          videoInstance.value.on('enterFullWindow', () => {
            isFullscreen.value = true;
            if (!isMobile.value) hideHeader();
            emit('toggleFull', isFullscreen.value);
          });

          const controlsEls = videoInstance.value.el().getElementsByClassName('vjs-control-bar');
          if (controlsEls.length > 0) {
            // controlsEls[0].append(fullscreenBtn.value)
          }
        }

        vjs.on('loadedmetadata', function() {
          console.log("load metadata")
          metadata.duration = Math.floor(this.duration());


        });

        vjs.on('play', function() {
          enableNoSleep();

          let ct = Math.floor(this.currentTime());

          // console.log("chk play", ct, log.logSec, "resume chk", resumeResponded.value);
          if (ct - log.logSec >= 0 && ((ct - log.logSec) < 2)) {
            if (resumeResponded.value) {
              logDebounce.value = setTimeout(() => {
                if (logDebounce.value) clearTimeout(logDebounce.value);

                insertVodLearnProgress(props.crse, {
                  progSesnKey: uuid.value,
                  lastPlaySec: ct,
                  playSec: getPlaySec(ct),
                  playSpeed: this.playbackRate(),
                  eventCdDcd: eventCdDcds.Play,
                  eventNm: '학습시작',
                }, () => {
                  log.logSec = ct;
                });
              },500);
            }
          }
        });

        vjs.on('pause', function() {
          disableNoSleep();
          const ct = Math.floor(this.currentTime());
          console.log("check pause time", ct, this.duration());
          this.bigPlayButton.show();
          console.log("pause check")
        });

        vjs.on('timeupdate', function() {
          let dur = Math.floor(this.duration());
          let ct = Math.floor(this.currentTime());

          // console.log("check timeupdate time", ct, dur, this.id());
          if ((dur-ct) <= 10) {
            disableNoSleep();
          }

          if (this.currentTime() > -1) {
            let ct = Math.floor(this.currentTime());
            // 최대 도달 시간
            if (log.completeYn === 'N' && (ct > log.max)) {
              if (ct - log.max < 2) {
                log.max = ct;
              }
            }
            // log.logSec 찍은 초
            if ((ct - log.logSec >= 30) && ((ct - log.logSec) <= 32)) {
              log.logSec = ct;
              // console.log('학습중 log.logSec : ',log.logSec);
              insertVodLearnProgress(props.crse, {
                progSesnKey: uuid.value,
                lastPlaySec: ct,
                playSec: Math.round(30 / this.playbackRate()),
                playSpeed: this.playbackRate(),
                eventCdDcd: eventCdDcds.Timeupdate,
                eventNm: '학습중',
              });
            }
          }
        });

        vjs.on('ended', function() {
          console.log("ended")
          let ct = Math.floor(this.currentTime());
          insertVodLearnProgress(props.crse, {
            progSesnKey: uuid.value,
            lastPlaySec: ct,
            playSec: getPlaySec(ct),
            playSpeed: this.playbackRate(),
            eventCdDcd: eventCdDcds.Ended,
            eventNm: '학습종료',
          },() => {
            log.logSec = 0;
          });

          if (vjs.paused()) {
            vjs.currentTime(0);
            doPlay(vjs);
          }

        });

        vjs.on('seeked', function() {
          log.logSec = Math.floor(this.currentTime());
        });

        // if(isMobile.value){
        //   videoDom.value.addEventListener("touchstart", (event) =>{
        //     event.stopPropagation();
        //     event.preventDefault();
        //   }, {passive : false});
        //
        //   videoDom.value.addEventListener("touchmove", (event) =>{
        //     event.stopPropagation();
        //     event.preventDefault();
        //   }, {passive : false});
        //
        //   videoDom.value.addEventListener("touchend", (event) =>{
        //     event.stopPropagation();
        //   }, {passive : false});
        // }

      });

      if(props.autoPlay)resumeResponded.value = true;
    });

    const playVideo = () =>{
      resumeResponded.value = true;
      videoInstance.value.play();
    };

    const pauseVideo = () =>{
      try{
        console.log("video check", videoInstance.value.player);
        videoInstance.value.currentTime(0);
        videoInstance.value.pause();
      }catch (e) {
        console.log('pause err', e.message);
      }
    };

    const handleGoBack = () => {
      if (props.goBackFunc instanceof Function) {
        props.goBackFunc();
      } else {
        goBack();
      }
    };

    onBeforeUnmount(() => {
      if (videoInstance.value) videoInstance.value.dispose();
      exitFullscreen();
      disableNoSleep();
      noSleep.value = null;
    });

    return {
      isMobile, isPortableiOS, isFullscreen, fullscreenBtn, noSleep,
      videoDom, detailDom, actionDom, videoInstance, metadata, resumePlaySec, activeContinue,
      objectParam, completeActive, videoContainer, isDev, isInApp, callNativeFunc, renderImg,
      toggleFullScreen, localMessage, isAppleTablet, completePause,
      playVideo, pauseVideo, handleGoBack, backDom,
    };
  }
};
</script>