<template>
  <div class="source-wrap mb-0">
<!--    <template v-if="distCrseCd.indexOf('self') > -1">-->
<!--      <div class="source-category">-->
<!--        <p class="text">{{ myLrnTrgtGrp.lrnTrgtGrpNm }}</p>-->
<!--      </div>-->
<!--    </template>-->

    <template v-if="distCrseCd === 'group' && refTyCdDcd != '2113005'">
<!--      <template v-else-if="distCrseCd === 'group' && refTyCdDcd != '2113005'">-->
      <div v-if="relInfos.length > 1" class="source-category">
        <p class="text">{{ myLrnTrgtGrp.lrnTrgtGrpNm }}</p>
        <p class="text">{{ relInfos[0] ? `${relInfos[0]}` : ''}}</p>
      </div>
      <div v-if="relInfos.length > 2"  class="source-category">
        <p class="text">{{ relInfos[1] }}, {{ relInfos[2] }}</p>
      </div>
    </template>

    <template v-else-if="distCrseCd === 'knowledge'">
      <div v-if="relInfos.length > 0" class="source-category">
        <div class="image-box">
          <div class="image" @click="clickOpenRel"><img src="@/assets/lxp/images/ssl/myssl/@img_jisike.png" alt=""></div>
          <div class="content">
            <p class="text">{{ relInfos[0] }}</p>
<!--            <p class="text">가계여신</p>-->
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="distCrseCd === 'hrd'">
      <div class="source-category">
        <div class="image-box">
          <div class="image">
            <CommonTypeImage
                :sequence="relInfos[3]"
                :img-src="relInfos[0]"
                :target="relInfos"
                @clickImage="goToHrd"
            />
          </div>
          <div class="content">
            <span class="text">{{ relInfos[2] }}</span>
            <br>
            <span class="text">{{ relInfos[1] }}</span>
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="distCrseCd === 'book'">
      <div v-if="relInfos.length > 0" class="source-category">
        <div v-if="relInfos[0] === '자유도서'" class="image-box image-book">
          <div class="image"><img src="@/assets/lxp/images/ssl/myssl/img_book_kb.jpg" alt=""></div>
          <div class="content">
            <p class="text">자유도서</p>
          </div>
        </div>
        <div v-else class="image-box image-book">
          <div v-if="relInfos.length > 2" class="image"><img :src="relInfos[2]" alt=""></div>
          <div class="content">
            <p class="text">{{relInfos[0]}}</p>
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="distCrseCd === 'live'">
      <div v-if="relInfos.length > 0" class="source-category">
        <p class="text">{{ relInfos[0] }}</p>
        <p v-if="relInfos.length > 2" class="text">{{ relInfos[2] }}</p>
      </div>
    </template>

    <template v-else-if="relInfos.length > 4">
      <div class="source-category">
        <div class="image-box" :class="{'image-book' : relInfos[0] === 'ssl'}">
          <div class="image" @click.stop="$router.push({path:refUrl})">
            <CommonTypeImage
                :theme="relInfos[0]"
                :img-src="relInfos[1]"
            />
          </div>
          <div class="content">
            <p class="text">{{ relInfos[2] }}</p>
            <p class="text text-muted">{{ relInfos[3] }}</p>
          </div>
          <button v-if="socLrnPostSn === 0" class="btn" @click.stop="clickDeleteRel" title="연결 삭제"><i class="icon-tag-delete" /></button>
        </div>
      </div>
    </template>
  </div>
</template>
<script>


import {writeRelSetup} from "@/assets/js/modules/ssl/ssl-write";
import CommonTypeImage from '@/components/common/CommonTypeImage';

export default {
  name: 'SSLMobileWriteRel',
  components: {CommonTypeImage},
  props: {
    refTyCdDcd:String,
    socLrnPostSn: Number,
    refPost: String,
    refUrl: String
  },
  setup: writeRelSetup
}
</script>
