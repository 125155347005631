<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <!-- popup -->
    <div class="popup" id="popup-hrdcloud-kbtube">
      <!-- popup > popup-inner -->
      <div v-if="item.cttTyCdDcd !== '2034005'" class="popup-inner">
        <video class="vjs-matrix video-js vjs-default-skin vjs-big-play-centered"
               ref="videoDom"
               oncontextmenu="return false;"
               playsinline
        ></video>
      </div>
      <div v-else class="popup-inner">
        <div>
          <template v-if="pageCount === 0">
            Loading...
          </template>
          <template v-else>
      <span>
        <button :disabled="page <= 1" @click="page--">❮</button>
        {{ page }} / {{ pageCount }}
        <button :disabled="page >= pageCount" @click="page++">❯</button>
      </span>
          </template>
          <vue-pdf-embed v-if="src"
                         :source="src"
                         ref="pdfViewer"
                         :page="page"
                         @rendered="handleDocumentRender"
          />
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="$emit('update:modelValue',false)"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onBeforeUnmount, onMounted, ref} from 'vue';
import {getType, getVideoUrl} from '@/assets/js/util';
import {useStore} from 'vuex';
import videojs from 'video.js';
import VuePdfEmbed from 'vue-pdf-embed';

export default {
  name: 'Preview',
  props: {
    modelValue: Boolean,
    item: Object,
  },
  components: {
    VuePdfEmbed
  },
  emits: ['update:modelValue'],
  setup(props) {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const videoDom = ref(null), video_instance = ref(null);


    const pdfViewer = ref(null);
    const page = ref(null);
    const pageCount = ref(0);

    const isDev = (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development' )

    const src = ref('');

    if (isDev) {
      src.value = 'https://devlxpcms.kbstar.com/' + props.item.etnUrl;
    } else {
      src.value = 'https://lxpcms.kbstar.com/' + props.item.etnUrl;
    }

    console.log("src = ", src);

    const handleDocumentRender = () => {
      pageCount.value = pdfViewer.value.pageCount;
      if(!page.value) page.value = 1;
    };

    console.log(props);

    onMounted(() => {
      if (session.value && videoDom.value) {
        const vjsOptions = {
          fluid: false,
          preload: 'metadata',
          autoplay: false,
          crossOrigin: 'anonymous',
          language: 'ko',
          width: 1200,
          height: 675,
          controls: true,
          controlBar: {
            subsCapsButton: false,
            audioTrackButton: false,
          },
          sources: [{
            src: getVideoUrl(session.value.itn, props.item),
            type: getType(getVideoUrl(session.value.itn, props.item))
          }]
        };
        videojs(videoDom.value, vjsOptions,function() {

        });
      }
    });

    onBeforeUnmount(() => {
      // console.log('video disposed onbeforeunmount');
      if (video_instance.value) video_instance.value.dispose();
    });

    return {
      videoDom,
      pdfViewer,
      page,
      pageCount,

      handleDocumentRender,
      src
    }
  }
};
</script>