<template>
  <footer class="kb-footer" id="kb-footer">
    <!-- 공지사항 숨김처리 footer-divider -->
    <div v-if="items && items.length > 0" class="footer-row">
    </div>
    <div>
      <nav class="footer-fnb">
        <router-link to="/main" class="logo" v-slot="{ navigate }" @click="closeMenu(navigate, 1)">
          <button><i class="home-icon" :class="{ 'is-active' : route.path === '/main' }"></i></button>
          <div class="title">홈</div>
        </router-link>
        <router-link to="/prep/hrdcloud" class="logo" v-slot="{ navigate }" @click="closeMenu(navigate, 2)">
          <button><i class="cloud-icon" :class="{ 'is-active' : route.path === '/prep/hrdcloud'}"></i></button>
          <div class="title">클라우드</div>
        </router-link>
        <div class="logo">
          <button @click="toggleDropDown"><i class="upload-icon" :class="{ 'is-active' : showDropdown }"></i></button>
          <div class="title">등록하기</div>
        </div>
        <router-link to="/ssl/home" class="logo" v-slot="{ navigate }" @click="closeMenu(navigate, 4)">
          <button><i class="ssl-icon" :class="{ 'is-active' : route.path === '/ssl/home'}"></i></button>
          <div class="title">SSL</div>
        </router-link>
<!--        <div class="logo">-->
<!--          <button @click="showMessage('2025년 SSL 준비중입니다.');"><i class="ssl-icon" :class="{ 'is-active' : route.path === '/ssl/home'}"></i></button>-->
<!--          <div class="title">SSL</div>-->
<!--        </div>-->
        <div class="logo">
          <button @click="toggleMenu"><i class="menu-icon" :class="{ 'is-active' : isMenuOpen }"></i></button>
          <div class="title">메뉴</div>
        </div>
      </nav>
      <LxpMobileFooterMenu v-model="isMenuOpen" :toggle-menu="toggleMenu"/>
    </div>
    <template v-if="showDropdown">
      <SSLMobileNavWrt :effect-func="effectFunc" />
    </template>
  </footer>
</template>

<script>
import {computed, ref} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from "vue-router";
// import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import LxpMobileFooterMenu from '@/views/layout/lxp/footer/mobile/LxpMobileFooterMenu';
import {useAlert} from "@/assets/js/modules/common/alert";
import SSLMobileNavWrt from "@/components/ssl/mobile/SSLMobileNavWrt.vue";

export default {
  name: "LxpMobileFooterV2",
  components: {SSLMobileNavWrt, LxpMobileFooterMenu},
  props: {
    items: Array
  },
  setup() {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const isMenuOpen = ref(false);
    const showDropdown = ref(false);
    const selectedMenu = ref(1);
    const pathName = ref(window.location.pathname.replace('/app', ''));

    const route = useRoute();

    if (pathName.value === '/main') {
      selectedMenu.value = 1;
    } else if (pathName.value === '/prep/hrdcloud') {
      selectedMenu.value = 2;
    } else if (pathName.value === '/ssl/home') {
      selectedMenu.value = 4;
    }

    const {showMessage} = useAlert();

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    const closeMenu = (navigate, num) => {
      selectedMenu.value = num;
      window.scrollTo(0,0);
      if (isMenuOpen.value) {
        isMenuOpen.value = false;
        navigate();
      }
    }

    const toggleDropDown = () => {
      isMenuOpen.value = false;
      if(showDropdown.value){
        showDropdown.value = false;
      }else{
        showDropdown.value = true;
      }
    }

    const effectFunc = () => {
      showDropdown.value = false;
    }

    return {
      session,
      isMenuOpen,
      toggleMenu,
      closeMenu,
      showDropdown,
      effectFunc,
      toggleDropDown,
      pathName,
      selectedMenu,
      route,
      showMessage
    }
  }
}
</script>

<style scoped>
  .lxp-mobile-layout #kb-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    border-top: 1px solid var(--kb-light-silver);
    z-index: var(--kb-zindex-footer-nav);
  }
</style>