import {computed, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {useAlert} from "@/assets/js/modules/common/alert";
import {ACT_GET_GROUP_LRN_TRGT_DTL_LIST} from "@/store/modules/prep/prep";
import {getItems, getPaging, isSuccess, lengthCheck} from "@/assets/js/util";
import {ACT_DELETE_GROUP_LRN_TRGT_DTL} from "@/store/modules/board/board";
import router from "@/router";
import {ACT_DELETE_GROUP} from "@/store/modules/course/course";
import navigationUtils from "@/assets/js/navigationUtils";

export const groupLearningDetailViewSetup = () => {

    const session = computed(() => store.state.auth.session);
    const store = useStore();
    const route = useRoute();
    const items2 = ref([]);
    const items = ref([]);
    const item = ref({});
    const distCrseSn = computed(()=> route.params.distCrseSn);
    const { showMessage, showConfirm } = useAlert();

    const applyGroupModal = ref(false);

    const inviteModal = ref(false);

    const changeLeaderModal = ref(false);

    const paging = ref({pageNo: 1, pageSize: 12, totalCount: 0});

    const isAction = ref(false);

    // 임시
    const bgImgUrl = require('@/assets/lxp/images/@tmp/@tmp_group_symbol_02.jpg');

    const isGroup = ref([]);
    const isMobileModal = ref(false);

    const isLeader = ref(false);

    const getGroupList = () => {

        store.dispatch(`prep/${ACT_GET_GROUP_LRN_TRGT_DTL_LIST}`,{
            distCrseSn: route.params.distCrseSn,
            stt: '01',
            pageNo: paging.value.pageNo,
            pageSize: paging.value.pageSize,
            pwdToken: store.state.board.pwdToken
        }).then(res => {
            if(lengthCheck(res)){
                //해당그룹 리스트
                items.value = getItems(res); //그룹전체정보
                isGroup.value = items.value.filter(x => x.lrnerId === session.value.lrnerId); // 그룹 신청 되어있는지 확인
                if(isGroup.value.length === 0){ // 내 그룹이 아닌경우
                    // 해당그룹 미참여인경우
                    store.dispatch(`prep/${ACT_GET_GROUP_LRN_TRGT_DTL_LIST}`,{
                        lrnerId: session.value.lrnerId,
                        pageNo: paging.value.pageNo,
                        pageSize: paging.value.pageSize
                    }).then(res => {
                        if(lengthCheck(res)){
                            items2.value = getItems(res);
                            showMessage('"'+items2.value[0].crseNm+'"에 참여중입니다. <br> 탈퇴 후 재신청 바랍니다.');
                            router.push({name: 'GroupLearning'});
                        }
                    }).catch(e => {
                        console.error(e);
                    })
                }else{ //내 그룹 인경우 확정되면 my로 보내줌
                    if(isGroup.value.find(x=> x.distCrseSn == route.params.distCrseSn).stt ==='00'){ //그룹확정
                        router.push({name: 'GroupView', params: {distCrseSn: isGroup.value.find(x=> x.distCrseSn == route.params.distCrseSn).distCrseSn}}); // my그룹으로 redirect
                    }

                }
                paging.value = getPaging(res);

                if(isGroup.value.length > 0) isLeader.value = isGroup.value.find(x=> x.distCrseSn == route.params.distCrseSn).grpRoleCdDcd === '2010001'
                item.value = items.value[0];
            }else{
                items.value = [];
                showMessage("잘못된 접근입니다. <br> 그룹에 비밀번호가 설정되어 있는 경우 목록에서 별도로 신청바랍니다.",()=>{  router.push({name: 'GroupLearning'}) });
            }
        }).catch(e => {
            console.error(e);
        })
    }

    getGroupList();

    const limitPeople = () => {
        let result = false;

        if(item.value.lrnTrgtCnt >= item.value.peoplCnt) {
            showMessage({text: '그룹의 정원이 마감 되었습니다.'});
        } else {
            result = true;
        }
        return result;
    }

    const clickApply = () => {
        if(limitPeople()){
            applyGroupModal.value = true;
            isMobileModal.value = true;
        }
    }

    const clickInvite = () => {
        if(limitPeople()){
            inviteModal.value = true;
            isAction.value = false;
            isMobileModal.value = true;
        }
    }

    const clickChangeLeader = () => {
        changeLeaderModal.value = true;
        isAction.value = false;
    }

    const updateSelfIntro = (selfIntroSuccess) => {
        if(selfIntroSuccess) getGroupList();
    }

    const clickSecession = () => {
        isAction.value = false;
        if(isGroup.value[0].leaderId === session.value.lrnerId) {
            showConfirm({
                text: '리더는 리더 변경 후 <br/>탈퇴가 가능합니다.'
            });
        } else {
            showConfirm({
                text: '그룹을 탈퇴 하시겠습니까?',
                callback: () => {
                    store.dispatch(`board/${ACT_DELETE_GROUP_LRN_TRGT_DTL}`, route.params.distCrseSn).then(res => {
                        if(isSuccess(res)) {
                            showMessage({
                                text: '그룹 탈퇴가 완료되었습니다.',
                                callback: () => {
                                    router.push({name: 'GroupLearning'});
                                }
                            });
                        }
                    }).catch(e => {
                        console.error(e);
                    });

                }
            });
        }
    }

    const clickDeleteGroup = () => {
        showConfirm('그룹을 삭제 하시겠습니까?',() =>{
            deleteGroup();
        }, () => {
            isAction.value = !isAction.value
        });
    }

    const deleteGroup = () => {
        store.dispatch(`course/${ACT_DELETE_GROUP}`, route.params.distCrseSn).then(res => {
            if(isSuccess(res)) {
                showMessage({
                    text: '그룹이 삭제되었습니다.',
                    callback: () => {
                        router.push({name: 'GroupLearning'});
                    }
                });
            }
        })
    }

    const isApplySuccess = ref(false);

    const applySuccess = (isSuccess) => {
        isApplySuccess.value = isSuccess;
    }

    const clickAction = () => {
        isAction.value = true;
    }

    watch(() => changeLeaderModal.value, () => {
        if(!changeLeaderModal.value) {
            getGroupList();
        }
    });

    watch(() => isApplySuccess.value, () => {
        if(isApplySuccess.value) {
            getGroupList();
        }
    });


    return{
        session,
        items,
        item,
        bgImgUrl,
        isGroup,
        applyGroupModal,
        inviteModal,
        changeLeaderModal,
        isMobileModal,
        isAction,
        isLeader,
        applySuccess,
        updateSelfIntro,
        clickAction,
        clickApply,
        clickInvite,
        clickSecession,
        clickChangeLeader,
        clickDeleteGroup,
        distCrseSn,
        isMobile: navigationUtils.any()
    }
}