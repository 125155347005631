<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue.isActive }">
    <div class="popup" id="popup-hrdcloud-kbtube">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">쓸스튜디오(영상/PDF) 등재</h3>
        </header>
        <div class="popup-content">
          <article class="popup-section">
            <div class="section-content">
              <div class="segment-box notice-segment"><p class="text text-gold">등록하신 동영상을 관리자가 심사중입니다. </p></div>
              <div class="kb-form-fields">
                <RowItem title="제목" :data="item.cttTitle"/>
                <RowItem title="분야" :data="fieldNm"/>
                <RowItem title="영상 업로드" :show-text="false">
                  <template v-slot:action>
                    <div class="kb-form-column">
                      <ul class="file-list">
                        <li class="file-item"><span class="file-name">{{ item.fileNm }}</span>
                          <span v-if="item.cttFileSz > 0" class="file-size">{{ convertToStorageBytes(item.cttFileSz) }}</span></li>
                      </ul>
                    </div>
                    <div class="kb-form-column justify-content-end t2">
                      <div class="kb-form-preview">
                        <button v-if="item.buketNm.indexOf('contents') !== -1 || item.cttTyCdDcd === '2034005'" class="text preview" @click="previewModal=true">미리보기</button>
                        <div v-else class="text preview">인코딩중...</div>
                      </div>
                    </div>
                  </template>
                </RowItem>
                <div v-if="stringCheck(item.thumb)" class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">썸네일 업로드</strong>
                    </label>
                  </div>
                  <div class="kb-form-column column-whole">
                    <article class="hrdcloud-card"  style="width: 225px; height: 400px;">
                      <div class="hrdcloud-image" style="color:black; outline:unset !important; text-align:center; height: 400px;">
                        <div class="image" style="background-color:black; height: 400px;">
                          <img :src="item.thumb" alt="임시이미지" style="width:unset !important;object-fit: cover; height: 400px; width: 225px;" />
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
                <RowItem v-if="stringCheck(item.orgFileNm)" title="첨부파일" :show-text="false">
                  <template v-slot:action>
                    <div class="kb-form-column">
                      <ul class="file-list">
                        <li class="file-item">
                          <span class="file-name" style=" text-decoration: underline; cursor:pointer !important" @click="downloadFile">{{ item.orgFileNm }}</span>
                          <span v-if="item.fileSz > 0" class="file-size">{{ convertToStorageBytes(item.fileSz) }}</span>
                        </li>
                      </ul>
                    </div>
                  </template>
                </RowItem>

                <div v-if="stringCheck(item.cttCn)" class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">설명</strong>
                    </label>
                  </div>
                  <div class="kb-form-column column-whole py-3">
                    <div class="editor-content m-h-300 ms-0">
                      <div ref="pageEditor"></div>
                    </div>
                  </div>
                </div>

                <div v-if="stringCheck(item.tags)" class="kb-form-row editor-body-tag" style="margin-top:unset !important;">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">태그</strong>
                    </label>
                  </div>
                  <div class="kb-form-column tag-list column-whole" style="padding: 22px 40px;">
                    <div v-for="(hashTag, idx) in item.tags" class="tag-item is-active" :key="idx">
                      <span class="tag-item-text">{{ hashTag }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="section-bottom bottom-notice">
              <p class="text text-icon">1. 제작한 영상/PDF를 <span style="color:#ff8000;line-height:unset !important;">MP4(최대 2GB)/PDF파일(최대 20MB)로 암호화 해제 후 외부망에서 업로드</span> 해주세요.</p>
              <p class="text">2. <span style="color:#ff8000;line-height:unset !important;">
                콘텐츠에 개인정보 및 중요정보가 포함되지 않도록 유의</span>하여 주시고, 개인정보 유출 등으로 인한 책임은 등록자에게 있음을 확인해주시기 바랍니다.
              </p>            </div>
          </article>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="modelValue.toggle"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
  <Preview v-if="previewModal && item.etnUrl" v-model="previewModal" :item="item" />
</template>

<script>
import RowItem from '@/components/history/module/RowItem';
import Preview from '@/components/history/hrdcloud/Preview';
import {getSSLStudioList} from '@/assets/js/modules/hrd/hrd-history-common';
import {convertToStorageBytes, getImg, getItems, lengthCheck, setParams, stringCheck, winOpen} from '@/assets/js/util';
import {computed, onMounted, reactive, ref, toRefs} from 'vue';
import {useAlert} from '@/assets/js/modules/common/alert';
import ClassicEditor from "@/assets/js/editor/custom-ckeditor";
import {kbtubeEditorConfig} from "@/assets/js/editor/editor-config";
import {currentYear, getCrseSnToDistCrseCd} from "@/assets/js/modules/ssl/ssl-common";
import {ACT_GET_MY_SSL_LST} from "@/store/modules/ssl/ssl-index";
import {useStore} from "vuex";

export default {
  name: 'SSLStudioPending',
  components: {Preview, RowItem},
  props: {
    modelValue: Object,
    cttMstSn: Number,
  },
  setup(props) {
    const {showLoading, hideLoading} = useAlert();
    const {cttMstSn} = toRefs(props);
    const store = useStore();
    const fieldNm = ref('');

    const previewModal = ref(false);

    const expsrServ = computed(() => {
      // if (item.expsrServ === '2053001,2053002,2053003,2053004')
      return '전직원';
    });

    const item = reactive({
      cttTitle: '',
      cloudNmLst: '',
      expsrServ: '',
      fileNm: '',
      etnUrl: '',
      itnUrl: '',
      orgFileNm: '',
      cttFileSz: 0,
      fileSz: 0,
      buketNm: '',
      thumb: '',
      tag: '',
      cttCn: '',
      tags: [],
      atchEtnUrl: '',
      atchFileDomain: null,
      lrnTrgtGrpMstSn: '',
      refTyNm: '',
      cttTyCdDcd: ''
    });

    const pageEditor = ref(null);
    let $_instance = null;

    const downloadFile = () => {
      if (stringCheck(item.atchEtnUrl)) {
        winOpen(`${process.env.VUE_APP_CLOUDFRONT_URL}/${item.atchEtnUrl}`, '_blank');
      }
    };

    let tryCount = 0;
    const setPageEditor = () => {
      if(tryCount > 10) return;
      if(pageEditor.value){
        ClassicEditor.create( pageEditor.value, kbtubeEditorConfig ).then(editor => {
          editor.isReadOnly = true;
          $_instance = editor;
          if(item.cttCn) $_instance.setData(item.cttCn);
        }).catch(error => {
          console.error(error);
        })
      }else{
        tryCount++;
        setPageEditor();
      }
    }

    const distOptions =ref([]);
    const getOptions= async ()=>{ //년도별 my입과 과정
      // console.log('year : ' + filters.year);
      distOptions.value = [];

      await store.dispatch(`ssl/${ACT_GET_MY_SSL_LST}`, currentYear).then(res => {
        if (lengthCheck(res)) {
          distOptions.value = getItems(res);
        }
        distOptions.value = getItems(res).map(x=>({...x, distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)}));
        console.log("distOptions.value = ", distOptions.value);

      }).catch(e => {
        console.error(e);
      });
    }

    onMounted(async() => {
      await getOptions();
      showLoading();
      getSSLStudioList({cttMstSn: cttMstSn.value}, null, {}, async (res) => {
        await setParams(item, res.items[0]);
        if (item.cloudNmLst && item.cloudNmLst.length > 0) {
          item.cloudNmLst = item.cloudNmLst.replaceAll(/,/g, ' > ');
        }
        item.thumb = stringCheck(item.thumb) ?  getImg(item.thumb) : null;
        item.tags = stringCheck(item.tag) ? item.tag.split(',') : [];

        if (item.lrnTrgtGrpMstSn) {
          let crseMstSn;
          if (item.refTyNm) {
            crseMstSn = distOptions.value.filter(x => x.lrnTrgtGrpMstSn == Number(item.refTyNm)).map(y => y.crseMstSn);
          } else {
            crseMstSn = distOptions.value.filter(x => x.lrnTrgtGrpMstSn == item.lrnTrgtGrpMstSn).map(y => y.crseMstSn);
          }

          fieldNm.value = distOptions.value.find(x => x.crseMstSn == crseMstSn).crseNm;
        }

        if (item.atchFileDomain) {
          const atchFile = item.atchFileDomain
          item.orgFileNm = stringCheck(atchFile.orgFileNm) ? atchFile.orgFileNm : null;
          item.fileSz = atchFile.fileSz > 0 ? atchFile.fileSz : 0;
          item.atchEtnUrl = stringCheck(atchFile.etnUrl) ? atchFile.etnUrl : null;
        }
        await setPageEditor();
        hideLoading();
      },() => {hideLoading()});
    });

    return {
      item, expsrServ, previewModal, pageEditor,
      convertToStorageBytes, stringCheck,
      downloadFile, fieldNm
    }
  }
};
</script>