import LxpLayout from '@/views/layout/lxp/LxpLayout';
import navigationUtils from '@/assets/js/navigationUtils';
import LxpMobileLayoutV2 from '@/views/layout/lxp/mobile/LxpMobileLayoutV2';
import HrdCloudMain from '@/views/pages/prep/HrdCloudMain';
import HrdCloudMobileMainV2 from '@/views/pages/prep/mobile/HrdCloudMobileMainV2';
import HrdCloudContentBridge from '@/views/pages/prep/HrdCloudContentBridge';
import HrdCloudMobileContentBridge from '@/views/pages/prep/mobile/HrdCloudMobileContentBridge';
import HrdCloud from '@/views/pages/prep/HrdCloud';
import HrdCloudMobileViewAll from '@/views/pages/prep/mobile/HrdCloudMobileViewAll';
import KnowledgeELearning from '@/views/pages/prep/KnowledgeELearning';
import MobileKnowledgeELearning from '@/views/pages/prep/mobile/MobileKnowledgeELearning';

// 그룹러닝 PC
import GroupLearning from "@/views/pages/prep/GroupLearning";
import GroupApplication from "@/views/pages/prep/GroupApplication";
import GroupLearningMng from "@/views/pages/prep/GroupLearningMng";
import GroupLearningDetailView from "@/views/pages/prep/GroupLearningDetailView";
import GroupView from "@/views/pages/prep/GroupView";
import GroupStudyPlan from "@/views/pages/prep/GroupStudyPlan";
import GroupClassEdit from "@/views/pages/prep/GroupClassEdit.vue";
import GroupLearningJournal from "@/views/pages/prep/GroupLearningJournal.vue";

// 그룹러닝 Mobile
import MobileGroupLearning from "@/views/pages/prep/mobile/MobileGroupLearning";
import MobileGroupApplication from "@/views/pages/prep/mobile/MobileGroupApplication";
import MobileGroupLearningMng from "@/views/pages/prep/mobile/MobileGroupLearningMng";
import MobileGroupLearningDetailView from "@/views/pages/prep/mobile/MobileGroupLearningDetailView";
import MobileGroupView from "@/views/pages/prep/mobile/MobileGroupView";
import MobileGroupStudyPlan from "@/views/pages/prep/mobile/MobileGroupStudyPlan";
import MobileGroupClassEdit from "@/views/pages/prep/mobile/MobileGroupClassEdit.vue";
import MobileGroupLearningJournal from "@/views/pages/prep/mobile/MobileGroupLearningJournal.vue";
import {insertHotTipActLog} from "@/assets/js/modules/learn/learn-hot-tip";
import {actTyCdDcds} from "@/assets/js/modules/common/common";


const isMobile = navigationUtils.any();

const prepRoutes = [
  {
    path: '/prep',
    component: isMobile ? LxpMobileLayoutV2 : LxpLayout,
    children: [
      {
        path: 'group',
        name: "GroupLearning",
        component: isMobile ? MobileGroupLearning : GroupLearning
      },
      {
        // 그룹 러닝 MY
        path: 'group/:distCrseSn/my',
        name: "GroupView",
        component: isMobile ? MobileGroupView : GroupView
      },
      {
        // 그룹 러닝 리스트
        path: 'group/application',
        name: "GroupApplication",
        component: isMobile ? MobileGroupApplication : GroupApplication
      },
      {
        path: 'group/:boardSn/journal/:lrnPostSn',
        name: "GroupLearningJournal",
        component: isMobile ? MobileGroupLearningJournal : GroupLearningJournal
      },
      {
        // 그룹생성
        path: 'group/manage/:boardSn',
        name: "GroupLearningMng",
        component: isMobile ? MobileGroupLearningMng : GroupLearningMng
      },
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'group/:distCrseSn',
        name: "GroupLearningDetailView",
        component: isMobile ? MobileGroupLearningDetailView  : GroupLearningDetailView
      },
      {
        path: 'group/:boardSn/moim/:lrnPostSn',
        name: "GroupClassEdit",
        component: isMobile ? MobileGroupClassEdit : GroupClassEdit
      },
      {
        path: 'group/plan/:boardSn',
        name: "GroupStudyPlan",
        component: isMobile ? MobileGroupStudyPlan : GroupStudyPlan
      },
      {
        path: 'knowledge',
        name: "KnowledgeELearning",
        component: isMobile ? MobileKnowledgeELearning : KnowledgeELearning
      },
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: 'knowledge/detail',
        name: "KnowledgeView",
        component: () => import('../../views/pages/prep/KnowledgeView')
      },
      {
        path: 'hrdcloud',
        name: "HrdCloud",
        component: isMobile ? HrdCloudMobileMainV2 : HrdCloudMain
      },
      {
        path: 'hrdcloud/:lrnObjDtlDistSn/detail/:mode?',
        name: "HrdCloudContentBridge",
        component: isMobile ? HrdCloudMobileContentBridge : HrdCloudContentBridge
      },
      {
        path: 'hrdcloud/:view',
        name: "HrdCloudList",
        component: isMobile ? HrdCloudMobileViewAll : HrdCloud
      },
      {
        path: 'hrdcloud/roadmap/main',
        name: "HrdCloudRoadmapMain",
        component: () => import('../../views/pages/prep/mobile/MobileHrdCloudRoadmapMain')
      },
      {
        path: 'hrdcloud/roadmap',
        name: "HrdCloudRoadmapPage",
        component: () => isMobile ? import('../../views/pages/prep/mobile/MobileHrdCloudRoadmapPage') : import('../../views/pages/prep/HrdCloudRoadmapPage')
      },
      {
        path: 'hottip/main',
        name: "HotTipMain",
        beforeEnter: (to, from, next) => {
          insertHotTipActLog(actTyCdDcds.ACT_TY_CD_LEARN_HOME);
          next();
        },
        component: () => import('../../views/pages/prep/HotTipMain.vue')
      },
      {
        path: 'hottip/main/:boardId',
        name: "HotTipBoard",
        component: () => import('../../views/pages/prep/HotTipBoard.vue')
      },
      {
        path: 'hottip/search',
        name: "HotTipSearch",
        component: () => import('../../views/pages/prep/HotTipSearch.vue')
      },
    ],
  }
];

export const setPrepRoutes = (routes) => {
  routes.push(...prepRoutes);
}
