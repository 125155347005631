<template>

  <section class="myssl-section section-mybadge">
    <header class="section-header">
      <h3 class="title">인기</h3>
    </header>

    <SSLLearnerBoardSwiper :sortCd="'like'" :lmtCnt="10" :opt="'S'"></SSLLearnerBoardSwiper>
  </section>

  <section class="myssl-section section-mybadge">
    <header class="section-header">
      <h3 class="title">최신</h3>
    </header>

    <SSLLearnerBoardSwiper :sortCd="'new'" :lmtCnt="10" :opt="'S'"></SSLLearnerBoardSwiper>
  </section>

  <SSLLearnerBadge />
</template>

<script>
import SSLLearnerBoardSwiper from "@/components/ssl/learner/SSLLearnerBoardSwiper.vue";
import SSLLearnerBadge from '@/components/ssl/learner/SSLLearnerBadge';

export default {
  name: 'SSLLearnerHome',
  components: {
    SSLLearnerBoardSwiper, SSLLearnerBadge
  },
  setup(){

    return{

    }
  }
}
</script>