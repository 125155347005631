<template>
  <main v-if="!isLicenseSearch" class="kb-main" id="kb-support">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="support-view-container">
        <div class="view-contents">

          <!-- content-section -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">신청정보</h4>
            </header>
            <div class="section-content">
              <div class="content-item">
               <MobileApplicant />
              </div>
              <div class="content-item">
                <div class="kb-form-fields">
                  <!-- kb-form-item:자격증명 -->
                  <div class="kb-form-item">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">자격증명 검색</span>
                      </label>
                    </div>
                    <div class="kb-form-column column-whole">
                      <input v-model="license.lcsnNm" type="text" class="kb-form-control" placeholder="자격증명을 검색하세요">
                    </div>
                  </div>
                </div>
                <div class="kb-btn-actions">
                  <button class="kb-btn-content-item" @click="searchLicense">자격증명 검색</button>
                </div>
              </div>
              <div class="content-item">
                <div class="kb-form-fields">
                  <!-- kb-form-item:발급처 -->
                  <div class="kb-form-item">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">발급처</span>
                      </label>
                    </div>
                    <div class="kb-form-column column-whole">
                      <input v-model="license.pblcnInstNm" type="text" class="kb-form-control" placeholder="발급처를 입력하세요">
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-item">
                <div class="kb-form-fields">
                  <RowDatepicker
                      title="자격증 응시일"
                      v-model="param.lcsnExamYmd"
                      v-model:toggle="toggles.lcsnExamYmd"
                      :disabled="isReadOnly"
                      custom-class="license"
                      @update:toggle="closeToggles(toggles, 'lcsnExamYmd')"
                  />
                </div>
              </div>
              <div class="content-item">
                <div class="kb-form-fields">
                  <RowDatepicker
                      title="합격자 발표일"
                      v-model="param.passAnnonYmd"
                      v-model:toggle="toggles.passAnnonYmd"
                      :disabled="isReadOnly"
                      custom-class="license"
                      @update:toggle="closeToggles(toggles, 'passAnnonYmd')"
                  />
                </div>
              </div>
              <div class="content-item">
                <div class="kb-form-fields">
                  <RowDatepicker
                      title="자격증 등록일"
                      v-model="param.lcsnRegYmd"
                      v-model:toggle="toggles.lcsnRegYmd"
                      :disabled="isReadOnly"
                      custom-class="license"
                      @update:toggle="closeToggles(toggles, 'lcsnRegYmd')"
                  />
                </div>
              </div>

              <div class="content-item">
                <div class="kb-form-fields">
                  <!-- kb-form-item:자격증명 -->
                  <div class="kb-form-item">
                    <MobileUpload
                        v-model="licenseFiles"
                        title="자격증 사본"
                        placeholder="자격증 사본을 등록하세요."
                        btn-title="자격증 사본 등록하기"
                        :extensions="extensions"
                        :max-qty="10"
                        sequence-key="proofDataSn"
                        :disabled="isReadOnly"
                    />
                  </div>
                </div>
              </div>

              <div class="content-item">
                <div class="kb-form-fields">
                  <div class="kb-form-item">
                    <InputCustomSelect
                        v-model:value1="param.costAplyYn"
                        v-model:toggle1="toggles.costAplies"
                        title="비용지원신청여부"
                        :options1="costAplies"
                        sequence-key1="val"
                        nameKey1="nm"
                        placeholder1="비용지원신청여부를 선택해주세요"
                        :disabled="isReadOnly"
                        @update:toggle1="closeToggles(toggles, 'costAplies')"
                    />
                  </div>
                </div>
              </div>

              <template v-if="param.costAplyYn == 'Y'">
                <template v-if="formModes.isInvProtEdu">
                  <div class="content-item">
                    <div class="kb-form-fields">
                      <div class="kb-form-item">
                        <div v-if="invProtEduDivs && invProtEduDivs.length > 0" class="kb-form-column">
                          <InputText
                              title="투자자보호교육비 (금액수정가능)"
                              v-model="param.invProtEduCost"
                              placeholder="교육비를 입력해주세요"
                              :class-lst="invProtEduDivs && invProtEduDivs.length > 0 ? 'kb-form-divider' : ''"
                              :is-number="true"
                              :is-money="true"
                              :disabled="isReadOnly"
                          />
                          <div v-if="invProtEduDivs && invProtEduDivs.length > 0" class="kb-form-column kb-form-dropdown-group">
                            <div class="kb-form-dropdown" :class="{'is-active': toggles.invProtEduDivs}">
                              <button class="kb-form-dropdown-btn" @click.stop="toggles.invProtEduDivs=!toggles.invProtEduDivs">
                                <span class="text">{{ invProtEduDivNm }}</span><i class="icon-arrow"></i>
                              </button>
                              <div v-if="!isReadOnly" class="kb-form-dropdown-options">
                                <div class="dropdown-option">
                                  <ul class="dropdown-option-list">
                                    <li v-for="(item, idx) in invProtEduDivs" class="dropdown-option-item" :key="idx">
                                      <a href="javascript:" class="dropdown-option-link" @click.stop="selectInvProtEduDivs(idx)">
                                        <span class="dropdown-option-text">{{ item.nm }}</span></a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="content-item">
                    <div class="kb-form-fields">
                      <!-- kb-form-item:자격증명 -->
                      <div class="kb-form-item">
                        <MobileUpload
                            v-model="certFiles"
                            title="투자자보호교육수료증"
                            placeholder="투자자보호교육수료증을 등록하세요."
                            btn-title="투자자보호교육수료증 등록하기"
                            :extensions="extensions"
                            :max-qty="10"
                            sequence-key="proofDataSn"
                            :disabled="isReadOnly"
                        />
                      </div>
                    </div>
                  </div>

                </template>
                <!-- kb-form-row:학원비 TODO:등급자격증만 -->
                <template v-if="formModes.isTrnAid && license.grd1Cd != null">
                  <div class="content-item">
                    <div class="kb-form-fields">
                      <!-- kb-form-item:응시료 -->
                      <div class="kb-form-item">
                        <MobileFormItemInput
                            title="학원비"
                            v-model="param.acdmFee"
                            placeholder="학원비를 입력하세요."
                            :disabled="isReadOnly"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="content-item">
                    <div class="kb-form-fields">
                      <!-- kb-form-item:자격증명 -->
                      <div class="kb-form-item">
                        <MobileUpload
                            v-model="acadRegCert"
                            title="학원비 영수증"
                            placeholder="학원비 영수증을 등록하세요."
                            btn-title="수강증 및 학원비 영수증 등록"
                            sequence-key="proofDataSn"
                            :extensions="extensions"
                            :max-qty="10"
                            :disabled="isReadOnly"
                        />
                      </div>
                    </div>
                  </div>
                </template>

                <template v-if="formModes.isExamFeeSprt">
                  <div class="content-item">
                    <div class="kb-form-fields">
                      <!-- kb-form-item:응시료 -->
                      <div class="kb-form-item">
                        <MobileFormItemInput
                            title="응시료"
                            v-model="param.examFee"
                            placeholder="응시료를 입력하세요."
                            :disabled="isReadOnly"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="content-item">
                    <div class="kb-form-fields">
                      <!-- kb-form-item:자격증명 -->
                      <div class="kb-form-item">
                        <MobileUpload
                            v-model="examFeeFiles"
                            title="응시료 영수증"
                            placeholder="영수증을 등록하세요."
                            btn-title="영수증 등록"
                            sequence-key="proofDataSn"
                            :extensions="extensions"
                            :max-qty="10"
                            :disabled="isReadOnly"
                        />
                      </div>
                    </div>
                  </div>
                </template>

                <template v-if="formModes.isBookCostSprt">
                  <div class="content-item">
                    <div class="kb-form-fields">
                      <!-- kb-form-item:응시료 -->
                      <div class="kb-form-item">
                        <MobileFormItemInput
                            title="교재비"
                            v-model="param.bookCost"
                            placeholder="교재비를 입력하세요."
                            :disabled="isReadOnly"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="content-item">
                    <div class="kb-form-fields">
                      <!-- kb-form-item:자격증명 -->
                      <div class="kb-form-item">
                        <MobileUpload
                            v-model="receiptFiles"
                            title="교재비 영수증"
                            placeholder="영수증을 등록하세요."
                            btn-title="도서주문 내용 및 영수증 등록"
                            sequence-key="proofDataSn"
                            :extensions="extensions"
                            :max-qty="10"
                            :disabled="isReadOnly"
                        />
                      </div>
                    </div>
                  </div>
                </template>

                <template v-if="formModes.isInvProtEdu || formModes.isExamFeeSprt || formModes.isBookCostSprt || formModes.isTrnAid">
                  <div class="content-item">
                    <div class="total-num">
                      <p>합계금액(실소요비)</p>
                      <p>{{ numberComma(param.totAmt) }}</p>
                    </div>
                  </div>
                  <div class="content-item" v-if="license.grd1Cd != null">
                    <div class="total-num">
                      <p>지원예정금액</p>
                      <p>{{ numberComma(expAmt) }}</p>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </section>

          <section class="content-section" v-if="param.rtnYn=='Y' || param.addAmtYn=='Y'">
            <header class="section-header header-divider">
              <h4 class="title">환입 및 추가지원금액</h4>
            </header>
            <div class="section-content">
              <div class="kb-form-fields kb-form-fields-v2" v-if="param.rtnYn=='Y'">
                <div class="kb-form-row">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">환입금액</span>
                  </label>
                </div>
                <div class="kb-form-row">
                  <input type="text" class="kb-form-control"  :value="numberComma(param.rtnAmt)" readonly>
                  <div class="kb-form-side">
                    <span class="kb-form-text">원</span>
                  </div>
                </div>
              </div>
              <div class="kb-form-fields kb-form-fields-v2" v-if="param.addAmtYn=='Y'">
                <div class="kb-form-row">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">추가지원금액</span>
                  </label>
                </div>
                <div class="kb-form-row">
                  <input type="text" class="kb-form-control" :value="numberComma(param.addAmt)" readonly>
                  <div class="kb-form-side">
                    <span class="kb-form-text">원</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- content-section -->

          <section class="content-section" v-if="data == null" >
<!--          <article class="popup-section section-agree">-->
            <UserAgreementMobile
                ref="usrAgrmntRef"
                v-model:user-agreement="userAgreement"
                @applyBtnEnable="applyBtnEnable"
            />
<!--          </article>-->
          </section>
<!--          <section class="content-section">-->
<!--            <header class="section-header">-->
<!--              <h4 class="title">증빙서류 제출시 유의사항</h4>-->
<!--            </header>-->
<!--            <div class="section-content">-->
<!--              <div class="content-item">-->
<!--                <div class="desc">-->
<!--                  <p>1. 자기주도학습비는 직원의 자기계발 및 업무능력향상을 위해 은행재원으로 지원되고 있습니다. 본 제도의 취지에 맞지 않는 부당수령사례(환불/대리수강/증명서위변조/사은품 수령 등) 확인시 소속부점통보, 자기주도학습비 지원제한 및 각종 연수 참가 제한 조치되며 사안이 중대한 경우 인사조치 될 수 있습니다. 상기 내용을 숙지 하였음을 확인합니다.-->
<!--                    <br>-->
<!--                    2. 신청내용 검증을 위한 담당무서의 자료제출 및 개인정보 조회에 동의합니다.-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="content-item">-->
<!--              <label for="chk_agree_03" class="item-agree">상기내용을 숙지하였으며 동의합니다.-->
<!--                <div class="kb-form-check"><input v-model="userAgreement" type="checkbox" class="kb-form-check-input" id="chk_agree_03" @click="userAgreement=!userAgreement"></div>-->
<!--              </label>-->
<!--            </div>-->
<!--          </section>-->
        </div>
        <MobileSupportButton
            :stt="data && data.stt"
            :aply-stt-cd-dcd="data && data.aplySttCdDcd"
            :apply-btn-enable="applyBtnEnable"
            :user-agreement="userAgreement"
            :apply="apply"
            :cancel="cancel"
            :close-modal="closeModal"
        />
      </div>
    </div>
    <!-- //main-content -->
  </main>
  <MobileLicenseSearch
      v-if="isLicenseSearch"
      v-model="isLicenseSearch"
      v-model:selected-value="license"
      :keyword="license.lcsnNm"
      @update:modelValue="changeLicense"
  />
</template>

<script>
import MobileApplicant from '@/components/support/mobile/MobileApplicant';
import {supportLicenseSetup} from '@/assets/js/modules/support/support-license-setup';
import MobileUpload from '@/components/support/common/MobileUpload';
import InputText from '@/components/support/common/InputText';
import MobileFormItemInput from '@/components/support/common/MobileFormItemInput';
import RowDatepicker from '@/components/support/common/RowDatepicker';
import MobileSupportButton from '@/components/support/common/mobile/MobileSupportButton';
import MobileLicenseSearch from '@/components/support/mobile/MobileLicenseSearch';
import UserAgreementMobile from "@/components/support/common/mobile/UserAgreementMobile";
import InputCustomSelect from "@/components/support/common/InputCustomSelect.vue";

export default {
  name: "SupportStatusMobileLicenseModal",
  components: {
    InputCustomSelect,
    MobileLicenseSearch,
    MobileSupportButton,
    RowDatepicker,
    MobileFormItemInput,
    InputText, MobileUpload, MobileApplicant, UserAgreementMobile},
  props: {
    modelValue: Boolean,
    data: { type: Object, default: null },
    reList : Function,
    jncmpYmd:String
  },
  setup: supportLicenseSetup
}
</script>