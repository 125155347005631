import store from '@/store';
import {
    ACT_GET_LEARN_TARGET_LIST,
    ACT_GET_SOC_BOARD_LIST, ACT_GET_SOC_REL_GROUP_LIST, MUT_SET_GROUP_LIST,
    MUT_SET_LEARN_TARGET_LIST, ACT_GET_SOC_REL_MULT_GROUP_LIST, MUT_SET_MULT_GROUP_LIST, ACT_GET_MY_SSL_LST
} from '@/store/modules/ssl/ssl-index';
import {getItems, getPaging, lengthCheck, timestampToDateFormat} from '@/assets/js/util';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {
    getDistCrseCd,
    getRouteLrnTrgtGrpMstSn,
    isMyLrnTrgtGrpMstSn,
} from '@/assets/js/modules/ssl/ssl-common';
import {computed, reactive, ref} from 'vue';
import {closeToggles} from "@/assets/js/ui.init";

export const getLearnTargetList = lrnTrgtGrpMstSn => {
    store.dispatch(`ssl/${ACT_GET_LEARN_TARGET_LIST}`, {lrnTrgtGrpMstSn, customCd: 'ssl'}).then(res => {
        if(lengthCheck(res)){
            setLearnTargetList(getItems(res));
        }else{
            setLearnTargetList([]);
        }
    }).catch(e => {
        console.error(e);
        setLearnTargetList([]);
    });
};

export const setLearnTargetList = (items) => {
    store.commit(`ssl/${MUT_SET_LEARN_TARGET_LIST}`, items);
}


export const getMultGroupList = () => {
    store.dispatch(`ssl/${ACT_GET_SOC_REL_MULT_GROUP_LIST}`).then(res => {
        if(lengthCheck(res)){
            setMultGroupList(getItems(res));
        }else{
            setMultGroupList([]);
        }
    }).catch(e => {
        console.error(e);
        setMultGroupList([]);
    });
}

export const setMultGroupList = (items) => {
    store.commit(`ssl/${MUT_SET_MULT_GROUP_LIST}`, items);
}

export const getGroupList = () => {
    store.dispatch(`ssl/${ACT_GET_SOC_REL_GROUP_LIST}`).then(res => {
        if(lengthCheck(res)){
            setGroupList(getItems(res));
        }else{
            setGroupList([]);
        }
    }).catch(e => {
        console.error(e);
        setGroupList([]);
    });
}

export const setGroupList = (items) => {
    store.commit(`ssl/${MUT_SET_GROUP_LIST}`, items.map(x => ({...x, lrnerId: x.leaderLrnerId})));
}

export const sslLearnerBoardSetup = (props) => {
    const store = useStore();
    const route = useRoute();

    const isLoading = ref(true);
    const items = ref([]);
    // const paging = reactive({pageNo: 1, pageSize: 12, totalCount: 0, hasMore: false});
    const paging = reactive({pageNo: 1, pageSize: (props.lmtCnt>0?props.lmtCnt:12), totalCount: 0, hasMore: false});
    const lrnerId = computed(() => route.params.lrnerId);

    const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));
    const isMyLrnTrgt = computed(() => isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn.value));

    /* 년도, 과정조회 추가 */
    const filters = reactive({
        distCrseSn: 0,
        objType:'',
        sort:props.sortCd?props.sortCd:'new',
    });

    const selToggles = reactive({
        distCrseSn: false,
        objType:false,
        sort:false
    });

    const distOptions =ref([]);
    const getOptions= async ()=>{ //년도별 my입과 과정
        // console.log('year : ' + filters.year);
        distOptions.value = [];

        await store.dispatch(`ssl/${ACT_GET_MY_SSL_LST}`, (new Date).getFullYear()).then(res => {
            if (lengthCheck(res)) {
                distOptions.value = getItems(res);
            }
            if(lrnTrgtGrpMstSn.value > 0 && filters.distCrseSn == 0){
                filters.distCrseSn = parseInt(distOptions.value.filter(x=>x.lrnTrgtGrpMstSn==route.params.lrnTrgtGrpMstSn).map(y=>y.distCrseSn).toString());
            }

        }).catch(e => {
            console.error(e);
        });
    }
    getOptions();

    const typeOptions = ref([
        {key:'note', value:'노트'},
        {key:'vod', value:'동영상'},
        {key:'pdf', value:'PDF'},
    ]);

    const sortOptions = ref([
        {key:'new', value:'최신순'},
        {key:'like', value:'인기순'},
    ]);

    const getLrnerSocBoardList = (isInit) => {
        isLoading.value = true;
        if(isInit) items.value = [];
        store.dispatch(`ssl/${ACT_GET_SOC_BOARD_LIST}`, {
            lrnTrgtGrpMstSn: lrnTrgtGrpMstSn.value,
            distCrseSn:(filters.distCrseSn?filters.distCrseSn:0),
            lrnerId: lrnerId.value,
            sortCd:filters.sort,
            cttType:filters.objType,
            pageNo: paging.pageNo,
            pageSize: paging.pageSize
        }).then(res => {


            if(lengthCheck(res)) {

                items.value.push(...getItems(res).map(x => ({
                                    ...x,
                                    date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
                                    day: timestampToDateFormat(x.pblcnDt, 'dd'),
                                    distCrseCd: getDistCrseCd(x.distCrseSn)
                                })));
            }
            const resPaging = getPaging(res);
            paging.totalCount = resPaging.totalCount;
            if(props.lmtCnt == 0) {
                paging.hasMore = resPaging.hasMore;
            }

            isLoading.value = false;
        }).catch(() => {
            isLoading.value = false;
        });
    }

    const moreFunc = () => {
        if(isLoading.value) return;
        isLoading.value = true;
        paging.pageNo++;
        getLrnerSocBoardList();
    }

    getLrnerSocBoardList();

    const goBoard=()=>{
        getLrnerSocBoardList(true);
    }

    return {
        isLoading,
        items,
        paging,
        isMyLrnTrgt,
        moreFunc,

        filters, selToggles, distOptions, typeOptions, sortOptions,
        closeToggles,
        getLrnerSocBoardList, goBoard
    }


}
