<template>
  <div class="hrdcloud-list-wrapper hrdcloud-swiper" :class="{ 'hrdcloud-list-edit' : isEditable }">
    <slot name="action" />
    <swiper
        class="hrdcloud-list"
        :modules="modules"
        slides-per-view="auto"
        :speed="750"
        :navigation="{
            prevEl: '.swiper-btn-prev',
            nextEl: '.swiper-btn-next'
        }"
    >
      <swiper-slide v-for="(item,idx) in items" :key="idx" class="hrdcloud-item" :class="{'isShorts' : isShorts}">
        <HrdCloudEasyContent
            v-if="item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_ZIP"
            :item="item"
            :idx="idx"
            :session="session"
            :is-editable="isEditable"
            :is-selected="item.isSelected"
            :show-put="showPut"
            @click.stop="editFunc && editFunc(idx)"
        />
        <HrdCloudContent
            v-else
            :item="item"
            :idx="idx"
            :session="session"
            :is-editable="isEditable"
            :is-selected="item.isSelected"
            :show-put="showPut"
            :is-shorts="isShorts"
            :class="{'isShorts' : isShorts}"
            @click.stop="editFunc && editFunc(idx)"
            :show-count="showCount"
        />
      </swiper-slide>
      <button class="swiper-btn-prev">
        <i class="icon-arrow">prev</i>
      </button>
      <button class="swiper-btn-next">
        <i class="icon-arrow">next</i>
      </button>
    </swiper>
  </div>
</template>
<style scoped>
  .isShorts {
    flex: 0 0 230px !important;
  }
</style>
<script>
import HrdCloudContent from '@/components/hrdcloud/HrdCloudContent';
import HrdCloudEasyContent from '@/components/hrdcloud/HrdCloudEasyContent';
import {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {cttTyCdDcds, disableMyAddChanged} from '@/assets/js/modules/hrd/hrd-common';
import navigationUtils from '@/assets/js/navigationUtils';
import {computed, toRefs, watch} from 'vue';
import {useStore} from 'vuex';

export default {
  name: 'HrdCloudSwiper',
  components: {Swiper, SwiperSlide, HrdCloudEasyContent, HrdCloudContent},
  props: {
    items: Array,
    session: Object,
    showPut: Boolean,
    isEditable: Boolean,
    editFunc: Function,
    isShorts: Boolean,
    showCount: {
      type:Boolean,
      default: true
    }
  },
  setup(props) {
    const store = useStore();
    const leaderAdd = computed(() => store.state.hrdcloud.leaderAdd);
    const {items} = toRefs(props);

    watch(() => leaderAdd.value.show, () => {
      if (!leaderAdd.value.show && leaderAdd.value.updateMyAdd) {
        const lrnObjDtlDistSn = leaderAdd.value.lrnObjDtlDistSn;
        const item = items.value && items.value.length > 0 ? items.value.filter(x => x.lrnObjDtlDistSn === lrnObjDtlDistSn)[0] : null;
        if (item) {
          item.put = leaderAdd.value.isPut;
          disableMyAddChanged();
        }

      }
    });

    return {
      cttTyCdDcds,
      isMobile: navigationUtils.any(),
      modules: [Navigation]
    }
  }
};
</script>