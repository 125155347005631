<template>
  <main class="kb-main" id="kb-hrdcloud-v2" style="padding: 15px 0px 15px 15px;">
    <div class="main-content main-component">
      <slot name="top" />
      <article v-if="learningRoadmapList" class="content-section">
        <header class="section-header" style="display: flex; padding-right: 15px;">
          <h4 class="title">학습 로드맵</h4>
          <div class="header-side"><a class="sub-text text-dark kb-mouse-cursor" @click.stop="$router.push({name:'HrdCloudRoadmapMain'})">전체보기</a></div>
        </header>
        <div class="section-body">
          <swiper v-if="learningRoadmapList.length > 0"
                  class="hrdcloud-swiper"
                  :modules="modules"
                  slides-per-view="auto"
                  effect="coverflow"
                  :coverflow-effect="{'rotate':0, 'stretch':0, 'depth':100, 'modifier':1, 'slideShadows':false}"
                  :centered-slides="false" >
            <swiper-slide  v-for="(roadmap,idx) in learningRoadmapList" :key="idx" class="roadmap-swiper">
              <HrdCloudRoadmapCardV2 :data="roadmap.data" />
            </swiper-slide>
          </swiper>
          <LoadingDiv v-else />
        </div>
      </article>
    </div>
  </main>
</template>

<script>
import {useStore} from 'vuex';
import {computed, onMounted, ref} from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {EffectCoverflow} from "swiper";
import LoadingDiv from '@/components/common/LoadingDiv';
import {getHrdCloudLearningRoadmapSortedList} from "@/assets/js/modules/hrd/hrd-cloud-roadmap-setup";
import HrdCloudRoadmapCardV2 from "@/components/hrdcloud/HrdCloudRoadmapCardV2.vue";

export default {
  name: 'HrdCloudMobileMainList',
  components: {
    HrdCloudRoadmapCardV2, LoadingDiv, Swiper, SwiperSlide},
  props: {
    mainList: Array
  },
  setup() {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const learningRoadmapList = ref([])

    onMounted(() => {
      getHrdCloudLearningRoadmapSortedList(learningRoadmapList);
    })

    return {
      session,
      learningRoadmapList,
      modules: [EffectCoverflow]
    }
  }
};
</script>