import {useStore} from "vuex";
import {computed, reactive, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useAlert} from "@/assets/js/modules/common/alert";
import {ACT_GET_BOARD_MNG, ACT_UPDATE_BOARD_MNG} from "@/store/modules/board/board";
import {getItem, isSuccess, lengthCheck, setParams} from "@/assets/js/util";

export const groupStudyPlanSetup = () => {
    const store = useStore();
    const session = computed(() => store.state.auth.session);

    const route = useRoute();
    const router = useRouter();

    const {showConfirm, showMessage} = useAlert();

    const params = reactive({
        lrnPlan: '',
        lrnGoals: '',
        lrnEtcCn: '',
        distCrseSn: 0
    });

    const lrnPlanCnt = ref(0);
    const lrnGoalsCnt = ref(0);
    const lrnEtcCnCnt = ref(0);

    const lrnGoalPlaceholder = '그룹러닝을 통해 달성하고 싶은 학습 및 성과 목표를 작성해주세요.';
    const lrnPlanPlaceholder = '학습 일정 및 커리큘럼을 작성해주세요.';
    const lrnEtcCnPlaceholder = '학습 진행사항 및 성과를 작성해주세요.(학습기간 중 자유롭게 작성)';


    watch(() => [params.lrnPlan, params.lrnGoals, params.lrnEtcCn], () => {
        lrnPlanCnt.value = params.lrnPlan.length;
        lrnGoalsCnt.value = params.lrnGoals.length;
        lrnEtcCnCnt.value = params.lrnEtcCn.length;


        if(lrnGoalsCnt.value > 500) {
            params.lrnGoals.substring(0, 500);
        }
        if(lrnPlanCnt.value > 1000) {
            params.lrnPlan.substring(0, 1000);
        }
        if(lrnEtcCnCnt.value > 1000) {
            params.lrnEtcCn.substring(0, 1000);
        }
    });



    const isLeader = ref(false);

    const getBoardMng = () => {
        store.dispatch(`board/${ACT_GET_BOARD_MNG}`, route.params.boardSn).then(res => {
            if(lengthCheck(res)) {
                const item = getItem(res)
                setParams(params, item);
                if(item.grpRoleCdDcd === '2010001') {
                    isLeader.value = true;
                }

            }
        })
    };

    const clickSave = () => {
        showConfirm({
            text: '저장하시겠습니까?',
            callback: () => {

                let result = false;
                if(!params.lrnPlan || !params.lrnGoals || !params.lrnEtcCn) {
                    showMessage({
                        text: '모두 입력해주세요.',
                    })
                } else {
                    result = true;
                }

                if(result) {
                    store.dispatch(`board/${ACT_UPDATE_BOARD_MNG}`, {
                        boardSn: route.params.boardSn,
                        params: params
                    }).then(res => {
                        if(isSuccess(res)){
                            router.push({name: 'GroupLearning'});
                        }
                    });
                }

            }
        })
    }

    getBoardMng();

    return {
        session,
        params,
        isLeader,
        lrnPlanCnt,
        lrnGoalsCnt,
        lrnEtcCnCnt,
        lrnGoalPlaceholder,
        lrnPlanPlaceholder,
        lrnEtcCnPlaceholder,
        clickSave
    }

}