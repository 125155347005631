<template>
  <div :class="[ isMobile ? 'hrdcloud-list-container' : 'popup-content' ]">
    <div class="hrdcloud-list-wrapper">
      <div v-if="!isMobile" class="hrdcloud-actions">
        <button type="button" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="handleNewUpload">
          <i class="icon-upload"></i>
          <span class="text">쓸스튜디오(영상/PDF) 등재</span>
        </button>
      </div>
      <ul v-if="!isMobile" class="hrdcloud-list" data-grid="4" style="box-sizing: border-box;">
        <li v-for="(item, idx) in items" class="hrdcloud-item" :key="idx" @click="handleItemClick(idx)" style="flex: 0;">
          <article class="hrdcloud-card" style="width: 273px;">
<!--            <div class="hrdcloud-image" style="color:black; outline:unset !important; text-align:center; height: 400px;">-->
<!--              <div class="image" style="background-color:black; height: 400px;">-->
<!--                <img :src="item.thumb" alt="임시이미지" style="width:unset !important;object-fit: cover; height: 400px; width: 225px;" />-->
<!--              </div>-->
<!--            </div>-->
            <div class="hrdcloud-image" style="color:black; outline:unset !important; text-align:center; height: 483px;">
              <div class="image" style="height: 483px;">
                <a href="javascript:" class="image-link">
                  <img v-if="item.thumb" :src="getImg(item.thumb)" style="object-fit: cover; height: 483px; width: 273px;" />
                  <img v-else src="../../../assets/lxp/images/@tmp/@tmp_hrdcloud_03.jpg" alt="임시이미지" />
                </a>
              </div>
            </div>
            <div class="hrdcloud-content">
              <div class="content-title">
                <h5 class="title"><a href="javascript:" class="title-link">{{ item.cttTitle }}</a></h5>
              </div>
              <div :class="[ isMobile ? 'content-badge' : 'content-status' ]">
                <div class="badge" :class="getStatus(item.stt).classNm">
                  <span class="badge-text">{{ getStatus(item.stt).name }}</span>
                </div>
              </div>
            </div>
          </article>
        </li>
      </ul>
      <div v-else class="hrdcloud-list-wrapper">
        <div ></div>
        <!-- board-list -->
        <swiper
            class="board-card-type"
            slides-per-view="auto"
            :slides-offset-after="20"
            effect="fade"
            :navigation="{
        nextEl: '.kb-btn-note-next',
        prevEl: '.kb-btn-note-prev'
      }"
        >
          <swiper-slide v-for="(item, idx) in items" :key="idx" class="board-item">
            <!--      <article class="post-card" :data-read="item.readYn === 'Y' ? 'active' : ''" @click="goNote(item)">-->
            <article class="post-card" @click="handleItemClick(idx)">

              <a href="javascript:" class="post-link"></a>
              <div class="post-image" style="background-color: var(--kb-brown)">
                <img  v-if="item.thumb" :src="getImg(item.thumb)" alt="">
                <img v-else class="kb-thumb-list" src="../../../assets/lxp/images/@tmp/@tmp_hrdcloud_03.jpg" alt="임시이미지" />
              </div>
              <div class="post-deem"></div>
              <div class="post-content">
                <div class="post-content-date">
                  <h6>{{ item.date }}</h6>
                  <h1>{{ item.day }}</h1>

                  <div class="post-content-type" style="left: 80px;">
                    <div v-if="item.stt === '78'" class="badge-reject"><span class="text">반려</span></div>
                    <div v-else-if="item.refCttTyNm === 'VOD'" class="badge-vod"><span class="text">영상</span></div>
                    <div v-else-if="item.refCttTyNm === 'PDF'" class="badge-pdf"><span class="text">PDF</span></div>
                    <div v-else class="badge-note"><span class="text">{{ getStatus(item.stt).name }}</span></div>
                  </div>
                </div>
                <section class="post-content-desc">
                  <div class="post-title">
                    <h3 class="title">{{ item.cttTitle }}</h3>
                  </div>
                  <div class="post-desc">
                    <div class="post-content-date">
                      <h6>{{ item.date }}</h6>
                      <h1>{{ item.day }}</h1>
                    </div>
                    <span v-html="item.title"></span>
                    <p v-html="(item.cn !=null && item.cn.length > 300 ? item.cn.substring(0,300) + '...':item.cn)"></p>
                  </div>
                </section>
              </div>
            </article>
          </swiper-slide>
        </swiper>
      </div>
      <div v-if="items && items.length === 0 && !isLoading" class="search-container">
        <div class="result-empty">
          <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">등재완료된 쓸스튜디오 콘텐츠가 없습니다.</p>
<!--          <p class="text">나만의 다양한 노하우를 동영상으로 제작하여 공유해주세요.</p>-->
        </div>
      </div>
      <LoadingDiv v-if="isLoading" v-model="isLoading" />
      <CommonPaginationFront v-if="!isMobile" :paging="paging" :page-func="pagingFunc"/>
    </div>
    <CommonPaginationFront v-if="isMobile && items && items.length > 0 && !isLoading" :paging="paging" :page-func="pagingFunc"/>
  </div>
</template>

<script>
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {reactive, ref, toRefs, watch} from 'vue';
import LoadingDiv from '@/components/common/LoadingDiv';
import {getSSLStudioList} from '@/assets/js/modules/hrd/hrd-history-common';
import {useRouter} from 'vue-router';
import {useAlert} from '@/assets/js/modules/common/alert';
import {getItems, lengthCheck, stringCheck, timestampToDateFormat} from '@/assets/js/util';
import {ACT_GET_MY_SSL_LST} from "@/store/modules/ssl/ssl-index";
import {currentYear, getCrseSnToDistCrseCd} from "@/assets/js/modules/ssl/ssl-common";
import {useStore} from "vuex";
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';

export default {
  name: 'SSLStudioList',
  methods: {stringCheck},
  components: {SwiperSlide, Swiper, LoadingDiv, CommonPaginationFront},
  props: {
    uploadFunc: Object,
    pendingFunc: Object,
    isMobile: Boolean
  },
  setup(props) {
    const router = useRouter();
    const {showMessage} = useAlert();
    const isLoading = ref(true);
    const {uploadFunc, pendingFunc} = toRefs(props);
    const items = ref([]);
    const store = useStore();
    const paging = reactive({
      pageNo: 1,
      pageSize: 4,
      totalCount: 0
    });

    const getList = () => {
      getSSLStudioList({}, items, paging, () => {
        isLoading.value = false;
        items.value = items.value.map(x => ({
          ...x,
          date: timestampToDateFormat((x.pblcnDt ? x.pblcnDt : x.regDt), 'yyyy.MM'),
          day: timestampToDateFormat((x.pblcnDt ? x.pblcnDt : x.regDt), 'dd'),
        }));
      }, () => {isLoading.value=false;});
    };

    const getStatus = (stt) => {
      let info = {
        name: '',
        classNm: '',
      }
      if (stt === '01' || stt === '53') {
        info.name = '작성중';
        info.classNm = 'badge-silver';
      }
      else if (stt === '81') {
        info.name = '심사대기중';
        info.classNm = 'badge-silver';
      }
      else if (stt === '78') {
        info.name = '반려';
        info.classNm = 'badge-red';
      }
      else if (stt === '00') {
        info.name = '승인완료';
        info.classNm = 'badge-primary';
      }
      return info;
    };

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      getList();
    };

    const handleNewUpload = () => {
      uploadFunc.value.update(0);
      uploadFunc.value.toggle();
    };

    const distOptions =ref([]);
    const getOptions= async ()=>{ //년도별 my입과 과정
      // console.log('year : ' + filters.year);
      distOptions.value = [];

      await store.dispatch(`ssl/${ACT_GET_MY_SSL_LST}`, currentYear).then(res => {
        if (lengthCheck(res)) {
          distOptions.value = getItems(res);
        }
        distOptions.value = getItems(res).map(x=>({...x, distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)}));

        console.log("distOptions= ", distOptions.value);

      }).catch(e => {
        console.error(e);
      });
    }

    getOptions();

    const handleItemClick = (idx) => {
      let item = items.value[idx];
      console.log("JIOZxjciozjczioc", item.lrnObjDtlDistSn);
      let stt = item.stt;
      if (stt == '01' || stt == '53' || stt == '78') {
        uploadFunc.value.update(item.cttMstSn);
        uploadFunc.value.toggle();
      } else if (stt == '81') {
        pendingFunc.value.update(item.cttMstSn);
        pendingFunc.value.toggle();
      } else {
        // TODO :: 상세페이지로 이동
        if (stt == '00') {
          // router.push({ name: 'HrdCloudContentBridge',params: {lrnObjDtlDistSn: item.lrnObjDtlDistSn} });

          let crseMstSn;
          if (item.refTyNm) {
            crseMstSn = distOptions.value.filter(x => x.lrnTrgtGrpMstSn == Number(item.refTyNm)).map(y => y.crseMstSn);
          } else {
            crseMstSn = distOptions.value.filter(x => x.lrnTrgtGrpMstSn == item.lrnTrgtGrpMstSn).map(y => y.crseMstSn);
          }

          console.log("crseMstSn = ", crseMstSn);
          console.log("item.lrnTrgtGrpMstSn = ", item.lrnTrgtGrpMstSn);

          router.push({
            name: 'SSLView',
            params: {
              distCrseCd: getCrseSnToDistCrseCd(crseMstSn),
              lrnTrgtGrpMstSn: item.lrnTrgtGrpMstSn,
              socLrnPostSn: item.socLrnPostSn
            }
          });

        } else {
          showMessage('문제가 발생했습니다. 관리자에게 문의하세요.');
        }
      }
    };

    const getImg = (src) => {
      return`${process.env.VUE_APP_CLOUDFRONT_URL}/${src}`;
    };

    getList();

    watch(() => uploadFunc.value.isActive, () => {
      if (uploadFunc.value.isActive === false) {
        getList();
      }
    });

    return {
      isLoading, items, paging,
      pagingFunc, getStatus, handleNewUpload, handleItemClick, getImg
    }
  }
};
</script>