import {useStore} from "vuex";
import {computed, reactive, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {
    addDays,
    getItem, getItems, getPaging,
    getTodayDate,
    initPaging, isSuccess,
    lengthCheck, secondToKrFormat,
    setParams,
    timestampToDateFormat, winOpen
} from "@/assets/js/util";
import {useAlert} from "@/assets/js/modules/common/alert";
import {
    ACT_GET_GROUP_LRN_ATTN_STATUS_LIST, ACT_GET_GROUP_LRN_BOARD_CASTRECORDER,
    ACT_GET_GROUP_LRN_BOARD_HRDCLOUD,
    ACT_GET_GROUP_LRN_BOARD_MNG, ACT_INSERT_GROUP_CASTRECORDER_CONVERT, ACT_UPDATE_GROUP_LEARN_BOARD_CN
} from "@/store/modules/board/board";
import {goBack} from "@/assets/js/modules/common/common";
import {getThumbUrl} from "@/assets/js/modules/course/course-common";

export const groupLearningJournalSetup = () => {
    const store = useStore();
    const session = computed(() => store.state.auth.session);

    const route = useRoute();
    const router = useRouter();

    const attnItems = ref([]);

    const paging = ref(initPaging(route, 1000));

    const pcPreviewModal = ref(false);
    const ivsCastSrc = ref({
        itnUrl:null,
        etnUrl:'',
    });
    const {showConfirm, showMessage} = useAlert();

    const item = reactive({
        distCrseSn: 0,
        distCrseNm: '',
        objNm: '',
        lrnObjTyCd: '',
        distCrseLrnFldNm: '',
        grpRoleCdDcd: '',
        bgngYmd: '',
        objBgngDt	: '',
        objEndDt: '',
        cn: '',
        avgLrnTime: 0,
        bgngLrn: '',
        endLrn: '',
        mtgAcptYn: 'N'
    });

    const cttItems = ref([]);

    const cttPaging = ref(initPaging(route));

    const cttIvsCastsItems = ref([]);

    const writeYmd = reactive({
        startYmd: '',
        endYmd: ''
    });

    const today = getTodayDate('yyyyMMdd');

    const viewCastHls = (idx) => {
        console.log("viewcasthls", idx)
        console.log(cttIvsCastsItems.value[idx]);
        let stt = getCastStt(cttIvsCastsItems.value[idx]);
        console.log("stt", stt);
        if(stt.includes("live")){
            showMessage("강의중에는 재생하실 수 없습니다. 모임에 입장하여 시청하시기 바랍니다.");
        } else if(session.value.itn){
            showMessage("은행 내부에서는 시청하실 수 없습니다. 외부 인터넷환경에서 시청하시기 바랍니다.");
        }else if(stt.includes("fin")){
            ivsCastSrc.value.etnUrl =  cttIvsCastsItems.value[idx].etnUrl;
            pcPreviewModal.value = true;
        }
    }
    const requestMp4 = (idx) => {
        console.log("request-mp4", idx);
        let stt = getCastStt(cttIvsCastsItems.value[idx]);
        let cast = cttIvsCastsItems.value[idx];
        console.log("cast info", cast);

        if(stt.includes("live")){
            showMessage("강의중에는 재생하실 수 없습니다. 모임에 입장하여 시청하시기 바랍니다.");
        }else if(stt.includes("mp4able")){
            console.log("ccc")
            showConfirm("해당 강의영상을 다운로드하기위해 변환작업을 요청하시겠습니까?<br/>영상 길이에 따라 시간이 소요될 수 있으며, 작업완료 후 다운로드 가능합니다."
            ,async ()=>{
                    let params = {
                        lrnerNm: cast.lrnerNm,
                        lrnerId: cast.lrnerId,
                        lrnObjDtlDistSn: cast.lrnObjDtlDistSn,
                        objSn: 0,
                        deptNm: cast.deptNm,
                        objNm: cast.objNm,
                        crseMstSn: item.crseMstSn,
                        deptCd: item.deptCd,
                        distCrseSn: item.distCrseSn,
                        actValue:cast.qrAttendNm,
                    };

                    const convRes = await store.dispatch(`board/${ACT_INSERT_GROUP_CASTRECORDER_CONVERT}`, params);

                    if(convRes.status && convRes.result.number==200){
                        cttIvsCastsItems.value[idx].mp4Str ="◎ 변환중";
                        showMessage('요청하였습니다.');
                    }
                    console.log(convRes);

                }
            )
        }else if(stt.includes("mp4ing")){
            showMessage("다운로드를 위한 변환작업이 진행 중입니다. 영상 길이에 따라 시간이 소요될 수 있으니 잠시 후 재시도 하시기 바랍니다.");
        }else if(stt.includes("mp4fin") && session.value.itn){
            showMessage("은행 내부에서는 다운로드 하실 수 없습니다. 외부 인터넷환경에서 다시 요청하시기 바랍니다.");
        }else{
            showConfirm(
                "해당 강의영상을 다운로드하시겠습니까?", ()=>{

                    winOpen(cttIvsCastsItems.value[idx].mp4Url)
                }
            )
        }
    }

    const getBoard = () => {
        store.dispatch(`board/${ACT_GET_GROUP_LRN_BOARD_MNG}`, {
            boardSn: route.params.boardSn,
            lrnPostSn: route.params.lrnPostSn
        }).then(res => {
            if(lengthCheck(res)) {
                const boardItem = getItem(res);

                // 모임일지 등록기간 계산(모임일지 작성 가능 상태(모임종료일 다음날을 시작으로 +14일) / 이후 [등록] 버튼 비노출)
                writeYmd.startYmd = timestampToDateFormat(addDays(boardItem.objBgngDt, 1), 'yyyy.MM.dd');
                writeYmd.endYmd = timestampToDateFormat(addDays((writeYmd.startYmd), 14), 'yyyyMMdd');

                setParams(item, boardItem);

                item.objBgngDt = timestampToDateFormat(item.objBgngDt, 'hh:mm');
                item.objEndDt = timestampToDateFormat(item.objEndDt, 'hh:mm');

                // params.cn = item.cn;
            }
        }).catch(e => {
            console.error(e);
        });
    }

    const cmptnCnt = ref(0);

    const isWrite = computed(() => {
        return item.grpRoleCdDcd === '2010001' && today > (writeYmd.startYmd).replaceAll('.', '') && writeYmd.endYmd >= today;
    });

    // 모임 현황(멤버별)
    const getGroupLrnAttnStatusList = () => {
        store.dispatch(`board/${ACT_GET_GROUP_LRN_ATTN_STATUS_LIST}`,{
            boardSn: route.params.boardSn,
            lrnPostSn: route.params.lrnPostSn,
            pageNo: paging.value.pageNo,
            pageSize: paging.value.pageSize
        }).then(res => {
            if(lengthCheck(res)){
                attnItems.value = getItems(res).map(x => ({
                    ...x,
                    regDt: timestampToDateFormat(x.regDt,'hh:mm:ss'),
                    mdfcnDt: timestampToDateFormat(x.mdfcnDt,'hh:mm:ss'),
                }));

                //cmptnYn
                cmptnCnt.value = attnItems.value.filter(x => x.rsltYn === 'Y').length;
                paging.value = getPaging(res)

            } else {
                attnItems.value = [];
                paging.value.totalCount = 0;
            }
        })
    }

    const coldCallRound = computed(() => attnItems.value.map(x => (x.coldCallRound || 0)).reduce(add,0));
    const coldCallRspnsCnt = computed(() => attnItems.value.map(x => (x.coldCallRspnsCnt || 0)).reduce(add,0));

    const add = (accumulator, a) => {
        return accumulator + a;
    }

    const getGroupLrnBoardHrdcloud = () => {
        store.dispatch(`board/${ACT_GET_GROUP_LRN_BOARD_HRDCLOUD}`, {
            lrnPostSn: route.params.lrnPostSn,
            pageNo: cttPaging.value.pageNo,
            pageSize: cttPaging.value.pageSize
        }).then(res => {
            if(lengthCheck(res)) {
                cttItems.value = getItems(res);
                cttPaging.value = getPaging(res);
            } else {
                cttItems.value = [];
                cttPaging.value.totalCount = 0;
            }
        })
    }

    getGroupLrnBoardHrdcloud();

    const setIntraImg = () => {
        return require('../../../../assets/lxp/images/cloud/denyIntranet.jpg');
    };

    const getCastStt = (item) =>{
        console.log("item check",
            `${timestampToDateFormat(item.regDt, 'hh:mm')} ${item.lrnerNm}`,
            item.regDt, item.endDt, item.objEndDt, item.objBgngDt);
        if(!item.endDt) return "live";

        if(item.objEndDt) return "hlsfin,mp4fin";
        if(item.objBgngDt) return "hlsfin,mp4ing";
        if(item.endDt)return "hlsfin,mp4able";
        return "err";

    }
    const getCastUrl =(contDiv, item) =>{
        let retUrl;

        if(getCastStt(item)=="live"){
            return require('../../../../assets/lxp/images/cloud/onclass-live.jpg');
        }

        if(session.value.itn){
            if(contDiv=="img"){
                retUrl =  setIntraImg()
            }else{
                retUrl = null;
            }
        //외부망
        }else {

            if (getCastStt(item).includes("fin")) {
                if (contDiv == "img") {
                    retUrl = `${process.env.VUE_APP_CLOUDFRONT_URL}/ivsreal/${item.qrAttendNm}/media/thumbnails/1280x720/2.jpg`
                }
                if (contDiv == "hls") {
                    retUrl = `${process.env.VUE_APP_IVSREAL_CF}/${item.qrAttendNm}/media/hls/multivariant.m3u8`
                }
                if (contDiv == "mp4"){
                    retUrl = getCastStt(item).includes("mp4fin") ?
                        `${process.env.VUE_APP_CLOUDFRONT_URL}/ivsreal/${item.qrAttendNm}/media/MP4/multivariant.mp4` : null
                }
            }
        }

        return retUrl;
    }

    //그룹러닝 화상강의 녹화목록 setting
    const getGroupLrnBoardCastrecorder = () => {
        store.dispatch(`board/${ACT_GET_GROUP_LRN_BOARD_CASTRECORDER}`, {
            lrnPostSn: route.params.lrnPostSn,
            pageNo: 1,
            pageSize: 99
        }).then(res => {
            if(lengthCheck(res)) {

                let items = getItems(res).map(x => ({
                    ...x,
                    objNm: `${timestampToDateFormat(x.regDt, 'hh:mm')} ${x.lrnerNm}`,
                    thumb: getCastUrl('img',x),
                    etnUrl:getCastUrl('hls',x),
                    mp4Url: getCastUrl('mp4',x),
                    //layer의 unique 유지를 위해
                    layerUnique: x.lrnObjDtlDistSn + x.lrnPostSn,
                    contentDiv : "nowCastRecorder",
                    mp4Str: getCastStt(x).includes('mp4fin') ? '▽ 다운로드' :
                            getCastStt(x).includes('mp4ing') ? '◎ 변환중' :
                            getCastStt(x).includes('mp4able') ? '○ 변환요청' : getCastStt(x)
                }));
                cttIvsCastsItems.value = items;
            } else {
                cttIvsCastsItems.value = [];
            }
        })
    }

    getGroupLrnBoardCastrecorder();
    const clickSave = () => {
        showConfirm({
            text: '모임일지를 등록하시겠습니까?',
            callback: () => {
                updateGroupLearnBoardCn();
            }
        })
    }

    const updateGroupLearnBoardCn = () => {
        store.dispatch(`board/${ACT_UPDATE_GROUP_LEARN_BOARD_CN}`, {
            lrnPostSn: route.params.lrnPostSn,
            params: {cn: item.cn}
        }).then(res => {
            if(isSuccess(res)) {
                showMessage({
                    text: '등록 완료됐습니다.',
                    callback: () => {
                        router.push({name: 'GroupView', params: {distCrseSn: item.distCrseSn}})
                    }
                })
            }
        }).catch(e => {
            console.error(e);
        })
    };

    const pagingFunc = (pageNo, pageSize) => {
        const query = {type: route.query.type, pageNo: pageNo};
        if (pageSize !== paging.value.pageSize) {
            query.pageSize = pageSize;
        }
        router.push({query: query});
    };

    watch(() => route.query,() => {
        if (route.name === 'GroupLearningJournal') {
            if (route.query.pageNo) {
                paging.value.pageNo = route.query.pageNo;
            } else {
                paging.value.pageNo = 1;
            }
            if (route.query.pageSize) {
                paging.value.pageSize = route.query.pageSize;
            }
        }
        getGroupLrnAttnStatusList();
    });

    getBoard();
    getGroupLrnAttnStatusList();

    const clickBack = () => {
        router.push({name: 'GroupView', params: {distCrseSn: item.distCrseSn}})

    }

    return{
        item,
        attnItems,
        cttItems,
        cmptnCnt,

        paging,
        isWrite,
        session,
        coldCallRound,
        coldCallRspnsCnt,
        secondToKrFormat,
        clickSave,
        pagingFunc,
        goBack,
        clickBack,
        getThumbUrl,
        timestampToDateFormat,
        cttIvsCastsItems,
        pcPreviewModal,
        ivsCastSrc,
        viewCastHls,
        requestMp4,
    }
}