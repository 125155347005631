<template>
  <!-- page-intro -->
  <template v-if="isReady">
    <SSLView
        v-if="currentName === 'SSLView'||currentName === 'SSLBestNoteView'"
        @acted="acted"
    />
    <template v-else>
      <div class="page-intro">
        <div class="intro-content">
          <div class="page-component">
            <h2 class="intro-title" :class="{'black-title': isBlack}">{{ mainName }}</h2>
            <div class="intro-buttons">
              <button class="kb-btn-intro" :class="{'black': isBlack}" @click="goMySSL">
                <span class="kb-btn-border" :style="distCrseCd === 'group'?'':''">
                  <img v-if="isBlack" src="@/assets/lxp/images/ssl/main/ic_myssl_border_blk.svg" alt="">
                </span>
                <SSLMySvg
                  :style="distCrseCd === 'group'?'':''"
                />
              </button>
              <button v-if="distCrseCd === 'group'" class="kb-btn-intro is-no-change" :class="{'black': isBlack}">
                <span class="kb-btn-border">
                  <router-link v-if="myGroup.length > 0" :to="{name: 'GroupView', params: {distCrseSn: myGroup[0].distCrseSn}}" class="link is-active">
                  <i class="icon-my-group"></i></router-link>
                  <router-link v-else :to="{name: 'GroupLearning'}" class="link is-active"><i class="icon-my-group"></i></router-link>
                </span>
              </button>
              <button v-else-if="distCrseCd === 'knowledge'" class="kb-btn-intro is-white">
                <span class="kb-btn-border">
                  <router-link :to="{name: 'KnowledgeELearning'}" class="link"><i class="icon-my-test"></i></router-link>
                </span>
              </button>
            </div>

<!--            <div class="intro-links" :class="{'black': isBlack}">-->
<!--              <SSLTrial-->
<!--                  trial-class="intro-link"-->
<!--                  text-class="text"-->
<!--                  :show-arrow="true"-->
<!--              />-->
<!--              <SSLManual-->
<!--                  manual-class="intro-link"-->
<!--                  text-class="text"-->
<!--                  :show-arrow="true"-->
<!--              />-->
<!--            </div>-->
          </div>
        </div>
        <div class="intro-background"></div>
      </div>
      <!-- //page-intro -->
      <!-- page-container -->
      <div class="page-container">
        <SSLMainNotice :notices="notices"/>

        <div class="page-body">
          <div class="page-component">
            <!-- page-aside -->
            <SSLMember
                v-if="!isNoneReward"
                :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
            />
            <SSLBoard
                :is-loading="isLoading"
                :items="items"
                :paging="paging"
                :more-func="moreFunc"
                :search-func="searchFunc"
                :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
                :view-modes="viewModes"
                :view-mode-idx="viewModeIdx"
                :init-keyword="keyword"
                @moveBoard="moveBoard">
              <template v-slot:loading-area>
                <template v-if="isLoading">
                  <LoadingDiv v-model="isLoading" />
                </template>
              </template>
              <template v-slot:sub-option>
                <div class="board-list-top">
                  <div class="my-category" style="display: flex;justify-content: flex-start">
                    <div class="top-column" style="margin-left:20px" v-if="isGroup">
                      <SortButtonSelect
                          v-model="filters.distCrseLrnFldSn"
                          v-model:toggle="selToggles.crseDistFlds"
                          title="분야"
                          :options="crseDistFlds"
                          sequence-key="distCrseLrnFldSn"
                          name-key="distCrseLrnFldNm"
                          :is-all="false"
                          :width="200"
                          :textStyle="`font:normal 16px/20px var(--kb-font-KBFGDisplayB)`"
                          @update:toggle="closeToggles(selToggles, 'crseDistFlds')"
                          @selected="getSocsBoardList(true);"
                      />
                      <!--                      @selected="goQuerySearch()"-->
                    </div>
                    <div class="top-column" style="margin-left:20px">
                      <SortButtonSelect
                          v-model="filters.objType"
                          v-model:toggle="selToggles.objType"
                          title="모든 타입"
                          :options="typeOptions"
                          sequence-key="key"
                          name-key="value"
                          :is-all="true"
                          :width="130"
                          :textStyle="`font:normal 16px/20px var(--kb-font-KBFGDisplayB)`"
                          @update:toggle="closeToggles(selToggles, 'objType')"
                          @selected="getSocsBoardList(true);"
                      />
                    </div>
                  </div>
                </div>
              </template>

            </SSLBoard>
          </div>
        </div>
        <!-- page-footer -->
        <div class="page-footer">
          <div class="page-component"></div>
        </div>
      </div>
    <!-- //page-container -->
    </template>
  </template>

</template>

<script>
import SSLMember from '@/components/ssl/main/SSLMember';
import SSLBoard from '@/components/ssl/SSLBoard';
import SSLMySvg from '@/components/ssl/main/SSLMySvg';
import SSLMainNotice from '@/components/ssl/main/SSLMainNotice';
// import SSLTrial from '@/components/ssl/common/SSLTrial';
// import SSLManual from '@/components/ssl/common/SSLManual';
import SSLView from '@/views/ssl/SSLView';
import {sslMainSetup} from '@/assets/js/modules/ssl/setup/ssl-main-setup';
import SortButtonSelect from "@/components/common/SortButtonSelect.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";

export default {
  name: 'SSLMain',
  components: {
    LoadingDiv,
    SortButtonSelect,
    SSLView,
    // SSLManual,
    // SSLTrial,
    SSLMainNotice,
    SSLMySvg,
    SSLMember,
    SSLBoard
  },
  setup: sslMainSetup
}
</script>
