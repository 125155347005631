<template>
  <div class="footer-row footer-divider">
    <div class="footer-component">
      <!-- footer-breadcrumb -->
      <nav class="footer-breadcrumb">
        <ol class="kb-breadcrumb-list">
          <!--          <li class="kb-breadcrumb-item"><a href="javascript:" class="kb-breadcrumb-link"><i class="kb-logo"></i></a></li>-->
          <!--          <li class="kb-breadcrumb-item"><a href="javascript:" class="kb-breadcrumb-link">마이페이지</a></li>-->
        </ol>
      </nav>
      <!-- //footer-breadcrumb -->
      <!-- footer-sitemap -->
      <div class="footer-sitemap">
        <template v-for="(menu, idx) in menus" :key="idx">
          <div v-if="!webHiddenMenu.includes(menu.menuNm)" class="sitemap-column">
            <article class="sitemap-group">
              <h5 class="sitemap-group-title sitemap-group-link">{{ menu.menuNm }}</h5>
              <ul v-if="menu.children.length > 0" class="sitemap-list">
                <li v-for="(child, i) in menu.children" class="sitemap-item" :key="`child-${idx}-${i}`" style="padding-left: 0px">
                  <template v-if="child.menuUrl">
                    <a v-if="isTempMenu(child)" href="javascript:" class="sitemap-link">・ {{ child.menuNm }}</a>
                    <a v-else :to="child.menuUrl" class="sitemap-link kb-mouse-cursor" @click="goMenu(child)">・ {{ child.menuNm }}</a>
                  </template>
                </li>
              </ul>
            </article>
          </div>
        </template>

        <!--        <div class="sitemap-column">-->
        <!--          <article class="sitemap-group">-->
        <!--            <h3 class="sitemap-group-title"><a href="javascript:" class="sitemap-group-link">마이페이지</a></h3>-->
        <!--            <ul class="sitemap-list">-->
        <!--              <li class="sitemap-item"><a href="javascript:" class="sitemap-link">마이페이지</a></li>-->
        <!--              <li class="sitemap-item"><a href="javascript:" class="sitemap-link">Talent Board</a></li>-->
        <!--              <li class="sitemap-item"><a href="javascript:" class="sitemap-link">비용신청현황조회</a></li>-->
        <!--            </ul>-->
        <!--          </article>-->
        <!--          <article class="sitemap-group">-->
        <!--            <h3 class="sitemap-group-title"><router-link :to="{name: 'TrainNotification'}" class="sitemap-group-link">연수알리미</router-link></h3>-->
        <!--          </article>-->
        <!--        </div>-->
      </div>
      <!-- //footer-sitemap -->
    </div>
  </div>
</template>

<script>

import {computed} from 'vue';
import {useStore} from 'vuex';
import {
  customPageMove,
  devAsisMoveLinkMenus,
  isTempMenu,
  prdAsisMoveLinkMenus,
  webHiddenMenu,
} from '@/assets/js/modules/common/common';
import {useRouter} from 'vue-router';
// import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'LxpFooterMenu',
  setup() {
    const store = useStore();
    const menus = computed(() => store.state.menu.menus);
    const router = useRouter();
    // const {showMessage} = useAlert();

    const goMenu = (menu) => {
      // 2022-11-22 : SSL : 11, 그룹러닝: 12, 지식e러닝 : 13 (2023년 3월 오픈) [김보희 대리 요청]
      // if(menu.menuSn === 11 || menu.menuSn === 12 || menu.menuSn === 13) {
      // if (menu.menuUrl === '/ssl/home' && process.env.NODE_ENV === 'production') { // || menu.menuUrl === '/prep/group'
      //   showMessage('2025년 SSL 준비중입니다.');
      // }else
      if(process.env.NODE_ENV === 'production' ? prdAsisMoveLinkMenus.includes(menu.menuUrl) : devAsisMoveLinkMenus.includes(menu.menuUrl)) {
        customPageMove(menu.menuUrl);
      } else {
        router.push({path: menu.menuUrl});
      }
    }

    return {
      menus,
      webHiddenMenu,
      isTempMenu,
      goMenu
    }
  }
}
</script>