<template>
  <article class="content-section section-my" style="padding: 10px 0 0 15px;">
    <div class="my-info">
      <div class="info-content">
        <h4 class="name" style="font: normal 16px/22px var(--kb-font-KBFGDisplayB);">{{ session.lrnerNm }}님, 반가워요!</h4>
      </div>
    </div>
    <div class="my-links" style="display: inline-flex; overflow-x: auto; width: 100%; ">
      <router-link :to="{ name:'HistoryHrdCloud' }" class="link-v2 kb-btn kb-btn-outline kb-btn-content-item">
        <span class="text">My 클라우드</span><i class="icon-arrow"></i>
      </router-link>
      <a href="javascript:" class="link-v2 kb-btn kb-btn-outline kb-btn-content-item" @click="clickattendanceModal">
        <span class="text">출석일수</span><i class="icon-arrow"></i>
      </a>
      <a href="javascript:" class="link-v2 kb-btn kb-btn-outline kb-btn-content-item" @click="showSubscribed">
        <span class="text">구독채널</span><i class="icon-arrow"></i>
      </a>
      <a href="javascript:" class="link-v2 kb-btn kb-btn-outline kb-btn-content-item" @click="showAdded">
        <span class="text">담은 콘텐츠</span><i class="icon-arrow"></i>
      </a>
    </div>
  </article>
</template>

<script>
import {closeSubscribed, showAdded, showSubscribed} from '@/assets/js/modules/hrd/hrd-common';

export default {
  name: 'HrdCloudMobileMyV2',
  components: {},
  props: {
    session: Object,
    toggleModal: Object,
    attendanceCnt: Number,
    modelValue: Boolean
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const clickattendanceModal = () => {
      emit('update:modelValue', true);
    };
    return {
      showAdded,
      showSubscribed,
      closeSubscribed,
      clickattendanceModal
    }
  }
};
</script>