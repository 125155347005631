<template>
  <div class="header-column column-dropdown">
    <div class="dropdown is-active">
      <div class="dropdown-target" style="transform: translateY(290px);left: -290px;right:50px">
        <div class="dropdown-box">
          <div class="depthmenu">
            <ul class="dropdown-option-list">
<!--              <li v-for="(item, idx) in items" class="kb-nav-item" :key="idx">-->
<!--                <router-link :to="item.to" class="dropdown-option-link" style="padding: 0; !important;">-->
<!--                  <div class="dropdown-option-link kb-mouse-cursor">-->
<!--                    <span class="dropdown-option-text">{{ item.name }}</span>-->
<!--                  </div>-->
<!--                </router-link>-->
<!--              </li>-->
<!--              <router-link :to="{name:'SSLWrite', params:{distCrseCd:'none', lrnTrgtGrpMstSn:0, socLrnPostSn:0}}" class="dropdown-option-link" style="padding: 0; !important;">-->
                <div class="dropdown-option-link kb-mouse-cursor" @click="goWrite">
                  <span class="dropdown-option-text">학습노트 작성</span>
                </div>
<!--              </router-link>-->
              <li>
                <div class="dropdown-option-link kb-mouse-cursor" @click="openSSLStudioModal">
                  <span class="dropdown-option-text">쓸스튜디오(영상/PDF) 등재</span>
                </div>
              </li>
              <li>
                <div class="dropdown-option-link kb-mouse-cursor" @click="goGroup">
                  <span class="dropdown-option-text">모임 등록</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SSLStudioModal v-if="sslStudioModal" v-model="sslStudioModal" />
</template>


<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import SSLStudioModal from "@/components/ssl/studio/SSLStudioModal";
import {ACT_GET_SOC_MY_REL, MUT_SET_MY_REL, MUT_SET_ROUTE_FROM} from '@/store/modules/ssl/ssl-index';
import {getItems} from '@/assets/js/util';
import {useRoute, useRouter} from "vue-router";

export default {
  name: 'SSLWrtTyNav',
  components: {SSLStudioModal},
  props: {
    modelValue : Boolean
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {

    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const myGroup = computed(() => {return store.state.ssl.group[0]});


    // const items = reactive([
    //   {to:{name:'SSLWrite', params:{distCrseCd:'none', lrnTrgtGrpMstSn:0, socLrnPostSn:0}}, name:'학습노트 작성', key:'note'},
    //   {to:{name:'GroupView', params:{distCrseSn:myGroup.value.distCrseSn}}, name:'모임 등록', key:'meet'}
    // ]);


    const goGroup = () => {
      if(!myGroup.value) {
        store.dispatch(`ssl/${ACT_GET_SOC_MY_REL}`, 'group').then(res => {
          store.commit(`ssl/${MUT_SET_MY_REL}`, {division: 'group', items: getItems(res)});
        });
      }

      if(myGroup.value != null && myGroup.value.distCrseSn > 0) {
        router.push({name: 'GroupView', params: {distCrseSn: myGroup.value.distCrseSn}});
      } else {
        router.push({name: 'GroupLearning'});
      }
    };

    const goWrite=()=>{
      store.commit(`ssl/${MUT_SET_ROUTE_FROM}`, route);
      router.push({name:'SSLWrite', params:{distCrseCd:'none', lrnTrgtGrpMstSn:0, socLrnPostSn:0}});
    }

    const sslStudioModal = ref(false);

    const openSSLStudioModal = () => {
      store.commit(`ssl/${MUT_SET_ROUTE_FROM}`, route);
      emit('update:modelValue', true);
      sslStudioModal.value = true;
    };

    return {
      // items,
      openSSLStudioModal,
      sslStudioModal,
      goGroup,
      goWrite
    }
  }
}
</script>