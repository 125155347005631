<template>
  <!-- learningGroup-list -->
  <ul class="learningGroup-list">
    <!-- learningGroup-item -->
    <li v-for="(item, idx) in items" :key="idx" class="learningGroup-item">
      <article v-if="item.distCrseSn == distCrseSn || isAll" class="learningGroup-card">
        <!-- learningGroup-symbol -->
        <div class="learningGroup-symbol">
          <div class="image">
            <SSLProfileImg :target="items.map(x => ({lrnerId : x.leaderId}))[idx]" />
            <!-- <img :src="getProfileImg(item.leaderId)" @error="setDefaultImg" alt="임시이미지" /> -->
          </div>
        </div>
        <!-- learningGroup-content -->
        <div class="learningGroup-content">
          <!-- content-row -->
          <div class="content-row">
            <div class="content-column content-detail">
              <h3 class="content-title">
                <a href="javascript:" class="title-link"><span class="title">{{ item.crseNm }}</span></a>
              </h3>
              <p class="content-description">
                <span class="text category">{{ item.distCrseLrnFldNm }}</span>
                <span class="text">{{ item.boardSumup }}</span>
              </p>
              <div class="content-meta">
                <span class="text">{{ item.leaderDeptNm }}</span>
                <span class="text">{{ item.leaderNm }}</span>
                <span class="text">{{ item.leaderJbgdNm }}{{ item.leaderJbpsNm }}</span>
              </div>
            </div>
            <!-- content-column -->
            <div v-if="$route.name !== 'GroupView'" class="content-column column-member">
              <span class="text">참여 {{ item.lrnTrgtCnt }}</span>
              <span class="text">정원 {{ item.peoplCnt }}</span>
            </div>
            <!-- content-column -->
            <div class="content-column column-buttons">
              <GroupDetailButton :item="item" :click-group-detail="clickGroupDetail" :go-group-lrn="goGroupLrn" />
            </div>
          </div>
        </div>
      </article>
    </li>
    <!-- //learningGroup-item -->
  </ul>
  <!-- //learningGroup-list -->
  <div class="kb-alert-container" :class="{'is-active' : alertShow}">
    <div class="kb-alert kb-alert-prompt">
      <div class="kb-alert-contents">
        <strong class="kb-alert-title"></strong>
        <p class="kb-alert-text">{{!pwdFail ?  '비밀번호가 일치하지 않습니다.' : '해당 그룹의 비밀번호를 입력하세요.' }}</p>
        <div class="kb-alert-form">
          <template v-if="!pwdFail">
            <input v-model="pwd" type="password" class="kb-form-control" maxlength="4" required pattern="'[0-9]+'">
          </template>
          <template v-else>
            <input v-model="pwd" type="password" class="kb-form-control" maxlength="4">
          </template>
        </div>
      </div>
      <div class="kb-alert-actions">
        <button @click="okConfirm" class="kb-btn-alert"><span class="text">확인</span></button>
        <button @click="closeConfirm" class="kb-btn-alert"><span class="text">취소</span></button>
      </div>
    </div>
  </div>
</template>

<script>
import SSLProfileImg from "@/components/ssl/common/SSLProfileImg";
import {groupCardSetup} from "@/assets/js/modules/groupLearing/group-card-setup";
import GroupDetailButton from "@/components/prep/group/GroupDetailButton";

export default {
  name: "GroupCard",
  components: {GroupDetailButton, SSLProfileImg},
  props: {
    items: Array,
    isViewPage: Boolean,
    distCrseSn: String,
    isAll: Boolean,
  },
  setup: groupCardSetup
}
</script>