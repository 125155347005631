<template>
  <div class="kb-form-layer" :class="{'is-active': toggles.main}" @click.stop="closeNav">
    <div class="layer-container">
      <div class="layer">
        <div class="kb-form-layer-options">
          <div class="layer-option">
            <ul class="layer-option-list max-h-500">
<!--              <li v-for="(mainRoute, idx) in mainRoutes" class="layer-option-item" :key="idx">-->
<!--                <a @click="getMainPath(mainRoute)" class="layer-option-link"><strong class="layer-option-text">{{ mainRoute.name }}</strong></a>-->
<!--&lt;!&ndash;                <router-link :to="getMainPath(mainRoute)" class="layer-option-link" @click="toggles.main = false"><strong class="layer-option-text">{{ mainRoute.name }}</strong></router-link>&ndash;&gt;-->
<!--              </li>-->
              <li class="layer-option-item">
                <a @click="getMainPath(0)" class="layer-option-link"><strong class="layer-option-text">학습노트 작성</strong></a>
              </li>
              <li class="layer-option-item">
                <a @click="goToRoute('sslStudio')" class="layer-option-link"><strong class="layer-option-text">쓸스튜디오(영상/PDF) 등재</strong></a>
              </li>
              <li class="layer-option-item">
                <a @click="goGroup()" class="layer-option-link"><strong class="layer-option-text">모임등록</strong></a>
              </li>
              <li class="layer-option-item">
                <a @click="goToRoute('MobileOpinion')" class="layer-option-link"><strong class="layer-option-text">KB스타런 현장의견</strong></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <SSLEnroll
      v-if="enroll.toggle"
      v-model:toggle="enroll.toggle"
      :dist-crse-cd="enroll.key"
  />
</template>


<script>
import {computed, reactive} from 'vue';
import {getRouteLrnTrgtGrp, sslNavRoutes, sslRoutes} from '@/assets/js/modules/ssl/ssl-common'; //, isMyLrnTrgtGrpMstSn
import {useStore} from 'vuex';
import {useRoute, useRouter} from "vue-router";
import SSLEnroll from "@/components/ssl/common/SSLEnroll.vue";
import {ACT_GET_SOC_MY_REL, MUT_SET_MY_REL, MUT_SET_ROUTE_FROM} from "@/store/modules/ssl/ssl-index";
import {useAlert} from "@/assets/js/modules/common/alert";
import {isSSLPeriod} from "@/assets/js/modules/ssl/ssl-write";
import {getItems} from "@/assets/js/util";
// import {limitSSL} from "@/assets/js/modules/ssl/ssl-message";

export default {
  name: 'SSLMobileNavWrt',
  components: {SSLEnroll},
  props: {
    childDistCrseCd: {
      type: String,
      required: false
    },
    effectFunc: Function,
    showUIOpinion: Function,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const {showMessage} = useAlert();

    const distCrseCd = computed(() => {
      if (props.childDistCrseCd && props.childDistCrseCd.length > 0) return props.childDistCrseCd;
      return route.params.distCrseCd;
    });

    const lrnTrgtGrp = computed(() => getRouteLrnTrgtGrp(route.params.lrnTrgtGrpMstSn));
    const myLrnTrgtGrpMstSns = computed(() => store.state.ssl.myLearnTargets.map(x => x.lrnTrgtGrpMstSn));
    // const distCrseSns = computed(() => getDistCrseSns(route.params.distCrseCd));
    // const myRoutes = computed(() => {
    //   // 나의 차수 Get
    //   const myDistCrseSns = store.state.ssl.myLearnTargets.map(x => x.distCrseSn);
    //   return [...Object.keys(sslRoutes).map(key => ({...sslRoutes[key]})).filter(x => {return (x.distCrseSns.filter(y => myDistCrseSns.includes(y)).length > 0)})];
    // });

    // main route
    const mainRoutes = computed(() => {
      return Object.values(sslNavRoutes(myLrnTrgtGrpMstSns));
      // if (getTodayDate('yyyyMMdd') < '20240701'){
      //   return Object.values(sslRoutes).filter(x => x.key != 'book').filter(x=>(x.lrnTrgtGrpMstSns.filter(y=>myLrnTrgtGrpMstSns.value.includes(y)) != '' || x.key == 'trivia'));
      // }else{
      //   return Object.values(sslRoutes).filter(x=>(x.lrnTrgtGrpMstSns.filter(y=>myLrnTrgtGrpMstSns.value.includes(y)) != '' || x.key == 'trivia'));
      // }
    });

    const currentMainName = computed(() => {
      if(route.name === 'SSLHome' || route.name === 'SSLNotice' || route.name === 'SSLNoticeView'){
        // return {name: 'SSL 홈', key: 'home'};
        return 'SSL 홈'
      }
      else if(distCrseCd.value && sslRoutes[distCrseCd.value]){
        if (distCrseCd.value === 'self') {
          return `${sslRoutes[distCrseCd.value].name}${lrnTrgtGrp.value.lrnTrgtGrpNm ? `-${lrnTrgtGrp.value.lrnTrgtGrpNm}` : ''}`
        }
        return sslRoutes[distCrseCd.value].name;
      }
      return {};
    });

    const enroll = reactive({
      key: null,
      toggle: false,
    });

    const toggles = reactive({
      main: true,
      // sub: false
    });

    const getMainPath = targetRoute => {

      if (!isSSLPeriod()) {
        showMessage('2025년 SSL 준비중입니다.');
      } else if (targetRoute === 0) {
        store.commit(`ssl/${MUT_SET_ROUTE_FROM}`, route);
        router.push({
          name: 'SSLWrite',
          params: {distCrseCd: 'none', lrnTrgtGrpMstSn: 0, socLrnPostSn: 0}
        }).then(() => {
        });
      } else {
        // console.log('분야 : ' + targetRoute.name);
        let targetLrnTrgtGrpMstSn = targetRoute.lrnTrgtGrpMstSns[0];
        const targetLrnTrgtGrpMstSns = targetRoute.lrnTrgtGrpMstSns.filter(x => myLrnTrgtGrpMstSns.value.includes(x));
        if (targetLrnTrgtGrpMstSns.length > 0) targetLrnTrgtGrpMstSn = targetLrnTrgtGrpMstSns[0];

        // if (isMyLrnTrgtGrpMstSn(targetLrnTrgtGrpMstSn)){ //신청한 과정인경우 -- 2024년 신청된 메뉴만 가져오는거라 검사 불필요
        //   router.push({name: 'SSLWrite', params: {distCrseCd: targetRoute.key, lrnTrgtGrpMstSn: targetLrnTrgtGrpMstSn, socLrnPostSn: 0}}).then(() => {});
        // }else{// 미신청인 경우 신청권유
        //   enroll.key = targetRoute.key;
        //   enroll.toggle = true;
        // }

        // SSL 글쓰기 진입 페이지 정보 저장
        store.commit(`ssl/${MUT_SET_ROUTE_FROM}`, route);

        if (targetRoute.name === '그룹러닝' || targetRoute.name === '북러닝') {
          router.push({name: 'SSLMy', params: {distCrseCd: targetRoute.key, lrnTrgtGrpMstSn: targetLrnTrgtGrpMstSn}});
        } else {
          router.push({
            name: 'SSLWrite',
            params: {distCrseCd: targetRoute.key, lrnTrgtGrpMstSn: targetLrnTrgtGrpMstSn, socLrnPostSn: 0}
          }).then(() => {
          });
        }
      }
    };

    const goToRoute = (routeName) => {

      if (!isSSLPeriod() && routeName !== 'MobileOpinion') {
        showMessage('2025년 SSL 준비중입니다.');
        // showMessage( limitSSL);
      } else {
        store.commit(`ssl/${MUT_SET_ROUTE_FROM}`, route);
        router.push({name: routeName});
      }
    };

    const myGroup = computed(() => {return store.state.ssl.group[0]});
    const goGroup = () => {
      if(!myGroup.value) {
        store.dispatch(`ssl/${ACT_GET_SOC_MY_REL}`, 'group').then(res => {
          store.commit(`ssl/${MUT_SET_MY_REL}`, {division: 'group', items: getItems(res)});
        });
      }

      if(myGroup.value != null && myGroup.value.distCrseSn > 0) {
        router.push({name: 'GroupView', params: {distCrseSn: myGroup.value.distCrseSn}});
      } else {
        router.push({name: 'GroupLearning'});
      }
    };


    const toggleVisible = target => {
      Object.keys(toggles).forEach(key => {
        if (key === target) {
          toggles[key] = !toggles[key];
        } else {
          toggles[key] = false;
        }
      });
    }

    const closeNav = () => {
      toggles.main = false;
      if(props.effectFunc instanceof Function){
        props.effectFunc();
      }
    }

    return {
      distCrseCd,
      mainRoutes,
      currentMainName,
      toggles,
      getMainPath,
      toggleVisible,
      closeNav,
      enroll,
      goToRoute,
      goGroup
    }
  }
}
</script>
