<template>
  <div class="search-container">
    <div class="content-section">
      <HrdCloudList :is-loading="isLoading"
                    :session="session"
                    :result="hrds"
                    :show-put="true"
                    :paging="paging"
                    :paging-func="pagingFunc">
<!--        <template v-slot:top>-->
<!--          <div class="list-top">-->
<!--            <div class="top-column">-->
<!--              <h3 class="title text-muted">콘텐츠 검색결과 {{ numberComma(paging.totalCount) }}건</h3>-->
<!--            </div>-->
<!--          </div>-->
<!--        </template>-->
      </HrdCloudList>
    </div>
<!--    <div class="content-section section-margin">-->
<!--      <h3 class="title section-title">카테고리 검색결과 {{ categoriesPaging && categoriesPaging.totalCount ? categoriesPaging.totalCount : 0 }}건</h3>-->
<!--      <ul class="category-list" v-if="categories && categories.length > 0">-->
<!--        <li class="category-item" v-for="(cate, idx) in categories" :key="idx">-->
<!--          <a href="javascript:" class="category-link" @click="searchCate(idx)">{{ replaceSymbol(cate.hrdCloudCateNmLst) }}</a>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->
  </div>
</template>

<script>
import {useRoute} from 'vue-router';
import {computed, ref} from 'vue';
import {pushQuery} from '@/assets/js/ui.init';
import {numberComma} from '@/assets/js/util';
import HrdCloudList from '@/components/hrdcloud/HrdCloudList';

export default {
  name: 'HrdCloudMobileSearchResult',
  components: {HrdCloudList},
  props: {
    keyword: [Number, String],
    session: Object,
    hrds: Array,
    categories: Array,
    paging: Object,
    pagingFunc: Function,
    categoriesPaging: Object
  },
  setup(props) {
    console.log("V2!!");
    const route = useRoute();
    const isLoading = ref(false);
    const keyword = computed(() => route.query.keyword);
    const localKeyword = ref(keyword.value);

    const handleSearch = () => {
      pushQuery({keyword: localKeyword.value});
    };

    const replaceSymbol = (str) => {
      return (str && str.length && str.indexOf(',') > -1 ? str.replaceAll(',', '>') : '');
    };

    const searchCate = (idx) => {
      let query = {};
      let cates = props.categories[idx].hrdCloudCateSnLst.split(',');
      for (let idx in cates) {
        query[`category${Number(idx)+1}`] = cates[idx];
      }
      pushQuery(query);
    };

    return {
      isLoading, localKeyword,
      handleSearch, numberComma, replaceSymbol, searchCate
    }
  }
};
</script>