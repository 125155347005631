<template>
  <main class="ssl-body" id="ssl-myssl">
    <header class="ssl-header back">
      <div class="page-component">
        <!-- header-back -->
        <div class="header-column header-back">
          <a href="javascript:" class="back-link" @click="goBack">
            <span class="back"></span>
          </a>
        </div>
        <!-- //header-back -->
        <!-- header-myssl -->
        <div class="header-column header-myssl">
          <div class="header-myssl-inner">
            <div class="avatar">
              <SSLProfileImg :target="target"/>
            </div>
            <h2 class="title">
              <img src="@/assets/lxp/mobile/images/ssl/myssl/ic_myssl.svg" alt="">
            </h2>
          </div>
        </div>

        <div class="header-column header-util">
          <SSLMobileLearnerSubscribe v-if="isNotMine" :lrner="target" :is-ready="isReady"/>
        </div>
      </div>
    </header>

    <div ref="mysslTop" class="page-container">
      <div v-if="isReady" class="myssl-container">
        <div class="myssl-top my-profile">
          <div class="top-my">
            <div class="my-avatar">
              <div class="avatar">
                <SSLProfileImg :target="target"/>
              </div>
            </div>
            <!--            <div class="my-title">-->
            <!--              <h2 class="title">-->
            <!--                <img src="@/assets/lxp/mobile/images/ssl/myssl/ic_myssl.svg" alt="">-->
            <!--              </h2>-->
            <!--            </div>-->
<!--            <div class="my-category " :class="getCategoryClass(distCrseCd)">-->
<!--              <span class="category">{{ mainName }}</span>-->
<!--            </div>-->
            <div class="my-name">
              <h3 class="nickname mb-2">{{target.chnNm}}</h3>
              <span class="name" style="margin-right: 5px">{{ target.lrnerNm }}</span>
              <span class="name text-muted">{{ target.deptNm }}</span>
            </div>
          </div>
        </div>

        <div class="myssl-top my-tabs">
          <SSLLearnerRoute/>
        </div>



<!--        <SSLMobileMyWrite v-if="isRecord && distCrseCd === 'book'" :lrner="target"/>-->
<!--        <SSLMobileMyActivity v-if="isRecord && distCrseCd === 'book'" :lrner="target"/>-->
<!--        <SSLMobileLearnerBadge v-if="isRecord"/>-->
        <SSLMobileLearnerHome v-if="isHome"/>
        <SSLMobileLearnerBoard v-if="isBoard"/>

      </div>
    </div>
  </main>

</template>

<script>
import SSLMobileLearnerHome from '@/components/ssl/learner/mobile/SSLMobileLearnerHome';
import SSLLearnerRoute from '@/components/ssl/learner/SSLLearnerRoute';
import SSLMobileLearnerBoard from '@/components/ssl/learner/mobile/SSLMobileLearnerBoard';
import SSLMobileLearnerSubscribe from '@/components/ssl/learner/mobile/SSLMobileLearnerSubscribe';
import SSLProfileImg from '@/components/ssl/common/SSLProfileImg';
import {sslLearnerCommSetup} from "@/assets/js/modules/ssl/setup/ssl-learn-comm-setup";

export default {
  name: 'SSLMobileLearnerComm',
  components: {
    SSLProfileImg,
    SSLMobileLearnerSubscribe,
    SSLMobileLearnerBoard,
    SSLLearnerRoute,
    SSLMobileLearnerHome
  },
  setup: sslLearnerCommSetup
}
</script>
