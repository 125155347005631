<template>
  <div class="player-viewport" :class="{'viewscript' : viewScriptFlag}">
    <div
        ref="videoContainer"
        class="player-video kb-video-wrapper"
        :class="{'kb-size-100': !isFullscreen, 'kb-size-100-padding-0': !isMobile}"
    >
      <div class="vsc-controller vsc-nosource"></div>
      <video class="vjs-matrix video-js vjs-default-skin vjs-big-play-centered vjs-fullscreen vjs-fade-out"
             ref="videoDom"
             oncontextmenu="return false;"
             playsinline
             :loop="noSleep"
      >
        <track v-if="crse.substFileNm!==null && crse.substFileNm.length > 0" kind="captions" :src="vttPath" srclang="ko" label="한국어" />
      </video>
      <button v-show="isMobile || isPortableiOS" ref="fullscreenBtn" class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen" aria-disabled="false" @click="toggleFullScreen">
        <span class="vjs-icon-placeholder" aria-hidden="true"></span>
        <span class="vjs-control-text" aria-live="polite">Fullscreen</span>
      </button>
<!--      </template>-->
      <HrdCloudSeries v-if="crse.cttSnLst != null && crse.cttSnLst.length > 0" :is-active="isSeriesActive" :crse="crse" @toggle="toggleSeries" />
      <HrdCloudRoadmapPlayerButton :crse="crse" :mobile-view-func="showRoadmapFunc" />
      <div ref="coldcallDom">
        <template v-if="isMobile">
          <HrdCloudMobileColdCall
              v-if="videoInstance && uuid"
              :uuid="uuid"
              :player-cont="videoContainer"
              :player="videoInstance"
              :objectParam="objectParam"
              :progress="progress"
              :meta="metadata"
              :lastPlaySec="resumePlaySec"
              :popups="popups"
          />
        </template>
        <template v-else>
          <HrdCloudColdCall
              v-if="videoInstance && uuid && !activeContinue"
              :uuid="uuid"
              :player-cont="isAppleTablet ? videoContainer : videoDom"
              :player="videoInstance"
              :objectParam="objectParam"
              :progress="progress"
              :meta="metadata"
              :lastPlaySec="resumePlaySec"
              :popups="popups"
          />
        </template>
        <LxpMessage v-model="localMessage.show" :options="localMessage" />
      </div>
    </div>
  </div>
  <div class="subtitle-container" :class="{'viewscript' : viewScriptFlag}">
    <ul ref="subtitleListEl">
      <li v-for="(subtitle, index) in subtitles"
          :key="index"
          class="text"
          :class="{ highlight:currSubtitleIndex === index}"
          @click="seekToSubtitle(subtitle.start)"
          @mouseover="hoverIndex = index"
          @mouseleave="hoverIndex = null"
      >
        <span>{{formatTime(subtitle.start)}} - {{subtitle.text}}</span>
        <button v-if="hoverIndex===index" class="edit-btn" @click.stop="funcs.openCaptionModi(index, subtitle, videoInstance)"><i class="icon-pencil"></i></button>
      </li>
    </ul>
  </div>
</template>
<style scoped>
.lxp-mobile-layout .player .player-video video {width:100%;height:100%;object-fit:contain;}
.player-viewport.viewscript {width:70%}
.subtitle-container {
  display: none;
}
.subtitle-container.viewscript {
  display: initial;
  position: relative;
  width: 28%;
  margin-left: 15px;
  overflow:hidden;
  height:675px;
  align-items:center;
  justify-content: center;
  overflow-y: auto;
}

.subtitle-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.subtitle-container li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
}

#kb[data-bg="dark"] .subtitle-container li {
  color: #fff;
}


.subtitle-container .highlight {
  background-color: #fdbb01;
  font-weight: bold;
}

#kb[data-bg="dark"] .subtitle-container .highlight {
  background-color: #fdbb01;
  color:#000000;
  font-weight: bold;
}

.subtitle-container li:hover {
  background-color: #fac91e;
  font-weight: bold;
}

.subtitle-container .edit-btn {
  position: absolute;
  right: 10px;
  top:50%;
  transform:translateY(-50%);
  background: #3c3737;
  color:#fff;
  border:none;
  padding:5px 10px;
  cursor: pointer;
  border-radius: 50%;
  display: none;
}

.subtitle-container li:hover .edit-btn{
  display: inline-block;
}

.icon-pencil {width:24px;height:24px;background-image:url(../../assets/lxp/images/ssl/myssl/ic_pencil.svg)}

/*@media (orientation: landscape) {*/
/*  !* Landscape 모드*!*/
/*  .lxp-mobile-layout .kb-size-100 .video-js {*/
/*    position: relative!important;*/
/*    width: 100%!important;*/
/*    height: 100vh !important;*/
/*  }*/
/*}*/
</style>
<script>
import videojs from 'video.js';
import {useStore} from 'vuex';
import {computed, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch} from 'vue';
import {getType, getVideoUrl, getVideoVttCttMngNo, getVideoVttUrl} from '@/assets/js/util';
import {
  callNative,
  doPause,
  doPlay,
  eventCdDcds,
  insertVodLearnProgress
} from '@/assets/js/modules/hrd/hrd-common';
import HrdCloudColdCall from '@/components/hrdcloud/HrdCloudColdCall';
import HrdCloudSeries from '@/components/hrdcloud/HrdCloudSeries';
import HrdCloudRoadmapPlayerButton from '@/components/hrdcloud/HrdCloudRoadmapPlayerButton';
import HrdCloudMobileColdCall from '@/components/hrdcloud/mobile/HrdCloudMobileColdCall.vue';
import {TOKEN_KEY} from '@/store/modules/auth/auth';
import localstorageService from '@/assets/js/localstorage.service';
import navigationUtils, {isInApp} from '@/assets/js/navigationUtils';
import {getThumbImg} from '@/assets/js/modules/baner/common-baner';
import LxpMessage from '@/components/common/LxpMessage.vue';
import {hideFooter, hideHeader, showFooter, showHeader} from '@/assets/js/modules/common/common';

export default {
  name: 'HrdCloudPlayer',
  components: {LxpMessage, HrdCloudMobileColdCall, HrdCloudSeries, HrdCloudRoadmapPlayerButton, HrdCloudColdCall},
  props: {
    modelValue: Object, // player instance
    isSeriesActive: {type: Boolean, default: false},
    container: Object,
    coldcall: Boolean, // 콜드콜 응답여부
    options: Object, // videojs options
    enableProgress: Boolean, // progress activate
    crse: Object, // learn object (play-info)
    progress: Object,
    uuid: String,
    popups: Object,
    wrapperDom1: Object,
    wrapperDom2: Object,
    wrapperDom3: Object,
    showRoadmapFunc: Function,
    viewScriptFlag: Boolean,
    funcs: {
      type: Object,
      default: () => {
        return {
          toggleScript:()=>{},
          openCaptionModi: () =>{},
        };
      }
    }
  },
  emits:['update:modelValue', 'toggleFull', 'update:isSeriesActive'],
  setup(props,{emit}) {
    const isDev = (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local');
    const store = useStore(),
          isMobile = computed(() => store.state.auth.isMobile),
          session = computed(() => store.state.auth.session),
          isPortableiOS = navigationUtils.isApplePortable(),
          fullscreenBtn = ref(null),
          isAppleTablet = navigationUtils.isAppleTablet();

    const videoDom = ref(null),
        coldcallDom = ref(null),
        videoInstance = ref(null),
        activeContinue = ref(true), // 이어보기 최초 한번 노출하기 위한 플래그
        resumePlaySec = ref(0),
        completeActive = ref(true), // false 이후 노출 안되도록 막음
        videoContainer = ref(null),
        completePause = ref(false),
        isFullscreen = ref(false),
        {uuid} = toRefs(props),
        {popups} = toRefs(props);

    // 이어보기 알럿 응답여부
    const resumeResponded = ref(false);
    const noSleep = ref(false);

    const subtitles = ref(null);
    const currSubtitleIndex = ref(null);
    const subtitleListEl = ref(null);
    const hoverIndex = ref(null);

    const enableNoSleep = () => {
      if (isMobile.value && isPortableiOS) noSleep.value = true;
    };

    const disableNoSleep = () => {
      if(noSleep.value) noSleep.value = false;
    };

    const objectParam = computed(() => {
      return {
        crseMstSn: props.crse.crseMstSn,
        distCrseSn: props.crse.distCrseSn,
        distCrseNm: props.crse.distCrseNm,
        distChapSn: props.crse.distChapSn,
        distChapNm: props.crse.distChapNm,
        lrnObjDtlDistSn: props.crse.lrnObjDtlDistSn,
        objSn: props.crse.objSn,
        objNm: props.crse.objNm,
        // division: 'vod'
        cttDifi: props.crse.cttDifi
      }
    });

    const metadata = reactive({
      duration: 0,
      lastPlaySec: 0
    });

    const log = reactive({
      max: 0,
      completeYn: 'N',
      logSec: 0,
    });

    const localMessage = reactive({
      show: false,
      center: true,
      title: '',
      text: '학습이 종료되었습니다.',
      callback: null
    });

    const showMessage = () => {
      localMessage.show = true;
    };

    //vttPath를 S3 cloudfront에서 dynamoDB-lambda로
    // const vttPath = getVideoVttUrl(session.value.itn, props.crse);
    const cttMstNo = getVideoVttCttMngNo(props.crse);
    const vttPath = ref(`${location.protocol}//${location.hostname}:${location.port}/lmd/subtitle/vtt_files/${cttMstNo}`);
    console.log("chk", vttPath.value);


    //자막버튼 활성화여부
    const subtitleBtn = ()=>{
      if(getVideoVttUrl(session.value.itn, props.crse)){
        return true;
      }
      return false;
    }


    // 실제 재생 초
    const getPlaySec = (ct) => {
      return (ct - log.logSec < 0 ? 0 : Math.round((ct - log.logSec )/ videoInstance.value.playbackRate()));
    };

    const continuePlay = () => {
      if (props.progress.lastPlaySec > 0 && props.progress.lastPlaySec <= (metadata.duration - 3)) {
        const latestDt = (props.progress.mdfcnDt ? props.progress.mdfcnDt : props.progress.regDt) || 0;
        // 이어보기 활성화, 이어보기 시간이 0초 아닐때, 최종학습일이 7일이내일때
        if (activeContinue.value && (props.progress.lastPlaySec > 0) && (new Date().getTime() - latestDt < (7 * 1000 * 60 * 60 * 24))) {
          videoInstance.value.pause();
          popups.value.resume.toggle(true);
        } else {
          resumeResponded.value = true;
        }
      } else {
        resumeResponded.value = true;
      }
      activeContinue.value = false
    };

    const exitFullscreen = () => {
      if (isFullscreen.value) {
        if (isMobile.value || isPortableiOS) videoInstance.value.exitFullWindow();
        else videoInstance.value.exitFullscreen();
      }
    }

    const showComplete = async (res) => {
      await popups.value.getUUID();
       popups.value.complete.feedback = false;
      // if (res.complete && completeActive.value) {
      // 서버의 feedbackEnable 값에 따라 난이도 평가 popup 노출 판단
      if (res.feedbackEnable){
        popups.value.complete.feedback = true;
      }else if (res.complete) {
        completePause.value = true;
        videoInstance.value.pause();
        popups.value.complete.toggle(true);
        // completeActive.value = false;
      } else {
        showMessage();
      }

      // console.log('showComplete isFullscreen : ',isFullscreen.value);
      //창 자동 닫기 , feedback인 경우 닫지 않는다
      if(!res.feedbackEnable){
        setTimeout(() => {
          // 완료창 떠있으면 닫기
          if (popups.value.complete.show) popups.value.complete.toggle(false);
          // 종료창 떠있으면 닫기
          if (localMessage.show) localMessage.show = false;
          // 전체화면이면 닫기
          exitFullscreen();
        },15000);
      }

    };

    // 완료창 닫기 눌렀을때
    watch(() => popups.value.complete.show, () => {
      // console.log('[WATCH] complete show : ',popups.value.complete.show);
      // COMPLETE ALERT CLOSED
      if (!popups.value.complete.show) {
        exitFullscreen();
      }
    });

    const toggleFullScreen = () => {
      // console.log('[toggleFullScreen] isFullscreen : ',isFullscreen.value);
      if (videoInstance.value !== null) {
        if (!isFullscreen.value) {
          videoInstance.value.enterFullWindow();
        } else {
          videoInstance.value.exitFullWindow();
        }
      }
      activeContinue.value = false;
    };

    const renderImg = () => {
      const getImg = getThumbImg(props.crse.thumb);
      return (getImg ? getImg : require('../../assets/lxp/images/@tmp/@tmp_myclass_player_thumbnail_pdf.png'));
    };

    const callNativeFunc = () => {
      const token = computed(() => localstorageService.getToken(TOKEN_KEY));
      callNative(props.crse, props.progress, uuid, token, session);
    };

    const toggleSeries = (enable) => {
      if (props.crse.cttSnLst != null && props.crse.cttSnLst.length > 0) {
        if (enable) {
          emit('update:isSeriesActive', true);
        } else {
          emit('update:isSeriesActive', !props.isSeriesActive);
        }
      }
    };

    const logDebounce = ref(0);

    watch(()=> props.viewScriptFlag, ()=> {
      if(props.viewScriptFlag){
        loadSubtitles()
      }
    })

    watch(() => popups.value.resume.response.effect, () => {
      if (props.progress.lastPlaySec > 0) {
        if (popups.value.resume.response.type) {
          if (popups.value.resume.response.type === 'continue') {
            resumePlaySec.value = props.progress.lastPlaySec;
            log.logSec = props.progress.lastPlaySec;
            videoInstance.value.currentTime(props.progress.lastPlaySec);
          }
          popups.value.resume.toggle(false);
          popups.value.resume.initRespond();
          // console.log('[중단 알럿] videoInstance.value.paused() : ',videoInstance.value.paused())
          doPlay(videoInstance.value);
        }
      }
      resumeResponded.value = true;
      activeContinue.value = false;
    });

    watch(() => isFullscreen.value, () => {
      if (isMobile.value || isPortableiOS) {
        if (isFullscreen.value) {
          hideFooter();
          setTimeout(function() {
            window.scrollTo(0, 1);
          }, 500);
        }
        else showFooter();
      }
    });

    // const convertTimeToSeconds = (time) => {
    //   const [hours, minutes, seconds] = time.split(':');
    //   return (
    //       parseFloat(hours) * 3600 +
    //       parseFloat(minutes) * 60 +
    //       parseFloat(seconds.replace(',', '.'))
    //   );
    // };
    const formatTime = (seconds) => {
      // const h = Math.floor(seconds / 3600);
      const m = Math.floor(seconds / 60);
      const s = Math.floor(seconds % 60);
      return [m, s]
          .map(unit => String(unit).padStart(2, '0'))
          .join(':');

      // const [start] = timeline.timeline.split(' --> ');
      // let time = convertTimeToSeconds(start);
      //
      // return timeline ? timeline.split(" ")[0].split(".")[0] : ''
    }

    const formatVTTTime = (seconds) => {

      const h = Math.floor(seconds / 3600);
      const m = Math.floor(seconds / 60);
      const s = (seconds % 60).toFixed(3);
      return [h, m, s]
          .map(((unit,idx) => (idx<2 ? String(unit).padStart(2, '0') : String(unit).padStart(6,'0'))))
          .join(':');
    }

    const loadSubtitles = async () =>{
      await loadSubtitlesFromTrack();
    }

    const loadSubtitlesFromTrack = async () =>{
      const textTracks = videoInstance.value.textTracks();

      let subtitleTrack = null;

      for (let i=0; i< textTracks.length; i++){
        // console.log( "check tracks", textTracks[i]);
        if(textTracks[i].kind ==="subtitles" || textTracks[i].kind ==="captions"){
          subtitleTrack = textTracks[i];
          break;
        }
      }

      if(subtitleTrack){
        subtitles.value= [];
        for (let i=0; i <subtitleTrack.cues.length; i++){
          const cue = subtitleTrack.cues[i];
          let item = {
            start: cue.startTime,
            end: cue.endTime,
            text: cue.text,
            timeline: `${formatVTTTime(cue.startTime)} --> ${formatVTTTime(cue.endTime)}`,
            cttMstNo
          }
          subtitles.value.push(item)
        }
      }


      if(subtitles.value.length==0){
        if(props.viewScriptFlag){
          props.funcs.toggleScript("자막을 찾을 수 없습니다. 확인 후 재시도 하시기 바랍니다.");
          return;
        }
      }
    }

    const seekToSubtitle = (time) =>{
      videoInstance.value.currentTime(time);
      videoInstance.value.play();
    }
    const scrollToSubtitle =(index) => {
      const subtitleItem = subtitleListEl.value.children[index];

      if(subtitleItem){
        if(index>subtitles.value.length-8) return;
        subtitleItem.scrollIntoView({behavior:"smooth", block:"center"});
      }
    };

    const updateSubtitleHighlight = () =>{
      const currentTime = videoInstance.value.currentTime();
      const subtitleIndex = subtitles.value.findIndex(
          subtitle => currentTime >= subtitle.start && currentTime <= subtitle.end
      );
      if (subtitleIndex !== -1 && currSubtitleIndex.value != subtitleIndex) {
        currSubtitleIndex.value = subtitleIndex;
        scrollToSubtitle(subtitleIndex);
      }
    }


    onMounted(() => {
      const vjsOptions = (isMobile.value || isPortableiOS) ? {
        fluid: true,
        preload: 'metadata',
        autoplay: false,
        crossOrigin: 'anonymous',
        language: 'ko',
        width: window.innerWidth,
        height: 360,
        controls: true,
        textTrackSettings: false,
        html5:{
          nativeTextTracks: false
        },
        controlBar: {
          subsCapsButton: subtitleBtn(),
          audioTrackButton: false,
          fullscreenToggle: !(isMobile.value || isPortableiOS)
        },
        playbackRates: [1, 1.2, 1.5, 2.0],
        poster: getThumbImg(props.crse.thumb),
        sources: [{
          src: getVideoUrl(session.value.itn, props.crse),
          type: getType(getVideoUrl(session.value.itn, props.crse))
        }],
        languages:{
          ko:{
            'Play':'재생',
            'subtitles off': '자막끄기',
            'Captions' : '캡션',
            'captions off' : '캡션끄기'
          }
        }
      } : {
        fluid: true,
        preload: 'metadata',
        autoplay: false,
        crossOrigin: 'anonymous',
        language: 'ko',
        // width: 1200,
        // height: 675,
        controls: true,
        textTrackSettings: false,
        html5:{
          nativeTextTracks: false
        },
        controlBar: {
          subsCapsButton: subtitleBtn(),
          audioTrackButton: false,
          fullscreenToggle: !isPortableiOS
        },
        responsive: true,
        playbackRates: [1, 1.2, 1.5, 2.0],
        poster: getThumbImg(props.crse.thumb),
        sources: [{
          src: getVideoUrl(session.value.itn, props.crse),
          type: getType(getVideoUrl(session.value.itn, props.crse))
        }],
        languages:{
          ko:{
            'Play':'재생',
            'subtitles off': '자막끄기',
            'Captions' : '캡션',
            'captions off' : '캡션끄기'
          }
        }
      };

      videojs(videoDom.value, vjsOptions,function() {
        this.tech_.off('dblclick');

        videoInstance.value = this;

        emit('update:modelValue',videoInstance.value);

        // 학습종료 닫기 콜백 렌더
        localMessage.callback = () => {
          exitFullscreen();
        };

        let vjs = videoInstance.value;

        videoInstance.value.el()?.appendChild(coldcallDom.value);

        if (isMobile.value || isPortableiOS) {
          videoInstance.value.el()?.appendChild(fullscreenBtn.value);
          videoInstance.value.on('exitFullWindow', () => {
            isFullscreen.value = false;
            if (!isMobile.value) showHeader();
            emit('toggleFull', isFullscreen.value);
          });

          videoInstance.value.on('enterFullWindow', () => {
            isFullscreen.value = true;
            if (!isMobile.value) hideHeader();
            emit('toggleFull', isFullscreen.value);
          });

          const controlsEls = videoInstance.value.el().getElementsByClassName('vjs-control-bar');
          if (controlsEls.length > 0) {
            controlsEls[0].append(fullscreenBtn.value)
          }
        } else if (!isMobile.value) {
          try{
            videoInstance.value.el()?.appendChild(props.wrapperDom1);
            videoInstance.value.el()?.appendChild(props.wrapperDom2);
            if(props.wrapperDom3)videoInstance.value.el()?.appendChild(props.wrapperDom3);
          }catch (e) {
            console.warn('appendchild error', e.message);
          }

        }

        // vjs.on('click', function() {
        //   if (vjs.paused()) {
        //     console.log('vjs.getChild(\'controlBar\') : ',vjs.getChild('controlBar'));
        //   }
        // });

        vjs.on('loadedmetadata', function() {
          metadata.duration = Math.floor(this.duration());
          // loadSubtitles();
        });

        vjs.on('play', function() {
          enableNoSleep();

          if (activeContinue.value) {
            continuePlay(vjs);
          }

          let ct = Math.floor(this.currentTime());
          if (ct - log.logSec >= 0 && ((ct - log.logSec) < 2)) {
            if (resumeResponded.value) {
              logDebounce.value = setTimeout(() => {
                if (logDebounce.value) clearTimeout(logDebounce.value);

                insertVodLearnProgress(props.crse, {
                  progSesnKey: uuid.value,
                  lastPlaySec: ct,
                  playSec: getPlaySec(ct),
                  playSpeed: this.playbackRate(),
                  eventCdDcd: eventCdDcds.Play,
                  eventNm: '학습시작',
                }, () => {
                  log.logSec = ct;
                });
              },500);
            }
          }
        });

        vjs.on('pause', function() {
          disableNoSleep();

          const ct = Math.floor(this.currentTime());
          if (!completePause.value && ct < this.duration()) {
            if (ct - log.logSec >= 0 && ((ct - log.logSec) < 1)) {
              if (resumeResponded.value) {
                logDebounce.value = setTimeout(() => {
                  if (logDebounce.value) clearTimeout(logDebounce.value);

                  insertVodLearnProgress(props.crse, {
                    progSesnKey: uuid.value,
                    lastPlaySec: ct,
                    playSec: getPlaySec(ct),
                    playSpeed: this.playbackRate(),
                    eventCdDcd: eventCdDcds.Pause,
                    eventNm: '학습중지',
                  }, () => {
                    log.logSec = ct;
                  });
                },500);
              }
            }
          }

          if (completePause.value) completePause.value = false;
        });

        vjs.on('timeupdate', function() {
          let dur = Math.floor(this.duration());
          let ct = Math.floor(this.currentTime());
          if ((dur-ct) <= 10) {
            disableNoSleep();
          }
          if(props.viewScriptFlag) updateSubtitleHighlight();

          if (this.currentTime() > -1) {
            let ct = Math.floor(this.currentTime());
            // 최대 도달 시간
            if (log.completeYn === 'N' && (ct > log.max)) {
              if (ct - log.max < 2) {
                log.max = ct;
              }
            }
            // log.logSec 찍은 초
            if ((ct - log.logSec >= 30) && ((ct - log.logSec) <= 32)) {
              log.logSec = ct;
              // console.log('학습중 log.logSec : ',log.logSec);
              insertVodLearnProgress(props.crse, {
                progSesnKey: uuid.value,
                lastPlaySec: ct,
                playSec: Math.round(30 / this.playbackRate()),
                playSpeed: this.playbackRate(),
                eventCdDcd: eventCdDcds.Timeupdate,
                eventNm: '학습중',
              });
            }
          }
        });

        vjs.on('ended', function() {
          toggleSeries(true);

          let ct = Math.floor(this.currentTime());
          insertVodLearnProgress(props.crse, {
            progSesnKey: uuid.value,
            lastPlaySec: ct,
            playSec: getPlaySec(ct),
            playSpeed: this.playbackRate(),
            eventCdDcd: eventCdDcds.Ended,
            eventNm: '학습종료',
          },res => {
            log.logSec = 0;
            showComplete(res);
          });

          if (!vjs.paused()) doPause(vjs);
          else {
            vjs.currentTime(0);
            vjs.bigPlayButton.show();
          }
        });

        vjs.on('seeked', function() {
          log.logSec = Math.floor(this.currentTime());
          // if(seekTimer) {
          //   clearTimeout(seekTimer);Hist
          //   seekTimer = null;
          // }
          //
          // seekTimer = setTimeout(()=>{
          //   let ct = this.currentTime();
          //   log.logSec = ct;
          //   insertVodLearnProgress(props.crse, {
          //     progSesnKey: uuid.value,
          //     lastPlaySec: ct,
          //     playSec: getPlaySec(ct),
          //     playSpeed: this.playbackRate(),
          //     eventCdDcd: eventCdDcds.S,
          //     eventNm: '학습이동',
          //     coldcall: props.coldcall,
          //   });
          // }, 5000);
        });

        vjs.on('fullscreenchange', function() {
          // console.log('fullscreenchange');
          isFullscreen.value = vjs.isFullscreen() ? true : false;
        });
      });



    });

    // const orientation = computed(() => screen.orientation.type);

    // alert(1);

    // watch(() => orientation.value, () => {
    //   // console.log('orientation : '+orientation.value);
    //   alert('orientation : '+orientation.value);
    // });

    // window.addEventListener("orientationchange", function() {
    //   console.log('screen type : ',screen.orientation.type);

    //   // alert('orientation change');
    //   if (isFullscreen.value) {
    //     // alert('isFullscreen : '+isFullscreen.value);
    //     setTimeout(function() {
    //       alert('window.scrollTo');
    //       window.scrollTo(0, 1);
    //     }, 0);
    //     document.body.style.overflow = 'hidden';
    //   }
    // });

    onBeforeUnmount(() => {
      if (videoInstance.value) videoInstance.value.dispose();
      exitFullscreen();
      disableNoSleep();
      noSleep.value = null;
    });

    return {
      isMobile, isPortableiOS, isFullscreen, fullscreenBtn, noSleep,
      videoDom, coldcallDom, videoInstance, metadata, resumePlaySec, activeContinue,vttPath,
      objectParam, completeActive, videoContainer, isDev, isInApp, callNativeFunc, renderImg,
      toggleFullScreen, localMessage, isAppleTablet, toggleSeries, completePause,
      subtitles, currSubtitleIndex, formatTime, subtitleListEl, seekToSubtitle,
      hoverIndex,

    };
  }
};
</script>