<template>
  <main class="kb-main" id="kb-hrdcloud">
    <div class="main-content main-component">
      <div class="hrdcloud-view-container">
        <div class="player hrdcloud-player">
          <div class="player-container">
            <img :src="getThumbImg(objDetail.thumb)" alt="" class="img-fluid" style="width:-webkit-fill-available;" />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>

.lxp-mobile-layout #kb-hrdcloud { padding-bottom: 0px !important;}
</style>
<script>
import {useStore} from "vuex";
import {useRoute, useRouter} from 'vue-router';
import {computed, onBeforeUnmount, onMounted, ref, watch,} from 'vue';
import {
  darkMode, eventCdDcds, insertVodLearnProgress,
  lightMode,
  showMain,
} from '@/assets/js/modules/hrd/hrd-common';
import {isInApp} from '@/assets/js/navigationUtils';
import {getThumbImg} from '@/assets/js/modules/baner/common-baner';
import {goBack} from '@/assets/js/modules/common/common';
import {getToken} from "@/assets/js/localstorage.service";
import {TOKEN_KEY} from "@/store/modules/auth/auth";
import {executeApps, } from "@/assets/js/nativeAppUtils";
import {MUT_CLOSE_NATIVE_LAYER, MUT_SHOW_NATIVE_LAYER} from "@/store/modules/common/common-index";

export default {
  name: 'HrdMobileShortsNativeLauncher',
  props: {
    objDetail: Object,
    effectNav: {
      required: false,
      type: Number,
      default: 0
    },
    shortsItems:Array,
  },
  setup(props) {

    const route = useRoute(), router = useRouter();
    const store = useStore();
    const lrnObjDtlDistSn = computed(() => route.params.lrnObjDtlDistSn);
    const hrd = computed(() => props.objDetail);
    const refLrnObjDtlDistFlag = ref(false);

    const objectParam = computed(() => {
      return {
        distCrseSn: hrd.value.distCrseSn,
        distChapSn: hrd.value.distChapSn,
        lrnObjDtlDistSn: hrd.value.lrnObjDtlDistSn,
        objSn: hrd.value.objSn,
        // division: 'vod'
      }
    });


    watch(()=> store.state.common.nativeLayer.show, ()=>{
      let nativeLayer = store.state.common.nativeLayer;
      console.log("native layer !!", nativeLayer);
      if(isInApp && !store.state.common.nativeLayer.show && !refLrnObjDtlDistFlag.value){
        goBack();
      }
    })

    //연관콘텐츠 바로가기 action 삽입 후 router push
    watch(()=> store.state.hrdcloud.refLrnObjDtlJson?.objJsonStr, ()=>{
      let hrd = JSON.parse(store.state.hrdcloud.refLrnObjDtlJson?.objJsonStr);
      refLrnObjDtlDistFlag.value = true;
      //actionInsert
      console.log("ref click" , store.state.hrdcloud.refLrnObjDtlJson, hrd);

      if(hrd.refLrnObjDtlDistSn>0) {
        let ct = parseInt(store.state.hrdcloud.refLrnObjDtlJson?.currTime);
        insertVodLearnProgress(hrd, {
          progSesnKey: hrd.uuid,
          lastPlaySec: ct,
          playSec: ct,
          playSpeed: 1,
          eventCdDcd: eventCdDcds.callRefContent,
          eventNm: '연관콘텐츠 호출',
        }, () => {
          store.commit(`common/${MUT_CLOSE_NATIVE_LAYER}`, "shortFormPlayer");
          let path = `/prep/hrdcloud/${hrd.refLrnObjDtlDistSn}/detail`;
          router.push(path);
        });
      }
    })

    onMounted(async () => {
      darkMode();

      let apiPayload = {
        "crseMstSn":hrd.value.crseMstSn,
        "distCrseSn":hrd.value.distCrseSn,
        "distCrseNm":hrd.value.distCrseNm,
        "distChapSn":hrd.value.distChapSn,
        "distChapNm":hrd.value.distChapNm,
        "lrnObjDtlDistSn":"{{lrnObjDtlDistSn}}",
        "objSn":"{{objSn}}",
        "objNm":"{{objNm}}",
        "division":"vod",
        "cloudKeyLst":"{{cloudKeyLst}}",
        "progSesnKey":"{uuid}",
        "lastPlaySec":"{{lastPlaySec}}",
        "playSec":"{{playSec}}",
        "playSpeed":1,
        "eventCdDcd":"{{eventCdDcd}}",
        "eventNm":"{{eventNm}}",
        "actTyCdDcd": null,
        "picRate": "9.16"
      }

      let params = {
        cmd: "callLayer",
        direction: "up",
        speed: 300,
        cb_func:"commonListenerEnttApp",
        token: getToken(TOKEN_KEY),
        lrnObjDtlDistSn: hrd.value.lrnObjDtlDistSn,
        apiPayload
      };

      //callback onUnmountLayer , onSelRefObj

      console.log("param", params);
      executeApps("shortFormPlayer", params);
      console.log("setting store native start.. shortForm");
      await store.commit(`common/${MUT_SHOW_NATIVE_LAYER}`, "shortForm");
      console.log("setting store native commit.. shortForm");

    });

    onBeforeUnmount(() => {
      lightMode();
      showMain();
    });

    return {
      lrnObjDtlDistSn,
      objectParam,
      isInApp,
      getThumbImg,
  };
  }
};
</script>