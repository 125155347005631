import {onBeforeRouteLeave, useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
import {
    currentYear, getCrseSnToDistCrseCd,
    getDistCrseNm,
    getRouteLrnTrgtGrp,
    getTimeText,
    goBack, goHome,
    insertSocAct,
    isMyLrnTrgtGrpMstSn,
    isRightSSL,
    noneRewardCds,
    noteLimit, operPeriod, sslRoutes
} from '@/assets/js/modules/ssl/ssl-common';
import {
    editorConfig,
    editorCss,
    inAppEditorConfig,
    mobileEditorCss,
} from '@/assets/js/editor/editor-config';
import navigationUtils from '@/assets/js/navigationUtils';
import {
    ACT_DELETE_SOC_BOARD,
    ACT_GET_MY_SOC_BOARD_COUNT, ACT_GET_MY_SSL_LST,
    ACT_GET_SOC_BOOK_LIST,
    ACT_GET_SOC_PAGE_LIST,
    ACT_GET_SOC_REL_WRITE,
    ACT_INSERT_SOC_BOARD,
    ACT_UPDATE_SOC_BOARD,
    ACT_UPLOAD_SOC_BOARD_THUMBNAIL,
    // MUT_INIT_ROUTE_FROM,
} from '@/store/modules/ssl/ssl-index';
import {
    limitSSL,
    // writeDayLimit,
    writeDeleteConfirm,
    writeExit,
    writeExitPlane,
    writeInsertNote,
    writeTotalLimit,
} from '@/assets/js/modules/ssl/ssl-message';
import {
    clearTargetInterval,
    dateToDatePart, //dateToDateFormat,
    getItem,
    getItems,
    isSuccess,
    lengthCheck,
    setParams,
    timestampToDateFormat,
    validateObject,
} from '@/assets/js/util';
import {
    getRandomThumb,
    initTempSave, isSSLPeriod,
    limitSave,
} from '@/assets/js/modules/ssl/ssl-write';
import {actTyCdDcds} from '@/assets/js/modules/common/common';
import {MUT_SHOW_TOAST} from '@/store/modules/common/common-index';
import {getThumbImg} from '@/assets/js/modules/baner/common-baner';
import ClassicEditor from '@/assets/js/editor/custom-ckeditor';
import UploadAdapter from '@/assets/js/editor/upload-adapter';
import {ACT_GET_HRDCLOUD_DETAIL} from "@/store/modules/prep/prep";
import {ACT_GET_POST_DTL} from "@/store/modules/board/board-mst";
import {ACT_GET_SOCS_EDITOR_UPLOAD_OCR_MASKING} from "@/store/modules/board/board";

export const sslWriteSetup = () => {

    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const {showMessage, showConfirm, showLoading, hideLoading} = useAlert();

    const isMobile = computed(() => store.state.auth.isMobile);
    const session = computed(() => store.state.auth.session);
    const tempLimit = 2;
    const maxTextLen = 5000;
    const minTextLen = 100;

    const socLrnPostSn = ref(route.params.socLrnPostSn || 0);
    const lrnTrgtGrpMstSn = computed(() => route.params.lrnTrgtGrpMstSn);
    const distCrseCd = computed(() => route.params.distCrseCd);
    const distCrseNm = computed(() => getDistCrseNm(route.params.distCrseCd));

    const selOpt = reactive({
        distCrseCd:distCrseCd.value == 'none'?'':distCrseCd.value,
        lrnTrgtGrpMstSn:route.params.lrnTrgtGrpMstSn,
    });



    // console.log('SSLWriteVue......................');
    // console.log('lrnTrgtGrpMstSn=============>' + lrnTrgtGrpMstSn.value);
    // console.log('distCrseCd=============>' + distCrseCd.value);
    // console.log('socLrnPostSn=============>' + socLrnPostSn.value);

    const distOptions =ref([]);
    const getOptions= async ()=>{ //년도별 my입과 과정
        // console.log('year : ' + filters.year);
        distOptions.value = [];

        await store.dispatch(`ssl/${ACT_GET_MY_SSL_LST}`, currentYear).then(res => {
            if (lengthCheck(res)) {
                distOptions.value = getItems(res);
            }
            distOptions.value = getItems(res).map(x=>({...x, distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)}));
            selOpt.lrnTrgtGrpMstSn = route.params.lrnTrgtGrpMstSn;

        }).catch(e => {
            console.error(e);
        });
    }

    getOptions();

    const isNoneReward = computed(() => noneRewardCds.includes(distCrseCd.value));
    const isBook = computed(() => 'book' === distCrseCd.value);

    // const lrnTrgtGrp = computed(() => getRouteLrnTrgtGrp(lrnTrgtGrpMstSn.value));
    const lrnTrgtGrp = computed(() => distCrseCd.value == 'none'?null:getRouteLrnTrgtGrp(sslRoutes[distCrseCd.value].group[0].lrnTrgtGrpMstSn));



    // const myGroup = computed(() => store.state.ssl.group);
    // const myGroupCrseNm = ref('');
    // const myGroupFldNm = ref('');
    // if(distCrseCd.value == 'group'){
    //     myGroupCrseNm.value = myGroup.value.filter(x=> x.lrnTrgtGrpMstSn == lrnTrgtGrpMstSn.value).map(y=> y.crseNm)[0];
    //     myGroupFldNm.value = my
    // }
    const myGroup = computed(()=>{
        if(distCrseCd.value == 'group'){
            return{
                crseNm:store.state.ssl.group.filter(x=> x.lrnTrgtGrpMstSn == lrnTrgtGrpMstSn.value).map(y=> y.crseNm)[0],
                distCrseLrnFldNm:store.state.ssl.group.filter(x=> x.lrnTrgtGrpMstSn == lrnTrgtGrpMstSn.value).map(y=> y.distCrseLrnFldNm)[0]
            }
        } else {
            return {crseNm:'', distCrseLrnFldNm:''};
        }
    })


    // console.log('myGroup.length :' + myGroup.value.length);
    // console.log('myGroup.crseNm :' + myGroup.value.crseNm);
    // console.log('myGroup.distCrseLrnFldNm :' + myGroup.value.distCrseLrnFldNm);

    if(distCrseCd.value == 'group'){
        lrnTrgtGrp.value.lrnTrgtGrpNm = myGroup.value.crseNm;
    }




    const useRel = computed(() => ['knowledge', 'hrd', 'book', 'live'].includes(distCrseCd.value)); // 'group',
    const routeFrom = computed(() => store.state.ssl.routeFrom);
    // console.log('routeFrom.value.name : ' + routeFrom.value.name);

    // const currentRel = computed(() => sslRoutes[route.params.distCrseCd] ? sslRoutes[route.params.distCrseCd].rel.write : null);

    const isLoading = ref(false);
    const hashTag = ref('');
    const hashTags = ref([]);

    const thumbFile = ref(null);
    const previewVisible = ref(false);
    const totalLimit = computed(() =>  distCrseCd.value!='none'?noteLimit[distCrseCd.value]:0);

    const socBoard = reactive({
        socLrnPostSn: 0,
        title: '',
        thumb: '',
        hashTag: '',
        refPost: '',
        refTyCdDcd: '',
        refUrl: ''
    });
    const stt = ref('');

    const socPages = ref([]);
    const currentPageIdx = ref(0);
    // const setCount = ref(0);

    const pageEditor = ref(null);
    const editorIframe = ref(null);
    let $_instance = null;
    const tryCount = ref(0);
    const currentText = ref('');
    const isInApp = ref(navigator && navigator.appVersion && navigator.appVersion.indexOf('eHRD_app') > -1 && navigationUtils.iOS()); // in app ios 경우

    const textCount = computed(() => {
        return replaceAll(replaceAll(currentText.value.replace(/(<([^>]+)>)/ig,''), '&nbsp;', ''), '\n', '').length}
    );
    const isExist = ref(false);

    const tempSaveInterval = ref(null);
    const hasNoteChange = ref(false);

    const showOcrMaskingModal = ref(false);
    const ocrImageUrl = ref('');

    const textInfo = ref([{
        text : null,
        tag : null,
        boundingPoly : null,
        vertices : null
    }]);

    const clearInstance=()=>{
        if ($_instance) {
            $_instance.destroy();
            $_instance = null;
        }
        clearTargetInterval(tempSaveInterval.value);

        // SSL 글쓰기 진입 페이지 정보 삭제
        // store.commit(`ssl/${MUT_INIT_ROUTE_FROM}`);
    }

    const dccToggle = ref(false); //분야selectBox Toggle

    //분야
    // const distOptions = computed(() => {
    //     return Object.values(sslRoutes).filter(x=>(x.openYn=='Y')).map(x=>{
    //         return {
    //             distCrseNm: x.name,
    //             distCrseCd: x.key,
    //             lrnTrgtGrpMstSn: x.lrnTrgtGrpMstSns[0],
    //             distCrseSn: x.distCrseSns[0],
    //         }
    //     });
    // });

    //분야선택
    const selDistOption =()=>{
        let crseMstSn = distOptions.value.filter(x => x.lrnTrgtGrpMstSn == selOpt.lrnTrgtGrpMstSn).map(y => y.crseMstSn);
        selOpt.distCrseCd = getCrseSnToDistCrseCd(crseMstSn);
        // let tmpTags = hashTags.value;
        hashTags.value = [];
        hashTag.value = '';
        // hashTags.value = tmpTags.filter((item, index)=>index>0 && ((selOpt.distCrseCd == 'group' && item != '그룹러닝') || selOpt.distCrseCd != 'group'));
        let mltgms = Date.now();
        // let mdcsn = 0;

        if(selOpt.distCrseCd === 'group') {
            //selOpt.lrnTrgtGrpMstSn ==> 그룹러닝차수 TrgtGrpMstSn
            mltgms = distOptions.value.filter(x => x.lrnTrgtGrpMstSn == selOpt.lrnTrgtGrpMstSn).map(y => y.mstTrgtGrpMstSn); //SSL그룹러닝의 TrgtGrpMstSn
            // mdcsn = distOptions.value.filter(x => x.lrnTrgtGrpMstSn == selOpt.lrnTrgtGrpMstSn).map(y => y.mstDistCrseSn);
        }

        router.push({name: 'SSLWrite', params: {distCrseCd:selOpt.distCrseCd, lrnTrgtGrpMstSn:selOpt.lrnTrgtGrpMstSn, socLrnPostSn: 0}, query:{mltgms:mltgms}}).then();
    }


    // common func
    const replaceAll = (str, searchStr, replaceStr) => {
        return str.split(searchStr).join(replaceStr);
    }

    const setCkeditor = (callback) => {
        ClassicEditor.create( pageEditor.value, (isInApp.value ? inAppEditorConfig : editorConfig) )
        .then(editor => {
            $_instance = editor;

            $_instance.model.document.on('change:data', () => {
                currentText.value = $_instance.getData();
            });

            editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                    showLoading('개인정보 검출중...');
                    return new UploadAdapter(loader, '/v1/app/socs/editor/upload/ocr');
            };

            editor.plugins.get('ImageUploadEditing').on('uploadComplete', (evt, {data}) => {
                if (data.fields && data.fields.length > 0) {
                    ocrImageUrl.value = data.url;
                    textInfo.value = data.fields;
                    showOcrMaskingModal.value = true;
                }
                hideLoading();
            });

            watch(() => showOcrMaskingModal.value, () => {
                if (!showOcrMaskingModal.value && textInfo.value.length > 0) {
                    showLoading('마스킹 처리중...');
                    store.dispatch(`board/${ACT_GET_SOCS_EDITOR_UPLOAD_OCR_MASKING}`, {
                        url: ocrImageUrl.value,
                        fields : textInfo.value
                    }).then(res => {
                        $_instance.setData($_instance.getData().replace(ocrImageUrl.value, res));
                        hideLoading();
                    }).catch(() => {
                        hideLoading();
                    });
                }
            });

            if(callback instanceof Function) callback();
        })
        .catch( error => {
            console.error( error );
        });
    }



    const setPageEditor = (callback) => {
        if(tryCount.value > 10) return;
        if(pageEditor.value){
            setCkeditor(callback);
        }else{
            tryCount.value++;
            setTimeout(() => {setPageEditor(callback);}, 200);
        }
    }

    const setEditorInIframe = () => {
        editorIframe.value.contentDocument.body.innerHTML = '<div id="editor" placeholder="본문을 입력하세요."></div>'
        editorIframe.value.contentDocument.head.innerHTML =
            editorCss.map(x => ('<style type="text/css">' + x + '</style>')).join('') + '<style type="text/css">' + mobileEditorCss + '</style>';
        pageEditor.value = editorIframe.value.contentDocument.getElementById('editor');
    }



    const setCurrentPageCn = () => {
        if($_instance){
            $_instance.setData(socPages.value[currentPageIdx.value].cn);
        }
    }

    const getCurrentPageCn = () => {
        if($_instance) {
            socPages.value[currentPageIdx.value].cn = $_instance.getData();
        }
    }

    const addSocPage = () => {
        socPages.value.push({
            socLrnPgSn: 0,
            title: ``,
            cn: '',
        });
    }

    const addHashTag = () => {

        if (hashTag.value !== '') {
            hashTags.value.push(hashTag.value);
            hashTag.value = '';
        }

        if(hashTags.value.length == 0){
            showMessage('태그(키워드)를 1개 이상 입력하세요.');
            return;
        }

        if(hashTags.value.length > 9){
            showMessage('태그는 최대 10개까지 작성 가능합니다.');
            return;
        }
    }

    const deleteHashTag = (idx) => {
        hashTags.value.splice(idx, 1);
    }

    const goToHome = () => {
        goToRoute('SSLHome', '권한이 없습니다.');
    }



    const goToRoute = (routeName, message) => {
        isExist.value = true;
        hideLoading();
        showMessage(message);
        router.push({name: routeName}).then(() => {});
    }

    const togglePreviewVisible = (toggle) => {
        // 먼저 처리
        isMenuActive.value = false;
        getCurrentPageCn();
        previewVisible.value = toggle;
    }

    const exitSocWrite = () => {
        showConfirm({
            text : writeExit,
            callback: () => {
                isExist.value = true;
                // router.push({name: 'SSLMy'}).then(() => {});
                router.push({name: 'SSLTotalMy', params: {distCrseCd:'total', lrnTrgtGrpMstSn:0}}).then(() => {});
            }
        });
    }
    const initEditor = (callback) => {
        if(isInApp.value) setEditorInIframe();
        setPageEditor(callback);
    }


    const getSocsPageList = () => {
        store.dispatch(`ssl/${ACT_GET_SOC_PAGE_LIST}`, socLrnPostSn.value).then(res => {
            if (res.socBoard) {
                let isReturn = false;

                // 2025년 과정... 작성분기동안 수정가능함.
                let currentYmd = dateToDatePart(new Date());
                let pblcnDt = timestampToDateFormat((res.socBoard.pblcnDt ? res.socBoard.pblcnDt : res.socBoard.regDt), 'yyyyMMdd');

                // // 직무 college인 경우 상시 수정 가능
                const isPublic = route.params.distCrseCd === 'college' ? false :
                    res.socBoard.stt === '00'
                    && !Object.values(operPeriod)
                        .filter(x=>x.sdt<= pblcnDt && x.edt >= pblcnDt )
                        .some(y=>y.sdt<= currentYmd.ymd && y.edt >= currentYmd.ymd);
                    // && timestampToDateFormat((res.socBoard.pblcnDt ? res.socBoard.pblcnDt : res.socBoard.regDt), 'yyyy-MM-dd') !== dateToDateFormat(new Date(), 'yyyy-MM-dd')

                // 1차 검증... => 이미 발행 되 었을 경우 같은 날짜가 아니면 return... ==> 2025년과정은 해당 분기동안은 수정가능해짐
                if(isPublic){
                    isReturn = true;
                }

                // 2차 검증... => 자기 글이 아니면 return
                if(res.socBoard.lrnerId !== session.value.lrnerId){
                    isReturn = true;
                }

                if(isReturn){
                    goToHome();
                }else{
                    setParams(socBoard, res.socBoard);
                    if(res.socBoard.hashTag){
                        hashTags.value = res.socBoard.hashTag.split(',');
                    }
                    stt.value = res.socBoard.stt;
                    if (lengthCheck(res)) {
                        socPages.value = getItems(res);
                        initEditor(setCurrentPageCn);
                        // 현재 페이지 정보 저장
                    }
                }
                hideLoading();
            }else{
                goToHome();
            }
        });
    }

    const validateSocParams = (isTemp) => {
        const boardMsgs = validateObject({title: '제목을 입력하세요.'}, socBoard);

        if(boardMsgs.length > 0){
            showMessage(boardMsgs.join('<br>'));
            return false;
        }

        // 페이지가 없을 경우
        if(socPages.value.length === 0){
            showMessage('소셜 러닝 페이지를 작성해주세요.');
            return false;
        }

        if(!isTemp){
            if(textCount.value < minTextLen){
                showMessage(`${minTextLen}자 이상으로 내용 작성해주세요.`);
                return false;
            }

            if(textCount.value > maxTextLen){
                showMessage(`${maxTextLen}자 이하로 내용 작성해주세요.`);
                return false;
            }
        }

        const pageMsgs = [];
        socPages.value.forEach((page, idx) => {

            page.title = socBoard.title;
            pageMsgs.push(...validateObject({
                cn: () => `소셜 러닝 페이지 내용을 작성해주세요.`
            }, page, idx));
        });

        if(pageMsgs.length > 0){
            showMessage(pageMsgs.join('<br>'));
            return false;
        }
        return true;
    }

    // const saveSocs = async (isTemp, isAuto) => {
    const saveSocs = async (isTemp, isAuto) => {
        if(isLoading.value) return;
        isLoading.value = true;
        getCurrentPageCn();
        if(validateSocParams(isTemp)){
            const isUpdate = socBoard.socLrnPostSn > 0;

            try{
                if (isTemp && !isUpdate) {
                    const countRes = await store.dispatch(`ssl/${ACT_GET_MY_SOC_BOARD_COUNT}`, {
                        lrnTrgtGrpMstSn: lrnTrgtGrp.value.lrnTrgtGrpMstSn,
                        tempYn: 'Y',
                    });

                    let tempCount = 0;
                    if(countRes.socBoardCount) tempCount = countRes.socBoardCount;

                    if (tempCount >= tempLimit) {
                        // 자동저장일 경우
                        if (isAuto) clearTargetInterval(tempSaveInterval.value);
                        showMessage(`임시저장된 학습노트를 등록하거나 삭제 후<br>글쓰기 가능합니다.<br>임시저장은 최대 ${tempLimit}개까지 가능합니다.`);
                        isLoading.value = false;
                        return;
                    }

                }else if(!isTemp && !isNoneReward.value){
                    const params = {
                        lrnTrgtGrpMstSn: lrnTrgtGrp.value.lrnTrgtGrpMstSn,
                        todayYn: 'Y',
                    };

                    if(isUpdate) {
                        params['mySocLrnPostSn'] = socBoard.socLrnPostSn;
                    }

                    // 수정의 경우 자기 자신은 뺀다...
                    const countRes = await store.dispatch(`ssl/${ACT_GET_MY_SOC_BOARD_COUNT}`, params);
                    let totalCount = 0;
                    // let todayCount = 0;

                    if(countRes) {
                        if(countRes.socBoardCount) totalCount = countRes.socBoardCount;
                        // if(countRes.todayCount) todayCount = countRes.todayCount;
                    }

                    /* 1일 1노트작성 폐지 */
                    // if(todayCount > 0) {
                    //     showMessage(writeDayLimit);
                    //     isLoading.value = false;
                    //     return;
                    // }

                    if(totalCount >= totalLimit.value){
                        showMessage(writeTotalLimit(totalLimit.value));
                        isLoading.value = false;
                        return;
                    }
                }

                if(thumbFile.value != null){
                    const thumbRes = await store.dispatch(`ssl/${ACT_UPLOAD_SOC_BOARD_THUMBNAIL}`, [thumbFile.value]);
                    if(thumbRes.url) {
                        // 썸네일이 등록되면 file 을 삭제
                        socBoard.thumb = thumbRes.url;
                        thumbFile.value = null;
                    }
                }

                const params = {
                    ...socBoard,
                    lrnTrgtGrpMstSn: lrnTrgtGrp.value.lrnTrgtGrpMstSn,
                    lrnTrgtGrpNm: lrnTrgtGrp.value.lrnTrgtGrpNm,
                    // refTyNm: distCrseCd.value === 'group' ? myGroup.value.crseNm : null,
                    hashTag: hashTags.value.join(','),
                    stt: isTemp ? '01' : '00',
                    socPages: socPages.value.map((x, idx) => ({
                        ...x,
                        pgOrd: (idx + 1)
                    }))
                };

                const res = await (isUpdate ?
                    store.dispatch(`ssl/${ACT_UPDATE_SOC_BOARD}`, {socLrnPostSn: socBoard.socLrnPostSn, params}) :
                    store.dispatch(`ssl/${ACT_INSERT_SOC_BOARD}`, params));

                if(isSuccess(res)){
                    if(!isUpdate){
                        // insert 의 경우 데이터 를 받아온다.
                        socLrnPostSn.value = res.socLrnPostSn || "0";
                        socBoard.socLrnPostSn = parseInt(res.socLrnPostSn);
                        if(res.socLrnPgSns && res.socLrnPgSns.length > 0){
                            socPages.value[currentPageIdx.value].socLrnPgSn = res.socLrnPgSns[0];
                        }
                    }

                    if(isTemp){
                        // if(isAuto){
                        await store.commit(`common/${MUT_SHOW_TOAST}`, '임시저장이 완료되었어요!');
                        // }else{
                        //     showMessage('임시저장된 노트는 최대한 빨리<br>작성하여 등록을 부탁드립니다.<br>노트에 댓글작성은 작성시작일로부터<br>7일까지만 가능합니다.')
                        // }
                        stt.value = "01";
                        isLoading.value = false;
                        insertSocAct(actTyCdDcds.ACT_TY_CD_WRITE , socBoard.socLrnPostSn);
                        isExist.value = true;
                        await router.push({name: 'SSLTotalMyView', params: {distCrseCd: distCrseCd.value, lrnTrgtGrpMstSn: route.params.lrnTrgtGrpMstSn, year:(new Date()).getFullYear(), view:'temporary'}});

                    }else{
                        insertSocAct(actTyCdDcds.ACT_TY_CD_WRITE , socBoard.socLrnPostSn);
                        showMessage('등록이 완료되었어요!');

                        isExist.value = true;
                        // await router.push({name: 'SSLMyView', params: {view: 'complete'}});
                        await router.push({name: 'SSLTotalMyView', params: {distCrseCd: distCrseCd.value, lrnTrgtGrpMstSn: route.params.lrnTrgtGrpMstSn, year:(new Date()).getFullYear(), view:'complete'}});

                    }
                }else{
                    isLoading.value = false;
                    //등록실패 원인 표시
                    // console.error(res);
                    showMessage(`노트를 등록할 수 없습니다.
                    
                    ${res.errorMessage}`);
                    return;
                }
            }catch (e){
                isLoading.value = false;
                console.error(e);
            }
        }else{
            isLoading.value = false;
        }
    }

    const clickSaveButton = (isTemp) => {
        isMenuActive.value = false;

        // console.log("ccc2", distCrseCd.value, route.params)

        //noMessage를 왜 false로 넘기는지??
        if(limitSave(false, isTemp, null, distCrseCd.value)) return;

        // 이미 등록 되어 있는 경우 임시 저장은 못한다.
        if(isTemp && stt.value === '00') return;
        showConfirm(
            (isTemp ? '노트를 임시저장 하시겠습니까?' : writeInsertNote),
            () => { saveSocs(isTemp).then(() => {  }); }
        )
    }

    const deleteSocBoard = () => {
        if(isLoading.value) return;
        isLoading.value = true;

        store.dispatch(`ssl/${ACT_DELETE_SOC_BOARD}`, socLrnPostSn.value).then(res => {
            if(isSuccess(res)){
                insertSocAct(actTyCdDcds.ACT_TY_CD_REMOVE, socBoard.socLrnPostSn);
                store.commit(`common/${MUT_SHOW_TOAST}`, '삭제되었어요!');
                isExist.value = true;
                router.push( {name: 'SSLTotalMyView', params: {distCrseCd: 'total', lrnTrgtGrpMstSn: 0, year:(new Date()).getFullYear(), view: 'temporary'}}).then(() => {});
            }else{
                isLoading.value = false;
            }
        }).catch(e => {
            console.error(e);
            isLoading.value = false;
        });
    }

    const clickDeleteButton = () => {
        isMenuActive.value = false;
        // 저장되지 않는 소셜 러닝은 처리하지 않는다.
        if(socLrnPostSn.value === "0") return;
        showConfirm({
            text : writeDeleteConfirm,
            callback: deleteSocBoard
        });
    }

    const relSn = computed(() => route.query.relSn);
    // const lrnObjDtlDistSn = computed(() => route.query.lrnObjDtlDistSn);

    const getRel = () => {
        if(useRel.value){
            if(isBook.value){
                if(relSn.value){
                    store.dispatch(`ssl/${ACT_GET_SOC_BOOK_LIST}`, {
                        bookIsbn: relSn.value
                    }).then(res => {
                        if(lengthCheck(res)){
                            setRelParams(getItem(res));
                        }else{
                            setRelParams({bookIsbn: null,bookNm: '자유도서', bookDesc: '-'});
                        }
                    });
                }else{
                    setRelParams({bookIsbn: null,bookNm: '자유도서', bookDesc: '-'});
                }
            }else{
                if(relSn.value && relSn.value > 0){ // ==>기존 그룹러닝 모임에서 글쓰기할 경우 relSn(모임id)로 모임정보조회 후 refPost에 넣음, 2025과정은 안탄다....
                    store.dispatch(`ssl/${ACT_GET_SOC_REL_WRITE}`, {division: distCrseCd.value, relSn: relSn.value}).then(res => {
                        if (lengthCheck(res)) setRelParams(getItem(res));
                    });
                }
            }
        }else{
            getRefFromPrevPage();
        }
    }

    const setRelParams = (item) => {
        // if(distCrseCd.value === 'group'){
        //     socBoard.refPost = [
        //         myGroup.value.crseNm,
        //         item.relNm,
        //         getTimeText(item.relBgngDt, item.relEndDt),
        //         relSn.value
        //     ].join('^');
        // }else
        if(distCrseCd.value === 'knowledge'){
            if(item.relNm) hashTags.value.push(item.relNm.split(' ').join(''));
            socBoard.refPost = [item.relNm, relSn.value].join('^');
            // 수정 필요
            socBoard.refUrl = item.relEtnUrl;
        }else if(distCrseCd.value === 'hrd'){
            if(item.relCateNm) hashTags.value.push(item.relCateNm.split(' ').join(''));
            socBoard.refPost = [getThumbImg(item.relThumb), item.relNm, item.relCateNm, relSn.value].join('^');
            // hrd cloud url 임
            // 수정 필요
            socBoard.refUrl = item.relEtnUrl;
        }else if(distCrseCd.value === 'book'){
            if(item.bookNm) hashTags.value.push(item.bookNm.split(' ').join(''));
            socBoard.refPost = [item.bookNm, item.bookDesc, item.thumb, relSn.value].join('^');
            socBoard.refUrl = item.bookUrl;
        }
        else if(distCrseCd.value === 'live'){
            if(item.relNm) hashTags.value.push(item.relNm.split(' ').join(''));
            socBoard.refPost = [item.relNm, relSn.value, getTimeText(item.relBgngDt, item.relEndDt)].join('^');
        }
        else if(routeFrom.value.name === 'HrdCloudContentBridge'){
            const HrdThumb = getThumbImg(item.thumb) ? getThumbImg(item.thumb) : (item.lrnObjTyCd ==="100003" ?  `${process.env.VUE_APP_CLOUDFRONT_URL}/contents/thumbnail/temp/temp_pdf_thumb.jpg` : `${process.env.VUE_APP_CLOUDFRONT_URL}/contents/thumbnail/temp/temp_hrdcloud_thumb.jpg`);
            socBoard.refPost = ['hrd', HrdThumb, item.objNm, item.hrdCloudCateNm, item.lrnObjDtlDistSn].join('^');
            socBoard.refPost = socBoard.refPost.replace(/devlxpcms-ncloud.cdn.ntruss.com/g,'devlxpcms.kbstar.com');
            socBoard.refPost = socBoard.refPost.replace(/lxpcms-ncloud.cdn.ntruss.com/g,'lxpcms.kbstar.com');
        }
        else if(routeFrom.value.name === 'SSLView' || routeFrom.value.name === 'SSLTotalView'){
            socBoard.refPost = ['ssl', item.thumb, item.title, item.lrnerNm.concat(' ').concat(item.deptNm), item.socLrnPostSn].join('^');
        }
        else if(routeFrom.value.name === 'BoardView'){
            if(routeFrom.value.fullPath.indexOf("/board/hottip")>-1){
                socBoard.refPost = ['hottip', `${process.env.VUE_APP_LXP_URL}files/images/hottip-thumb.png`, item.postTitle, item.lrnerNm.concat(' ').concat(item.deptNm), item.comPostSn].join('^');
            }
        }


        // console.log("routefrom check", routeFrom.value, item);

        if(!socBoard.refUrl){
            socBoard.refUrl = routeFrom.value.fullPath;
        }
    }

    const getRefFromPrevPage = async () => {
        if(routeFrom.value.name === 'HrdCloudContentBridge'){
            store.dispatch(`prep/${ACT_GET_HRDCLOUD_DETAIL}`, {lrnObjDtlDistSn: routeFrom.value.params.lrnObjDtlDistSn}).then(res => {
                if(lengthCheck(res)){
                    setRelParams(getItem(res));
                }
            })
        }else if(routeFrom.value.name === 'SSLView' || routeFrom.value.name === 'SSLTotalView'){
            store.dispatch(`ssl/${ACT_GET_SOC_PAGE_LIST}`, routeFrom.value.params.socLrnPostSn).then(res => {
                if(res.socBoard){
                    setRelParams(res.socBoard);
                }
            })
        }else if(routeFrom.value.name === 'BoardView'){

            //boardId 의 mstsn 구하기

            //mstsn 기준으로 post dtl
            store.dispatch(`boardmst/${ACT_GET_POST_DTL}`, routeFrom.value.params).then((res) => {
                if(lengthCheck(res)){
                    setRelParams(getItem(res));
                }
            })
        }else{
            setRelParams(null);
        }
    }

    // watch
    watch(() => hashTag.value, () => {
        if (hashTag.value.indexOf('#') > -1) {
            hashTag.value = hashTag.value.replace('#', '');
        }
        if(hashTag.value.indexOf(' ') > -1){
            hashTag.value = hashTag.value.replace(' ', '');
        }
        if(hashTag.value.length > 15){
            hashTag.value = hashTag.value.substring(0, 15);
        }
    });

    watch(() => currentText.value, () => {
        if(!hasNoteChange.value) hasNoteChange.value = true;
        if(hasNoteChange.value && currentText.value.length === 0) hasNoteChange.value = false;
    });

    watch(() => routeFrom.value.name, getRefFromPrevPage)

    watch(()=>[route.params.lrnTrgtGrpMstSn], ()=>{
        if (route.params.lrnTrgtGrpMstSn > 0){
            hashTag.value = '';
            hashTags.value = [];
            firStep();
        }
    });

    // *********************************************************
    // pc
    const uploadModal = ref(false);
    const uploadEffect = ref(0);
    const showUploadModal = () => {
        if(!socBoard.thumb){
            uploadEffect.value++;
        }
        uploadModal.value = true;
    }

    const applyPCThumbnail = (thumb, file) => {
        socBoard.thumb = thumb;
        thumbFile.value = file;
    }

    const closeThumbnail = () => {
        uploadModal.value = false;
    }

    // *********************************************************
    // mobile
    const step = ref(0);
    const isMenuActive = ref(false);
    const isThumbActive = ref(false);

    const uploadFile = ref(null);
    let imgReader = null;
    if (isMobile.value) {
        imgReader = new FileReader();
        imgReader.onload = (event) => {
            socBoard.thumb = event.target.result;
        };
    }

    const toggleMenu = () => {isMenuActive.value = !isMenuActive.value;}
    const toggleThumb = (toggle)=>{isThumbActive.value = toggle;}

    const applyMobileThumbnail = () => {
        if(imgReader !== null && uploadFile.value.files && uploadFile.value.files.length > 0){
            const file = uploadFile.value.files[0];
            // 썸 네일 처리
            thumbFile.value = file;
            imgReader.readAsDataURL(file);
            uploadFile.value.value = null;
        }
    }

    const clickUploadFile = () => {
        if(uploadFile.value){
            uploadFile.value.click();
            isThumbActive.value = false;
        }
    }

    const setRecommend = () => {
        socBoard.thumb = getRandomThumb();
        thumbFile.value = null;
        isThumbActive.value = false;
    }

    const titleIns = ref(null);
    const validationStep = computed(() => {
        return !!(socBoard.title);
    });

    const goStep = () => {
        if(step.value === 1){
            clickSaveButton();
        }else{
            if(validationStep.value){
                step.value = 1;
            }else{
                showMessage({text: '제목을 입력해주세요.', callback: () => {setTimeout(() => {if(titleIns.value){ titleIns.value.focus(); }}, 400);}});
            }
        }
    }

    const backStep = () => {
        if(previewVisible.value){
            previewVisible.value = false;
        }else{
            if(step.value === 1){
                step.value = 0;
            }else{
                goBack();
            }
        }
    }


    // *********************************************************
    // init


    const isAccess = () => {
        console.log('distCrseCd.value : ' + distCrseCd.value);
        console.log('route.query.mltgms : ' + route.query.mltgms);
        if (distCrseCd.value === 'group') {
            if (isRightSSL(distCrseCd.value, route.query.mltgms)) {
                return true;
                // 그룹 러닝의 경우 리더(그룹장)만 글을 작성할 수 있다. --> 2025년부터 리더,멤버 모두 작성함.
                // return session.value.lrnerId === myGroup.value.leaderLrnerId;
            }
        }else {
            if (isRightSSL(distCrseCd.value, lrnTrgtGrp.value.lrnTrgtGrpMstSn)) {

                if (distCrseCd.value === 'trivia' || distCrseCd.value === 'live') {
                    // 알쓸신잡의 경우 그냥 글을 작성할수 있다.
                    return true;
                }
                return isMyLrnTrgtGrpMstSn(lrnTrgtGrp.value.lrnTrgtGrpMstSn);
            }
        }
        return false;

    }
    const firStep=()=>{

        // 확인...
        if (isAccess()) {
            window.scrollTo(0, 0);
            showLoading();
            if (socLrnPostSn.value === "0") {
                // 임시저장 개수를 체크한다.
                store.dispatch(`ssl/${ACT_GET_MY_SOC_BOARD_COUNT}`, {
                    tempYn: 'Y',
                    lrnTrgtGrpMstSn: lrnTrgtGrp.value.lrnTrgtGrpMstSn,
                }).then(res => {
                    const tempCount = res && res.socBoardCount ? res.socBoardCount : 0;
                    if (tempCount >= tempLimit) {

                        hideLoading();
                        isExist.value = true;
                        showMessage('임시저장된 학습노트를 등록하거나 삭제 후<br>글쓰기 가능합니다.<br>임시저장은 최대'+ tempLimit+'개까지 가능합니다.');
                        router.push({name: 'SSLTotalMyView', params: {distCrseCd: 'total', lrnTrgtGrpMstSn: 0, year:(new Date).getFullYear(), view:'temporary'}});
                    } else {
                        if(socPages.value.length < 1){ addSocPage(); }

                        clearInstance();
                        initEditor();

                        if (distCrseCd.value.indexOf("self") >= 0 && hashTags.value.toString().indexOf(lrnTrgtGrp.value.lrnTrgtGrpNm) < 0) {
                            hashTags.value.push(lrnTrgtGrp.value.lrnTrgtGrpNm);
                        } else if (distCrseCd.value === 'group'){

                            hashTags.value.push('그룹러닝');
                            if (hashTags.value.toString().indexOf(myGroup.value.distCrseLrnFldNm) < 0) {
                                hashTags.value.push(myGroup.value.distCrseLrnFldNm);
                            }

                            //2025년 그룹러닝 : 그룹 2개에 속할 수 있음...
                            // socBoard.distCrseSn = route.query.mdcsn;
                            // socBoard.lrnTrgtGrpMstSn = route.query.mltgms;
                            socBoard.refTyNm= lrnTrgtGrpMstSn.value;
                            socBoard.refTyCdDcd = '2113005';
                        }
                        getRel();
                        hideLoading();
                    }
                }).catch(() => {
                    hideLoading();
                    goToRoute('SSLTotalMy', '오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
                });
            } else {
                // console.log('socLrnPostSn : 1111111111111111111111111 / ' + socLrnPostSn.value);
                getSocsPageList();
            }
            initTempSave(tempSaveInterval, hasNoteChange, stt, socBoard, saveSocs);
        } else {
            //goToHome();

            // distCrseCd.value = 'none';
            // console.log("isNoAccess.....");
            showMessage('권한이 없습니다.<br>학습분야를 다시 선택하세요.');
            router.push({name: 'SSLWrite', params: {distCrseCd:'none', lrnTrgtGrpMstSn:0
                    , socLrnPostSn: 0}}).then(() => {
                socPages.value=[];
                socBoard.refPost='';
                socBoard.socLrnPostSn=0;
                socBoard.refUrl='';
                socBoard.title='';
                socBoard.thumb='';
                socBoard.hashTag= '';
                selOpt.distCrseCd = '';
                selOpt.lrnTrgtGrpMstSn = 0;
            });

        }
    }

    onBeforeRouteLeave(() => {
        if(previewVisible.value){
            previewVisible.value = false;
            return false;
        }

        if(isExist.value) return true;
        return confirm(writeExitPlane);
    });

    onBeforeUnmount(() => {
        clearInstance();
    });

    // 학습 그룹이 없이는 글을 작성할 수 없다.
    onMounted(async () => {
        if(!isSSLPeriod()){
            showMessage( limitSSL);
            goHome();
        }


        if(route.params.distCrseCd == 'none'){ //초기선택
            showMessage('학습분야를 선택하세요.');
        }else {
            firStep();
        }
    });


    // 작성 중 화면 unload 이벤트 확인
    window.addEventListener('beforeunload', (e) => {
        e.preventDefault();
        e.returnValue = '';
    });

    return {
        currentText,
        isMobile,
        maxTextLen,
        minTextLen,

        distCrseNm,
        isMenuActive,
        isThumbActive,

        socBoard,
        stt,
        socPages,
        currentPageIdx,
        hashTags,
        hashTag,

        previewVisible,
        textCount,
        pageEditor,
        editorIframe,
        isInApp,

        addSocPage,
        addHashTag,
        deleteHashTag,
        exitSocWrite,
        clickSaveButton,
        clickDeleteButton,
        togglePreviewVisible,

        // pc
        uploadModal,
        uploadEffect,
        applyPCThumbnail,
        closeThumbnail,
        showUploadModal,

        // mobile
        step,
        socLrnPostSn,
        uploadFile,
        titleIns,
        validationStep,
        toggleMenu,
        toggleThumb,
        clickUploadFile,
        setRecommend,
        applyMobileThumbnail,
        goStep,
        backStep,
        showOcrMaskingModal,
        textInfo,
        ocrImageUrl,

        distOptions,
        selOpt,
        selDistOption,
        dccToggle,
        lrnTrgtGrp

    }


}
