<template>
  <div class="learningGroup-my mb-4">
    <div class="my-avatar">
      <div class="image">
        <SSLProfileImg :target="targetId" />
      </div>
    </div>
    <div class="my-content">
<!--      <h3 class="title"><i class="icon-title-my-group"></i></h3>-->
      <h4 class="subtitle subtitle-indigo">
        <template v-if="subTitle">
          {{ subTitle }}
        </template>
        <template v-else>
          그룹러닝 | {{ distCrseLrnFldNm }}
        </template>
        <template v-if="crseNm">
          | {{ crseNm }}
        </template>
      </h4>

      <p class="content"><span class="text">{{ session.lrnerNm }}</span> <span class="text text-muted">{{ session.deptNm }}</span></p>
    </div>
    <div class="my-links">
      <router-link :to="{name: 'SSLTotalMyView', params: {distCrseCd: 'group', lrnTrgtGrpMstSn: lrnTrgtGrpMstSn, year:(new Date).getFullYear(), view:'complete'}}" class="link"><i class="icon-my-ssl"></i></router-link>
<!--      <a href="javascript:" class="link is-active">-->
<!--        <i v-if="distCrseCd === 'group'" class="icon-my-group"></i>-->
<!--        <i v-else-if="distCrseCd === 'knowledge'" class="icon-my-test"></i>-->
<!--      </a>-->
    </div>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import SSLProfileImg from "@/components/ssl/common/SSLProfileImg";
import {useRoute, useRouter} from "vue-router";
import {ACT_GET_SOC_MY_REL, MUT_SET_MY_REL} from "@/store/modules/ssl/ssl-index";
import {getItems} from "@/assets/js/util";
// import {enrollSSLAlert, isMyLrnTrgtGrpMstSn, noneRewardCds, sslRoutes} from "@/assets/js/modules/ssl/ssl-common";


export default {
  name: "MobileMyGroupLearningProfile",
  components: {
    SSLProfileImg,
    // SortButtonSelect,
  },
  props: {
    crseNm: String,
    subTitle: {
      type: String,
      default: null
    },
    distCrseLrnFldNm: String,
    distCrseCd: String,
    distCrseSn: Number,
    lrnGoals: String,
    items: Array
  },

  setup(props){
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const relToggle = ref(false);
    const session = computed(() => store.state.auth.session);
    // const distCrseCd = computed(() => route.params.distCrseCd);
    // const distCrseNm = computed(() => sslRoutes[route.params.distCrseCd] ? sslRoutes[route.params.distCrseCd].name : '' );
    const myGroup = computed(() => store.state.ssl.group);

    const getMyGroup=()=>{
      store.dispatch(`ssl/${ACT_GET_SOC_MY_REL}`, 'group').then(res => {
        store.commit(`ssl/${MUT_SET_MY_REL}`, {division: 'group', items: getItems(res)});
      });
    }
    if(myGroup.value == null || myGroup.value.length == 0){
      getMyGroup();
    }

    const isBlack = computed(() => ['selfIct','selfJa','selfGa','selfGi','selfDvl','group', 'trivia', 'college', 'live'].includes(route.params.distCrseCd));
    // const isMyLrnTrgt = computed(() => isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn.value));
    // const selDistCrseSn = ref( props.distCrseSn);
    const targetId = computed(() => {
      if (props.items && props.items.length > 0 ) return {lrnerId: props.items.find(x=> x.distCrseSn == props.distCrseSn).lrnerId};
      else return session.value;
    });


    // const changeThis = () => {
    //     emit("selected");
    //     router.push({name: 'GroupView', params: {distCrseSn: selDistCrseSn.value}});
    //
    //
    // }

    console.log('props.distCrseSn : ' + props.distCrseSn);
    myGroup.value.filter(x=>{
      console.log(x.distCrseSn+':'+x.lrnTrgtGrpMstSn);
    });

    const lrnTrgtGrpMstSn = computed(()=> myGroup.value.length > 0?myGroup.value.filter(x=>x.distCrseSn == props.distCrseSn)[0].lrnTrgtGrpMstSn:0);

    // const lrnTrgtGrpMstSn = computed(() => {
    //   if (props.distCrseCd === 'group') return 119739;
    //   else if (props.distCrseCd === 'knowledge') return 112121
    //   return 0;
    // });

    // const goMySSL = () => {
    //   if (isMyLrnTrgt.value) {
    //     router.push({name: 'SSLMy'}).then(() => {});
    //   } else {
    //     if (noneRewardCds.includes(distCrseCd.value)) {
    //       router.push({name: 'SSLMy'}).then(() => {});
    //     } else {
    //       enrollSSLAlert(distCrseCd, distCrseNm, lrnTrgtGrpMstSn);
    //     }
    //   }
    // };

    const goKnowledge = () =>{
      router.push({name: 'KnowledgeELearning'});
    };



    return {
      targetId,
      session,
      lrnTrgtGrpMstSn,
      isBlack,
      myGroup,
      goKnowledge,
      // goMySSL,
      // selDistCrseSn,
      relToggle,
      // changeThis,
    }
  }
}
</script>
