import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {computed, ref, watch} from 'vue';
import {
  ACT_GET_SOC_CMNT_LIST,
  ACT_GET_SOC_REL_GROUP_TARGET,
} from '@/store/modules/ssl/ssl-index';
import {
  // dateToDateFormat,
  getItem,
  getItems, initParams,
  lengthCheck,
  timestampToDateFormat,
} from '@/assets/js/util';
import {
  canCommentEdit, confirmDeleteComment,
  confirmInsertComment,
  confirmUpdateComment,
} from '@/assets/js/modules/ssl/ssl-write';
import {
  enrollSSLAlert,
  getRouteLrnTrgtGrpMstSn,
  isMyLrnTrgtGrpMstSn, noneRewardCds, sslRoutes,
} from '@/assets/js/modules/ssl/ssl-common';
import {useAlert} from '@/assets/js/modules/common/alert';

export const sslCommentSetup = (props) => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const isLoading = ref(false);
  const session = computed(() => store.state.auth.session);
  const socLrnPostSn = computed(() => route.params.socLrnPostSn ? (route.params.socLrnPostSn || 0) : props.socLrnPostSn);
  const distCrseCd = computed(() => route.params.distCrseCd);
  const isGroup = computed(() => distCrseCd.value === 'group');
  const isNoneReward = computed(() => noneRewardCds.includes(distCrseCd.value));
  const lrnTrgtGrpMstSn = computed(()=>getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));
  const isMyLrnTrgt = computed(() => isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn.value));
  const distCrseNm = computed(() => sslRoutes[distCrseCd.value] ? sslRoutes[distCrseCd.value].name : '' );

  const cmnt = ref('');
  const comments = ref([]);

  const replyParam = ref({
    socLrnPostSn: 0,
    upCmntSn: 0,
    cmnt: '',
    cmntLvl: 0,
  });

  // const commentLimit = 1000 * 60 * 60 * 24 * 7;
  // 추가 처리 필요 => 나의 학습그룹 + 시간제한
  /* 7일이전글 댓글 금지 폐지 */
  const isDisabled = ref(false);
  // const isDisabled = computed(() => {
  //   let currentDate = new Date();
  //   currentDate.setDate(currentDate.getDate() - 7);
  //   // 알쓸신잡, 러닝라이브, 직무College 의 경우 댓글은 7일만 확인한다.
  //   // if (noneRewardCds.includes(distCrseCd.value)) {
  //   if(isNoneReward.value){
  //     if(distCrseCd.value === 'college'){
  //       return false;
  //     }
  //     // console.log(timestampToDateFormat((props.regDt), 'yyyyMMdd') ,dateToDateFormat(currentDate, 'yyyyMMdd'))
  //     return  timestampToDateFormat((props.regDt), 'yyyyMMdd') < dateToDateFormat(currentDate, 'yyyyMMdd')
  //   }
  //   return timestampToDateFormat((props.regDt), 'yyyyMMdd') < dateToDateFormat(currentDate, 'yyyyMMdd')
  //
  //   // return !isMyLrnTrgtGrpMstSn(getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn)) || timestampToDateFormat((props.regDt), 'yyyyMMdd') < dateToDateFormat(currentDate, 'yyyyMMdd')
  // });

  const getSocCommentList = () => {
    cmnt.value= '';
    store.dispatch(`ssl/${ACT_GET_SOC_CMNT_LIST}`, {socLrnPostSn: socLrnPostSn.value})
    .then(res => {
      if(lengthCheck(res)){
        comments.value = getItems(res)
      }else{
        comments.value = [];
      }
    })
    .catch(() => {
      comments.value = [];
    });
  }

  // const pushComment = (socLrnCmntSn) => {
  //   comments.value.push({
  //     socLrnCmntSn,
  //     cmnt: cmnt.value,
  //     regDt: new Date().getTime(),
  //     lrnerId: session.value.lrnerId,
  //     lrnerNm: session.value.lrnerNm,
  //     deptNm: session.value.deptNm,
  //     imgUrl: store.state.my.profileImgUrl
  //   });
  //   cmnt.value = '';
  // }

  const clickInsert = () => {
    if (!isNoneReward.value && !isMyLrnTrgt.value) { //미입과 과정인 경우 신청유도
      enrollSSLAlert(distCrseCd, distCrseNm, lrnTrgtGrpMstSn);
      return;
    }
    confirmInsertComment(isDisabled.value, isLoading.value, socLrnPostSn.value, 0,0, cmnt.value, getSocCommentList); // pushComment
  }

  const clickUpdate = (idx) => {
    const isColleage = computed(() => 'college' === distCrseCd.value);

    confirmUpdateComment(canCommentEdit(comments.value[idx].regDt, isColleage.value), isLoading.value, comments.value[idx]);
  }

  const clickDelete = (idx) => {
    confirmDeleteComment(isLoading.value, comments.value, idx);
  }
  const addReply = (mode, idx) => {
    if(mode == 'add'){

      if(comments.value[idx].cmntLvl + 1 > 5){
        showMessage("대댓글은 5개까지만 등록 가능합니다.");
        return;
      }

      comments.value[idx].isReplying = true;
      replyParam.value.socLrnPostSn = comments.value[idx].socLrnPostSn;
      replyParam.value.upCmntSn = comments.value[idx].socLrnCmntSn;
      replyParam.value.cmntLvl = comments.value[idx].cmntLvl + 1;
    }else if(mode == 'cancel'){
      comments.value[idx].isReplying = false;
      initParams(replyParam.value);
    }else if(mode == 'save'){
      if(replyParam.value.cmnt == ''){
        showMessage("댓글을 입력하세요");
        return;
      }
      confirmInsertComment(isDisabled.value, isLoading.value,replyParam.value.socLrnPostSn, replyParam.value.upCmntSn, replyParam.value.cmntLvl, replyParam.value.cmnt, getSocCommentList);

      initParams(replyParam.value);
      comments.value[idx].isReplying = false;
    }
  }

  const changeMode = (idx) => {
    if(!comments.value[idx].editMode){
      comments.value[idx].originCmnt = comments.value[idx].cmnt;
    }else{
      // 취소 햇을 때
      comments.value[idx].cmnt = comments.value[idx].originCmnt;
    }
    comments.value[idx].editMode = !comments.value[idx].editMode;
  }

  const toggleEdit = (idx) => {
    comments.value.forEach(x => {
      if(x.toggle) x.toggle = false;
    });
    comments.value[idx].toggle = !comments.value[idx].toggle;
  }

  const closeCommentToggles = () => {
    comments.value.forEach(x => {
      if(x.toggle) x.toggle = false;
    });
  }

  const {showMessage, showLoading, hideLoading} = useAlert();

  const goLearner = target => {
    if (target && target.lrnerId) {
      if (isGroup.value) {
        showLoading();
        store.dispatch(`ssl/${ACT_GET_SOC_REL_GROUP_TARGET}`, target.value).then(res => {
          hideLoading();
          if (lengthCheck(res) && getItem(res).leaderLrnerId === target.lrnerId) {
            goToLearnerBoard(target.lrnerId);
          } else {
            showMessage('멤버의 my SSL 이동은 불가합니다.');
          }
        }).catch(() => {
          hideLoading();
          showMessage("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        });
        // check if learner is Leader

      } else {
        goToLearnerBoard(target.lrnerId);
      }
    }
  }

  const goToLearnerBoard = (lrnerId) => {
    router.push({name: 'SSLLearnerBoard', params: {lrnerId}}).then(() => {});
  }

  if(socLrnPostSn.value > 0) getSocCommentList();

  watch(() => socLrnPostSn.value, () => {
    if(socLrnPostSn.value > 0){
      getSocCommentList();
    }else{
      comments.value = [];
    }
  });

  return {
    session,
    cmnt,
    isDisabled,
    comments,
    distCrseCd,
    changeMode,
    toggleEdit,
    canCommentEdit,
    clickUpdate,
    clickDelete,
    clickInsert,
    closeCommentToggles,
    goLearner,

    timestampToDateFormat,

    addReply,
    replyParam
  }

}
