<template>
  <main class="kb-main" id="ssl-home-v2">
    <main class="kb-main" id="kb-home" style="padding-bottom: 0px;">

      <article class="content-section section-hrdcloud" style="padding-bottom: 0;border-top: 1px solid var(--ssl-light-gray)">
        <header class="section-header">
          <h2 class="title">인기</h2>
        </header>
        <div class="hrdcloud-list-wrapper">
          <SSLMobileLearnerBoard :lrn-trgt-grp-mst-sn="0" :sortCd="'like'" :lmtCnt="10" :opt="'S'" />
        </div>
      </article>



      <article class="content-section section-hrdcloud mt-0" style="border-top: 1px solid var(--kb-light-silver);padding-bottom: 0">
        <header class="section-header">
          <h2 class="title">최신</h2>
        </header>
        <div class="hrdcloud-list-wrapper">
          <SSLMobileLearnerBoard :lrn-trgt-grp-mst-sn="0" :sortCd="'new'" :lmtCnt="10" :opt="'S'" />
        </div>
      </article>

    </main>
  </main>

  <SSLMobileLearnerBadge/>
</template>

<script>
import SSLMobileLearnerBadge from '@/components/ssl/learner/mobile/SSLMobileLearnerBadge';
import SSLMobileLearnerBoard from '@/components/ssl/learner/mobile/SSLMobileLearnerBoard'

export default {
  name: 'SSLMobileLearnerHome',
  components: { SSLMobileLearnerBadge, SSLMobileLearnerBoard},
  setup() {

    return{}
  }
}
</script>
