<template>
  <div class="popup-container is-active">
    <div class="popup popup-attendance kb-scroll-attendance" id="popup-attendance" >
      <button v-if="!isMobile" class="kb-btn-popup-close" @click="closeCalendar">
        <i class="icon-close"></i>
      </button>
      <div class="calendar">
        <div class="calendar-top">
          <h2 class="title">{{ currentMonth }}월 출석</h2>
          <div class="actions">
            <button class="kb-btn-month kb-btn-month-prev" type="button" @click="changeMonth(false)">
              <i class="icon-prev"></i>
              <span class="text">{{ prevYearMonth }}</span>
            </button>
            <button class="kb-btn-month kb-btn-month-next" type="button" @click="changeMonth(true)">
              <span class="text">{{ nextYearMonth }}</span>
              <i class="icon-next"></i>
            </button>
          </div>
        </div>
        <div class="calendar-body">
          <table class="calendar-table">
            <thead>
            <tr>
              <th scope="col"><span class="day-header">일</span></th>
              <th scope="col"><span class="day-header">월</span></th>
              <th scope="col"><span class="day-header">화</span></th>
              <th scope="col"><span class="day-header">수</span></th>
              <th scope="col"><span class="day-header">목</span></th>
              <th scope="col"><span class="day-header">금</span></th>
              <th scope="col"><span class="day-header">토</span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(week, idx) in currentWeeks_new" :key="idx">
              <td v-for="(day, i) in week" :key="`${idx}-${i}`">
                <div v-if="day.month === currentMonth"  class="day day-check">
                  <template v-if="isHolyDay(day)">
                    <template v-if="!isActive(day)">
                      <span class="att-text calendar-day-text calendar-day-large-text">{{ day.day }}</span> <!--  :class="{ 'text-red' : isWeekend(day) }" -->
                      <span class="att-text" style="position: absolute;margin-top:70px; font-size:14px">{{ getHolyDay(day).indexOf('대체공휴일')>-1?'대체공휴일':getHolyDay(day) }}</span>
                    </template>
                    <template v-else>
                      <img  class="img-att" src="@/assets/images/ssl/ic_att_active.png" alt="" />
                    </template>
                  </template>
<!--                  <template v-else-if="(i === 0 || i === 6)&& day.isCouponDay < 0">-->
<!--                    <span class="att-text calendar-day-text calendar-day-large-text" :class="{ 'text-red' : isWeekend(day) }">{{ day.day }}</span>-->
<!--                  </template>-->
<!--                  <template v-else-if="day.isCouponDay > 0">-->
<!--                    <img v-if="day.isCouponDay === 1" class="img-att" src="@/assets/images/ssl/ic_coupon_deactive.svg" alt="" />-->
<!--                    <img v-else-if="day.isCouponDay === 2" class="img-att" src="@/assets/images/ssl/ic_coupon_active.svg" alt="" />-->
<!--                  </template>-->
<!--                  <template v-else>-->
<!--                    <img v-if="isActive(day)" class="img-att" src="@/assets/images/ssl/ic_att_active.png" alt="" />-->
<!--                    <span v-else class="att-text calendar-day-text calendar-day-large-text">{{ day.day }}</span> &lt;!&ndash;  :class="{ 'text-red' : isWeekend(day) }" &ndash;&gt;-->
<!--                    <span v-if="day.year === today.year && day.month === today.month && day.day === today.day" class="att-text">오늘</span>-->
<!--                  </template>-->
<!--{{day.isIncenDay}}-->
                  <template v-else>
                    <img v-if="isActive(day)" class="img-att" src="@/assets/images/ssl/ic_att_active.png" alt="" />
                    <span v-else class="att-text calendar-day-text calendar-day-large-text">{{ day.day }}</span> <!--  :class="{ 'text-red' : isWeekend(day) }" -->
                    <span v-if="day.year === today.year && day.month === today.month && day.day === today.day" class="att-text" style="position: absolute;margin-top:70px; font-size:14px">오늘</span>
                  </template>
                </div>
                <div v-else class="day blank"></div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="calendar-bottom mt-5">
          <p class="mb-1 text-center msgbox">{{lrnerNm}}님의 <span class="msgboxS">{{currSeq}}회차[{{term}}]</span><br>
            출석일수는 <span class="msgboxS">{{incenAttendCnt}}일</span>, 등록콘텐츠는 <span class="msgboxS">{{regBoardCnt}}건</span>입니다.</p>
          <p class="bottom-text mb-1 text-center text-danger" v-if="incenAttendCnt>=20 && regBoardCnt>=1">"{{currSeq}}회차 인센티브 요건을 달성했어요!"</p>
          <p class="bottom-text mb-1 text-center" v-else>"{{currSeq}}회차 인센티브 요건을 아직 달성하지 못했어요!"</p>
            <button v-if="isMobile" class="kb-btn-attendance-close" @click="closeCalendar" style="flex: 0 0 100% !important; margin-left: 0 !important;">
              <span class="text">닫기</span>
            </button>
            <button class="kb-btn-attendance mt-3" @click="closeCalendarAndExpire">
              <span class="text">오늘 하루 보지 않기</span>
            </button>
        </div>
<!--        <div class="calendar-bottom">-->
<!--          <p class="bottom-text mb-1">2주 단위 매영업일 출석체크 달성 시 커피쿠폰이 제공됩니다.</p>-->
<!--          <p class="bottom-text mt-1 mb-3">학습노트 조회 + 좋아요 1건 완료시 출석이 인정됩니다.</p>-->
<!--          <button v-if="isMobile" class="kb-btn-attendance-close" @click="closeCalendar" style="flex: 0 0 100% !important; margin-left: 0 !important;">-->
<!--            <span class="text">닫기</span>-->
<!--          </button>-->
<!--          <button class="kb-btn-attendance mt-3" @click="closeCalendarAndExpire">-->
<!--            <span class="text">오늘 하루 보지 않기</span>-->
<!--          </button>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import {sslCalendarSetup} from '@/assets/js/modules/ssl/setup/ssl-calendar-setup';
export default {
  name: 'SSLCalendar',
  setup: sslCalendarSetup
}
</script>

<style>
  #ssl-layout .calendar .calendar-bottom .bottom-box {
    font: normal 16px/22px var(--kb-font-KBFGText);
    color: var(--kb-silver);
  }
</style>