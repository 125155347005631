<template>
  <div v-if="!reload" @click="plusNavigation">
    <HrdMobileShortsNativeLauncher
        v-if="isInApp && item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_VOD && item.picRate==='9.16'"
        :effect-nav="clickCount"
        v-model:objDetail="item"
    />
    <HrdShorts v-else-if="item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_VOD && item.picRate==='9.16'" :effect-nav="clickCount" v-model:objDetail="item" :shortsItems="shortsItems"/>
    <HrdVod v-else-if="item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_VOD" :effect-nav="clickCount" v-model:objDetail="item"/>
    <HrdYoutube v-else-if="item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_YOUTUBE" :effect-nav="clickCount" v-model:objDetail="item"/>
    <HrdPdf v-else-if="item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_PDF" :effect-nav="clickCount" v-model:objDetail="item"/>
    <HrdScorm v-else-if="item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_ZIP" :effect-nav="clickCount" v-model:objDetail="item"/>
  </div>
</template>

<script>
import {useRoute} from 'vue-router';
import {computed, onMounted, ref, watch} from 'vue';
import {ACT_GET_HRDCLOUD_DETAIL, ACT_GET_HRDCLOUD_SHORT_DETAIL} from '@/store/modules/prep/prep';
import {
  cttTyCdDcds,
  getHrdCloudLatestLrnObjDtlDistSn,
  getTop1CategoryByLevel,
  makeTags,
  showAlertAndGoBack,
} from '@/assets/js/modules/hrd/hrd-common';
import {useStore} from 'vuex';
import HrdScorm from '@/components/hrdcloud/pages/HrdScorm';
import HrdVod from '@/components/hrdcloud/pages/HrdVod';
import HrdYoutube from '@/components/hrdcloud/pages/HrdYoutube';
import HrdPdf from '@/components/hrdcloud/pages/HrdPdf';
import {lengthCheck, stringCheck} from '@/assets/js/util';
import HrdShorts from "@/components/hrdcloud/pages/HrdShorts.vue";
import HrdMobileShortsNativeLauncher from "@/components/hrdcloud/mobile/page/HrdMobileShortsNativeLauncher.vue";
import {isInApp} from "@/assets/js/navigationUtils";
export default {
  name: 'HrdCloudContentBridge',
  components: {HrdMobileShortsNativeLauncher, HrdShorts, HrdScorm, HrdVod, HrdPdf, HrdYoutube},
  setup() {
    const store = useStore(), route = useRoute();
    const lrnObjDtlDistSn = computed(() => route.params.lrnObjDtlDistSn);
    const item = ref({});
    const reload = ref(false);

    const shortsItems = ref([]);

    const getItem = async () => {
      if(!lrnObjDtlDistSn.value){
        return;
      }
      try {
        await getHrdCloudLatestLrnObjDtlDistSn(lrnObjDtlDistSn.value);

        const res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_DETAIL}`, {lrnObjDtlDistSn: lrnObjDtlDistSn.value});

        if (lengthCheck(res)) {
          item.value = res.items.map(x => {
            return {
              ...x,
              ...getTop1CategoryByLevel(x.cloudKeyLst),
              tag: makeTags(x.tag)
            };
          })[0];

          if (!stringCheck(item.value.etnUrl)) {
            showAlertAndGoBack(null, "HrdCloud");
          }

          //shorts확인
          if(item.value.picRate){
            if(item.value.picRate=="9.16"){
              // shortsItems.value.push(item);
              //shorts 목록 조회
              reload.value = true;
              const shortsRes = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_SHORT_DETAIL}`, {lrnObjDtlDistSn: lrnObjDtlDistSn.value});

              if (lengthCheck(shortsRes)) {
                shortsItems.value = shortsRes.items
                console.log("shortsRes", shortsRes.items)
              }
              reload.value = false;
            }
          }
        } else {
          showAlertAndGoBack(null, "HrdCloud");
        }
      } catch {
        showAlertAndGoBack(null, "HrdCloud");
      }
    };

    const clickCount = ref(0);
    const plusNavigation = () => {
      clickCount.value++;
    };

    watch(() => lrnObjDtlDistSn.value, () => {
      console.log("lrnObjDtlDistSn check ", lrnObjDtlDistSn.value)
      reload.value = !reload.value;
      if(lrnObjDtlDistSn.value > 0) getItem();
    });

    watch(() => reload.value, () => {
      console.log("reload check ", reload.value)
      const tm = setTimeout(() => {
        reload.value = false;
        clearTimeout(tm);
      }, 500);
    });

    onMounted(getItem)

    return {
      cttTyCdDcds,
      item, shortsItems,
      plusNavigation, clickCount,
      reload,
      isInApp
    }
  }
};
</script>
