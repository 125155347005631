<template>
  <div class="myssl-top" >
    <div class="top-my">
      <div class="my-avatar">
        <div class="avatar">
          <SSLProfileImg :target="lrner"/>
        </div>
      </div>
<!--      <div class="my-title">-->
<!--        <h2 class="title">-->
<!--          <img src="@/assets/lxp/images/ssl/myssl/ic_myssl.svg" alt/>-->
<!--        </h2>-->
<!--      </div>-->

<!--      <div class="my-category" :class="categoryClass">-->
<!--        <span class="category">{{ mainName }}</span>-->
<!--      </div>-->
      <div class="my-name">
        <h3 class="nickname mb-2">{{lrner.chnNm}}</h3>
        <div class="flex" style="height:50px;display:flex; justify-content: space-evenly">
          <span class="name" style="margin-right: 5px">{{ lrner.lrnerNm }}</span>
          <span class="name text-muted">{{ lrner.deptNm }}</span>
<!--          <div style="margin-left:5px; margin-top: -5px">-->
<!--            <SSLLearnerSubscribeSm :lrner="lrner"/>-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <div style="margin-top: -32px;" class="mb-2">
      <SSLLearnerSubscribe :lrner="lrner"/>
    </div>

    <SSLLearnerRoute>
<!--      <template v-slot:page-name>-->
<!--        <div style="width:12%; text-align: left;">-->
<!--          <h3 class="tab-text text-dark mt-3" style="font-family: var(&#45;&#45;kb-font-KBFGDisplayB);font-size: 24px;font-weight: 500;color: var(&#45;&#45;bs-heading-color);line-height: 1.2;">SSL Studio</h3>-->
<!--        </div>-->

<!--      </template>-->
    </SSLLearnerRoute>

  </div>
</template>

<script>
// import {getCategoryClass} from '@/assets/js/modules/ssl/ssl-common';
// import {useRoute} from 'vue-router';
// import {computed} from 'vue';
import SSLLearnerRoute from '@/components/ssl/learner/SSLLearnerRoute';
import SSLProfileImg from '@/components/ssl/common/SSLProfileImg';
// import SSLLearnerSubscribeSm from "@/components/ssl/learner/SSLLearnerSubscribeSm";
import SSLLearnerSubscribe from "@/components/ssl/learner/SSLLearnerSubscribe";

export default {
  name: 'SSLLearnerTop',
  props: {
    mainName: {
      type: String,
      default:null
    },
    lrner: Object,
    isNotMine: Boolean
  },
  components: {
    SSLLearnerSubscribe,
    SSLProfileImg,
    SSLLearnerRoute
  },
  setup() {
    // const route = useRoute();
    // const categoryClass= computed(() => getCategoryClass(route.params.distCrseCd));

    return {
      // categoryClass,
    }


  }
}
</script>
