<template>
<div id="kb-common-board" v-if="!isLoading&&boardMst">
<!--  <div class="main-content min-component" style="padding-bottom:20px !important;" >-->
        <section v-if="boardMst.boardTopCn" class="content-section segment-box-outline " style="margin-top:30px;">
          <div v-html="boardMst.boardTopCn" class="px-sm-3"></div>
        </section>

        <board-main-list v-if="boardMst.boardLayoutCd==='mainList'" :board-mst-map="boardMst" :board-cate-list="boardCateList" :is-oper="isOper" :post-grp-no="postGrpNo" />
        <board-timeline v-if="boardMst.boardLayoutCd === 'timeline'" :board-mst-map="boardMst" :board-cate-list="boardCateList" :is-oper="isOper" :post-grp-no="postGrpNo" />
        <board-thumb-list v-if="boardMst.boardLayoutCd === 'thumbList'" :board-mst-map="boardMst" :board-cate-list="boardCateList" :is-oper="isOper" :post-grp-no="postGrpNo"  />
        <board-group-list v-if="boardMst.boardLayoutCd === 'groupList'" :board-mst-map="boardMst" :board-cate-list="boardCateList" :is-oper="isOper" :post-grp-no="postGrpNo"  />

        <section v-if="boardMst.boardBtmCn" class="content-section section-margin segment-box-outline ck-content">
          <div v-html="boardMst.boardBtmCn" class="px-sm-3"></div>
        </section>
</div>
  <LoadingDiv v-if="isLoading"/>
  <div id="kb-common-board" v-if="boardMst==null">
    <div  class="main-content min-component">
      <section  class="segment-box my-2 ck-content">
        <div class="view-component" >
          게시판이 활성화 되지 않았습니다. 관리자에게 문의해주세요.
        </div>
      </section>
    </div>
  </div>

</template>

<script>
import {onMounted, ref, watch} from "vue";
import {useStore} from 'vuex';
import {useRoute} from "vue-router";
import {useAlert} from "@/assets/js/modules/common/alert";
import LoadingDiv from '@/components/common/LoadingDiv';
import boardMainList from '@/components/board/BoardMainList';
import boardTimeline from '@/components/board/BoardTimeline';
import boardThumbList from "@/components/board/BoardThumbList";
import boardGroupList from "@/components/board/BoardGroupList";
import {
  checkIsOper,
  exitBoardPage,
  getBoardCateList,
  getBoardMstInfo,
  validTarget
} from "@/assets/js/modules/board/board-common";



export default {
  name: 'boardMst',
  components: { boardMainList, boardTimeline, boardThumbList, boardGroupList,LoadingDiv},
  props:{
    boardId: String
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const {showMessage} = useAlert();
    // const boardId =  ref(props.boardId);
    const boardMst = ref({   })
    const postGrpNo = ref(boardMst.value.postGrpNo);

    const boardCateList = ref([]);
    const isOper = ref(false);
    const isLoading = ref(true);


    const checkValidAccess =  () => {
       checkIsOper({boardMstSn: boardMst.value.boardMstSn, userSn: store.state.auth.session.userSn}, isOper, () => {
        if(!validTarget(boardMst.value.postViewTrgt, isOper.value)){
          showMessage('게시글 조회 권한이 없습니다.', () => {exitBoardPage()});
        }else{
          isLoading.value = false;
        }
      });
    }

    // 게시판 정보
    const  getBoardMst =  () => {
      isLoading.value = true;
      // console.log(props.boardId));
       getBoardMstInfo(props.boardId, boardMst, async () => {
         if(boardMst.value!= null) {
           if (boardMst.value.postGrpNo) {
             // 글쓰기 버튼 숨김
             boardMst.value.postWrtYn = 'N';
           }
           getBoardCateList(boardMst.value.boardMstSn, boardCateList, checkValidAccess);
         }else{
           isLoading.value=false;
         }
      });
    }

    onMounted(()=>{
      getBoardMst();
    })


    watch(() => props.boardId, () => {
      getBoardMst();
    });




    return{
      boardMst,
      boardCateList,
      isOper,
      postGrpNo,
      isLoading,
      showHistoryBack: route.query.hisback == '1' ? true : false,
    }

  },
}
</script>