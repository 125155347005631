<template>
  <LxpMobileHeader title="그룹러닝">
    <template v-slot:right>
      <div class="util util-more">
        <div class="dropdown" :class="{'is-active' : toggleGuide}" data-offset="header-right">
          <button class="dropdown-btn" @click="clickGuide"><i class="icon-more"></i></button>
          <div class="dropdown-target">
            <div class="dropdown-box">
              <ul class="dropdown-option-list">
                <li class="dropdown-option-item">
                  <file-down-link :file-key="guideFileKey" :add-link-class="['dropdown-option-link']" :add-text-class="['dropdown-option-text']" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main ssl" id="kb-prep-group">
    <!-- main-content -->
    <div class="main-content">
      <div class="main-header main-header-visual mb-3">
        <div class="header-bg" :style="{'background-image': `url(${bgImgUrl})`}">
          <div class="header-title"><h2 class="title">그룹러닝</h2></div>
        </div>
      </div>
      <section class="content-section section-padding">
        <header class="section-header">
          <h4 v-if="paging.totalCount > 0" class="title">
            내 그룹
          </h4>
        </header>
        <!-- learningGroup-container -->
        <div class="learningGroup-container">
          <MobileGroupCard v-if="paging.totalCount > 0" :items="items" :is-all="true"/>
          <!-- learningGroup-list -->
          <ul v-else class="learningGroup-list">
            <!-- learningGroup-item -->
            <li class="learningGroup-item">
              <div class="learningGroup-empty">
                <p class="text">현재 참여중인 그룹이 없습니다.</p>
                <router-link :to="{name: 'GroupApplication'}" class="kb-btn kb-btn-content-item kb-btn-primary">신청하기</router-link>
              </div>
            </li>
            <!-- //learningGroup-item -->
          </ul>
          <!-- //learningGroup-list -->
        </div>
        <!-- //learningGroup-container -->
      </section>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import {groupLearningSetup} from "@/assets/js/modules/groupLearing/group-learning-setup";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import MobileGroupCard from "@/components/prep/group/mobile/MobileGroupCard";
import FileDownLink from "@/components/common/FileDownLink";

export default {
  name: "MobileGroupLearning",
  components: {MobileGroupCard, LxpMobileHeader, FileDownLink},
  setup: groupLearningSetup
}
</script>