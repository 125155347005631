<template>
<!--  is-visible-->
  <span class="ic_myssl" :style="style">
      <svg version="1.1" id="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 62 20" style="enable-background:new 0 0 62 20;" xml:space="preserve">
          <g id="" transform="translate(-928.000000, -262.000000)">
              <g id="Banner" transform="translate(0.000000, 72.000000)">
                  <g id="Group-4" transform="translate(887.000000, 172.000000)">
                      <g id="my-SSL" transform="translate(41.512817, 18.798000)">
                          <path id="Path" class="st0" d="M1.5,7.3L0,14.2h2.8l1.1-5.4C4,8.4,4.2,8,4.4,7.6S4.8,7,5,6.8s0.5-0.3,0.8-0.3
                              c0.3,0,0.6,0.1,0.7,0.4s0.2,0.6,0.1,1.1l-1.3,6.2H8l1.1-5.3C9.3,8.4,9.4,8,9.6,7.7C9.8,7.3,10,7,10.3,6.8s0.5-0.3,0.8-0.3
                              c0.3,0,0.6,0.1,0.7,0.4s0.2,0.7,0.1,1.2l-0.8,4c-0.2,0.8-0.1,1.3,0.1,1.7s0.7,0.6,1.3,0.6c0.5,0,1-0.1,1.5-0.3s0.8-0.5,1.1-0.8
                              l0.2-0.8c-0.1,0.1-0.3,0.2-0.5,0.2s-0.3,0.1-0.5,0.1c-0.2,0-0.4-0.1-0.4-0.2s-0.1-0.4,0-0.7l0.8-3.7c0.2-1,0.1-1.8-0.2-2.3
                              s-1-0.8-1.9-0.8c-0.5,0-0.9,0.1-1.3,0.3s-0.8,0.5-1.1,0.9S9.5,7.1,9.4,7.6c0,0,0,0,0,0s0,0,0,0c0.1-0.5,0-0.9-0.1-1.2
                              S8.9,5.6,8.6,5.4S7.8,5.1,7.2,5.1c-0.7,0-1.3,0.2-1.8,0.6s-1,1-1.3,1.8l0.5-2.3l-4,0.5L0.4,6.4L1,6.5c0.2,0.1,0.4,0.1,0.4,0.2
                              S1.5,7.1,1.5,7.3z"></path>
                          <path id="Path_00000063594447483958977610000008775626529808297109_" class="st0" d="M19.9,5.1l-3.7,0.5l-0.2,0.8l0.6,0.1
                              c0.2,0.1,0.4,0.1,0.5,0.2s0.1,0.3,0.2,0.5l0.6,8c-0.2,0.2-0.5,0.4-0.9,0.7c-0.3,0.3-0.7,0.5-1,0.8s-0.7,0.5-1.1,0.7
                              s-0.7,0.5-1,0.6v0.2h2.9c0.4-0.5,0.8-0.9,1.1-1.3s0.7-0.8,1.1-1.2c0.2-0.2,0.4-0.5,0.6-0.7s0.4-0.5,0.6-0.7s0.4-0.5,0.6-0.7
                              c0.9-1.1,1.6-2.1,2.2-3s1-1.7,1.3-2.4s0.4-1.2,0.4-1.6c0-0.5-0.2-0.8-0.4-1.1S23.7,5,23.2,5c-0.4,0-0.8,0.1-1,0.4
                              s-0.4,0.6-0.4,1c0,0.4,0.1,0.7,0.4,1s0.6,0.4,1,0.4c0.4,0,0.7-0.1,1-0.3s0.4-0.5,0.4-0.9l-1,0c0,0.6-0.2,1.2-0.5,1.9
                              s-0.7,1.4-1.1,2.1s-1,1.5-1.7,2.3L19.9,5.1z"></path>
                          <path id="Path_00000041282539710358017020000011841565919035478428_" class="st0" d="M30.5,12.1c0.5,0.1,1,0.2,1.6,0.3
                              c0.6,0.1,1.1,0.1,1.7,0.1c1,0,1.7-0.2,2.1-0.5c0.4-0.3,0.6-0.8,0.6-1.4c0-0.5-0.1-0.9-0.3-1.2c-0.2-0.3-0.6-0.6-1.1-1
                              c-0.6-0.4-1.2-0.7-1.7-1.1c-0.5-0.3-1-0.6-1.4-0.9c-0.6-0.4-1-0.8-1.3-1.3c-0.3-0.5-0.4-1.1-0.4-1.7c0-0.6,0.1-1,0.3-1.5
                              s0.5-0.8,0.9-1.1s0.9-0.5,1.4-0.7S34.1,0,34.7,0c0.3,0,0.6,0,0.9,0c0.4,0,0.7,0.1,1.1,0.1c0.4,0.1,0.7,0.1,1.1,0.2
                              s0.7,0.1,0.9,0.2l-0.3,1.7c-0.2,0-0.5-0.1-0.8-0.1s-0.6-0.1-1-0.1c-0.3,0-0.7-0.1-1-0.1s-0.6,0-0.9,0c-0.6,0-1,0.1-1.3,0.4
                              S33,2.9,33,3.4c0,0.4,0.1,0.7,0.3,1s0.5,0.4,0.8,0.7c0.3,0.3,0.8,0.5,1.3,0.8c0.5,0.3,1,0.6,1.5,0.9c0.3,0.2,0.7,0.5,1,0.8
                              c0.3,0.3,0.5,0.6,0.8,0.9c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.8,0.2,1.2c0,0.6-0.1,1.1-0.4,1.6c-0.2,0.5-0.6,0.9-1.1,1.2
                              c-0.5,0.3-1,0.6-1.7,0.8c-0.7,0.2-1.5,0.3-2.4,0.3c-0.3,0-0.6,0-0.9-0.1c-0.3,0-0.7-0.1-1-0.2c-0.3-0.1-0.6-0.1-1-0.2
                              c-0.3-0.1-0.6-0.2-0.8-0.3L30.5,12.1z"></path>
                          <path id="Path_00000175282210066654536400000006532456446620522663_" class="st0" d="M41.4,12.1c0.5,0.1,1,0.2,1.6,0.3
                              c0.6,0.1,1.1,0.1,1.7,0.1c1,0,1.7-0.2,2.1-0.5c0.4-0.3,0.6-0.8,0.6-1.4c0-0.5-0.1-0.9-0.3-1.2c-0.2-0.3-0.6-0.6-1.1-1
                              c-0.6-0.4-1.2-0.7-1.7-1.1c-0.5-0.3-1-0.6-1.4-0.9c-0.6-0.4-1-0.8-1.3-1.3c-0.3-0.5-0.4-1.1-0.4-1.7c0-0.6,0.1-1,0.3-1.5
                              s0.5-0.8,0.9-1.1c0.4-0.3,0.9-0.5,1.4-0.7S45,0,45.6,0c0.3,0,0.6,0,0.9,0s0.7,0.1,1.1,0.1c0.4,0.1,0.7,0.1,1.1,0.2
                              s0.7,0.1,0.9,0.2l-0.3,1.7c-0.2,0-0.5-0.1-0.8-0.1s-0.6-0.1-1-0.1c-0.3,0-0.7-0.1-1-0.1S46,2,45.7,2c-0.6,0-1,0.1-1.3,0.4
                              s-0.5,0.6-0.5,1.1c0,0.4,0.1,0.7,0.3,1s0.5,0.4,0.8,0.7c0.3,0.3,0.8,0.5,1.3,0.8c0.5,0.3,1,0.6,1.5,0.9c0.3,0.2,0.7,0.5,1,0.8
                              c0.3,0.3,0.5,0.6,0.8,0.9c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.8,0.2,1.2c0,0.6-0.1,1.1-0.4,1.6c-0.2,0.5-0.6,0.9-1.1,1.2
                              c-0.5,0.3-1,0.6-1.7,0.8c-0.7,0.2-1.5,0.3-2.4,0.3c-0.3,0-0.6,0-0.9-0.1c-0.3,0-0.7-0.1-1-0.2c-0.3-0.1-0.6-0.1-1-0.2
                              c-0.3-0.1-0.6-0.2-0.8-0.3L41.4,12.1z"></path>
                          <path id="Path_00000005951114778443579950000005781044416498395782_" class="st0" d="M55,0.3v10.9c0,0.6,0.4,0.9,1.1,0.9h4.7
                              v1.8c-0.8,0.1-1.7,0.2-2.5,0.2c-0.9,0-1.7,0.1-2.4,0.1c-1.2,0-2.1-0.2-2.7-0.7c-0.5-0.5-0.8-1.1-0.8-2.1V0.3H55z"></path>
                      </g>
                  </g>
              </g>
          </g>
      </svg>

  </span>
</template>
<script>

export default {
  name: 'SSLMySvg',
  props:{
    style:{
      type:String,
      default:'margin-top: 5px;'
    }
  }
}
</script>
