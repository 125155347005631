<template>
  <div class="hrdcloud-list-container">
    <slot name="top" />
    <LoadingDiv v-if="isLoading"/>
    <template v-else>
      <HrdCloudRow v-if="result && result.length > 0" :session="session"
                   :items="result"
                   :show-put="true"
                   :paging="paging"
                   :paging-func="pagingFunc"
                   :page-type="pageType"
                   :is-shorts="isShorts"
                   :data-grid="isShorts ? 6 : 4"
      />
      <div v-else-if="result && result.length === 0" class="search-container">
        <div :class="{ 'search-result' : isMobile}">
          <div class="result-empty">
            <img src="../../assets/lxp/images/common/img_empty.png" alt="">
            <p class="text">{{ emptyMsg }}</p>
          </div>
        </div>
      </div>

    </template>
    <!-- slot 에 숏츠 추가 -->
    <slot name="bottom" />
  </div>
</template>

<script>
import HrdCloudRow from '@/components/hrdcloud/HrdCloudRow';
import {useRoute} from 'vue-router';
import {onMounted, ref, watch} from 'vue';
import LoadingDiv from '@/components/common/LoadingDiv';
import navigationUtils from '@/assets/js/navigationUtils';

export default {
  name: 'HrdCloudList',
  components: {LoadingDiv, HrdCloudRow},
  props: {
    isLoading: Boolean,
    session: Object,
    result: Array,
    showPut: Boolean,
    paging: Object,
    pagingFunc: Function,
    pageType: {
      type: String,
      default: 'hrdcloud'
    },
    isShorts: Boolean,

  },
  setup() {
   const route = useRoute();
   const emptyMsg = ref('검색 결과가 없습니다.');

   const checkQuery = () => {
     if (route.query.keyword) emptyMsg.value = '검색 결과가 없습니다.';
     else emptyMsg.value = '콘텐츠가 없습니다.';
   };

   watch(() => route.query, () => {
     checkQuery();
   });

   onMounted(() => {
     checkQuery();
   });

   return {
     emptyMsg,
     isMobile: navigationUtils.any()
   }
  }
};
</script>