<template>
  <div class="is-active layer" @click="clickChecker" style="width:0px !important;height:0px !important;">chk</div>
  <header v-if="headerShow" class="kb-header d-print-none" id="kb-header">
    <div class="header-component">
      <!-- header-title -->
      <div class="header-column header-title" @click="func">
        <h1 class="title">
          <router-link :to="{name: 'Main'}" class="title-link" title="kb e-HRD"><span class="title-logo">kb e-HRD</span></router-link>
        </h1>
      </div>
      <!-- //header-title -->
      <!-- header-nav -->
      <lxp-header-menu @click="func"/>
      <!-- //header-nav -->
      <!-- header-util -->
      <div v-if="session.lrnerId" class="header-column header-util">
        <div class="util util-alarm">
          <router-link :to="{name:'TrainNotification'}" class="util-actions util-actions-alarm">
            <i class="icon-alarm"></i>
          </router-link>
        </div>
        <div class="util util-alarm">
          <router-link :to="{name:'LiveCalendarMng'}" class="util-actions util-actions-calender">
            <i v-if="notEndCnt > 0" class="icon-calender-active"></i>
            <i v-else  class="icon-calender"></i>
          </router-link>
        </div>
        <div class="util util-user">
          <button type="button" tabindex="2" class="util-actions util-actions-user" data-toggle="dropdown" data-toggle-target="util-dropdown-user" @click="toggleProfileMenu">
            <span class="user-avatar">
              <ProfileImg :target="session"/>
            </span>
          </button>
          <div class="util-dropdown util-dropdown-user" :class="{ 'is-active' : showProfileMenu }" data-toggle-el="util-dropdown-user">
            <ul class="dropdown-menu" style="width: 224px;">
              <li v-if="session.isAdmin" class="menu-item"><a href="javascript:" class="menu-link" @click="goAdminPage"><span class="menu-text">관리자 페이지로</span></a></li>
              <li v-if="session.isAdmin && session.isGeneralAdmin" class="menu-item"><a href="javascript:" class="menu-link" @click="goEdutorPage"><span class="menu-text">저작도구 페이지로</span></a></li>
              <template v-if="myMenus && myMenus.children">
                <template v-for="(item, idx) in myMenus.children" :key="idx">
                  <template v-if="item.menuNm === 'Talent Coaching Board'">
                    <li v-if="session.vice" class="menu-item">
                      <router-link :to="item.menuUrl" class="menu-link" @click="showProfileMenu = false"><span class="menu-text">{{item.menuNm}}</span></router-link>
                    </li>
                  </template>
                  <li v-else class="menu-item">
                    <template v-if="item.menuUrl && (item.menuUrl != '/my/ability' || session.viceMgrApptYmd == null) ">
                      <a v-if="isTempMenu(item)" href="javascript:"  class="menu-link"><span class="menu-text">{{item.menuNm}}</span></a>
                      <router-link v-else :to="item.menuUrl" class="menu-link" @click="showProfileMenu = false"><span class="menu-text">{{item.menuNm}}</span></router-link>
                    </template>
                  </li>
<!--                  <li v-if="item.menuNm === 'Talent Board' && session.vice" class="menu-item">-->
<!--                    <router-link to="/my/vice-talent" class="menu-link"><span class="menu-text">Talent Board(부점장)</span></router-link>-->
<!--                  </li>-->
                </template>
              </template>
              <li v-if="session.vice" class="menu-item"><a href="javascript:" class="menu-link" @click="goToAdmin"><span class="menu-text">우리부점 학습현황</span></a></li>
              <li v-if="session.trnMngr" class="menu-item"><a href="javascript:" class="menu-link" @click="goToAdmin"><span class="menu-text">부점 연수관리자</span></a></li>
              <li class="menu-item"><a href="javascript:" class="menu-link" @click="clickLogout"><span class="menu-text">로그아웃</span></a></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
</template>

<script>
import LxpHeaderMenu from '@/views/layout/lxp/header/LxpHeaderMenu';
import {computed, ref, watch} from 'vue';
import ProfileImg from '@/components/common/ProfileImg';
import {useStore} from 'vuex';
import {goToSignin} from '@/assets/js/modules/common/auth';
import {isTempMenu} from '@/assets/js/modules/common/common';
import {ACT_GET_ADMIN_AUTH, ACT_GET_CLICK, TOKEN_ADMKEY} from '@/store/modules/auth/auth';
import {isAdmin, setLogout} from '@/assets/js/modules/auth/auth-common';
import {saveToken} from '@/assets/js/localstorage.service';
import {getItems, isSuccess, lengthCheck, timestampToDateFormat} from '../../../../assets/js/util';
import {useRoute} from 'vue-router';
import {ACT_GET_MY_TRGT_LIST} from '@/store/modules/live/live';

export default {
  name: 'LxpHeader',
  components: {
    ProfileImg,
    LxpHeaderMenu
  },
  props: {
    effectNav: {
      required: false,
      type: Number,
      default: 0
    },
    func: {
      required: true,
      type: Function
    }
  },
  setup(props) {
    const store = useStore();
    const showProfileMenu = ref(false);
    const route = useRoute();
    isAdmin()
    const session = computed(() => store.state.auth.session);
    const myMenus = computed(() => {
      // return store.state.menu.menus.find(x => x.menuNm === 'MY');
      return store.state.menu.menus.find(x => x.menuNm === 'MY');
    });
    const notEndCnt = ref(0); // 입장가능 카운트
    const nowList = ref([]);

    const headerShow = computed(() => {
      if( route.query.nohead && route.query.nohead === 'only' ) {
        return false;
      } else {
        if(store.state.auth.isHeaderShow !== 1) {
          return true;
        } else {
          return false;
        }
      }
    });


    // 내부SSO 관련 설정 초기화
    window.localStorage.removeItem('kbssofailno')
    // const getMyMenu = () => {
    //   store.dispatch(`menu/${ACT_GET_ROLE_MENU}`,{stt: '11'}).then(res => {
    //     if(lengthCheck(res)){
    //       const items = getItems(res).filter(x => x.menuNm === 'MY');
    //       if(items.length > 0) myMenus.value = items[0];
    //       console.log(myMenus.value);
    //     }
    //   });
    // };

    const toggleProfileMenu = () => {
      showProfileMenu.value = !showProfileMenu.value;
    };

    const goToAdmin = () => {

      store.dispatch(`auth/${ACT_GET_ADMIN_AUTH}`).then(res => {
        if( isSuccess(res) && res.adminToken) {
          saveToken(TOKEN_ADMKEY , res.adminToken)
          const windowOption = 'scrollbars=0,resizable=0,top=0,left=' + window.screenLeft + ',width=1800,height=900'
          const popUpObj = window.open( `${process.env.VUE_APP_ADMIN_URL}modal/sso?sso=${res.sso}` , '', windowOption);
          if (popUpObj === null || popUpObj === undefined){
            alert('차단된 팝업창을 허용해 주십시오.');
          }else{
            popUpObj.focus();
          }

        } else {
          alert('차단된 팝업창을 허용해 주십시오.')
        }
      }).catch(e => {
        console.error(e)
        alert('관리자 페이지로 이동할 수 없습니다.')
      })
    }

    const goAdminPage = () => {
      showProfileMenu.value = false

      store.dispatch(`auth/${ACT_GET_ADMIN_AUTH}`).then(res => {
        if( isSuccess(res) && res.adminToken) {
          saveToken(TOKEN_ADMKEY , res.adminToken)
          const openNewWindow = window.open("about:blank");
          openNewWindow.location.href = '/admin/'
        } else {
          alert('관리자 페이지로 이동할 수 없습니다.')
        }
      }).catch(e => {
        console.error(e)
        alert('관리자 페이지로 이동할 수 없습니다.')
      })
    }

    const goEdutorPage = () => {
      showProfileMenu.value = false

      store.dispatch(`auth/${ACT_GET_ADMIN_AUTH}`).then(res => {
        if( isSuccess(res) && res.adminToken) {
          saveToken(TOKEN_ADMKEY , res.adminToken)
          const openNewWindow = window.open("about:blank");
          if(process.env.NODE_ENV === 'local'){
            openNewWindow.location.href = `http://localhost:18080/sso?userId=${session.value.lrnerId}`
          }else{
            openNewWindow.location.href = `/edutor/sso?userId=${session.value.lrnerId}`
          }
        } else {
          alert('관리자 페이지로 이동할 수 없습니다.')
        }
      }).catch(e => {
        console.error(e)
        alert('관리자 페이지로 이동할 수 없습니다.')
      })
    }

    const clickLogout = async () => {
      // saveToken('isLogout', 'true');
      await setLogout(true);
      goToSignin();
    }

    watch(() => props.effectNav, () => {
      if (showProfileMenu.value) showProfileMenu.value = false;
    });

    // onMounted(() => {
    //   getMyMenu();
    // });

    // 메뉴 우상단 라이브편성표 아이콘 활성화 체크위해 나우목록 조회
    const getCloudNowList = () => {
      let objBgngDt = timestampToDateFormat(new Date().getTime(), 'yyyy-MM-dd hh:mm:ss');
      store.dispatch(`live/${ACT_GET_MY_TRGT_LIST}`, {
        startDt: objBgngDt,
        objType: '100008',
        lrnerId: session.value.lrnerId
      }).then(res => {
        if (lengthCheck(res)) {
          nowList.value = getItems(res);
          nowList.value.filter(v => {
            if(v.objEndYn === 'N'){
              notEndCnt.value++;
            }
          })
        }else{
          nowList.value = [];
          notEndCnt.value = 0;
        }
      }).catch(e=>{
        console.error(e);
      })
    }
    getCloudNowList();

    let clickCnt=0;
    const clickChecker = () =>{
      clickCnt++;
      if((clickCnt%3)==0){
        store.dispatch(`auth/${ACT_GET_CLICK}`);
      }
    }

    return {
      showProfileMenu,
      headerShow,
      session,
      myMenus,
      nowList,
      notEndCnt,
      toggleProfileMenu,
      goToSignin,
      goToAdmin,
      goAdminPage,
      goEdutorPage,
      isTempMenu,
      clickLogout,
      clickChecker
    }
  }
}
</script>
