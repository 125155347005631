<template>
  <SSLMobileView
      v-if="currentName === 'SSLView'"
      @acted="acted"
  />

  <main v-else class="kb-main" id="ssl-home-v2" style="margin-bottom: 70px">
    <div class="main-content">
      <div class="ssl-body pb-0" :id="mainId">
        <SSLMobileSlotHeader
            :hide-title="toggles.member || toggles.search"
        >
          <template v-slot:title>
            <SSLMobileNav />
          </template>
          <template v-slot:left>
            <template v-if="toggles.member">
              <div class="util util-history-back" @click="closeMainToggle('member')">
                <a href="javascript:" class="util-actions util-actions-history-back">
                  <i class="icon-history-back"></i>
                </a>
              </div>
              <div class="util util-search-input">
                <input v-model.trim="memberKeyword" type="text" :placeholder="`${distCrseCd === 'group' ? '그룹' : '직원'} 검색`" @keyup.enter="searchMember">
              </div>
            </template>
<!--            <template v-else-if="toggles.search">-->
<!--              <div class="util util-search-input">-->
<!--                <div class="util util-history-back" @click="closeMainToggle('search')">-->
<!--                  <a href="javascript:" class="util-actions util-actions-history-back">-->
<!--                    <i class="icon-history-back"></i>-->
<!--                  </a>-->
<!--                </div>-->
<!--                <input v-model="keyword" type="text" :placeholder="`${mainName} 검색`" @keyup.enter="searchFunc">-->
<!--              </div>-->
<!--            </template>-->
            <div v-else class="util util-history-back">
              <router-link :to="{name: 'SSLHome'}" class="util-actions util-actions-history-back">
                <i class="icon-history-back"></i>
              </router-link>
            </div>
          </template>

          <template v-slot:right>
            <div v-if="toggles.member" class="util util-search" @click="searchMember">
              <a href="javascript:" class="util-actions util-actions-search">
                <i class="icon-search"></i>
              </a>
            </div>
<!--            <div v-else-if="toggles.search" class="util util-search" @click="searchFunc">-->
<!--              <a href="javascript:" class="util-actions util-actions-search">-->
<!--                <i class="icon-search"></i>-->
<!--              </a>-->
<!--            </div>-->

            <template v-else>
              <div v-if="!isNoneReward" class="util util-member">
                <a href="javascript:" class="util-actions-member" @click="toggles.member = true" style="display:flex;flex-direction:column;align-items: center;">
                  <i class="icon-member"></i>
                  <div style="font: normal 11px/16px var(--kb-font-KBFGDisplayB); padding-top: 2px;">
                    직원검색
                  </div>
                </a>
              </div>
              <div v-if="isGroup" class="util util-member mt-1">
                <router-link :to="{name: 'pubNotice', params:{expsrLoc:expsrLocCds.EXPSR_LOC_CD_SSL_GROUP}}">
                  <i class="icon-notice"></i>
                  <div style="font: normal 11px/16px var(--kb-font-KBFGDisplayB); padding-top: 2px;">
                    공지사항
                  </div>
                </router-link>
              </div>

<!--              <div class="util util-more">-->
<!--                <div class="dropdown" :class="{'is-active': toggles.nav}" data-offset="header-left">-->
<!--                  <button class="dropdown-btn" @click="toggles.nav = !toggles.nav"><i class="icon-more"></i></button>-->
<!--                  <div class="dropdown-target" style="width: 120px;left: -88px;">-->
<!--                    <div class="dropdown-box">-->
<!--                      <ul class="dropdown-option-list">-->
<!--                        <li class="dropdown-option-item">-->
<!--                          <SSLTrial-->
<!--                              trial-class="dropdown-option-link"-->
<!--                              text-class="dropdown-option-text"-->
<!--                          />-->
<!--                        </li>-->
<!--                        <li class="dropdown-option-item">-->
<!--                          <SSLManual-->
<!--                              manual-class="dropdown-option-link"-->
<!--                              text-class="dropdown-option-text"-->
<!--                          />-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </template>
          </template>
        </SSLMobileSlotHeader>

        <SSLMobileSubNav/>

        <template v-if="!toggles.member">
<!--          <SSLMobileMainNotice :notices="notices"/>-->
          <SSLMobileMainMy
              :main-name="mainName"
              :goMySSL="goMySSL"
          />
        </template>

        <div class="page-container">
          <SSLMobileMember
              v-if="toggles.member"
              :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
          />
          <SSLMobileBoardList
              v-else
              :is-loading="isLoading"
              :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
              :items="items"
              :paging="paging"
              :more-func="moreFunc"
              :show-filter="true"
              :view-modes="viewModes"
              :view-mode-idx="viewModeIdx"
              :open-search="() => {toggles.search = true;}"
              @moveBoard="moveBoard"
          >
            <template v-slot:loading-area>
              <template v-if="isLoading">
                <LoadingDiv v-model="isLoading" />
              </template>
            </template>
            <template v-slot:input-search>
              <div class="kb-form-search-forms">
                <div class="kb-form-search-v2">
                  <div class="kb-form-search-field"><input type="text" v-model.trim="keyword"
                                                           class="kb-form-search-input-v2"
                                                           @keydown.enter.prevent="searchFunc"
                                                           placeholder="어떤 콘텐츠를 찾으시나요?">
                    <button type="button" class="kb-form-search-btn" @click="searchFunc"><i class="icon-search">검색</i>
                    </button>
                    <button v-if="keyword.length > 0" type="button" class="kb-form-search-delete" @click="resetSearch"><i class="icon-delete">삭제</i></button>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:show-filter>
              <div class="list-header">
                <div class="list-tab d-flex">
                  <template v-for="(viewMode, idx) in viewModes" :key="idx">
                    <router-link :to="{query:{view: viewMode.mode}}"
                                 :class="{'is-active': idx === viewModeIdx}"
                    >
                      {{ viewMode.tag }}
                    </router-link>
                  </template>
                </div>
              </div>
              <div class="list-header mt-0">
                <div class="top-column" style="display:flex; justify-content: flex-start">
                  <!-- list-top > list-sort -->
                  <div class="sort" style="margin-right: 15px" v-if="isGroup">
                    <SortButtonSelect3
                        v-model="filters.distCrseLrnFldSn"
                        v-model:toggle="selToggles.crseDistFlds"
                        title="분야"
                        :options="crseDistFlds"
                        sequence-key="distCrseLrnFldSn"
                        name-key="distCrseLrnFldNm"
                        :is-all="false"
                        :width="200"
                        :textStyle="`color:#000;font:normal 14px/16px var(--kb-font-KBFGText)`"
                        @update:toggle="closeToggles(selToggles, 'crseDistFlds')"
                        @selected="getSocsBoardList(true);"
                    />
                  </div>
                  <!-- list-top > list-sort -->
                  <div class="sort" style="margin-right: 15px">
                    <SortButtonSelect3
                        v-model="filters.objType"
                        v-model:toggle="selToggles.objType"
                        title="모든 타입"
                        :options="typeOptions"
                        sequence-key="key"
                        name-key="value"
                        :is-all="true"
                        :width="130"
                        :textStyle="`color:#000;font:normal 14px/16px var(--kb-font-KBFGText)`"
                        @update:toggle="closeToggles(selToggles, 'objType')"
                        @selected="getSocsBoardList(true);"
                    />
                  </div>
                </div>

                <!--      <div class="list-search">-->
                <!--        <button class="kb-btn-search" @click="openSearchAndFocus">-->
                <!--          <i class="icon-search"></i>-->
                <!--        </button>-->
                <!--      </div>-->
              </div>

            </template>

          </SSLMobileBoardList>
        </div>
      </div>
    </div>
  </main>

</template>
<script>
import SSLMobileBoardList from '@/components/ssl/main/mobile/SSLMobileBoardList';
// import SSLMobileMainNotice from '@/components/ssl/main/mobile/SSLMobileMainNotice';
import SSLMobileMainMy from '@/components/ssl/main/mobile/SSLMobileMainMy';
import SSLMobileMember from '@/components/ssl/main/mobile/SSLMobileMember';
import SSLMobileView from '@/views/ssl/mobile/SSLMobileView';
import {sslMainSetup} from '@/assets/js/modules/ssl/setup/ssl-main-setup';
import SSLMobileSlotHeader from '@/components/ssl/main/mobile/SSLMobileSlotHeader';
// import SSLTrial from '@/components/ssl/common/SSLTrial';
// import SSLManual from '@/components/ssl/common/SSLManual';
import SSLMobileNav from '@/components/ssl/mobile/SSLMobileNav';
import SSLMobileSubNav from '@/components/ssl/mobile/SSLMobileSubNav';
import SortButtonSelect3 from "@/components/common/SortButtonSelect3.vue";
import LoadingDiv from "@/components/common/LoadingDiv"

export default {
  name: 'SSLMobileMain',
  components: {
    SortButtonSelect3,
    SSLMobileSubNav,
    SSLMobileNav,
    // SSLManual,
    // SSLTrial,
    SSLMobileSlotHeader,
    SSLMobileView,
    SSLMobileMember,
    SSLMobileMainMy,
    // SSLMobileMainNotice,
    SSLMobileBoardList,
    LoadingDiv,

  },
  setup: sslMainSetup
}
</script>
