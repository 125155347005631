import {useStore} from "vuex";
import {useAlert} from "@/assets/js/modules/common/alert";
import {computed, reactive, ref} from "vue";
import {dateToDatePart, getCalendar, isSuccess, timestampToDatePart} from "@/assets/js/util";
import {calendarKey, checkDayOn, checkTodayNotAttend, getMyInfo} from "@/assets/js/modules/ssl/ssl-common";
import {
    ACT_GET_REG_BOARD_CNT,
    ACT_INSERT_SOC_ACT,
    MUT_CLOSE_ATTENDANCE,
    MUT_SET_MY_ATTENDANCE, MUT_SHOW_BADGE
} from "@/store/modules/ssl/ssl-index";
import {holyDay} from "@/assets/js/holy-day";
import {actTyCdDcds} from "@/assets/js/modules/common/common";
import {getBadgeList} from "@/assets/js/modules/ssl/ssl-badge";
import {couponDays} from "@/assets/js/coupon-day";
import {saveToken} from "@/assets/js/localstorage.service";

export const sslCalendarSetup = () => {

    const store = useStore();
    const {showMessage} = useAlert();
    const isMobile = computed(() => store.state.auth.isMobile);
    const session = computed(() => store.state.auth.session);

    const currentDate = new Date();
    const currentDatePart = dateToDatePart(currentDate);
    const currentYear = ref(currentDate.getFullYear());
    const currentMonth = ref(currentDate.getMonth() + 1);
    const myAttends = computed(() => store.state.ssl.calendar.myAttends);
    const isDayOn = computed(() => checkDayOn(currentDatePart));
    const isNotTodayAttend = computed(() => checkTodayNotAttend(currentDatePart, myAttends.value))

    const today = reactive({
        year: currentDate.getFullYear(),
        month: (currentDate.getMonth() + 1),
        day: currentDate.getDate(),
    });
    const todayYmd = `${today.year}${('0' + today.month).slice(-2)}${('0' + today.day).slice(-2)}`;

    const attendTimeout = ref(null);
    const isLoading = ref(false);

    const currentWeeks = computed(() => getCalendar(currentYear.value, currentMonth.value).map(week => week.map((day, idx) => ({...day, isCouponDay: isCouponDay(day.ymd, idx)}))));
    const currentWeeks_new = computed(() => getCalendar(currentYear.value, currentMonth.value).map(week => week.map((day) => ({...day, isIncenDay: isIncenDay(day.ymd)}))));

    // console.log("currentWeeks : " + currentWeeks.value);

    const nextYearMonth = computed(() => {
        if(currentMonth.value === 12){
            return `${currentYear.value + 1}년 1월`
        }else{
            return `${currentYear.value}년 ${currentMonth.value + 1}월`
        }
    });
    const prevYearMonth = computed(() => {
        if(currentMonth.value === 1){
            return `${currentYear.value - 1}년 12월`
        }else{
            return `${currentYear.value}년 ${currentMonth.value - 1}월`
        }
    });

    //인센티브기간
    const incenPeriod = {
        0: {seq:0, sdt:'20250201', edt:'20250330', term:'2.01~3.30'}, //오픈 전...
        1: {seq:1, sdt:'20250331', edt:'20250427', term:'3.31~4.27'},
        2: {seq:2, sdt:'20250428', edt:'20250525', term:'4.28~5.25'},
        3: {seq:3, sdt:'20250526', edt:'20250620', term:'5.26~6.20'},
        4: {seq:0, sdt:'20250621', edt:'20250817', term:'6.21~8.17'}, //휴식기
        5: {seq:4, sdt:'20250818', edt:'20250914', term:'8.18~9.14'},
        6: {seq:5, sdt:'20250915', edt:'20251012', term:'9.15~10.12'},
        7: {seq:6, sdt:'20251013', edt:'20251107', term:'10.13~11.7'},
        8: {seq:7, sdt:'20251108', edt:'20260331', term:'11.08~03.31'} //과정종료
    };

    const nickNm = computed(()=>store.state.ssl.mySSLInfo.lrnerNickNm);
    if (nickNm.value == null || nickNm.value == ''){
        getMyInfo();
    }

    const lrnerNm = computed(()=> nickNm.value?nickNm.value:session.value.lrnerNm);

    const currSeq = Object.values(incenPeriod).filter(x=>x.sdt<= todayYmd && x.edt >= todayYmd ).map(y=>y.seq < 1?1:(y.seq==4?3:(y.seq>7?7:y.seq))).toString(); //인센티브 회차

    const currSDt = incenPeriod[currSeq].sdt;
    const currEdt = incenPeriod[currSeq].edt;
    const term = incenPeriod[currSeq].term;
    const incenAttendCnt = myAttends.value.filter(x => x.datePart.ymd >= currSDt && x.datePart.ymd <= currEdt).length; // 인센티브기간동안의 출석일수
    const regBoardCnt = ref(0);

    const getRegBoardCnt = () => {
        store.dispatch(`ssl/${ACT_GET_REG_BOARD_CNT}`, {
            lrnerId:'',
            regStartYmd: currSDt,
            regEndYmd: currEdt,
        }).then(res => {
            regBoardCnt.value = res.regBoardCnt;
            // console.log('regBoardCnt : ' + regBoardCnt.value);
        });
    }
    getRegBoardCnt();

    const isHolyDay = ({year, month, day}) => {
        return !!holyDay[`${year}${('0' + month).slice(-2)}${('0' + day).slice(-2)}`];
    }

    const getHolyDay = ({year, month, day}) => {
        return holyDay[`${year}${('0' + month).slice(-2)}${('0' + day).slice(-2)}`];
    }

    const isWeekend = (date) => {
        const week = ['일', '월', '화', '수', '목', '금', '토'];

        const dayOfWeek = week[new Date(date.ymdformat).getDay()];

        if(dayOfWeek === '토' || dayOfWeek === '일') return true;

        return holyDay[date.ymd];
        // return false;
    }

    const changeMonth = (isNext) => {
        if(isNext){
            if(currentMonth.value === 12){
                currentYear.value++;
                currentMonth.value = 1;
            }else{
                currentMonth.value++;
            }
        }else{
            if(currentMonth.value === 1){
                currentYear.value--;
                currentMonth.value = 12;
            }else{
                currentMonth.value--;
            }
        }
    }

    const attendSSL = () => {
        if(isLoading.value) return;
        isLoading.value = true;

        // const date = new Date();
        // const currentDatePart = dateToDatePart(date);
        //2025년 쓸부터 주말 및 공휴일 포함해서 출석일수 체크함
        // if(currentDatePart.dow === '일' || currentDatePart.dow === '토' || holyDay[`${currentDatePart.year}${currentDatePart.mm}${currentDatePart.dd}`]) {
        //   showMessage('주말 또는 공휴일의 경우<br>출석을 할 수 없습니다.');
        //   // store.commit(`common/${MUT_SHOW_MESSAGE}`, '주말 또는 공휴일의 경우<br>출석을 할 수 없습니다.');
        //   store.commit(`ssl/${MUT_CLOSE_ATTENDANCE}`);
        //   return;
        // }

        store.dispatch(`ssl/${ACT_INSERT_SOC_ACT}`, {
            socLrnPostSn: 0,
            actTyCdDcd: actTyCdDcds.ACT_TY_CD_ATTENDANCE,
        })
            .then(res => {
                if(isSuccess(res)){
                    store.commit(`ssl/${MUT_SET_MY_ATTENDANCE}`, {actTyCdDcd: actTyCdDcds.ACT_TY_CD_ATTENDANCE, lrnerId: session.value.lrnerId, datePart: timestampToDatePart(new Date().getTime())});
                    store.commit(`ssl/${MUT_CLOSE_ATTENDANCE}`);

                    showMessage({
                        text: '출석하셨습니다!',
                        callback: () => {
                            if(res.badges && res.badges.length > 0) {
                                getBadgeList();
                                store.commit(`ssl/${MUT_SHOW_BADGE}`, res.badges);
                            }
                        }
                    })
                }else{
                    showMessage('오류가 발생했습니다. 다시 시도해주세요.');
                    isLoading.value = false;
                }
            })
            .catch(() => {
                showMessage('오류가 발생했습니다. 다시 시도해주세요.');
                isLoading.value = false;
            });
    }

    const debounceAttend = () => {
        if(attendTimeout.value){
            clearTimeout(attendTimeout.value);
            attendTimeout.value = null;
        }
        attendTimeout.value = setTimeout(() => {
            attendSSL();
        }, 500);
    }

    const {showConfirm} = useAlert();

    const confirmAttend = () => {
        showConfirm({
            text : `출석하시겠습니까?`,
            callback: debounceAttend,
        })
    }

    const isCouponDay = (ymd, idx) => {
        if(idx === 5 || idx === 4){
            // 이미 획득...
            const filtered = couponDays.filter(x => (x.indexOf(ymd) > -1));
            if(filtered.length > 0){
                const lastDate = filtered[0][(filtered[0].length - 1)];
                if(ymd === lastDate){
                    // 1. 이미 지남...
                    if(lastDate < todayYmd){
                        if((myAttends.value.filter(x => (x.datePart.ymd >= filtered[0][0] && x.datePart.ymd <= lastDate) && filtered[0].includes(x.datePart.ymd)).length) >= filtered[0].length){
                            return 2;
                        }
                    }
                    // 2. 아직 안지남
                    else if(lastDate > todayYmd){
                        const index = filtered[0].indexOf(todayYmd);
                        if(myAttends.value.filter(x => (x.datePart.ymd >= filtered[0][0] && x.datePart.ymd <= lastDate)).length >= index){
                            return 1;
                        }
                    }
                    // 3. 당일
                    else if(lastDate === todayYmd){
                        const todayAttend = myAttends.value.filter(x => x.datePart.ymd === todayYmd);
                        const attendCount = myAttends.value.filter(x => (x.datePart.ymd >= filtered[0][0] && x.datePart.ymd <= lastDate)&& filtered[0].includes(x.datePart.ymd)).length;
                        // 오늘 출석한 정보가 있을 경우
                        if(todayAttend.length > 0){
                            if(attendCount >= filtered[0].length){
                                return 2
                            }
                        }else{
                            if(attendCount < (filtered[0].length )){
                                return 1;
                            }
                        }
                    }
                }
            }
        }
        return 0;
    }



    const isIncenDay=(ymd)=>{
        if(ymd >= currSDt && ymd <= currEdt){
            return 1;
        }else{
            return 0;
        }
    }


    const isActive = ({year, month, day}) => {
        return myAttends.value
                  .filter(x => x.datePart.year === year && x.datePart.month === month && x.datePart.day === day &&
                          x.datePart.ymd >= '20250331' && x.datePart.ymd <= '20251107'
                               // x.datePart.ymd >= currSDt && x.datePart.ymd <= currEdt
                  )
                  .length > 0;
    }

    const closeCalendar = () => {
        store.commit(`ssl/${MUT_CLOSE_ATTENDANCE}`);
    }

    const closeCalendarAndExpire = () => {
        saveToken(calendarKey, todayYmd);
        closeCalendar();
    }


    return {
        isMobile,
        isDayOn,
        isNotTodayAttend,

        currentYear,
        currentMonth,
        today,
        currentWeeks,
        nextYearMonth,
        prevYearMonth,
        isHolyDay,
        getHolyDay,
        changeMonth,
        confirmAttend,
        isActive,
        closeCalendar,
        closeCalendarAndExpire,
        isCouponDay,
        isWeekend,
        currSeq,
        lrnerNm,
        incenAttendCnt,
        regBoardCnt,
        currentWeeks_new,
        term
    }

}