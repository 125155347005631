<template>
  <div class="top-tabs flex">
    <slot name="page-name"></slot>
    <div style="display:flex;justify-content: center"> <!--  :style="{'width:76%;':!isMobile}" -->
      <router-link
          v-for="(item, idx) in items"
          :to="item.to" :key="idx"
          class="tab" :class="{'is-active': idx === activeIdx}"
      >
        <span class="tab-text">{{ item.name }}</span>
      </router-link>
    </div>
<!--    <div v-if="!isMobile" style="width:12%">&nbsp;</div>-->
  </div>

</template>

<script>

import {computed, ref} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";

export default {
  name: 'SSLLearnerRoute',
  setup(){
    const route = useRoute();
    const store = useStore();

    const activeIdx = computed(() => {
      if(route.name === 'SSLLearner'){
        return 0
      }else if(route.name === 'SSLLearnerBoard'){
        return 1;
      }
      return 0;
    });

    const isMobile = computed(() => store.state.auth.isMobile);



    const items = ref([
      {to: {name: 'SSLLearner'}, name: '홈'},
      {to: {name: 'SSLLearnerBoard'}, name: '게시글'},
      // {to: {name: 'SSLLearnerBoard'}, name: '동영상/PDF'},
    ]);
    // const items = ref([
    //   {to: {name: 'SSLLearner'}, name: '학습현황'},
    //   {to: {name: 'SSLLearnerBoard'}, name: '등록완료'},
    // ]);

    return {
      items,
      activeIdx,
      isMobile
    }
  }
}
</script>
