<template>
  <div class="main-header">
    <div class="header-snb">
      <nav class="snb">
        <ul class="snb-list">
          <li v-for="(category, idx) in category2" class="snb-item"
              :class="{ 'is-active' : category.hrdCloudCateSn == selValue }" :key="idx">
            <button @click="handleClick(category)">{{category.hrdCloudCateNm}}
              <i v-if="hasChild(category.hrdCloudCateSn)" class="icon-arrow-bottom-gray"></i>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, reactive, toRefs, watch, ref} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {pushQuery} from '@/assets/js/ui.init';
import {setParamsByQuery} from '@/assets/js/util';

export default {
  name: 'HrdCloudMobileCateSnb',
  props: {
    modelValue: Object,
    showHrdCloudCate: Boolean,
  },
  emits: ['update:modelValue','update:showHrdCloudCate'],
  // eslint-disable-next-line no-unused-vars
  setup(props, {emit}) {
    const store = useStore(), route = useRoute();
    const {modelValue} = toRefs(props);

    const params = reactive({
      keyword: '',
      sortTyCdDcd: '',
      category1: 0,
      category2: 0,
      category3: 0,
      pageNo: 0,
    });

    const categories = computed(() => store.state.hrdcloud.hrdcloudCates);
    const category2 = ref([]);
    const selValue = ref('');

    const getCurrentCategories = () => {
      if (modelValue.value.category4) {
        category2.value = categories.value.filter(x => x.lvl === 4 && x.upHrdCloudCateSn === Number(modelValue.value.category3) && x.stt === '00');
        selValue.value = modelValue.value.category4;
      } else if (modelValue.value.category3) {
        category2.value = categories.value.filter(x => x.lvl === 3 && x.upHrdCloudCateSn === Number(modelValue.value.category2) && x.stt === '00');
        selValue.value = modelValue.value.category3;
      } else {
        category2.value = categories.value.filter(x => x.lvl === 2 && x.upHrdCloudCateSn === Number(modelValue.value.category1) && x.stt === '00');
        selValue.value = modelValue.value.category2;
      }
    };

    if (categories.value) {
      getCurrentCategories();
    }

    // console.log("category2.value = ", category2.value);
    watch(() => categories.value, () => {
      getCurrentCategories();
    });

    watch(() => route.query, () => {
      getCurrentCategories();
    });


    const hasChild = (cate2Sn) => {
      if (route.query.category5) {
        return categories.value.find(x => x.lvl === 6 && x.upHrdCloudCateSn === Number(cate2Sn) && x.stt === '00')
      }else if (route.query.category4) {
        return categories.value.find(x => x.lvl === 5 && x.upHrdCloudCateSn === Number(cate2Sn) && x.stt === '00')
      } else if (route.query.category3) {
        return categories.value.find(x => x.lvl === 4 && x.upHrdCloudCateSn === Number(cate2Sn) && x.stt === '00')
      } else {
        return categories.value.find(x => x.lvl === 3 && x.upHrdCloudCateSn === Number(cate2Sn) && x.stt === '00')
      }
    }

    const handleClick = (cate) => {
      if (cate.hrdCloudCateSn != selValue.value) {
        let query = {};
        let cates = cate.hrdCloudCateSnLst.split(',');
        for (let idx in cates) {
          query[`category${Number(idx) + 1}`] = cates[idx];
        }
        pushQuery(query);
      }
      if (hasChild(cate.hrdCloudCateSn)) emit('update:showHrdCloudCate', !props.showHrdCloudCate);
    };

    onMounted(() => {
      setParamsByQuery(route, params, true);
    });

    return {
      category2,
      handleClick,
      hasChild,
      selValue
    }
  }
};
</script>