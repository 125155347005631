<template>
  <div class="learningGroup-my">
    <div class="my-avatar">
      <div class="image">
        <SSLProfileImg :target="targetId" />
      </div>
    </div>
    <div class="my-content">
      <h3 class="title"><i class="icon-title-my-group"></i></h3>
      <h4 class="subtitle subtitle-indigo">
        <template v-if="subTitle">
          {{ subTitle }}
        </template>
        <template v-else>
          그룹러닝 | {{ distCrseLrnFldNm }}
        </template>
        <template v-if="crseNm">
          | {{ crseNm }}
        </template>
      </h4>
<!--      <div class="top-column">-->
<!--      &lt;!&ndash; list-top > list-sort &ndash;&gt;-->
<!--        <div class="list-top">-->
<!--          <div class="list-sort">-->
<!--              <div class="sort">-->
<!--                <SortButtonSelect-->
<!--                    v-model="selDistCrseSn"-->
<!--                    v-model:toggle="relToggle"-->
<!--                    title="그룹을 선택해 주세요!"-->
<!--                    :options="items"-->
<!--                    sequence-key="distCrseSn"-->
<!--                    name-key="crseNm"-->
<!--                    button-class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg"-->
<!--                    :is-row="true"-->
<!--                    :is-num="true"-->
<!--                    @selected="changeThis"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      <p class="content"><span class="text">{{ session.lrnerNm }}</span> <span class="text text-muted">{{ session.deptNm }}</span></p>
    </div>
    <div class="my-links">
      <router-link :to="{name: 'SSLMy', params: {distCrseCd: distCrseCd, lrnTrgtGrpMstSn: lrnTrgtGrpMstSn}}" class="link"><i class="icon-my-ssl"></i></router-link>
      <a href="javascript:" class="link is-active">
        <i v-if="distCrseCd === 'group'" class="icon-my-group"></i>
        <i v-else-if="distCrseCd === 'knowledge'" class="icon-my-test"></i>
      </a>
    </div>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import SSLProfileImg from "@/components/ssl/common/SSLProfileImg";
import {useRoute, useRouter} from "vue-router";
import {enrollSSLAlert, isMyLrnTrgtGrpMstSn, noneRewardCds, sslRoutes} from "../../../assets/js/modules/ssl/ssl-common";
// import SortButtonSelect from "@/components/common/SortButtonSelect";

export default {
  name: "MyGroupLearningProfile",
  components: {
    SSLProfileImg,
    // SortButtonSelect,
  },
  props: {
    crseNm: String,
    subTitle: {
      type: String,
      default: null
    },
    distCrseLrnFldNm: String,
    distCrseCd: String,
    distCrseSn: Number,
    lrnGoals: String,
    items: Array
  },

  setup(props){
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const relToggle = ref(false);
    const session = computed(() => store.state.auth.session);
    const distCrseCd = computed(() => route.params.distCrseCd);
    const distCrseNm = computed(() => sslRoutes[route.params.distCrseCd] ? sslRoutes[route.params.distCrseCd].name : '' );
    const myGroup = computed(() => store.state.ssl.group);
    const isBlack = computed(() => ['selfIct','selfJa','selfGa','selfGi','selfDvl','group', 'trivia', 'college', 'live'].includes(route.params.distCrseCd));
    const isMyLrnTrgt = computed(() => isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn.value));
    // const selDistCrseSn = ref( props.distCrseSn);
    const targetId = computed(() => {
      if (props.items && props.items.length > 0 ) return {lrnerId: props.items.find(x=> x.distCrseSn == props.distCrseSn).lrnerId};
      else return session.value;
    });


    // const changeThis = () => {
    //     emit("selected");
    //     router.push({name: 'GroupView', params: {distCrseSn: selDistCrseSn.value}});
    //
    //
    // }

    const lrnTrgtGrpMstSn = computed(() => {
      if (props.distCrseCd === 'group') return 119739;
      else if (props.distCrseCd === 'knowledge') return 112121
      return 0;
    });

    const goMySSL = () => {
      if (isMyLrnTrgt.value) {
        router.push({name: 'SSLMy'}).then(() => {});
      } else {
        if (noneRewardCds.includes(distCrseCd.value)) {
          router.push({name: 'SSLMy'}).then(() => {});
        } else {
          enrollSSLAlert(distCrseCd, distCrseNm, lrnTrgtGrpMstSn);
        }
      }
    };

    const goKnowledge = () =>{
      router.push({name: 'KnowledgeELearning'});
    };



    return {
      targetId,
      session,
      lrnTrgtGrpMstSn,
      isBlack,
      myGroup,
      goKnowledge,
      goMySSL,
      // selDistCrseSn,
      relToggle,
      // changeThis,
    }
  }
}
</script>
