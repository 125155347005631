<template>
  <main v-if="!bookHistoryModal && !memoModal && !timerModal && !showMyPortraitModal" class="main-content">
    <div class="ssl-body pb-0" id="ssl-myssl">
      <SSLMobileSlotHeader>
        <template v-slot:title>
          <SSLMobileNav />
        </template>
<!--        <template v-slot:left>-->
<!--          <div class="util util-history-back">-->
<!--            <router-link :to="{name: 'SSLMain'}" class="util-actions util-actions-history-back">-->
<!--              <i class="icon-history-back"></i>-->
<!--            </router-link>-->
<!--          </div>-->
<!--        </template>-->
        <template v-slot:right>
          <div class="util util-more">
            <div class="dropdown" :class="{'is-active': toggles.nav}" data-offset="header-left">
              <button class="dropdown-btn" @click="toggles.nav = !toggles.nav"><i class="icon-more"></i></button>
              <div class="dropdown-target" style="width: 144px;left: -104px;">
                <div class="dropdown-box">
                  <ul class="dropdown-option-list">
                    <li class="dropdown-option-item">
                      <SSLTrial
                          trial-class="dropdown-option-link"
                          text-class="dropdown-option-text"
                      />
                    </li>
                    <li class="dropdown-option-item">
                      <SSLManual
                          manual-class="dropdown-option-link"
                          text-class="dropdown-option-text"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
      </SSLMobileSlotHeader>


      <div class="page-container">
        <div v-if="isReady" class="myssl-container">
          <SSLMobileMyTop :year="filters.year" />

<!--          <template v-if="viewRoute === 'status'">-->
<!--            <SSLMobileMyWrite v-if="currYear == filters.year" :focus-func="memoTimerEvents"/>-->
<!--            &lt;!&ndash; section-myactivity &ndash;&gt;-->
<!--            <SSLMobileMyActivity v-if="distCrseCd != 'total'" :focus-func="historyEvents"/>-->
<!--&lt;!&ndash;            &lt;!&ndash; section-sbadge &ndash;&gt;&ndash;&gt;-->
<!--            <SSLMobileMyBadgeFlex/>-->
<!--          </template>-->
          <article class="myssl-section mt-0 pt-0">
            <div class="my-category category-self" style="justify-content: flex-start !important"  v-if="!isHome && !['subscribe', 'subscribed', 'status'].includes(viewRoute)">

              <div style="display: flex; width: 100%; overflow-x: auto; padding: 15px;justify-content: flex-start; white-space: nowrap">
<!--                <div class="list-top mt-0">-->
<!--                  <div class="top-column">-->
                    <!-- list-top > list-sort -->
                    <div class="sort" style="margin-right: 15px">
                      <SortButtonSelect4
                          v-model="filters.year"
                          v-model:toggle="toggles.year"
                          title="년도"
                          :options="yearOptions"
                          sequence-key="value"
                          name-key="display"
                          :is-num="true"
                          :is-all="false"
                          :textStyle="`color:#000;font-size:14px; font-family:var(--kb-font-KBFGText);`"
                          @update:toggle="closeToggles(toggles, 'year')"
                          @selected="initPageYear()"
                      />
                    </div>
                    <div class="sort" style="margin-right: 15px">
                      <SortButtonSelect4
                          v-model="filters.distCrseSn"
                          v-model:toggle="toggles.distCrseSn"
                          title="과정"
                          :options="distOptions"
                          sequence-key="distCrseSn"
                          name-key="crseNm"
                          :is-all="true"
                          :textStyle="`color:#000;font-size:14px; font-family:var(--kb-font-KBFGText);`"
                          @update:toggle="closeToggles(toggles, 'distCrseSn')"
                          @selected="initPageSubj()"
                      />
                    </div>
                    <div class="sort" style="margin-right: 15px">
                      <SortButtonSelect4
                          v-model="filters.objType"
                          v-model:toggle="selToggles.objType"
                          title="모든 타입"
                          :options="typeOptions"
                          sequence-key="key"
                          name-key="value"
                          :is-all="true"
                          :textStyle="`color:#000;font-size:14px; font-family:var(--kb-font-KBFGText);`"
                          @update:toggle="closeToggles(selToggles, 'objType')"
                          @selected="goQuerySearch()"
                      />
                    </div>
                    <div class="sort" style="margin-right: 15px">
                      <SortButtonSelect4
                          v-model="filters.sort"
                          v-model:toggle="selToggles.sort"
                          title="순서"
                          :options="sortOptions"
                          sequence-key="key"
                          name-key="value"
                          :textStyle="`color:#000;font-size:14px; font-family:var(--kb-font-KBFGText);`"
                          @update:toggle="closeToggles(selToggles, 'sort')"
                          @selected="goQuerySearch()"
                      />
                    </div>
<!--                  </div>-->
<!--                </div>-->
              </div>

            </div>
            <div class="my-category category-self p-3" v-if="['subscribe', 'subscribed'].includes(viewRoute)">
              <div class="list-tab" style="display: flex; justify-content: flex-start" >
                <a href="javascript:" class="tab" :class="{'is-active' : viewRoute === 'subscribe'}"
                   @click="selSubscribe('subscribe')">내가 구독</a>
                <a href="javascript:" class="tab" :class="{'is-active' : viewRoute === 'subscribed'}"
                   @click="selSubscribe('subscribed')">나를 구독</a>
              </div>
            </div>
          </article>

          <SSLMobileMyHome v-if="isHome"/>

          <SSLMobileMyBoard
              v-else-if="['complete', 'temporary', 'save'].includes(viewRoute)"
              :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
          />
          <SSLMobileMySubscribe
              v-else-if="['subscribe', 'subscribed'].includes(viewRoute)"  />
        </div>
      </div>
    </div>
  </main>
  <BookHistory v-if="bookHistoryModal" v-model="bookHistoryModal" :selectValue="selectValue"/>
  <SSLMyMemoModal v-if="memoModal" v-model="memoModal" :data="modalItem"/>
  <SSLMyTimerModal v-if="timerModal" v-model="timerModal" :data="modalItem"/>
  <MobileMyPortraitModal userProfileTyCdDcd="2161003" />
</template>

<script>
import SSLMobileMyTop from '@/components/ssl/my/mobile/SSLMobileMyTop';
// import SSLMobileMyWrite from '@/components/ssl/my/mobile/SSLMobileMyWrite';
// import SSLMobileMyActivity from '@/components/ssl/my/mobile/SSLMobileMyActivity';
// import SSLMobileMyBadgeFlex from '@/components/ssl/my/mobile/SSLMobileMyBadgeFlex';
import {sslMySetup} from '@/assets/js/modules/ssl/setup/ssl-my-setup';
import SSLMobileMyBoard from '@/views/ssl/mobile/SSLMobileMyBoard';
import SSLMobileMySubscribe from '@/components/ssl/my/mobile/SSLMobileMySubscribe';
import SSLMobileSlotHeader from '@/components/ssl/main/mobile/SSLMobileSlotHeader';
import SSLTrial from '@/components/ssl/common/SSLTrial';
import SSLManual from '@/components/ssl/common/SSLManual';
import SSLMobileNav from '@/components/ssl/mobile/SSLMobileNav';
import MobileMyPortraitModal from '@/components/mypage/mobile/MobileMyPortraitModal.vue';
import BookHistory from "@/components/history/book/BookHistory.vue";
import SSLMyMemoModal from "@/components/ssl/my/SSLMyMemoModal.vue";
import SSLMyTimerModal from "@/components/ssl/my/SSLMyTimerModal.vue";
import SortButtonSelect4 from "@/components/common/SortButtonSelect4.vue";
import SSLMobileMyHome from "@/views/ssl/mobile/SSLMobileMyHome.vue";

export default {
  name: 'SSLMobileMy',
  components: {
    SSLMobileMyHome,
    SSLMyTimerModal, SSLMyMemoModal,
    BookHistory,
    SSLMobileNav,
    SSLManual,
    SSLTrial,
    SSLMobileSlotHeader,
    SSLMobileMySubscribe,
    SSLMobileMyBoard,
    // SSLMobileMyBadgeFlex,
    // SSLMobileMyActivity,
    // SSLMobileMyWrite,
    SSLMobileMyTop,
    MobileMyPortraitModal,
    SortButtonSelect4
  },
  setup: sslMySetup
}
</script>
