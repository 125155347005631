<template>
  <div v-if="hrd" class="detail-actions">
    <button v-if="cttTyCdDcds.CTT_TY_CD_VOD === hrd.cttTyCdDcd" class="kb-btn-icon" @click.prevent.stop="funcs.toggleScript()">
      <i class="icon-history"></i>
      <span class="text">스크립트</span>
    </button>
    <button v-if="cttTyCdDcds.CTT_TY_CD_PDF !== hrd.cttTyCdDcd" class="kb-btn-icon" @click.prevent.stop="funcs.openHistory(hrd, player)">
      <i class="icon-history"></i>
      <span class="text">이력</span>
    </button>
    <button class="kb-btn-icon" @click.stop="handleActions(hrd).actLike">
      <i :class="[hrd.liked ? 'icon-like-active' : 'icon-like' ]" ></i><span class="text">{{ hrd.lkCnt ? hrd.lkCnt : 0 }}</span>
    </button>
    <button class="kb-btn-icon" @click.prevent.stop="handleActions(hrd, player).actMultiPut(session.lrnerId)">
      <i class="icon-add" :class="{'is-active' : hrd.put }"></i><span class="text">담기</span>
    </button>
    <button class="kb-btn-icon" @click.stop="funcs.openShare(hrd, player)">
      <i class="icon-share"></i><span class="text">공유</span>
    </button>
<!--    <button class="kb-btn-icon" @click.prevent.stop="handleActions(hrd, player).actSSL">-->
<!--      <i class="icon-ssl"></i>-->
<!--      <span class="text">SSL</span>-->
<!--    </button>-->
    <button class="kb-btn-icon" @click.prevent.stop="funcs.openOpinion(hrd, player)">
      <i class="icon-opinion"></i>
      <span class="text">의견</span>
    </button>
<!--    <button v-if="hrd.substFileNm !== null && hrd.substFileNm.length >5"  class="kb-btn-icon" @click.prevent.stop="funcs.openStt(hrd, player)">-->
<!--      <i class="icon-stt"></i>-->
<!--      <span class="text">요약</span>-->
<!--    </button>-->
    <button class="kb-btn-icon kb-btn kb-btn-primary" @click.prevent.stop="handleActions(hrd, player).actSubscribe(hrd.cloudNmLst)">
      <span class="text"><i class="mdi mdi-youtube-subscription"></i> {{ hrd.subscribed ? '구독중' : '구독' }}</span>
    </button>

  </div>
</template>
<style scoped>
#kb[data-mode="dark"] .detail-actions .icon-add.is-active {
  width:30px;height:30px;margin-bottom:2px;background-size:24px 24px !important;
}
</style>
<script>
import {cttTyCdDcds, handleActions} from '@/assets/js/modules/hrd/hrd-common';
import {computed} from 'vue';
import {useStore} from 'vuex';

export default {
  name: 'HrdCloudActions',
  props: {
    player: Object,
    hrd: Object,
    funcs: {
      type: Object,
      default: () => {
        return {
          openHistory : () => {},
          openShare : () => {},
          openOpinion: () => {},
          openStt: () =>{},
          toggleScript: () =>{},
        };
      }
    }
  },
  setup() {
    const store = useStore();
    const session = computed(() => store.state.auth.session);

    return {
      handleActions,
      cttTyCdDcds,
      session
    }
  }
};
</script>