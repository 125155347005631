<template>
  <div v-if="hrd" class="detail-actions">
    <div style="margin-bottom: 10em;" v-if="!isMobile">
      <button  class="swiper-btn-prev kb-btn-icon nav-btn" @click.prevent.stop="funcs.prevSlide()">
        <i class="icon-arrow-prev" />
      </button>
      <button class="swiper-btn-next kb-btn-icon nav-btn" @click.prevent.stop="funcs.nextSlide()">
        <i class="icon-arrow-next" />
      </button>
    </div>
    <div style="display: flex;align-items: center;flex-direction: column;">

      <button v-if="hrd.refLrnObjDtlDistSn>0" class="kb-btn-icon" @click.prevent.stop="funcs.openOrigin(hrd)" style="margin-left: 10px;">
        <i class="icon-monitor"></i><span class="text">원본</span>
      </button>
      <button v-if="cttTyCdDcds.CTT_TY_CD_PDF !== hrd.cttTyCdDcd" class="kb-btn-icon" @click.prevent.stop="funcs.openHistory(hrd, player)" style="margin-left: 10px;">
        <i class="icon-history"></i><span class="text">이력</span>
      </button>
      <button class="kb-btn-icon" @click.stop="handleActions(hrd).actLike">
        <i :class="[hrd.liked ? 'icon-like-active' : 'icon-like' ]" ></i><span class="text">{{ hrd.lkCnt ? hrd.lkCnt : 0 }}</span>
      </button>
      <button class="kb-btn-icon" @click.prevent.stop="handleActions(hrd, player).actMultiPut(session.lrnerId)">
        <i class="icon-add" :class="{'is-active' : hrd.put }"></i><span class="text">담기</span>
      </button>
      <button class="kb-btn-icon" @click.stop="funcs.openShare(hrd, player)">
        <i class="icon-share"></i><span class="text">공유</span>
      </button>
  <!--    <button class="kb-btn-icon" @click.prevent.stop="handleActions(hrd, player).actSSL">-->
  <!--      <i class="icon-ssl"></i>-->
  <!--      <span class="text">SSL</span>-->
  <!--    </button>-->
      <button class="kb-btn-icon" @click.prevent.stop="funcs.openOpinion(hrd, player)">
        <i class="icon-opinion"></i>
        <span class="text">의견</span>
      </button>
    </div>

  </div>
</template>
<style scoped>

.detail-actions {
  align-items: center;
  vertical-align: bottom;
  flex-direction: column;
  justify-content: flex-end;
}
.icon-arrow-prev {
  width:32px;height:32px;
  transform: rotate(90deg);
  background-image:url(../../assets/lxp/images/common/ic_arrow32_left_white.svg) !important;}
.icon-arrow-next {
  width:32px;height:32px;
  transform: rotate(90deg);
  background-image:url(../../assets/lxp/images/common/ic_arrow32_right_white.svg) !important;}

.nav-btn {
  margin-top:15px !important;
  margin-bottom: 15px !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--kb-gold);
  display: flex;
  align-items: center;
  justify-content: center;
}

.kb-btn-icon {
  margin-bottom: 1.5em !important;
}
/*
.lxp-mobile-layout .player .player-details .detail-actions {
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}
*/
</style>
<script>
import {cttTyCdDcds, handleActions} from '@/assets/js/modules/hrd/hrd-common';
import {computed} from 'vue';
import {useStore} from 'vuex';
import navigationUtils from "@/assets/js/navigationUtils";

export default {
  name: 'HrdCloudShortsActions',
  props: {
    player: Object,
    hrd: Object,
    funcs: {
      type: Object,
      default: () => {
        return {
          openOrigin : () => {},
          openHistory : () => {},
          openShare : () => {},
          openOpinion: () => {},
          openStt: () =>{},
          prevSlide: () =>{},
          nextSlide: () =>{}
        };
      }
    }
  },
  setup() {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const isMobile = navigationUtils.any();

    return {
      handleActions,
      cttTyCdDcds,
      session,
      isMobile
    }
  }
};
</script>