<template>
  <LxpMobileHeader v-if="isMobile" title="그룹관리">
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
    <template v-slot:right>
      <div class="util util-add">
        <router-link :to="{name: 'GroupLearningMng', params: {boardSn: 0}}" class="util-actions util-actions-add me-3">
          <i class="icon-plus"></i>
        </router-link>
      </div>
      <div class="util util-search">
        <a href="javascript:" class="util-actions util-actions-search">
          <i class="icon-search"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-prep-group">
      <div class="main-header">
        <div class="header-top main-component">
          <div class="header-column">
            <router-link :to="{name: 'GroupLearning'}" class="page-nav">
              <i class="icon-kb-nav-arrow"></i>
              <span class="text">이전</span>
            </router-link>
          </div>
          <div class="header-column">
            <router-link :to="{name: 'GroupLearningMng', params: {boardSn: 0}}" type="button" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg">
              <i class="icon-pencil"></i>
              <span class="text">그룹생성</span>
            </router-link>
          </div>
        </div>
        <div class="header-title">
          <h2 class="title">그룹 참여하기</h2>
        </div>
        <!-- main-header > header-dropdown -->
        <div class="header-dropdown">
          <div class="dropdown" :class="{ 'is-active' : isActive }" @click="isActive = !isActive">
            <button class="dropdown-btn kb-btn kb-btn-outline"><strong class="text">{{ activeLrnFld }}</strong><i class="icon-arrow"></i></button>
            <div class="dropdown-target">
              <div class="dropdown-box">
                <ul class="dropdown-option-list">
                  <li class="dropdown-option-item">
                    <a href="javascript:" class="dropdown-option-link" @click="selectLrnFld('ALL', 0)"><span class="dropdown-option-text">ALL</span></a>
                  </li>
                  <li v-for="(item, idx) in crseDistFlds" :key="idx" class="dropdown-option-item">
                    <a href="javascript:" class="dropdown-option-link" @click="selectLrnFld(item.distCrseLrnFldNm, item.distCrseLrnFldSn)"><span class="dropdown-option-text">{{item.distCrseLrnFldNm}}</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //main-header -->
      <!-- main-content -->
      <div class="main-content min-component">
        <div class="learningGroup-container">
          <div class="list-top">
            <div class="top-column align-self-end">
              <span class="title text-muted ">총 {{paging.totalCount}}개</span>
            </div>
            <div class="top-column">
              <div class="kb-form-search">
                <div class="kb-form-search-field">
                  <input type="text" class="kb-form-search-input" placeholder="그룹명/부점명/리더명 검색"
                         v-model="search.crseInfo"
                         @keyup.enter="clickSearch"
                  />
                  <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search">검색</i></button>
                </div>
              </div>
            </div>
          </div>
          <!-- learningGroup-list -->
          <GroupCard :items="items" :is-view-page="false" :is-all="isAll"/>
          <!-- //learningGroup-list -->
          <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>

          <template v-if="isReady">
            <div v-if="paging.totalCount === 0" class="search-container">
              <div class="result-empty">
                <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
                <p class="text">그룹 정보가 없습니다.</p>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- //main-content -->
  </main>
</template>

<script>
import CommonPaginationFront from "@/components/CommonPaginationFront";
import GroupCard from "@/components/prep/group/GroupCard";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import {groupApplicationSetup} from "@/assets/js/modules/groupLearing/group-application-setup";

export default {
  name: "GroupApplication",
  components:{LxpMobileHeader, GroupCard, CommonPaginationFront},
  setup: groupApplicationSetup
}
</script>