<template>
  <HrdCloudMobileHeader :show-back="true" :go-back-func="modelValue.toggle" />
    <main class="kb-main" id="kb-hrdcloud">
      <div class="main-content min-component">
        <article v-if="item.cttTyCdDcd !== '2034005'" class="content-section">
          <video
              class="vjs-matrix video-js vjs-default-skin vjs-big-play-centered kb-mob-preview"
              ref="videoDom"
              oncontextmenu="return false;"
              playsinline
          ></video>
        </article>
        <artitle v-else>
          <div>
            <template v-if="pageCount === 0">
              Loading...
            </template>
            <template v-else>
            <span style="padding: 10px; font:normal 16px/22px var(--kb-font-KBFGDisplayB)">
              <button :disabled="page <= 1" @click="page--">❮</button>
              {{ page }} / {{ pageCount }}
              <button :disabled="page >= pageCount" @click="page++">❯</button>
            </span>
            </template>
            <vue-pdf-embed v-if="src"
                           :source="src"
                           ref="pdfViewer"
                           :page="page"
                           @rendered="handleDocumentRender"
            />
          </div>
        </artitle>
      </div>
    </main>
</template>

<script>
import {useStore} from 'vuex';
import {computed, onBeforeUnmount, onMounted, ref} from 'vue';
import {getType, getVideoUrl} from '@/assets/js/util';
import videojs from 'video.js';
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import VuePdfEmbed from 'vue-pdf-embed';
export default {
  name: 'HrdCloudMobilePreview',
  components: {HrdCloudMobileHeader, VuePdfEmbed},
  props: {
    modelValue: Object,
    item: Object,
  },
  setup(props) {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const videoDom = ref(null), video_instance = ref(null);

    const pdfViewer = ref(null);
    const page = ref(null);
    const pageCount = ref(0);

    const isDev = (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development' )

    const src = ref('');

    if (isDev) {
      src.value = 'https://devlxpcms.kbstar.com/' + props.item.etnUrl;
    } else {
      src.value = 'https://lxpcms.kbstar.com/' + props.item.etnUrl;
    }

    const handleDocumentRender = () => {
      pageCount.value = pdfViewer.value.pageCount;
      if(!page.value) page.value = 1;
    };

    onMounted(() => {
      if (session.value && videoDom.value) {
        const vjsOptions = {
          fluid: true,
          preload: 'metadata',
          autoplay: false,
          crossOrigin: 'anonymous',
          language: 'ko',
          // width: 1200,
          // height: 675,
          controls: true,
          controlBar: {
            subsCapsButton: false,
            audioTrackButton: false,
          },
          sources: [{
            src: getVideoUrl(session.value.itn, props.item),
            type: getType(getVideoUrl(session.value.itn, props.item))
          }]
        };
        video_instance.value = videojs(videoDom.value, vjsOptions,function() {});
      }
    });

    onBeforeUnmount(() => {
      // console.log('video disposed onbeforeunmount');
      if (video_instance.value) video_instance.value.dispose();
    });

    return {
      videoDom,
      pdfViewer,
      page,
      pageCount,

      handleDocumentRender,
      src
    }
  }
};
</script>