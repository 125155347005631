<template>
  <LxpMobileHeader title="모임일지">
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="clickBack">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-prep-group">
    <!-- main-content -->
    <div class="main-content">
      <div>
        <section class="content-section section-forms">
          <!-- kb-form-fields -->
          <div class="kb-form-fields kb-form-fields-v2">
            <!-- kb-form-field:그룹명 -->
            <div class="kb-form-field field-first">
              <div class="kb-form-row">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">그룹명</span>
                </label>
              </div>
              <div class="kb-form-row">
                <div class="kb-form-text">{{item.distCrseNm}}</div>
              </div>
            </div>

            <!-- kb-form-field:분야 -->
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">분야</span>
                </label>
              </div>
              <div class="kb-form-row">
                <div class="kb-form-text">{{item.distCrseLrnFldNm}}</div>
              </div>
            </div>
            <!-- kb-form-field:구분 -->
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">구분</span>
                </label>
              </div>
              <div class="kb-form-row">
                <div class="kb-form-text">{{ item.lrnObjTyCd === '100020' ? '온라인' : '오프라인' }}</div>
              </div>
            </div>
            <!-- kb-form-field:모임주제 -->
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">모임주제</span>
                </label>
              </div>
              <div class="kb-form-row">
                <div class="kb-form-text">{{item.objNm}}</div>
              </div>
            </div>
            <!-- kb-form-field:모임일지 -->
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">모임일시</span>
                </label>
              </div>
              <div class="kb-form-row">
                <div class="kb-form-text">{{item.bgngYmd}} {{timestampToDateFormat(item.bgngLrn, 'hh:mm')}}-{{timestampToDateFormat(item.endLrn, 'hh:mm')}}</div>
              </div>
            </div>
          </div>
          <!-- //kb-form-fields -->
        </section>
      </div>

      <section class="content-section section-divider m-16-layer mt-5">
        <header class="section-header">
          <h4 class="title">
            학습한 콘텐츠
          </h4>
        </header>
        <div v-if="cttItems && cttItems.length === 0"  class="learned-list-container">
          <div class="search-result">
            <div class="result-empty">
              <img src="../../../../assets/lxp/mobile/images/common/img_empty.png">
              <p class="text">학습한 콘텐츠가 없어요</p>
            </div>
          </div>
        </div>
        <div v-else class="learned-list-container">
          <div class="learn-list-swiper swiper">
            <swiper
                :slides-per-view="2"
                :navigation="{
                    nextEl: '.kb-btn-swiper-prev .hrdcloud',
                    prevEl: '.kb-btn-swiper-next .hrdcloud'
                  }"
            >
              <swiper-slide v-for="(item, idx) in cttItems" :key="idx" >
                <HrdCloudContent
                    :item="item"
                    :session="session"
                    :idx="idx"
                    :show-count="false"
                />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </section>

      <section class="content-section section-divider m-16-layer mt-5">
        <header class="section-header">
          <h4 class="title">
            화상강의 녹화
          </h4>
        </header>
        <div v-if="cttIvsCastsItems && cttIvsCastsItems.length === 0"  class="learned-list-container">
          <div class="search-result">
            <div class="result-empty">
              <img src="../../../../assets/lxp/mobile/images/common/img_empty.png">
              <p class="text">녹화된 화상강의가 없어요</p>
            </div>
          </div>
        </div>
        <div v-else class="learned-list-container">
          <div class="learn-list-swiper swiper">
            <swiper
                :slides-per-view="2"
                :navigation="{
                    nextEl: '.kb-btn-swiper-prev .castrecorder',
                    prevEl: '.kb-btn-swiper-next .castrecorder'
                  }"
            >
              <swiper-slide v-for="(item, idx) in cttIvsCastsItems" :key="idx" >
                <CommmonContentWrapper
                    :item="item"
                    :session="session"
                    :idx="idx"
                    :show-count="false"
                    @view-cast-hls="viewCastHls"
                    @request-mp4="requestMp4"
                />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </section>


      <section class="content-section section-attendance section-divider m-16-layer">
        <header class="section-header">
          <h4 class="title">출석 <span class="text text-muted">{{ cmptnCnt }}/{{ paging.totalCount }}</span></h4>
          <div class="header-sub">
            <div class="text-group">
              <span class="text text-sm">평균학습시간 {{ secondToKrFormat(Math.round(item.avgLrnTime), false) }}</span>
              <span class="text text-sm">평균콜드콜응답률 {{ (coldCallRspnsCnt > 0 && coldCallRound > 0)? Math.floor((coldCallRspnsCnt/coldCallRound)*100) : 0}}%</span>
            </div>
            <div class="badge" :class="item.mtgAcptYn === 'Y' ? 'badge-primary' : 'badge-silver'"><span class="badge-text">{{item.mtgAcptYn === 'Y' ? '인정' : '미인정'}}</span></div>
          </div>
        </header>
        <div class="text-item-container">
          <!-- text-item -->
          <div  v-for="(item, idx) in attnItems" :key="idx" class="text-item">
            <div class="item-row row-contents">
              <div class="item-column">
                <p class="item-subtitle">{{item.grpRoleCdDcd === '2010001' ? '리더' : '멤버' }}</p>
                <p class="item-title">{{ item.lrnerNm }}・{{ item.deptNm }}・{{ item.jbpsNm }}</p>
              </div>
            </div>
            <div class="item-row mt-2">
              <span class="text-title">학습시작시간</span>
              <span class="text-description">{{ item.rsltYn === 'Y' ? item.regDt : '-' }}</span>
            </div>
            <div class="item-row">
              <span class="text-title">학습종료시간</span>
              <span class="text-description">{{ item.rsltYn === 'Y' ? item.mdfcnDt : '-'}}</span>
            </div>
            <div class="item-row">
              <span class="text-title">학습누적시간</span>
              <span class="text-description">{{ item.lrnTime ? item.lrnTime : '-'}}</span>
            </div>
            <div class="item-row">
              <span class="text-title">콜드콜</span>
              <span class="text-description">{{ item.rsltYn === 'Y' ? item.coldCallRspnsCnt+'/'+item.coldCallRound : '-' }}</span>
            </div>
            <div class="item-row">
              <span class="text-title">출석여부</span>
              <span class="text-description">{{ item.rsltYn === 'Y' ? '출석' : '결석' }}</span>
            </div>
          </div>
        </div>
      </section>

    </div>

  </main>
</template>

<script>
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader.vue";
import {groupLearningJournalSetup} from "@/assets/js/modules/groupLearing/group-learning-journal-setup";
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue";
import HrdCloudContent from "@/components/hrdcloud/HrdCloudContent.vue";
import CommmonContentWrapper from "@/components/common/CommonContentWrapper.vue";

export default {
  name: "MobileGroupLearningJournal",
  components: {CommmonContentWrapper, LxpMobileHeader, HrdCloudContent, Swiper, SwiperSlide},
  setup: groupLearningJournalSetup
}
</script>