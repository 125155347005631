<template>
  <article class="hrdcloud-card" :class="[isEditable && isSelected ? 'is-active' : '', isShorts ? 'shorts-card' : '' ]">
    <router-link
        class="hrdcloud-image"
        :to="{ name: (isEditable ? '' : 'HrdCloudContentBridge'), params: {lrnObjDtlDistSn: item.lrnObjDtlDistSn} }"
        @click="closeModal"
    >
      <div class="hrdcloud-image">
        <template v-if="item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_PDF">
          <div class="image">
            <a href="javascript:" class="image-link">
              <img v-if="item.thumb" :src="getThumbUrl(item.thumb)" @error="setDefaultImg" />
              <img v-else src="../../assets/lxp/images/@tmp/@temp_pdf_new.jpg" @error="setDefaultImg" />
            </a>
          </div>
        </template>
        <template v-else-if="item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_YOUTUBE">
          <div v-if="item.progRsltSn > 0" class="video" :key="`y_${item.lrnObjDtlDistSn}`">
            <a href="javascript:" class="video-link">
              <img v-if="isActiveIdx !== idx" :src="getYoutubeThumb(session.itn, item.etnUrl)" @error="setDefaultImg" />
            </a>
            <div v-if="isLessThanAWeek() && item.lastPlaySec > 0" class="video-controller">
              <div class="controller-row">
                <div class="controller-progressbar">
                  <div class="bar-total">
                    <div class="bar-current" :style="{width: handleShowProgress() }"></div>
                    <div class="bar-slider"></div>
                  </div>
                </div>
              </div>
              <div class="controller-row">
                <div class="controller-indicator">
                  <div class="duration">
                    <span class="current-time">{{ item.lastPlaySec > item.cttSz ? secondToFormat(item.cttSz, 'hh:mm:ss') : secondToFormat(item.lastPlaySec, 'hh:mm:ss') }}</span>
                    <span class="current-total">{{ secondToFormat(item.cttSz, 'hh:mm:ss') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="image" :key="`x_${item.lrnObjDtlDistSn}`">
            <a href="javascript:" class="image-link">
              <img v-if="isActiveIdx !== idx" :src="getYoutubeThumb(session.itn, item.etnUrl)" @error="setDefaultImg"/>
            </a>
          </div>
        </template>

        <template v-else>
          <div v-if="item.progRsltSn > 0" class="video" :class="{'shorts-card-image': isShorts }" @mouseover.stop="handleVideoActive(idx)" @mouseleave.stop="handleVideoActive(-2)" :key="`y_${item.lrnObjDtlDistSn}`">
            <a href="javascript:" class="video-link">
              <CommonLazyImage v-if="isActiveIdx !== idx" :img-src="getThumbImg(item.thumb)"/>
              <ThumbnailVideo v-if="item.etnUrl && isActiveIdx === idx" :src="getVideoUrl(session.itn, item)"/>
            </a>
            <div v-if="isLessThanAWeek() && item.lastPlaySec > 0 && !isShorts" class="video-controller">
              <div class="controller-row">
                <div class="controller-progressbar">
                  <div class="bar-total">
                    <div class="bar-current" :style="{width: handleShowProgress() }"></div>
                    <div class="bar-slider"></div>
                  </div>
                </div>
              </div>
              <div class="controller-row">
                <div class="controller-indicator">
                  <div class="duration">
                    <span class="current-time">{{ item.lastPlaySec > item.cttSz ? secondToFormat(item.cttSz, 'hh:mm:ss') : secondToFormat(item.lastPlaySec, 'hh:mm:ss') }}</span>
                    <span class="current-total">{{ secondToFormat(item.cttSz, 'hh:mm:ss') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="image" :class="{'shorts-card-image': isShorts }" @mouseover.stop="handleVideoActive(idx)" @mouseleave.stop="handleVideoActive(-2)"
               :key="`x_${item.lrnObjDtlDistSn}`">
            <a href="javascript:" class="image-link">
              <CommonLazyImage v-if="isActiveIdx !== idx" :img-src="getThumbImg(item.thumb)"/>
              <ThumbnailVideo v-if="item.etnUrl && isActiveIdx === idx" :src="getVideoUrl(session.itn, item)"/>
            </a>
          </div>
        </template>
        <div class="badges">
          <div v-if="item.cttTyCdDcd && item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_YOUTUBE && !session.itn " class="badge-red">
            <span class="text">YOUTUBE</span>
          </div>

          <div v-if="categoryNms && categoryNms.category2 && categorySns.category2 != 21 && categorySns.category1 != 4" class="badge-dark">
            <span class="text">{{ categoryNms.category2 }}</span>
          </div>
          <div v-else-if="categoryNms && categoryNms.category2 && categorySns.category1 == 4" class="badge-dark">
            <span class="text">{{ categoryNms.category1 }}</span>
          </div>
          <div v-if="item.servTyCdDcd && item.servTyCdDcd === servTyCdDcds.SERV_TY_CD_WITH" class="badge-gold">
            <span class="text">W.I.T.H</span>
          </div>

          <div v-if="item.cttTyCdDcd && item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_PDF" class="badge-red">
            <span class="text">PDF</span>
          </div>

          <div v-if="item.cttSerisSn > 0" class="badge-yellow">
            <span class="text">시리즈</span>
          </div>
          <div v-if="item.hrdCloudRoadmapSns && item.hrdCloudRoadmapSns.length > 0" class="badge-green">
            <span class="text">로드맵</span>
          </div>
          <div v-if="(item.cmptnYn === 'Y' || item.lastYearCmptnYn === 'Y')" class="badge-finished">
            <span class="text">완료</span>
            <i class="icon-check"></i>
          </div>
        </div>
      </div>
    </router-link>
    <div class="hrdcloud-content">
      <div class="content-category">
        <span v-if="categoryNms && categoryNms.category3 && !isShorts" class="text" @click="searchCategory">
          <a href="javascript:">{{ categoryNms.category3 }}</a>
        </span>
      </div>
      <div class="content-title">
        <h5 class="title" :class="{ 'new' : item.new > 0 }">
          <router-link
              class="title-link"
              :to="{ name: (isEditable ? '' : 'HrdCloudContentBridge'), params: {lrnObjDtlDistSn: item.lrnObjDtlDistSn} }"
              :title="item.objNm"
              @click="closeModal"
          >
<!--            <span v-if="showIndex" class="me-1">{{idx+1}}</span>-->
            <span v-if="showIndex" class="me-1">{{idx+1}}</span>
            <span v-if="!isMobile">{{ item.objNm }}</span>
            <span v-else style="white-space: nowrap; overflow:hidden; text-overflow: ellipsis; max-width: 100%; display: block;">{{ item.objNm }}</span>
            <span v-if="isMobile" class="after"></span>
<!--            <span v-else style="white-space: nowrap; overflow:hidden; text-overflow: ellipsis; max-width: 160px; display: block;">{{ item.objNm }}</span>-->
          </router-link>
        </h5>
      </div>
      <template v-if="isMobile">
        <div v-if="!isShorts" class="content-info-and-meta">
          <div v-if="showCount" class="content-info">
            <div class="info">
              <i class="icon-views"></i>
              <span class="text">{{ item.inqCnt || 0 }}</span>
            </div>
            <div class="info">
              <i class="icon-like"></i>
              <span class="text">{{ item.lkCnt || 0 }}</span>
            </div>
            <div v-if="categorySns.category1 && categorySns.category1 === '1'" class="info">
              <span v-if="item.cttDifi" class="text">{{ lvlStr[item.cttDifi] }}</span>
            </div>
          </div>
          <div class="content-meta">
            <span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd').substring(2) }}</span>
            <span v-if="item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_VOD || item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_YOUTUBE" class="text text-red">{{ secondToFormat(item.cttSz, 'hh:mm:ss') }}</span>
          </div>
        </div>
      </template>
      <template v-else-if="showCount">
        <div class="content-info">
          <div class="info">
            <i class="icon-views"></i>
            <span class="text">{{ item.inqCnt || 0 }}</span>
          </div>
          <div class="info">
            <i class="icon-like"></i>
            <span class="text">{{ item.lkCnt || 0 }}</span>
          </div>
          <div v-if="categorySns.category1 && categorySns.category1 === '1'" class="info">
            <span v-if="item.cttDifi" class="text">{{ lvlStr[item.cttDifi] }}</span>
          </div>
        </div>
        <div class="content-meta">
          <span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd') }}</span>
          <span v-if="item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_VOD || item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_YOUTUBE" class="text text-red">{{ secondToFormat(item.cttSz, 'hh:mm:ss') }}</span>
        </div>
      </template>
      <div v-if="showPut && !isShorts" class="content-interest">
        <button class="kb-btn-interest" data-content="담기" @click.prevent.stop="handleActions(item).actMultiPut(session.lrnerId)">
          <i v-if="isMobile" :class="[ item.put ? 'icon-interest-active' : 'icon-interest' ]"></i>
          <i v-else class="icon-interest" :class="{ 'is-active' : item.put }"></i>
        </button>
      </div>
    </div>
  </article>
</template>

<style scoped>
#kb-hrdcloud-v2 .shorts-card {
  width: 185px !important;
}

#kb-hrdcloud-v2 .shorts-card-image{
  height:329px !important;
}

.shorts-card {
  width: 230px !important;
}
.shorts-card-image{
  height:409px !important;
}
</style>
<script>
import {computed, ref, toRef} from 'vue';
import {getVideoUrl, secondToFormat, timestampToDateFormat} from '@/assets/js/util';
import CommonLazyImage from '@/components/common/CommonLazyImage';
import ThumbnailVideo from '@/components/common/ThumbnailVideo';
import {
  closeAdded,
  cttTyCdDcds,
  getTop1CategoryByLevel,
  handleActions,
  servTyCdDcds,
} from '@/assets/js/modules/hrd/hrd-common';
import {pushQuery} from '@/assets/js/ui.init';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {getThumbImg} from '@/assets/js/modules/baner/common-baner';
import {getThumbUrl} from '@/assets/js/modules/course/course-common';

export default {
  name: 'HrdCloudContent',
  components: {ThumbnailVideo, CommonLazyImage},
  props: {
    item: Object,
    idx: Number,
    session: Object,
    showPut: {type:Boolean, default:false},
    showIndex: {type: Boolean, default: false},
    isEditable: Boolean,
    isSelected: Boolean,
    showCount: {type:Boolean, default:true},
    isShorts: Boolean,
  },
  setup(props) {
    const route = useRoute(), router = useRouter(), store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);
    const isActiveIdx = ref(-1);
    const videoTimeout = ref(null);
    const hrd = toRef(props, 'item');
    const categoryNms = computed(() => getTop1CategoryByLevel(hrd.value.cloudNmLst));
    const categorySns = computed(() => getTop1CategoryByLevel(hrd.value.cloudKeyLst));
    const isGroupLrn = computed(() => route.name.indexOf('Group') > -1);

    const lvlStr ={
      "1": "초급",
      "3": "중급",
      "5": "고급"
    };

    const handleVideoActive = (idx) => {
      if (idx === -2) {
        isActiveIdx.value = idx;
      }
      if (videoTimeout.value) {
        clearTimeout(videoTimeout.value);
        videoTimeout.value = null;
      }
      videoTimeout.value = setTimeout(() => {
        isActiveIdx.value = idx;
      }, 1000);
    };

    const searchCategory = () => {
      let query = {};
      for (let i = 1; i < 4; i++) {
        let key = 'category' + (Number(i));
        query[key] = categorySns.value[key];
      }
      pushQuery(query);

      if (route.name !== 'HrdCloud') {
        router.push({name: 'HrdCloud', query: query});
      }
      closeModal();
    };

    const setDefaultImg = (e) => {
      e.src = require('../../assets/lxp/images/@tmp/@temp_pdf_new.jpg');
    };

    const closeModal = () => {
      if (!props.isEditable) {
        closeAdded();
      }
    };

    // eslint-disable-next-line no-unused-vars
    const isLessThanAWeek = () => {
      if (props.item.mdfcnDt) return new Date().getTime() - props.item.mdfcnDt < (7 * 1000 * 60 * 60 * 24);
      else if (props.item.lrnProgMdfcnDt) return new Date().getTime() - props.item.lrnProgMdfcnDt < (7 * 1000 * 60 * 60 * 24);
      else return false;
    };

    const handleShowProgress = () => {
      if (props.item.lastPlaySec > 0 && props.item.cttSz > 0) {
        const percent = props.item.lastPlaySec/props.item.cttSz * 100;
        return `${percent > 100 ? 100 : percent}%`;
      }
      return `0%`;
    };

    const getYoutubeThumb = (itn, etnUrl) => {

      let youtubeId = etnUrl.indexOf("watch")>-1 ? etnUrl.split("=").pop() : "";
      youtubeId = etnUrl.indexOf("youtu.be")>-1 ? etnUrl.split("/").pop() : youtubeId;
      console.log('check thumb', itn, youtubeId, etnUrl)
      if(itn){
        return `/files/images/youtube.jpg`;
      } else{
        return `https://i.ytimg.com/vi/${youtubeId}/maxresdefault.jpg`;
      }
    };

    return {
      isLessThanAWeek, handleShowProgress,
      timestampToDateFormat, secondToFormat, getVideoUrl,
      isMobile, isActiveIdx, categoryNms, categorySns,
      handleVideoActive, searchCategory, handleActions,
      servTyCdDcds, closeModal, getThumbImg, cttTyCdDcds, setDefaultImg, getThumbUrl,
      isGroupLrn, getYoutubeThumb, lvlStr,
    };
  },
};
</script>