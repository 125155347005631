<template>
  <LxpMobileHeader :title="`${boardSn === '0' ?  '그룹 생성하기' : '그룹관리'}`">
    <template v-slot:left>
      <div class="util util-back">
        <template v-if="boardSn === '0'">
          <router-link :to="{name: 'GroupApplication'}" class="util-actions util-actions-back" >
            <i class="icon-history-back"></i>
          </router-link>
        </template>
        <template v-else>
          <router-link v-if="params.stt === '00'" :to="{name: 'GroupView', params: {distCrseSn: params.distCrseSn}}" class="util-actions util-actions-back" >
            <i class="icon-history-back"></i>
          </router-link>
          <router-link v-else :to="{name: 'GroupLearningDetailView', params: {distCrseSn: params.distCrseSn}}" class="util-actions util-actions-back" >
            <i class="icon-history-back"></i>
          </router-link>
        </template>
      </div>
    </template>
    <template v-slot:right>
      <div class="util util-apply">
        <a href="javascript:" class="util-actions util-actions-yellow" @click="clickSave">
          {{ boardSn > 0 ? '저장' : '생성' }}
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-prep-group">
    <!-- main-content -->
    <div class="main-content">
      <div>
        <section class="content-section section-forms">
          <!-- kb-form-fields -->
          <div class="kb-form-fields kb-form-fields-v2">
            <!-- kb-form-field:그룹명 -->
            <div class="kb-form-field field-first">
              <div class="kb-form-row">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">그룹명</span>
                  <span class="kb-form-label-text text-red"> *</span>
                </label>
              </div>
              <div class="kb-form-row column-whole">
                <input v-model="params.distCrseNm" type="text" class="kb-form-control" placeholder="그룹명을 입력하세요." :disabled="params.stt === '00'">
              </div>
            </div>
            <!-- kb-form-field:학습분야 -->
            <div class="kb-form-field">
              <div class="kb-form-row mb-2">
                <label class="kb-form-label justify-content-between">
                  <span class="kb-form-label-text">학습분야</span>
                  <span class="kb-form-label-text text-red"> *</span>
                  <span class="text text-muted tip">그룹확정 후 변경 불가</span>
                </label>
              </div>
              <SortButtonSelect3
                  v-model="params.distCrseLrnFldSn"
                  v-model:toggle="relToggle"
                  :options="fldOptions"
                  sequence-key="distCrseLrnFldSn"
                  name-key="distCrseLrnFldNm"
                  title="학습분야 선택"
                  button-class="kb-form-dropdown-btn"
                  :is-row="true"
                  :is-all="false"
                  :width="100"
                  :is-disabled="params.stt === '00'"
              />
            </div>
            <!-- kb-form-field:그룹인원 -->
            <div class="kb-form-field" v-if="params.stt != '00'">
              <div class="kb-form-row">
                <label class="kb-form-label justify-content-between">
                  <span class="kb-form-label-text">그룹인원</span>
                  <span class="kb-form-label-text text-red"> *</span>
                  <span class="text text-muted tip">설정한 인원 모집 완료 시 그룹 일괄 확정</span>
                </label>
              </div>

              <div class="kb-form-row">
                <button class="kb-form-dropdown-btn" @click.stop="clickPeoplCount" :disabled="params.stt === '00'">
                  <span class="text">{{ params.peoplCnt > 0 ? `${params.peoplCnt}명 (리더 포함)` : '그룹인원 선택' }}</span>
                  <i class="icon-arrow"></i>
                </button>
                <div class="kb-form-layer" :class="{'is-active' : peoplCountToggle}">
                  <div class="layer-container">
                    <div class="layer">
                      <div class="kb-form-layer-options">
                        <div class="layer-option">
                          <ul class="layer-option-list">
                            <li v-for="num in 27" :key="num" class="layer-option-item">
                              <a href="javascript:" class="layer-option-link"  @click.stop="selectPeoplCount(num + 1)">
                                <strong class="layer-option-text">{{ num + 1 }}명</strong>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- kb-form-field:그룹목표 -->
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">그룹목표</span>
                  <span class="kb-form-label-text text-red"> *</span>
                </label>
              </div>
              <div class="kb-form-row">
                <input v-model="params.boardSumup" type="text" class="kb-form-control" placeholder="그룹의 달성 목표를 입럭하세요.">
              </div>
            </div>
            <!-- kb-form-field:자기소개 -->
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">자기소개</span>
                  <span class="kb-form-label-text text-red"> *</span>
                </label>
              </div>
              <div class="kb-form-row">
                <textarea v-model="params.selfIntro" name="" id="" placeholder="자기소개 및 각오를 입력하세요." class="kb-form-control"></textarea> <!-- :disabled="params.stt === '00'" -->
              </div>
            </div>
            <!-- kb-form-field:비밀번호 -->
            <div class="kb-form-field d-flex" v-if="params.stt != '00'">
              <div class="kb-form-column column-whole">
                <div class="kb-form-row">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">비밀번호</span>
                  </label>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-text">{{ pwdUseYn ? '사용' : '미사용' }}</div>
                </div>
              </div>
              <div class="kb-form-column">
                <div class="kb-form-switch">
                  <label class="kb-form-switch-label">
                    <input v-model="pwdUseYn" type="checkbox" class="kb-form-switch-input" @change="changePwdUseYn" :disabled="params.stt === '00'">
                    <span class="kb-form-switch-slider"></span>
                  </label>
                </div>
              </div>
            </div>
            <!-- //kb-form-field -->

            <div v-if="pwdUseYn && params.stt != '00'" class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label">
                  <span class="kb-form-label-text">비밀번호 설정</span>
                </label>
              </div>
              <div class="kb-form-row">
                <input v-model="tempPwd" type="password" class="kb-form-control" @input="checkPwd" :disabled="params.stt === '00'">
              </div>
            </div>

          </div>
          <!-- //kb-form-fields -->
          <!-- kb-form-fields-bottom -->
          <div class="kb-form-fields-bottom">
            <strong class="text">유의사항</strong>
            <p class="text">1인당 1개 분야 ・ 1개 그룹만 참여 가능합니다. </p>
          </div>
          <!-- //kb-form-fields-bottom -->
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import {groupLearningMngSetup} from "@/assets/js/modules/groupLearing/group-learning-mng-setup";
import SortButtonSelect3 from "@/components/common/SortButtonSelect3";

export default {
  name: "MobileGroupLearningMng",
  components: {SortButtonSelect3, LxpMobileHeader},
  setup: groupLearningMngSetup
}
</script>