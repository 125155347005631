<template>
  <div class="post-list" :class="[isSpecial() && 'special']" :data-read="refinedItem.myViewCnt > 0 ? 'active' : ''">
    <a href="javascript:" class="post-link" @click="goNote(refinedItem)">
<!--    <router-link :to="getPath(refinedItem)" class="post-link" @click="moveBoard">-->
      <section class="left">
        <div class="post-image-wrap">
          <template v-if="isSpecial()">
            <div class="post-line">
              <img src="@/assets/lxp/mobile/images/ssl/update/special_line.svg" alt="">
            </div>
            <div class="post-badge">
              <img src="@/assets/lxp/mobile/images/ssl/update/crown.gif" alt="">
            </div>
          </template>

          <div class="post-content-type">
            <div v-if="refinedItem.refCttTyNm === 'VOD'" class="badge-vod"><span class="text">영상</span></div>
            <div v-else-if="refinedItem.refCttTyNm === 'PDF'" class="badge-pdf"><span class="text">PDF</span></div>
            <div v-else class="badge-note"><span class="text">노트</span></div>
          </div>

          <div class="post-image">
            <v-lazy-image v-if="refinedItem.thumb" :src="refinedItem.thumb" />
            <div class="read">
              <i class="icon-read"></i>읽음
            </div>
          </div>
        </div>
      </section>
      <section class="right">
        <div class="post-list-desc top">
          <div class="post-title">
            <h3>{{ refinedItem.title }}</h3>
          </div>
          <div class="post-desc">
            <p class="text"></p>
          </div>
        </div>
        <div class="post-list-desc bottom">
          <div>
            <p class="post-date">
              {{ refinedItem.ymd }}
            </p>
            <p class="view-text">
              <i class="icon-view"></i>
              <span class="views">{{ refinedItem.inqcnt }}</span>
            </p>
            <p class="view-text">
              <i class="icon-like"></i>
              <span class="views">{{ refinedItem.likeCnt }}</span>
            </p>
          </div>
          <div class="post-profile">
            <div class="author2">
              <span class="name">{{ refinedItem.lrnerNm }}</span><span class="office">{{ isGroup ?  refinedItem.refTyNm : refinedItem.deptNm }}</span>
            </div>
          </div>
        </div>
      </section>
    </a>
<!--    </router-link>-->
  </div>

<!--  <SSLEnroll-->
<!--      v-if="enroll.toggle"-->
<!--      v-model:toggle="enroll.toggle"-->
<!--      :dist-crse-cd="enroll.key"-->
<!--      :socLrnPostSn="enroll.socLrnPostSn"-->
<!--  />-->
</template>
<script>

import {useRoute, useRouter} from "vue-router";
import {dateToDateFormat, timestampToDateFormat} from '@/assets/js/util';
import {computed, reactive} from "vue";
import VLazyImage from "v-lazy-image";
import {getTargetDt, getCrseSnToDistCrseCd} from '@/assets/js/modules/ssl/ssl-common'; //, isMyLrnTrgtGrpMstSn
import {useStore} from 'vuex';
// import SSLEnroll from "@/components/ssl/common/SSLEnroll.vue";

export default {
  name: 'SSLMobileBoardCard',
  methods: {getCrseSnToDistCrseCd},
  components:{
    // SSLEnroll,
    VLazyImage
  },
  props: {
    item: {
      required: true,
      type: Object
    },
    isGroup: Boolean
  },
  emits: ['moveBoard'],
  setup(props, {emit}) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const excellentTargets = computed(() => store.state.ssl.excellents.map(x => x.lrnerId+"-"+x.lrnTrgtGrpMstSn));
    const excellentDay = computed(() => store.state.ssl.excellents[0] ? store.state.ssl.excellents[0].regDt : null);

    const enroll = reactive({
      key: null,
      toggle: false,
    });

    const refinedItem = computed(() => {
      return {
        ...props.item,
        ymd: timestampToDateFormat(getTargetDt(props.item), 'yyyy.MM.dd')
      }
    });


    const getPath = (item) => {
      if(item.stt === '01'){
        return {name: 'SSLWrite', params: {socLrnPostSn: item.socLrnPostSn}};
      }else{
        if(route.name === 'SSLMyBoard'){
          if(timestampToDateFormat((item.pblcnDt ? item.pblcnDt : item.regDt), 'yyyy-MM-dd') === dateToDateFormat(new Date(), 'yyyy-MM-dd')){
            return {name: 'SSLWrite', params: {socLrnPostSn: item.socLrnPostSn}};
          }else{
            return {name: 'SSLView', params: {socLrnPostSn: item.socLrnPostSn}};
          }
        }else{
          if (route.name=== 'SSLHome' || route.name === 'SSLBoardSearch'){
            return {name: 'SSLView', params: {distCrseCd:getCrseSnToDistCrseCd(item.crseMstSn), lrnTrgtGrpMstSn:item.lrnTrgtGrpMstSn, socLrnPostSn: item.socLrnPostSn}};
          }else {
            return {name: 'SSLView', params: {socLrnPostSn: item.socLrnPostSn}};
          }
        }
      }
    }

    const isSpecial = () => {
      return excellentTargets.value.includes(props.item.lrnerId+"-"+props.item.lrnTrgtGrpMstSn) && ((props.item.pblcnDt ? props.item.pblcnDt : props.item.regDt) >= excellentDay.value);
      // return excellentTargets.value.includes(props.item.lrnerId) && timestampToDatePart((props.item.pblcnDt ? props.item.pblcnDt : props.item.regDt)).ymd > halfYmd;
    }

    const moveBoard = () => {
      emit('moveBoard', props.item.socLrnPostSn);
    }

    const goNote = (item) => {
      const distCrseCd = computed(() => getCrseSnToDistCrseCd(item.crseMstSn));

      // if (isMyLrnTrgtGrpMstSn(item.lrnTrgtGrpMstSn)|| distCrseCd.value == 'trivia') { //신청한 과정인경우
        emit('moveBoard', props.item.socLrnPostSn);

        router.push({
          name: 'SSLView',
          params: {distCrseCd: distCrseCd.value, lrnTrgtGrpMstSn: item.lrnTrgtGrpMstSn, socLrnPostSn: item.socLrnPostSn}
        });
      // }else{
      //   enroll.key = distCrseCd.value;
      //   enroll.toggle = true;
      //   enroll.socLrnPostSn = item.socLrnPostSn;
      // }
    }


    return {
      refinedItem,
      getPath,
      isSpecial,
      moveBoard,
      goNote,
      enroll
    }
  }
}
</script>
