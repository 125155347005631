<template>
  <main class="ssl-body" :class="{'ssl-preview' : previewVisible}" style="padding-bottom: 0; !important;"> <!-- @click="toggleMenu(false)"  -->
    <!-- 임시로 적용해봄... -->
    <div v-show="showOcrMaskingModal">
      <CheckOcrMaskingModal
          v-if="showOcrMaskingModal"
          v-model="showOcrMaskingModal"
          v-model:selected-value="textInfo"
          v-model:ocrImageUrl="ocrImageUrl"
      />
    </div>
    <div v-show="!showOcrMaskingModal">
      <SSLMobileWriteHeader
          :step="step"
          :go-func="goStep"
          :back-func="backStep"
          :validation-step="validationStep"
          :preview-visible="previewVisible">

        <template v-slot:sel-option>
          <SortButtonSelect3
              v-if="socLrnPostSn == 0"
              v-model="selOpt.lrnTrgtGrpMstSn"
              v-model:toggle="dccToggle"
              title="분야 선택"
              :options="distOptions"
              sequence-key="lrnTrgtGrpMstSn"
              name-key="crseNm"
              :is-all="false"
              :width="300"
              :textStyle="`font:normal 18px var(--kb-font-KBFGTextB);color:#757575`"
              @selected="selDistOption"
              :is-disabled="socLrnPostSn>0"
          />
          <template v-else><span style="font:normal 18px var(--kb-font-KBFGTextB);color:#757575">{{distCrseNm}}</span></template>
        </template>
        <template v-slot:btn-area>
<!--          <button class="publish-btn" @click="togglePreviewVisible(true);">미리보기</button>-->
<!--          <button class="publish-btn" @click="toggleMenu(true);">다음</button>-->
          <div class="util util-more">
            <div class="dropdown is-active" data-offset="header-left">
              <button class="publish-btn" @click="toggleMenu(!isMenuActive);">다음</button>
              <div class="dropdown-target" style="width: 100px;left: -60px;" v-if="isMenuActive">
                <div class="dropdown-box">
                  <ul class="dropdown-option-list">
                    <li class="dropdown-option-item">
                      <a href="javascript:" class="dropdown-option-link" @click="togglePreviewVisible(true)">
                        <span class="menu-text">미리보기</span>
                      </a>
                    </li>
                    <li class="dropdown-option-item">
                      <a v-if="stt === '' || stt === '01'" href="javascript:" class="dropdown-option-link" @click="clickSaveButton(true)">
                        <span class="menu-text">임시저장</span>
                      </a>
                    </li>
                    <li class="dropdown-option-item">
                      <a href="javascript:" class="dropdown-option-link" @click="clickSaveButton(false)">
                        <span class="menu-text">저장</span>
                      </a>
                    </li>
                    <li class="dropdown-option-item">
                      <a v-if="socLrnPostSn > 0 && stt === '01'" href="javascript:" class="dropdown-option-link" @click="clickDeleteButton">
                        <span class="menu-text">삭제</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
      </SSLMobileWriteHeader>
      <!-- 임시로 적용해봄... -->

      <SSLMobilePreview
          v-if="previewVisible"
          :soc-board="socBoard"
          :current-page-idx="currentPageIdx"
          :soc-pages="socPages"
          :hash-tags="hashTags"
      />

<!--      <div v-show="!previewVisible && step === 0" class="page-container">-->
      <div v-show="!previewVisible" class="page-container">
<!--        <div class="editor content-edit-menu" style="margin-top: 28px;" :class="{'is-active': isMenuActive}">-->
<!--          <ul class="menu-list" style="min-width:100px">-->
<!--            <li class="menu-item">-->
<!--              <a href="javascript:" class="menu-link" @click="togglePreviewVisible(true)">-->
<!--                <span class="menu-text">미리보기</span>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li class="menu-item">-->
<!--              <a v-if="stt === '' || stt === '01'" href="javascript:" class="menu-link" @click="clickSaveButton(true)">-->
<!--                <span class="menu-text">임시저장</span>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li class="menu-item">-->
<!--              <a href="javascript:" class="menu-link" @click="clickSaveButton(false)">-->
<!--                <span class="menu-text">저장</span>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li class="menu-item">-->
<!--              <a v-if="socLrnPostSn > 0 && stt === '01'" href="javascript:" class="menu-link" @click="clickDeleteButton">-->
<!--                <span class="menu-text">삭제</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->

        <div class="thumbnail-container">
          <div style="display: flex;justify-content: flex-start; margin-bottom: 10px;height:50px">
              <!-- title-wrap -->
              <div class="title-wrap mt-0" style="width:80%;height:50px">
<!--                <p class="sub-title">{{ distCrseNm }}</p>-->
                <input ref="titleIns" v-model.trim="socBoard.title" type="text" class="input-title" placeholder="제목을 입력하세요.">
              </div>
              <!-- //title-wrap -->

              <!-- image-wrap -->
              <div class="image-wrap" style="width:20%;height:50px">
                <div style="display: flex; justify-content: center">
                  <input ref="uploadFile" v-show="false" type="file" @change="applyMobileThumbnail"/>
                  <button class="image-btn-50" @click.stop="toggleThumb(true)">
                    <img v-if="socBoard.thumb" :src="socBoard.thumb" alt="">
                  </button>
                  <p class="thumb-placeholder" v-if="!socBoard.thumb" @click.stop="toggleThumb(true)">썸네일</p>
                </div>
                <!-- 썸네일 이미지 등록 메뉴 -->
                <!--  is-active 로 on/off 제어 -->
                <div class="editor thumbnail-edit-menu-sm" :class="{'is-active': isThumbActive}">
                  <ul class="menu-list-sm">
                    <li class="menu-item title">썸네일 이미지</li>
                    <li class="menu-item">
                      <a href="javascript:" class="menu-link" @click="clickUploadFile">
                        <span class="menu-text">내 이미지</span>
                      </a>
                    </li>
                    <li class="menu-item">
                      <a href="javascript:" class="menu-link" @click="setRecommend">
                        <span class="menu-text">추천 이미지</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- //image-wrap -->
          </div>

          <!-- tags-wrap -->
          <div class="tags-wrap">
            <div class="tag-list">
              <div v-for="(hashTag, idx) in hashTags" class="tag-item is-active" :key="idx">
                <span class="tag-item-text">{{ hashTag }}</span>
                <button class="kb-btn-tag" @click="deleteHashTag(idx)">
                  <i class="icon-tag-delete"></i>
                </button>
              </div>
              <div class="tag-item tag-input">
                <input v-model.trim="hashTag" type="text" class="tag-item-input" placeholder="태그입력" @keyup.enter="addHashTag"> <!-- @mouseleave="addHashTag" -->
              </div>
            </div>
          </div>
          <!-- //tags-wrap -->
          <!-- source-wrap -->
          <SSLMobileWriteRel
              :socLrnPostSn="Number(socLrnPostSn)"
              v-model:ref-ty-cd-dcd="socBoard.refTyCdDcd"
              v-model:ref-post="socBoard.refPost"
              v-model:ref-url="socBoard.refUrl"
          />
          <!-- //source-wrap -->
        </div>
<!--      </div>-->

<!--      <div v-show="!previewVisible && step === 1" class="page-container">-->
        <div class="editor-container" style="padding:0 !important;">
          <!-- editor-body -->
          <div class="editor-body">
<!--            <div class="editor-body-top">-->
<!--              <input v-model.trim="socBoard.title" type="text" placeholder="제목">-->
<!--              <button type="button" class="kb-btn-editor-more" @click.stop="toggleMenu(true)">-->
<!--                <i class="icon-more"></i>-->
<!--              </button>-->
<!--              &lt;!&ndash; 썸네일 이미지 등록 메뉴 &ndash;&gt;-->
<!--              &lt;!&ndash;  is-active 로 on/off 제어 &ndash;&gt;-->
<!--              <div class="editor content-edit-menu" :class="{'is-active': isMenuActive}">-->
<!--                <ul class="menu-list">-->
<!--                  <li class="menu-item">-->
<!--                    <a href="javascript:" class="menu-link" @click="togglePreviewVisible(true)">-->
<!--                      <span class="menu-text">미리보기</span>-->
<!--                    </a>-->
<!--                  </li>-->
<!--                  <li class="menu-item">-->
<!--                    <a v-if="stt === '' || stt === '01'" href="javascript:" class="menu-link" @click="clickSaveButton(true)">-->
<!--                      <span class="menu-text">임시저장</span>-->
<!--                    </a>-->
<!--                  </li>-->
<!--                  <li class="menu-item">-->
<!--                    <a v-if="socLrnPostSn > 0 && stt === '01'" href="javascript:" class="menu-link" @click="clickDeleteButton">-->
<!--                      <span class="menu-text">삭제</span>-->
<!--                    </a>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </div>-->
            <div class="editor-body-content" style="background: #FFFFFF; height:60%">
              <div v-show="false" id="editorTextarea" ref="pageEditor"></div>
              <iframe v-if="isInApp" ref="editorIframe" width="100%"></iframe>
<!--              <iframe v-if="isInApp" ref="editorIframe" width="100%" height="100%"></iframe>-->
            </div>

            <div class="editor-content-footer" style="margin-top: 5px">
              <div class="content-byte">
                <span class="current">{{ textCount }}</span>
                <span class="total">{{ maxTextLen }}</span>
              </div>
            </div>
<!--            <div class="mb-3 mt-0" style="display: flex;justify-content:space-around;padding:0 2%">-->
<!--              <button v-if="stt === '' || stt === '01'" class="kb-btn kb-btn-secondary kb-btn-xs" @click="clickSaveButton(true)" style="width:32%;">-->
<!--                <span class="text">임시저장</span>-->
<!--              </button>-->
<!--              <button class="kb-btn kb-btn-primary kb-btn-xs" @click="clickSaveButton(false)" style="width:32%">-->
<!--                <span class="text">저장</span>-->
<!--              </button>-->
<!--              <button v-if="socLrnPostSn > 0 && stt === '01'" class="kb-btn kb-btn-silver kb-btn-xs" @click="clickDeleteButton" style="width:32%">-->
<!--                <span class="text">삭제</span>-->
<!--              </button>-->
<!--            </div>-->
          </div>
          <!-- //editor-body -->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SSLMobileWriteRel from '@/components/ssl/write/mobile/SSLMobileWriteRel';
import SSLMobileWriteHeader from '@/components/ssl/write/mobile/SSLMobileWriteHeader';
import SSLMobilePreview from '@/components/ssl/write/mobile/SSLMobilePreview';
import {sslWriteSetup} from '@/assets/js/modules/ssl/setup/ssl-write-setup';
import CheckOcrMaskingModal from "@/components/support/CheckOcrMaskingModal.vue";
import SortButtonSelect3 from "@/components/common/SortButtonSelect3.vue";

export default {
  name: 'SSLMobileWrite',
  components: {
    CheckOcrMaskingModal,
    SSLMobilePreview,
    SSLMobileWriteHeader,
    SSLMobileWriteRel,
    SortButtonSelect3
  },
  setup: sslWriteSetup
}
</script>
