import ApiService from "@/assets/js/api.service";

// ACTION
export const ACT_GET_USER_INTEREST_INFO_LIST = "actGetUserInterestInfoList";
export const ACT_GET_USER_INTEREST_RECMD_INFO_LIST = "actGetUserInterestRecmdInfoList";
export const ACT_INSERT_USER_INTEREST_INFO = "actInsertUserInterestInfo";
export const ACT_UPDATE_USER_INTEREST_INFO = "actUpdateUserInterestInfo";

const state = {};

const mutations = {};

const actions = {
    [ACT_GET_USER_INTEREST_INFO_LIST](context, params) {
        return new Promise((resolve) => {
            ApiService.query("/v1/app/interest/user/info/list", params).then((response) =>
                resolve(response)
            );
        });
    },
    [ACT_GET_USER_INTEREST_RECMD_INFO_LIST](context, params) {
        return new Promise((resolve) => {
            ApiService.query("/v1/app/interest/user/recmd/info/list", params).then((response) =>
                resolve(response)
            );
        });
    },
    [ACT_INSERT_USER_INTEREST_INFO](context, params) {
        return new Promise((resolve) => {
            ApiService.post("/v1/app/interest/user/info", params).then((response) =>
                resolve(response)
            );
        });
    },
    [ACT_UPDATE_USER_INTEREST_INFO](context, params) {
        return new Promise((resolve) => {
            ApiService.put("/v1/app/interest/user/info", params).then((response) =>
                resolve(response)
            );
        });
    },
};


export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
