import store from '@/store';
import {commonDownloadFile} from "@/assets/js/util";
import {ACT_DOWNLOAD_COM_ATCH} from "@/store/modules/board/board";

export const expsrLocCds = {
    EXPSR_LOC_CD_MAIN : '2100001',  // 연수시스템메인
    EXPSR_LOC_CD_SSL_MAIN : '2100002',  // SSL_홈
    EXPSR_LOC_CD_SSL_SELF : '2100003',  // SSL_셀프러닝
    EXPSR_LOC_CD_SSL_GROUP : '2100004',  // SSL_그룹러닝
    EXPSR_LOC_CD_SSL_KNOWLEDGE : '2100005',  // SSL_지식e러닝
    EXPSR_LOC_CD_SSL_HRD : '2100006',  // SSL_HRD클라우드러닝
    EXPSR_LOC_CD_SSL_BOOK : '2100007',  // SSL_북러닝
    EXPSR_LOC_CD_SSL_TRIVIA : '2100008',  // SSL_알SSL신잡
    EXPSR_LOC_CD_TAKE : '2100009',  // 휴직자복직지원
    EXPSR_LOC_CD_ON_BOARDING : '2100010',  // 신입행원온보딩
    EXPSR_LOC_CD_MENTORING : '2100011',  // KB멘토링
    EXPSR_LOC_CD_SSM : '2100012',  // KB씀(SSM)
    EXPSR_LOC_CD_SSL_COMMON : '2100013',  // SSL_공통
    EXPSR_LOC_CD_SSL_COLLEGE : '2100014',  // SSL_직무College
    EXPSR_LOC_CD_SSL_LEARNING_LIVE_REVIEW : '2100015',  // SSL_러닝라이브리뷰
    EXPSR_LOC_CD_SUMGO_MAIN : '2100016',  // 숨고
    EXPSR_LOC_CD_HRDCLOUD_MAIN : '2100017',
    EXPSR_LOC_CD_BPCC_MAIN : '2100018', // HRD CLOUD NOTICE

};

export const libTyCdDcds = {
    LIB_TY_CD_LIB : '2101001', //자료실
    LIB_TY_CD_TAKE_LIB : '2101002', //휴직자복직지원
}

export const downloadComAtch = (atch) => {
    commonDownloadFile(atch);
};

export const downloadAtch = (atch) => {
    store.dispatch(`board/${ACT_DOWNLOAD_COM_ATCH}`, { secuKey: atch.secuKey, fileName: atch.fileNm });
};