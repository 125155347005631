<template>
  <nav class="list-tabs">
    <ul class="tab-list">
      <li v-for="(category,idx) in categories" class="tab-item" :class="{'is-active': category.hrdCloudCateSn === nowCateSn}" :key="idx">
          <a href="javascript:" class="tab" @click="handleCategoryShorts(category)" >
            {{ category.hrdCloudCateNm }}
          </a>
      </li>
    </ul>
  </nav>
</template>
<style scoped>

.tab-list {
  display: inline-flex;
  padding: 0 16px;
  max-width: 100vw;
  overflow-x: auto;
}

.tab-item {
  display:flex;
  align-items:center;
  margin-left:8px;
  justify-content:center;
  min-width:38px;
  height:34px;
  border-radius:var(--kb-border-radius-pill); border:1px solid var(--kb-light-silver); color:var(--kb-silver);
  padding:0 16px;
  flex:0 0 auto;
  white-space:nowrap;
}

.tab-item:hover {color:var(--kb-dark-brown)}
.tab-item.is-active {background-color:var(--kb-background-brown);}

</style>
<script>
import {reactive, ref, watch} from 'vue';
import {useRoute} from 'vue-router';
// import {useStore} from 'vuex';
import {initParams,lengthCheck} from '@/assets/js/util';
import {pushQuery} from '@/assets/js/ui.init';
import {getShortsCateList} from "@/assets/js/modules/hrd/hrd-common";

export default {
  name: 'HrdCloudRouteForShorts',
  setup(props, {emit}) {

    const route = useRoute();
    const searchParam = reactive({
      category2: 0,
      category3: 0,
      category4: 0,
      category5: 0,
      pageNo: 1
    });

    const isLoading = ref(false);

    const toggleLvl1 = ref({hrdCloudCateSn: 0, activate: false}),
        toggleLvl2 = ref({hrdCloudCateSn: 0, activate: false}),
        toggleLvl3 = ref({hrdCloudCateSn: 0, activate: false}),
        toggleLvl4 = ref({hrdCloudCateSn: 0, activate: false}),
        toggleLvl5 = ref({hrdCloudCateSn: 0, activate: false});
    const nowCateSn = ref(-99);

    // search requestParam에서 가져와서 넣어줘야함
    const categories =  ref([
      {
        hrdCloudCateNm: '전체',
        hrdCloudCateSn: 0,
        lvl: 1
      }
    ]);


    const handleCategoryShorts = (menu) => {
      selectOptions(menu.hrdCloudCateSn, menu.lvl);
    };

    const getMainCategories = async () => {

      if(isLoading.value) return;
      isLoading.value = true;

      let res = await getShortsCateList({lvl: 1});
      isLoading.value = false;
      categories.value = [
        {
          hrdCloudCateNm: '전체',
          hrdCloudCateSn: -99,
          lvl: 1
        }
      ];
      if (lengthCheck(res)) {

        categories.value = categories.value.concat(res.items);
      }
    };

    const category1 = ref(-99);
    const category2 = ref(0);
    const category3 = ref(0);
    const category4 = ref(0);
    const category5 = ref(0);

    const selectOptions = (hrdCloudCateSn, lvl) => {

      searchParam.category2 = 0;
      searchParam.category3 = 0;
      searchParam.category4 = 0;
      searchParam.category5 = 0;
      toggleLvl2.value.hrdCloudCateSn = 0;
      toggleLvl3.value.hrdCloudCateSn = 0;
      toggleLvl4.value.hrdCloudCateSn = 0;
      toggleLvl5.value.hrdCloudCateSn = 0;

      if (lvl === 1) {
        toggleLvl1.value.activate = !toggleLvl1.value.activate;

        searchParam.category1 = hrdCloudCateSn;
        toggleLvl1.value.hrdCloudCateSn = hrdCloudCateSn;
        if (category2.value.length === 0) emit('update:toggle', false);
      } else if (lvl === 2) {

        searchParam.category2 = hrdCloudCateSn;
        toggleLvl2.value.hrdCloudCateSn = hrdCloudCateSn;
        if (category3.value.length === 0) emit('update:toggle', false);
      } else if (lvl === 3) {

        searchParam.category3 = hrdCloudCateSn;
        toggleLvl3.value.hrdCloudCateSn = hrdCloudCateSn;
        toggleLvl3.value.activate = true;
        if (category4.value.length === 0) emit('update:toggle', false);
      } else if (lvl === 4) {

        searchParam.category4 = hrdCloudCateSn;
        toggleLvl4.value.hrdCloudCateSn = hrdCloudCateSn;
        toggleLvl4.value.activate = true;
        if (category5.value.length === 0) emit('update:toggle', false);
      } else if (lvl === 5) {

        searchParam.category5 = hrdCloudCateSn;
        toggleLvl5.value.hrdCloudCateSn = hrdCloudCateSn;
        toggleLvl5.value.activate = true;
        emit('update:toggle', false);
      }
    };

    const setParamsByQuery = () => {
      if (route.name && route.name.indexOf('HrdCloud') > -1 && route.query) {
        getMainCategories();
        initParams(toggleLvl1.value);
        initParams(toggleLvl2.value);
        initParams(toggleLvl3.value);
        initParams(toggleLvl4.value);
        initParams(toggleLvl5.value);

        if (route.query.category1) {
          category1.value = Number(route.query.category1);
          toggleLvl1.value.hrdCloudCateSn = category1.value;
          if(searchParam.category1==-99)nowCateSn.value = searchParam.category1;
        }
        if (route.query.category2) {
          toggleLvl2.value.hrdCloudCateSn = Number(route.query.category2);
          searchParam.category2 = Number(route.query.category2);
          if(searchParam.category2>0)nowCateSn.value = searchParam.category2;
        }
        if (route.query.category3) {
          toggleLvl3.value.hrdCloudCateSn = Number(route.query.category3);
          searchParam.category3 = Number(route.query.category3);
          if(searchParam.category3>0)nowCateSn.value = searchParam.category3;
        }
        if (route.query.category4) {
          toggleLvl4.value.hrdCloudCateSn = Number(route.query.category4);
          searchParam.category4 = Number(route.query.category4);
          if(searchParam.category4>0)nowCateSn.value = searchParam.category4;
        }
        if (route.query.category5) {
          toggleLvl5.value.hrdCloudCateSn = Number(route.query.category5);
          searchParam.category5 = Number(route.query.category5);
          if(searchParam.category5>0)nowCateSn.value = searchParam.category5;
        }
        if (route.query.pageNo) {
          searchParam.pageNo = Number(route.query.pageNo);
        }
      }
    };

    watch(() => route.query, () => {
      setParamsByQuery();

    }, {deep: true});

    watch(() => searchParam, () => {
      pushQuery({
        ...route.query,
        pageNo: searchParam.pageNo,
        category2: toggleLvl2.value.hrdCloudCateSn,
        category3: toggleLvl3.value.hrdCloudCateSn,
        category4: toggleLvl4.value.hrdCloudCateSn,
        category5: toggleLvl5.value.hrdCloudCateSn,
      });
    }, {deep: true});

    watch(() => category1.value, () => {
      // console.log('category 2 : ',category2.value);
      // console.log('toggleLvl1 : ',toggleLvl1.value);
    });

    setParamsByQuery();

    return {
      categories, nowCateSn,
      category1, category2, category3, category4, category5,
      toggleLvl1, toggleLvl2, toggleLvl3, toggleLvl4, toggleLvl5,
      selectOptions, handleCategoryShorts
    }
  }
};
</script>