<template>
  <div class="myssl-top">
    <div> <!-- class="top-my" -->
      <div class="my-avatar">
        <div class="avatar">
          <SSLProfileImg :target="lrner" />
        </div>
      </div>
<!--      <div class="my-title">-->
<!--        <h2 class="title">-->
<!--          <img src="@/assets/lxp/images/ssl/myssl/ic_myssl.svg" alt/>-->
<!--        </h2>-->
<!--      </div>-->

<!--      <div class="my-category" :class="categoryClass">-->
<!--        <span v-for="(category, index) in categories" class="category" :key="index">{{ category }}</span>-->
<!--      </div>-->

      <div class="my-name">
        <h3 class="nickname mb-2">{{lrner.chnNm}}</h3>
        <span class="name" style="margin-right: 5px">{{ lrner.lrnerNm }}</span>
        <span class="name text-muted">{{ distCrseCd === 'group' ? myGroup.crseNm : lrner.deptNm }}</span>
      </div>
      <div class="mt-3">
        <button class="link" @click="showMyPortraitModal"><span class="text text-gold" style="font:var(--kb-display16-fontB)">채널명 설정</span><i class="icon-arrow20-right-gold"/></button>
      </div>

      <div class="my-links" v-if="myGroup.length > 0">
<!--        <a href="javascript:" class="link">-->
<!--          <i class="icon-my-ssl"></i>-->
<!--        </a>-->
        <div class="link is-active" @click="goGroup"><i class="icon-my-group"></i></div>

<!--        <router-link v-if="myGroup.length > 0" :to="{name: 'GroupView', params: {distCrseSn: myGroup[0].distCrseSn}}" class="link is-active"><i class="icon-my-group"></i></router-link>-->
<!--        <router-link v-else :to="{name: 'GroupLearning'}" class="link is-active"><i class="icon-my-group"></i></router-link>-->
      </div>
<!--      <div v-else-if="distCrseCd === 'knowledge'" class="my-links">-->
<!--        <a href="javascript:" class="link">-->
<!--          <i class="icon-my-ssl"></i>-->
<!--        </a>-->
<!--        <router-link :to="{name: 'KnowledgeELearning'}" class="link is-active">-->
<!--          <i class="icon-my-test"></i>-->
<!--        </router-link>-->
<!--      </div>-->
    </div>

    <SSLTotalMyRoute :year="year" >
<!--      <template v-slot:page-name>-->
<!--        <div style="width:12%; text-align: left;">-->
<!--          <h3 class="tab-text text-dark mt-3" style="font-family: var(&#45;&#45;kb-font-KBFGDisplayB);font-size: 24px;font-weight: 500;color: var(&#45;&#45;bs-heading-color);line-height: 1.2;">SSL Studio</h3>-->
<!--        </div>-->

<!--      </template>-->
    </SSLTotalMyRoute>

    <section class="myssl-section section-mybadge" style="width:100% ;margin-top:10px ">
      <slot name="year-subj"></slot>
    </section>
<!--    <SSLMyWrite v-if="showWrite"/>-->
    <MyPortraitModal userProfileTyCdDcd="2161003" :afterFnct="getLearnTarget" />
  </div>
</template>

<script>
import SSLTotalMyRoute from '@/components/ssl/my/SSLTotalMyRoute';
// import SSLMyWrite from '@/components/ssl/my/SSLMyWrite';
import SSLProfileImg from '@/components/ssl/common/SSLProfileImg';
import MyPortraitModal from '@/components/mypage/MyPortraitModal.vue';
import {sslMyTopSetup} from '@/assets/js/modules/ssl/setup/ssl-my-setup';

export default {
  name: 'SSLMyTop',
  props: {
    showWrite: Boolean,
    year:String
  },
  components: {
    SSLProfileImg,
    SSLTotalMyRoute,
    // SSLMyWrite,
    MyPortraitModal,
  },
  setup: sslMyTopSetup
}
</script>
