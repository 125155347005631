<template>
  <div class="page-container">
    <div class="page-body">
      <div class="page-component">
        <div class="home-section-row">
          <SSLEvent/>
          <SSLHomeProfile :myInfo="myInfo"/>
        </div>

        <!-- 공지사항 -->
        <div class="home-section-row">
          <SSLHomeNotice/>
        </div>

        <!-- 내 활동 -->
        <div class="home-section-row">
          <SSLActivity/>
        </div>

        <!-- 학습현황 -->
        <div class="home-section-row">
        <SSLHomeStatusV2 />
        </div>

        <!-- 내 배지 -->
<!--        <div class="home-section-row">-->
<!--          <SSLHomeBadge/>-->
<!--        </div>-->

        <!-- begin::학습노트-->
        <section class="note section_note">
          <div class="section-title-area">
            <div class="titles fl">
              <h2 class="title">SSL Studio</h2>
              <span class="bar"></span>
              <ul class="title-buttons">
                <li v-for="(item, idx) in sslList"
                    :key="idx"
                    :class="{ 'is-active' : sslCardFilter === item.name }"
                    @click="sslCardFilter = item.name">
                  <a href="javascript:">{{ item.title }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="board-list-wrapper" style="padding:0 20px">
            <!-- board-list -->
            <template v-if="sslLoading">
              <LoadingDiv v-model="sslLoading" />
            </template>
            <template v-else>
              <!-- board-list -->
              <template v-if="sslitems.length > 0">
                <SSLNoteCard :slides-per-view="4"
                             :swiper-class="`excellent-board-card-type`"
                             :sslItems="sslitems"/>
              </template>
              <template v-else>
                <div class="search-container">
                  <div class="result-empty" style="text-align: center; margin-top: 30px; margin-bottom: 30px;"><img
                      src="../../assets/lxp/images/common/img_empty.png" alt="">
                  </div>
                </div>
              </template>
            </template>
            <!-- //board-list -->
          </div>
        </section>

        <section class="note section_note">
          <div class="section-title-area">
            <div class="titles fl">
              <h2 class="title">구독채널 신규</h2>
              <template  v-if="subscribeCnt == 0">
                <span class="bar"></span>
                <span class="text-muted mt-1">구독중인 채널이 없어 인기 채널의 신규 콘텐츠를 제공합니다. 관심 채널을 구독해보세요!</span>
              </template>
            </div>
          </div>
          <div class="board-list-wrapper" style="padding:0 20px">
            <!-- board-list -->
            <template v-if="sslSubscLoading">
              <LoadingDiv2 v-model="sslSubscLoading" />
            </template>
            <template v-else>
              <!-- board-list -->
              <template v-if="subscribeItems.length > 0">
                <SSLNoteCard :slides-per-view="4"
                             :swiper-class="`excellent-board-card-type`"
                             :sslItems="subscribeItems"/>
              </template>
              <template v-else>
                <div class="search-container">
                  <div class="result-empty" style="text-align: center; margin-top: 30px; margin-bottom: 30px;"><img
                      src="../../assets/lxp/images/common/img_empty.png" alt="">
                    <p class="text">구독직원의 SSL 학습노트가 없습니다.</p>
                  </div>
                </div>
              </template>
            </template>
            <!-- //board-list -->
          </div>
        </section>

        <SSLLink/>
      </div>
    </div>
  </div>
</template>

<script>

import {getAllSubscribes, getCrseSnToDistCrseCd, sslRoutes, getMyInfo} from "@/assets/js/modules/ssl/ssl-common"; // getProfileImg,
import SSLHomeProfile from "@/components/ssl/home/SSLHomeProfile";
// import SSLHomeStatus from "@/components/ssl/home/SSLHomeStatus";
import SSLHomeStatusV2 from '@/components/ssl/home/SSLHomeStatusV2.vue';
// import SSLHomeBadge from "@/components/ssl/home/SSLHomeBadge";
import SSLLink from "@/components/ssl/home/SSLLink";
import SSLActivity from "@/components/ssl/home/SSLActivity";
import SSLEvent from "@/components/ssl/home/SSLEvent";
import SSLHomeNotice from "@/components/ssl/home/SSLHomeNotice";
import SSLNoteCard from "@/components/main/SSLNoteCard.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import LoadingDiv2 from "@/components/common/LoadingDiv.vue";
import {computed, ref, watch} from "vue";
import {getItems, lengthCheck, stringCheck, timestampToDateFormat} from "@/assets/js/util";
import {
  ACT_GET_BEST_SUBSCRIBES_NOTE_LIST,
  ACT_GET_SOC_CNT,
  ACT_GET_SOC_EXCELLENT_BOARD_LIST,
  ACT_GET_SOC_RECPOP_BOARD_LIST,
  ACT_GET_SOC_SUBSCRIBE_BOARD_LIST,
} from "@/store/modules/ssl/ssl-index";
import {useStore} from 'vuex';

export default {
  name: 'SSLHome',
  components: {
    SSLHomeStatusV2,
    LoadingDiv,
    SSLNoteCard, SSLHomeNotice, SSLEvent, SSLLink, SSLActivity, SSLHomeProfile, LoadingDiv2}, // SSLHomeStatus, SSLHomeBadge,
  setup() {

    const store = useStore();


    getMyInfo();

    const myInfo = computed(()=>store.state.ssl.mySSLInfo);
    // const myInfo=reactive({
    //   lrnerId:'',
    //   lrnerNm:'',
    //   lrnerNickNm:'',
    //   deptNm:'',
    // });
    //
    // myInfo.lrnerId = store.state.ssl.mySSLInfo.lrnerId;
    // myInfo.lrnerNm = store.state.ssl.mySSLInfo.lrnerNm;
    // myInfo.deptNm = store.state.ssl.mySSLInfo.deptNm;
    // myInfo.lrnerNickNm = store.state.ssl.mySSLInfo.lrnerNickNm;

    getAllSubscribes();
    const sslCardFilter = ref('recommend');
    const sslLoading = ref(false);
    const sslSubscLoading = ref(false);
    const sslList = ref([
      { name: 'recommend', title: '추천', isLoading: true, items:[] },
      { name: 'excellent', title: '인기', isLoading: true, items:[] },
      // { name: 'subscribe', title: '구독', isLoading: true, items:[] },
    ]);
    const sslitems = ref([]);
    const excellentItems = ref([]);
    const subscribeItems = ref([]);
    const recommendItems = ref([]);
    const subscribeCnt = ref(0);

    // 쓸이 종료된 경우 구독 학습노트 숨김 처리
    store.dispatch(`ssl/${ACT_GET_SOC_CNT}`).then(res => {
      if(res && res.cnt === 0) {
        sslList.value[1].title = '';
      }
    });

    const getsslCardList = async (sslCardFilter) => {
      sslLoading.value = true;
      if (sslCardFilter.value === 'excellent') {
        if (!stringCheck(excellentItems.value)) {
          await store.dispatch(`ssl/${ACT_GET_SOC_EXCELLENT_BOARD_LIST}`).then(res => {
            if(lengthCheck(res)) {
              // console.log("res.value");
              // console.log(getItems(res));
              excellentItems.value = getItems(res).map(x => ({
                ...x,
                date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
                day: timestampToDateFormat(x.pblcnDt, 'dd'),
                distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
              }));
              // console.log("sslitems.value");
              // console.log(sslitems.value);
            }
          });
        }
        sslitems.value = excellentItems.value;
      } else if (sslCardFilter.value === 'recommend') {
        recommendItems.value = [];
        await store.dispatch(`ssl/${ACT_GET_SOC_RECPOP_BOARD_LIST}`, {seqNm:'0'}).then(res => {
          if(lengthCheck(res)) {
            // console.log("res.value");
            // console.log(getItems(res));
            recommendItems.value = getItems(res).map(x => ({
              ...x,
              date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
              day: timestampToDateFormat(x.pblcnDt, 'dd'),
              distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
            }));
            // console.log("sslitems.value");
            // console.log(sslitems.value);
          }
        });
        if(recommendItems.value == null || recommendItems.value.length == 0){
          sslCardFilter.value = 'excellent';
        }else{
          sslitems.value = recommendItems.value;
        }
      }
      sslLoading.value = false;
    };
    getsslCardList(sslCardFilter);

    const getsslSubscribeCardList =()=>{

      sslSubscLoading.value = true;
      if (!stringCheck(subscribeItems.value)) {
        store.dispatch(`ssl/${ACT_GET_SOC_SUBSCRIBE_BOARD_LIST}`).then(res => {
          if(lengthCheck(res)) {
            // console.log("res.value");
            // console.log(getItems(res));
            subscribeItems.value = getItems(res).map(x => ({
              ...x,
              date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
              day: timestampToDateFormat(x.pblcnDt, 'dd'),
              distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
            }));
            // console.log("subscribe.value");
            // console.log(sslitems.value);
            subscribeCnt.value = subscribeItems.value.length;
          }else{
            subscribeCnt.value = 0;
            getBestSubscribesNoteList();
          }
        });
      }
      sslSubscLoading.value = false;
    }
    getsslSubscribeCardList();

    const getBestSubscribesNoteList=()=>{
      sslSubscLoading.value = true;
      store.dispatch(`ssl/${ACT_GET_BEST_SUBSCRIBES_NOTE_LIST}`).then(res => {
        if(lengthCheck(res)) {
          // console.log("res.value");
          // console.log(getItems(res));
          subscribeItems.value = getItems(res).map(x => ({
            ...x,
            date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
            day: timestampToDateFormat(x.pblcnDt, 'dd'),
            distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
          }));
          // console.log("subscribe.value");
          // console.log(sslitems.value);
        }
      });
      sslSubscLoading.value = false;
    }

    watch(() => sslCardFilter.value, () => {
      getsslCardList(sslCardFilter);
    });


    return {
      // getProfileImg,
      sslRoutes,
      sslCardFilter,
      sslList,
      sslitems,
      sslLoading,
      myInfo,
      subscribeItems,
      sslSubscLoading,
      subscribeCnt
    }
  }
}
</script>
