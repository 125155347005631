<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <div class="popup" id="popup-hrdcolud-h0301">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">콘텐츠 자막 수정요청 등록</h3>
        </header>
        <div class="popup-content">
          <section class="popup-section">
            <div class="section-content">
              <div class="kb-form-fields field-01">
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">콘텐츠명</span>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <div class="kb-form-text">{{ cttMstObj.objNm }}</div>
                  </div>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">재생위치</span>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <div class="kb-form-text">{{ caption.timeline.split(" ")[0] }}</div>
                  </div>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">현재 자막내용</span>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <div class="kb-form-text">{{ caption.text }}</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="popup-section">
            <div class="kb-form-fields">
              <RowInputText v-model="inqCnOri" class-lst="column-whole" title="새로운 자막" placeholder="새로운 자막을 입력 해 주세요" />
            </div>
            <div class="kb-form-fields-bottom mt-3">
              <p class="text-notice text-muted">재생위치는 수정하실 수 없으며 신청하시는 새로운 자막은 콘텐츠 담당자의 확인 후 반영됩니다.</p>
            </div>

          </section>
        </div>
        <div class="popup-buttons">
          <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="insertQaAndFiles"><span class="text">저장</span></a>
          <a href="javascript:" class="kb-btn kb-btn-secondary kb-btn-lg" @click.stop="closeModal"><span class="text">취소</span></a>
        </div>

      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click.stop="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, reactive, ref} from 'vue';
import RowInputText from '@/components/support/common/RowInputText';
import {closeToggles} from '@/assets/js/ui.init';
import {initParams, isSuccess, } from '@/assets/js/util';
import {cttTableNm, insertQa} from '@/assets/js/modules/hrd/hrd-common';
import {useAlert} from '@/assets/js/modules/common/alert';

export default {
  name: 'HrdCloudCaptionModiModal',
  components: {RowInputText},
  props: {
    modelValue: Boolean,
    title: String,
    cttMstObj: Object,
    caption: Object,
  },
  setup(props, {emit}) {
    const {showMessage} = useAlert();

    const inqTyCdDcds = [{ cd: '2091010', cdNm: '콘텐츠 개선의견' }];


    const param = reactive({
      tblNm: cttTableNm,
      comInqSn: 0,
      inqTitle: props.cttMstObj.objNm,
      inqTyCdDcd: '2091019',
      inqTyNm: '콘텐츠 자막수정요청',
      inqCn:'',
      readYn: '',
      ansYn: '',
      ansCn: ''
    });

    console.log("cttmst", props.cttMstObj);

    const inqCnOri = ref(null);
    const toggles = reactive({
      inqTyCdDcds: false
    });

    const cnSz = computed(() => {
      return (param.inqCn && param.inqCn.length ? param.inqCn.length : 0 );
    });

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    const validate = () => {
      if (!inqCnOri.value) {
        showMessage('새로운 자막을 입력해주세요.');
        return false;
      }
      if (inqCnOri.value.length > 100) {
        showMessage('내용을 100자 이하로 작성해주세요.');
        return false;
      }
      return true;
    };

    const init = () => {
      initParams(param);
      param.tblNm = cttTableNm;
      param.tblSn = (props.cttMstObj.objSn ? props.cttMstObj.objSn : 0);
      param.inqTyCdDcd = '2091019';
      param.inqTyNm = '콘텐츠 자막수정요청';
    };

    const insertQaAndFiles = async () => {
      if (validate()) {
        //param구성
        //inqCn 을 재정의한다.
        //caption json에 newText 추가하여 stringfy하여 전송
        let reqCaption = {
                newText:inqCnOri.value,
                ...props.caption};
        param.inqCn = JSON.stringify(reqCaption);

        let qa = await insertQa(param);
        if (isSuccess(qa)) {
            showMessage("콘텐츠 자막 수정요청이 접수되었습니다", () => {
              init();
              closeModal();
            });

        } else {
          showMessage("등록에 실패했습니다. 잠시 후 다시 시도해주세요.");
        }
      }
    };

    console.log("caption check", props.caption);

    return {
      inqTyCdDcds, param, toggles, cnSz, inqCnOri,
      closeModal, closeToggles, insertQaAndFiles
    }
  }
};
</script>