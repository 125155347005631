
import store from "@/store";
import {computed, ref, toRaw} from "vue";
import {ACT_INSERT_LEARN_HOT_TIP_ACT_LOG} from "@/store/modules/learn/learn";
import {ACT_GET_BOARD_CATE_LIST, ACT_GET_BOARD_MST, MUT_SET_BOARD_MST_OBJECT} from "@/store/modules/board/board-mst";
import {getItem, getItems} from "@/assets/js/util";
import {getPostList} from "@/assets/js/modules/board/board-common";

// 핫팁 관련 액션 로그 저장
export const insertHotTipActLog = (actTyCdDcd) => {
    store.dispatch(`learn/${ACT_INSERT_LEARN_HOT_TIP_ACT_LOG}`, {actTyCdDcd}).then(res => {
        if(res.result === 0){
            console.log('[FAILED] HOT_TIP_ACT_LOG');
        }
    })
}

// 핫팁 게시판 정보 state 저장
export const setHotTipState = async () => {
    ['hottip-my','hottip-hq','hottip-biz','hottip-notice'].map( async (boardId) => {await saveHotTipBoardToState(boardId);});
}
const saveHotTipBoardToState = async (boardId) => {
    const boardMstObject = computed(() => store.state.boardmst.objects.filter(x => x.boardId === boardId));
    if(boardMstObject.value.length === 0){
        let boardMst = null;
        let boardCateList = [{cateNm:'전체', boardCateSn:''}];
        await store.dispatch(`boardmst/${ACT_GET_BOARD_MST}`, boardId).then(async (res) => {
            boardMst = getItem(res);
            await store.dispatch(`boardmst/${ACT_GET_BOARD_CATE_LIST}`, boardMst.boardMstSn).then((res) => {
                boardCateList = boardCateList.concat(getItems(res));
            }).finally(async () => {
                await store.commit(`boardmst/${MUT_SET_BOARD_MST_OBJECT}`, {boardId, boardMst, boardCateList});
            })
        })
    }
}

// state 내 핫팁 게시판 정보 호출
export const getHotTipBoardMst = (boardId) => {
    const boardMstObject = computed(() => store.state.boardmst.objects.find(x => x.boardId === boardId));
    if(boardMstObject.value){
        return toRaw(boardMstObject.value.boardMst);
    }else{
        return null;
    }
}
export const getHotTipBoardCateList = (boardId) => {
    const boardMstObject = computed(() => store.state.boardmst.objects.find(x => x.boardId === boardId));
    if(boardMstObject.value){
        return toRaw(boardMstObject.value.boardCateList);
    }else{
        return [];
    }
}

// 핫팁 메인 화면 내 핫팁 미리보기 리스트
export const getHotTipMainPreviewList = async (boardId) => {
    const hottipBoardMst = getHotTipBoardMst(boardId);
    const hottipBoardCates = getHotTipBoardCateList(boardId);
    const hottipPostListTopFixY = ref([]);
    const hottipPostListTopFixN = ref([]);
    if(hottipBoardMst === null){
        return [];
    }else{
        await getPostList({boardMstSn:hottipBoardMst.boardMstSn, postBadgeCdDcd:HotTipBoardBadgeCdDcd.HOT, pageNo:1, pageSize:2}, hottipPostListTopFixY, null, hottipBoardCates);
        await getPostList({boardMstSn:hottipBoardMst.boardMstSn, topFixYn:'N', pageNo:1, pageSize:( 6 - hottipPostListTopFixY.value.length)}, hottipPostListTopFixN, null, hottipBoardCates);
        return toRaw(hottipPostListTopFixY.value).concat(toRaw(hottipPostListTopFixN.value));
    }
}

// 핫팁 메인 화면 내 우수 Tip 모아보기 리스트
export const getBestHotTipList = async () => {
    let returnArr = [];
    // 영업노하우 공모 3개 / 나만의 핫팁 3개 합쳐서 최신 6개만노출
    returnArr = returnArr.concat(await getBestHotTipListByBoardId('hottip-my', 3));
    // returnArr = returnArr.concat(await getBestHotTipListByBoardId('hottip-hq', 2));
    returnArr = returnArr.concat(await getBestHotTipListByBoardId('hottip-biz', 3));

    return returnArr;
}
const getBestHotTipListByBoardId = async (boardId, pageSize) => {
    const hottipBoardMst = getHotTipBoardMst(boardId);
    const hottipBoardCates = getHotTipBoardCateList(boardId);
    const hottipPostList = ref([]);
    if(hottipBoardMst === null){
        return [];
    }else{
        await getPostList({boardMstSn:hottipBoardMst.boardMstSn, postBadgeCdDcd:HotTipBoardBadgeCdDcd.BEST, pageNo:1, pageSize}, hottipPostList, null, hottipBoardCates);
        return toRaw(hottipPostList.value);
    }
}

// 핫튜브 페이지 이동을 위한 파라미터
export const hotTubeParams = {sortTyCdDcd: '2073001', category1: 4, pageNo: 1};

// 핫팁 게시글 배지 모음
export const HotTipBoardBadgeCdDcd = {
    HOT : '2141001',
    BEST : '2141002', // 우수 Tip
}

export const hotTipManagerInfo = `[담당] 영업추진부 <br class="d-sm-none">민진서(☎7999), 윤서영(☎4822)`;

export const hotTipBoardIds = ['hottip-my','hottip-hq','hottip-biz'];