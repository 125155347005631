<template>
  <main class="kb-main" id="kb-hrdcloud" style="padding-bottom: 0px;">
    <div class="main-content min-component" :class="{'viewscript' : viewScriptFlag}" style="padding-bottom: 50px;">
      <div class="hrdcloud-view-container">
        <div class="player hrdcloud-player">
          <div class="player-container">
            <div class="player-inner" :class="{'viewscript': viewScriptFlag}">
              <template v-if="!isLoading && hrd && uuid">
                <SSLPlayer
                    v-if="hrd.lrnObjTyCd === lrnObjTyCds.MP3 || hrd.lrnObjTyCd === lrnObjTyCds.MP4"
                    v-model:uuid="uuid"
                    v-model:is-series-active="isSeriesActive"
                    :coldcall="coldcall"
                    :crse="hrd"
                    :progress="user"
                    :enable-progress="true"
                    :popups="popups"
                    :wrapper-dom1="wrapperDom1"
                    :wrapper-dom2="wrapperDom2"
                    :wrapper-dom3="wrapperDom3"
                    :view-script-flag="viewScriptFlag"
                    :funcs="detailFuncs"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {goBack} from '@/assets/js/modules/common/common';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
import {getItem, initProgressBar, lengthCheck, setParams, timestampToDateFormat} from '@/assets/js/util';
import SSLPlayer from '@/components/hrdcloud/SSLPlayer';
import {
  darkMode,
  detailCategoryDisplay,
  disableMyAddChanged,
  generateUUID,
  // getTop1CategoryByLevel,
  getUserProgress,
  handleActions,
  lightMode,
  // makeTags,
  pageView,
  toggleConcentrationMode,
} from '@/assets/js/modules/hrd/hrd-common';
import {lrnObjTyCds} from '@/assets/js/modules/learn/learn-common';
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'SSLVod',
  components:{
    SSLPlayer,
  },
  props: {
    objDetail: Object,
    effectNav: {
      required: false,
      type: Number,
      default: 0
    },
  },
  setup(props) {
    const {showMessage} = useAlert();
    const store = useStore(), route = useRoute();
    const isMobile = computed(() => store.state.auth.isMobile);
    const session = computed(() => store.state.auth.session);
    const hrd = computed(() => props.objDetail);
    const isLoading = ref(true);
    const lrnObjDtlDistSn = computed(() => route.params.lrnObjDtlDistSn);
    const category = ref({});
    const concentrationModeText = computed(() => store.state.hrdcloud.mode === 'dark' ? '집중모드 켜짐' : '집중모드 꺼짐');
    const isMenuActive = ref(false);
    const coldcall = ref(false);
    const user = reactive({ progRsltSn: 0, maxPlaySec: 0, lastPlaySec: 0, cmptnYn: 'N', mdfcnDt: null, regDt: null });
    const latestHistoryModal = ref(false), historyModal = ref(false), shareModal = ref(false), opinionModal = ref(false), sttModal = ref(false), captionModal = ref(false);
    const histories = ref([]);
    const sttText = ref({});
    const wrapperDom1 = ref(null);
    const wrapperDom2 = ref(null);
    const wrapperDom3 = ref(null);
    const uuid = ref(null);
    const isSeriesActive = ref(false);

    const viewScriptFlag = ref(false);
    const caption = ref(null);

    const popups = reactive({
      miss: {
        show: false,
        toggle: (bool) => {
          popups.miss.show = bool;
        },
        response: {
          effect: 0,
          type: ''
        },
        respond: (type) => {
          popups.miss.response.type = type;
          popups.miss.show = false;
          popups.miss.response.effect++;
        },
        initRespond : () => {
          popups.miss.response.type = '';
        }
      },
      resume: {
        show: false,
        toggle: (bool) => {
          popups.resume.show = bool;
        },
        response: {
          effect: 0,
          type: '',
          continueEffect: 0,
        },
        respond: (type) => {
          popups.resume.response.type = type;
          popups.resume.show = false;
          popups.resume.response.effect++;
          if (type === 'continue') {
            popups.resume.response.continueEffect++;
          }
        },
        initRespond : () => {
          popups.resume.response.type = '';
        }
      },
      complete: {
        show: false,
        feedback: false,
        toggle: (bool) => {
          popups.complete.show = bool;
          if(!bool) popups.complete.feedback = bool;
        },
        callback: (videoInstance) => {
          videoInstance.value.exitFullscreen();
        }
      },
      getUUID: async () => {
        const id = await generateUUID();
        if (id && id.uuid) uuid.value = id.uuid;
      }
    });

    const objectParam = computed(() => {
      return {
        distCrseSn: hrd.value.distCrseSn,
        distChapSn: hrd.value.distChapSn,
        lrnObjDtlDistSn: hrd.value.lrnObjDtlDistSn,
        objSn: hrd.value.objSn,
        // division: 'vod'
      }
    });

    // 콘텐츠 정보
    const getHrdCloudDetail = async () => {

      pageView(hrd);
      await popups.getUUID();
      const userProgressRes = await getUserProgress(hrd);
      if(lengthCheck(userProgressRes)) setParams(user, getItem(userProgressRes));
      isLoading.value = false;

      // store.dispatch(`prep/${ACT_GET_HRDCLOUD_DETAIL}`, {lrnObjDtlDistSn: lrnObjDtlDistSn.value}).then(async (res) => {
      //   if (res && res.items.length > 0) {
      //     hrd.value = res.items.map(x => {
      //       return {
      //         ...getTop1CategoryByLevel(x.cloudKeyLst),
      //         ...x,
      //         tag: makeTags(x.tag)
      //       };
      //     })[0];
      //     pageView(hrd);
      //     await popups.getUUID();
      //     const userProgressRes = await getUserProgress(hrd);
      //     if(lengthCheck(userProgressRes)) setParams(user, getItem(userProgressRes));
      //   }
      //   isLoading.value = false;
      // }).catch(() => {
      //   isLoading.value = false;
      // });
    };

    const togglePlayerMenu = () => {
      isMenuActive.value = !isMenuActive.value;
    };

    const detailFuncs = {
      openHistory: (item) => {
        handleActions(item).actHistory(historyModal, histories);
      },
      openShare: (item) => {
        handleActions(item).actShare(shareModal);
      },
      openOpinion: (item) => {
        handleActions(item).actOpinion(opinionModal);
      },
      openStt: (item) => {
        handleActions(item).actStt(sttModal,sttText);
      },
      toggleScript: (message) => {
        if(message){
          showMessage(message)
          console.log(message);
        }
        viewScriptFlag.value = !viewScriptFlag.value;
      },
      openCaptionModi:(idx, subtitle, player) =>{
        player.pause();
        caption.value = subtitle;
        captionModal.value = true;
      }
    };

    const showLatestHistory = () => {
      latestHistoryModal.value = true;
    };

    getHrdCloudDetail();

    watch(() => lrnObjDtlDistSn.value, () => {
      initProgressBar();
      if (lrnObjDtlDistSn.value > 0) {
        getHrdCloudDetail();
      }
    });


    const leaderAdd = computed(() => store.state.hrdcloud.leaderAdd);

    // 담기 후 업데이트를 위해 한번 더 호출
    watch(() => leaderAdd.value.show, () => {
      if (hrd.value && !leaderAdd.value.show && leaderAdd.value.updateMyAdd) {
        const lrnObjDtlDistSn = leaderAdd.value.lrnObjDtlDistSn;
        if (hrd.value.lrnObjDtlDistSn === lrnObjDtlDistSn) {
          hrd.value.put = leaderAdd.value.isPut;
          disableMyAddChanged();
        }
      }
    });

    onMounted(() => {
      darkMode();
    });

    onBeforeUnmount(() => {
      lightMode();
      coldcall.value = false;
    });

    return {
      isMobile, session, uuid, lrnObjDtlDistSn,
      hrd, category, coldcall, user, histories,sttText,
      isLoading,
      goBack,
      concentrationModeText,
      isMenuActive,
      toggleConcentrationMode,
      togglePlayerMenu,
      timestampToDateFormat,
      handleActions, detailFuncs, detailCategoryDisplay,
      historyModal, shareModal, opinionModal, captionModal, objectParam,sttModal,
      latestHistoryModal ,showLatestHistory,
      popups, lrnObjTyCds,
      wrapperDom1, wrapperDom2, wrapperDom3,
      isSeriesActive,
      viewScriptFlag, caption
    };
  }
};
</script>
