<template>
  <div class="member-container" ref="members">
    <ul  class="member-list">
      <template v-if="isGroup">
        <li v-for="(item, idx) in renderItems"  class="member-item" :key="idx">
          <div class="member">
            <router-link v-if="item.lrnerId==session.lrnerId" :to="{name: 'SSLTotalMy', params: {distCrseCd:'total', lrnTrgtGrpMstSn:0}}" class="member-link" @click="closeMember"></router-link>
            <router-link v-else :to="{name:'SSLLearner', params: {lrnerId: item.lrnerId}}" class="member-link" @click="closeMember"></router-link>
            <div class="member-badge">
              <div class="badge badge-primary" v-if="item.grpRoleCdLst.split('|')[0] == '2010001'"><span class="badge-text">리더</span></div>
              <div class="badge badge-gold" v-else><span class="badge-text">멤버</span></div>
            </div>
<!--            <div v-if="myGroup.distCrseSn > 0 && myGroup.distCrseSn === item.distCrseSn" class="member-badge"><div class="badge badge-primary"><span class="badge-text">MY</span></div></div>-->
            <div class="member-avatar">
              <CommonTypeImage
                  :img-src="item.leaderImgUrl"
                  theme="profile"
              />
            </div>
            <div class="member-content">
              <strong class="name">{{ item.crseNmLst.split("|")[0] }} {{item.grpCnt > 1?'외'+(item.grpCnt-1):''}}</strong>
              <p class="team">{{ item.distCrseLrnFldNmLst.split("|")[0] }} · {{ item.lrnerNm }}</p>
            </div>
            <div class="member-follow" :class="{'is-active': item.subscribeSts > 0}">
              <button class="kb-btn-follow" :class="{'kb-btn-follow-friends': (item.subscribeSts === 1), 'kb-btn-follow-mate': (item.subscribeSts === 2)}" @click="unSubscribe(item)">
                <span class="text">{{ item.subscribeSts === 2 ? '메이트' : '프렌즈' }}</span>
              </button>
            </div>
          </div>
        </li>
      </template>
      <template v-else>
        <li v-for="(item, idx) in renderItems"  class="member-item" :key="idx">
          <div class="member">
            <router-link v-if="item.lrnerId==session.lrnerId" :to="{name: 'SSLTotalMy', params: {distCrseCd:'total', lrnTrgtGrpMstSn:0}}" class="member-link" @click="closeMember"></router-link>
            <router-link v-else :to="{name:'SSLLearner', params: {lrnerId: item.lrnerId}}" class="member-link" @click="closeMember"></router-link>
<!--            <div v-if="item.lrnerId === session.lrnerId" class="member-badge"><div class="badge badge-primary"><span class="badge-text">MY</span></div></div>-->
            <div class="member-avatar">
              <CommonTypeImage
                  :img-src="item.imgUrl"
                  theme="profile"
              />
            </div>
            <div class="member-content">
              <strong class="name">{{ item.lrnerNm }}</strong>
              <p class="team">{{ item.deptNm }}</p>
            </div>
            <div class="member-follow" :class="{'is-active': item.subscribeSts > 0}">
              <button class="kb-btn-follow" :class="{'kb-btn-follow-friends': (item.subscribeSts === 1), 'kb-btn-follow-mate': (item.subscribeSts === 2)}" @click="unSubscribe(item)">
                <span class="text">{{ item.subscribeSts === 2 ? '메이트' : '프렌즈' }}</span>
              </button>
            </div>
          </div>
        </li>
      </template>
    </ul>

    <div v-if="renderItems.length !== refinedItems.length" class="board-list-actions" style="margin-top: 10px; margin-bottom: 10px" @click="moreMembers">
      <button class="kb-btn-more" @click="moreMembers">
        <span class="text">더보기</span>
      </button>
    </div>

  </div>
</template>
<script>

import {sslMemberSetup} from '@/assets/js/modules/ssl/setup/ssl-learn-setup';
import CommonTypeImage from '@/components/common/CommonTypeImage';

export default {
  name: 'SSLMobileMember',
  components: {CommonTypeImage},
  props: {
    lrnTrgtGrpMstSn: Number,
  },
  setup: sslMemberSetup
}
</script>
