import ApiService from '@/assets/js/api.service';

// ACTION
export const ACT_GET_GROUP_CRSE_DISP = 'actGetCrseDisp';

export const ACT_GET_GROUP_LEARNING_GROUP_INFO_LIST = 'actGetGroupLearningGroupInfoList'
export const ACT_GET_GROUP_LEARNING_GROUP_INFO_COUPON_LIST = 'actGetGroupLearningGroupInfoCouponList'
export const ACT_GET_MY_GROUP_LIST = 'actGetMyGroupList';
export const ACT_GET_GROUP_LEARNING_MEMBER_INFO_LIST = 'actGetGroupLearningMemberInfoList';
export const ACT_GET_GROUP_LEARNING_GROUP_CLASS_LIST = 'actGetGroupLearningGroupClassList';
export const ACT_GET_GROUP_APPLICATION_ASSET_LIST = 'actGetGroupApplicationAssetList';
export const ACT_GET_GROUP_DETAIL_VIEW = 'actGetGroupDetailView';
export const ACT_GET_GROUP_DETAIL_VIEW_LIST = 'actGetGroupDetailViewList';

// 미정
export const ACT_GET_GROUP_LEARNING_QUIZ_MNG = 'actGetGroupLearningQuizMng';
export const ACT_GET_GROUP_LEARNING_COST_MNG = 'actGetGroupLearningCostMng';
export const ACT_GET_GROUP_JOURNAL_CLASS_LIST = 'actGetJournalClassList';
export const ACT_GET_GROUP_JOURNAL_CONTENTS_LIST = 'actGetJournalContentsList';
export const ACT_GET_GROUP_JOURNAL_MEMBER_LIST = 'actGetJournalMemberList';

// 지식e러닝
export const ACT_GET_KNOWLEDGE_LEARNING_STATUS = 'actGetKnowledgeLearningStatus';
export const ACT_GET_KNOWLEDGE_LEARNING_DATA = 'actGetKnowledgeLearningData';
export const ACT_GET_KNOWLEDGE_EVALUATE_LIST = 'actGetKnowledgeEvaluateList';
export const ACT_GET_KNOWLEDGE_VIEW = 'actGetKnowledgeView';
export const ACT_GET_KNOWLEDGE_VIEW_ATCH_FILE = 'actGetKnowledgeViewAtchFile';

export const ACT_GET_EXISTS_KNOWLEDGE_LRN = 'getExistsKnowledgeLrn';

// 그룹러닝
export const ACT_GET_GROUP_LRN_TRGT_DTL_LIST = 'getGroupLrnTrgtDtlList';
export const ACT_GET_GROUP_LRN_LIST = 'getGroupLrnList';

// 클라우드EASY
export const ACT_GET_CLOUD_EASY_LIST = 'actGetCloudEasyList';
export const ACT_GET_CLOUD_EASY_CATE_LIST = 'actGetCloudEasyCateList';

// HRD 클라우드
export const ACT_GET_HRDCLOUD_MAIN_LISTS = 'actGetHrdCloudMainLists';
export const ACT_GET_HRDCLOUD_LIST = 'actGetHrdCloudList';
export const ACT_GET_HRDCLOUD_DETAIL = 'actGetHrdCloudDetail';
export const ACT_GET_HRDCLOUD_SSL_STUDIO_DETAIL = 'actGetHrdCloudSSLStudioDetail';
export const ACT_GET_HRDCLOUD_SHORT_DETAIL = 'actGetHrdCloudShortDetail';
export const ACT_GET_HRDCLOUD_ATCH_FILE_LIST = 'actGetHrdCloudAtchFileList';
export const ACT_GET_HRDCLOUD_COMMENT_LIST = 'actGetHrdCloudCommentList';
export const ACT_GET_HRDCLOUD_SERIES_LIST = 'actGetHrdCloudSeriesList';
export const ACT_GET_HRDCLOUD_RECOMMEND_LIST = 'actGetHrdCloudRecommendList';
export const ACT_GET_HRDCLOUD_LATEST_LIST = 'actGetHrdcloudLatestList';
export const ACT_GET_HRDCLOUD_RECENT_LIST = 'actGetHrdcloudRecentList';
export const ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST = 'actGetHrdcloudRecentPopularList';
export const ACT_GET_HRDCLOUD_SUMGO_LIST = 'actGetHrdCloudSumgoList';
export const ACT_GET_HRDCLOUD_BPCC_LIST = 'actGetHrdCloudBpccList';
export const ACT_GET_CHECK_HRDCLOUD_OBJECT = 'actGetCheckHrdcloudObject';



export const ACT_GET_USERS_INFO = 'actGetUsersInfo'

const state = {
};

const mutations = {
};

const actions = {
    [ACT_GET_USERS_INFO](context, userSn) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/users', userSn).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_GROUP_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa501a', params).then(response => resolve(response))});
    },
    [ACT_GET_GROUP_CRSE_DISP](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa502a', params).then(response => resolve(response))});
    },
    [ACT_GET_GROUP_LEARNING_GROUP_INFO_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa502b', params).then(response => resolve(response))});
    },
    [ACT_GET_GROUP_LEARNING_GROUP_INFO_COUPON_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa502c', params).then(response => resolve(response))});
    },
    [ACT_GET_GROUP_LEARNING_MEMBER_INFO_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa504b', params).then(response => resolve(response))});
    },
    [ACT_GET_GROUP_LEARNING_GROUP_CLASS_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa507b', params).then(response => resolve(response))});
    },
    [ACT_GET_GROUP_APPLICATION_ASSET_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa508a', params).then(response => resolve(response))});
    },
    [ACT_GET_GROUP_DETAIL_VIEW](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa509a', params).then(response => resolve(response))});
    },
    [ACT_GET_GROUP_DETAIL_VIEW_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa509b', params).then(response => resolve(response))});
    },
    // 지식e러닝
    [ACT_GET_KNOWLEDGE_LEARNING_STATUS](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa601a', params).then(response => resolve(response))});
    },
    [ACT_GET_KNOWLEDGE_LEARNING_DATA](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa601b', params).then(response => resolve(response))});
    },
    [ACT_GET_KNOWLEDGE_VIEW](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa602a', params).then(response => resolve(response))
        });
    },
    [ACT_GET_KNOWLEDGE_VIEW_ATCH_FILE](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa602b', params).then(response => resolve(response))
        });
    },
    [ACT_GET_GROUP_JOURNAL_CLASS_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa510a', params).then(response => resolve(response))});
    },
    [ACT_GET_GROUP_JOURNAL_CONTENTS_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa510b', params).then(response => resolve(response))});
    },
    [ACT_GET_GROUP_JOURNAL_MEMBER_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa510c', params).then(response => resolve(response))});
    },


    /**
     * 현재 테이블 없음
     */
    // 테이블 미정
    // 그룹관리
    [ACT_GET_GROUP_LEARNING_QUIZ_MNG](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa508a', params).then(response => resolve(response))});
    },
    [ACT_GET_GROUP_LEARNING_COST_MNG](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa510a', params).then(response => resolve(response))});
    },


    //지식e러닝
    [ACT_GET_KNOWLEDGE_EVALUATE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa603a', params).then(response => resolve(response))});
    },

    // 그룹러닝
    [ACT_GET_GROUP_LRN_TRGT_DTL_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/crses/dists/group', params).then(response => resolve(response));
        });
    },
    [ACT_GET_GROUP_LRN_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/crses/dists/groups', params).then(response => resolve(response));
        });
    },
    // 클라우드Easy
    [ACT_GET_CLOUD_EASY_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/cloudeasy', params).then(response => resolve(response))});
    },
    [ACT_GET_CLOUD_EASY_CATE_LIST]() {
        return new Promise(resolve => {
            ApiService.query('/v1/app/category/cloudeasy', {}).then(response => resolve(response))});
    },

    //HRD 클라우드
    [ACT_GET_HRDCLOUD_MAIN_LISTS](context, params) {
        return new Promise(resolve => {
           ApiService.query('/v1/app/lrn/mainlists', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/lrn/obj', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_DETAIL](context, {lrnObjDtlDistSn, params}) {
        return new Promise(resolve => {
            ApiService.get(`/v1/app/lrn/obj/${lrnObjDtlDistSn}`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_SSL_STUDIO_DETAIL](context, {lrnObjDtlDistSn, params}) {
        return new Promise(resolve => {
            ApiService.get(`/v1/app/lrn/obj/sslStudio/${lrnObjDtlDistSn}`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_SHORT_DETAIL](context, {lrnObjDtlDistSn, params}) {
        return new Promise(resolve => {
            ApiService.get(`/v1/app/lrn/obj/shorts/${lrnObjDtlDistSn}`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_ATCH_FILE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa014b', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_COMMENT_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/front/fa014c', params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_SERIES_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/lrn/series`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_RECOMMEND_LIST](context, params) {
        return new Promise(resolve => {
            // ApiService.query(`/v1/app/lrn/hrd/recommend`, params).then(response => resolve(response))
            ApiService.query(`/v1/app/lrn/hrd/recommendusr`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_LATEST_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/lrn/hrd/latest`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_RECENT_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/lrn/hrd/my-recent`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/lrn/hrd/recent-popular`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_CHECK_HRDCLOUD_OBJECT](context, params) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/lrn/hrd/validate`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_EXISTS_KNOWLEDGE_LRN](){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/learns/target/knowledge`).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_SUMGO_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/lrn/hrd/sum-go`, params ).then(response => resolve(response))
        });
    },
    [ACT_GET_HRDCLOUD_BPCC_LIST](context, params){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/lrn/hrd/bpcc`, params ).then(response => resolve(response))
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
