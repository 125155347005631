import {computed, onMounted, reactive, ref, watch} from "vue";
import store from "../../../../store";
import {useRoute} from "vue-router";
import {hrdCloudRoadmapCateLevels, partTimerCateLevels, preCateLevels, hrdCloudRoadmapCateSns} from "@/assets/js/modules/cloud/cloud-common";
import {getItem, getItems, lengthCheck, setParams} from "@/assets/js/util";
import {
    ACT_GET_HRDCLOUD_LEARNING_ROADMAP_CARD_LIST,
    ACT_GET_HRDCLOUD_LEARNING_ROADMAP_CTT_LIST,
    ACT_GET_HRDCLOUD_LEARNING_ROADMAP_LIST
} from "@/store/modules/hrdcloud/hrdcloud";
import {getListFunc} from "@/assets/js/ui.init";
import {useAlert} from "@/assets/js/modules/common/alert";
import {goBack} from "@/assets/js/modules/common/common";
import {ACT_GET_USER_RECOMMEND_LSIT, ACT_GET_USER_WORK_PERIOD} from "@/store/modules/my/my";
import {goRecommendSystem} from "@/assets/js/modules/mypage/talent/talent-setup";
import {getGroupLeader, lightMode} from "@/assets/js/modules/hrd/hrd-common";

export const hrdCloudRoadmapMainModalSetup = (props, {emit}) => {
    const showAllList = ref(false)
    const nodes = ref([])
    const upSkill = ref(null)
    const reSkill = ref(null)
    const aiResult = ref([])
    const hrdCloudCateList = computed(() => store.state.hrdcloud.hrdcloudCates.filter(x => hrdCloudRoadmapCateSns.includes(x.hrdCloudCateSn)))

    const closeModal = () => {
        showAllList.value = false;
        toggleAll();
        emit('update:modelValue', false);
    }

    const toggleAll = () => {
        nodes.value.map(x => {
            x.isActive = showAllList.value;
            if(x.levels.length > 0){
                x.levels.map(y => {
                    y.isActive = !showAllList.value;
                    toggleLevel(y);
                })
            }
        })
    }

    watch(() => showAllList.value, toggleAll)

    onMounted(() => {
        getHrdCloudLearningRoadmapList(nodes, upSkill, reSkill);
        getListFunc(`my/${ACT_GET_USER_RECOMMEND_LSIT}`, null, aiResult);
    })

    return {
        showAllList,
        nodes,
        upSkill,
        reSkill,
        aiResult,
        hrdCloudCateList,
        closeModal,
        moveToContentBridge,
        toggleLevel,
        goRecommendSystem,
        moveToDtl,
    }
}

export const hrdCloudRoadmapMainSetup = () => {
    const showAllList = ref(false)
    const nodes = ref([])
    const upSkill = ref(null)
    const reSkill = ref(null)
    const workData = ref([])
    const lastJobInfo = ref(null)
    const aiResult = ref([])
    const hrdCloudCateList = computed(() => store.state.hrdcloud.hrdcloudCates.filter(x => hrdCloudRoadmapCateSns.includes(x.hrdCloudCateSn)))
    const isMobile = computed(() => store.state.auth.isMobile)
    const {showMessage} = useAlert()

    const toggleAll = () => {
        nodes.value.map(x => {
            x.isActive = showAllList.value;
            if(x.levels.length > 0){
                x.levels.map(y => {
                    y.isActive = !showAllList.value;
                    toggleLevel(y);
                })
            }
        })
    }

    const getRecommendInfo = () => {
        // 현재 직무 정보
        getListFunc(`my/${ACT_GET_USER_WORK_PERIOD}`, null, workData, null, (res) => {
            if(lengthCheck(res)){
                workData.value.map(x => {
                    if(res.lastJobInfo && x.jobCd == res.lastJobInfo.jobCd) {
                        lastJobInfo.value = x;
                    }
                })
            }
        })
        getListFunc(`my/${ACT_GET_USER_RECOMMEND_LSIT}`, null, aiResult);
    }

    watch(() => showAllList.value, toggleAll)

    onMounted(() => {
        if(!isMobile.value) {
            showMessage('잘못된 접근입니다.');
            goBack();
        }else{
            getHrdCloudLearningRoadmapList(nodes, upSkill, reSkill);
            getRecommendInfo();
        }
    })

    return {
        showAllList,
        nodes,
        upSkill,
        reSkill,
        lastJobInfo,
        aiResult,
        hrdCloudCateList,
        moveToContentBridge,
        toggleLevel,
        goRecommendSystem,
        moveToDtl,
    }
}

export const hrdCloudRoadmapPageSetup = () => {
    const route = useRoute();
    const {showMessage} = useAlert();
    const session = computed(() => store.state.auth.session);
    const isLoading = ref(true);
    const roadmap = ref(null);
    const cttList = ref([]);
    const groupLearning = reactive({
        distCrseSn: 0,
        distCrseNm: '',
    });
    const showGroupLeaderPutModal = ref(false);
    const groupLearningAllPut = ref(false);
    // 모바일
    const isMobile = computed(() => store.state.auth.isMobile);
    const showMenu = ref(false);
    const categoryRoadmap = ref([]);

    // 그룹러닝 리더 확인
    const checkGroupLeader = async () => {
        const trgtRes = await getGroupLeader(session.value.lrnerId);
        if(trgtRes && trgtRes.trgt){
            setParams(groupLearning, trgtRes.trgt);
        }
    }

    const getHrdCloudRoadmap = () => {
        isLoading.value = true;
        roadmap.value = null;
        store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_LEARNING_ROADMAP_LIST}`, {hrdCloudCateSn: route.query.category, hrdCloudCateLevel: route.query.level}).then(res => {
            if(lengthCheck(res)){
                roadmap.value = getItem(res);
                if (roadmap.value.hrdCloudCateSn === 1984) {
                    roadmap.value['hrdCloudCateLevelNm'] = partTimerCateLevels.find(x => x.level == roadmap.value.hrdCloudCateLevel).label;
                } else if ([2047,2048].includes(roadmap.value.hrdCloudCateSn)) {
                    roadmap.value['hrdCloudCateLevelNm'] = preCateLevels.find(x => x.level == roadmap.value.hrdCloudCateLevel).label;
                } else {
                    roadmap.value['hrdCloudCateLevelNm'] = hrdCloudRoadmapCateLevels.find(x => x.level == roadmap.value.hrdCloudCateLevel).label;
                }
                isLoading.value = false;
                if(roadmap.value.cttCount > 0) {
                    getHrdCloudRoadmapCttList();
                }
            }else{
                showMessage('존재하지 않는 로드맵입니다.');
                goBack();
            }
        })
        // 동일 카테고리의 로드맵 정보 불러오기
        if(isMobile.value){
            store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_LEARNING_ROADMAP_LIST}`, {hrdCloudCateSn: route.query.category}).then(res => {
                if(lengthCheck(res)){
                    categoryRoadmap.value = getItems(res);
                    categoryRoadmap.value.map(x => {
                        if (x.hrdCloudCateSn === 1984) {
                            x.hrdCloudCateLevelNm = partTimerCateLevels.find(y => y.level == x.hrdCloudCateLevel).label;
                        } else if ([2047,2048].includes(x.hrdCloudCateSn)) {
                            x.hrdCloudCateLevelNm = preCateLevels.find(y => y.level == x.hrdCloudCateLevel).label;
                        } else {
                            x.hrdCloudCateLevelNm = hrdCloudRoadmapCateLevels.find(y => y.level == x.hrdCloudCateLevel).label;
                        }
                    });
                    categoryRoadmap.value.sort((a,b) => a.hrdCloudCateLevel - b.hrdCloudCateLevel);
                }
            })
        }
    }

    const getHrdCloudRoadmapCttList = () => {
        cttList.value = [];
        getListFunc(`hrdcloud/${ACT_GET_HRDCLOUD_LEARNING_ROADMAP_CTT_LIST}`, roadmap.value.hrdCloudRoadmapSn, cttList, null);
    }

    watch(() => route.query, () => {
        getHrdCloudRoadmap();
        groupLearningAllPut.value = false;
    })

    onMounted(() => {
        getHrdCloudRoadmap();
        checkGroupLeader();
        lightMode();
    })

    return {
        session,
        isLoading,
        roadmap,
        cttList,
        categoryRoadmap,
        groupLearning,
        showGroupLeaderPutModal,
        groupLearningAllPut,
        showMenu,
        moveToDtl,
    }
}

// 로드맵 상세페이지 이동
export const moveToDtl = (router, cateSn, level) => {
    router.push({name:'HrdCloudRoadmapPage', query:{category:cateSn, level:level}});
}

// 로드맵 정렬 리스트
export const getHrdCloudLearningRoadmapSortedList = (target) => {
    store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_LEARNING_ROADMAP_CARD_LIST}`).then((res) => {
        if(lengthCheck(res)){
            target.value = getItems(res);
            target.value.sort((a,b) => {return (a.reskillRcmdYn > b.reskillRcmdYn) ? -1 : 0})
                .sort((a,b) => {return (a.upskillRcmdYn > b.upskillRcmdYn) ? -1 : 0})
                .sort((a,b) => {return (a.isProgress > b.isProgress) ? -1 : 0})
                .sort((a,b) => {return (a.roadmapLastProgDt > b.roadmapLastProgDt) ? -1 : 0})
                .sort((a,b) => {return (a.isCompleted < b.isCompleted) ? -1 : 0})
                .sort((a,b) => {if([2047,2048].includes(a.data[0].hrdCloudCateSn)){return -1;}else if([2047,2048].includes(b.data[0].hrdCloudCateSn)){return 1}else{return 0;}});
        }
    });
}

const getHrdCloudLearningRoadmapList = (rtnItem, upSkill, reSkill) => {
    store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_LEARNING_ROADMAP_LIST}`, null).then(res => {
        if(lengthCheck(res)){
            const items = getItems(res);
            makeTreeNode(items, rtnItem);
            upSkill.value = items.filter(x => x.upskillRcmdYn == 'Y').map(x => {return x.hrdCloudCateNm.concat(' ').concat(hrdCloudRoadmapCateLevels.find(y => y.level == x.hrdCloudCateLevel).label)});
            reSkill.value = items.filter(x => x.reskillRcmdYn == 'Y').map(x => {return x.hrdCloudCateNm.concat(' ').concat(hrdCloudRoadmapCateLevels.find(y => y.level == x.hrdCloudCateLevel).label)});
        }
    })
}

const makeTreeNode = (origin, rtnItem) => {
    origin.map(data => {
        // 1 depth
        if(!rtnItem.value.find(x => x.hrdCloudCateSn == data.hrdCloudCateSn)){
            rtnItem.value.push({
                'hrdCloudCateSn': data.hrdCloudCateSn,
                'hrdCloudCateNm': data.hrdCloudCateNm,
                'sortOrd': data.sortOrd,
                'isCompleted': false,
                'isActive': false,
                'levels': []
            });
        }else if(rtnItem.value.find(x => x.hrdCloudCateSn == data.hrdCloudCateSn).sortOrd > data.sortOrd){
            rtnItem.value.find(x => x.hrdCloudCateSn == data.hrdCloudCateSn).sortOrd = data.sortOrd;
        }

        // 2 depth
        if(!rtnItem.value.find(x => x.hrdCloudCateSn == data.hrdCloudCateSn).levels.includes(data.hrdCloudCateLevel)){
            let levelNm;
            if (data.hrdCloudCateSn === 1984) {
                levelNm = partTimerCateLevels.find(x => x.level == data.hrdCloudCateLevel).label;
            } else if ([2047,2048].includes(data.hrdCloudCateSn)) {
                levelNm = preCateLevels.find(x => x.level == data.hrdCloudCateLevel).label;
            } else {
                levelNm = hrdCloudRoadmapCateLevels.find(x => x.level == data.hrdCloudCateLevel).label;
            }
            rtnItem.value.find(x => x.hrdCloudCateSn == data.hrdCloudCateSn).levels.push({
                'hrdCloudRoadmapSn': data.hrdCloudRoadmapSn,
                'roadmapTitle': data.roadmapTitle,
                'hrdCloudCateLevel': data.hrdCloudCateLevel,
                'hrdCloudCateLevelNm': levelNm,
                'upskillRcmdYn': data.upskillRcmdYn,
                'reskillRcmdYn': data.reskillRcmdYn,
                'isCompleted': data.cttCmptnCount > 0 && data.cttCount <= data.cttCmptnCount,
                'isActive': false,
                'cttList': [],
            });
        }
        // 카테고리 완료 여부
        rtnItem.value.find(x => x.hrdCloudCateSn == data.hrdCloudCateSn).isCompleted = rtnItem.value.find(x => x.hrdCloudCateSn == data.hrdCloudCateSn).levels.filter(x => x.isCompleted === false).length == 0;
        // 정렬
        rtnItem.value.find(x => x.hrdCloudCateSn == data.hrdCloudCateSn).levels.sort((a,b) => a.hrdCloudCateLevel - b.hrdCloudCateLevel);
    })

    // 정렬
    rtnItem.value.sort((a,b) => a.sortOrd - b.sortOrd);
}

const getHrdCloudLearningRoadmapCttList = (level) => {
    store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_LEARNING_ROADMAP_CTT_LIST}`, level.hrdCloudRoadmapSn).then(res => {
        if(lengthCheck(res)){
            level.cttList = getItems(res);
        }
    }).finally(() => {level.isActive = !level.isActive;})
}

const toggleLevel = (level) => {
    if(level.cttList.length == 0 && !level.isActive){
        getHrdCloudLearningRoadmapCttList(level);
    }else{
        level.isActive = !level.isActive;
    }
}

const moveToContentBridge = (router, lrnObjDtlDistSn) => {
    router.push({name: 'HrdCloudContentBridge', params: {'lrnObjDtlDistSn': lrnObjDtlDistSn, 'mode': 'uebung'}});
}