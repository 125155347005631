<template>
  <header class="kb-header" id="kb-header"> <!-- v-if="!calendar.show" -->
    <div class="header-component">
      <div v-if="!hideTitle" class="header-column header-title">
        <slot name="title"></slot>
      </div>

      <div class="header-column header-util left">
        <slot name="left"></slot>
      </div>

      <!--      <div class="header-column header-util right">-->
      <!--        <slot name="right"></slot>-->
      <!--        <router-link :to="{name: 'pubNotice', params:{expsrLoc:expsrLocCds.EXPSR_LOC_CD_SSL_MAIN}}"><i class="icon-search"></i></router-link>-->
      <!--      </div>-->
      <div class="header-column header-util right">
        <slot name="right" />
        <router-link :to="{name: 'pubNotice', params:{expsrLoc:expsrLocCds.EXPSR_LOC_CD_SSL_MAIN}}">
          <i class="icon-notice"></i>
          <div style="font: normal 11px/16px var(--kb-font-KBFGDisplayB); padding-top: 5px;">
            공지사항
          </div>
        </router-link>

      </div>
    </div>
  </header>

</template>
<script>

import {computed} from 'vue';
import {useStore} from 'vuex';
import {expsrLocCds} from "@/assets/js/modules/help/help-common";

export default {
  name: 'SSLMobileSlotHeader',
  components: {},
  props: {
    hideTitle: Boolean,
  },
  setup() {
    const store = useStore();
    const calendar = computed(() => store.state.ssl.calendar);

    return {
      calendar,
      expsrLocCds
    }
  }
}
</script>