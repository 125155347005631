<template>
  <div class="page-intro" :class="{'page-intro-black': isBlack}">
    <div class="intro-content">
      <div class="page-component">
        <h2 class="intro-title" >{{ mainName }}</h2>
        <div class="intro-buttons">
          <button class="kb-btn-intro" @click="goMySSL">
            <span class="kb-btn-border kb-left-unset"></span>
            <i class="icon-my"></i>
            <span class="text">SSL</span>
          </button>

          <button v-if="distCrseCd === 'group'" class="kb-btn-intro" @click="goGroup">
            <span class="kb-btn-border kb-left-unset"></span>
            <i class="icon-my"></i>
            <span class="text">그룹</span>
          </button>

          <button v-if="distCrseCd === 'knowledge'" class="kb-btn-intro" @click="goKnowledge">
            <span class="kb-btn-border kb-left-unset"></span>
            <i class="icon-my"></i>
            <span class="text">Test</span>
          </button>

        </div>
      </div>
    </div>
    <div class="intro-background"></div>
  </div>
</template>
<style scoped>
.kb-left-unset {
    left: unset !important;
}
</style>
<script>

import {noneRewardCds} from '@/assets/js/modules/ssl/ssl-common';
import {useRoute, useRouter} from 'vue-router';
import {computed} from 'vue';
import {useStore} from "vuex";

export default {
  name: 'SSLMobileMainMy',
  props: {
    mainName: String,
    goMySSL: Function
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const distCrseCd = computed(() => route.params.distCrseCd);
    const isBlack = computed(() => ['selfIct','selfJa','selfGa','selfGi','selfDlv','group', 'trivia', 'college', 'live'].includes(distCrseCd.value));
    const isNoneReward = computed(() => noneRewardCds.includes(distCrseCd.value));

    // 그룹러닝 나의 그룹
    const myGroup = computed(() => store.state.ssl.group);

    const goGroup = () =>{
      if(myGroup.value.length > 0) {
        router.push({name: 'GroupView', params: {distCrseSn: myGroup.value[0].distCrseSn}});
      } else {
        router.push({name: 'GroupLearning'});
      }
    }
    const goKnowledge = () =>{
      router.push({name: 'KnowledgeELearning'});
    };

    return {
      distCrseCd,
      isBlack,
      isNoneReward,
      goGroup,
      goKnowledge

    }
  }
}
</script>
