<template>
  <!-- content-section:학습 현황 -->
  <section class="content-section section-divider-m">
    <header class="section-header">
      <h4 class="title">그룹
      </h4>
    </header>
    <div class="learningGroup-container">
      <!-- symbol-item-wrap -->
      <MobileGroupCard :items="items" :dist-crse-sn="distCrseSn"/>
      <!-- //symbol-item-wrap -->
    </div>
  </section>

  <!-- content-section:그룹모임 -->
  <section class="content-section section-divider-m  mt-2">
    <header class="section-header">
      <h4 class="title">모임일정</h4>
    </header>
    <div class="learningGroup-container">
      <!-- symbol-item-wrap -->
      <ul class="symbol-item-wrap symbol-item-margin" data-content="row">
        <!-- symbol-item -->
        <li class="symbol-item">
          <div class="item-row row-content">
            <div v-if="existsBoard" class="item-learning">
              <div class="content-title">{{ item.objNm }}<div class="badge mini-badge"><span class="badge-text" style="vertical-align: -webkit-baseline-middle">{{ item.lrnObjTyCd === '100020' ? '온라인' : '오프라인' }}</span></div></div>
              <div class="content-texts text-break">
                <span class="text">모임예정 {{timestampToDateFormat(item.objBgngDt, 'yyyy.MM.dd')}} {{timestampToDateFormat(item.objBgngDt, 'hh:mm')}}-{{timestampToDateFormat(item.objEndDt, 'hh:mm')}}</span>
              </div>
            </div>
            <div v-else class="learningGroup-empty">
              <p class="text">진행 예정인 모임일정이 없습니다.</p>
            </div>
          </div>
          <template v-if="existsBoard && isLeader">
            <div v-if="!item.expsrBgngDt" class="item-row row-buttons">
              <button
                  v-if="items.length > 0"
                  class="kb-btn kb-btn-outline"
                  @click="clickClassEdit(items.find(x=>distCrseSn==distCrseSn).boardSn, item.lrnPostSn)"><span class="text">수정 ・ 삭제</span>
              </button>
            </div>
            <div class="item-row row-buttons">
              <button
                  v-if="items.length > 0 && item.progLogSn > 0"
                  class="kb-btn kb-btn-outline"
                  @click="lrnStatusUpdate(items.find(x=>distCrseSn==distCrseSn).boardSn, item)"><span class="text">학습종료취소</span>
              </button>
            </div>
            <div v-if="item.expsrEndDt" class="item-row row-buttons">
              <button class="kb-btn kb-btn-outline" @click="clickClassEdit(items.find(x=>distCrseSn==distCrseSn).boardSn, 0)"><span class="text">모임등록</span></button>
            </div>
          </template>
          <div v-if="existsBoard" class="item-row row-buttons">
            <button class="kb-btn kb-btn-primary" @click="enterClass(item)"><span class="text">입장하기</span></button>
          </div>
          <div v-else-if="!existsBoard && isLeader" class="item-row row-buttons">
            <button class="kb-btn kb-btn-primary" @click="clickClassEdit(items.find(x=>distCrseSn==distCrseSn).boardSn, existsBoard ? item.lrnPostSn : 0)"><span class="text">모임등록</span></button>
          </div>
        </li>
        <!-- //symbol-item -->
      </ul>
      <!-- //symbol-item-wrap -->
    </div>
  </section>

  <!-- content-section:그룹 학습현황 -->
  <section class="content-section section-divider-m">
    <header class="section-header">
      <h4 class="title">학습현황</h4>
    </header>
    <div class="roundedbox-wrap roundedbox-low">
      <!-- roundedbox -->
      <div class="roundedbox">
        <div class="roundedbox-top">
          <h5 class="roundedbox-title">총 모임횟수</h5>
        </div>
        <div class="roundedbox-bottom">
          <span class="roundedbox-text">{{ statusItem.grpMoimAdmitCnt }}회</span>
        </div>
      </div>
      <!-- roundedbox -->
      <div class="roundedbox">
        <div class="roundedbox-top">
          <h5 class="roundedbox-title">평균 출석률</h5>
        </div>
        <div class="roundedbox-bottom">
          <span class="roundedbox-text">{{ statusItem.avgGrpMoimAttndRatio }}%</span>
        </div>
      </div>
      <!-- roundedbox -->
      <div class="roundedbox">
        <div class="roundedbox-top">
          <h5 class="roundedbox-title">평균 콜드콜 응답률</h5>
        </div>
        <div class="roundedbox-bottom">
          <span class="roundedbox-text">{{ statusItem.avgColdcallRatio }}%</span>
        </div>
      </div>
      <!-- roundedbox -->
      <div class="roundedbox">
        <div class="roundedbox-top">
          <h5 class="roundedbox-title">평균 학습시간</h5>
        </div>
        <div class="roundedbox-bottom">
          <span class="roundedbox-text">{{ statusItem.avgLearningTimeRatioMi }}분</span>
        </div>
      </div>
    </div>
  </section>

  <!-- content-section:러닝닭컴 -->
  <section class="content-section section-stamp section-divider-m">
    <header class="section-header">
      <h4 class="title">러닝닭컴</h4>
      <div class="header-side">
        <p class="text-sm text-muted">회차별 <span class="text-sm" style="color:#ff8000;">‘학습자 전원이 출석한 모임횟수 4회 이상’</span> 달성 시 <br> 치킨쿠폰 지급 (회차별 대상 기간은 시행문 또는 공지사항 참고) <br>
          ※ 총 모임횟수와 러닝닭컴 인정횟수는 <span class="text-sm" style="color:#ff8000;">‘학습자 전원 출석 여부’</span>에 따라 상이할 수 있습니다.</p>
      </div>
    </header>
    <div class="stamp-list-wrap d-flex ">
      <div class="d-flex stamp-list flex-wrap">
        <div v-for="period in periods" :key="period.id" class="d-flex stamp-item" >
          <!-- stamp-item -->
          <!--        <p class="position-absolute">기간: {{period.period}}</p>-->
          <div v-for="index in 4" :key="index" class=" stamp-item ">
            <!--          일반-->
            <div v-if="index <= 3" class="d-flex stamp" :class="{'is-active' :  index <= period.acptCount}">
              <div class="stamp-content"><span class="text">{{ period.id }}회차</span></div>
            </div>
            <!--          쿠폰-->
            <div v-else class="stamp stamp-coupon" :class="{'is-active' :  index <= period.acptCount}">
              <div class="stamp-content"><i class="icon-chicken"></i><small class="text">쿠폰</small></div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    &lt;!&ndash; stamp-list-wrap &ndash;&gt;-->
<!--    <div class="stamp-list-wrap">-->
<!--      <ul class="stamp-list">-->
<!--        &lt;!&ndash; stamp-item &ndash;&gt;-->
<!--        <li v-for="(item, idx) in grpCpItems" :key="idx" class="stamp-item">-->
<!--          <div v-if="idx !== 4 && idx !== 9 && idx !== 14 && idx !== 19 && idx !== 23" class="stamp" :class="{'is-active' : item.acptYn === 'Y'}">-->
<!--            <div class="stamp-content"><span class="text">{{ idx+1 }}회</span></div>-->
<!--          </div>-->
<!--          <div v-if="idx === 4 || idx === 9 || idx === 14 || idx === 19 || idx === 23" class="stamp" :class="{'is-active' : item.acptYn === 'Y'}">-->
<!--            <div class="stamp-content"><span class="text"><i class="icon-chicken"></i></span></div>-->
<!--          </div>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->
<!--    &lt;!&ndash; //stamp-list-wrap &ndash;&gt;-->
  </section>

  <!-- content-section:그룹멤버 -->
  <section class="content-section section-divider-m">
    <header class="section-header">
      <h4 class="title">
        멤버현황
        <div class="header-side">
          <p class="text-sm text-muted">&nbsp;&nbsp;{{ groupItems.length }}명</p>
        </div>
      </h4>
    </header>
    <MobileGroupMembers :items="groupItems"/>
  </section>
</template>

<script>
import {groupStatusSetup} from "@/assets/js/modules/groupLearing/group-status-setup";
import MobileGroupCard from "@/components/prep/group/mobile/MobileGroupCard.vue";
import MobileGroupMembers from "@/components/prep/group/mobile/MobileGroupMembers.vue";

export default {
  name: "MobileGroupStatus",
  components: {MobileGroupMembers, MobileGroupCard},
  props: {
    items: Array,
    isLeader: Boolean
  },
  setup: groupStatusSetup
}
</script>