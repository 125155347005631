<template>
  <article class="myssl-section mt-0 pt-0" v-if="lmtCnt == 0">
    <div class="my-category category-self" style="justify-content: flex-start !important" >
      <div class="contents">
        <div class="list-top mt-0">
          <div class="top-column">
            <div class="sort" style="margin-right: 15px">
              <SortButtonSelect3
                  v-model="filters.distCrseSn"
                  v-model:toggle="selToggles.distCrseSn"
                  title="과정"
                  :options="distOptions"
                  sequence-key="distCrseSn"
                  name-key="crseNm"
                  :is-all="true"
                  :width="300"
                  :textStyle="`color:#000;font-size:14px; font-family:var(--kb-font-KBFGText);`"
                  @update:toggle="closeToggles(selToggles, 'distCrseSn')"
                  @selected="goBoard"
              />
            </div>
            <div class="sort" style="margin-right: 15px">
              <SortButtonSelect3
                  v-model="filters.objType"
                  v-model:toggle="selToggles.objType"
                  title="모든 타입"
                  :options="typeOptions"
                  sequence-key="key"
                  name-key="value"
                  :is-all="true"
                  :width="130"
                  :textStyle="`color:#000;font-size:14px; font-family:var(--kb-font-KBFGText);`"
                  @update:toggle="closeToggles(selToggles, 'objType')"
                  @selected="goBoard"
              />
            </div>
            <div class="sort" style="margin-right: 15px">
              <SortButtonSelect3
                  v-model="filters.sort"
                  v-model:toggle="selToggles.sort"
                  title="순서"
                  :options="sortOptions"
                  sequence-key="key"
                  name-key="value"
                  :width="130"
                  :textStyle="`color:#000;font-size:14px; font-family:var(--kb-font-KBFGText);`"
                  @update:toggle="closeToggles(selToggles, 'sort')"
                  @selected="goBoard"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
  <div class="contents">
    <template v-if="isLoading">
      <LoadingDiv v-model="isLoading" />
    </template>
    <SSLNoteCard
        v-if="lmtCnt > 0"
        :slides-per-view='"auto"'
        :sslItems="items"/>

    <SSLMobileBoardList
        v-else
        :is-loading="isLoading"
        :items="items"
        :paging="paging"
        :more-func="moreFunc"
    />
  </div>
</template>

<script>

import SSLMobileBoardList from "@/components/ssl/main/mobile/SSLMobileBoardList";
import {sslLearnerBoardSetup} from "@/assets/js/modules/ssl/ssl-learn";
import SortButtonSelect3 from "@/components/common/SortButtonSelect3.vue";
import SSLNoteCard from "@/components/main/SSLNoteCard.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";

export default {
  name: 'SSLMobileLearnerBoard',
  props:{
    sortCd:{
      type:String,
      default:null
    },
    lmtCnt:{
      type:Number,
      default:0
    },
    opt:{
      type:String,
      default:'N'
    }
  },
  components: {
    LoadingDiv,
    SSLNoteCard,
    SortButtonSelect3,
    SSLMobileBoardList
  },
  setup: sslLearnerBoardSetup
}
</script>
