<template>

<!--    <SSLMyTop/>-->
    <SSLBoardList
        :is-loading="isLoading"
        :items="items"
        :paging="paging"
        :more-func="moreFunc"
        :opt="opt"
    />

</template>

<script>
// import SSLMyTop from '@/components/ssl/my/SSLMyTop';

import SSLBoardList from '@/components/ssl/SSLBoardList';
import {sslMyBoardSetup} from '@/assets/js/modules/ssl/setup/ssl-my-setup';

export default {
  name: 'SSLMyBoard',
  components: {
    SSLBoardList,
    // SSLMyTop,
  },
  props: {
    sortCd: String,
    lmtCnt: {
      type:Number,
      default:0
    },
    opt:{
      Type:String,
      default:'N'
    }
  },
  setup: sslMyBoardSetup
}
</script>
