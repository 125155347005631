import {computed, ref} from "vue";
import {useStore} from "vuex";
import {ACT_GET_GROUP_LRN_TRGT_DTL_LIST} from "@/store/modules/prep/prep";
import {getItems, getPaging, lengthCheck} from "@/assets/js/util";
import {useRouter} from "vue-router";

export const groupLearningSetup = () =>  {
    const isActive = ref(false);

    const items = ref([]);

    const paging = ref({pageNo: 1, pageSize: 2, totalCount: 0}); //2개까지 노출

    const store = useStore();
    const router = useRouter();

    const isMobile = computed(() => store.state.auth.isMobile);
    const session = computed(() => store.state.auth.session);
    const userItem = ref();

    const bgImgUrl = require(isMobile.value ? '@/assets/lxp/mobile/images/prep/@header_bg_group.jpg' : '@/assets/lxp/images/prep/@header_bg_group.jpg');

    // 알럿들 공통으로 빼기 전 임시 코딩
    const isClassAlert = ref(false);
    const isDeleteAlert = ref(false);

    const isReady = ref(false);

    const getGroupList = () => {
        store.dispatch(`prep/${ACT_GET_GROUP_LRN_TRGT_DTL_LIST}`,{
            lrnerId: session.value.lrnerId,
            pageNo: paging.value.pageNo,
            pageSize: paging.value.pageSize
        }).then(res => {
            if(lengthCheck(res)){
                items.value = getItems(res);
                paging.value = getPaging(res);
                console.log(items)
                if(items.value[0].stt === '00') {
                    router.push({name: 'SSLMain', params: {distCrseCd: 'group', lrnTrgtGrpMstSn: 119739}});
                } else {
                    isReady.value = true;
                }
            }else{
                items.value = [];
                isReady.value = true;
            }
        }).catch(e => {
            console.error(e);
        })
    }
    getGroupList();

    const deleteClass = () => {
        isDeleteAlert.value = true;
    }

    const closeAlert = () => {
        isClassAlert.value = false;
        isDeleteAlert.value = false;
    }

    const toggleGuide = ref(false);

    const clickGuide = () => {
        toggleGuide.value = !toggleGuide.value;
    }

    return {
        isMobile,
        isActive,
        bgImgUrl,
        items,
        userItem,
        isClassAlert,
        isDeleteAlert,
        paging,
        toggleGuide,
        isReady,
        guideFileKey: 'GroupLearningGuide',
        clickGuide,
        deleteClass,
        closeAlert,
    }
}