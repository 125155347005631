<template>
  <main class="ssl-body" id="ssl-notice-list">
    <header class="ssl-header back">
      <div class="page-component">
        <!-- header-back -->
        <div class="header-column header-back">
          <a href="javascript:" class="back-link" @click="goBack">
            <span class="back"></span>
          </a>
        </div>
        <!-- //header-back -->
        <!-- header-title -->
        <!-- 백 버튼 헤더 가운데에 들어가는 타이틀 -->
        <div class="header-column header-title">
          <h3>{{ distCrseNm }} 공지사항 </h3>
        </div>
        <!-- //header-title -->
        <!-- header-right -->
        <div v-if="paging.totalCount > 0" class="header-column header-right">
          <span class="silver">전체 {{ paging.totalCount }}건</span>
        </div>
        <!-- //header-right -->
      </div>
    </header>

    <!-- page-container -->
    <div class="page-container">

      <div class="board-list-container">
        <ul class="notice-list-type">
          <!--notice-item -->
          <li v-for="(item, idx) in items" class="notice-item" :key="idx">
            <router-link :to="{name: 'SSLNoticeView', params: {socLrnNtcSn: item.comNtcSn}}">
              <p class="title">{{ item.ntcTitle }}</p>
              <p class="item-info">
                <span>{{ item.expsrLocNm }}</span>
                <span>{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd') }} 작성</span>
                <span>{{ numberComma(item.inqCnt) }} 조회</span>
              </p>
            </router-link>
          </li>
        </ul>
        <SSLMobilePagination
            :paging="paging"
            :page-func="pageFunc"
        />
      </div>
    </div>
  </main>
</template>

<script>

import {computed, ref, watch} from 'vue';
import {dateToDateFormat, getItems, getPaging, lengthCheck, numberComma, timestampToDateFormat} from '@/assets/js/util';
import {useStore} from 'vuex';
import {ACT_GET_SSL_NTC_LIST} from '@/store/modules/ssl/ssl-index';
import {getExpsrLocCd, sslRoutes} from '@/assets/js/modules/ssl/ssl-common';
import {useRoute, useRouter} from 'vue-router';
import SSLMobilePagination from '@/components/ssl/notice/mobile/SSLMobilePagination';
import {expsrLocCds} from '@/assets/js/modules/help/help-common';

export default {
  name: 'SSLMobileNotice',
  components: {
    SSLMobilePagination,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isLoading = ref(false);
    const items = ref([]);
    const paging = ref({pageNo: 1, pageSize: 10, totalCount: 0});

    const distCrseNm = computed(() => sslRoutes[route.params.distCrseCd] ? sslRoutes[route.params.distCrseCd].name : 'SSL' );
    const expsrLocCd = computed(() => getExpsrLocCd(route.params.distCrseCd));

    const pageFunc = (num) => {
      router.push({query: {pageNo: num}});
    }

    const getNoticeList = () => {
      isLoading.value = true;
      store.dispatch(`ssl/${ACT_GET_SSL_NTC_LIST}`, {
        nowDate: dateToDateFormat(new Date(), 'yyyy-MM-dd'),
        expsrLocCd: `${expsrLocCds.EXPSR_LOC_CD_SSL_COMMON},${expsrLocCd.value ? expsrLocCd.value : expsrLocCds.EXPSR_LOC_CD_SSL_MAIN}`,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if(lengthCheck(res)){
          items.value = getItems(res);
          paging.value = getPaging(res);
        }
        isLoading.value = false;
      }).catch(() => {});
    }

    const goBack = () => {
      if(route.params.distCrseCd){
        let trgtGrpMstSn = sslRoutes[route.params.distCrseCd].group[0].lrnTrgtGrpMstSn;
        router.push({name: 'SSLMain', params: {distCrseCd: route.params.distCrseCd, lrnTrgtGrpMstSn: trgtGrpMstSn}});
      }else{
        router.push({name: 'SSLHome'});
      }
    }

    watch(() => route.query, () => {
      paging.value.pageNo = route.query.pageNo > 0 ? parseInt(route.query.pageNo) : 1;
      getNoticeList();
    });

    paging.value.pageNo = route.query.pageNo > 0 ? parseInt(route.query.pageNo) : 1
    getNoticeList();


    return {
      distCrseNm,
      isLoading,
      items,
      paging,
      pageFunc,
      timestampToDateFormat,
      numberComma,
      goBack
    }

  }
}
</script>
