<template>
  <!-- page-container -->
  <div v-if="isReady" class="page-container">
    <div class="page-top">
      <div class="page-component">
        <div class="top-nav">
          <button class="kb-btn-type back top-kb-nav-link" @click="goBack">
<!--          <router-link :to="{name: 'SSLHome'}" class="top-kb-nav-link">-->
            <span class="ic-bg"><i class="icon-type-back"></i></span>
            <span class="text">이전</span>
<!--          </router-link>-->
          </button>
        </div>

      </div>
    </div>
    <div class="page-body">
      <div class="myssl-container">
        <SSLLearnerTop
            :lrner="target"
            :isNotMine="isNotMine"
        />
<!--        :main-name="mainName"-->

<!--        <div class="myssl-top">-->
<!--          <SSLMyWrite v-if="isRecord && distCrseCd === 'book'" :lrner="target"/>-->
<!--          <SSLMyActivity v-if="isRecord && distCrseCd === 'book'" :lrner="target"/>-->
<!--        </div>-->
        <SSLLearnerHome v-if="isHome" />
        <div class="myssl-section" v-else-if="isBoard">
        <SSLLearnerBoard />
        </div>
      </div>
    </div>
    <!-- //page-footer -->
  </div>
  <!-- //page-container -->
</template>

<script>
import SSLLearnerTop from '@/components/ssl/learner/SSLLearnerTop';
import SSLLearnerBoard from '@/components/ssl/learner/SSLLearnerBoard';
import {sslLearnerCommSetup} from '@/assets/js/modules/ssl/setup/ssl-learn-comm-setup';
// import SSLMyWrite from "@/components/ssl/my/SSLMyWrite.vue";
// import SSLMyActivity from "@/components/ssl/my/SSLMyActivity.vue";
import SSLLearnerHome from "@/components/ssl/learner/SSLLearnerHome.vue";

export default {
  name: 'SSLLearnerComm',
  components: {
    SSLLearnerHome,
    // SSLMyActivity,
    // SSLMyWrite,
    SSLLearnerBoard,
    // SSLLearnerBadge,
    SSLLearnerTop
  },
  setup: sslLearnerCommSetup
}
</script>
