import {ACT_GET_HRD_NOW_ON_SECRET} from "@/store/modules/nowon/nowon";
import {getItem, isSuccess, lengthCheck, nowWinOpen} from "@/assets/js/util";
import store from '@/store';
import {isInApp} from "@/assets/js/navigationUtils";
import {useAlert} from "@/assets/js/modules/common/alert";
import {ACT_GET_MY_TRGT_LIST} from "@/store/modules/live/live";
import {executeApps} from "@/assets/js/nativeAppUtils";

const {showConfirm, showMessage} = useAlert();
// 온클래스 입장조건 체크
export const entranceCloudNowOnCheck = (params) => {
    store.dispatch(`live/${ACT_GET_MY_TRGT_LIST}`, params)
        .then(res => {
            if (lengthCheck(res)) {
                let item = getItem(res);
                let entrancetime = 10;
                if (item.crseMstSn == '100331' || item.crseMstSn == '100332') {
                    entrancetime = 15;
                }
                let msgText = `해당 강의는 입장가능시간이 아닙니다. <br> 입장은 강의시작 시간 ${entrancetime}분전부터 가능합니다.`;
                if((new Date(item.objBgngDt).getTime() - new Date().getTime())/1000/60 > entrancetime && item.oprId == 'N'){
                    showMessage(msgText);
                    return false;
                }else if(((new Date(item.objBgngDt).getTime() - new Date().getTime())/1000/60 < entrancetime) && item.objEndDt > new Date().getTime()) {
                    if(item.objEndYn == 'Y'){
                        showMessage('해당 강의는 종료되었습니다.');
                        return false;
                    }else{
                        entranceCloudNowOn(params)
                    }
                }else{
                    if(item.objEndYn == 'Y'){
                        showMessage('해당 강의는 종료되었습니다.');
                        return false;
                    }else{
                        entranceCloudNowOn(params)
                    }
                }
            }
        }).catch(e=>{
        console.error(e);
    })
}

// 클라우드 나우/온 입장
export const entranceCloudNowOn = (params) => {
    console.log(params)
    const session = store.state.auth.session;
    const {showMessage} = useAlert();

    if(session.itn){
        showMessage('내부망에서 지원하지 않습니다. <br>외부망기기에서 참여해주세요');
        return false;
    }else{
        let cloudType;
        if(params.objType === '100008' || params.objType === '100026'){
            cloudType = 'now'
        }else{
            cloudType = 'on'
        }
        showConfirm({
            title: `[클라우드 ${cloudType.toUpperCase()}] 강의 입장`,
            text: '해당 강의로 입장하시겠습니까?',
            callback: () => {
                store.dispatch(`nowon/${ACT_GET_HRD_NOW_ON_SECRET}`,params
                ).then(res => {
                    //if(res.status.code === 200 || res.resultCodes === 'ALREADY_APPLY'){
                    if(isSuccess(res)){
                        const secret = res.secret;
                        params.etnRefDtl = params.etnRefDtl || '';

                        if (isInApp) callNativeOnclass(params, secret, 'callFullLayer')
                        else nowWinOpen(`${process.env.VUE_APP_NOWON_URL}/cloud/${cloudType}/${secret}/LIVE_IVS`, 'width=1350, height= 980, resizable=no,status=no')

                        // if(params.etnRefDtl.indexOf("ehrdcloudnow") > -1) {
                        //     store.dispatch(`nowon/${ACT_CLOUD_NOW_SSO}`, params).then(res2 =>{
                        //         if(res2) {
                        //             if (isInAppIOS) {
                        //                 callNative(params, secret, {
                        //                     popupType : "notice", notiDeny :"hidden", titlefontColor : "#2d2d2d", titleBGColor : "#FFFFFF", title:"닫기", actionUrl: 'https://ehrd.kbstar.com/pr/kbh_cloudnow.directurl_lxp?p_token=' + res2
                        //                 }, 'LayerCall');
                        //             }
                        //             else {openPopUp('https://ehrd.kbstar.com/pr/kbh_cloudnow.directurl_lxp?p_token=' + res2, 'width=1350, height= 980, resizable=no,status=no');}
                        //         }
                        //     });
                        // } else {
                        //
                        // }

                    }else{
                        onclassErrorAlert(res.resultNumber, res.resultMessage);
                    }
                }).catch(e => {
                    console.error(e);
                    showMessage('서버처리 중 일시적인 장애가 발생하였습니다.<br>잠시 후 다시 시도 해 주시기 바랍니다.');
                    return;
                })
            },
        });

    }
}

// asis 네이티브 호출
// export const callNative = async (params, secret, jsonParam, callLayer) => {
//     let cloudType = '';
//     if(params.objType === '100008' || params.objType === '100026'){
//         cloudType = 'now'
//     }else{
//         cloudType = 'on'
//     }
//
//     let encodeSecret = encodeURIComponent(encodeURIComponent(secret))
//
//     const jsonData = {
//         cmd: 'callLayer',
//         role: 'LIVE_SENDBIRD',
//         direction: 'up',
//         speed: 500,
//         actionUrl: `${process.env.VUE_APP_NOWON_URL}/cloud/${cloudType}/${encodeSecret}`,
//     }
//
//     const objData = Object.assign(jsonData, jsonParam);
//     executeApps('callFullLayer', objData);
//     // let jsonString = JSON.stringify(objData);
//
//
//     // if (isInAppAnd) window.Android.callFullLayer(jsonString);
//     // else if (isInAppIOS) window.location = `iOSNativeCall://${callLayer}?` + (jsonString);
// }

// 온클래스 호출
export const callNativeOnclass = async (params, secret) => {
    let cloudType = '';
    if(params.objType === '100008' || params.objType === '100026'){
        cloudType = 'now'
    }else{
        cloudType = 'on'
    }

    let encodeSecret = encodeURIComponent(encodeURIComponent(secret))

    const jsonData = {
        cmd: 'callLayer',
        // role: 'LIVE_SENDBIRD',
        role: 'LIVE_IVS',
        direction: 'up',
        speed: 500,
        actionUrl: `${process.env.VUE_APP_NOWON_URL}/cloud/${cloudType}/${encodeSecret}/LIVE_IVS`,
    }
    executeApps('callFullLayer', jsonData);

    // let jsonString = JSON.stringify(jsonData);
    //
    // if (isInAppAnd) window.Android.callFullLayer(jsonString);
    // else if (isInAppIOS) window.location = "iOSNativeCall://callFullLayer?" + (jsonString);
}

const onclassErrorAlert = (resultNumber, resultMessage) => {
    let errorNumber = [301,302,303,304,305,306,307,308,309,310,311,312,313,314,315,316];
    if(errorNumber.includes(resultNumber)){
        showMessage(resultMessage);
    }else{
        showMessage('서버처리 중 일시적인 장애가 발생하였습니다.<br>잠시 후 다시 시도 해 주시기 바랍니다.');
    }
    return;
}

// 로드맵 설정 카테고리 일련번호: 자산관리(9), 퇴직연금(8), 가계여신(19), 기업여신(18), 외국환(17)
export const hrdCloudRoadmapCateSns = [9,8,19,18,17];
// 로드맵 카테고리 단계
export const hrdCloudRoadmapCateLevels = [{level:1,label:'초급'},{level:2,label:'중급'},{level:3,label:'고급'}];

export const preCateLevels = [
    {level:1,label:'1월'},{level:2,label:'2월'},{level:3,label:'3월'},
    {level:4,label:'4월'},{level:5,label:'5월'},{level:6,label:'6월'},
    {level:7,label:'7월'},{level:8,label:'8월'},{level:9,label:'9월'},
    {level:10,label:'10월'},{level:11,label:'11월'},{level:12,label:'12월'}];

export const partTimerCateLevels = [{level:1,label:'영상'},{level:2,label:'실습'},{level:3,label:'교재'},{level:4,label:'사고예방'}];

export const getEditorWordCount = (str) => {
    return str.replace(/(<([^>]+)>)/ig,'').replaceAll('&nbsp;', '').replaceAll('\n', '').length;
}