<template>
  <main class="kb-main" id="ssl-home-v2" v-if="!attendanceModal">
    <SSLMobileSlotHeaderV2>
      <template v-slot:title>
        <SSLMobileNav />
      </template>
    </SSLMobileSlotHeaderV2>
    <form class="kb-form-search-forms">
      <div class="kb-form-search-v2">
        <div class="kb-form-search-field"><input type="text" v-model.trim="search.keyword"
                                                 class="kb-form-search-input-v2"
                                                 @keydown.enter.prevent="searchKeyword(search.keyword)"
                                                 placeholder="어떤 콘텐츠를 찾으시나요?">
          <button type="button" class="kb-form-search-btn" @click="searchKeyword(search.keyword)"><i class="icon-search">검색</i>
          </button>
          <button v-if="search.keyword.length > 0" type="button" class="kb-form-search-delete" @click="resetSearch"><i class="icon-delete">삭제</i></button>
        </div>
      </div>
      <article class="content-section section-my" style="padding-left: 15px;">
        <div class="my-info">
          <div class="info-content">
            <h4 class="name" style="font: normal 16px/22px var(--kb-font-KBFGDisplayB);">{{ chNm }}님, 반가워요!</h4>
          </div>
        </div>
      </article>

      <article>
        <SSLMobileActivityV2 />
      </article>
<!--      <div style="display: flex; width: 100%; overflow-x: auto; padding: 15px;">-->
<!--        <div v-for="(data,idx) in activityInfoItems" :key="idx" style="margin-right: 5px;">-->
<!--          <a v-if="data.activityInfoNm === 'my SSL'" class="kb-btn kb-btn-wide rounded-lg loadmap-btn" href="javascript:" @click="goMyNote"><span-->
<!--              class="text">{{ data.activityInfoNm }} <i class="icon-arrow" style="height: 17px;"></i></span>-->
<!--          </a>-->
<!--          <a v-else-if="data.activityInfoNm === '출석일수'" class="kb-btn kb-btn-wide rounded-lg loadmap-btn" href="javascript:" @click="showAttendance"><span-->
<!--              class="text">{{ data.activityInfoNm }} : {{ data.activityInfoItem }} <i class="icon-arrow" style="height: 17px;"></i></span>-->
<!--          </a>-->
<!--          <a v-else class="kb-btn kb-btn-wide rounded-lg loadmap-btn" href="javascript:"><span class="text">{{ data.activityInfoNm }} : {{ data.activityInfoItem }}</span>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
    </form>

    <MobileBanerPopupModal
        v-if="popups.length > 0 && !expirationPopup && !lochref.includes('?')"
        :items="popups"
    />

    <SSLMobileHomeStatusV2 v-show="!isSearch">
    </SSLMobileHomeStatusV2>

    <main class="kb-main" id="kb-home" style="padding-bottom: 0px;">

      <article class="content-section section-hrdcloud" style="border-top: 1px solid var(--kb-light-silver);">
        <header class="section-header d-flex">
          <h2 class="title">SSL Studio</h2>
          <div class="tabs">
            <a v-for="(item, idx) in sslList" :key="idx" href="javascript:" class="tab" :class="{'is-active' : sslCardFilter === item.name}" @click="sslCardFilter = item.name">
              {{ item.title }}
            </a>
          </div>
        </header>
        <!-- hrdcloud-list-wrapper -->
        <div class="hrdcloud-list-wrapper">
          <template v-if="sslLoading">
            <LoadingDiv v-model="sslLoading" />
          </template>
          <template v-else>
            <!-- board-list -->
            <template v-if="sslitems.length > 0">
              <SSLNoteCard :slides-per-view='"auto"'
                           :sslItems="sslitems"/>
            </template>
            <template v-else>
              <div class="search-container">
                <div class="result-empty" style="text-align: center; margin-top: 30px; margin-bottom: 30px;"><img
                    src="../../../assets/lxp/images/common/img_empty.png" alt="">
                  <p class="text">{{sslCardFilter=='excellent'?'인기':'추천'}} SSL이 없습니다.</p>
                </div>
              </div>
            </template>
          </template>
        </div>
      </article>

      <article v-if="!isSearch" class="content-section section-hrdcloud" style="border-top: 1px solid var(--kb-light-silver);">
        <header class="section-header">
          <h2 class="title">구독 신규</h2>
        </header>
        <!-- hrdcloud-list-wrapper -->
        <div class="hrdcloud-list-wrapper">
          <template v-if="sslLoading">
            <LoadingDiv v-model="sslLoading" />
          </template>
          <template v-else>
            <!-- board-list -->
            <template v-if="subscribeItems.length > 0">
              <SSLNoteCard :slides-per-view='"auto"'
                           :sslItems="subscribeItems"/>
            </template>
            <template v-else>
              <div class="search-container">
                <div class="result-empty" style="text-align: center; margin-top: 30px; margin-bottom: 30px;"><img
                    src="../../../assets/lxp/images/common/img_empty.png" alt="">
                  <p class="text">구독직원의 SSL이 없습니다.</p>
                </div>
              </div>
            </template>
          </template>
        </div>
      </article>

      <article v-if="isSearch" class="content-section section-hrdcloud" style="border-top: 1px solid var(--kb-light-silver);">
        <header class="section-header d-flex">
          <h2 class="title">검색 노트</h2>
        </header>
        <!-- hrdcloud-list-wrapper -->
        <div class="hrdcloud-list-wrapper">
          <template v-if="sslLoading">
            <LoadingDiv v-model="sslLoading" />
          </template>
          <template v-else>
            <!-- board-list -->
            <template v-if="sslitems.length > 0">
              <SSLNoteCard :slides-per-view='"auto"'
                           :sslItems="sslitems"
                           :addSSLItems="addSSLItems"
                           :swipeLoading="swipeLoading"/>
            </template>
            <template v-else>
              <div class="search-container">
                <div class="result-empty" style="text-align: center; margin-top: 30px; margin-bottom: 30px;"><img
                    src="../../../assets/lxp/images/common/img_empty.png" alt="">
                  <p class="text">검색된 SSL이 없습니다.</p>
                </div>
              </div>
            </template>
          </template>
        </div>
      </article>
    </main>
    <SSLMobileLink/>

  </main>
</template>

<script>
import {computed, reactive, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {getDcdOptions, pushQuery} from '@/assets/js/ui.init';
import {useRoute} from 'vue-router';
import {
  dateToDateFormat,
  getItem, getItems, isSuccess,
  lengthCheck,
  stringCheck,
  timestampToDateFormat
} from '@/assets/js/util';
import {banerDivCdDcds, banerExpsrLocCdDcds, getBanerList} from '@/assets/js/modules/baner/common-baner';
import MobileBanerPopupModal from '@/components/main/mobile/MobileBanerPopupModal';
import {
  getTodayDate
} from "@/assets/js/util";
import {
  ACT_GET_BEST_SUBSCRIBES_NOTE_LIST,
  // ACT_GET_MY_SOC_STAT,
  ACT_GET_SOC_BOARD_LIST,
  ACT_GET_SOC_CNT,
  ACT_GET_SOC_EXCELLENT_BOARD_LIST, ACT_GET_SOC_RECPOP_BOARD_LIST,
  ACT_GET_SOC_SUBSCRIBE_BOARD_LIST
} from "@/store/modules/ssl/ssl-index";
import {getCrseSnToDistCrseCd, getDistCrseCd, getMyInfo, showAttendance} from "@/assets/js/modules/ssl/ssl-common";
import {
  ACT_GET_USER_INTEREST_INFO_LIST,
  ACT_INSERT_USER_INTEREST_INFO,
  ACT_UPDATE_USER_INTEREST_INFO
} from "@/store/modules/interest/interest";
import {useAlert} from "@/assets/js/modules/common/alert";
import {moveSSL} from '@/assets/js/modules/common/common';
import {ACT_GET_BOARD_CATE_LIST, ACT_GET_BOARD_MST} from "@/store/modules/board/board-mst";
import {getPostList} from "@/assets/js/modules/board/board-common";
import router from "@/router";
import {currentYear, sslCodes} from "@/assets/js/modules/ssl/ssl-common";
import SSLNoteCard from "@/components/main/SSLNoteCard.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import SSLMobileLink from "@/components/ssl/home/mobile/SSMobileLLink.vue";
import SSLMobileNav from "@/components/ssl/mobile/SSLMobileNav.vue";
import SSLMobileSlotHeaderV2 from "@/components/ssl/main/mobile/SSLMobileSlotHeaderV2.vue";
import SSLMobileHomeStatusV2 from '@/components/ssl/home/mobile/SSLMobileHomeStatusV2.vue';
import {getBadgeList} from '@/assets/js/modules/ssl/ssl-badge';
import SSLMobileActivityV2 from "@/components/ssl/home/mobile/SSLMobileActivityV2"

export default {
  name: 'SSLMobileHomeV2',
  methods: {showAttendance},
  components: {
    SSLMobileHomeStatusV2,
    SSLMobileSlotHeaderV2,
    SSLMobileNav,
    SSLMobileLink,
    LoadingDiv,
    SSLNoteCard,
    MobileBanerPopupModal,
    SSLMobileActivityV2,
  },
  setup() {
    const store = useStore(), route = useRoute();
    const session = computed(() => store.state.auth.session);
    const sortTyCdDcd = computed(() => getDcdOptions('2073'));
    const isSearching = computed(() => (route.query && route.query.keyword && route.query.keyword.length > 0));
    const isLoading = ref(true);
    const sslLoading = ref(true);
    const sslSubscLoading = ref(true);
    const hottipLoading = ref(true);
    const hotTipMyPreviewList = ref([]);
    const tmpHotTipItems = ref([]);

    const showRoute = ref(true),
        showSubMenu = ref(false);
    const lochref = ref(location.href);

    const activeIdx = ref(-2);

    const isKbBank = computed(() => session.value.susdyCdDcd && (session.value.susdyCdDcd === '2109001'));

    const interestInfoItems = ref([]);


    const nickNm = computed(()=>store.state.ssl.mySSLInfo.lrnerNickNm);
    if (nickNm.value == null || nickNm.value == ''){
      getMyInfo();
    }

    const chNm = computed(()=> nickNm.value?nickNm.value:session.value.lrnerNm);
        // const activityInfoItems = ref([
    //   {
    //     activityInfoNm : 'my SSL',
    //     activityInfoItem : ''
    //   },
    //   {
    //     activityInfoNm : '출석일수',
    //     activityInfoItem : computed(() => Array.from(new Set(store.state.ssl.calendar.myAttends.map(x => x.datePart.ymd))).length)
    //   },
    //   {
    //     activityInfoNm : '나를 구독',
    //     activityInfoItem : computed(() => Array.from(new Set(store.state.ssl.subscribe.target.map(x => x.lrnerId))).length)
    //   },
    //   {
    //     activityInfoNm : '내가 구독',
    //     activityInfoItem : computed(() => Array.from(new Set(store.state.ssl.subscribe.my.map(x => x.trgtLrnerId))).length)
    //   },
    //   {
    //     activityInfoNm : '취득 배지',
    //     activityInfoItem : store.state.ssl.myBadges.length
    //   },
    //   {
    //     activityInfoNm : '받은 댓글',
    //     activityInfoItem : ''
    //   },
    //   {
    //     activityInfoNm : '받은 좋아요',
    //     activityInfoItem : ''
    //   },
    //   {
    //     activityInfoNm : '받은 조회',
    //     activityInfoItem : ''
    //   },
    //   {
    //     activityInfoNm : '남긴 댓글',
    //     activityInfoItem : ''
    //   },
    //   {
    //     activityInfoNm : '남긴 좋아요',
    //     activityInfoItem : ''
    //   }
    // ]);
    getBadgeList();

    // const getSocStat = () => {
    //   store.dispatch(`ssl/${ACT_GET_MY_SOC_STAT}`).then(res => {
    //     if(lengthCheck(res)){
    //       const items = getItems(res);
    //       console.log("getitems = ====", items[0]);
    //       activityInfoItems.value[5].activityInfoItem = items[0].cmntReceiveCnt;
    //       activityInfoItems.value[6].activityInfoItem = items[0].likeGetCnt;
    //       activityInfoItems.value[7].activityInfoItem = items[0].boardInqCnt;
    //       activityInfoItems.value[8].activityInfoItem = items[0].cmntWriteCnt;
    //       activityInfoItems.value[9].activityInfoItem = items[0].likeGiveCnt;
    //     }
    //   }).catch(() => {});
    // }

    // getSocStat();

    const attendanceModal = computed(()=> store.state.ssl.calendar.show)//ref(false);
    const interestInfoNms = ref("");
    const showInterestInfoModal = ref(false);
    const isSearch = ref(false);
    const {showConfirm, showMessage} = useAlert();
    const sslCurrentYear = currentYear;
    const sslTarget = sslCodes[sslCurrentYear];
    let boardMst = null;
    let boardCateList = [{cateNm: '전체', boardCateSn: ''}];
    const attendanceCnt = ref(0);
    const isSearchEnd = ref(false);
    const selectedMenu = ref(0);
    const lrnTrgtGrpMstSns = ref([]);

    Object.keys(sslTarget).forEach(k => {
      if(k !== 'trivia') {
        lrnTrgtGrpMstSns.value.push(sslTarget[k].group[0].lrnTrgtGrpMstSn);
      }
    });

    const getInterestInfoList = async () => {
      await store.dispatch(`interest/${ACT_GET_USER_INTEREST_INFO_LIST}`, {
        lrnerId: session.value.lrnerId,
      }).then(res => {
        if (lengthCheck(res)) {
          interestInfoItems.value = getItems(res);
          interestInfoNms.value = "";
          for (const target of interestInfoItems.value) {
            if (target.useYn == 'Y') {
              interestInfoNms.value = interestInfoNms.value + "," + target.interestInfoNm;
            }
          }
          if (interestInfoNms.value == "") {
            interestInfoNms.value = "미설정";
          } else {
            interestInfoNms.value = interestInfoNms.value.substring(1);
          }
        }
        // console.log('승격예비::',gradeItems.value)
      }).catch(err => {
        console.error(err)
      })
    }

    const getHotTipList = async (boardId) => {
      hottipLoading.value = true;
      await store.dispatch(`boardmst/${ACT_GET_BOARD_MST}`, boardId).then(async (res) => {
        boardMst = getItem(res);
        await store.dispatch(`boardmst/${ACT_GET_BOARD_CATE_LIST}`, boardMst.boardMstSn).then((res) => {
          boardCateList = boardCateList.concat(getItems(res));
        }).finally(async () => {
          await getPostList({
            boardMstSn: boardMst.boardMstSn,
            topFixYn: 'N',
            pageNo: 1,
            pageSize: 5
          }, tmpHotTipItems, null, boardCateList);
          hotTipMyPreviewList.value = tmpHotTipItems.value;
        })
      });

      hottipLoading.value = false;

    }

    const moveToBoardDtl = (item) => {
      router.push({name: 'BoardView', params: {'boardId': item.boardId, 'boardSecuKey': item.boardSecuKey}});
    }

    const topicSelected = async (item) => {
      if (item.useYn != "Y") {
        showConfirm({
          title: '관심 주제 설정 [' + item.interestInfoNm + ']',
          text: '나의 관심 주제로 설정하시겠습니까?',
          callback: async () => {
            await store.dispatch(`interest/${ACT_INSERT_USER_INTEREST_INFO}`, {
              interestInfoCdDcd: item.interestInfoCdDcd,
              interestInfoNm: item.interestInfoNm,
            }).then(res => {
              if (isSuccess(res)) {
                showMessage("설정이 완료됐습니다.");
                getInterestInfoList();
              }
              // console.log('승격예비::',gradeItems.value)
            }).catch(err => {
              console.error(err)
            })
          }
        });
      } else {
        showConfirm({
          title: '관심 주제 해제 [' + item.interestInfoNm + ']',
          text: '나의 관심 주제를 해제하시겠습니까?',
          callback: async () => {
            await store.dispatch(`interest/${ACT_UPDATE_USER_INTEREST_INFO}`, {
              interestInfoCdDcd: item.interestInfoCdDcd,
            }).then(res => {
              if (isSuccess(res)) {
                showMessage("설정이 완료됐습니다.");
                getInterestInfoList();
              }
              // console.log('승격예비::',gradeItems.value)
            }).catch(err => {
              console.error(err)
            })
          }
        });
      }
    }

    getInterestInfoList();

    const roadmap = ref([
      {type: 'time', title: '관심', keys: ['bookLrnSec'], showDetail: true, data: 0},
      {type: 'day', title: '기업여신', keys: ['bookLrnDayCnt'], showDetail: false, data: 1},
      {type: 'memo', title: '퇴직연금', keys: ['bookMemoCnt'], showDetail: true, data: 2},
      {type: 'complete', title: '수신/카드', keys: ['bookCompleteCnt'], showDetail: false, data: 3},
    ]);

    const sslList = ref([
      {name: 'recommend', title: '추천', isLoading: true, items:[] },
      {name: 'excellent', title: '인기', isLoading: true, items: []},
      // {name: 'subscribe', title: '구독', isLoading: true, items: []},
    ]);

    const sslitems = ref([]);

    store.dispatch(`ssl/${ACT_GET_SOC_CNT}`).then(res => {
      if (res && res.cnt === 0) {
        sslList.value[1].title = '';
      }
    });

    const view = reactive({
      name: 'main',
      title: 'SSL 홈'
    });
    const sslCardFilter = ref('recommend');

    const excellentItems = ref([]);
    const subscribeItems = ref([]);
    const recommendItems = ref([]);

    const categories = ref([]),
        tabMenu = ref(0),
        paging = reactive({
          pageNo: 1,
          pageSize: 10,
          totalCount: 0
        }),
        categoriesPaging = reactive({
          pageNo: 1,
          pageSize: 9999,
          totalCount: 0
        }),
        search = reactive({
          keyword: '',
          sortTyCdDcd: '',
          category1: '',
          category2: '',
          category3: '',
          category4: '',
          category5: ''
        }),
        toggles = reactive({
          sortTyCdDcd: false
        });

    const tmpKeyword = ref("");

    const resetSearch = () => {
      isSearch.value = false;
      search.keyword = '';
    }

    const searchKeyword = async (keyword) => {
      if (keyword.length === 0) {
        showMessage("검색어를 입력 바랍니다.");
      } else {
        isSearch.value = true;
        if (search.keyword.length > 0) {
          activeIdx.value = -2;
        }
        sslLoading.value = true;
        tmpKeyword.value = keyword;
        paging.pageNo = 1;
        sslitems.value = [];
        isSearchEnd.value = false;

        store.dispatch(`ssl/${ACT_GET_SOC_BOARD_LIST}`,
            {lrnTrgtGrpMstSns: lrnTrgtGrpMstSns.value.join(','), pageSize: paging.pageSize, keyword: keyword}).then(res => {
          if (lengthCheck(res)) {
            sslitems.value = getItems(res).map(x => ({
              ...x,
              date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
              day: timestampToDateFormat(x.pblcnDt, 'dd'),
              distCrseCd: getDistCrseCd(x.distCrseSn)
            }));
          } else {
            sslitems.value = [];
          }
          sslLoading.value = false;
        });
      }
    }

    const swipeLoading = ref(false);

    const addSSLItems = async () => {
      if (isSearch.value) {
        swipeLoading.value = true;
        let keyword = '';
        keyword = search.keyword;
        paging.pageNo++;
        await store.dispatch(`ssl/${ACT_GET_SOC_BOARD_LIST}`,
            {lrnTrgtGrpMstSns: lrnTrgtGrpMstSns.value.join(','), pageSize: paging.pageSize, pageNo: paging.pageNo, keyword: keyword}).then(res => {
          if (lengthCheck(res)) {
            sslitems.value = sslitems.value.concat(getItems(res).map(x => ({
              ...x,
              date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
              day: timestampToDateFormat(x.pblcnDt, 'dd'),
              distCrseCd: getDistCrseCd(x.distCrseSn)
            })));
          }
          sslLoading.value = false;
          swipeLoading.value = false;
        });
      } else {
        // 추후 추가 예정
        return ;
        // await store.dispatch(`ssl/${ACT_GET_SOC_EXCELLENT_BOARD_LIST}`).then(res => {
        //   if (lengthCheck(res)) {
        //     excellentItems.value = getItems(res).map(x => ({
        //       ...x,
        //       date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
        //       day: timestampToDateFormat(x.pblcnDt, 'dd'),
        //       distCrseCd: getDistCrseCd(x.distCrseSn)
        //     }));
        //   }
        // });
      }

    }


    const getsslCardList = async (sslCardFilter) => {
      sslLoading.value = true;
      if (sslCardFilter.value === 'excellent') {
        // if (!stringCheck(excellentItems.value)) {
          await store.dispatch(`ssl/${ACT_GET_SOC_EXCELLENT_BOARD_LIST}`).then(res => {
            if(lengthCheck(res)) {
              // console.log("res.value");
              // console.log(getItems(res));
              excellentItems.value = getItems(res).map(x => ({
                ...x,
                date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
                day: timestampToDateFormat(x.pblcnDt, 'dd'),
                distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
              }));
              // console.log("sslitems.value");
              // console.log(sslitems.value);
            }
          });
        // }
        sslitems.value = excellentItems.value;
      } else if (sslCardFilter.value === 'recommend') {
        await store.dispatch(`ssl/${ACT_GET_SOC_RECPOP_BOARD_LIST}`, {seqNm: '0'}).then(res => {
          if (lengthCheck(res)) {
            // console.log("res.value");
            // console.log(getItems(res));
            recommendItems.value = getItems(res).map(x => ({
              ...x,
              date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
              day: timestampToDateFormat(x.pblcnDt, 'dd'),
              distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
            }));
            // console.log("sslitems.value");
            // console.log(sslitems.value);
          }
        });

        if (recommendItems.value == null || recommendItems.value.length == 0) {
          sslCardFilter.value = 'excellent';
        } else {
          sslitems.value = recommendItems.value;
        }
      }
      sslLoading.value = false;
    };
    getsslCardList(sslCardFilter);
    const subscribeCnt = ref(0);
    const getsslSubscribeCardList =()=>{

      sslSubscLoading.value = true;
      if (!stringCheck(subscribeItems.value)) {
        store.dispatch(`ssl/${ACT_GET_SOC_SUBSCRIBE_BOARD_LIST}`).then(res => {
          if(lengthCheck(res)) {
            // console.log("res.value");
            // console.log(getItems(res));
            subscribeItems.value = getItems(res).map(x => ({
              ...x,
              date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
              day: timestampToDateFormat(x.pblcnDt, 'dd'),
              distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
            }));
            // console.log("subscribe.value");
            // console.log(sslitems.value);
            subscribeCnt.value = subscribeItems.value.length;
          }else{
            subscribeCnt.value = 0;
            getBestSubscribesNoteList();
          }
        });
      }
      sslSubscLoading.value = false;
    }
    getsslSubscribeCardList();

    const getBestSubscribesNoteList=()=>{
      sslSubscLoading.value = true;
      store.dispatch(`ssl/${ACT_GET_BEST_SUBSCRIBES_NOTE_LIST}`).then(res => {
        if(lengthCheck(res)) {
          // console.log("res.value");
          // console.log(getItems(res));
          subscribeItems.value = getItems(res).map(x => ({
            ...x,
            date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
            day: timestampToDateFormat(x.pblcnDt, 'dd'),
            distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
          }));
          // console.log("subscribe.value");
          // console.log(sslitems.value);
        }
      });
      sslSubscLoading.value = false;
    }


    watch(() => sslCardFilter.value, () => {
      getsslCardList(sslCardFilter.value);
    });

    // watch(() => store.state.ssl.calendar, () => {
    //   activityInfoItems.value[1].activityInfoItem = Array.from(new Set(store.state.ssl.subscribe.target.map(x => x.lrnerId))).length;
    //   activityInfoItems.value[2].activityInfoItem = Array.from(new Set(store.state.ssl.subscribe.my.map(x => x.trgtLrnerId))).length;
    // });
    //
    // watch(() => store.state.ssl.calendar, () => {
    //   activityInfoItems.value[3].activityInfoItem = Array.from(new Set(store.state.ssl.calendar.myAttends.map(x => x.datePart.ymd))).length;
    // });

    // watch(() => store.state.ssl.myBadges, () => {
    //   activityInfoItems.value[4].activityInfoItem = store.state.ssl.myBadges.length;
    // });

    getHotTipList('hottip-my');

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      if (pageNo) {
        pushQuery({...search, pageNo});
      } else {
        pushQuery({...search});
      }
    };

    const renderTitle = computed(() => {
      return view.title;
    });
    const subscribeNotiPopupModal = ref(true);
    const eventStatusPopup = ref(true);

    const checkEventFlag = () => {
      console.log("checkEventFlag", dateToDateFormat(new Date(), 'yyyyMMdd'), dateToDateFormat(new Date(), 'yyyyMMdd') <= '20231130')
      return dateToDateFormat(new Date(), 'yyyyMMdd') <= '20231130';
    }

    eventStatusPopup.value = checkEventFlag();

    watch(() => tabMenu.value, () => {
      subscribeNotiPopupModal.value = tabMenu.value === 0;
      eventStatusPopup.value = tabMenu.value === 0 ? checkEventFlag() : false;
    });

    const handleScroll = (event) => {
      const scrollContainer = event.target;
      console.log("scrollContainer", scrollContainer);
    };

    const goMyNote=()=>{
      router.push({name: 'SSLTotalMy', params: {distCrseCd:'total', lrnTrgtGrpMstSn:0}}).then(() => {});
    }


    const popups = ref([]);
    // 팝업
    getBanerList({
      banerDivCdDcd: banerDivCdDcds.popup,
      banerExpsrLocCdDcd: banerExpsrLocCdDcds.hrdCloud,
      pageNo: 1,
      pageSize: 10
    }, popups);

    watch(() => sslCardFilter.value, () => {
      getsslCardList(sslCardFilter);
    });

    return {
      handleScroll,
      isSearching,
      isLoading,
      view,
      session,
      search,
      tabMenu,
      toggles,
      paging,
      categories,
      lochref,
      showSubMenu,
      showRoute,
      categoriesPaging,
      pagingFunc,
      sortTyCdDcd,
      renderTitle,
      popups,
      subscribeNotiPopupModal,
      eventStatusPopup,
      roadmap,
      activeIdx,
      getTodayDate,
      isKbBank,
      sslList,
      sslLoading,
      sslitems,
      interestInfoItems,
      interestInfoNms,
      showInterestInfoModal,
      topicSelected,
      sslCardFilter,
      moveSSL,
      searchKeyword,
      hottipLoading,
      hotTipMyPreviewList,
      moveToBoardDtl,
      isSearch,
      attendanceCnt,
      attendanceModal,
      tmpKeyword,
      selectedMenu,
      // activityInfoItems,
      goMyNote,
      subscribeItems,
      excellentItems,
      resetSearch,
      addSSLItems,
      swipeLoading,

      subscribeCnt,
      chNm
    }
  }
};
</script>