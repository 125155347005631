import {
  computed,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRef,
  toRefs,
  watch,
} from 'vue';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import {
  cttTyCdDcds,
  getCateNm, getSSLStudioCateList,
  getTop1CategoryByLevel,
  servTyCdDcds,
} from '@/assets/js/modules/hrd/hrd-common';
import {
  convertToStorageBytes,
  getCheckItems,
  getHeader,
  getImg, getItem, getItems,
  initParams,
  isSuccessFailAlert, lengthCheck,
  nullEmpty,
  setParams,
  stringCheck,
} from '@/assets/js/util';
import {
  getCttMngNo,
  getCttTrfmStt,
  getFileMngNo,
  getSSLStudioList,
  insertCttMst,
  updateCttMst,
  uploadAtch,
  uploadContents,
} from '@/assets/js/modules/hrd/hrd-history-common';
import {splitUpload} from '@/assets/js/modules/common/split_upload.min';
import {
  ACT_DELETE_CTT_MST,
  ACT_UPLOAD_CMS_THUMBNAIL,
} from '@/store/modules/contents/contents';
import {closeToggles} from '@/assets/js/ui.init';
import ClassicEditor from '@/assets/js/editor/custom-ckeditor';
import {kbtubeEditorConfig} from "@/assets/js/editor/editor-config";
import {
  ACT_GET_MY_SSL_LST,
  ACT_GET_SOC_PAGE_LIST,
  ACT_INSERT_SOC_BOARD,
  ACT_UPDATE_SOC_BOARD
} from "@/store/modules/ssl/ssl-index";
import {
  currentYear,
  getCrseSnToDistCrseCd,
  getRouteLrnTrgtGrp,
  getTimeText,
  sslRoutes,
  getNecessaries
} from "@/assets/js/modules/ssl/ssl-common";
import {getThumbImg} from "@/assets/js/modules/baner/common-baner";
import {ACT_GET_HRDCLOUD_DETAIL} from "@/store/modules/prep/prep";
import {ACT_GET_POST_DTL} from "@/store/modules/board/board-mst";
import {useRoute} from "vue-router";

export const checkCttStt = (stt) => {
  const unDeletableStt = ['00','81'];
  return stringCheck(stt) && !(unDeletableStt.includes(stt));
};

export const sslStudioUploadSetup = (props, {emit}) => {
  const isMobile = computed(() => store.state.auth.isMobile);
  const isLoading = ref(false);
  const store = useStore();
  const session = computed(() => store.state.auth.session);
  const {showMessage, showLoading, hideLoading, showConfirm} = useAlert();
  const {modelValue, cttMstSn} = toRefs(props);
  // PC용 Preview Modal, Mobile은 vue에 있음
  const pcPreviewModal = ref(false);
  const items = ref([])
  const cates = ref([]);
  const uploadEvent = toRef(props, 'uploadEvent');

  const lrnTrgtGrp = ref({});
  const fieldNm = ref('');

  const routeFrom = computed(() => store.state.ssl.routeFrom);
  const route = useRoute();
  const distCrseCd = computed(() => route.params.distCrseCd);
  console.log("routeFrom = ", routeFrom);

  const cttMstParam = reactive({
    cttMngNo: null,
    cttTitle: '',
    thumb: '',
    fileNm: '',
    etnUrl: '',
    itnUrl: '',
    cttSz: 0,
    cttFileSz: 0,
    stt: '',
    buketNm: '',
    category2: 0, category3: 0, category4: 0, category5: 0,
    categoryNm2: '', categoryNm3: '', categoryNm4: '', categoryNm5: '',
    atrzOpn: '',
    mlgDesc:'',
    trfmSttCdDcd:'',
    tag: '',
    cttCn: '',
    cttTyCdDcd: '',
    atchFileDomain: null, // 빈 객체 있으면 INSERT 해서 null로 준다
    socLrnPostSn: '',
    lrnTrgtGrpMstSn: '',
    refPost: '',
    refTyNm: '',
    refUrl: '',
    socLrnPgSn: ''
  });

  const isDev = (process.env.NODE_ENV === 'development')

  //2057 : 운영, 1566: 개발
  const cttMstStatic = {
    category1: isDev? 1566: 2057,
    // cttTyCdDcd: cttTyCdDcds.CTT_TY_CD_PDF,
    servTyCdDcd: servTyCdDcds.SERV_TY_CD_SSLSTUDIO,
    // bgngYmd: getTodayDate('yyyy-mm-dd'),
    // endYmd: dateToDateFormat(new Date(now.setFullYear(now.getFullYear() + 2)), 'yyyy-MM-dd')
  };

  const atchFileParam = reactive({
    atchFileSn: 0,
    fileMngNo: null,
    fileNm: '',
    orgFileNm: '',
    fileSz: 0,
    buketNm: '',
    etnUrl: '',
    itnUrl: '',
    fileTyCdDcd: '', // 미적용
    fileTyNm: '', // 미적용
  });

  const catesLvl2 = computed(() => cates.value.filter(x => x.upHrdCloudCateSn === cttMstStatic.category1 && x.lvl === 2)),
        catesLvl3 = computed(() => cates.value.filter(x => x.upHrdCloudCateSn == cttMstParam.category2 && x.lvl == 3)),
        catesLvl4 = computed(() => cates.value.filter(x => x.upHrdCloudCateSn == cttMstParam.category3 && x.lvl == 4)),
        catesLvl5 = computed(() => cates.value.filter(x => x.upHrdCloudCateSn == cttMstParam.category4 && x.lvl == 5));

  const cttFile = ref({file: []}), atchFile = ref({file: []}), thumbFile = ref({file: []});

  const cttFileInfo = computed(() => {
    if (cttFile.value.file && cttFile.value.file[0]) {
      return cttFile.value.file[0];
    } else if (stringCheck(cttMstParam.fileNm)) {
      return {
        name: cttMstParam.fileNm,
        size: cttMstParam.cttFileSz
      }
    } else {
      return null;
    }
  });

  const atchFileInfo = computed(() => {
    if (atchFile.value.file && atchFile.value.file[0]) {
      return atchFile.value.file[0];
    } else if (stringCheck(atchFileParam.orgFileNm)) {
      return {
        name: atchFileParam.orgFileNm,
        size: atchFileParam.fileSz
      }
    } else {
      return null;
    }
  });

  const toggles = reactive({
    category2: false,
    category3: false,
    category4: false,
    category5: false,
  });

  const thumbSrc = ref(null);
  const hashTag = ref('');
  const hashTags = ref([]);
  const isTranscoding = ref(false);
  const transcodingInterval = ref(null);

  const pageEditor = ref(null);
  let $_instance = null;

  const distOptions =ref([]);
  const dccToggle = ref(false); //분야selectBox Toggle
  const getOptions= async ()=>{ //년도별 my입과 과정
    // console.log('year : ' + filters.year);
    distOptions.value = [];

    await store.dispatch(`ssl/${ACT_GET_MY_SSL_LST}`, currentYear).then(res => {
      if (lengthCheck(res)) {
        distOptions.value = getItems(res);
      }
      distOptions.value = getItems(res).map(x=>({...x, distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)}));
      selOpt.lrnTrgtGrpMstSn = '';

    }).catch(e => {
      console.error(e);
    });
  }

  const selDistOption =()=>{
    let crseMstSn = distOptions.value.filter(x => x.lrnTrgtGrpMstSn === selOpt.lrnTrgtGrpMstSn).map(y => y.crseMstSn);
    selOpt.distCrseCd = getCrseSnToDistCrseCd(crseMstSn);
    // let tmpTags = hashTags.value;
    hashTags.value = [];
    hashTag.value = '';
    lrnTrgtGrp.value = getRouteLrnTrgtGrp(sslRoutes[selOpt.distCrseCd].group[0].lrnTrgtGrpMstSn);

    const myGroup = computed(() => {return store.state.ssl.group.filter(x=>x.lrnTrgtGrpMstSn == selOpt.lrnTrgtGrpMstSn)[0]});

    console.log("myGroup = ", myGroup);
    console.log("selOpt = ", selOpt);
    console.log("store.state.ssl.group = ", store.state.ssl.group);

    if (selOpt.distCrseCd.indexOf("self") >= 0) {
      hashTags.value.push(lrnTrgtGrp.value.lrnTrgtGrpNm);
    } else if (selOpt.distCrseCd === 'group'){
      hashTags.value.push('그룹러닝');
      if (hashTags.value.toString().indexOf(myGroup.value.distCrseLrnFldNm) < 0) {
        hashTags.value.push(myGroup.value.distCrseLrnFldNm);
      }
    }

  }

  const selOpt = reactive({
    distCrseCd:'',
    lrnTrgtGrpMstSn:'',
  });


  const getCategories = () => {
    getSSLStudioCateList().then(res => {
      cates.value = getCheckItems(res);
    });
  };

  getCategories();

  const setPlaceholder = (idx) => {
    if (idx > 1) return '하위 카테고리 선택';
    else return '카테고리 선택';
  };

  const splitKeys = () => {
    return {
      manageKey: cttMstParam.cttMngNo,
      userSn: session.value.lrnerId
    }
  };

  const closeModal = () => {
    initParams(cttMstParam);
    modelValue.value.toggle();
  };

  const TRFM_ST_CD_BEFORE = '2025001';

  const uploadCttFile = async () => {
    // 파일이 있을때만 업로드 없을수도 있음
    if (cttFile.value.file && cttFile.value.file[0]) {
      // 없을때, 즉 완전 신규일때만 최초 생성
      if (cttMstParam.cttMngNo === null) {
        let cttMngRes = await getCttMngNo();
        cttMstParam.cttMngNo = cttMngRes.cttMngNo;
      }

      if (cttMstParam.cttMngNo != null) {
        if (cttFile.value.file[0].size > 1024 * 1024 * 100) {
          let blobRes = await splitUpload('/v1/app/contents/split', getHeader(), cttFile.value.file[0], splitKeys(), percent => {
            console.log('blob upload : ',percent);
          });
          if (blobRes && blobRes.code === 200) {
            cttMstParam.cttFileSz = cttFile.value.file[0].size;
            cttMstParam.fileNm = blobRes.data.fileName;
            cttMstParam.etnUrl = blobRes.data.uploadKey;
            cttMstParam.itnUrl = blobRes.data.uploadKey;
            cttMstParam.buketNm = blobRes.data.buketNm;
          }
        } else {
          let upRes = await uploadContents({
            params: {cttMngNo: cttMstParam.cttMngNo, contentsType: cttMstParam.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_VOD ? 'vod' : 'pdf', cttTyCdDcd: cttMstParam.cttTyCdDcd},
            files: cttFile.value.file
          });
          if (upRes && upRes.data && upRes.data.cttMstDomain) {
            cttMstParam.cttFileSz = upRes.data.cttMstDomain.cttFileSz;
            cttMstParam.fileNm = upRes.data.cttMstDomain.fileNm;
            cttMstParam.etnUrl = upRes.data.cttMstDomain.etnUrl;
            cttMstParam.itnUrl = upRes.data.cttMstDomain.itnUrl;
            cttMstParam.buketNm = upRes.data.cttMstDomain.buketNm;
          }
        }
        if (cttFileInfo.value.type === 'video/mp4') {
          cttMstParam.cttTyCdDcd = cttTyCdDcds.CTT_TY_CD_VOD
        } else {
          cttMstParam.cttTyCdDcd = cttTyCdDcds.CTT_TY_CD_PDF
        }
        cttMstParam.stt = '';
        cttMstParam.trfmSttCdDcd = TRFM_ST_CD_BEFORE;
      }
    }
  };

  const uploadAtchFile = async () => {
    if (atchFile.value.file && atchFile.value.file[0]) {
      if (atchFileParam.fileMngNo === null) {
        let fileMngRes = await getFileMngNo();
        atchFileParam.fileMngNo = fileMngRes.fileMngNo;
      }

      if (atchFileParam.fileMngNo != null) {
        let atchRes = await uploadAtch({
          params: { atchFileSn: atchFileParam.atchFileSn, fileMngNo: atchFileParam.fileMngNo },
          files: atchFile.value.file
        });
        if (atchRes && atchRes.data && atchRes.data.atchFileDomain) {
          cttMstParam.atchFileDomain = atchRes.data.atchFileDomain;
        }
      }
    }
  };

  const uploadThumbFile = async () => {
    if (thumbFile.value != null && thumbFile.value.file != null && thumbFile.value.file.length > 0) {
      // 없을때, 즉 완전 신규일때만 최초 생성
      if (cttMstParam.cttMngNo === null) {
        let cttMngRes = await getCttMngNo();
        cttMstParam.cttMngNo = cttMngRes.cttMngNo;
      }

      if (cttMstParam.cttMngNo != null) {
        const thumbRes = await store.dispatch(`contents/${ACT_UPLOAD_CMS_THUMBNAIL}`,{cttMngNo: cttMstParam.cttMngNo, fileList: [thumbFile.value.file[0]]});
        if(thumbRes.url) {
          // 썸네일이 등록되면 file 을 삭제
          cttMstParam.thumb = thumbRes.url;
          thumbFile.value = {file:[]};
        }
      }
    }
  };

  // type - 저장: 0, 제출: 1
  const submitKbTube = async (type) => {
    if (isMobile.value) emit('update:uploadEvent',null);
    // 제출일때만 VALIDATION 체크
    if (!stringCheck(cttMstParam.cttTitle) || cttMstParam.cttTitle === '임시저장 콘텐츠') {
      showMessage("제목을 입력해주세요.");
      return;
    } else if (selOpt.distCrseCd === '') {
      showMessage("분야를 선택해주세요.");
      return;
    } else if (!stringCheck(hashTags.value)) {
      showMessage("해시태그를 입력해주세요.");
      return;
    } else if (!stringCheck(cttFile.value.file)) {
      if(!stringCheck(cttMstParam.fileNm)) {
        showMessage("파일을 업로드해주세요.");
        return;
      }
    }

    if (cttFileInfo.value.type === 'application/pdf' && cttFile.value.file[0].size >= 1024 * 1024 * 20) {
      showMessage("PDF는 최대 20MB 까지 등록 가능합니다.");
      return;
    }

    isLoading.value = true;
    showLoading();

    await uploadCttFile();
    await uploadAtchFile();
    await uploadThumbFile();

    cttMstParam.cttTitle = (type === 0 && !cttMstParam.cttTitle) ? '임시저장 콘텐츠' : cttMstParam.cttTitle;
    // HOT Tube만
    cttMstParam.category2 = isDev? 1566: 2057;
    cttMstParam.mlgDesc = '';
    cttMstParam.stt = type > 0 ? '81' : '01'; // 저장일때는 01 제출일때는 81
    cttMstParam.tag = stringCheck(hashTags.value) ? hashTags.value.join(',') : null;
    if($_instance) cttMstParam.cttCn = $_instance.getData();
    nullEmpty(cttMstParam); // 빈값 NULL 넣어주기

    let transactionResult;
    const payload = {
      ...cttMstParam,
      ...cttMstStatic
    }
    if (cttMstSn.value > 0) {
      transactionResult = await updateCttMst({cttMstSn: cttMstSn.value, params: payload});
    } else {
      transactionResult = await insertCttMst(payload);
    }

    isLoading.value = false;
    hideLoading();

    if (isSuccessFailAlert(transactionResult)) {

      console.log("transactionResult = ", transactionResult);
      console.log("transactionResult = ", transactionResult.cttMstSn);
      const socPages = ref([]);

      socPages.value.push({
        pgOrd: 1,
        socLrnPgSn: cttMstParam.socLrnPgSn > 0 ? cttMstParam.socLrnPgSn : 0,
        title: cttMstParam.cttTitle,
        cn: cttMstParam.cttCn,
      });

      const socBoard = reactive({
        socLrnPostSn: 0,
        title: cttMstParam.cttTitle,
        thumb: cttMstParam.thumb ? (isDev ? 'https://devlxpcms.kbstar.com/' + cttMstParam.thumb : 'https://lxpcms.kbstar.com/' + cttMstParam.thumb) : '',
        hashTag: cttMstParam.tag,
        refPost: cttMstParam.refPost,
        refTyCdDcd: '',
        refTyNm: '',
        refUrl: cttMstParam.refUrl,
        refLrnObjDtlDistSn: transactionResult.cttMstSn
      });

      if (selOpt.distCrseCd === 'group') {
        socBoard.refTyNm = selOpt.lrnTrgtGrpMstSn;
        socBoard.refTyCdDcd = '2113005';
      }

      const params = {
        ...socBoard,
        lrnTrgtGrpMstSn: lrnTrgtGrp.value.lrnTrgtGrpMstSn,
        lrnTrgtGrpNm: lrnTrgtGrp.value.lrnTrgtGrpNm,
        // refTyNm: distCrseCd.value === 'group' ? myGroup.value.crseNm : null,
        stt: '81',
        socPages: socPages.value
      };

      // const res = await store.dispatch(`ssl/${ACT_INSERT_SOC_BOARD}`, params);

      const res = await (cttMstSn.value > 0 ?
          store.dispatch(`ssl/${ACT_UPDATE_SOC_BOARD}`, {socLrnPostSn: cttMstParam.socLrnPostSn, params}) :
          store.dispatch(`ssl/${ACT_INSERT_SOC_BOARD}`, params));

      console.log("res!!!!!! = ", res);
      console.log("cttMstSn.value = ", cttMstSn.value);
      console.log("payload = ", payload);
      showMessage(type > 0 ? `제출되었습니다.<br/>관리자의 승인 후 등록이 완료됩니다.` : '저장되었습니다.');
      closeModal();
    }
  };

  // for PC ONLY
  const getToggle = (idx) => {
    if (idx === 0) return toggles.category2;
    if (idx === 1) return toggles.category3;
    if (idx === 2) return toggles.category4;
    if (idx === 3) return toggles.category5;
  };

  // for PC ONLY
  const openToggle = (idx) => {
    if (idx === 0) {
      toggles.category2 = !toggles.category2;
      closeToggles(toggles, 'category2');
    } else if (idx === 1) {
      toggles.category3 = !toggles.category3;
      closeToggles(toggles, 'category3');
    } else if (idx === 2) {
      toggles.category4 = !toggles.category4;
      closeToggles(toggles, 'category4');
    } else if (idx === 3) {
      toggles.category5 = !toggles.category5;
      closeToggles(toggles, 'category5');
    }
  };

  const selectCate = (lvl, idx) => {
    if (lvl === 2) {
      if (isMobile.value) {
        cttMstParam.categoryNm2 = getCateNm(cttMstParam.category2);
      } else {
        cttMstParam.category2 = catesLvl2.value[idx].hrdCloudCateSn;
        cttMstParam.categoryNm2 = catesLvl2.value[idx].hrdCloudCateNm;
      }

      cttMstParam.category3 = 0;
      cttMstParam.categoryNm3 = '';
      cttMstParam.category4 = 0;
      cttMstParam.categoryNm4 = '';
      cttMstParam.category5= 0;
      cttMstParam.categoryNm5= '';
    }
    if (lvl === 3) {
      if (isMobile.value) {
        cttMstParam.categoryNm3 = getCateNm(cttMstParam.category3);
      } else {
        cttMstParam.category3 = catesLvl3.value[idx].hrdCloudCateSn;
        cttMstParam.categoryNm3 = catesLvl3.value[idx].hrdCloudCateNm;
      }

      cttMstParam.category4 = 0;
      cttMstParam.categoryNm4 = '';
      cttMstParam.category5= 0;
      cttMstParam.categoryNm5= '';
    }
    if (lvl === 4) {
      if (isMobile.value) {
        cttMstParam.categoryNm4 = getCateNm(cttMstParam.category4);
      } else {
        cttMstParam.category4 = catesLvl4.value[idx].hrdCloudCateSn;
        cttMstParam.categoryNm4 = catesLvl4.value[idx].hrdCloudCateNm;
      }
      cttMstParam.category5= 0;
      cttMstParam.categoryNm5= '';
    }
    if (lvl === 5) {
      if (isMobile.value) {
        cttMstParam.categoryNm5 = getCateNm(cttMstParam.category5);
      } else {
        cttMstParam.category5 = catesLvl5.value[idx].hrdCloudCateSn;
        cttMstParam.categoryNm5 = catesLvl5.value[idx].hrdCloudCateNm;
      }

    }
    closeToggles(toggles);
  };

  const checkCateList = (idx) => {
    if (idx === 1 && catesLvl2.value && catesLvl2.value.length > 0) return true;
    else if (idx === 2 && catesLvl3.value && catesLvl3.value.length > 0) return true;
    else if (idx === 3 && catesLvl4.value && catesLvl4.value.length > 0) return true;
    else if (idx === 4 && catesLvl5.value && catesLvl5.value.length > 0) return true;
  };

  const getParamCate = (idx) => {
    if (idx === 1) return cttMstParam.categoryNm2;
    else if (idx === 2) return cttMstParam.categoryNm3;
    else if (idx === 3) return cttMstParam.categoryNm4;
    else if (idx === 4) return cttMstParam.categoryNm5;
    else return '';
  };

  const removeFile = (type) => {
    if (type === 'ctt') {
      cttFile.value.file = null;
      cttFile.value.removed = null;
      cttMstParam.fileNm = '';
      cttMstParam.etnUrl = '';
      cttMstParam.itnUrl = '';
      cttMstParam.cttSz = 0;
      cttMstParam.cttFileSz = 0;
      cttMstParam.stt = '';
      cttMstParam.stt = '';
      cttMstParam.trfmSttCdDcd = '';
    } else if (type === 'thumb') {
      thumbFile.value.file = null;
      thumbFile.value.removed = null;
      cttMstParam.thumb = '';
      thumbSrc.value=null;
    } else if (type === 'atch'){
      atchFile.value.file = null;
      atchFile.value.binaries = null;
      atchFile.value.removed = null;
      atchFileParam.orgFileNm = '';
      atchFileParam.fileSz = 0;
      atchFileParam.etnUrl = '';
      atchFileParam.itnUrl = '';
      cttMstParam.atchFileDomain.etnUrl = null;
      cttMstParam.atchFileDomain.itnUrl = null;
      cttMstParam.atchFileDomain.orgFileNm = null;
      cttMstParam.atchFileDomain.fileNm = null;
      cttMstParam.atchFileDomain.fileSz = 0;
      cttMstParam.atchFileDomain.fileTyCdDcd = null;
      cttMstParam.atchFileDomain.fileTyNm = null;
    }
  };

  const addHashTag = () => {
    if(hashTags.value && hashTags.value.length > 9){
      showMessage('해시태그는 최대 10개까지 작성 가능합니다.');
      return;
    }

    if (hashTag.value !== '') {
      hashTags.value.push(hashTag.value);
      hashTag.value = '';
    }
  };

  const deleteHashTag = (idx) => {
    hashTags.value.splice(idx, 1);
  };

  const imgReader = new FileReader();
  imgReader.onload = (event) => {
    thumbSrc.value = event.target.result;
  };

  // 임시저장 AND 변환전 상태일때
  // '01' && '2025001'
  const checkTranscodingStatus = async () => {
    let startDt = new Date().getTime();

    const enableBtn = () => {
      if (isMobile.value) modelValue.value.toggleSubmitBtn(false);
      else isTranscoding.value = false;
      clearInterval(transcodingInterval.value);
    };

    const intervalFunc = async () => {
      let endDt = new Date().getTime();
      // 2시간시 경과 에러
      if ((endDt - startDt) >= 1000 * 60 * 60 * 2) {
        enableBtn();
      }
      const trfmRes = await getCttTrfmStt(cttMstSn.value);

      // 임시저장 및 변환전
      if (trfmRes.result > 0) {
        enableBtn();
      } else {
        if (isMobile.value) modelValue.value.toggleSubmitBtn(false);
        else isTranscoding.value = true;
      }
    }

    if (stringCheck(cttMstParam.etnUrl)) {
      if (cttMstParam.stt === '01') {
        await intervalFunc();
        transcodingInterval.value = setInterval(async () => {
          await intervalFunc();
        },5000);
      }
    } else {
      enableBtn();
    }
  };

  const handleDeleteKbTube = () => {
    showConfirm({
      text: '정말 삭제하시겠습니까?<br><p style="color:red;font-weight:bold;">삭제 후 되돌릴 수 없습니다</p>',
      callback: deleteKbTube
    });
  };

  const deleteKbTube = () => {
    showLoading();
    store.dispatch(`contents/${ACT_DELETE_CTT_MST}`, cttMstSn.value).then(res => {
      if (isSuccessFailAlert(res)) {
        showMessage('삭제되었습니다.', closeModal);
      }
    }).catch(e => {
      console.error(e);
    }).finally(hideLoading);
  };

  let tryCount = 0;
  const setPageEditor = () => {
    if(tryCount > 10) return;
    if(pageEditor.value){
      ClassicEditor.create( pageEditor.value, {...kbtubeEditorConfig, placeholder:'내용을 입력하세요'} ).then(editor => {
        $_instance = editor;
        if(cttMstParam.cttCn) $_instance.setData(cttMstParam.cttCn);
      }).catch(error => {
        console.error(error);
      })
    }else{
      tryCount++;
      setPageEditor();
    }
  }

  const relSn = computed(() => route.query.relSn);

  const setRelParams = (item) => {
    // if(distCrseCd.value === 'group'){
    //     socBoard.refPost = [
    //         myGroup.value.crseNm,
    //         item.relNm,
    //         getTimeText(item.relBgngDt, item.relEndDt),
    //         relSn.value
    //     ].join('^');
    // }else
    if(distCrseCd.value === 'knowledge'){
      if(item.relNm) hashTags.value.push(item.relNm.split(' ').join(''));
      cttMstParam.refPost = [item.relNm, relSn.value].join('^');
      // 수정 필요
      cttMstParam.refUrl = item.relEtnUrl;
    }else if(distCrseCd.value === 'hrd'){
      if(item.relCateNm) hashTags.value.push(item.relCateNm.split(' ').join(''));
      cttMstParam.refPost = [getThumbImg(item.relThumb), item.relNm, item.relCateNm, relSn.value].join('^');
      // hrd cloud url 임
      // 수정 필요
      cttMstParam.refUrl = item.relEtnUrl;
    }else if(distCrseCd.value === 'book'){
      if(item.bookNm) hashTags.value.push(item.bookNm.split(' ').join(''));
      cttMstParam.refPost = [item.bookNm, item.bookDesc, item.thumb, relSn.value].join('^');
      cttMstParam.refUrl = item.bookUrl;
    }
    else if(distCrseCd.value === 'live'){
      if(item.relNm) hashTags.value.push(item.relNm.split(' ').join(''));
      cttMstParam.refPost = [item.relNm, relSn.value, getTimeText(item.relBgngDt, item.relEndDt)].join('^');
    }
    else if(routeFrom.value.name === 'HrdCloudContentBridge'){
      const HrdThumb = getThumbImg(item.thumb) ? getThumbImg(item.thumb) : (item.lrnObjTyCd ==="100003" ?  `${process.env.VUE_APP_CLOUDFRONT_URL}/contents/thumbnail/temp/temp_pdf_thumb.jpg` : `${process.env.VUE_APP_CLOUDFRONT_URL}/contents/thumbnail/temp/temp_hrdcloud_thumb.jpg`);
      cttMstParam.refPost = ['hrd', HrdThumb, item.objNm, item.hrdCloudCateNm, item.lrnObjDtlDistSn].join('^');
      cttMstParam.refPost = cttMstParam.refPost.replace(/devlxpcms-ncloud.cdn.ntruss.com/g,'devlxpcms.kbstar.com');
      cttMstParam.refPost = cttMstParam.refPost.replace(/lxpcms-ncloud.cdn.ntruss.com/g,'lxpcms.kbstar.com');
    }
    else if(routeFrom.value.name === 'SSLView' || routeFrom.value.name === 'SSLTotalView'){
      cttMstParam.refPost = ['ssl', item.thumb, item.title, item.lrnerNm.concat(' ').concat(item.deptNm), item.socLrnPostSn].join('^');
    }
    else if(routeFrom.value.name === 'BoardView'){
      if(routeFrom.value.fullPath.indexOf("/board/hottip")>-1){
        cttMstParam.refPost = ['hottip', `${process.env.VUE_APP_LXP_URL}files/images/hottip-thumb.png`, item.postTitle, item.lrnerNm.concat(' ').concat(item.deptNm), item.comPostSn].join('^');
      }
    }


    // console.log("routefrom check", routeFrom.value, item);

    if(!cttMstParam.refUrl){
      cttMstParam.refUrl = routeFrom.value.fullPath;
    }

    console.log("cttMstParam = ", cttMstParam);
  }

  const getRefFromPrevPage = async () => {
    console.log("routeFrom.value.name = ", routeFrom.value.name);
    if(routeFrom.value.name === 'HrdCloudContentBridge'){
      store.dispatch(`prep/${ACT_GET_HRDCLOUD_DETAIL}`, {lrnObjDtlDistSn: routeFrom.value.params.lrnObjDtlDistSn}).then(res => {
        if(lengthCheck(res)){
          setRelParams(getItem(res));
        }
      })
    }else if(routeFrom.value.name === 'SSLView' || routeFrom.value.name === 'SSLTotalView'){
      store.dispatch(`ssl/${ACT_GET_SOC_PAGE_LIST}`, routeFrom.value.params.socLrnPostSn).then(res => {
        if(res.socBoard){
          setRelParams(res.socBoard);
        }
      })
    }else if(routeFrom.value.name === 'BoardView'){

      //boardId 의 mstsn 구하기

      //mstsn 기준으로 post dtl
      store.dispatch(`boardmst/${ACT_GET_POST_DTL}`, routeFrom.value.params).then((res) => {
        if(lengthCheck(res)){
          setRelParams(getItem(res));
        }
      })
    }else{
      console.log("setRelParams(null);");
      setRelParams(null);
    }
  }

  getRefFromPrevPage();

  watch(() => thumbFile.value, () => {
    if (imgReader !== null && thumbFile.value.file && thumbFile.value.file.length > 0) {
      imgReader.readAsDataURL(thumbFile.value.file[0]);
    }
  });

  // FOR MOBILE ONLY
  // 모바일의 경우 모달창의 위치가 달라서 저장 및 제출 처리 WATCH로 처리c
  watch(() => uploadEvent.value, async () => {
    if (isMobile.value) {
      if (uploadEvent.value === 'save') await submitKbTube(0);
      else if (uploadEvent.value === 'submit') await submitKbTube(1);
    }
  });

  onMounted(async() => {
    getNecessaries();
    await getOptions();
    if (cttMstSn.value > 0) {
      showLoading();
      // GETCTTMST :: cttMstSn로 1개만 조회
      getSSLStudioList({cttMstSn: cttMstSn.value}, items, {},async () => {
        items.value.map(x => {
          let cateSns = getTop1CategoryByLevel(x.cloudKeyLst), cateNms = getTop1CategoryByLevel(x.cloudNmLst);
          for (let i=1; i<6; i++) {
            x[`category${i}`] = Number(cateSns[`category${i}`]);
            x[`categoryNm${i}`] = cateNms[`category${i}`];
          }
          if (x.cttSz > 0) cttFileInfo.value;
          thumbSrc.value = stringCheck(x.thumb) ? getImg(x.thumb) : null;
          hashTags.value = stringCheck(x.tag) ? x.tag.split(',') : [];
        });

        let item = items.value[0];

        setParams(cttMstParam, item);

        if (cttMstParam.lrnTrgtGrpMstSn) {
          let crseMstSn;
          if (cttMstParam.refTyNm) {
            crseMstSn = distOptions.value.filter(x => x.lrnTrgtGrpMstSn == Number(cttMstParam.refTyNm)).map(y => y.crseMstSn);
          } else {
            crseMstSn = distOptions.value.filter(x => x.lrnTrgtGrpMstSn == cttMstParam.lrnTrgtGrpMstSn).map(y => y.crseMstSn);
          }
          selOpt.distCrseCd = getCrseSnToDistCrseCd(crseMstSn);
          selOpt.lrnTrgtGrpMstSn = cttMstParam.refTyNm ? Number(cttMstParam.refTyNm) : cttMstParam.lrnTrgtGrpMstSn;

          fieldNm.value = distOptions.value.find(x => x.crseMstSn == crseMstSn).crseNm;
        }

        if (item && item.atchFileDomain) setParams(atchFileParam, item.atchFileDomain);
        if (isMobile.value) {
          props.previewModal.update(item);
          props.modelValue.stt = cttMstParam.stt;
        }
        await setPageEditor();
        await checkTranscodingStatus();
        hideLoading();
      }, () => {hideLoading();});
    }else{
      setPageEditor();
    }
  });

  onBeforeUnmount(() => {
    if (isMobile.value) emit('update:uploadEvent',null);
    initParams(cttMstParam);
    if (transcodingInterval.value > 0) {
      clearInterval(transcodingInterval.value);
    }
    if($_instance){
      $_instance.destroy();
      $_instance = null;
    }
  });

  return {
    isLoading, cttMstParam, toggles, pcPreviewModal,
    cttFile, atchFile, catesLvl2, catesLvl3, catesLvl4, catesLvl5,
    cttFileInfo, atchFileInfo, thumbFile, pageEditor,
    submitKbTube, removeFile, getParamCate, selectCate, getToggle, openToggle, checkCateList,
    convertToStorageBytes, closeToggles, closeModal, setPlaceholder,
    hashTag, hashTags, addHashTag, deleteHashTag, thumbSrc,
    isTranscoding,  checkCttStt, handleDeleteKbTube,
    distOptions, dccToggle, selDistOption, selOpt,fieldNm
  }
};