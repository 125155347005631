<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue.isActive }">
    <div class="popup" id="popup-hrdcloud-kbtube">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">쓸스튜디오(영상/PDF) 등재</h3>
        </header>
        <div class="popup-content" @click="closeToggles(toggles)">
          <article class="popup-section">
            <div class="section-content">
              <div class="kb-form-fields">
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">제목</strong><span class="color-red">*</span>
                    </label>
                  </div>
                  <div class="kb-form-column column-whole">
                    <input type="text" v-model.trim="cttMstParam.cttTitle" class="kb-form-control" placeholder="제목을 입력하세요."/>
                  </div>
                </div>
                <RowItem v-if="fieldNm" title="분야" :data="fieldNm"/>
                <div v-else class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">분야</strong><span class="color-red">*</span>
                    </label>
                  </div>
                  <div class="kb-form-column kb-form-dropdown-group">
                    <SortButtonSelect
                        v-model="selOpt.lrnTrgtGrpMstSn"
                        v-model:toggle="dccToggle"
                        title="분야 선택"
                        :options="distOptions"
                        sequence-key="lrnTrgtGrpMstSn"
                        name-key="crseNm"
                        :is-all="false"
                        :width="300"
                        :textStyle="`font:normal 16px/20px var(--kb-font-KBFGText);`"
                        @selected="selDistOption"
                    />
                  </div>
                </div>

                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">영상/PDF 업로드</strong><span class="color-red">*</span>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <ul class="file-list">
                      <li class="file-item" v-if="cttFileInfo">
                        <span class="file-name">{{ cttFileInfo.name }}</span>
                        <span class="file-size">{{ convertToStorageBytes(cttFileInfo.size) }}</span>
                        <button class="kb-btn-file-delete" @click="removeFile('ctt')"><i class="icon-delete"></i></button>
                      </li>
                      <li v-else>
                        <span class="file-name-placeholder kb-form-text">영상/PDF를 업로드해주세요.</span>
                      </li>
                    </ul>
                  </div>
                  <div class="kb-form-column justify-content-end t2">
                    <div class="kb-form-preview">
                      <button v-if="cttMstParam.etnUrl" class="text preview" @click="pcPreviewModal=true">미리보기</button>
                    </div>
                    <div class="kb-form-file">
                      <FileUpload v-model="cttFile" file-type="sslStudio" />
                      <label for="inpFile" class="kb-btn-link"><span class="text">파일찾기</span></label>
                    </div>
                  </div>
                </div>

                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">썸네일 이미지</strong>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <ul class="file-list">
                      <li v-if="thumbSrc" class="file-item" >
                        <span class="file-name">
                          <article class="hrdcloud-card" style="width: 225px; height: 400px;">
                            <div class="hrdcloud-image" style="color:black; outline:unset !important; text-align:center; height: 400px;">
                              <div class="image" style="width:unset !important;background-color:black; height: 400px;">
                                <img :src="thumbSrc" alt="썸네일 이미지" style="width:unset !important; object-fit:cover; width: 225px; height: 400px;" />
                              </div>
                            </div>
                          </article>
                        </span>
                        <button class="kb-btn-file-delete" @click="removeFile('thumb')"><i class="icon-delete"></i></button>
                      </li>
                      <li v-else><span class="file-name-placeholder kb-form-text">썸네일을 업로드해주세요.(권장사이즈: 450x797)</span></li>
                    </ul>
                  </div>
                  <div class="kb-form-column justify-content-end">
                    <div class="kb-form-file">
                      <FileUpload v-model="thumbFile" file-type="img" />
                      <label for="inpFile" class="kb-btn-link"><span class="text">파일찾기</span></label>
                    </div>
                  </div>
                </div>

                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">설명</strong>
                    </label>
                  </div>
                  <div class="kb-form-column column-whole py-3">
                    <div class="editor-content m-h-300 ms-0">
                      <div ref="pageEditor"></div>
                    </div>
                  </div>
                </div>

                <div class="kb-form-row editor-body-tag" style="margin-top:unset !important;">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">해시태그</strong><span class="color-red">*</span>
                    </label>
                  </div>
                  <div class="kb-form-column tag-list" style="padding: 22px 40px;">
                    <div v-for="(hashTag, idx) in hashTags" class="tag-item is-active" :key="idx">
                      <span class="tag-item-text">{{ hashTag }}</span>
                      <button class="kb-btn-tag" @click="deleteHashTag(idx)">
                        <i class="icon-tag-delete"></i>
                      </button>
                    </div>
                    <div class="tag-item">
                      <input v-model.trim="hashTag" type="text" class="tag-item-input" placeholder="해시태그 입력 후 Enter"
                             @keyup.enter="addHashTag"
                      />
                    </div>
                  </div>
                </div>

                <div v-if="cttMstParam.stt == '78' && cttMstParam.atrzOpn" class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <strong class="kb-form-label-text">사유</strong>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <p class="kb-form-text">{{ cttMstParam.atrzOpn }}</p>
                  </div>
                </div>

              </div>
            </div>
            <div class="section-bottom bottom-notice">
              <p class="text text-icon">1. 제작한 영상/PDF를 <span style="color:#ff8000;line-height:unset !important;">MP4(최대 2GB)/PDF파일(최대 20MB)로 암호화 해제 후 외부망에서 업로드</span> 해주세요.</p>
              <p class="text">2. <span style="color:#ff8000;line-height:unset !important;">
                콘텐츠에 개인정보 및 중요정보가 포함되지 않도록 유의</span>하여 주시고, 개인정보 유출 등으로 인한 책임은 등록자에게 있음을 확인해주시기 바랍니다.
              </p>            </div>
          </article>
        </div>
        <div class="popup-buttons">
          <template v-if="isTranscoding && cttMstParam.cttTyCdDcd !== '2034005'">
            <button class="kb-btn kb-btn-outline kb-btn-lg" disabled><span class="text">인코딩중</span></button>
          </template>
          <template v-else>
            <button v-if="checkCttStt(cttMstParam.stt)" class="kb-btn kb-btn-secondary kb-btn-lg" @click="handleDeleteKbTube"><span class="text">삭제</span></button>
            <button class="kb-btn kb-btn-outline kb-btn-lg" @click="submitKbTube(0)"><span class="text">저장</span></button>
            <button class="kb-btn kb-btn-primary kb-btn-lg" @click="submitKbTube(1)"><span class="text">제출</span></button>
          </template>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <LxpLoading v-if="isLoading" />
  </div>
  <Preview v-if="pcPreviewModal && cttMstParam.etnUrl" v-model="pcPreviewModal" :item="cttMstParam" />
</template>
<script>
import FileUpload from '@/components/history/module/FileUpload';
import Preview from '@/components/history/hrdcloud/Preview';
import LxpLoading from '@/components/common/LxpLoading';
import {sslStudioUploadSetup} from "@/assets/js/modules/sslStudio/ssl-studio-upload-setup";
import SortButtonSelect from "@/components/common/SortButtonSelect.vue";
import RowItem from "@/components/history/module/RowItem.vue";

export default {
  name: 'SSLStudioUpload',
  components: {RowItem, SortButtonSelect, LxpLoading, Preview, FileUpload},
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {
          isActive: false,
          toggle: () => {}
        }
      }
    },
    cttMstSn: {
      type: Number,
      default: 0
    }
  },
  setup: sslStudioUploadSetup
};
</script>