<template>
  <main class="kb-main" id="kb" @click="closeAllToggle">

    <div class="main-content"
         v-if="!shareToggle && !opinionToggle">
      <div class="ssl-body pb-0">
        <SSLMobileSlotHeader v-if="isHeader === 0"
        >
          <template v-slot:left>
            <div class="util util-history-back" @click="goList">
              <a href="javascript:" class="util-actions util-actions-history-back">
                <i class="icon-history-back"></i>
              </a>
            </div>
          </template>

          <template v-slot:right>
            <div v-if="isReady" class="util util-buttons">
              <a v-if="canPut && isInProgress" href="javascript:" class="util-actions util-actions-texticon" @click="putSocBoard">
                <span class="text">{{ isPut ? '담기취소' : '담기' }}</span>
                <i class="icon-board-save"></i>
              </a>
              <a v-if="canShare && isInProgress" href="javascript:" class="util-actions util-actions-texticon" @click="shareToggle = true;">
                <span class="text">공유</span>
                <i class="icon-board-share"></i>
              </a>
              <a v-if="!isMyNote && isInProgress" href="javascript:" class="util-actions util-actions-texticon" @click="opinionToggle=true;">
                <span class="text">수정의견</span>
                <i class="icon-board-opinion"></i>
              </a>
            </div>
          </template>
        </SSLMobileSlotHeader>

        <div v-if="isReady" class="page-container">
          <div class="board-view-container">
            <div class="board-view">
              <div v-if="!socBoard.refCttTyCdDcd" class="board-view-header view-component">
                <img v-if="socBoard.thumb" :src="socBoard.thumb" alt="">
                <div class="blur"></div>
                <div class="page-header-wrap">
                  <div>
                    <h2 class="title">{{ socBoard.title }}</h2>
                    <p class="page-editor">{{ socBoard.lrnerNm }}<span> ・ </span>{{ isGroup ? socBoard.refTyNm : socBoard.deptNm }}</p>
                  </div>
                  <div class="page-info">
                    <span class="text">{{ dateToFormat(socBoard.pblcnDt) }}</span>
                    <span class="text">{{ numberComma(socBoard.inqcnt) }} 조회수</span>
                    <span class="text">{{ numberComma(socBoard.likeCnt) }} 좋아요수</span>
<!--                    <span class="text">{{ numberComma(socBoard.sharedCnt) }}공유</span>-->
                  </div>
                </div>
              </div>
              <div v-else class="board-view-header-v2 view-component">
                <div class="page-header-wrap-v2">
                  <div class="member-avatar" style="padding-bottom: 10px;">
                    <SSLProfileImg :target="socBoard"/>
                  </div>
                  <div>
                    <h2 class="title" style="color: black;">{{ socBoard.title }}</h2>
                    <p class="page-editor" style="color: black;">{{
                        socBoard.lrnerNm
                      }}<span> ・ </span>{{ isGroup ? socBoard.refTyNm : socBoard.deptNm }}</p>
                  </div>
                  <div class="page-info" style="color: black;">
                    <span class="text" style="color: black;">{{ dateToFormat(socBoard.pblcnDt) }}</span>
                    <span class="text" style="color: black;">{{ numberComma(socBoard.inqcnt) }} 조회수</span>
                    <span class="text" style="color: black;">{{ numberComma(socBoard.likeCnt) }} 좋아요수</span>
                    <!--                    <span class="text">{{ numberComma(socBoard.sharedCnt) }}공유</span>-->
                  </div>
                </div>
              </div>

              <SSLVod v-if="hrdItem.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_VOD" v-model:objDetail="hrdItem"/>
              <SSLPdf v-else-if="hrdItem.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_PDF" v-model:objDetail="hrdItem"/>

              <div class="board-view-body view-component"
                   :style="hrdItem.cttTyCdDcd ? {'margin-top': '0px'} : {'margin-top': '32px'}">
                <div class="editor-area">
                  <div class="editor-contents" v-html="currentPage.cn"></div>
                </div>
                <SSLViewRel
                    :hash-tags="hashTags"
                    :ref-ty-cd-dcd="socBoard.refTyCdDcd"
                    :ref-url="socBoard.refUrl"
                    :ref-post="socBoard.refPost"
                />

                <div class="like-container" :style="canEdit ? {marginBottom: 0} : {}">
                  <div class="like">
                    <img src="@/assets/lxp/images/ssl/view/ic_like2.svg" alt="">
                    <button :class="{'is-active': (session.lrnerId !== socBoard.lrnerId && isLike)}" @click="toggleLike">
                      <span class="text-black">좋아요</span>&nbsp;<span class="like-num text-black">{{ socBoard.likeCnt  }}</span>
                    </button>
                  </div>
                  <template v-if="postSns.length > 0">
                    <div class="move">
                      <button @click="goPage('prv')">
                        <span class="text-black">◀</span>
                      </button>
                      <button @click="goPage('nxt')" style="margin-left: 20px">
                        <span class="text-black">▶</span>
                      </button>
                    </div>
                  </template>
                </div>

                <div v-if="canEdit" class="like-container" style="margin-top: 0">
                  <div class="like">
                    <button class="kb-btn-primary is-active" @click="goEdit">
                      <span>수정</span>
                    </button>
                  </div>
                </div>

              </div>


              <div class="divider"></div>

              <div class="board-view-author view-component">
                <div class="author-box">
                  <div class="author-avatar">
                    <SSLProfileImg :target="socBoard" @clickImage="goLearner"/>
                  </div>
                  <div class="author-content">
                    <span class="author-name" style="margin-top: -12px">{{ socBoard.lrnerNm }}</span>
<!--                    <span class="author-text"></span>-->
<!--                    <span class="author-text">{{ isGroup ? socBoard.refTyNm : socBoard.deptNm }}</span>-->
                  </div>
                </div>
                <button v-if="canSubscribe && isInProgress" class="kb-btn-following" :class="{'is-follow': isSubscribe}" @click="toggleSubscribe">
                  <i class="following"></i>
                  <span class="text text-black">{{ isSubscribe ? '구독중' : '구독' }}</span>
                </button>
              </div>

              <div class="divider"></div>
              <SSLMobileComment ref="commentComp" :reg-dt="socBoard.pblcnDt"/>

            </div>
          </div>
        </div>
      </div>
    </div>
    <SSLMobileNoteOpinionModal
        v-if="opinionToggle"
        v-model:toggle="opinionToggle"
        :soc-lrn-post-sn="socBoard.socLrnPostSn"
        :title="socBoard.title"
    />
    <SSLMobileShare
        v-if="shareToggle"
        v-model:toggle="shareToggle"
        :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
        :soc-lrn-post-sn="socBoard.socLrnPostSn"
        :title="socBoard.title"
        @acted="acted"
    />
  </main>
</template>
<script>
import SSLViewRel from '@/components/ssl/view/SSLViewRel';
import SSLMobileComment from '@/components/ssl/view/mobile/SSLMobileComment';
import SSLProfileImg from '@/components/ssl/common/SSLProfileImg';
import {sslViewSetup} from '@/assets/js/modules/ssl/setup/ssl-view-setup';
import SSLMobileSlotHeader from '@/components/ssl/main/mobile/SSLMobileSlotHeader';
import SSLMobileShare from '@/components/ssl/view/mobile/SSLMobileShare';
import SSLMobileNoteOpinionModal from '@/components/ssl/view/mobile/SSLMobileNoteOpinionModal';
import SSLPdf from "@/components/hrdcloud/pages/SSLPdf.vue";
import SSLVod from "@/components/hrdcloud/pages/SSLVod.vue";

export default {
  name: 'SSLMobileView',
  components: {
    SSLVod, SSLPdf,
    SSLMobileShare,
    SSLMobileSlotHeader,
    SSLProfileImg,
    SSLMobileComment,
    SSLViewRel,
    SSLMobileNoteOpinionModal
  },
  emits: ['acted'],
  setup: sslViewSetup
}
</script>
