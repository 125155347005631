<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <div class="popup" id="popup-hrdcloud-kbtube">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">쓸스튜디오</h3>
        </header>
        <SSLStudioList :upload-func="uploadModal" :pending-func="pendingModal"/>
        <SSLStudioUpload v-if="uploadModal.isActive" v-model="uploadModal" :ctt-mst-sn="cttMstSn" />
        <SSLStudioPending v-if="pendingModal.isActive" v-model="pendingModal" :ctt-mst-sn="cttMstSn" />
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="$emit('update:modelValue',false)"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, ref} from 'vue';
import SSLStudioList from '@/components/ssl/studio/SSLStudioList';
import SSLStudioUpload from '@/components/ssl/studio/SSLStudioUpload';
import SSLStudioPending from '@/components/ssl/studio/SSLStudioPending';

export default {
  name: 'SSLStudioModal',
  components: {SSLStudioPending, SSLStudioUpload, SSLStudioList},
  props: {
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],
  setup(){
    const cttMstSn = ref(0);

    const setCttMstSn = (sn) => {
      cttMstSn.value = sn;
    };

    const uploadModal = reactive({
      isActive: false,
      toggle: () => {
        uploadModal.isActive = !uploadModal.isActive;
      },
      update: setCttMstSn
    });

    const pendingModal = reactive({
      isActive: false,
      toggle: () => {
        pendingModal.isActive = !pendingModal.isActive;
      },
      update: setCttMstSn
    });

    return {
      cttMstSn,
      uploadModal, pendingModal
    }
  }
};
</script>