import ApiService from '@/assets/js/api.service';

export const MUT_TOGGLE_CONCENTRATION_MODE = 'toggleConcentrationMode';
export const MUT_SET_HRDCLOUD_CATE_LIST = 'setCategoryList';
export const MUT_SET_MY_HRDCLOUD_BADGE = 'setMyHrdCloudBadges';

export const MUT_SHOW_ADDED = 'showAdded';
export const MUT_CLOSE_ADDED = 'closeAdded';
export const MUT_SHOW_MAIN = 'showMain';
export const MUT_CLOSE_MAIN = 'closeMain';
export const MUT_SHOW_SUBSCRIBED = 'showSubscribed';
export const MUT_CLOSE_SUBSCRIBED = 'closeSubscribed';
export const MUT_SHOW_SHARE = 'showShare';
export const MUT_CLOSE_SHARE = 'closeShare';
export const MUT_SET_YEAR_SHARE = 'setYearShare';

//shortform refcontent
export const MUT_SET_REF_CONTENT = 'mutSetRefContent';

// badge
export const MUT_SHOW_HRD_BADGE = 'showHrdBadge';
export const MUT_CLOSE_HRD_BADGE = 'closeHrdBadge';
export const MUT_SET_MY_HRD_BADGE = 'setMyHrdBadge';
export const MUT_INIT_MY_HRD_BADGE = 'setInitHrdBadge';
// export const ACT_GET_HRD_BADGE_LIST = 'getHrdBadgeList';
export const MUT_SHOW_HRD_LEADER_ADD = 'showHrdLeaderAdd';
export const MUT_CLOSE_HRD_LEADER_ADD = 'closeHrdLeaderAdd';
export const MUT_ENABLE_MY_ADD_CHANGED = 'enableMyAddChanged';
export const MUT_DISABLE_MY_ADD_CHANGED = 'disableMyAddChanged';
export const MUT_TOGGLE_HRD_ROADMAP = 'toggleHrdRoadmap';

// ACTIONS
export const ACT_GET_HRDCLOUD_CATE_LIST = 'actGetHrdcloudCateList';
export const ACT_GET_HRDCLOUD_UV_LIST = 'actGetHrdCloudUvList';
export const ACT_GET_HRDCLOUD_CATE_TREE_LIST = 'actGetHrdcloudCateTreeList'
export const ACT_GET_HRDCLOUD_CATE_SEARCH_LIST = 'actGetHrdcloudCateSearchList'
export const ACT_GET_HRDCLOUD_MAIN_CATE_LIST = 'actGetHrdcloudMainCateList';
export const ACT_GET_HRDCLOUD_SSLSTUDIO_CATE_LIST = 'actGetHrdcloudSSLStudioCateList';
export const ACT_GET_HRDCLOUD_ROADMAP_CTT = 'getHrdcloudRoadmapCtt';
export const ACT_INSERT_HRDCLOUD_ROADMAP_CTT = 'insertHrdcloudRoadmapCtt';
export const ACT_GET_HRDCLOUD_ROADMAP_LIST = 'getHrdcloudRoadmapList';
export const ACT_GET_HRDCLOUD_ROADMAP_CTT_LIST = 'getHrdcloudRoadmapCttList';
export const ACT_INSERT_HRDCLOUD_ROADMAP = 'insertHrdcloudRoadmap';
export const ACT_UPDATE_HRDCLOUD_ROADMAP = 'updateHrdcloudRoadmap';
export const ACT_UPDATE_HRDCLOUD_ROADMAP_CTT = 'updateHrdcloudRoadmapCtt';
export const ACT_INSERT_HRDCLOUD_LVL_FEEDBACK = 'insertHrdcloudLevelFeedback';
// 학습 로드맵 관련
export const ACT_GET_HRDCLOUD_LEARNING_ROADMAP_LIST = 'getHrdcloudLearningRoadmapList';
export const ACT_GET_HRDCLOUD_LEARNING_ROADMAP_MENU = 'getHrdcloudLearningRoadmapMenu';
export const ACT_GET_HRDCLOUD_LEARNING_ROADMAP_CARD_LIST = 'getHrdcloudLearningRoadmapCardList';
export const ACT_GET_HRDCLOUD_LEARNING_ROADMAP_CTT_LIST = 'getHrdcloudLearningRoadmapCttList';
export const ACT_GET_HRDCLOUD_LEARNING_ROADMAP_SNS  = 'getHrdcloudLearningRoadmapSns';

// 자막(script> 관리
export const ACT_GET_HRDCLOUD_CTT_SUBTITLES = 'getHrdcloudCttSubtitles';

// 임시 추후 삭제
export const ACT_GET_HRDCLOUD_LIST = 'actGetHrdcloudList';

const state = {
  mode: '',
  hrdcloudCates: [],
  added: {
    show: false,
    year: '',
    type: 'hrdcloud'
  },
  leaderAdd: {
    show: false,
    isPut: false,
    isLeaderPut: false,
    distCrseSn: 0,
    lrnObjDtlDistSn: 0,
    objNm: '',
    objSn: '',
    updateMyAdd: false,
    cttTyCdDcd: '',
  },
  subscribed: {
    show: false,
    year: '',
  },
  share: {
    show: false,
    year: '',
  },
  main: {
    show: true,
    showRoadmap: false,
  },
  badgeShow: false,
  badges: [],
  myBadges: [],
  refLrnObjDtlJson:{
    objJsonStr:'',
    currTime:0
  },

}

const mutations = {
  [MUT_TOGGLE_CONCENTRATION_MODE](state, {mode}) {
    state.mode = mode;
  },
  [MUT_SET_HRDCLOUD_CATE_LIST](state, categories) {
    state.hrdcloudCates = categories;
  },
  [MUT_SET_MY_HRDCLOUD_BADGE](state, badges) {
    state.myBadges = badges;
  },
  [MUT_SHOW_ADDED](state, {year, type}) {
    state.added.year = year;
    state.added.show = true;
    state.added.type = type || 'hrdcloud';
  },
  [MUT_CLOSE_ADDED](state) {
    state.added.year = '';
    state.added.show = false;
  },
  [MUT_SHOW_SUBSCRIBED](state, year) {
    state.subscribed.year = year;
    state.subscribed.show = true;
  },
  [MUT_CLOSE_SUBSCRIBED](state) {
    state.subscribed.year = '';
    state.subscribed.show = false;
  },
  [MUT_SHOW_SHARE](state, year) {
    state.share.year = year;
    state.share.show = true;
  },
  [MUT_CLOSE_SHARE](state) {
    state.share.year = '';
    state.share.show = false;
  },

  [MUT_SET_YEAR_SHARE](state, year) {
    state.share.year = year;
  },

  [MUT_SHOW_MAIN](state) {
    state.main.show = true;
  },
  [MUT_CLOSE_MAIN](state) {
    state.main.show = false;
  },
  // badge
  [MUT_SHOW_HRD_BADGE](state, badges){
    state.badges = badges;
    state.badgeShow = true;
  },
  [MUT_CLOSE_HRD_BADGE](state){
    state.badgeShow = false;
  },
  [MUT_SET_MY_HRD_BADGE](state, badges){
    state.myBadges = badges;
  },
  [MUT_INIT_MY_HRD_BADGE](state){
    state.myBadges = [];
  },
  [MUT_SHOW_HRD_LEADER_ADD](state, trgt){
    state.leaderAdd.show = true;
    state.leaderAdd.distCrseSn = trgt.distCrseSn || 0;
    state.leaderAdd.lrnObjDtlDistSn = trgt.lrnObjDtlDistSn || 0;
    state.leaderAdd.objNm = trgt.objNm || '';
    state.leaderAdd.objSn = trgt.objSn || '';
    state.leaderAdd.isPut = trgt.put || false;
    state.leaderAdd.isLeaderPut = trgt.leaderPut || false;
    state.leaderAdd.cttTyCdDcd = trgt.cttTyCdDcd;
  },
  [MUT_CLOSE_HRD_LEADER_ADD](state, isPut){
    state.leaderAdd.isPut = isPut || false;
    state.leaderAdd.show = false;
  },
  [MUT_ENABLE_MY_ADD_CHANGED](state){
    state.leaderAdd.updateMyAdd = true;
  },
  [MUT_DISABLE_MY_ADD_CHANGED](state){
    state.leaderAdd.updateMyAdd = false;
  },
  [MUT_TOGGLE_HRD_ROADMAP](state, showRoadmap){
    state.main.showRoadmap = showRoadmap;
  },
  [MUT_SET_REF_CONTENT](state, param){
    state.refLrnObjDtlJson = param;
    console.log("mut check", param, state.refLrnObjDtlJson);
  }
}

const actions = {
  [ACT_GET_HRDCLOUD_CATE_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/category/hrdcloud', params).then(response => resolve(response))
    });
  },
  [ACT_GET_HRDCLOUD_CATE_TREE_LIST] (context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/category/hrdcloud/tree', params).then(response => resolve(response))
    });
  },
  [ACT_GET_HRDCLOUD_CATE_SEARCH_LIST] (context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/category/hrdcloud/search', params).then(response => resolve(response))
    });
  },
  [ACT_GET_HRDCLOUD_MAIN_CATE_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/category/hrdcloud/level', params).then(response => resolve(response))
    });
  },
  [ACT_GET_HRDCLOUD_SSLSTUDIO_CATE_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/category/hrdcloud/sslStudio', params).then(response => resolve(response))
    });
  },

  [ACT_GET_HRDCLOUD_UV_LIST] (context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/lrn/hrd/uv', params).then(response => resolve(response))
    });
  },
  [ACT_GET_HRDCLOUD_ROADMAP_CTT] (context, cttMstSn) {
    return new Promise(resolve => {
      ApiService.get('/v1/app/learns/cloud/roadmap/ctt', cttMstSn).then(response => resolve(response))
    });
  },
  [ACT_INSERT_HRDCLOUD_ROADMAP_CTT] (context, {lrnObjDtlDistSn, cttMstSn, params}) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/learns/cloud/roadmap/ctt/' + lrnObjDtlDistSn + '/' + cttMstSn, params).then(response => resolve(response))
    });
  },
  [ACT_GET_HRDCLOUD_ROADMAP_LIST] (context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/learns/cloud/roadmap', params).then(response => resolve(response))
    });
  },
  [ACT_GET_HRDCLOUD_ROADMAP_CTT_LIST] (context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/learns/cloud/roadmap/ctt', params).then(response => resolve(response))
    });
  },
  [ACT_INSERT_HRDCLOUD_ROADMAP] (context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/learns/cloud/roadmap', params).then(response => resolve(response))
    });
  },
  [ACT_UPDATE_HRDCLOUD_ROADMAP] (context, params) {
    return new Promise(resolve => {
      ApiService.put('/v1/app/learns/cloud/roadmap', params).then(response => resolve(response))
    });
  },
  [ACT_UPDATE_HRDCLOUD_ROADMAP_CTT] (context, params) {
    return new Promise(resolve => {
      ApiService.put('/v1/app/learns/cloud/roadmap/ctt', params).then(response => resolve(response))
    });
  },
  [ACT_INSERT_HRDCLOUD_LVL_FEEDBACK] (context, {lrnObjDtlDistSn, cttMstSn, params}) {
    return new Promise(resolve => {
      ApiService.post(`/v1/app/learns/cloud/roadmap/levelfeedback/${lrnObjDtlDistSn}/${cttMstSn}`, params).then(response => resolve(response))
    });
  },
  [ACT_GET_HRDCLOUD_LEARNING_ROADMAP_LIST] (context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/learns/cloud/roadmap/learning', params).then(response => resolve(response))
    });
  },
  [ACT_GET_HRDCLOUD_LEARNING_ROADMAP_MENU] (context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/learns/cloud/roadmap/learning/menu', params).then(response => resolve(response))
    });
  },
  [ACT_GET_HRDCLOUD_LEARNING_ROADMAP_CARD_LIST] (context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/learns/cloud/roadmap/learning/card', params).then(response => resolve(response))
    });
  },
  [ACT_GET_HRDCLOUD_LEARNING_ROADMAP_CTT_LIST] (context, roadmapSn) {
    return new Promise(resolve => {
      ApiService.get('/v1/app/learns/cloud/roadmap/learning/ctt', roadmapSn).then(response => resolve(response))
    });
  },
  [ACT_GET_HRDCLOUD_LEARNING_ROADMAP_SNS] (context, lrnObjDtlDistSn) {
    return new Promise(resolve => {
      ApiService.get('/v1/app/learns/cloud/roadmap/learning/sns', lrnObjDtlDistSn).then(response => resolve(response))
    });
  },
  [ACT_GET_HRDCLOUD_CTT_SUBTITLES] (context, cttMngNo) {
    return new Promise(resolve => {
      ApiService.get(`/lmd/subtitle/subtitles/${cttMngNo}`).then(response => resolve(response))
    });
  },


}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}