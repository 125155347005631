import {useStore} from 'vuex';
import {goBack} from '@/assets/js/modules/common/common';
import {useAlert} from '@/assets/js/modules/common/alert';
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
import {
  actSubscribe,
  getAllSubscribes,
  getCategoryClass,
  getRouteLrnTrgtGrp,
  // getRouteLrnTrgtGrpMstSn,
  getSubscribes,
  getTrgtLrnerParams,
  // isMyLrnTrgtGrpMstSn,
  isRightSSL,
  noneRewardCds,
  sslRoutes,
} from '@/assets/js/modules/ssl/ssl-common';
import {
  getItem,
  getItems,
  lengthCheck,
  paginate,
  setParams,
} from '@/assets/js/util';
import {
  ACT_GET_BADGE_LIST,
  ACT_GET_LEARN_TARGET, ACT_GET_LEARN_TARGET_LIST,
  ACT_GET_SOC_REL_GROUP_TARGET, ACT_GET_SOC_REL_MULT_GROUP_LIST,
  ACT_INSERT_SOC_ACT,
  MUT_ADD_SUBSCRIBE_MY,
  MUT_REMOVE_SUBSCRIBE_MY, MUT_SET_LEARN_TARGET_LIST, MUT_SET_MULT_GROUP_LIST,
  MUT_TOGGLE_LRNERS,
} from '@/store/modules/ssl/ssl-index';
import {actTyCdDcds} from '@/assets/js/modules/common/common';
import {useRoute, useRouter} from 'vue-router';
import {getListFunc} from "@/assets/js/ui.init";
import {ACT_GET_COM_BADGE_TY_LIST} from "@/store/modules/badge/badge";

export const sslLearnerSetup = () => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const { showMessage } = useAlert();

  const isReady = ref(false);
  const isMobile = computed(() => store.state.auth.isMobile);
  const session = computed(() => store.state.auth.session);
  // const myGroup = computed(() => store.state.ssl.group);
  const lrnerId = computed(() => route.params.lrnerId);

  // console.log('learnerId : ' + lrnerId.value);
  const lrnTrgtGrp = computed(() => getRouteLrnTrgtGrp(route.params.lrnTrgtGrpMstSn));

  // console.log('lrnTrgtGrp.value.lrnTrgtGrpMstSn : ' + lrnTrgtGrp.value.lrnTrgtGrpMstSn);

  const distCrseCd = computed(() => route.params.distCrseCd||'');
  // const distCrseNm = computed(() => sslRoutes[distCrseCd.value] ? sslRoutes[distCrseCd.value].name : '' );
  const isGroup = computed(() => distCrseCd.value === 'group');

  // const isRecord = computed(() => route.name === 'SSLLearner');
  const isBoard = computed(() => route.name === 'SSLLearnerBoard');
  const isHome = computed(() => route.name === 'SSLLearner');

  const mainName = computed(() => {
    const target = sslRoutes[route.params.distCrseCd];
    if (target) {
      if (target.key === 'self') {
        const subTarget = target.group.find(x => x.lrnTrgtGrpMstSn === lrnTrgtGrp.value.lrnTrgtGrpMstSn);
        return target.name + (subTarget ? ` - ${subTarget.lrnTrgtGrpNm}` : '');
      }
      return sslRoutes[route.params.distCrseCd].name
    }
    return '';
  });

  const lrner = reactive({
    lrnerId: '',
    lrnerNm: '',
    chnNm:'',
    deptNm: '',
    deptCd: '',
  });

  const group = reactive({
    distCrseSn: 0,
    crseNm: '',
    distCrseLrnFldSn: 0,
    distCrseLrnFldNm: '',
    leaderLrnerId: '',
    leaderLrnerNm: '',
  });

  const isNotMine = computed(() => {
    if (isGroup.value) {
      return session.value.lrnerId !== group.leaderLrnerId;
    }
    return lrner.lrnerId !== session.value.lrnerId;
  });

  const target = computed(() => {
    if (isGroup.value) {
      return {
        lrnerId: group.leaderLrnerId,
        // lrnerNm: `${group.crseNm}(${group.distCrseLrnFldNm})`,
        lrnerNm: group.crseNm,
        deptNm: group.leaderLrnerNm,
        lrnTrgtGrpMstSn: lrnTrgtGrp.value.lrnTrgtGrpMstSn,
        lrnTrgtGrpNm: lrnTrgtGrp.value.lrnTrgtGrpNm,
      }
    }
    return {
      ...lrner,
      lrnTrgtGrpMstSn: lrnTrgtGrp.value.lrnTrgtGrpMstSn,
      lrnTrgtGrpNm: lrnTrgtGrp.value.lrnTrgtGrpNm,
    };
  })

  const getLearnTarget = () => {
    // 학습자 정보 가져오기
    store.dispatch(`ssl/${ACT_GET_LEARN_TARGET}`, {lrnerId: lrnerId.value, lrnTrgtGrpMstSn: lrnTrgtGrp.value.lrnTrgtGrpMstSn}).then(res => {
      if(lengthCheck(res)) {
        const item = getItem(res);
        setParams(lrner, item);
        isReady.value = true;
      } else {
        showMessage('잘못된 접근입니다.')
        router.push({name: 'SSLHome'}).then(() => {});
      }
    }).catch(() => {});
  }

  getLearnTarget();

  const getGroupTarget = () => {
    // 학습자 정보 가져오기
    store.dispatch(`ssl/${ACT_GET_SOC_REL_GROUP_TARGET}`, lrnerId.value).then(res => {
      if(lengthCheck(res)) {
        const item = getItem(res);
        setParams(group, item);
        isReady.value = true;
      } else {
        showMessage('잘못된 접근입니다.')
        router.push({name: 'SSLHome'}).then(() => {});
      }
    }).catch(() => {});
  }

  const getLearn = () => {
    if (isGroup.value) {
      getGroupTarget();
    } else {
      getLearnTarget();
    }

  }

  // const goMain = () => {
  //   router.push({name: 'SSLMain'}).then(() => {});
  // }

  // mobile
  const mysslTop = ref(null);
  const targetY = ref(0);
  const el = ref(null);

  const scrollEvnet = () => {
    if(targetY.value > -1){
      if (window.scrollY > targetY.value) {
        el.value.classList.add('is-scroll');
      } else {
        el.value.classList.remove('is-scroll');
      }
    }
  }

  if (isMobile.value) {
    onMounted(() => {
      targetY.value = mysslTop.value.clientHeight;
      el.value = document.querySelector('body .ssl');
      if(el.value){
        document.addEventListener('scroll', scrollEvnet);
        scrollEvnet();
      }
    });

    onBeforeUnmount(() => {
      if(el.value){
        document.removeEventListener('scroll', scrollEvnet);
        el.value.classList.remove('is-scroll');
      }
    });
  }

  if(isRightSSL(distCrseCd.value, lrnTrgtGrp.value.lrnTrgtGrpMstSn) && lrnerId.value){
    getLearn();
    if (!noneRewardCds.includes(distCrseCd.value)) getAllSubscribes();
  }

  return {
    isReady,
    lrnTrgtGrp,
    isNotMine,
    isGroup,
    // isRecord,
    isBoard,
    session,
    target,
    mainName,
    distCrseCd,

    // mobile
    mysslTop,
    // goMain,
    goBack,
    getCategoryClass,

    isHome,

  }

}

export const sslMemberSetup = (props) => {
  const store = useStore();
  const route = useRoute();
  const {showConfirm} = useAlert();

  const isMobile = computed(() => store.state.auth.isMobile);
  const session = computed(() => store.state.auth.session);
  const myGroup = computed(() => store.state.ssl.group);
  const isGroup = computed(() => route.params.distCrseCd === 'group');
  const paging = reactive({pageNo: 1, pageSize: 30, totalCount: 0, hasMore: false})

  // pc
  const membersEl = ref(null);

  const keyword = ref('');
  // mobile
  const moreMembers = () => {
    paging.pageNo++;
  }

  const closeMember = () => {
    store.commit(`ssl/${MUT_TOGGLE_LRNERS}`);
  }

  const searchMember=()=>{
    if(isGroup.value){
      store.dispatch(`ssl/${ACT_GET_SOC_REL_MULT_GROUP_LIST}`, {lrnerId:isMobile.value ? store.state.ssl.lrnerKeyword : keyword.value}).then(res => {
        if(lengthCheck(res)){
          store.commit(`ssl/${MUT_SET_MULT_GROUP_LIST}`, getItems(res));
        }else{
          store.commit(`ssl/${MUT_SET_MULT_GROUP_LIST}`, []);
        }
      }).catch(e => {
        console.error(e);
        store.commit(`ssl/${MUT_SET_MULT_GROUP_LIST}`, []);
      });

    }else{
      store.dispatch(`ssl/${ACT_GET_LEARN_TARGET_LIST}`, {lrnTrgtGrpMstSn:props.lrnTrgtGrpMstSn, customCd: 'ssl', lrnerId:isMobile.value ? store.state.ssl.lrnerKeyword : keyword.value}).then(res => {
        if(lengthCheck(res)){
          store.commit(`ssl/${MUT_SET_LEARN_TARGET_LIST}`, getItems(res));
        }else{
          store.commit(`ssl/${MUT_SET_LEARN_TARGET_LIST}`, []);
        }
      }).catch(e => {
        console.error(e);
        store.commit(`ssl/${MUT_SET_LEARN_TARGET_LIST}`, []);
      });
    }
  }

  // const groups = computed(() => store.state.ssl.groups.map(x => ({...x, lrnTrgtGrpMstSn: props.lrnTrgtGrpMstSn})));
  const multGroupsMember = computed(() => store.state.ssl.multGroupsMember);

  const refinedItems = computed(() => {
    if (isGroup.value) {
      // 1차 소팅
      return getSubscribes(
        //   groups.value
        // .filter(x =>
        //     x.leaderLrnerId.includes(isMobile.value ? store.state.ssl.lrnerKeyword : keyword.value)
        //     || x.leaderLrnerNm.includes(isMobile.value ? store.state.ssl.lrnerKeyword : keyword.value)
        //     || x.crseNm.includes(isMobile.value ? store.state.ssl.lrnerKeyword : keyword.value)
        // )
        //   // , props.lrnTrgtGrpMstSn

          multGroupsMember.value
            .filter(x =>
                x.lrnerId?.includes(isMobile.value ? store.state.ssl.lrnerKeyword : keyword.value)
                || x.lrnerNm?.includes(isMobile.value ? store.state.ssl.lrnerKeyword : keyword.value)
                || x.crseNmLst?.includes(isMobile.value ? store.state.ssl.lrnerKeyword : keyword.value)
                || x.distCrseLrnFldNmLst?.indexOf(isMobile.value ? store.state.ssl.lrnerKeyword : keyword.value)>-1
            )
        )
      // 접속시간으로 1차 소팅

      // 내 그룹일 경우 최종 소팅
      .sort((a, b) => {
        if (a.isMyGroup < b.isMyGroup) { return 1; }
        if (a.isMyGroup > b.isMyGroup) { return -1; }
        // a must be equal to b
        return 0;
      });
    }

    return getSubscribes(
        store.state.ssl.learnTargets
            // .filter(x => x.lrnerNm.toLowerCase().includes(isMobile.value ? store.state.ssl.lrnerKeyword.toLowerCase() : keyword.value.toLowerCase())
            //              || x.chnNm.toLowerCase().includes(isMobile.value ? store.state.ssl.lrnerKeyword.toLowerCase() : keyword.value.toLowerCase()))
            .filter(x =>
                // x.lrnerNm.indexOf(isMobile.value ? store.state.ssl.lrnerKeyword : keyword.value)>-1 ||
                x.chnNm?.toLowerCase().includes(isMobile.value ? store.state.ssl.lrnerKeyword.toLowerCase() : keyword.value.toLowerCase())
            )
        // , props.lrnTrgtGrpMstSn
    );
  });

  const renderItems = computed(() => {
    return paginate(refinedItems.value, 1, paging.pageNo * paging.pageSize);
  })

  const deleteSubscribe = (subscribe) => {
    store.dispatch(`ssl/${ACT_INSERT_SOC_ACT}`, {
      trgtLrnerId: subscribe.lrnerId,
      lrnTrgtGrpMstSn: subscribe.lrnTrgtGrpMstSn,
      lrnTrgtGrpNm: subscribe.lrnTrgtGrpNm,
      actTyCdDcd: actTyCdDcds.ACT_TY_CD_CANCEL_SUBSCRIBE
    }).then(() => {}).catch(() => {});
  }

  const unSubscribe = (subscribe) => {
    showConfirm('구독을 취소 하시겠습니까?',() => {
      deleteSubscribe(subscribe);
      store.commit(`ssl/${MUT_REMOVE_SUBSCRIBE_MY}`, {actTyCdDcd: actTyCdDcds.ACT_TY_CD_SUBSCRIBE, trgtLrnerId: subscribe.lrnerId, lrnTrgtGrpMstSn: subscribe.lrnTrgtGrpMstSn});
    });
  }

  if (isMobile.value) {
    watch(() => store.state.ssl.lrnerKeyword, () => {
      paging.pageNo = 1;
      searchMember();
    });

  } else {

    watch(() => keyword.value, () => {
      paging.pageNo = 1;
    });

    watch(() => props.lrnTrgtGrpMstSn, () => {
      keyword.value = '';
      paging.pageNo = 1;
    });

    onMounted(() => {
      if(membersEl.value){
        membersEl.value.addEventListener('scroll', () => {
          if(membersEl.value.scrollHeight - membersEl.value.clientHeight === membersEl.value.scrollTop){
            paging.pageNo++;
          }
        })
      }
    });
  }

  getAllSubscribes();

  return {
    session,
    myGroup,
    isGroup,
    refinedItems,
    renderItems,
    unSubscribe,
    // pc
    membersEl,
    keyword,

    // mobile
    moreMembers,
    closeMember,
    searchMember
  }

}

export const sslLearnerBadgeSetup = () => {
  const store = useStore();
  const route = useRoute();

  const lrnerId = computed(() => route.params.lrnerId);
  const learnerBadges = ref([]);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentTimestamp = currentDate.getTime();
  const newLimit = 1000 * 60 * 60 * 24 * 7;
  const sslHrdBadgesTmp = ref([]);
  getListFunc(`badge/${ACT_GET_COM_BADGE_TY_LIST}`, {year: currentYear, badgeKindCdDcd: "2065001"}, sslHrdBadgesTmp, null);
  const activeBadges = computed(() => {


    return sslHrdBadgesTmp.value.filter(x => !x.showYears || x.showYears.includes(currentYear)).map(x => {
      const targetBadge = {
        badgeTySn: x.badgeTySn,
        badgeTyCdDcd: x.badgeTyCdDcd,
        isActive: false,
        isNew: false,
        hiddenYn: x.hiddenYn,
        badgeName: x.badgeTyNm,
        badgeDesc: x.cdDesc
      };
      const found = learnerBadges.value.find(y => x.badgeTyCdDcd === y.badgeTyCdDcd);

      if (x.badgeTyNm instanceof Object) {
        targetBadge.badgeName = x.badgeTyNm[currentYear];
      } else {
        targetBadge.badgeName = x.badgeTyNm;
      }

      if (x.cdDesc instanceof Object) {
        targetBadge.badgeDesc = x.cdDesc[currentYear];
      } else {
        targetBadge.badgeDesc = x.cdDesc;
      }
      if(found){
        targetBadge.isActive = true;
        targetBadge.isNew = found.getDt + newLimit >= currentTimestamp;
      }else{
        if (targetBadge.hiddenYn === 'Y') {
          targetBadge.badgeName = x.hiddenBadgeTyNm;
          targetBadge.badgeDesc = x.hiddenBadgeCn;
        }
      }

      return targetBadge;
    })
    .filter(x=> x.isActive)
    .sort((a,b) => {
      if (a.badgeTySn < b.badgeTySn) { return -1; }
      else {return 1;}
    }).sort((a) => {
      if (a.isActive) { return -1; }
      else {return 1;}
    }).sort((a) => {
      if (a.isNew) { return -1; }
      else {return 1;}
    });
  });

  const getLearnerBadgeList = () => {
    store.dispatch(`ssl/${ACT_GET_BADGE_LIST}`, {
      lrnerId: lrnerId.value,
      year: currentYear
    }).then(res => {
      if(lengthCheck(res)) {
        learnerBadges.value = getItems(res);
      }
    }).catch(() => {});
  }

  if(lrnerId.value){
    getLearnerBadgeList();
  }

  return {
    learnerBadges,
    activeBadges
  }
}

export const sslLearnerSubscribeSetup = (props) => {
  const store = useStore();
  // const route = useRoute();

  const {showConfirm} = useAlert();

  // const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));
  // const isMyLrnTrgt = computed(() => isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn.value));
  const session = computed(() => store.state.auth.session);

  // const isMy = computed(() => session.value.lrnerId === props.lrner.lrnerId);
  // const isSameLrnTrgt = computed(() => lrnTrgtGrpMstSn.value === props.lrner.lrnTrgtGrpMstSn);

  const isSubscribe = computed(() => {
    return (store.state.ssl.subscribe.my.filter(x => x.trgtLrnerId === props.lrner.lrnerId).length > 0); //x.lrnTrgtGrpMstSn === lrnTrgtGrpMstSn.value &&
  });

  const toggleSubscribe = () => {
    // 구독된 상태일 경우
    if(isSubscribe.value){
      showConfirm({
        text : `구독을 취소 하시겠습니까?`,
        callback: () => {
          actSubscribe(actTyCdDcds.ACT_TY_CD_CANCEL_SUBSCRIBE, getTrgtLrnerParams(props.lrner));
          store.commit(
              `ssl/${MUT_REMOVE_SUBSCRIBE_MY}`,
              {
                actTyCdDcd: actTyCdDcds.ACT_TY_CD_SUBSCRIBE,
                trgtLrnerId: props.lrner.lrnerId,
                lrnTrgtGrpMstSn: props.lrner.lrnTrgtGrpMstSn
              }
          );
        }
      })

    }else{
      //구독 하기함..
      actSubscribe(actTyCdDcds.ACT_TY_CD_SUBSCRIBE, getTrgtLrnerParams(props.lrner));
      store.commit(`ssl/${MUT_ADD_SUBSCRIBE_MY}`, {
        lrnerId: session.value.lrnerId,
        actTyCdDcd: actTyCdDcds.ACT_TY_CD_SUBSCRIBE,
        socLrnPostSn: 0,
        ...getTrgtLrnerParams(props.lrner)
      });
    }
  }

  return {
    // isMyLrnTrgt,
    isSubscribe,
    toggleSubscribe
  }
}

export const sslLearnerSubscribeSmSetup = (props) => {
  const store = useStore();
  // const route = useRoute();

  const {showConfirm} = useAlert();

  // const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));
  // const isMyLrnTrgt = computed(() => isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn.value));
  const session = computed(() => store.state.auth.session);

  // const isMy = computed(() => session.value.lrnerId === props.lrner.lrnerId);
  // const isSameLrnTrgt = computed(() => lrnTrgtGrpMstSn.value === props.lrner.lrnTrgtGrpMstSn);

  const isSubscribe = computed(() => {
    // return (store.state.ssl.subscribe.my.filter(x => x.lrnTrgtGrpMstSn === lrnTrgtGrpMstSn.value && x.trgtLrnerId === props.lrner.lrnerId).length > 0);
    return (store.state.ssl.subscribe.my.filter(x => x.trgtLrnerId === props.lrner.lrnerId).length > 0);
  });

  const toggleSubscribe = () => {
    // 구독된 상태일 경우
    if(isSubscribe.value){
      showConfirm({
        text : `구독을 취소 하시겠습니까?`,
        callback: () => {
          actSubscribe(actTyCdDcds.ACT_TY_CD_CANCEL_SUBSCRIBE, getTrgtLrnerParams(props.lrner));
          store.commit(
              `ssl/${MUT_REMOVE_SUBSCRIBE_MY}`,
              {
                actTyCdDcd: actTyCdDcds.ACT_TY_CD_SUBSCRIBE,
                trgtLrnerId: props.lrner.lrnerId,
                lrnTrgtGrpMstSn: props.lrner.lrnTrgtGrpMstSn
              }
          );
        }
      })

    }else{
      //구독 하기함..
      actSubscribe(actTyCdDcds.ACT_TY_CD_SUBSCRIBE, getTrgtLrnerParams(props.lrner));
      store.commit(`ssl/${MUT_ADD_SUBSCRIBE_MY}`, {
        lrnerId: session.value.lrnerId,
        actTyCdDcd: actTyCdDcds.ACT_TY_CD_SUBSCRIBE,
        socLrnPostSn: 0,
        ...getTrgtLrnerParams(props.lrner)
      });
    }
  }

  return {
    isSubscribe,
    toggleSubscribe
  }
}

