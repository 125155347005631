<template>
  <div class="board-list-container">
    <slot name="input-search" />
    <slot name="show-filter" />
    <!-- board-list-wrapper -->
    <div class="board-list-wrapper">
      <slot name="loading-area" />
      <div v-if="!isLoading && items.length === 0" class="board-search-wrapper">
        <div class="search-empty">
          <img src="@/assets/lxp/images/ssl/main/empty_image.png" alt>
        </div>
      </div>

      <!-- board-list-type -->
      <ul v-else class="board-list-type">
        <!-- board-item -->
        <li v-for="(item, idx) in items" class="board-item" :class="{'type-text': !item.thumb}" :key="idx">
          <SSLMobileCard
              :item="item"
              :is-group="isGroup"
              @moveBoard="moveBoard"
          />
        </li>
      </ul>
      <!-- //board-list-type -->
      <!-- board-list-actions -->
      <div v-if="paging.hasMore" class="board-list-actions" >
        <button class="kb-btn-more" @click="moreFunc">
          <span class="text">더보기</span>
        </button>
      </div>
      <!-- //board-list-actions -->
    </div>
    <!-- //board-list-wrapper -->
  </div>
</template>
<script>

import SSLMobileCard from "@/components/ssl/main/mobile/SSLMobileCard";
import {computed, ref} from 'vue';
import {useRoute} from 'vue-router'
export default {
  name: 'SSLMobileBoardList',
  components: {SSLMobileCard},
  props: {
    isLoading: Boolean,
    lrnTrgtGrpMstSn: Number,
    items: Array,
    paging: Object,
    moreFunc: Function,
    showFilter: Boolean,
    viewModes: Array,
    viewModeIdx: Number,
    openSearch: Function
  },
  emits: ['moveBoard'],
  setup(props, {emit}) {
    const route = useRoute();
    const isGroup = computed(() => route.params.distCrseCd === 'group');

    const tryCount = ref(0);
    const isTry = ref(false);

    const focusSearch = () => {
      if(tryCount.value > 10) {
        isTry.value = false;
        return;
      }
      const target = document.getElementById('kb-board-search');
      if(target){
        target.focus();
        isTry.value = false;
      }else{
        tryCount.value++;
        setTimeout(() => {
          focusSearch();
        }, 200)
      }
    }

    const openSearchAndFocus = () => {
      if(isTry.value) return;
      isTry.value = true;
      tryCount.value = 0;
      props.openSearch();
      focusSearch();
    }

    const moveBoard = (item) => {
      emit('moveBoard', item);
    }

    return {
      isGroup,
      openSearchAndFocus,
      moveBoard
    }
  }
}
</script>
