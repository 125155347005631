<template>
  <LxpMobileHeader v-if="!applyGroupModal && !inviteModal && !changeLeaderModal" title="그룹러닝">
    <template v-slot:left>
      <div class="util util-back">
        <router-link :to="{name : isGroup.length === 0 ? 'GroupApplication' : 'GroupLearning'}" class="util-actions util-actions-back" >
          <i class="icon-history-back"></i>
        </router-link>
      </div>
    </template>
    <template v-if="isLeader" v-slot:right>
      <div class="util util-pencil me-2 mt-1">
        <router-link :to="{name: 'GroupLearningMng', params: {boardSn: items.find(x=> x.distCrseSn==distCrseSn).boardSn}}" class="util-actions">
          관리
        </router-link>
      </div>
    </template>
  </LxpMobileHeader>
  <main v-if="!applyGroupModal && !inviteModal && !changeLeaderModal" class="kb-main" id="kb-prep-group">
    <!-- main-content -->
    <div class="main-content ">
      <!-- learningGroup-header -->
      <div class="learningGroup-header">
        <!-- learningGroup-info -->
        <div class="learningGroup-info">
          <div class="info-symbol">
            <div class="image"><img src="../../../../assets/lxp/mobile/images/@tmp/@tmp_group_symbol.jpg" alt=""></div>
          </div>
          <div class="info-title">
            <h3 class="title">{{ item.crseNm }}</h3>
          </div>
          <div class="info-category"><span class="text">그룹러닝 | {{ item.distCrseLrnFldNm }}</span></div>
          <div class="info-description">
            <p class="text">{{ item.crseSumup }}</p>
          </div>
        </div>
      </div>
      <!-- //learningGroup-header -->

      <!-- learningGroup-body -->
      <div class="learningGroup-body">
        <!-- content-section:my 그룹 현황 -->
        <section class="content-section section-member">
          <header class="section-header">
            <h4 class="title">
              <span class="title-text">참여<span class="count text-muted">{{ item.lrnTrgtCnt }}</span> 정원 <span class="count text-muted">{{ item.peoplCnt }}</span></span>
              <template v-if="isGroup.length > 0 && isGroup[0].lrnerId === session.lrnerId">
                <div class="kb-form-layer" :class="{'is-active' : isAction}">
                  <button class="dropdown-btn kb-btn-actions" @click="clickAction"><i class="icon-more"></i></button>
                  <div class="layer-container">
                    <div class="kb-top-layer" style="width:100%;flex:1 1 auto;position:relative;overflow:hidden;" @click.stop.prevent="isAction = false" />
                    <div class="layer">
                      <div class="kb-form-layer-options">
                        <div class="layer-option">
                          <ul class="layer-option-list">
                            <li v-if="item.stt === '01'" class="layer-option-item" @click="clickInvite">
                              <a href="javascript:" class="layer-option-link"><strong class="layer-option-text">초대하기</strong></a>
                            </li>
                            <li v-if="item.stt === '01'" class="layer-option-item" @click="clickSecession">
                              <a href="javascript:" class="layer-option-link"><strong class="layer-option-text">탈퇴하기</strong></a>
                            </li>
                            <li v-if="item.stt === '01' && isLeader" class="layer-option-item" @click="clickChangeLeader">
                              <a href="javascript:" class="layer-option-link"><strong class="layer-option-text">리더변경</strong></a>
                            </li>
                            <li v-if="item.stt === '01' && isLeader" class="layer-option-item" @click="clickDeleteGroup">
                              <a href="javascript:" class="layer-option-link"><strong class="layer-option-text">그룹삭제</strong></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </h4>

          </header>
          <!-- member-list-wrap -->
          <MobileGroupMembers :items="items" @update:modelValue="updateSelfIntro"/>
          <!-- //member-list-wrap -->
        </section>
      </div>
      <!-- //learningGroup-body -->
      <div v-if="item.stt === '01' && isGroup.length === 0" class="page-buttons">
        <button class="kb-btn kb-btn-primary" @click="clickApply">참여하기</button>
      </div>
    </div>
  </main>
  <ApplyGroupLrnModal
      v-if="applyGroupModal"
      v-model="applyGroupModal"
      @btnBack="btnBack"
      @apply-success="applySuccess"
      :dist-crse-sn="$route.params.distCrseSn"
  />
  <MobileGroupMemberInvite
      v-if="inviteModal"
      v-model="inviteModal"
      :group-info="item"
  />
  <MobileChangeLeaderModal
      v-if="changeLeaderModal"
      v-model="changeLeaderModal"
      :dist-crse-sn="$route.params.distCrseSn"
      :members="items"
  />
</template>
<script>
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import MobileGroupMembers from "@/components/prep/group/mobile/MobileGroupMembers";
import {groupLearningDetailViewSetup} from "@/assets/js/modules/groupLearing/group-learning-detail-view-setup";
import ApplyGroupLrnModal from "@/components/prep/group/ApplyGroupLrnModal";
import MobileGroupMemberInvite from "@/components/prep/group/mobile/MobileGroupMemberInvite";
import MobileChangeLeaderModal from "@/components/prep/group/mobile/MobileChangeLeaderModal";

export default {
  name: "MobileGroupLearningDetailView",
  components: {
    MobileChangeLeaderModal,
    MobileGroupMemberInvite, ApplyGroupLrnModal, MobileGroupMembers, LxpMobileHeader},
  setup: groupLearningDetailViewSetup
}
</script>
<style scoped>
.layer-container { flex-direction:column; }
.kb-top-layer { width:100%;flex:1 1 auto;position:relative;overflow:hidden; }
.layer {height:auto;}
</style>