<template>
  <div class="kb lxp-mobile-layout" :class="renderClass" id="kb" @click="plusEffectNav" :data-mode="dataBg">
    <router-view />
    <HrdCloudPopups />
    <LxpMobileFooterV2 v-if="!hideFooter" :items="notices"/>
    <LxpPopup/>
    <MobileDailyPopup v-if="(($route.name === 'DailyApply') && !expirationDaliyPopup && false)"/>
<!--    <div class="floating-btn-area" v-if="!hideFooter">-->
<!--      <SSLWriteButton v-if="!hideSSLBtn" :effect-nav="touchBodyCount" :effect-func="plusEffectNav" />-->
<!--    </div>-->
  </div>
</template>
<script>
// import NoSleep from 'nosleep.js';
import LxpMobileFooterV2 from '@/views/layout/lxp/footer/mobile/LxpMobileFooterV2';
import {computed, onMounted, onUnmounted, ref} from 'vue';
import {
    ACT_GET_CODE_LIST,
    ACT_GET_MEDIA_CATE_LIST,
    MUT_SET_CATE_LIST,
    MUT_SET_CODE_LIST,
} from '@/store/modules/code/code';
import {getCheckItems, getItems, lengthCheck, timestampToDateFormat} from '@/assets/js/util';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';
import LxpPopup from '@/components/common/LxpPopup';
import {ACT_GET_HELP_NOTICE_LIST} from '@/store/modules/help/help';
import {expsrLocCds} from '@/assets/js/modules/help/help-common';
import HrdCloudPopups from '@/components/common/HrdCloudPopups';
import {getHrdCates} from '@/assets/js/modules/hrd/hrd-common';
import MobileDailyPopup from '@/components/daily/mobile/MobileDailyPopup';
import {setProfileImgUrl} from '@/assets/js/modules/common/common';
import {setHotTipState} from "@/assets/js/modules/learn/learn-hot-tip";
import {MUT_INIT_BOARD_MST_OBJECT} from "@/store/modules/board/board-mst";
// import {MUT_SET_NOSLEEP} from '@/store/modules/common/common-index';
// import SSLWriteButton from "@/components/ssl/SSLWriteButton.vue";

export default {
  name: "LxpMobileLayoutV2",
  components: {MobileDailyPopup, HrdCloudPopups, LxpPopup, LxpMobileFooterV2},
  setup() {
    const route = useRoute();
    const store = useStore();
    const touchBodyCount = ref(0);
    const session = computed(() => store.state.auth.session);

    console.log(session.value)
    let nowDate = new Date();
    const expirationDaliyPopup = computed(() => parseInt(localStorage.getItem('kb-popup-daliy')) > nowDate);

    const notices = ref([]);

    // const hideSSLBtn = computed(() => session.value.jbpsCd === 'A99' || route.path.indexOf('/hottip') > -1);
    const hideSSLBtn = computed(() => {
      //hottip 게시물 조회시 ssl 버튼 활성화
      if(route.path.indexOf("/board/hottip-")>-1) return false;
      return session.value.jbpsCd === 'A99' || route.path.indexOf('/hottip') > -1
    });

    // watch 가 아닌 computed 로 설정
    const concentrationMode = computed(() => {
      if (store.state.hrdcloud.mode === 'dark') {
        return 'dark';
      } else if (store.state.hrdcloud.mode === 'fill') {
        return 'fill';
      }
      return '';
    });

    const fillNames = ['MentoringWrite' , 'SSMWrite', 'LearnBoardWrite'];
    // const fillBrownNames = ['LearnBoardWrite'];

    const dataBg = computed(() => {
      if(fillNames.includes(route.name)) return 'fill';
      // else if (fillBrownNames.includes(route.name)) return 'fill-brown';
      return concentrationMode.value;
    });

    // const noSleep = computed(() => store.state.common.noSleep.instance);

    const hideNames = ['PageNotFound', 'LearnBoardWrite'];

    setProfileImgUrl() // profile img url set

    // 임시 적용.
    const hideFooter = computed(() => {
      return route.query.modal === 'evl'
          || route.query.modal === 'cms'
          || hideNames.includes(route.name)
          || store.state.common.footer.hide
          ;
    });

    const plusEffectNav = () => {
      touchBodyCount.value++;
    };

    const getCodes = () => {
      store.dispatch(`code/${ACT_GET_CODE_LIST}`).then(res => {
        if (lengthCheck(res)) {
          store.commit(`code/${MUT_SET_CODE_LIST}`, getItems(res));
        }
      });
    };

    const getCates = () => {
      store.dispatch(`code/${ACT_GET_MEDIA_CATE_LIST}`, {treeYn: 'Y'}).then(res => {
        store.commit(`code/${MUT_SET_CATE_LIST}`, getCheckItems(res));
      });
    };

    const getHelpNoticeList = () => {
      store.dispatch(`help/${ACT_GET_HELP_NOTICE_LIST}`, {
        expsrLocCd: expsrLocCds.EXPSR_LOC_CD_MAIN,
        pageSize: 5
      }).then(res => {
        if (lengthCheck(res)) {
          notices.value = getItems(res).map(x => ({
            ...x,
            regDt: timestampToDateFormat(x.regDt, 'yyyy.MM.dd')
          }));
        } else {
          notices.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    const renderClass = computed(() => {
        let classes = '';
        if (route.name.indexOf('Group') > -1) classes = 'ssl-mobile-layout';
        if (route.name.indexOf('KnowledgeELearning') > -1) classes += ' ssl';
        return classes;
    });

    // if (noSleep.value === null) {
    //   store.commit(`common/${MUT_SET_NOSLEEP}`, new NoSleep());
    // }

    getCodes();
    getCates();
    getHrdCates();

    // footer 의 조건부 숨김정책으로 불필요한 api 재호출 방지를 위해 layout 으로 이동
    getHelpNoticeList();

    onMounted(setHotTipState)
    onUnmounted(() => {
      store.commit(`boardmst/${MUT_INIT_BOARD_MST_OBJECT}`);
    })

    return {
      session,
      notices,
      touchBodyCount,
      dataBg,
      hideFooter,
      expirationDaliyPopup,
      plusEffectNav,
      renderClass,
      hideSSLBtn,
    }
  }
}
</script>
