<template>
  <main class="kb-main" id="kb-hrdcloud">
    <div class="main-header">
      <div class="header-top min-component">
        <div class="header-column">
          <a href="javascript:" class="page-nav" @click="goBack">
            <i class="icon-kb-nav-arrow"></i>
            <span class="text">이전</span>
          </a>
        </div>
        <div class="header-column">
          <button class="kb-btn-mode" @click="toggleConcentrationMode">
            <span class="text">{{ concentrationModeText }}</span>
            <i class="icon-mode"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="main-content min-component">
      <div class="hrdcloud-view-container">
        <div class="player hrdcloud-player">
          <div class="player-container">
            <swiper ref="Swiper" :direction="'vertical'"
                    class="shorts-swiper"
                    :space-between="50"
                    :module="[Mousewheel]"
                    :mousewheel="true"
                    @swiper="setControlledSwiper"
                    :onSlideChange="onSlideChange"
            >


              <swiper-slide class="player-inner" v-for="(video, idx) in shortsItems" :key="idx">
                <div v-if="renderVideoFlag(idx)">
                    <HrdCloudShortsPlayer
                           ref="players"
                           :uuid="video.uuid"
                           :crse="video"
                           :progress="user"
                           :popups="popups"
                           :preload="true"
                           :autoPlay="idx === activeIndex"
                           :videoIndex="idx"
                       >
                        <template v-slot:shortsDetail>
                             <div class="detail-row">
                               <div class="detail-contents">
                                 <h3 class="title">{{ video.objNm }} <i v-if="video.isNew" class="icon-new"></i></h3>
                                 <div class="metadata-container">
                                   <div class="metadata"><i class="icon-view" style="filter:invert(1);"></i><span class="text">{{ video.inqCnt || 0 }}</span></div>
                                   <div v-if="servTyCdDcds.SERV_TY_CD_KBTUBE === video.servTyCdDcd" class="metadata w-ctt"><span class="text">등록 {{ video.cttMstRgtrDeptNm }} {{ video.cttMstRgtrNm }} {{ timestampToDateFormat(video.regDt, 'yyyy.MM.dd') }}</span></div>
                                   <div v-else class="metadata w-ctt"><span class="text">등록 {{ timestampToDateFormat(video.regDt, 'yyyy.MM.dd') }}</span></div>
                                   <div class="metadata"><span class="text tag">{{ video.tag }}</span></div>
                                 </div>
                               </div>
                             </div>
                         </template>
                         <template v-slot:actionsDetail>
                           <HrdCloudShortsActions
                               :hrd="video"
                               :funcs="detailFuncs"
                           />
                         </template>
                       </HrdCloudShortsPlayer>
  <!--             <HrdCloudComment v-if="video && video.objSn" :effect-nav="effectNav" :objSn="video.objSn" />-->
                </div>
                <div v-else>
                  dummy Slide {{ idx+1 }}
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>

    <HrdCloudHistory v-if="nowVideo && historyModal" v-model="historyModal" :title="nowVideo.objNm" :duration="nowVideo.cttSz" :items="histories" :func="showLatestHistory" :ctt-ty-cd-dcd="nowVideo.cttTyCdDcd" />
    <HrdCloudHistoryDtlModal v-if="nowVideo && latestHistoryModal" v-model="latestHistoryModal" v-model:lrn-obj-dtl-dist-sn="nowVideo.lrnObjDtlDistSn" :year="new Date().getFullYear()" :month="new Date().getMonth()+1" />
    <HrdCloudShareModal v-if="nowVideo && shareModal" v-model="shareModal" :data="nowVideo" />
    <HrdCloudOpinionModal v-if="opinionModal" v-model="opinionModal" :cttMstSn="nowVideo.objSn" :title="nowVideo.objNm" />
    <HrdCloudSttModal v-if="nowVideo && sttModal" v-model="sttModal" :title="nowVideo.objNm" :item="sttText" />
  </main>
</template>
<style scoped>
.player-inner {
  display: flex;
  justify-content: center;
}

.shorts-swiper{
  height: 100vh;
}
</style>
<script>
import {goBack} from '@/assets/js/modules/common/common';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch, nextTick} from 'vue';
import {initProgressBar, timestampToDateFormat} from '@/assets/js/util';
// import HrdCloudComment from '@/components/hrdcloud/HrdCloudComment';
import HrdCloudShortsPlayer from '@/components/hrdcloud/HrdCloudShortsPlayer';
import {
  cttTyCdDcds, servTyCdDcds,
  darkMode,
  detailCategoryDisplay,
  generateUUID,
  handleActions,
  lightMode,
  // makeTags,
  pageView,
  toggleConcentrationMode, insertVodLearnProgress, eventCdDcds,
} from '@/assets/js/modules/hrd/hrd-common';
import HrdCloudHistory from '@/components/hrdcloud/HrdCloudHistory';
import HrdCloudShareModal from '@/components/hrdcloud/HrdCloudShareModal';
import HrdCloudOpinionModal from '@/components/hrdcloud/HrdCloudOpinionModal';
import HrdCloudHistoryDtlModal from '@/components/history/HrdCloudHistoryDtlModal';
import HrdCloudSttModal from '@/components/hrdcloud/HrdCloudSttModal';
import {lrnObjTyCds} from '@/assets/js/modules/learn/learn-common';
import {Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Mousewheel } from "swiper";
import HrdCloudShortsActions from "@/components/hrdcloud/HrdCloudShortsActions.vue";

export default {
  name: 'HrdShorts',
  components:{
    HrdCloudShortsActions,
    HrdCloudHistoryDtlModal,
    HrdCloudOpinionModal,
    HrdCloudShareModal,
    HrdCloudHistory,
    HrdCloudShortsPlayer,
    // HrdCloudComment,
    HrdCloudSttModal,
    Swiper,
    SwiperSlide
  },
  props: {
    objDetail: Object,
    shortsItems:Array,
    effectNav: {
      required: false,
      type: Number,
      default: 0
    },
  },
  setup(props) {
    const store = useStore(), route = useRoute(), router = useRouter();
    const isMobile = computed(() => store.state.auth.isMobile);
    const session = computed(() => store.state.auth.session);
    const hrd = computed(() => props.objDetail);
    const nowVideo = ref({});
    const isLoading = ref(true);
    const lrnObjDtlDistSn = computed(() => route.params.lrnObjDtlDistSn);
    const category = ref({});
    const concentrationModeText = computed(() => store.state.hrdcloud.mode === 'dark' ? '집중모드 켜짐' : '집중모드 꺼짐');
    const isMenuActive = ref(false);
    const coldcall = ref(false);
    const user = reactive({ progRsltSn: 0, maxPlaySec: 0, lastPlaySec: 0, cmptnYn: 'N', mdfcnDt: null, regDt: null });
    const latestHistoryModal = ref(false), historyModal = ref(false), shareModal = ref(false), opinionModal = ref(false), sttModal = ref(false);
    const histories = ref([]);
    const sttText = ref({});
    const uuid = ref(null);
    const players = ref(null);
    const Swiper = ref(null);

    const activeIndex = ref(0);

    const controlledSwiper = ref(null);
    const setControlledSwiper = (swiper) => {
      controlledSwiper.value = swiper;
    };

    const popups = reactive({
      miss: {
        show: false,
        toggle: (bool) => {
          popups.miss.show = bool;
        },
        response: {
          effect: 0,
          type: ''
        },
        respond: (type) => {
          popups.miss.response.type = type;
          popups.miss.show = false;
          popups.miss.response.effect++;
        },
        initRespond : () => {
          popups.miss.response.type = '';
        }
      },
      resume: {
        show: false,
        toggle: (bool) => {
          popups.resume.show = bool;
        },
        response: {
          effect: 0,
          type: '',
          continueEffect: 0,
        },
        respond: (type) => {
          popups.resume.response.type = type;
          popups.resume.show = false;
          popups.resume.response.effect++;
          if (type === 'continue') {
            popups.resume.response.continueEffect++;
          }
        },
        initRespond : () => {
          popups.resume.response.type = '';
        }
      },
      complete: {
        show: false,
        feedback: false,
        toggle: (bool) => {
          popups.complete.show = bool;
          if(!bool) popups.complete.feedback = bool;
        },
        callback: (videoInstance) => {
          videoInstance.value.exitFullscreen();
        }
      },
      getUUID: async () => {
        const id = await generateUUID();
        if (id && id.uuid) uuid.value = id.uuid;
      }
    });

    const objectParam = computed(() => {
      return {
        distCrseSn: hrd.value.distCrseSn,
        distChapSn: hrd.value.distChapSn,
        lrnObjDtlDistSn: hrd.value.lrnObjDtlDistSn,
        objSn: hrd.value.objSn,
        // division: 'vod'
      }
    });

    const resetPlayers = () =>{
      players.value.forEach((player) => {
          player.videoInstance.pause();
        player.videoInstance.currentTime(0);
        }
      );
    };

    const renderVideoFlag = (index) =>{
      return ( index >= activeIndex.value -1 && index <= activeIndex.value +1)
    }

    const playActivePlayer = (idx) =>{
      const video = ref(props.shortsItems[idx]);
      nowVideo.value = video;
      console.log("active idx", idx, video);
      if(video.value){
        pageView(video);
        if(players.value) players.value[idx].videoInstance.play();

        // if(props.shortsItems.length>idx){
        //   console.log("preload at", idx+1, players.value[idx+1].videoInstance.src());
        //   players.value[idx+1].videoInstance.load();
        // }
      }
    }

    const updateVideoPlayback = () =>{
      nextTick(()=>{
        console.log("active idx", activeIndex.value);
        players.value.forEach((player) => {
              console.log("chk players", player.videoIndex , activeIndex.value, player.videoIndex === activeIndex.value);
              try{
                player.pauseVideo()
              }catch (e) {
                console.log("pause catch err", e.message);
              }
              if(player && typeof player.playVideo === "function"){
                if(player.videoIndex === activeIndex.value){

                  player.playVideo();

                  console.log("play video", player.videoInstance.id())
                }
              }
            }
        );
      })
    }

    const onSlideChange = (e) =>{
      if(!e){
        resetPlayers();
        playActivePlayer(activeIndex.value);
      }

      console.log("chk e", e.activeIndex);
      activeIndex.value = e.activeIndex;

      updateVideoPlayback();

    };
    // 콘텐츠 정보
    const getHrdCloudDetail = async (video) => {
      console.log(video);
    };

    const togglePlayerMenu = () => {
      isMenuActive.value = !isMenuActive.value;
    };

    const detailFuncs = {
      //연관콘텐츠 바로가기 action 삽입 후 router push
      openOrigin: (crse) => {


        // console.log("crse ", crse, players.value[activeIndex.value]);
        //현재 player의 currentTime 찾기

        let ct = Math.floor(players.value[activeIndex.value].videoInstance.currentTime());
        insertVodLearnProgress(crse, {
          progSesnKey: crse.uuid,
          lastPlaySec: ct,
          playSec: ct,
          playSpeed: 1,
          eventCdDcd: eventCdDcds.callRefContent,
          eventNm: '연관콘텐츠 호출',
        }, () => {
          let path = `/prep/hrdcloud/${crse.refLrnObjDtlDistSn}/detail`;
          router.push(path);
        });
      },
      openHistory: (item) => {
        nowVideo.value = item;
        handleActions(item).actHistory(historyModal, histories);
      },
      openShare: (item) => {
        nowVideo.value = item;
        handleActions(item).actShare(shareModal);
      },
      openOpinion: (item) => {
        nowVideo.value = item;
        handleActions(item).actOpinion(opinionModal);
      },
      openStt: (item) => {
        nowVideo.value = item;
        handleActions(item).actStt(sttModal,sttText);

      },
      prevSlide: () =>{
        controlledSwiper.value.slidePrev();
      },
      nextSlide: () =>{
        controlledSwiper.value.slideNext();
      }
    };

    const showLatestHistory = () => {
      latestHistoryModal.value = true;
    };

    // getHrdCloudDetail();

    watch(() => lrnObjDtlDistSn.value, () => {
      initProgressBar();
      if (lrnObjDtlDistSn.value > 0) {
        getHrdCloudDetail();
      }
    });


    const leaderAdd = computed(() => store.state.hrdcloud.leaderAdd);

    // 담기 후 업데이트를 위해 한번 더 호출
    watch(() => leaderAdd.value.show, () => {
      if (hrd.value && !leaderAdd.value.show && leaderAdd.value.updateMyAdd) {
        const lrnObjDtlDistSn = leaderAdd.value.lrnObjDtlDistSn;
        if (hrd.value.lrnObjDtlDistSn === lrnObjDtlDistSn) {
          hrd.value.put = leaderAdd.value.isPut;
        }
      }
    });

    const handleKeyDown = (e) =>{
      if(e.key==="ArrowUp"){
        controlledSwiper.value.slidePrev();
        e.preventDefault();
      }else if(e.key ==="ArrowDown"){
        controlledSwiper.value.slideNext();
        e.preventDefault();
      }
    }

    onMounted(() => {
      darkMode();
      // setTimeout(()=>{
      //   if(props.shortsItems.length>0) playActivePlayer(0);
      // }, 500);
      window.addEventListener("keydown", handleKeyDown);
    });

    onBeforeUnmount(() => {
      lightMode();
      coldcall.value = false;
      window.removeEventListener("keydown", handleKeyDown);
    });

    return {
      isMobile, session, uuid, lrnObjDtlDistSn,
      hrd, category, coldcall, user, histories,sttText,
      isLoading,
      goBack,
      concentrationModeText,
      isMenuActive,
      toggleConcentrationMode,
      togglePlayerMenu,
      timestampToDateFormat,
      handleActions, detailFuncs, detailCategoryDisplay,
      historyModal, shareModal, opinionModal, objectParam,sttModal,
      latestHistoryModal ,showLatestHistory,
      popups, lrnObjTyCds,
      cttTyCdDcds,
      servTyCdDcds,
      onSlideChange,players,
      Swiper,
      setControlledSwiper,
      Mousewheel,
      nowVideo,
      renderVideoFlag,
      activeIndex
    };
  }
};
</script>
