import store from '@/store';
/*
 구분(`BANER_DIV_CD_DCD`) : 팝업/배너
 2103001 : 팝업
 2103002 : 배너
 */
import {ACT_GET_BANER_LIST,ACT_GET_BANER_EXT_URL} from '@/store/modules/baner/baner';
import {aws2ncloudUrl, dateToDateFormat, getItems, lengthCheck} from '@/assets/js/util';
import navigationUtils from "@/assets/js/navigationUtils";

export const banerDivCdDcds = {
    popup : '2103001',
    baner : '2103002'
}


/*
- 구분값(배너, 팝업)에 따라 노출위치 코드 정의(개발에서 노출 처리)
	* 배너 : 메인, HRD 클라우드 , SSL메인
	* 팝업 : 메인

	메인
	HRD 클라우드
	SSL메인
* */
export const banerExpsrLocCdDcds = {
    main : '2104001',
    hrdCloud : '2104002',
    sslMain: '2104003',
    sumgo: '2104004',
    bpcc: '2104005',
    ccc: '2104006',
    hotTip: {
        'main': '2104007',
        'hottip-my': '2104008',
        'hottip-hq': '2104009',
        'hottip-biz': '2104010',
    },
}

export const getType = (thumb) => {
    if(thumb){
        if(thumb.indexOf('.mp4') > -1){
            return 'video';
        }else if(thumb.indexOf('.jpg') > -1 || thumb.indexOf('.png') > -1){
            return 'image';
        }
    }
    return null;
}

export const getThumbImg = (thumb) => {
    let thumbUrl = null;
    const session = store.state.auth.session;
    if(thumb) {
        let splitThumb = [];
        if (thumb.indexOf(process.env.VUE_APP_S3_URL) > -1) {
            splitThumb = thumb.split(process.env.VUE_APP_S3_URL);
            thumbUrl =  `${process.env.VUE_APP_CLOUDFRONT_URL}${splitThumb[1]}`;
        } else if(thumb.indexOf(process.env.VUE_APP_CLOUDFRONT_URL) > -1){
            thumbUrl =  thumb;
        } else if(thumb.indexOf("/app/") == 0 ){
            return(thumb);
        } else {
            thumbUrl = `${process.env.VUE_APP_CLOUDFRONT_URL}/${thumb}`;
        }
        if(session.itn){
            // console.log('thumbUrl::::::', thumbUrl);
            return thumbUrl;
        } else {
            // console.log('aws2ncloudUrl(thumbUrl)::::::', aws2ncloudUrl(thumbUrl));
            return aws2ncloudUrl(thumbUrl);
        }
    }
    return null;
}

export const getBanerList = (paylod, items, cb) => {
    const params = {
        nowDate: dateToDateFormat(new Date(), 'yyyy-MM-dd'),
        ...paylod
    };

    store.dispatch(`baner/${ACT_GET_BANER_LIST}`, params).then(res => {
        if(lengthCheck(res)){
            items.value = getItems(res).map(x => ({
                ...x,
                type: getType(navigationUtils.any() ? x.mobiThumb : x.thumb),
                thumb: getThumbImg(x.thumb),
                mobiThumb: getThumbImg(x.mobiThumb),
            }));
        }
        if (cb instanceof Function) cb(res);
    });
};

export const getBanerExcUrl = (paylod, items, cb) => {
    const params = {
        ...paylod
    };

    store.dispatch(`baner/${ACT_GET_BANER_EXT_URL}`, params).then(res => {
        if(lengthCheck(res)){
            items.value = getItems(res).map(x => ({
                ...x,
                url: x.col1,
                url2: x.col2,
            }));
        }
        if (cb instanceof Function) cb(res);
    });
};



export const getJuniorBaner = (baners) => {
    baners.push({
        comBanerSn: 999,
        banerDivCdDcd: '2103002',
        banerDivNm: '배너',
        banerExpsrLocCdDcd: '2104001',
        banerExpsrLocNm: '메인',
        banerUrl: 'junior',
        thumb: `${process.env.VUE_APP_CLOUDFRONT_URL}/contents/hmpg/baner/prestar_banner.png`,
        mobiThumb:`https://lxpcms.kbstar.com/contents/hmpg/baner/mobithumb/prestar_banner_mobile.png`
    });
}