<template>
  <div class="ssl lxp-mobile-layout ssl-mobile-layout">
    <template v-if="isReady">
      <router-view @click="plusEffectNav"/>
<!--      <div v-if="!mobileHideFooter" class="floating-btn-area">-->
<!--        <SSLWriteButton :effect-nav="touchBodyCount" :effect-func="plusEffectNav" />-->
<!--        <div class="floating-btn bg-white-btn">-->
<!--          <button class="floating-btn-link" @click="goTop">-->
<!--            <i class="icon-scrolltop"></i>-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
      <LxpMobileFooterV2 v-if="!mobileHideFooter && !hideFooter" :items="notices"/>
<!--      <SSLCalendar v-if="calendar.show"/>-->
      <SSLMobileCalendar v-if="calendar.show && !isWrite"/>
      <SSLPopup/>
    </template>
    <SSLEventStatusPopup v-if="eventStatusPopup" v-model="eventStatusPopup"/>
  </div>
</template>
<script>
// import SSLCalendar from '@/components/ssl/common/SSLCalendar';
import SSLMobileCalendar from '@/components/ssl/common/mobile/SSLMobileCalendar';
import SSLPopup from '@/components/ssl/common/SSLPopup';
import {sslLayoutSetup} from '@/assets/js/modules/ssl/setup/ssl-layout-setup';
import SSLEventStatusPopup from "@/components/ssl/SSLEventStatusPopup.vue";
import LxpMobileFooterV2 from "@/views/layout/lxp/footer/mobile/LxpMobileFooterV2.vue";

export default {
  name: 'SSLMobileLayout',
  components: {
    LxpMobileFooterV2,
    SSLPopup,
    // SSLCalendar,
    SSLEventStatusPopup,
    SSLMobileCalendar
  },
  setup: sslLayoutSetup
}
</script>
