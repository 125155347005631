<template>
  <main class="kb-main" id="kb-hrdcloud" style="padding-bottom : 0px;">
    <div class="main-content min-component" style="padding-bottom: 50px;">
      <div class="hrdcloud-view-container">
        <div class="player hrdcloud-player">
          <div class="player-container">
            <div class="player-inner">
                <LearnSSLPdf
                    v-if="!isLoading"
                    :cms="hrd"
                    v-model:is-series-active="isSeriesActive"
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {goBack} from '@/assets/js/modules/common/common';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
import {getItem, initProgressBar, lengthCheck, setParams, timestampToDateFormat} from '@/assets/js/util';
import {
  darkMode,
  detailCategoryDisplay,
  disableMyAddChanged,
  generateUUID,
  getUserProgress,
  handleActions,
  lightMode,
  pageView,
  toggleConcentrationMode,
} from '@/assets/js/modules/hrd/hrd-common';
import {lrnObjTyCds} from '@/assets/js/modules/learn/learn-common';
import LearnSSLPdf from '@/components/learn/window/LearnSSLPdf';

export default {
  name: 'HrdPdf',
  components:{
    LearnSSLPdf
  },
  props:{
    objDetail:Object,
    effectNav: {
      required: false,
      type: Number,
      default: 0
    },
  },
  setup(props) {
    console.log("hrdItem = ", props.objDetail);
    const store = useStore(), route = useRoute();
    const isMobile = computed(() => store.state.auth.isMobile);
    const session = computed(() => store.state.auth.session);
    const lrnObjDtlDistSn = computed(() => route.params.lrnObjDtlDistSn);
    // const {showMessage} = useAlert();
    const isLoading = ref(true);
    const hrd = computed(() => props.objDetail);
    const category = ref({});
    const concentrationModeText = computed(() => store.state.hrdcloud.mode === 'dark' ? '집중모드 켜짐' : '집중모드 꺼짐');
    const isMenuActive = ref(false);
    const coldcall = ref(false);
    const user = reactive({ progRsltSn: 0, maxPlaySec: 0, lastPlaySec: 0, cmptnYn: 'N', mdfcnDt: null, regDt: null });
    const latestHistoryModal = ref(false), historyModal = ref(false), shareModal = ref(false), opinionModal = ref(false);
    const histories = ref([]);
    const isSeriesActive = ref(false);

    const objectParam = computed(() => {
      return {
        distCrseSn: hrd.value.distCrseSn,
        distChapSn: hrd.value.distChapSn,
        lrnObjDtlDistSn: hrd.value.lrnObjDtlDistSn,
        objSn: hrd.value.objSn,
        // division: 'vod'
      }
    });

    // 콘텐츠 정보
    const getHrdCloudDetail = async () => {

      pageView(hrd);
      await getUUID();
      const userProgressRes = await getUserProgress(hrd);
      if(lengthCheck(userProgressRes)) setParams(user, getItem(userProgressRes));
      isLoading.value = false;

      //
      // const res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_DETAIL}`, {lrnObjDtlDistSn: lrnObjDtlDistSn.value})
      // if (lengthCheck(res)) {
      //   hrd.value = res.items.map(x => {
      //     return {
      //       ...getTop1CategoryByLevel(x.cloudKeyLst),
      //       ...x,
      //       tag: makeTags(x.tag)
      //     };
      //   })[0];
      //   pageView(hrd);
      //   await getUUID();
      //   const userProgressRes = await getUserProgress(hrd);
      //   if(lengthCheck(userProgressRes)) setParams(user, getItem(userProgressRes));
      //   isLoading.value = false;
      // } else {
      //   isLoading.value = false;
      //   showMessage("데이터 조회중 오류가 발생했습니다.");
      // }
    };

    const togglePlayerMenu = () => {
      isMenuActive.value = !isMenuActive.value;
    };

    const detailFuncs = {
      openHistory: (item) => {
        handleActions(item).actHistory(historyModal, histories);
      },
      openShare: (item) => {
        handleActions(item).actShare(shareModal);
      },
      openOpinion: (item) => {
        handleActions(item).actOpinion(opinionModal);
      }
    };

    const uuid = ref(null);
    const getUUID = async () => {
      let id = await generateUUID();
      if (!uuid.value) {
        id = await generateUUID();
        uuid.value = id.uuid;
      }
    };

    getHrdCloudDetail();

    const showLatestHistory = () => {
      latestHistoryModal.value = true;
    };

    watch(() => lrnObjDtlDistSn.value, () => {
      initProgressBar();
      if (lrnObjDtlDistSn.value > 0) {
        getHrdCloudDetail();
      }
    });

    watch(() => historyModal.value, () => {
      // console.log('hrdvod historyModal : ',historyModal.value);
    });

    const leaderAdd = computed(() => store.state.hrdcloud.leaderAdd);

    // 담기 후 업데이트를 위해 한번 더 호출
    watch(() => leaderAdd.value.show, () => {
      if (hrd.value && !leaderAdd.value.show && leaderAdd.value.updateMyAdd) {
        const lrnObjDtlDistSn = leaderAdd.value.lrnObjDtlDistSn;
        if (hrd.value.lrnObjDtlDistSn === lrnObjDtlDistSn) {
          hrd.value.put = leaderAdd.value.isPut;
          disableMyAddChanged();
        }
      }
    });

    onMounted(() => {
      darkMode();
    });

    onBeforeUnmount(() => {
      lightMode();
      coldcall.value = false;
    });

    return {
      isMobile, session, uuid, lrnObjDtlDistSn,
      hrd, category, coldcall, user, histories,
      goBack,
      concentrationModeText,
      isMenuActive,
      toggleConcentrationMode,
      togglePlayerMenu,
      timestampToDateFormat,
      handleActions, detailFuncs, detailCategoryDisplay,
      historyModal, shareModal, opinionModal, objectParam,
      latestHistoryModal ,showLatestHistory,
      lrnObjTyCds,
      isLoading,
      isSeriesActive
    };
  }
};
</script>