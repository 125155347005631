<template>
  <article class="post-card" :class="[isSpecial() && 'special']" :data-read="refinedItem.myViewCnt > 0 ? 'active' : ''" @click="moveBoard(refinedItem)">
<!--    <router-link :to="getPath(refinedItem)" class="post-link" @click="moveBoard"></router-link>-->

    <template v-if="isSpecial()">
      <div class="post-line">
        <img src="@/assets/lxp/images/ssl/update/special_line.svg" alt="">
      </div>
      <div class="post-badge">
        <img src="@/assets/lxp/images/ssl/update/crown.gif" alt="">
      </div>
    </template>

    <div class="post-image">
<!--      <v-lazy-image v-if="refinedItem.thumb" :src="refinedItem.thumb" />-->
      <v-lazy-image v-if="refinedItem.thumb" :src="refineThumbUrl(refinedItem.thumb)" />
    </div>
    <div class="post-deem"></div>
    <div class="post-content">
      <div class="post-content-date">
        <h6>{{ refinedItem.yearMonth }}</h6>
        <h1>{{ refinedItem.day }}</h1>
      </div>
      <section class="post-content-desc">
        <div class="post-title">
          <h3>{{ refinedItem.title }}</h3>
          <div class="post-info">
            <div class="info-item">
              <i class="icon-like"></i>
              <span class="text">{{ refinedItem.likeCnt }}</span>
            </div>
            <div class="info-item">
              <i class="icon-share"></i>
              <span class="text">{{ refinedItem.sharedCnt }}</span>
            </div>
            <div class="info-item">
              <i class="icon-view"></i>
              <span class="text">{{ refinedItem.inqcnt }}</span>
            </div>
          </div>
        </div>
        <div class="post-desc">
          <div class="post-content-date">
            <h6>{{ refinedItem.yearMonth }}</h6>
            <h1>{{ refinedItem.day }}</h1>
          </div>
          <span v-html="refinedItem.title"></span>
          <p v-html="refinedItem.cutCn"></p>
        </div>
        <div class="post-profile">
          <div class="author">
            <span class="name" style="margin-right: 5px">{{ refinedItem.lrnerNm }}</span>
<!--            <span class="office">{{ isGroup ? refinedItem.refTyNm : '' }}</span> &lt;!&ndash; refinedItem.deptNm &ndash;&gt;-->
          </div>
        </div>
      </section>
      <div class="post-content-type">
        <div v-if="refinedItem.refCttTyNm === 'VOD'" class="badge-vod"><span class="text">영상</span></div>
        <div v-else-if="refinedItem.refCttTyNm === 'PDF'" class="badge-pdf"><span class="text">PDF</span></div>
        <div v-else class="badge-note"><span class="text">노트</span></div>
      </div>

      <div class="post-content-read" :class="{'is-active': refinedItem.myViewCnt > 0}">
        <i class="icon-read"></i>읽음
      </div>
    </div>
  </article>
</template>
<script>

import {computed} from "vue";
import {aws2ncloudUrl, dateToDateFormat, timestampToDateFormat, timestampToDatePart} from "@/assets/js/util";
import {useRoute, useRouter} from "vue-router";
import VLazyImage from "v-lazy-image";
import {getCrseSnToDistCrseCd, getTargetDt} from '@/assets/js/modules/ssl/ssl-common';
import {useStore} from 'vuex';

export default {
  name: 'SSLCard',
  components:{
    VLazyImage
  },
  props: {
    item: {
      required: true,
      type: Object
    },
    isGroup: Boolean
  },
  emits: ['moveBoard'],
  setup(props, {emit}) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const session = computed(() => store.state.auth.session);
    const excellents = computed(() => store.state.ssl.excellents.map(x => x.lrnerId+"-"+x.lrnTrgtGrpMstSn));
    const excellentDay = computed(() => store.state.ssl.excellents[0] ? store.state.ssl.excellents[0].regDt : null);

    const refinedItem = computed(() => {
      const datePart = timestampToDatePart(getTargetDt(props.item));
      return {
        ...props.item,
        yearMonth: `${datePart.year}.${datePart.mm}`,
        day: datePart.dd,
        cutCn:(props.item.cn !=null && props.item.cn.length > 300 ? props.item.cn.substring(0,300) + '...':props.item.cn)
      }
    });


    console.log("refinedItem = ", refinedItem.value.refCttTyNm);
    console.log("refinedItem = ", refinedItem.value.title);

    const refineThumbUrl =  (thumb) => {
      if (session.value.itn) {
          return thumb;
        } else {
          // console.log('aws2ncloudUrl', aws2ncloudUrl(thumb))
          return aws2ncloudUrl(thumb);
        }
      }

    const getPath = (item) => {
      // console.log('route.name : ' + route.name);
      let distCrseCd = getCrseSnToDistCrseCd(item.crseMstSn);
      let lrnTrgtGrpMstSn = item.lrnTrgtGrpMstSn;

      // console.log('SSLCardVue......................');
      // console.log('distCrseCd : ' + distCrseCd);
      // console.log('lrnTrgtGrpMstSn : ' + lrnTrgtGrpMstSn);
      // console.log('socLrnPostSn : ' + item.socLrnPostSn);
      // console.log('mltgms : ' + item.refTyNm);

      if(item.stt === '01'){
        // console.log('mltgms : ' + item.refTyNm);
        if (distCrseCd == 'group'){
          return {name: 'SSLWrite', params: {distCrseCd:distCrseCd, lrnTrgtGrpMstSn:item.refTyNm, socLrnPostSn: item.socLrnPostSn}, query:{mltgms:lrnTrgtGrpMstSn}};
        }else{
          return {name: 'SSLWrite', params: {distCrseCd:distCrseCd, lrnTrgtGrpMstSn:lrnTrgtGrpMstSn, socLrnPostSn: item.socLrnPostSn}, query:{mltgms:''}};
        }

      }else{
        if(route.name === 'SSLMyBoard'){
          if(timestampToDateFormat((item.pblcnDt ? item.pblcnDt : item.regDt), 'yyyy-MM-dd') === dateToDateFormat(new Date(), 'yyyy-MM-dd')){
            return {name: 'SSLWrite', params: {distCrseCd:distCrseCd, lrnTrgtGrpMstSn:lrnTrgtGrpMstSn, socLrnPostSn: item.socLrnPostSn}};
          }else{
            return {name: 'SSLView', params: {distCrseCd:distCrseCd, lrnTrgtGrpMstSn:lrnTrgtGrpMstSn, socLrnPostSn: item.socLrnPostSn}};
          }
        }else if(route.name === 'SSLTotalMy' || route.name === 'SSLTotalMyView') {
          return {name: 'SSLTotalView', params: {distCrseCd:distCrseCd, lrnTrgtGrpMstSn:lrnTrgtGrpMstSn,socLrnPostSn: item.socLrnPostSn}};
        }else if(route.name === 'SSLBestNote'){
          return {name: 'SSLBestNoteView', params: {distCrseCd:distCrseCd, lrnTrgtGrpMstSn:lrnTrgtGrpMstSn, socLrnPostSn: item.socLrnPostSn}};
        }else{
          return {name: 'SSLView', params: {distCrseCd:distCrseCd, lrnTrgtGrpMstSn:lrnTrgtGrpMstSn, socLrnPostSn: item.socLrnPostSn}};
        }
      }
    }

    const isSpecial = () => {

      return excellents.value.includes(props.item.lrnerId+"-"+props.item.lrnTrgtGrpMstSn) && ((props.item.pblcnDt ? props.item.pblcnDt : props.item.regDt) >= excellentDay.value);
      // return excellents.value.includes(props.item.lrnerId) && timestampToDatePart((props.item.pblcnDt ? props.item.pblcnDt : props.item.regDt)).ymd > halfYmd;
    }

    const moveBoard = (item) => {
      emit('moveBoard', props.item.socLrnPostSn);
      router.push(getPath(item)).then();
    }

    return {
      // category,
      session,
      refinedItem,
      refineThumbUrl,
      getPath,
      isSpecial,
      moveBoard
    }


  }
}
</script>
