<template>
  <!-- member-list-wrap -->
  <div class="member-list-wrap">
    <ul class="member-list">
      <!-- member-item -->
      <li v-for="(item, idx) in items" :key="idx" class="member-item">
        <div class="member-card">
            <div class="member-avatar">
              <router-link :to="getPath(item.lrnerId)">
              <ProfileImg :target="item"/>
              </router-link>
            </div>
          <div class="member-content">
            <div class="content-meta">
              <div class="meta"><span class="meta-text">{{ item.lrnerNm }}</span></div>
              <div class="meta"><span class="meta-text sub-text">{{ item.deptNm }}・{{ item.jbgdNm }}{{ item.jbpsNm }}</span></div>
            </div>
            <div v-if="$route.name === 'GroupView'" class="member-info mb-1">
              <div class="info"><span class="info-text">{{ item.selfIntro }}</span></div>
            </div>
            <div class="member-info">
              <template v-if="$route.name === 'GroupView'">
                <div class="info"><span class="info-text">출석 {{ item.attndRate }}회</span></div>
<!--                <div class="info"><span class="info-text">학습 {{ item.sumActPlaySec ? item.sumActPlaySecMi : '00' }}분</span></div>-->
                <div class="info"><span class="info-text">콘텐츠등록 {{  item.noteCnt }}건</span></div>
              </template>
              <div v-if="$route.name !== 'GroupView'" class="info"><span class="info-text">{{ item.selfIntro }}</span></div>
            </div>
          </div>
          <div class="member-badge">
            <div class="mini-badge" :class="item.grpRoleCdDcd === '2010001' ? 'badge-primary' : 'badge-gold'"><span class="badge-text">{{item.grpRoleCdDcd === '2010001' ? '리더' : '멤버' }}</span></div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <!-- //member-list-wrap -->
</template>

<script>
import ProfileImg from "@/components/common/ProfileImg";
import {groupMembersSetup} from "@/assets/js/modules/groupLearing/group-members-setup";

export default {
  name: "MobileGroupMembers",
  components: {ProfileImg},
  props: {
    items: Array,
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],
  setup: groupMembersSetup
}
</script>
<style scoped>
.lxp-mobile-layout.ssl-mobile-layout .member-badge {position:sticky !important;}
[id^="kb-prep"] .member-item ~ .member-item {border-top:1px solid #e8e8e8;margin-top:16px;padding-top:16px}
.badge-text {vertical-align: -webkit-baseline-middle}
</style>