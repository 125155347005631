<template>
  <div class="top-tabs flex">
    <slot name="page-name"></slot>
    <div style="display:flex;justify-content: center"> <!--  :style="{'width:76%;':!isMobile}" -->
      <router-link
          v-for="(item, idx) in items"
          :to="item.to" :key="idx"
          class="tab" :class="{'is-active': idx === activeIdx}"
      >
        <span class="tab-text">{{ item.name }}</span>
      </router-link>
    </div>
<!--    <div v-if="!isMobile" style="width:12%">&nbsp;</div>-->
  </div>

</template>

<script>

import {computed, ref} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";

export default {
  name: 'SSLTotalMyRoute',
  props:{
    year:String
  },
  setup(props){
    const store = useStore();
    const route = useRoute();

    const isMobile = computed(() => store.state.auth.isMobile);

    const activeIdx = computed(() => {
      if(route.params.view === 'complete'){
        return 1;
      }else if(route.params.view === 'temporary'){
        return 2;
      }else if(route.params.view === 'save'){
        return 3;
      }else if(route.params.view === 'subscribe'){
        return 4;
      }else if(route.params.view === 'subscribed'){
        return 4;
      }
      return 0;
    });

    console.log('route.params.view : ' + route.params.view);
    console.log('activeIdx : ' + activeIdx.value);



    const items_pc = ref([
      {to: {name: 'SSLTotalMy', params: {distCrseCd:'total', lrnTrgtGrpMstSn:0}}, name: '홈'},
      {to: {name: 'SSLTotalMyView', params: {distCrseCd: 'total', lrnTrgtGrpMstSn: 0, year:props.year, view: 'complete'}}, name: '게시글'},
      {to: {name: 'SSLTotalMyView', params: {distCrseCd: 'total', lrnTrgtGrpMstSn: 0, year:props.year, view: 'temporary'}}, name: '임시저장'},
      {to: {name: 'SSLTotalMyView', params: {distCrseCd: 'total', lrnTrgtGrpMstSn: 0, year:props.year, view: 'save'}}, name: '담은콘텐츠'},
      {to: {name: 'SSLTotalMyView', params: {distCrseCd: 'total', lrnTrgtGrpMstSn: 0, year:props.year, view: 'subscribe'}}, name: '구독'},
    ]);


    const items_mob = ref([
      {to: {name: 'SSLTotalMy', params: {distCrseCd:'total', lrnTrgtGrpMstSn:0}}, name: '홈'},
      {to: {name: 'SSLTotalMyView', params: {distCrseCd: 'total', lrnTrgtGrpMstSn: 0, year:props.year, view: 'complete'}}, name: '게시글'},
      {to: {name: 'SSLTotalMyView', params: {distCrseCd: 'total', lrnTrgtGrpMstSn: 0, year:props.year, view: 'temporary'}}, name: '임시저장'},
      {to: {name: 'SSLTotalMyView', params: {distCrseCd: 'total', lrnTrgtGrpMstSn: 0, year:props.year, view: 'save'}}, name: '담은콘텐츠'},
      {to: {name: 'SSLTotalMyView', params: {distCrseCd: 'total', lrnTrgtGrpMstSn: 0, year:props.year, view: 'subscribe'}}, name: '구독'},
    ]);

    const items = isMobile.value?items_mob.value:items_pc.value;

    return {
      items,
      activeIdx,
      isMobile
    }
  }
}
</script>
