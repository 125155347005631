<template>


  <main class="kb-main" id="ssl-home-v2">
    <main class="kb-main" id="kb-home" style="padding-bottom: 0px;">
      <article class="content-section section-hrdcloud" style="padding-bottom: 0;">
        <header class="section-header">
          <h2 class="title">인기</h2>
        </header>
        <div class="hrdcloud-list-wrapper">
          <SSLMobileMyBoard :lrn-trgt-grp-mst-sn="0" :sortCd="'like'" :lmtCnt="4" :opt="'S'" />
        </div>
      </article>


      <article class="content-section section-hrdcloud mt-0" style="border-top: 1px solid var(--kb-light-silver);padding-bottom: 0">
        <header class="section-header">
          <h2 class="title">최신</h2>
        </header>
        <div class="hrdcloud-list-wrapper">
          <SSLMobileMyBoard :lrn-trgt-grp-mst-sn="0" :sortCd="'new'" :lmtCnt="4" :opt="'S'" />
        </div>
      </article>
    </main>
  </main>

<!--  <div class="section-wrapper" style="padding-bottom: 72px;">-->
<!--    <article class="myssl-section section-sbadge">-->
<!--      <header class="section-header">-->
<!--        <h3 class="title">최신 노트</h3>-->
<!--      </header>-->
<!--      <div class="section-body">-->
<!--        <div class="contents">-->
<!--          <SSLMobileMyBoard :lrn-trgt-grp-mst-sn="0" :sortCd="'new'" :lmtCnt="4" :opt="'S'" />-->
<!--        </div>-->
<!--      </div>-->
<!--    </article>-->
<!--  </div>-->


  <SSLMobileMyBadgeFlex/>
</template>

<script>
import SSLMobileMyBoard from "@/views/ssl/mobile/SSLMobileMyBoard.vue";
import SSLMobileMyBadgeFlex from "@/components/ssl/my/mobile/SSLMobileMyBadgeFlex.vue";

export default {
  name: 'SSLMobileMyHome',
  components: {SSLMobileMyBadgeFlex, SSLMobileMyBoard},
  setup() {

    return{}
  }
}
</script>
