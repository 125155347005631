<template>
  <div class="contents" style="margin-bottom: 70px">
    <template v-if="isLoading">
      <LoadingDiv v-model="isLoading" />
    </template>
    <SSLNoteCard
        v-if="lmtCnt > 0"
        :slides-per-view='"auto"'
        :sslItems="items"/>

    <SSLMobileBoardList
        v-else
        :is-loading="isLoading"
        :items="items"
        :paging="paging"
        :more-func="moreFunc"
    />
  </div>
</template>

<script>
import SSLMobileBoardList from '@/components/ssl/main/mobile/SSLMobileBoardList';
import {sslMyBoardSetup} from '@/assets/js/modules/ssl/setup/ssl-my-setup';
import SSLNoteCard from "@/components/main/SSLNoteCard.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";

export default {
  name: 'SSLMobileMyBoard',
  components: {
    LoadingDiv,
    SSLNoteCard,
    SSLMobileBoardList,
  },
  props: {
    lrnTrgtGrpMstSn: Number,
    sortCd: String,
    lmtCnt: {
      type:Number,
      default:0
    },
    opt:{
      Type:String,
      default:'N'
    }
  },
  setup: sslMyBoardSetup,
};
</script>
