<template>
  <div style="display: flex; width: 100%; overflow-x: auto; padding: 15px;">
    <div style="margin-right: 5px;">
      <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn" href="javascript:" @click="goMyNote"><span
          class="text">my SSL<i class="icon-arrow" style="height: 17px;"></i></span>
      </a>
    </div>
    <div style="margin-right: 5px;">
      <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn" href="javascript:"><span class="text">콘텐츠 등록 : {{ socStat.boardCnt }}</span></a>
    </div>
    <div style="margin-right: 5px;">
      <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn" href="javascript:" @click="showAttendance"><span
          class="text">출석 일수 : {{ attendanceCnt }} <i class="icon-arrow" style="height: 17px;" v-if="attendanceCnt > 0"></i></span>
      </a>
    </div>
    <div style="margin-right: 5px;">
      <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn" href="javascript:" @click="showActFeed('cmntRec')"><span
          class="text">받은 댓글 : {{ socStat.cmntReceiveCnt }} <i class="icon-arrow" v-if="socStat.cmntReceiveCnt > 0" style="height: 17px;"></i></span>
      </a>
    </div>
    <div style="margin-right: 5px;">
      <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn" href="javascript:" @click="showActFeed('likeGet')">
        <span class="text">받은 좋아요 : {{ socStat.likeGetCnt }} <i class="icon-arrow" v-if="socStat.likeTodayGetCnt > 0" style="height: 17px;"></i></span>
      </a>
    </div>
    <div style="margin-right: 5px;">
      <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn" href="javascript:" @click="goBadgeBox"><span class="text">취득 배지수 : {{ myBadges.length }}<i class="icon-arrow" v-if="myBadges.length > 0" style="height: 17px;"></i></span></a>
    </div>
    <div style="margin-right: 5px;">
      <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn" href="javascript:"><span class="text">러닝메이트 : {{ mateCount }}</span></a>
    </div>
    <div style="margin-right: 5px;">
      <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn" href="javascript:"><span class="text">내 학습노트 조회수 : {{ socStat.boardInqCnt }}</span></a>
    </div>
    <div style="margin-right: 5px;">
      <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn" href="javascript:"><span class="text">내 학습노트 공유 : {{ socStat.sharedCnt }}</span></a>
    </div>
    <div style="margin-right: 5px;">
      <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn" href="javascript:"><span class="text">남긴 댓글 : {{ socStat.cmntWriteCnt }}</span></a>
    </div>
    <div style="margin-right: 5px;">
      <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn" href="javascript:"><span class="text">남긴 좋아요 : {{ socStat.likeGiveCnt }}</span></a>
    </div>
    <div style="margin-right: 5px;">
      <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn" href="javascript:" @click="showShareList">
        <span class="text">내가 공유한 노트수 : {{ socStat.shareGivCnt }} <i class="icon-arrow" v-if="socStat.shareGivCnt > 0" style="height: 17px;"></i></span>
      </a>
    </div>

  </div>
</template>
<script>
import {sslHomeActivitySetup} from '@/assets/js/modules/ssl/ssl-home';

export default {
  name: 'SSLMobileActivity',
  setup: sslHomeActivitySetup

}
</script>
