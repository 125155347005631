<template>
  <article class="hrdcloud-card" :class="[isEditable && isSelected ? 'is-active' : '', isShorts ? 'shorts-card' : '' ]">
      <div class="hrdcloud-image">
        <template v-if="item.cttTyCdDcd === cttTyCdDcds.CTT_TY_CD_PDF">
          <div class="image">
            <a href="javascript:" class="image-link">
              <img v-if="item.thumb" :src="getThumbUrl(item.thumb)" @error="setDefaultImg" />
              <img v-else src="../../assets/lxp/images/@tmp/@temp_pdf_new.jpg" @error="setDefaultImg" />
            </a>
          </div>
        </template>
        <template v-else>
          <div class="video" :class="{'shorts-card-image': isShorts }" @mouseover.stop="handleVideoActive(idx)" @mouseleave.stop="handleVideoActive(-2)"
               :key="`x_${item.layerUnique}`" @click.stop="viewHls(item)">
            <a href="javascript:" class="image-link">
              <CommonLazyImage v-if="isActiveIdx !== idx" :img-src="getThumbImg(item.thumb)"/>
              <ThumbnailVideo v-if="item.etnUrl && isActiveIdx === idx" :src="getVideoUrl(session.itn, item)"/>
            </a>
          </div>
        </template>
      </div>
    <div class="hrdcloud-content">
      <div class="content-title" style="padding-right:10px !important; display:flex; justify-content: space-between;">
        <span class="title">
          <span class="title-link">{{ item.objNm }}</span>
        </span>
        <span class="title" @click="mp4request">
          <span class="title-link" style="font-size: small">{{item.mp4Str}}</span>
        </span>
      </div>
    </div>
  </article>
</template>

<style scoped>
#kb-hrdcloud-v2 .shorts-card {
  width: 185px !important;
}

#kb-hrdcloud-v2 .shorts-card-image{
  height:329px !important;
}

.shorts-card {
  width: 230px !important;
}
.shorts-card-image{
  height:409px !important;
}
</style>
<script>
import {computed, ref, toRef} from 'vue';
import {getVideoUrl, secondToFormat, timestampToDateFormat} from '@/assets/js/util';
import CommonLazyImage from '@/components/common/CommonLazyImage';
import ThumbnailVideo from '@/components/common/ThumbnailVideo';
import {
  cttTyCdDcds,
  getTop1CategoryByLevel,
  handleActions,
  servTyCdDcds,
} from '@/assets/js/modules/hrd/hrd-common';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';
import {getThumbImg} from '@/assets/js/modules/baner/common-baner';
import {getThumbUrl} from '@/assets/js/modules/course/course-common';

export default {
  name: 'CommmonContentWrapper',
  components: {ThumbnailVideo, CommonLazyImage},
  props: {
    item: Object,
    idx: Number,
    session: Object,
    showPut: {type:Boolean, default:false},
    showIndex: {type: Boolean, default: false},
    isEditable: Boolean,
    isSelected: Boolean,
    showCount: {type:Boolean, default:true},
    isShorts: Boolean,
  },
  emits:['viewCastHls','requestMp4'],
  setup(props,{emit}) {
    const route = useRoute(), store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);
    const isActiveIdx = ref(-1);
    const videoTimeout = ref(null);
    const hrd = toRef(props, 'item');
    const categoryNms = computed(() => getTop1CategoryByLevel(hrd.value.cloudNmLst));
    const categorySns = computed(() => getTop1CategoryByLevel(hrd.value.cloudKeyLst));
    const isGroupLrn = computed(() => route.name.indexOf('Group') > -1);


    const handleVideoActive = (idx) => {
      if (idx === -2) {
        isActiveIdx.value = idx;
      }
      if (videoTimeout.value) {
        clearTimeout(videoTimeout.value);
        videoTimeout.value = null;
      }
      videoTimeout.value = setTimeout(() => {
        isActiveIdx.value = idx;
      }, 1000);
    };

    const setDefaultImg = (e) => {
      e.src = require('../../assets/lxp/images/@tmp/@temp_pdf_new.jpg');
    };

    const getYoutubeThumb = (itn, etnUrl) => {

      let youtubeId = etnUrl.indexOf("watch")>-1 ? etnUrl.split("=").pop() : "";
      youtubeId = etnUrl.indexOf("youtu.be")>-1 ? etnUrl.split("/").pop() : youtubeId;
      console.log('check thumb', itn, youtubeId, etnUrl)
      if(itn){
        return `/files/images/youtube.jpg`;
      } else{
        return `https://i.ytimg.com/vi/${youtubeId}/maxresdefault.jpg`;
      }
    };


    const viewHls = () =>{
      emit('viewCastHls', props.idx);
    }

    const mp4request = () =>{
      emit('requestMp4', props.idx);
    }

    return {
      timestampToDateFormat, secondToFormat, getVideoUrl,
      isMobile, isActiveIdx, categoryNms, categorySns,
      handleVideoActive, handleActions,
      servTyCdDcds, getThumbImg, cttTyCdDcds, setDefaultImg, getThumbUrl,
      isGroupLrn, getYoutubeThumb, viewHls, mp4request
    };
  },
};
</script>