import LxpLayout from "@/views/layout/lxp/LxpLayout";
import LxpMobileLayoutV2 from "@/views/layout/lxp/mobile/LxpMobileLayoutV2";
import navigationUtils from "@/assets/js/navigationUtils";
import LxpMobileSSLView from "@/views/pages/mobile/LxpMobileSSLView";


const isMobile = navigationUtils.any();

const lxpRoutes = [
    {
        path: '/main',
        component: isMobile ? LxpMobileLayoutV2: LxpLayout,
        children: [
            {
                path: "",
                name: "Main",
                component: () => isMobile ? import('../../views/pages/mobile/MobileMainV2') : import('../../views/pages/Main'),
            },
            {
                path: "/mockup",
                name: "MobileMockup",
                component: () => import('../../views/pages/mobile/MobileMockup'),
            },
            {
                path: "ssl/:distCrseCd/view/:socLrnPostSn",
                name: "LxpMobileSSLView",
                component: LxpMobileSSLView
            },
            {
                path: "/opinion",
                name: "MobileOpinion",
                component: () => import('../../views/pages/mobile/UIMobileOpinion'),
            },
            {
                path: "/sslStudio",
                name: "sslStudio",
                component: () => import('../../components/ssl/mobile/SSLMobileStudio'),
            },
        ],
    }
];

export const setLxpRoutes = (routes) => {
    routes.push(...lxpRoutes);
}