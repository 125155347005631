<template>

  <section class="myssl-section section-mybadge" style="border-top: 0;margin-top:-25px;">
    <header class="section-header">
      <h3 class="title">인기</h3>
    </header>

    <SSLMyBoardSwiper :sortCd="'like'" :lmtCnt="10" :opt="'S'"/>
  </section>

  <section class="myssl-section section-mybadge">
    <header class="section-header">
      <h3 class="title">최신</h3>
    </header>

    <SSLMyBoardSwiper :sortCd="'new'" :lmtCnt="10" :opt="'S'" />
  </section>

  <SSLMyBadgeFlex />
</template>

<script>
import SSLMyBadgeFlex from '@/components/ssl/my/SSLMyBadgeFlex';
import SSLMyBoardSwiper from "@/components/ssl/my/SSLMyBoardSwiper.vue";

export default {
  name: 'SSLMyHome',
  components: {
    SSLMyBoardSwiper, SSLMyBadgeFlex
  },
  setup(){

    return{

    }
  }
}
</script>