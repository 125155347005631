<template>
  <aside class="page-aside">
    <section class="aside-member">
<!--      <header class="aside-member-header">-->
<!--&lt;!&ndash;        <h3 class="title">참가{{ isGroup ? '그룹' : '인원' }} <em class="count">{{ refinedItems.length }}</em></h3>&ndash;&gt;-->
<!--        <h3 class="title">참가자</h3>-->
<!--      </header>-->
      <div class="aside-member-body">
        <div class="member-search">
          <div class="member-search-field">
            <input v-model.trim="keyword" type="text" class="kb-form-control" :placeholder="`직원 검색`" @keyup.enter="searchMember"/> <!--   :placeholder="`참가${isGroup ? '그룹' : '인원'} 검색`" -->
            <button class="kb-btn-search">
              <i class="icon-search"></i>
            </button>
          </div>
        </div>

        <div class="member-mask">
          <ul ref="membersEl" class="member-list">
            <template v-if="isGroup">
              <li v-for="(item, index) in renderItems" class="member-item" :key="index">
                <div class="member">
                  <router-link v-if="item.lrnerId==session.lrnerId" :to="{name: 'SSLTotalMy', params: {distCrseCd:'total', lrnTrgtGrpMstSn:0}}" class="member-link" @click="closeMember"></router-link>
                  <router-link v-else :to="{name:'SSLLearnerBoard', params: {lrnerId: item.lrnerId}}" class="member-link"></router-link>
<!--                  <div v-for="(group, index) in myGroup" :key="index">-->
<!--                    <div v-if=" group.distCrseSn === item.distCrseSn" class="member-badge">-->
<!--                      <div v-if="item.lrnerId == session.lrnerId" class="member-badge">-->
<!--                        <div class="badge badge-primary"><span class="badge-text">MY</span></div>-->
<!--                      </div>-->
<!--                  </div>-->
                  <div class="member-badge">
                    <div class="badge badge-primary" v-if="item.grpRoleCdLst.split('|')[0] == '2010001'"><span class="badge-text">리더</span></div>
                    <div class="badge badge-gold" v-else><span class="badge-text">멤버</span></div>
                  </div>
                  <div class="member-avatar">
                    <CommonTypeImage
                        :img-src="item.leaderImgUrl"
                        theme="profile"
                    />
                  </div>
                  <div class="member-content">
                    <strong class="name">{{ item.crseNmLst.split("|")[0] }} {{item.grpCnt > 1?'외'+(item.grpCnt-1):''}}</strong>
                    <p class="team">{{ item.distCrseLrnFldNmLst.split("|")[0]}} · {{ item.lrnerNm }}</p>
                  </div>
<!--                  <div class="member-content">-->
<!--                    <strong class="name">{{ item.crseNm }}</strong>-->
<!--                    <p class="team">{{ item.distCrseLrnFldNm }} · {{ item.leaderLrnerNm }}</p>-->
<!--                  </div>-->

                  <div class="member-follow" :class="{'is-active': item.subscribeSts > 0}">
                    <button class="kb-btn-follow" :class="{'kb-btn-follow-friends': item.subscribeSts === 1, 'kb-btn-follow-mate': item.subscribeSts === 2}" @click="unSubscribe(item)">
                      <span class="text">{{ item.subscribeSts === 2 ? '메이트' : '프렌즈' }}</span>
                    </button>
                  </div>

                </div>
              </li>
            </template>
            <template v-else>
              <li v-for="(item, index) in renderItems" class="member-item" :key="index">
                <div class="member">
                  <router-link v-if="item.lrnerId==session.lrnerId" :to="{name: 'SSLTotalMy', params: {distCrseCd:'total', lrnTrgtGrpMstSn:0}}" class="member-link" @click="closeMember"></router-link>
                  <router-link v-else :to="{name:'SSLLearnerBoard', params: {lrnerId: item.lrnerId}}" class="member-link"></router-link>
                  <div class="member-avatar">
                    <CommonTypeImage
                        :img-src="item.imgUrl"
                        theme="profile"
                    />
                  </div>

                  <div class="member-content">
                    <strong class="name">{{ item.lrnerNm }}</strong>
<!--                    <p class="team">{{ item.deptNm }}</p>-->
                  </div>
                  <div class="member-follow" :class="{'is-active': item.subscribeSts > 0}">
                    <button class="kb-btn-follow" :class="{'kb-btn-follow-friends': item.subscribeSts === 1, 'kb-btn-follow-mate': item.subscribeSts === 2}" @click="unSubscribe(item)">
                      <span class="text">{{ item.subscribeSts === 2 ? '메이트' : '프렌즈' }}</span>
                    </button>
                  </div>
                </div>
              </li>
            </template>

          </ul>
        </div>
      </div>
    </section>
  </aside>
</template>
<script>

import {sslMemberSetup} from '@/assets/js/modules/ssl/setup/ssl-learn-setup';
import CommonTypeImage from '@/components/common/CommonTypeImage';

export default {
  name: 'SSLMember',
  components: {CommonTypeImage},
  props: {
    lrnTrgtGrpMstSn: Number
  },
  setup: sslMemberSetup
}
</script>

<script setup>
</script>