export const MUT_SET_FROM_QUERY = 'setFromQuery'
export const MUT_SET_RETURN_QUERY = 'setReturnQuery'

export const MUT_SHOW_TOAST = 'showToast';
export const MUT_SHOW_CONFIRM = 'showConfirm';
export const MUT_CLOSE_CONFIRM = 'closeConfirm';

export const MUT_SHOW_MESSAGE = 'showMessage';
export const MUT_CLOSE_MESSAGE = 'closeMessage';

export const MUT_SHOW_LOADING = 'showLoading';
export const MUT_HIDE_LOADING = 'hideLoading';

export const MUT_SHOW_FOOTER = 'mutShowFooter';
export const MUT_HIDE_FOOTER = 'mutHideFooter';

// Util
export const ACT_GET_UTIL_UUID = 'actGetUtilUUID';

// Common
export const ACT_INSERT_QA = 'actInsertQa';
export const ACT_INSERT_FILE_ATCH = 'actInsertFileAtch';
export const ACT_GET_FILE_DOWN_MNG = 'actGetFileDownMng';
export const ACT_GET_FILE_DOWN_HIST_SN = 'actGetFileDownHistSn';
export const ACT_DOWNLOAD_FILE_DOWN_ATCH = 'actDownloadFileDownAtch';
export const ACT_GET_ADDR_LINK_LIST = 'actGetAddrLinkList';

export const MUT_SHOW_CUSTOM_CONFIRM = 'showCustomConfirm';
export const MUT_CLOSE_CUSTOM_CONFIRM = 'closeCustomConfirm';

export const MUT_SET_NOSLEEP = 'mutSetNoSleep';
export const MUT_ENABLE_NOSLEEP = 'mutEnableNosleep';
export const MUT_DISABLE_NOSLEEP = 'mutDisableNosleep';
export const MUT_SHOW_NATIVE_LAYER = 'mutShowNativeLayer';
export const MUT_CLOSE_NATIVE_LAYER = 'mutCloseNativeLayer';