<template>
<!--  is-visible-->
  <article class="home-section section-my">
    <div class="my-info" :style="isMobile?'justify-content: left;padding-left:20px':''">
      <div style="margin-top:10px">
        <div style="text-align: right"></div> <!-- <a href="javascript:" @click="popAlarmInfo"><i class="icon-alarm"></i></a> -->
        <div v-if="isMobile" class="info-avatar">
          <SSLProfileImg :target="session"/>
        </div>
      </div>
      <div class="info-box">
        <div v-if="!isMobile" class="info-avatar">
          <SSLProfileImg :target="session"/>
        </div>
        <div class="info-content" :class="isMobile?'mt-3':''">
          <span class="name">{{ myInfo.lrnerNm }}</span>
          <span class="team">{{ myInfo.lrnerNickNm }}</span>

        </div>

        <div v-if="isMobile" class="status-box mt-3">
          <a href="javascript:" @click="goMyNote">
            <div  class="status-item">
              <span class="title" style="font-size: 14px">my SSL</span>
              <span class="text"><img src="@/assets/lxp/images/ssl/home/ic_banner_arrow_next.svg" class="mt-1"></span>
            </div>
          </a>
        </div>
        <div v-if="isMobile" class="status-box mt-1">
          <div  class="status-item">
            <span class="title" style="font-size: 14px">나를 구독한 사람</span>
            <span class="text" style="font-size: 14px">{{ targetSubscribes }}</span>
          </div>
        </div>
        <div v-if="isMobile" class="status-box mt-1">
          <div class="status-item">
            <span class="title" style="font-size: 14px">내가 구독한 사람</span>
            <span class="text" style="font-size: 14px">{{ mySubscribes }}</span>
          </div>
        </div>
      </div>
      <div class="info-box" v-if="!isMobile">
        <div class="myssl-top writing-default mt-lg-3">
          <a href="javascript:" class="kb-btn kb-btn-writing" @click="navWrtToggle" ><span class="text">등록하기</span><i class="icon-add"></i></a>
        </div>
      </div>
      <div  v-if="!isMobile" class="status-box mt-lg-4">
        <a href="javascript:" @click="goMyNote">
        <div class="status-item">
          <span class="title">my SSL</span>
          <span class="text"><img src="@/assets/lxp/images/ssl/home/ic_banner_arrow_next.svg"></span>
        </div>
        </a>
        <div class="status-item">
          <span class="title" >나를 구독한 사람</span>
          <span class="text">{{ targetSubscribes }}</span>
        </div>
        <div class="status-item">
          <span class="title">내가 구독한 사람</span>
          <span class="text">{{ mySubscribes }}</span>
        </div>
      </div>
    </div>
  </article>

<!--  <SSLNavWrt v-if="isNavWrt && !isMobile" />-->
  <SSLWrtTyNav v-if="isNavWrt && !isMobile" />
  <SSLMobileNavWrt v-if="isNavWrt && isMobile" />
</template>
<script>

import {useStore} from "vuex";
import {computed, ref} from "vue";
import SSLProfileImg from "@/components/ssl/common/SSLProfileImg";
import SSLWrtTyNav from "@/components/ssl/SSLWrtTyNav";
import SSLMobileNavWrt from "@/components/ssl/mobile/SSLMobileNavWrt"
import {isSSLPeriod} from "@/assets/js/modules/ssl/ssl-write";
import {limitSSL} from "@/assets/js/modules/ssl/ssl-message";
import {useAlert} from "@/assets/js/modules/common/alert";
import router from "@/router";

export default {
  name: 'SSLHomeProfile',
  methods: {isSSLPeriod},
  components: {SSLWrtTyNav, SSLProfileImg, SSLMobileNavWrt},
  props: {
    myInfo:Object
  },
  setup() {
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);
    const session = computed(() => store.state.auth.session);
    const {showMessage} = useAlert();

    // 내가 구독한 사용자
    const mySubscribes = computed(() => Array.from(new Set(store.state.ssl.subscribe.my.map(x => x.trgtLrnerId))).length);
    // 나를 구독한 사용자
    const targetSubscribes = computed(() => Array.from(new Set(store.state.ssl.subscribe.target.map(x => x.lrnerId))).length);

    const isNavWrt = ref(false);

    const navWrtToggle=()=>{

      if(!isSSLPeriod()){
        showMessage( limitSSL);
        return false;
      }

      if(isNavWrt.value){
        isNavWrt.value = false;
      }else{
        isNavWrt.value = true;
      }
    }

    const goMyNote=()=>{
      router.push({name: 'SSLTotalMy', params: {distCrseCd:'total', lrnTrgtGrpMstSn:0}}).then(() => {});
    }

    return {
      isMobile,
      session,
      mySubscribes,
      targetSubscribes,
      navWrtToggle,
      isNavWrt,
      goMyNote
    }

  }
}
</script>
