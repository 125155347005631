<template>
    <div class="my-category board-list-container" style="display: flex;justify-content: start;margin-top: -15px" v-if="lmtCnt == 0">
      <!-- board-list-top -->
      <div class="board-list-top" >
        <div class="top-column">
          <SortButtonSelect
              v-model="filters.distCrseSn"
              v-model:toggle="selToggles.distCrseSn"
              title="과정"
              :options="distOptions"
              sequence-key="distCrseSn"
              name-key="crseNm"
              :is-all="true"
              :width="300"
              :textStyle="`font:normal 20px/25px var(--kb-font-KBFGDisplayB)`"
              @update:toggle="closeToggles(selToggles, 'distCrseSn')"
              @selected="getLrnerSocBoardList(true)"
          />
        </div>
        <div class="top-column" style="margin-left:20px">
          <SortButtonSelect
              v-model="filters.objType"
              v-model:toggle="selToggles.objType"
              title="전체"
              :options="typeOptions"
              sequence-key="key"
              name-key="value"
              :is-all="true"
              :width="130"
              :textStyle="`font:normal 20px/25px var(--kb-font-KBFGDisplayB)`"
              @update:toggle="closeToggles(selToggles, 'objType')"
              @selected="getLrnerSocBoardList(true)"
          />
        </div>
        <div class="top-column" style="margin-left:20px">
          <SortButtonSelect
              v-model="filters.sort"
              v-model:toggle="selToggles.sort"
              title="순서"
              :options="sortOptions"
              sequence-key="key"
              name-key="value"
              :width="130"
              :textStyle="`font:normal 20px/25px var(--kb-font-KBFGDisplayB)`"
              @update:toggle="closeToggles(selToggles, 'sort')"
              @selected="getLrnerSocBoardList(true)"
          />
        </div>
      </div>
    </div>

  <SSLBoardList
      :is-loading="isLoading"
      :items="items"
      :paging="paging"
      :more-func="moreFunc"
      :opt="opt"
  />
</template>

<script>

import SSLBoardList from "@/components/ssl/SSLBoardList";
import {sslLearnerBoardSetup} from "@/assets/js/modules/ssl/ssl-learn";
import SortButtonSelect from "@/components/common/SortButtonSelect.vue";

export default {
  name: 'SSLLearnerBoard',
  components: {
    SSLBoardList,
    SortButtonSelect,
  },
  props:{
    sortCd:{
      type:String,
      default:null
    },
    lmtCnt:{
      type:Number,
      default:0
    },
    opt:{
      type:String,
      default:'N'
    }
  },
  setup: sslLearnerBoardSetup
}
</script>
