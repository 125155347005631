import {computed, reactive, ref} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {useAlert} from "@/assets/js/modules/common/alert";
import {isSuccess, secondToFormat, setParams} from "@/assets/js/util";
import {ACT_UPDATE_GROUP_SELF_INTRO} from "@/store/modules/board/board";
import navigationUtils from "@/assets/js/navigationUtils";

export const groupMembersSetup = (props, {emit}) => {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const route = useRoute();

    const isSelfIntro = ref(false);

    const params = reactive({
        selfIntro: ''
    });

    const {showConfirm} = useAlert();

    const clickSelfIntroEdit = (item) => {
        isSelfIntro.value = true;
        setParams(params, item);
    };

    const clickSelfIntroCancel = () => {
        isSelfIntro.value = false;
    }

    const clickSelfIntroSave = (lrnTrgtGrpDtlSn) => {
        showConfirm({
            text:'수정하시겠습니까?',
            callback: () => {
                store.dispatch(`board/${ACT_UPDATE_GROUP_SELF_INTRO}`, {
                    lrnTrgtGrpDtlSn: lrnTrgtGrpDtlSn,
                    params: params
                }).then(res => {
                    if(isSuccess(res)) {
                        emit('update:modelValue', true);
                        isSelfIntro.value = false;
                    }
                })
            }
        });
    }

    const memberStyle = computed(() => {
        return (props.items.length > 6 && route.name === 'GroupView') ? 'height: 300px; overflow:scroll;' : '';
    });

    const getPath=lrnerId=>{
        if(session.value.lrnerId == lrnerId){

            return {name: 'SSLTotalMy', params: {distCrseCd:'total', lrnTrgtGrpMstSn:0}};
        }else{
            return {name: 'SSLLearner', params: {lrnerId: lrnerId}}
        }
    }

    return {
        session,
        isSelfIntro,
        params,
        memberStyle,
        secondToFormat,
        clickSelfIntroSave,
        clickSelfIntroEdit,
        clickSelfIntroCancel,
        getPath,

        isMobile: navigationUtils.any()
    }
}