<template>
  <!-- main-content -->
  <div class="main-content min-component">
    <!-- section: 2022년 주요자격증 시험 일정 안내 - 상품판매 필수자격증 -->
    <article class="content-section section-01">
      <header class="section-header">
        <div class="header-column">
          <h4 class="title">2025년 주요자격증 시험 일정 안내 - 상품판매 필수자격증</h4>
        </div>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table>
            <colgroup>
              <col style="width:250px" />
              <col style="width:100px" />
              <col style="width:148px" />
              <col style="width:auto" />
              <col style="width:148px" />
              <col style="width:290px" />
            </colgroup>
            <thead>
            <tr>
              <th class="span th-title">자격증명</th>
              <th class="span th-title">회차</th>
              <th class="span th-title">시험일</th>
              <th class="span th-title">원서접수 기간</th>
              <th class="span th-title">합격자 발표일</th>
              <th class="span th-title">주관처</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th><strong class="th-title">펀드투자권유자문인력</strong></th>
              <td><span class="td-text">30회</span></td>
              <td><span class="td-text">03.30(일)</span></td>
              <td><span class="td-text">2025.03.04(화)~2025.03.07(금)</span></td>
              <td><span class="td-text">04.10(목)</span></td>
              <td><span class="td-text">금융투자협회 자격시험 접수센터</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">펀드투자권유자문인력</strong></th>
              <td><span class="td-text">31회</span></td>
              <td><span class="td-text">06.29(일)</span></td>
              <td><span class="td-text">2025.06.02(월)~2025.06.06(금)</span></td>
              <td><span class="td-text">07.10(목)</span></td>
              <td><span class="td-text">금융투자협회 자격시험 접수센터</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">펀드투자권유자문인력</strong></th>
              <td><span class="td-text">32회</span></td>
              <td><span class="td-text">12.21(일)</span></td>
              <td><span class="td-text">2025.11.24(월)~2025.11.28(금)</span></td>
              <td><span class="td-text">01.01(목)</span></td>
              <td><span class="td-text">금융투자협회 자격시험 접수센터</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">파생상품 투자권유자문인력</strong></th>
              <td><span class="td-text">34회</span></td>
              <td><span class="td-text">03.09(일)</span></td>
              <td><span class="td-text">2025.02.10(월)~2025.02.14(금)</span></td>
              <td><span class="td-text">03.20(목)</span></td>
              <td><span class="td-text">금융투자협회 자격시험 접수센터</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">파생상품 투자권유자문인력</strong></th>
              <td><span class="td-text">35회</span></td>
              <td><span class="td-text">06.01(일)</span></td>
              <td><span class="td-text">2025.05.07(수)~2025.05.12(월)</span></td>
              <td><span class="td-text">06.12(목)</span></td>
              <td><span class="td-text">금융투자협회 자격시험 접수센터</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">파생상품 투자권유자문인력</strong></th>
              <td><span class="td-text">36회</span></td>
              <td><span class="td-text">11.30(일)</span></td>
              <td><span class="td-text">2025.11.03(월)~2025.11.07(금)</span></td>
              <td><span class="td-text">12.11(목)</span></td>
              <td><span class="td-text">금융투자협회 자격시험 접수센터</span></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="kb-table-bottom">
          <div class="bottom-notice">
            <strong class="subtext">금융투자협회 자격시험접수센터 ㅣ 1600-9477</strong>
          </div>
        </div>
      </div>
    </article>
    <!-- section: 2022년 주요자격증 시험 일정 안내 - 직무관련 핵심자격증 -->
    <article class="content-section section-divider section-02">
      <header class="section-header">
        <h4 class="title">2025년 주요자격증 시험 일정 안내 - 직무관련 핵심자격증</h4>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table>
            <colgroup>
              <col style="width:250px" />
              <col style="width:100px" />
              <col style="width:148px" />
              <col style="width:auto" />
              <col style="width:148px" />
              <col style="width:290px" />
            </colgroup>
            <thead>
            <tr>
              <th class="span th-title">자격증명</th>
              <th class="span th-title">회차</th>
              <th class="span th-title">시험일</th>
              <th class="span th-title">원서접수 기간</th>
              <th class="span th-title">합격자 발표일</th>
              <th class="span th-title">주관처</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th><strong class="th-title">외환전문역 I, II</strong></th>
              <td><span class="td-text">52회</span></td>
              <td><span class="td-text">03.22(토)</span></td>
              <td><span class="td-text">2025.02.11(화)~2025.02.18(화)</span></td>
              <td><span class="td-text">04.04(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">외환전문역 I, II</strong></th>
              <td><span class="td-text">53회</span></td>
              <td><span class="td-text">07.05(토)</span></td>
              <td><span class="td-text">2025.05.27(화)~2025.06.03(화)</span></td>
              <td><span class="td-text">07.18(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">외환전문역 I, II</strong></th>
              <td><span class="td-text">54회</span></td>
              <td><span class="td-text">11.22(토)</span></td>
              <td><span class="td-text">2025.10.14(화)~2025.10.21(화)</span></td>
              <td><span class="td-text">12.05(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">신용분석사</strong></th>
              <td><span class="td-text">61회</span></td>
              <td><span class="td-text">02.22(토)</span></td>
              <td><span class="td-text">2025.01.14(화)~2025.01.21(화)</span></td>
              <td><span class="td-text">03.07(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">신용분석사</strong></th>
              <td><span class="td-text">62회</span></td>
              <td><span class="td-text">06.21(토)</span></td>
              <td><span class="td-text">2025.05.13(화)~2025.05.20(화)</span></td>
              <td><span class="td-text">07.04(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">신용분석사</strong></th>
              <td><span class="td-text">63회</span></td>
              <td><span class="td-text">10.25(토)</span></td>
              <td><span class="td-text">2025.09.16(목)~2025.09.23(목)</span></td>
              <td><span class="td-text">11.07(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">자산관리사(은행FP)</strong></th>
              <td><span class="td-text">64회</span></td>
              <td><span class="td-text">04.19(토)</span></td>
              <td><span class="td-text">2025.03.11(화)~2025.03.18(화)</span></td>
              <td><span class="td-text">05.02(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">자산관리사(은행FP)</strong></th>
              <td><span class="td-text">65회</span></td>
              <td><span class="td-text">07.26(토)</span></td>
              <td><span class="td-text">2025.06.17(화)~2025.06.24(화)</span></td>
              <td><span class="td-text">08.08(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">자산관리사(은행FP)</strong></th>
              <td><span class="td-text">66회</span></td>
              <td><span class="td-text">11.08(토)</span></td>
              <td><span class="td-text">2025.09.23(화)~2025.09.30(화)</span></td>
              <td><span class="td-text">11.21(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">영업점컴플라이언스오피서(은행)</strong></th>
              <td><span class="td-text">33회</span></td>
              <td><span class="td-text">05.31(토)</span></td>
              <td><span class="td-text">2025.04.22(화)~2025.04.29(화)</span></td>
              <td><span class="td-text">06.13(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">영업점컴플라이언스오피서(은행)</strong></th>
              <td><span class="td-text">34회</span></td>
              <td><span class="td-text">09.06(토)</span></td>
              <td><span class="td-text">2025.07.29(화)~2025.08.05(화)</span></td>
              <td><span class="td-text">09.19(금)</span></td>
              <td><span class="td-text">한국금융연수원</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">한국재무설계사(AFPK)</strong></th>
              <td><span class="td-text">90회</span></td>
              <td><span class="td-text">03.15(토)</span></td>
              <td><span class="td-text">2025.02.17(월)~2025.03.04(화)</span></td>
              <td><span class="td-text">04.04(금)</span></td>
              <td><span class="td-text">한국 FPSB</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">한국재무설계사(AFPK)</strong></th>
              <td><span class="td-text">91회</span></td>
              <td><span class="td-text">08.23(토)</span></td>
              <td><span class="td-text">2025.07.28(월)~2025.08.11(월)</span></td>
              <td><span class="td-text">09.12(금)</span></td>
              <td><span class="td-text">한국 FPSB</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">한국재무설계사(AFPK)</strong></th>
              <td><span class="td-text">92회</span></td>
              <td><span class="td-text">11.22(토)</span></td>
              <td><span class="td-text">2025.10.27(월)~2025.11.10(월)</span></td>
              <td><span class="td-text">12.12(금)</span></td>
              <td><span class="td-text">한국 FPSB</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">국제공인 재무설계사(CFP)</strong></th>
              <td><span class="td-text">47회</span></td>
              <td><span class="td-text">05.17(토)~05.18(일)</span></td>
              <td><span class="td-text">2025.04.21(월)~2025.05.07(수)</span></td>
              <td><span class="td-text">06.05(목)</span></td>
              <td><span class="td-text">한국 FPSB</span></td>
            </tr>
            <tr>
              <th><strong class="th-title">국제공인 재무설계사(CFP)</strong></th>
              <td><span class="td-text">48회</span></td>
              <td><span class="td-text">10.25(토)~10.26(일)</span></td>
              <td><span class="td-text">2025.09.29(월)~2025.10.13(월)</span></td>
              <td><span class="td-text">11.14(금)</span></td>
              <td><span class="td-text">한국 FPSB</span></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="kb-table-bottom">
          <div class="bottom-notice">
            <strong class="subtext">한국금융연수원 ㅣ 02-3700-1500</strong>
          </div>
          <div class="bottom-notice">
            <strong class="subtext">한국 FPSB ㅣ 02-3276-7610</strong>
          </div>
        </div>
      </div>
    </article>
  </div>
  <!-- //main-content -->
</template>

<script>
export default {
  name: 'SupportLcnsExamSchedule',
};
</script>