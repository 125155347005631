<template>
  <main class="kb-main" id="kb-hrdcloud">
    <div class="main-content main-component">
      <div class="hrdcloud-view-container">
        <div class="player hrdcloud-player">
          <div class="player-container">
            <swiper ref="Swiper"
                    :direction="'vertical'"
                    class="shorts-swiper"
                    :slides-per-view="1"
                    @swiper="setControlledSwiper"
                    :onSlideChange="onSlideChange"
                    :mousewheel="true"
                    :touch-start-force-prevent-default="false"
                    :touch-events-target="'container'"
                    :allow-touch-move="true"
            >

              <swiper-slide v-for="(video, idx) in shortsItems" :key="idx">
                <div v-if="renderVideoFlag(idx)" class="player-inner">
                  <HrdCloudShortsPlayer
                      ref="players"
                      :uuid="video.uuid"
                      :crse="video"
                      :progress="user"
                      :popups="popups"
                      :preload="true"
                      :autoPlay="idx === activeIndex"
                      :videoIndex="idx"
                  >
                    <template v-slot:shortsDetail>
                      <div class="detail-row">
                        <div class="detail-contents">
                          <h3 class="title">{{ video.objNm }} <i v-if="video.isNew" class="icon-new"></i></h3>
                          <div class="metadata-container">
                            <div class="metadata"><i class="icon-view" style="filter:invert(1);"></i><span class="text">{{ video.inqCnt || 0 }}</span></div>
                            <div v-if="servTyCdDcds.SERV_TY_CD_KBTUBE === video.servTyCdDcd" class="metadata w-ctt"><span class="text">등록 {{ video.cttMstRgtrDeptNm }} {{ video.cttMstRgtrNm }} {{ timestampToDateFormat(video.regDt, 'yyyy.MM.dd') }}</span></div>
                            <div v-else class="metadata w-ctt"><span class="text">등록 {{ timestampToDateFormat(video.regDt, 'yyyy.MM.dd') }}</span></div>
                            <div class="metadata"><span class="text tag">{{ video.tag }}</span></div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:actionsDetail>
                      <HrdCloudShortsActions
                          :hrd="video"
                          :funcs="detailFuncs"
                      />
                    </template>
                  </HrdCloudShortsPlayer>
                </div>
                <div v-else class="player-inner" >
                  dummy Slide {{ idx+1 }}
                </div>
              </swiper-slide>
            </swiper>
            <!--            <HrdMobilePlayerDetails v-if="hrd" :player="videoInstance" :hrd="hrd" :funcs="detailFuncs" />-->
            <!--            <HrdCloudMobileSeriesList v-if="isSeriesActive && hrd && hrd.cttSnLst != null && hrd.cttSnLst.length > 0" :crse="hrd" />-->
            <!--            <HrdCloudRoadmapPlayList v-if="isRoadmapListActive" :crse="hrd" :roadmap-sns="roadmapSns" :close-func="() => {isRoadmapListActive.value = false;}" />-->
          </div>
        </div>
        <!--        <HrdCloudMobileComment v-if="hrd" :effect-nav="effectNav" :objSn="hrd.objSn" />-->
      </div>
    </div>
  </main>
  <div v-if="hrd" class="popup-container p-0 d-block" id="kb-mobile-popup" :class="{ 'is-active' : showModal }">
    <HrdCloudMobileHistory v-model="historyModal" :title="hrd.objNm" :duration="hrd.cttSz" :items="histories" :lrnObjDtlDistSn="lrnObjDtlDistSn" :ctt-ty-cd-dcd="hrd.cttTyCdDcd" />
    <HrdCloudMobileShare v-if="shareModal" v-model="shareModal" :data="hrd" />
    <HrdCloudMobileOpinion v-model="opinionModal" :cttMstSn="hrd.objSn" :title="hrd.objNm"/>
    <HrdCloudMobileSttModal v-model="sttModal" :title="hrd.objNm"  :item="sttText"  />
  </div>


</template>

<style scoped>

.lxp-mobile-layout #kb-hrdcloud { padding-bottom: 0px !important;}
.shorts-swiper{
  height: 100vh;
}

.player-inner {
  display: flex;
  justify-content: center;
}

</style>
<script>
import HrdCloudMobileHistory from '@/components/hrdcloud/mobile/HrdCloudMobileHistory';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
// import {ACT_GET_HRDCLOUD_DETAIL} from '@/store/modules/prep/prep';
import {
  closeAdded,
  closeSubscribed,
  darkMode,
  detailCategoryDisplay,
  disableMyAddChanged,
  generateUUID,
  handleActions,
  lightMode,
  pageView,
  showMain,
  cttTyCdDcds, servTyCdDcds, insertVodLearnProgress, eventCdDcds,
} from '@/assets/js/modules/hrd/hrd-common';
import {getFunc} from '@/assets/js/ui.init';
import {ACT_GET_LEARN_PROGRESS} from '@/store/modules/learn/learn';
import {initProgressBar, timestampToDateFormat} from '@/assets/js/util';
import HrdCloudMobileShare from '@/components/hrdcloud/mobile/HrdCloudMobileShare';
import HrdCloudMobileOpinion from '@/components/hrdcloud/mobile/HrdCloudMobileOpinion';
import {lrnObjTyCds} from '@/assets/js/modules/learn/learn-common';
import HrdCloudMobileSttModal from "@/components/hrdcloud/mobile/HrdCloudMobileSttModal";
import {Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import HrdCloudShortsPlayer from '@/components/hrdcloud/HrdCloudShortsPlayer';
import HrdCloudShortsActions from "@/components/hrdcloud/HrdCloudShortsActions.vue";
import {isInApp} from '@/assets/js/navigationUtils';

export default {
  name: 'HrdMobileShorts',
  components: {
    HrdCloudShortsActions,
    SwiperSlide,
    Swiper,
    HrdCloudMobileSttModal,
    HrdCloudMobileOpinion,
    HrdCloudMobileShare,
    HrdCloudMobileHistory,
    HrdCloudShortsPlayer,
  },
  props: {
    objDetail: Object,
    effectNav: {
      required: false,
      type: Number,
      default: 0
    },
    shortsItems:Array,
  },
  setup(props) {
    const store = useStore(), route = useRoute(), router = useRouter();
    const isMobile = computed(() => store.state.auth.isMobile);
    const session = computed(() => store.state.auth.session);
    const sttText = ref({});
    const lrnObjDtlDistSn = computed(() => route.params.lrnObjDtlDistSn);
    const videoInstance = ref(null);
    const hrd = computed(() => props.objDetail);
    const category = ref({});
    const isMenuActive = ref(false);
    const coldcall = ref(false);
    const user = reactive({progRsltSn: 0, maxPlaySec: 0, lastPlaySec: 0, cmptnYn: 'N', mdfcnDt: null, regDt: null});
    const showModal = ref(false), historyModal = ref(false), shareModal = ref(false), opinionModal = ref(false), sttModal = ref(false);
    const histories = ref([]);
    const mainShow = computed(() => store.state.hrdcloud.main.show);
    const isFullscreen = ref(false);
    const toggleFull = change => { isFullscreen.value = change; }
    const uuid = ref(null);
    const isSeriesActive = ref(false);
    const isRoadmapListActive = ref(false);
    const roadmapSns = ref([]);
    const nowVideo = ref({});
    const players = ref(null);

    const activeIndex = ref(0);

    const controlledSwiper = ref(null);
    const setControlledSwiper = (swiper) => {
      controlledSwiper.value = swiper;
    };

    const renderVideoFlag = (index) =>{
      return ( index >= activeIndex.value -1 && index <= activeIndex.value +1)
    }

    // PC랑 맞추기 위해 같은 객체 사용
    const popups = reactive({
      miss: {
        show: false,
        toggle: (bool) => {
          popups.miss.show = bool;
        },
        response: {
          effect: 0,
          type: null
        },
        respond: (type) => {
          popups.miss.response.effect++;
          popups.miss.response.type = type;
          popups.miss.show = false;
        },
        initRespond : () => {
          popups.miss.response.type = null;
        }
      },
      resume: {
        show: false,
        toggle: (bool) => {
          popups.resume.show = bool;
        },
        response: {
          effect: 0,
          type: null,
          continueEffect: 0,
        },
        respond: (type) => {
          popups.resume.response.effect++;
          popups.resume.response.type = type;
          popups.resume.show = false;
          if (type === 'continue') {
            popups.resume.response.continueEffect++;
          }
        },
        initRespond : () => {
          popups.resume.response.type = null;
        }
      },
      complete: {
        show: false,
        feedback: false,
        toggle: (bool) => {
          popups.complete.show = bool;
          if(!bool) popups.complete.feedback = bool;
        }
      },
      getUUID: async () => {
        const id = await generateUUID();
        if (id && id.uuid) uuid.value = id.uuid;
      }
    });

    const objectParam = computed(() => {
      return {
        distCrseSn: hrd.value.distCrseSn,
        distChapSn: hrd.value.distChapSn,
        lrnObjDtlDistSn: hrd.value.lrnObjDtlDistSn,
        objSn: hrd.value.objSn,
        // division: 'vod'
      }
    });

    // 로드맵 리스트
    const showRoadmapPlayList = (roadmapSnList) => {
      roadmapSns.value = roadmapSnList;
      isRoadmapListActive.value = true;
    }

    // 콘텐츠 정보
    const getHrdCloudDetail = async () => {
      pageView(hrd);
      await popups.getUUID();
      getUserProgress();
    };

    // 사용자 진도 로그
    const getUserProgress = () => {
      getFunc(`learn/${ACT_GET_LEARN_PROGRESS}`, {
        distCrseSn: hrd.value.distCrseSn,
        lrnObjDtlDistSn: hrd.value.lrnObjDtlDistSn
      }, user);
    };


    const updateVideoPlayback = () =>{
      nextTick(()=>{
        console.log("active idx", activeIndex.value);
        players.value.forEach((player) => {
              console.log("chk players", player.videoIndex , activeIndex.value, player.videoIndex === activeIndex.value);
              try{
                player.pauseVideo()
              }catch (e) {
                console.log("pause catch err", e.message);
              }
              if(player && typeof player.playVideo === "function"){
                if(player.videoIndex === activeIndex.value){
                  player.playVideo();
                  console.log("play video", player.videoInstance.id())
                }
              }
            }
        );
      })
    }

    const onSlideChange = (e) =>{

      console.log("chk slide event", e.activeIndex);
      activeIndex.value = e.activeIndex;

      updateVideoPlayback();

    };


    const togglePlayerMenu = () => {
      isMenuActive.value = !isMenuActive.value;
    };

    const detailFuncs = {
      //연관콘텐츠 바로가기 action 삽입 후 router push
      openOrigin: (crse) => {

        // console.log("crse ", crse, players.value[activeIndex.value]);
        let ct = Math.floor(players.value[activeIndex.value].videoInstance.currentTime());
        insertVodLearnProgress(crse, {
          progSesnKey: crse.uuid,
          lastPlaySec: ct,
          playSec: ct,
          playSpeed: 1,
          eventCdDcd: eventCdDcds.callRefContent,
          eventNm: '연관콘텐츠 호출',
        }, () => {
          let path = `/prep/hrdcloud/${crse.refLrnObjDtlDistSn}/detail`;
          router.push(path);
        });
      },
      openHistory: (item) => {
        handleActions(item, videoInstance.value).actHistory(historyModal, histories,
            () => {
              showModal.value = true;
            });
      },
      openShare: (item) => {
        handleActions(item, videoInstance.value).actShare(shareModal);
      },
      openOpinion: (item) => {
        handleActions(item, videoInstance.value).actOpinion(opinionModal);
      },
      openStt: (item) => {
        handleActions(item).actStt(sttModal,sttText);
      }
    };

    getHrdCloudDetail();

    watch(() => lrnObjDtlDistSn.value, () => {
      initProgressBar();
      if (lrnObjDtlDistSn.value > 0) {
        getHrdCloudDetail();
      }
    });

    watch(() => historyModal.value, () => {
      showModal.value = !showModal.value;
    });
    watch(() => shareModal.value, () => {
      showModal.value = !showModal.value;
    });
    watch(() => opinionModal.value, () => {
      showModal.value = !showModal.value;
    });
    watch(() => sttModal.value, () => {
      showModal.value = !showModal.value;
    });

    const leaderAdd = computed(() => store.state.hrdcloud.leaderAdd);

    // 담기 후 업데이트를 위해 한번 더 호출
    watch(() => leaderAdd.value.show, () => {
      if (hrd.value && !leaderAdd.value.show && leaderAdd.value.updateMyAdd) {
        const lrnObjDtlDistSn = leaderAdd.value.lrnObjDtlDistSn;
        if (hrd.value.lrnObjDtlDistSn === lrnObjDtlDistSn) {
          hrd.value.put = leaderAdd.value.isPut;
          disableMyAddChanged();
        }
      }
    });

    onMounted(() => {
      darkMode();
    });

    onBeforeUnmount(() => {
      lightMode();
      showMain();
      closeAdded();
      closeSubscribed();
      coldcall.value = false;
    });

    return {
      isMobile, session, uuid, hrd, category, coldcall, user, histories, sttText,
      videoInstance, lrnObjDtlDistSn,
      isMenuActive,
      togglePlayerMenu,
      timestampToDateFormat,
      handleActions, detailFuncs, detailCategoryDisplay,
      showModal, historyModal, shareModal, opinionModal, objectParam, sttModal,
      lrnObjTyCds,
      mainShow,
      popups,
      isFullscreen, toggleFull,
      isSeriesActive,
      roadmapSns,
      isRoadmapListActive,
      showRoadmapPlayList,
      nowVideo,
      setControlledSwiper,
      renderVideoFlag,
      activeIndex,
      onSlideChange, players,
      cttTyCdDcds, servTyCdDcds,
      isInApp,
    };
  }
};
</script>