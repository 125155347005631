<template>

  <article class="content-section home-section">
    <header class="section-header">
      <h3 class="title">활동 지표</h3>
    </header>
    <div class="section-body">
      <swiper v-if="learningRoadmapList.length > 0"
              class="hrdcloud-swiper"
              :modules="modules"
              slides-per-view="auto"
              :speed="750"
              :navigation="{
                      prevEl: '.swiper-btn-prev',
                      nextEl: '.swiper-btn-next'
                  }"
      >
        <swiper-slide  v-for="(roadmap,idx) in learningRoadmapList" :key="idx" class="roadmap-swiper" style="width: 310px !important;margin-left:12px">
          <SSLMobileHomeStatusCard @click="goLearn(roadmap.sslDivCd, roadmap.targetLrnTrgtGrpMstSn)" :data="roadmap"  />
        </swiper-slide>
        <button class="swiper-btn-prev">
          <i class="icon-arrow">prev</i>
        </button>
        <button class="swiper-btn-next">
          <i class="icon-arrow">next</i>
        </button>
      </swiper>
      <!--              <div v-if="learningRoadmapList.length > 0" class="d-flex justify-content-evenly">-->
      <!--                <template v-for="(roadmap,idx) in learningRoadmapList" :key="idx">-->
      <!--                  <HrdCloudRoadmapCard :data="roadmap.data" />-->
      <!--                </template>-->
      <!--              </div>-->
      <LoadingDiv v-else />
    </div>
  </article>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import LoadingDiv from '@/components/common/LoadingDiv';
import SSLMobileHomeStatusCard from '@/components/ssl/home/mobile/SSLMobileHomeStatusCard.vue';
import {sslHomeStatusSetup} from "@/assets/js/modules/ssl/setup/ssl-home-status";

export default {
  name: 'SSLHomeStatusV2',
  components: {
    SSLMobileHomeStatusCard, LoadingDiv, Swiper, SwiperSlide},
  props: {
    mainList: Array
  },
  setup: sslHomeStatusSetup,
};
</script>