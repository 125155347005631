<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue && !isLoading }">
    <div class="popup popup-attendance" id="popup-attendance">
      <button class="kb-btn-popup-close" @click="closeModal">
        <i class="icon-close"></i>
      </button>
      <div class="calendar">
        <div class="calendar-top">
          <h2 class="title">{{ currentMonth }}월 출석</h2>
          <div class="actions">
            <button class="kb-btn-month kb-btn-month-prev" type="button" @click="changeMonth(false)">
              <i class="icon-prev"></i>
              <span class="text">{{ prevYearMonth }}</span>
            </button>
            <button class="kb-btn-month kb-btn-month-next" type="button" @click="changeMonth(true)">
              <span class="text">{{ nextYearMonth }}</span>
              <i class="icon-next"></i>
            </button>
          </div>
        </div>
        <div class="calendar-body">
          <table class="calendar-table">
            <thead>
            <tr>
              <th scope="col"><span class="day-header">일</span></th>
              <th scope="col"><span class="day-header">월</span></th>
              <th scope="col"><span class="day-header">화</span></th>
              <th scope="col"><span class="day-header">수</span></th>
              <th scope="col"><span class="day-header">목</span></th>
              <th scope="col"><span class="day-header">금</span></th>
              <th scope="col"><span class="day-header">토</span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(week, idx) in currentWeeks_new" :key="idx">
              <td v-for="(day, i) in week" :key="`${idx}-${i}`">
                <template v-if="day.month === currentMonth">
                  <div class="day">
                    <template v-if="isHolyDay(day)">
                      <template v-if="!isActive(day)">
                        <span>{{ day.day }}</span> <!--  :class="{ 'text-red' : isWeekend(day) }" -->
                        <span class="text" style="position: absolute;margin-top:70px; font-size:14px">{{ getHolyDay(day).indexOf('대체공휴일')>-1?'대체공휴일':getHolyDay(day) }}</span>
                      </template>
                      <template v-else>
                        <div v-if="checkMlg(day) === 1" class="day">
                          <img v-if="day.isCouponDay === 2" style="width:40px;"  src="@/assets/images/ssl/ic_mlg_coupon1.svg" alt="" />
                          <i v-else class="icon-mileage"></i>
                        </div>
                        <div v-else-if="checkMlg(day) >= 2" class="day">
                          <img v-if="day.isCouponDay === 2" style="width:40px;"  src="@/assets/images/ssl/ic_mlg_coupon2.svg" alt="" />
                          <i v-else class="icon-mileage-2"></i>
                        </div>
                        <i v-else class="icon-attendance"></i>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="isActive(day)">
                          <div v-if="checkMlg(day) === 1" class="day">
                            <img v-if="day.isCouponDay === 2" style="width:40px;"  src="@/assets/images/ssl/ic_mlg_coupon1.svg" alt="" />
                            <i v-else class="icon-mileage"></i>
                          </div>
                          <div v-else-if="checkMlg(day) >= 2" class="day">
                            <img v-if="day.isCouponDay === 2" style="width:40px;"  src="@/assets/images/ssl/ic_mlg_coupon2.svg" alt="" />
                            <i v-else class="icon-mileage-2"></i>
                          </div>
                          <i v-else class="icon-attendance"></i>
                      </template>
<!--                      <i v-if="isActive(day)" class="icon-attendance"></i>-->
                      <span v-else>{{ day.day }}</span> <!--  :class="{ 'text-red' : isWeekend(day) }" -->
                      <span v-if="day.year === today.year && day.month === today.month && day.day === today.day" class="text" style="position: absolute;margin-top:70px; font-size:14px">오늘</span>
                    </template>
                  </div>
                </template>
                <template v-else>
                  <div v-if="day.month === currentMonth" class="day blank"></div>
                </template>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="calendar-bottom">
          <p class="mb-1 text-center msgbox">{{lrnerNm}}님의 <span class="msgboxS">{{currSeq}}회차[{{term}}]</span>
            출석일수는 <span class="msgboxS">{{incenAttendCnt}}일</span> 입니다.</p>

          <p class="bottom-text mb-1 text-center text-danger" v-if="incenAttendCnt>=20">"{{currSeq}}회차 인센티브 요건을 달성했어요!"</p>
          <p class="bottom-text text-center" v-else>"{{currSeq}}회차 인센티브 요건을 아직 달성하지 못했어요!"</p>

          <p class="bottom-text mt-0 text-center">※ 출석 : 동영상 학습 또는 전산실습 <span class="text-yellow">1마일리지 취득</span></p>
<!--          <p class="bottom-text"><span class="text-yellow"> 2주 단위 매영업일(주말 및 공휴일 제외)</span>  출석체크 달성 시 커피쿠폰이 제공됩니다.</p>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, reactive, ref} from 'vue';
import {getCalendar, timestampToKrDatePart} from '@/assets/js/util'; //, timestampToDatePart
import {ACT_GET_HRDCLOUD_HISTORY_ATTENDANCE_LIST} from '@/store/modules/history/history';
import {getListFunc} from '@/assets/js/ui.init';
import {holyDay} from '@/assets/js/holy-day';
import {HrdCloudCouponDays} from "@/assets/js/coupon-day";
import {useStore} from "vuex";

export default {
  name: 'Attendance',
  props: {
    modelValue: Boolean
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const isLoading = ref(true);
    const currentDate = new Date();
    const currentYear = ref(currentDate.getFullYear());
    const currentMonth = ref(currentDate.getMonth() + 1);
    const items = ref([]);
    const today = reactive({
      year: currentDate.getFullYear(),
      month: (currentDate.getMonth() + 1),
      day: currentDate.getDate(),
    });

    getListFunc(`history/${ACT_GET_HRDCLOUD_HISTORY_ATTENDANCE_LIST}`, {year: currentYear.value}, items, null, () => {
      // items.value = items.value.map(x => ({...x, datePart: timestampToDatePart(x.regDt)}));
      items.value = items.value.map(x => ({...x, datePart: timestampToKrDatePart(x.regDt)}));
      isLoading.value = false;
    });

    const currentWeeks = computed(() => getCalendar(currentYear.value, currentMonth.value).map(week => week.map((day, idx) => ({...day, isCouponDay: isCouponDay(day.ymd, idx)}))));
    const currentWeeks_new = computed(() => getCalendar(currentYear.value, currentMonth.value).map(week => week.map((day) => ({...day, isIncenDay: isIncenDay(day.ymd)}))));

    const nextYearMonth = computed(() => {
      if(currentMonth.value === 12){
        return `${currentYear.value + 1}년 1월`
      }else{
        return `${currentYear.value}년 ${currentMonth.value + 1}월`
      }
    });
    const todayYmd = `${today.year}${('0' + today.month).slice(-2)}${('0' + today.day).slice(-2)}`;

    const isCouponDay = (ymd, idx) => {
      if(idx === 5 || idx === 4){
        // 이미 획득...
        const filtered = HrdCloudCouponDays.filter(x => (x.indexOf(ymd) > -1));
        if(filtered.length > 0){
          const lastDate = filtered[0][(filtered[0].length - 1)];
          // console.log("lastDate:",lastDate)
          if(ymd === lastDate){
            // 1. 이미 지남...
            if(lastDate < todayYmd){
              if((items.value.filter(x => (x.datePart.ymd >= filtered[0][0] && x.datePart.ymd <= lastDate) && filtered[0].includes(x.datePart.ymd)).length) >= filtered[0].length){
                return 2;
              }else{
                return 1;
              }
            }
            // 2. 아직 안지남
            else if(lastDate > todayYmd){
              const index = filtered[0].indexOf(todayYmd);
              if(items.value.filter(x => (x.datePart.ymd >= filtered[0][0] && x.datePart.ymd <= lastDate)).length >= index){
                return 1;
              }else{
                return 2;
              }
            }
            // 3. 당일
            else if(lastDate === todayYmd){
              const todayAttend = items.value.filter(x => x.datePart.ymd === todayYmd);
              const attendCount = items.value.filter(x => (x.datePart.ymd >= filtered[0][0] && x.datePart.ymd <= lastDate)&& filtered[0].includes(x.datePart.ymd)).length;
              // console.log("todayAttend,attendCount :",todayAttend,attendCount,filtered[0].length)
              // 오늘 출석한 정보가 있을 경우
              if(todayAttend.length > 0){
                if(attendCount >=filtered[0].length){
                  return 2
                }
              }else{
                if(attendCount < (filtered[0].length )){
                  return 1;
                }
              }
            }
          }
        }
      }
      return 0;
    }

    const prevYearMonth = computed(() => {
      if(currentMonth.value === 1){
        return `${currentYear.value - 1}년 12월`
      }else{
        return `${currentYear.value}년 ${currentMonth.value - 1}월`
      }
    });



    const isHolyDay = ({year, month, day}) => {
      return !!holyDay[`${year}${('0' + month).slice(-2)}${('0' + day).slice(-2)}`];
    }

    const getHolyDay = ({year, month, day}) => {
      return holyDay[`${year}${('0' + month).slice(-2)}${('0' + day).slice(-2)}`];
    }

    const isWeekend = (date) => {
      const week = ['일', '월', '화', '수', '목', '금', '토'];

      const dayOfWeek = week[new Date(date.ymdformat).getDay()];

      if(dayOfWeek === '토' || dayOfWeek === '일') return true;

      return holyDay[date.ymd];
      // return false;
    }

    const changeMonth = (isNext) => {
      if(isNext){
        if(currentMonth.value === 12){
          currentYear.value++;
          currentMonth.value = 1;
        }else{
          currentMonth.value++;
        }
      }else{
        if(currentMonth.value === 1){
          currentYear.value--;
          currentMonth.value = 12;
        }else{
          currentMonth.value--;
        }
      }
    };


    const checkMlg = ({year, month, day}) => {
      let mlgValue = 0;
      items.value.forEach(x => {
        if (x.cmptnYear == year && x.cmptnMonth == month && x.cmptnDay == day) return mlgValue = x.mlgValue;
      });
      return mlgValue;
    };

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    //인센티브기간
    const incenPeriod = {
      // 0: {seq:0, sdt:'20250201', edt:'20250223', term:'2.01~2.23'}, //오픈 전...
      // 1: {seq:1, sdt:'20250224', edt:'20250427', term:'2.24~4.27'},
      0: {seq:0, sdt:'20250101', edt:'20250330', term:'1.01~3.30'}, //오픈 전...
      1: {seq:1, sdt:'20250331', edt:'20250427', term:'3.31~4.27'},
      2: {seq:2, sdt:'20250428', edt:'20250525', term:'4.28~5.25'},
      3: {seq:3, sdt:'20250526', edt:'20250620', term:'5.26~6.20'},
      4: {seq:0, sdt:'20250621', edt:'20250817', term:'6.21~8.17'}, //휴식기
      5: {seq:4, sdt:'20250818', edt:'20250914', term:'8.18~9.14'},
      6: {seq:5, sdt:'20250915', edt:'20251012', term:'9.15~10.12'},
      7: {seq:6, sdt:'20251013', edt:'20251107', term:'10.13~11.7'},
      8: {seq:7, sdt:'20251108', edt:'20260331', term:'11.08~03.31'} //과정종료
    };


    const lrnerNm = computed(()=> session.value.lrnerNm);

    const currSeq = Object.values(incenPeriod).filter(x=>x.sdt<= todayYmd && x.edt >= todayYmd ).map(y=>y.seq < 1?1:(y.seq==4?3:(y.seq>7?7:y.seq))).toString(); //인센티브 회차

    const currSDt = incenPeriod[currSeq].sdt;
    const currEdt = incenPeriod[currSeq].edt;
    const term = incenPeriod[currSeq].term;

    const incenAttendCnt = computed(()=>items.value.filter(x => x.datePart.ymd >= currSDt && x.datePart.ymd <= currEdt).length); // 인센티브기간동안의 출석일수

    const isIncenDay=(ymd)=>{
      if(ymd >= currSDt && ymd <= currEdt){
        return 1;
      }else{
        return 0;
      }
    }

    const isActive = ({year, month, day}) => {
      return items.value
          .filter(x => x.datePart.year === year && x.datePart.month === month && x.datePart.day === day
              // && ((x.datePart.ymd >= currSDt && x.datePart.ymd <= currEdt) || (x.datePart.ymd >= '20250101' && x.datePart.ymd <= '20250330')) //인센티브구간 + 2025.01.01~2025.03.30 기존출첵
          ) //인센티브구간 + 2025.01.01~2025.03.30 기존출첵
          .length > 0;
    }


    return {
      isLoading, 
      items, today, currentWeeks, currentMonth, currentYear, nextYearMonth, prevYearMonth,
      changeMonth, checkMlg, isHolyDay, getHolyDay, closeModal,
      isWeekend,isCouponDay,

      isActive,
      currSeq,
      lrnerNm,
      incenAttendCnt,
      term,
      currentWeeks_new
    }
  }
};
</script>