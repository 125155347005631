<template>
  <!-- content-section:my 그룹 현황 -->
  <section class="content-section section-divider">
    <header class="section-header">
      <h4 class="title">그룹</h4>
    </header>
    <GroupCard :items="items" :dist-crse-sn="distCrseSn"/>
  </section>
  <!-- content-section:그룹모임 -->
  <section class="content-section section-divider">
    <header class="section-header">
      <h4 class="title">모임일정</h4>
    </header>
    <article class="learningGroup-card">
      <!-- learningGroup-symbol -->
      <div v-if="moimTotalCnt > 0" class="learningGroup-symbol">
<!--        <span class="round">{{ mtgSeq }}</span>-->
      </div>
      <!-- learningGroup-content -->
      <div class="learningGroup-content">
        <!-- content-row -->
        <div class="content-row">
          <div class="content-column content-detail">
            <template v-if="existsBoard">
              <h3 class="content-title">
                <a href="javascript:" class="title-link">
                  <span class="title">{{ item.objNm }}</span>
                </a>
                <div class="badge">
                  <span class="badge-text">{{ item.lrnObjTyCd === '100020' ? '온라인' : '오프라인' }}</span>
                </div>
              </h3>
              <p class="content-description">
                <strong class="text gold">모임예정 {{timestampToDateFormat(item.objBgngDt, 'yyyy.MM.dd')}} {{timestampToDateFormat(item.objBgngDt, 'hh:mm')}}-{{timestampToDateFormat(item.objEndDt, 'hh:mm')}}</strong>
              </p>
            </template>
            <p v-else class="text-none text-muted">진행 예정인 모임일정이 없습니다.</p>
          </div>
          <!-- content-column -->
          <div v-if="existsBoard" class="content-column column-buttons">
            <a href="javascript:" @click="enterClass(item)" class="kb-btn kb-btn-primary"><span class="text">입장하기</span></a>
          </div>
          <div v-else-if="!existsBoard && isLeader" class="content-column column-buttons">
            <a
                href="javascript:"
                class="kb-btn kb-btn-primary"
                @click="clickClassEdit(items.find(x=> x.distCrseSn==distCrseSn).boardSn, existsBoard ? item.lrnPostSn : 0)"
              >
              <span class="text">모임등록</span>
            </a>
          </div>
          <!-- content-column -->
          <template v-if="existsBoard && isLeader" >
            <div v-if="!item.expsrBgngDt" class="content-column column-actions">
              <div class="dropdown" :class="{'is-active' : isClassSubActive}">
                <button class="dropdown-btn kb-btn-actions" @click="clickClassSub"><i class="icon-menu"></i></button>
                <div class="dropdown-target">
                  <div class="dropdown-box">
                    <ul class="dropdown-option-list boarded">
                      <li v-if="items.length > 0" class="dropdown-option-item" @click="clickClassEdit(items.find(x=> x.distCrseSn==distCrseSn).boardSn, item.lrnPostSn)">
                        <a href="javascript:" class="dropdown-option-link"><span class="dropdown-option-text">수정 ・ 삭제</span></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="items.length > 0 && item.progLogSn > 0" class="content-column column-actions">
              <div class="dropdown" :class="{'is-active' : isClassSubActive}">
                <button class="dropdown-btn kb-btn-actions" @click="clickClassSub"><i class="icon-menu"></i></button>
                <div class="dropdown-target">
                  <div class="dropdown-box">
                    <ul class="dropdown-option-list boarded">
                      <li class="dropdown-option-item" @click="lrnStatusUpdate(items.find(x=> x.distCrseSn==distCrseSn).boardSn, item)">
                        <a href="javascript:" class="dropdown-option-link"><span class="dropdown-option-text">학습종료취소</span></a>
                      </li>
                      <li v-if="item.expsrEndDt" class="dropdown-option-item" @click="clickClassEdit(items.find(x=> x.distCrseSn==distCrseSn).boardSn, 0)">
                        <a href="javascript:" class="dropdown-option-link"><span class="dropdown-option-text">모임등록</span></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </article>
  </section>
  <!-- content-section:그룹 학습현황 -->
  <section class="content-section section-divider">
    <header class="section-header">
      <h4 class="title">학습현황</h4>
    </header>
    <div class="roundedbox-wrap roundedbox-low">
      <!-- roundedbox -->
      <div class="roundedbox">
        <div class="roundedbox-top">
          <h5 class="roundedbox-title">총 모임횟수</h5>
        </div>
        <div class="roundedbox-bottom">
          <span class="roundedbox-text">{{ statusItem.grpMoimAdmitCnt }}회</span>
        </div>
      </div>
      <!-- roundedbox -->
      <div class="roundedbox">
        <div class="roundedbox-top">
          <h5 class="roundedbox-title">평균 참석률</h5>
        </div>
        <div class="roundedbox-bottom">
          <span class="roundedbox-text">{{ statusItem.avgGrpMoimAttndRatio }}%</span>
        </div>
      </div>
      <!-- roundedbox -->
      <div class="roundedbox">
        <div class="roundedbox-top">
          <h5 class="roundedbox-title">평균 콜드콜 응답률</h5>
        </div>
        <div class="roundedbox-bottom">
          <span class="roundedbox-text">{{ statusItem.avgColdcallRatio }}%</span>
        </div>
      </div>
      <!-- roundedbox -->
      <div class="roundedbox">
        <div class="roundedbox-top">
          <h5 class="roundedbox-title">평균 학습시간</h5>
        </div>
        <div class="roundedbox-bottom">
          <span class="roundedbox-text">{{ statusItem.avgLearningTimeRatioMi }}분</span>
        </div>
      </div>
    </div>
  </section>
  <!-- content-section:러닝닭컴 -->
  <section class="content-section section-stamp section-divider">
    <header class="section-header flex-column align-items-baseline">
      <h4 class="title">러닝닭컴</h4>
      <div class="header-sub mt-2">
        <span class="subtext">회차별 <span class="text" style="color:#ff8000;">‘학습자 전원이 출석한 모임횟수 4회 이상’</span> 달성 시 치킨쿠폰 지급 (회차별 대상 기간은 시행문 또는 공지사항 참고) <br>
          ※ 총 모임횟수와 러닝닭컴 인정횟수는 <span class="text" style="color:#ff8000;">‘학습자 전원 출석 여부’</span>에 따라 상이할 수 있습니다.
        </span>
      </div>
    </header>
    <!-- stamp-list-wrap -->
    <div class="stamp-list-wrap">
      <ul class="stamp-list">
        <div v-for="period in periods" :key="period.id" class="d-flex">
        <!-- stamp-item -->
<!--        <p class="position-absolute">기간: {{period.period}}</p>-->
        <div v-for="index in 4" :key="index" class="stamp-item">
            <!--          일반-->
            <div v-if="index <= 3" class="stamp" :class="{'is-active' :  index <= period.acptCount}">
              <div class="stamp-content"><span class="text">{{ period.id }}회차</span></div>
            </div>
            <!--          쿠폰-->
            <div v-else class="stamp stamp-coupon" :class="{'is-active' :  index <= period.acptCount}">
              <div class="stamp-content"><i class="icon-chicken"></i><small class="text">쿠폰</small></div>
            </div>
        </div>
        </div>
      </ul>
    </div>
    <!-- //stamp-list-wrap -->
  </section>
  <!-- content-section:그룹 멤버 -->
  <section class="content-section section-divider">
    <header class="section-header">
      <h4 class="title">
        멤버현황
        <div class="header-sub"><span class="subtext">&nbsp;&nbsp;{{ groupItems.length }}명</span></div>
      </h4>
    </header>
    <!-- curriculum-list-wrap -->
    <GroupMembers :items="groupItems"/>
    <!-- curriculum-list-wrap -->
  </section>

</template>

<script>
import GroupCard from "@/components/prep/group/GroupCard";
import GroupMembers from "@/components/prep/group/GroupMembers";
import {groupStatusSetup} from "@/assets/js/modules/groupLearing/group-status-setup";

export default {
  name: "GroupStatus",
  components: {GroupMembers, GroupCard},
  props: {
    items: Array,
    isLeader: Boolean
  },
  setup: groupStatusSetup
}
</script>