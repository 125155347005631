<template>
  <main class="kb-main" id="ssl-home-v2" >
    <SSLMobileSlotHeaderV2>
      <template v-slot:title>SSL</template>
    </SSLMobileSlotHeaderV2>
    <div class="main-content main-component mt-3">
      <article class="content-section">
        <div class="calendar">
          <div class="calendar-top">
            <h2 class="title">{{ currentMonth }}월 출석</h2>
            <div class="actions">
              <button class="kb-btn-month kb-btn-month-prev" type="button" @click="changeMonth(false)">
                <span class="text">이전</span>
              </button>
              <button class="kb-btn-month kb-btn-month-next" type="button" @click="changeMonth(true)">
                <span class="text">다음</span>
              </button>
            </div>
          </div>
          <div class="calendar-body">
            <table class="calendar-table">
              <thead>
              <tr>
                <th scope="col"><span class="day-header">일</span></th>
                <th scope="col"><span class="day-header">월</span></th>
                <th scope="col"><span class="day-header">화</span></th>
                <th scope="col"><span class="day-header">수</span></th>
                <th scope="col"><span class="day-header">목</span></th>
                <th scope="col"><span class="day-header">금</span></th>
                <th scope="col"><span class="day-header">토</span></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(week, idx) in currentWeeks_new" :key="idx">
                <td v-for="(day, i) in week" :key="`${idx}-${i}`">
                  <template v-if="day.month === currentMonth">
                    <div class="day kb-att-day day-deactivate"> <!--  :class="{ 'text-red' : isWeekend(day) }" -->
                      <img v-if="isActive(day)" class="day" src="@/assets/images/ssl/ic_att_active.png" style="width:34px;height:34px" alt="" />
                      <span v-else>{{ day.day }}</span>
                      <span v-if="isHolyDay(day)" class="text">{{ isHolyDay(day) ? (getHolyDay(day).indexOf('대체공휴일')>-1?'대체공휴일':getHolyDay(day)) : '' }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="day blank"></div>
                  </template>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="calendar-bottom" style="text-align: left; margin-top:0px;">
            <p class="bottom-text text-center" >{{lrnerNm}}님의  <span class="text-yellow">{{currSeq}}회차[{{term}}] </span> <br>
              출석일수는 <span class="text-yellow">{{incenAttendCnt}}일</span>, 등록콘텐츠는 <span class="text-yellow">{{regBoardCnt}}건</span> 입니다.</p>
            <p class="bottom-text mb-1 text-center text-danger" v-if="incenAttendCnt>=20 && regBoardCnt>=1">"{{currSeq}}회차 인센티브 요건을 달성했어요!"</p>
            <p class="bottom-text mb-1 text-center" v-else>"{{currSeq}}회차 인센티브 요건을 아직 달성하지 못했어요!"</p>
          </div>
          <div class="calendar-bottom mt-0">
<!--            <button v-if="isMobile" class="kb-btn-attendance-close " @click="closeCalendar" style="flex: 0 0 100% !important; margin-left: 0 !important;">-->
<!--              <span class="text">닫기</span>-->
<!--            </button>-->
            <button class="kb-btn-attendance mt-3" @click="closeCalendarAndExpire">
<!--              <span class="text">오늘 하루 보지 않기</span>-->
              <span class="text">닫기</span>
            </button>
          </div>
        </div>
      </article>
    </div>
  </main>
</template>

<script>

import {sslCalendarSetup} from '@/assets/js/modules/ssl/setup/ssl-calendar-setup';
import SSLMobileSlotHeaderV2 from "@/components/ssl/main/mobile/SSLMobileSlotHeaderV2.vue";
export default {
  name: 'SSLMobileCalendar',
  components: {SSLMobileSlotHeaderV2, },
  setup: sslCalendarSetup
};
</script>
<style>
#ssl-layout .calendar .calendar-bottom .bottom-box {
  font: normal 16px/22px var(--kb-font-KBFGText);
  color: var(--kb-silver);
}
</style>